import { Injectable } from '@angular/core';
import { IDateOrdinal } from '../interfaces/formula.interface';

@Injectable({
  providedIn: 'root',
})
export class SharedFormulaService {
  ordinals: Array<IDateOrdinal> = [
    { day: 1, day_ordinal_display_name: '1st' },
    { day: 2, day_ordinal_display_name: '2nd' },
    { day: 3, day_ordinal_display_name: '3rd' },
    { day: 4, day_ordinal_display_name: '4th' },
    { day: 5, day_ordinal_display_name: '5th' },
    { day: 6, day_ordinal_display_name: '6th' },
    { day: 7, day_ordinal_display_name: '7th' },
    { day: 8, day_ordinal_display_name: '8th' },
    { day: 9, day_ordinal_display_name: '9th' },
    { day: 10, day_ordinal_display_name: '10th' },
    { day: 11, day_ordinal_display_name: '11th' },
    { day: 12, day_ordinal_display_name: '12th' },
    { day: 13, day_ordinal_display_name: '13th' },
    { day: 14, day_ordinal_display_name: '14th' },
    { day: 15, day_ordinal_display_name: '15th' },
    { day: 16, day_ordinal_display_name: '16th' },
    { day: 17, day_ordinal_display_name: '17th' },
    { day: 18, day_ordinal_display_name: '18th' },
    { day: 19, day_ordinal_display_name: '19th' },
    { day: 20, day_ordinal_display_name: '20th' },
    { day: 21, day_ordinal_display_name: '21st' },
    { day: 22, day_ordinal_display_name: '22nd' },
    { day: 23, day_ordinal_display_name: '23rd' },
    { day: 24, day_ordinal_display_name: '24th' },
    { day: 25, day_ordinal_display_name: '25th' },
    { day: 26, day_ordinal_display_name: '26th' },
    { day: 27, day_ordinal_display_name: '27th' },
    { day: 28, day_ordinal_display_name: '28th' },
    { day: 29, day_ordinal_display_name: '29th' },
    { day: 30, day_ordinal_display_name: '30th' },
    { day: 31, day_ordinal_display_name: '31st' },
  ];

  constructor() {}

  getPowerRatingScale(percentage: number) {
    let x: number;

    if (percentage < 1) {
      x = 2.5;
    } else if (percentage < 2) {
      x = 2.52;
    } else if (percentage < 3) {
      x = 2.54;
    } else if (percentage < 4) {
      x = 2.56;
    } else if (percentage < 5) {
      x = 2.58;
    } else if (percentage < 6) {
      x = 2.6;
    } else if (percentage < 7) {
      x = 2.62;
    } else if (percentage < 8) {
      x = 2.64;
    } else if (percentage < 9) {
      x = 2.66;
    } else if (percentage < 10) {
      x = 2.68;
    } else if (percentage < 11) {
      x = 2.7;
    } else if (percentage < 12) {
      x = 2.724;
    } else if (percentage < 13) {
      x = 2.748;
    } else if (percentage < 14) {
      x = 2.772;
    } else if (percentage < 15) {
      x = 2.796;
    } else if (percentage < 16) {
      x = 2.82;
    } else if (percentage < 17) {
      x = 2.846;
    } else if (percentage < 18) {
      x = 2.872;
    } else if (percentage < 19) {
      x = 2.898;
    } else if (percentage < 20) {
      x = 2.924;
    } else if (percentage < 21) {
      x = 2.95;
    } else if (percentage < 22) {
      x = 2.978;
    } else if (percentage < 23) {
      x = 3.006;
    } else if (percentage < 24) {
      x = 3.034;
    } else if (percentage < 25) {
      x = 3.062;
    } else if (percentage < 26) {
      x = 3.062;
    } else if (percentage < 27) {
      x = 3.122;
    } else if (percentage < 28) {
      x = 3.154;
    } else if (percentage < 29) {
      x = 3.186;
    } else if (percentage < 30) {
      x = 3.218;
    } else if (percentage < 31) {
      x = 3.25;
    } else if (percentage < 32) {
      x = 3.288;
    } else if (percentage < 33) {
      x = 3.326;
    } else if (percentage < 34) {
      x = 3.364;
    } else if (percentage < 35) {
      x = 3.402;
    } else if (percentage < 36) {
      x = 3.44;
    } else if (percentage < 37) {
      x = 3.484;
    } else if (percentage < 38) {
      x = 3.528;
    } else if (percentage < 39) {
      x = 3.572;
    } else if (percentage < 40) {
      x = 3.616;
    } else if (percentage < 41) {
      x = 3.66;
    } else if (percentage < 42) {
      x = 3.712;
    } else if (percentage < 43) {
      x = 3.764;
    } else if (percentage < 44) {
      x = 3.816;
    } else if (percentage < 45) {
      x = 3.868;
    } else if (percentage < 46) {
      x = 3.92;
    } else if (percentage < 47) {
      x = 3.98;
    } else if (percentage < 48) {
      x = 4.04;
    } else if (percentage < 49) {
      x = 4.1;
    } else if (percentage < 50) {
      x = 4.16;
    } else if (percentage < 51) {
      x = 4.22;
    } else if (percentage < 52) {
      x = 4.29;
    } else if (percentage < 53) {
      x = 4.36;
    } else if (percentage < 54) {
      x = 4.43;
    } else if (percentage < 55) {
      x = 4.5;
    } else if (percentage < 56) {
      x = 4.57;
    } else if (percentage < 57) {
      x = 4.64;
    } else if (percentage < 58) {
      x = 4.71;
    } else if (percentage < 59) {
      x = 4.78;
    } else if (percentage < 60) {
      x = 4.85;
    } else if (percentage < 61) {
      x = 4.93;
    } else if (percentage < 62) {
      x = 5.01;
    } else if (percentage < 63) {
      x = 5.09;
    } else if (percentage < 64) {
      x = 5.17;
    } else if (percentage < 65) {
      x = 5.25;
    } else if (percentage < 66) {
      x = 5.33;
    } else if (percentage < 67) {
      x = 5.41;
    } else if (percentage < 68) {
      x = 5.49;
    } else if (percentage < 69) {
      x = 5.57;
    } else if (percentage < 70) {
      x = 5.65;
    } else if (percentage < 71) {
      x = 5.74;
    } else if (percentage < 72) {
      x = 5.83;
    } else if (percentage < 73) {
      x = 5.92;
    } else if (percentage < 74) {
      x = 6.01;
    } else if (percentage < 75) {
      x = 6.1;
    } else if (percentage < 76) {
      x = 6.19;
    } else {
      x = 10;
    }
    return x;
  }

  addDays(startDate: Date, numberOfDays: number) {
    return new Date(startDate.valueOf() + numberOfDays * 24 * 60 * 60 * 1000).valueOf();
  }

  subtractDays(startDate: Date, numberOfDays: number) {
    return new Date(startDate.valueOf() - numberOfDays * 24 * 60 * 60 * 1000).valueOf();
  }

  formatDateyyymmdd(milliseconds: number) {
    const d = new Date(milliseconds);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    let year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join('-');
  }

  formatDateWithHoursAndMinutes(date: Date) {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear(),
      hour = d.getHours(),
      min = d.getMinutes();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [[year, month, day].join('-'), [hour, min].join(':')].join(' ');
  }

  getDateOrdinal(day: number): IDateOrdinal {
    return this.ordinals.find((ordinal) => ordinal.day === day);
  }

  getDateOrdinalsForMonth(): Array<IDateOrdinal> {
    return this.ordinals;
  }

  getBirthDateFromIdNumber(idNumber: string): Date {
    const tempDate = new Date(parseInt(idNumber.substring(0, 2)), parseInt(idNumber.substring(2, 4)) - 1, parseInt(idNumber.substring(4, 6)));

    const id_date = tempDate.getDate();
    const id_month = tempDate.getMonth();
    let id_year = tempDate.getFullYear();
    if (id_year < new Date().getFullYear() - 100) {
      id_year += 100;
    }
    return new Date(id_year, id_month, id_date);
  }

  getAgeFromIdNumber(idNumber: string): number {
    const yearBorn = this.getBirthDateFromIdNumber(idNumber);
    const id_year = yearBorn.getFullYear();
    const theCurrentYear = new Date().getFullYear();
    return theCurrentYear - id_year;
  }

  getAgeOnDriversLicense(dateOnDrivers: string, idNumber: string) {
    const tempDate = new Date(
      parseInt(dateOnDrivers.substring(0, 4)),
      parseInt(dateOnDrivers.substring(5, 7)) - 1,
      parseInt(dateOnDrivers.substring(8, 10))
    );
    const drivers_date = tempDate.getDate();
    const drivers_month = tempDate.getMonth();
    const drivers_year = tempDate.getFullYear();
    const idNumberDate = this.getBirthDateFromIdNumber(idNumber);
    const idNumberYear = idNumberDate.getFullYear();
    const idNumberMonth = idNumberDate.getMonth();
    const idNumberDay = idNumberDate.getDay();
    const driversDate = new Date(drivers_year, drivers_month, drivers_date);
    const driversYear = driversDate.getFullYear();
    const driversMonth = driversDate.getMonth();
    const driversDay = driversDate.getDay();
    let ageOnDriversLicense = driversYear - idNumberYear;
    const month = driversMonth - idNumberMonth;
    const day = driversDay - idNumberDay;
    if (month < 0 || (month === 0 && driversDay < idNumberDay)) {
      ageOnDriversLicense--;
    }
    return ageOnDriversLicense;
  }
}
