export enum QuoteType {
  StandardQuoteRequest = 'standardQuoteRequest',
  MyProfile = 'myProfile',
  ViewAssetOnly = 'viewAssetOnly',
}

export enum QuoteQuestionUpdateType {
  RemoveQuestion = 'removeQuestion',
  AddQuestion = 'addQuestion',
}

export enum QuoteComparisonDetailType {
  EssentialCover = 'essentialCover',
  ConvenienceCover = 'convenienceCover',
  Excess = 'excess',
  Premium = 'premium',
  SpecialConditions = 'special_conditions',
  OptionalCover = 'optionalCover',
  QuoteComparisonDetailType = "QuoteComparisonDetailType",
}

export enum QuoteRequestStatusType {
  Open = 'OPEN',
  Submitted = 'SUBMITTED',
  WaitingToAccept = 'WAITING_TO_ACCEPT',
  WaitingOnQuotes = 'WAITING_ON_QUOTES',
  Accepted = 'ACCEPTED',
  Pending = "PENDING",
  Live = 'LIVE',
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  WaitingOnInsurer = 'WAITING_ON_INSURER',
}

export enum QuoteRequestType {
  Quote = 'QUOTE',
  Endorsement = 'ENDORSEMENT',
  EndorsementAdd = 'ENDORSEMENT_ADD',
  EndorsementUpdate = 'ENDORSEMENT_UPDATE',
}

export enum AddressSelectorScreen {
  Main = 'main',
  StreetAddress = 'streetAddress',
  PostalCode = 'postalCode',
}