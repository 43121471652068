import { CallNumber } from '@ionic-native/call-number/ngx';
import { Injectable } from '@angular/core';
import {  SharedConfig } from '@takectrl-multiapp/svcs-share-config';
@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  constructor(
    public callNumber: CallNumber,
    public sharedConfig: SharedConfig,
    ) {}

  makePhoneCall(phoneNumber: string) {
    this.callNumber
      .callNumber(phoneNumber, true)
      .then(() => console.log('makeCallMethod: Launched dialer!'))
      .catch((error) => {
        console.log('makeCallMethod: Error launching dialer');
        console.log(error);
      });
  }  
  
}
