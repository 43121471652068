import { SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
declare const dataLayer: any[];

@Component({
  selector: 'quote-complete',
  templateUrl: './quote-complete.component.html',
  styleUrls: ['./quote-complete.component.scss'],
})
export class QuoteCompleteComponent implements OnInit {
  @Input() queryParams: any;
  @Output() navigateHomeQuote: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() navigateReferFriend: EventEmitter<unknown> = new EventEmitter<unknown>();
  public policy_number: any;
  public effective_date: string;
  showCancelPrevInsurerNote = false;
  playAnimation = true;
  constructor(public thirdpartyService: BUSNThirdPartyService, public sharedConfig: SharedConfig) {}

  ngOnInit(): void {
    dataLayer.push({
      event: 'page_show',
      screenPath: 'quote-complete',
      screenName: 'quote-complete',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
    this.effective_date = SharedConfig.quoteConvertResponse.effective_date.split('T')[0].substring(0, 10);
    this.policy_number = SharedConfig.quoteConvertResponse.policy_number
      ? SharedConfig.quoteConvertResponse.policy_number
      : SharedConfig.quoteConvertResponse.policy_id;
  }

  goHome() {
    delete SharedConfig.quoteRequest
    this.navigateHomeQuote.emit();
    SharedConfig.showFooterTabs = true;
  }
}
