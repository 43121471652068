import { BUSNBrokerService } from '@takectrl-multiapp/svcs-core-business';
import { Injectable } from '@angular/core';
import { IBrokerage, IFirebaseLogin, IUser, SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import { SharedFormulaService } from '@takectrl-multiapp/svcs-share-config';
declare const dataLayer: any[];
import { FirebaseAnalyticsService, FirebaseService, ThirdPartyEventType } from '@takectrl-multiapp/svcs-core';
import { IntercomService } from 'libs/svcs-core/src/lib/services/third-party/intercom.service';
import { BUSNCommunicationService } from './busn-communication.service';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root',
})
export class BUSNThirdPartyService {
  intercomIsShowing = false;
  constructor(
    private readonly intercomService: IntercomService,
    private sharedConfig: SharedConfig,
    private sharedFormulaService: SharedFormulaService,
    private busnCommunicationService: BUSNCommunicationService,
    private firebaseAnalyticsService: FirebaseAnalyticsService,
    private firebaseService: FirebaseService,
    public busnBrokerService: BUSNBrokerService
  ) {}

  async initializeFirebaseAnalytics() {
    if (!SharedConfig.firebaseAnalyticsServiceInitialized) {
      SharedConfig.firebaseAnalyticsServiceInitialized = true;
      await this.firebaseAnalyticsService.initWeb(SharedConfig.appCredentials.firebase_credentials);
    }
  }

  async getBrokerage(): Promise<IBrokerage> {
    return await this.busnBrokerService.getBrokerage();
  }

  async initIntercom(brokerage: IBrokerage, user: IUser): Promise<void> {
    // INTERCOM ---
    SharedConfig.thirdPartyServiceInitialized = true;
    let intercomId = ''; //SharedConfig.appCredentials.intercom_app_id;  // this will be come redundent as all users will have brokers !!!! Leaving in as fail safe for now if backend did not update there side

    if (user.broker && brokerage && brokerage.intercom_id) {
      intercomId = brokerage.intercom_id;
    }
    const userPermissions = SharedConfig.userPermissions;
    
    if (userPermissions.intercom) {
      console.log('Registering Intercom User with the intercom ID: ' + intercomId);
      console.log('Registering Intercom User with the firebaseUserEmail: ' + this.sharedConfig.firebaseUserEmail);
      console.log('Registering Intercom User with the name: ' + user.personal_details.name);
      console.log('Registering Intercom User with the surname: ' + user.personal_details.surname);
      this.intercomService.init(this.sharedConfig.firebaseUserEmail, user.personal_details.name, user.firebase_uuid, intercomId);
      SharedConfig.intercomServiceInitialized = true;

    }
  }

  registerFirebaseAnalytics() {
    this.firebaseAnalyticsService.initWeb(SharedConfig.firebaseUserKey);
  }

  logEvent(
    eventType: ThirdPartyEventType,
    parameters: any = { dateUnix: Date.now(), date: this.sharedFormulaService.formatDateyyymmdd(Date.now()) }
  ) {
    parameters.date = this.sharedFormulaService.formatDateyyymmdd(Date.now());
    parameters.dateUnix = Date.now();
    parameters.platformType = SharedConfig.platformType;

    if (SharedConfig.firebaseUser) {
      if (SharedConfig.intercomServiceInitialized) {
        this.intercomService.logEvent(eventType, parameters);
      }
    }

    parameters.event = eventType;
    parameters.userUid = SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN';
    parameters.screenPath = 'na';
    parameters.screenName = 'na';
    dataLayer.push(parameters);
  }

  intercomDisplayLauncher() {
    if (this.sharedConfig?.userPermissions?.intercom) {
      if (!this.intercomIsShowing) {
        this.intercomIsShowing = true;
        this.intercomService.displayMessenger();
      } else {
        this.intercomIsShowing = false;
        this.intercomService.closeIntercom();
      }
    } else if (!this.sharedConfig?.userPermissions?.intercom) {
      this.busnCommunicationService.callBroker();
    }
  }

  intercomSendMessage(message: string) {
    this.intercomService.sendMessage(message);
  }

  firebaseSignOut(): Promise<void> {
    return this.firebaseService.signOut();
  }

  firebaseCurrentUser(): Promise<firebase.User> {
    return this.firebaseService.currentUser();
  }

  firebaseDatabaseSet(child: string, object: any): Promise<void> {
    return this.firebaseService.databaseSet(child, object);
  }

  firebaseDatabaseSubscribe(child: string, callback: () => Promise<void>) {
    this.firebaseService.databaseSubscribe(child, callback);
  }

  firebaseSignInWithPhoneNumber(phoneNumber: string, platformType: string): Promise<firebase.auth.ConfirmationResult> {
    return this.firebaseService.signInWithPhoneNumber(phoneNumber, platformType);
  }

  firebaseSignInWithEmailAndPassword(email: string, password: string): Promise<IFirebaseLogin> {
    return this.firebaseService.signInWithEmailAndPassword(email, password);
  }

  firebaseChangePassword(oldPassword: any, newPassword: any): void {
    this.firebaseService.changeFirebasePassword(oldPassword, newPassword);
  }

  firebaseResetPassword(email: string): Promise<unknown> {
    return this.firebaseService.resetPassword(email);
  }

  firebaseSignUpWithEmailAndPassword(email: string, password: string): Promise<IFirebaseLogin> {
    return this.firebaseService.signUpWithEmailAndPassword(email, password);
  }
}
