import { Component, EventEmitter, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { AlertController, IonRouterOutlet, IonSlides } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ClientConfigService } from '../../services/client-config.service';
import { UIAppInitializationService } from '../../services/ui-app-initialization.service';
import { SharedConfig, SharedEncryptionService } from '@takectrl-multiapp/svcs-share-config';
import { Storage } from '@capacitor/storage';
import '@lottiefiles/lottie-player';
import { SwUpdate } from '@angular/service-worker';
declare const dataLayer: any[];

@Component({
  selector: 'landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  @Output() navForgotPasswordPage: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() navRegisterPage: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() navLoginPage: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() navigateTermsPage: EventEmitter<unknown> = new EventEmitter<unknown>();
  @ViewChild('slides') ionSlides: IonSlides;
  @ViewChild('stepOne') stepOneVideo: ElementRef;
  @ViewChild('stepTwo') stepTwoVideo: ElementRef;
  @ViewChild('stepThree') stepThreeVideo: ElementRef;
  @ViewChild('stepFour') stepFourVideo: ElementRef;
  public clientDisplayName = this.sharedConfig.appClientDetail?.display_name;
  slideOpts = {
    slidesPerView: 1,
    loop: false,
  };
  public getCurrentSliderIndex = 0;
  public lottieSplash: boolean;
  hasSeenStepOne: boolean;
  hasSeenStepTwo: boolean;
  hasSeenStepThree: boolean;
  hasSeenStepFour: boolean;
  m_mode_popup = true;
  constructor(
    public clientConfigService: ClientConfigService,
    public uiAppInitializationService: UIAppInitializationService,
    private readonly sharedEncryptionService: SharedEncryptionService,
    public sharedConfig: SharedConfig,
    private readonly iab: InAppBrowser,
    public update: SwUpdate,
    private readonly routerOutlet: IonRouterOutlet,
    private readonly alertCtrl: AlertController
  ) {}

  ngAfterViewInit(): void {}

  async ngOnInit(): Promise<void> {
    console.log(`APP VERSION : ${this.sharedConfig.appClientDetail.version}`);
    this.lottieSplash = true;

    this.uiAppInitializationService.preUserValidationInitialization().then(() => {
      this.hasSeenStepOne = true;
      this.checkOTPLogin();
      this.routerOutlet.swipeGesture = false;
      this.startSplash();
    });

    dataLayer.push({
      event: 'page_show',
      screenPath: 'landing',
      screenName: 'landing',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }

  startSplash() {
    this.stepTwoVideo.nativeElement.load();
    const intervalId = setInterval(() => {
      this.lottieSplash = false;
      this.stepOneVideo.nativeElement.play();
      clearInterval(intervalId);
    }, 1000);
  }

  async checkOTPLogin() {
    const urlParams = new URLSearchParams(window.location.search);
    SharedConfig.routeParamUserMsisdn = urlParams.get('msisdn');
    SharedConfig.routeParamUserFirstName = urlParams.get('firstName');
    SharedConfig.routeParamUserLastName = urlParams.get('surname');
    SharedConfig.routeParamUserEmail = urlParams.get('email');
    SharedConfig.routeParamUserEmailQuoteOnBehalf = urlParams.get('email_on_behalf');
    SharedConfig.routeParamUserPaswrd = urlParams.get('passwrd');
    SharedConfig.routeParamDealershipReferralCode = urlParams.get('ref');
    SharedConfig.routeParamBrokerCode = urlParams.get('broker');
    SharedConfig.routeParamLogInWithOTP = urlParams.get('OTPLogin');

    if (!SharedConfig.routeParamUserMsisdn) {
      SharedConfig.routeParamUserMsisdn = urlParams.get('e_msisdn')
        ? this.sharedEncryptionService.decryptStringECB(urlParams.get('e_msisdn'), SharedConfig.decryptionKey)
        : null;
    }

    if (!SharedConfig.routeParamUserFirstName) {
      SharedConfig.routeParamUserFirstName = urlParams.get('e_firstName')
        ? this.sharedEncryptionService.decryptStringECB(urlParams.get('e_firstName'), SharedConfig.decryptionKey)
        : null;
    }

    if (!SharedConfig.routeParamUserLastName) {
      SharedConfig.routeParamUserLastName = urlParams.get('e_surname')
        ? this.sharedEncryptionService.decryptStringECB(urlParams.get('e_surname'), SharedConfig.decryptionKey)
        : null;
    }

    if (!SharedConfig.routeParamUserEmail) {
      SharedConfig.routeParamUserEmail = urlParams.get('e_email')
        ? this.sharedEncryptionService.decryptStringECB(urlParams.get('e_email'), SharedConfig.decryptionKey)
        : null;
    }

    if (!SharedConfig.routeParamUserEmailQuoteOnBehalf) {
      SharedConfig.routeParamUserEmailQuoteOnBehalf = urlParams.get('e_email_on_behalf')
        ? this.sharedEncryptionService.decryptStringECB(urlParams.get('e_email_on_behalf'), SharedConfig.decryptionKey)
        : null;
    }

    if (!SharedConfig.routeParamUserPaswrd) {
      SharedConfig.routeParamUserPaswrd = urlParams.get('e_passwrd')
        ? this.sharedEncryptionService.decryptStringECB(urlParams.get('e_passwrd'), SharedConfig.decryptionKey)
        : null;
    }

    if (!SharedConfig.routeParamUserMsisdn && (SharedConfig.routeParamDealershipReferralCode || SharedConfig.routeParamBrokerCode)) {
      this.uiAppInitializationService.getAppSettings();
      this.navRegisterPage.emit();
      return;
    }

    if (SharedConfig.routeParamUserEmail && !SharedConfig.routeParamUserMsisdn && !SharedConfig.routeParamUserEmailQuoteOnBehalf) {
      this.uiAppInitializationService.getAppSettings();
      this.navForgotPasswordPage.emit();
      return;
    }

    if (!SharedConfig.routeParamUserEmail) {
      this.sharedConfig.setFirebaseUserEmail((await Storage.get({ key: 'userEmail' })).value);
    }
    if (
      (this.sharedConfig.firebaseUserEmail ||
        SharedConfig.routeParamUserMsisdn ||
        SharedConfig.routeParamLogInWithOTP ||
        SharedConfig.routeParamUserEmailQuoteOnBehalf) &&
      this.sharedConfig.appSettings &&
      !this.sharedConfig.appSettings.maintenance_mode
    ) {
      this.navLoginPage.emit();
      return;
    }
  }

  closeUpdateAppPage(){
    SharedConfig.showPopupWithDismissButton_updateApp = false
  }

  onEnded() {
    if (this.hasSeenStepOne && !this.hasSeenStepTwo && !this.hasSeenStepThree && !this.hasSeenStepFour) {
      this.ionSlides.slideTo(1);
      this.hasSeenStepOne = false;
      this.hasSeenStepTwo = true;
      this.hasSeenStepThree = false;
      this.hasSeenStepFour = false;
      this.stepTwoVideo.nativeElement.addEventListener(
        'loadeddata',
        function () {
          this.stepTwoVideo.nativeElement.play();
        },
        false
      );
    }
  }

  slideDidChange() {
    this.ionSlides.getActiveIndex().then((index) => {
      this.getCurrentSliderIndex = index;
      if (index === 0) {
        this.stepFourVideo.nativeElement.currentTime = 0;
        this.stepOneVideo.nativeElement.play();
        this.hasSeenStepOne = true;
        this.hasSeenStepTwo = false;
        this.hasSeenStepThree = false;
        this.hasSeenStepFour = false;
        this.stepOneVideo.nativeElement.onended = () => {
          if (this.hasSeenStepOne && !this.hasSeenStepTwo && !this.hasSeenStepThree && !this.hasSeenStepFour) {
            this.ionSlides.slideTo(1);
          }
        };
      }
      if (index === 1) {
        this.stepOneVideo.nativeElement.currentTime = 0;
        this.stepTwoVideo.nativeElement.play();
        this.hasSeenStepOne = false;
        this.hasSeenStepTwo = true;
        this.hasSeenStepThree = false;
        this.hasSeenStepFour = false;
        this.stepTwoVideo.nativeElement.onended = () => {
          if (!this.hasSeenStepOne && this.hasSeenStepTwo && !this.hasSeenStepThree && !this.hasSeenStepFour) {
            this.ionSlides.slideTo(2);
          }
        };
      }
      if (index === 2) {
        this.stepTwoVideo.nativeElement.currentTime = 0;
        this.stepThreeVideo.nativeElement.play();
        this.hasSeenStepOne = false;
        this.hasSeenStepTwo = false;
        this.hasSeenStepThree = true;
        this.hasSeenStepFour = false;
        this.stepThreeVideo.nativeElement.onended = () => {
          if (!this.hasSeenStepOne && !this.hasSeenStepTwo && this.hasSeenStepThree && !this.hasSeenStepFour) {
            this.ionSlides.slideTo(3);
          }
        };
      }
      if (index === 3) {
        this.stepThreeVideo.nativeElement.currentTime = 0;
        this.stepFourVideo.nativeElement.play();
        this.hasSeenStepOne = false;
        this.hasSeenStepTwo = false;
        this.hasSeenStepThree = false;
        this.hasSeenStepFour = true;
        this.stepFourVideo.nativeElement.onended = () => {
          if (!this.hasSeenStepOne && !this.hasSeenStepTwo && !this.hasSeenStepThree && this.hasSeenStepFour) {
            this.ionSlides.slideTo(0);
          }
        };
      }
    });
  }

  sliderAtEnd() {
    this.ionSlides.stopAutoplay();
  }

  goToLastSlide() {
    this.ionSlides.slideTo(6);
  }

  loginPage() {
    this.navLoginPage.emit();
  }

  async registerPage() {
    this.navRegisterPage.emit();
  }

  termsPage() {
    this.iab.create(this.sharedConfig.appSettings.terms_url, '_system');
  }

  async showBypass() {
    const bypassAlert = await this.alertCtrl.create({
      header: 'Enter passcode',
      subHeader: 'Please enter your developer passcode.',
      inputs: [
        {
          name: 'passcode',
          placeholder: 'Enter Passcode',
          type: 'password',
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Confirm',
          handler: (data) => {
            data.passcode;
            if (data.passcode === 'goldeneye') {
              this.m_mode_popup = false;
              bypassAlert.dismiss();
            }
          },
        },
      ],
    });
    bypassAlert.present();
  }
}
