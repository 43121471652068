<div class="wrapper-no-height limit-width-on-desktop-float">
  <div class="top-section">
    <h2 class="h2-bold">Useful documents and letters from your insurer</h2>
  </div>
  <div class="content-section">
    <div *ngFor="let document of documents; let i = index">
      <a href={{document.url}} target="_blank">
        <ion-item lines="none">
          <svg class="documents-icon" slot="start" width="57" height="56" viewBox="0 0 57 56" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g id="Two tone Icon">
              <g id="Frame 1149">
                <path id="Path" fill-rule="evenodd" clip-rule="evenodd"
                  d="M36.0858 12C36.351 12 36.6054 12.1054 36.7929 12.2929L43.2071 18.7071C43.3946 18.8946 43.5 19.149 43.5 19.4142V37C43.5 38.1046 42.6046 39 41.5 39H34.5V42C34.5 43.1046 33.6046 44 32.5 44H15.5C14.3954 44 13.5 43.1046 13.5 42V19C13.5 17.8954 14.3954 17 15.5 17H22.5V14C22.5 12.8954 23.3954 12 24.5 12H36.0858Z"
                  class="icon-fill-light" />
                <path id="Shape" fill-rule="evenodd" clip-rule="evenodd"
                  d="M41.2481 12V9H39.7481V12H41.2481ZM45.3839 9.51188L46.4445 10.5725L42.909 14.1081L41.8483 13.0474L45.3839 9.51188ZM46.4981 14.75H43.4981V16.25H46.4981V14.75ZM31.5 18.2064V19.7064H26.5V18.2064H31.5ZM39.5 23.7064V22.2064H26.5V23.7064H39.5ZM22.5 23V24.5H17.5V23H22.5ZM39.5 27.7064V26.2064H26.5V27.7064H39.5ZM22.5 27V28.5H17.5V27H22.5ZM39.5 31.7064V30.2064H26.5V31.7064H39.5ZM22.5 31V32.5H17.5V31H22.5ZM22.5 35V36.5H17.5V35H22.5Z"
                  class="icon-fill-shade-3" />
                <path id="Combined Shape" fill-rule="evenodd" clip-rule="evenodd"
                  d="M36.0858 11.25H24.5C22.9812 11.25 21.75 12.4812 21.75 14V16.25H15.5C13.9812 16.25 12.75 17.4812 12.75 19V42C12.75 43.5188 13.9812 44.75 15.5 44.75H32.5C34.0188 44.75 35.25 43.5188 35.25 42V39.75H41.5C43.0188 39.75 44.25 38.5188 44.25 37V19.4142C44.25 18.9501 44.0656 18.505 43.7374 18.1768L37.3232 11.7626C36.995 11.4344 36.5499 11.25 36.0858 11.25ZM33.75 39.75H24.5C22.9812 39.75 21.75 38.5188 21.75 37V17.75H15.5C14.8096 17.75 14.25 18.3096 14.25 19V42C14.25 42.6904 14.8096 43.25 15.5 43.25H32.5C33.1904 43.25 33.75 42.6904 33.75 42V39.75ZM35.75 12.75H24.5C23.8096 12.75 23.25 13.3096 23.25 14V37C23.25 37.6904 23.8096 38.25 24.5 38.25H41.5C42.1904 38.25 42.75 37.6904 42.75 37V20.2064H38.5C37.0375 20.2064 35.8416 19.0647 35.755 17.6239L35.75 17.4564V12.75ZM42.1458 18.7064L37.25 13.8107V17.4564C37.25 18.1036 37.7419 18.636 38.3722 18.7L38.5 18.7064H42.1458Z"
                  class="icon-fill-dark" />
              </g>
            </g>
          </svg>
        
          <ion-label>{{ document.name }}</ion-label>
          <ion-icon slot="end" src="assets/icon/genetic-icons/download.svg"></ion-icon>
        </ion-item>
      </a>
    </div>
  </div>
</div>

<!-- TODO ALL OTHER POPUPS SHOULD BE REPLACED BY THIS GENERIC POPUP -->
<popup-dialog id="usefull.docs.component" *ngIf="sharedConfig.popupDialogShow"></popup-dialog>
