import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { QuoteRequestStatusType, SharedConfig } from '@takectrl-multiapp/svcs-share-config';
@Component({
  selector: 'side-menu-button',
  templateUrl: './side-menu-button.component.html',
  styleUrls: ['./side-menu-button.component.scss'],
})
export class SideMenuButtonComponent implements OnInit {
  QuoteRequestStatusType = QuoteRequestStatusType
  constructor(private readonly menu: MenuController, public sharedConfig: SharedConfig) {}
  showSideMenu() {
    this.menu.enable(true, 'sideMenu');
    this.menu.open('sideMenu');
  }

  ngOnInit(): void {}
}
