import { AfterViewInit, Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'delete-account-info',
  templateUrl: './delete-account-info.component.html',
  styleUrls: ['./delete-account-info.component.scss'],
})
export class DeleteAccountInfoComponent implements OnInit, AfterViewInit {
  @Output() navigateLogin: EventEmitter<unknown> = new EventEmitter<unknown>();

  constructor() {}

  async ngOnInit() {}

  async ngAfterViewInit(): Promise<void> {}

  async navLogin() {
    this.navigateLogin.emit();
  }
}
