import { Injectable } from '@angular/core';
import {
  IRbixObjectMapResponse,
  IQuestion,
  ReqResponse,
  SharedConfig,
  IRbixThreadQuery,
  IRbixMessageThread,
  QuestionType,
} from '@takectrl-multiapp/svcs-share-config';
import { HttpHeaders } from '@angular/common/http';
import { BUSNQuestionService } from './busn-question.service';
import { APIHttpService } from 'libs/svcs-ctrl-api/src/lib/services/api-http.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BUSNRbixService {
  selectedAssetUnderwritingQuestions: IQuestion[] = [];
  errorDescription: string;
  constructor(
    private readonly httpService: APIHttpService,
    private readonly sharedConfig: SharedConfig,
    public busnQuestionService: BUSNQuestionService
  ) {}

  // -- Chat --
  async sendChatMessage(message: string, rbixThread: IRbixMessageThread): Promise<ReqResponse> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('authorization', `Bearer ${this.sharedConfig.getAppCredentials.rbix_token}`);

    const body: IRbixThreadQuery = {
      thread_id: rbixThread.threadId,
      thread_message: message,
      text_to_speech: false,
      file_ids: rbixThread.fileIds,
    };

    const response: ReqResponse = await firstValueFrom(
      this.httpService.post(`${this.sharedConfig.getAppCredentials.rbix_api_url}/document/query/url`, body, headers)
    );
    return response;
  }

  // -- Recording --

  startTimer() {
    let seconds = 1,
      minutes = 0;
    SharedConfig.popupDialogSubHeader = '0:01';

    const timerInterval = setInterval(() => {
      if (SharedConfig.isRecording === false) {
        clearInterval(timerInterval); // stop if recording is false
        return;
      }
      // increment seconds
      seconds++;

      // check if one minute has passed
      if (seconds > 59) {
        minutes++;
        seconds = 0;
      }

      // add leading zero to seconds and minutes less than 10
      const sec = seconds < 10 ? `0${seconds}` : seconds;
      const min = minutes < 10 ? `0${minutes}` : minutes;

      // update timer
      SharedConfig.popupDialogSubHeader = `${min}:${sec}`;
    }, 1000);
  }

  stopRecording() {
    SharedConfig.isRecording = false;
    SharedConfig.isProcessing = true;
    SharedConfig.mediaRecorder.stop();
    SharedConfig.popupDialogHeader = 'Processing...';
    SharedConfig.popupDialogImageUrl = 'assets/icon/loader/recording_loading.svg';
    delete SharedConfig.popupDialogSubHeader;
    delete SharedConfig.popupDialogButtonOneText;
    SharedConfig.popupDialogButtonTwoText = 'Cancel';
    delete SharedConfig.popupDialogButtonOneCallBack;
    SharedConfig.popupDialogButtonTwoCallBack = this.cancelProcessing;
  }

  cancelProcessing() {
    SharedConfig.isRecording = false;
    SharedConfig.isProcessing = false;
    SharedConfig.stopTranscriptionProcessing = true;
  }

  cancelRecording() {
    //const unansweredQuestions = this.getAllAnsweredQuestions();
    if (SharedConfig.isRecording) {
      SharedConfig.mediaRecorder.stop();
    }
    SharedConfig.isRecording = false;
    SharedConfig.isProcessing = false;
    SharedConfig.stopTranscriptionProcessing = true;
    console.log('Recording Cancelled');

    //return unansweredQuestions;
  }

  startRecording() {
    SharedConfig.isRecording = true;
    this.startTimer();
    SharedConfig.mediaRecorder.start();
  }

  playAudio(base64Audio: string) {
    SharedConfig.isProcessing = false;
    // Create a new audio element
    // Set the audio source to the base64 string
    SharedConfig.audio.src = `data:audio/mpeg;base64,${base64Audio}`;
    // Set the audio type to mp3
    SharedConfig.audio.setAttribute('type', 'audio/mpeg');
    // Play the audio
    SharedConfig.audio.play();

    // Add an event listener to log the text "stopped" when the audio has stopped playing
    SharedConfig.audio.addEventListener('ended', () => {
      SharedConfig.isProcessing = false;
      // this.cd.detectChanges(); ???
      console.log('stopped');
    });
  }

  async initializeAndStartRecordingForObjectMap() {
    if (navigator.mediaDevices) {
      console.log('getUserMedia supported.');
      try {
        const constraints = { audio: true };
        let chunks = [];
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        SharedConfig.mediaRecorder = new MediaRecorder(stream);
        SharedConfig.isRecording = true;
        this.startTimer();
        SharedConfig.stopTranscriptionProcessing = false;

        SharedConfig.mediaRecorder.onstop = async (e) => {
          try {
            SharedConfig.isRecording = false;

            if (SharedConfig.stopTranscriptionProcessing) {
              console.log('cancelled() called.');
              SharedConfig.isProcessing = false;
              return;
            }
            SharedConfig.isProcessing = true;
            console.log('stop() called.');

            const blob = new Blob(chunks, { type: 'audio/wav; codecs=opus' });
            const rbixTranscription = await this.rbixTranscribeObjectMapAudio(blob);
            if (rbixTranscription && rbixTranscription.success) {
              const rbixTranscriptionData: IRbixObjectMapResponse = rbixTranscription.data;
              console.log(rbixTranscriptionData.questionText);

              if (SharedConfig.stopTranscriptionProcessing) {
                console.log('cancelled() called.');
                SharedConfig.isProcessing = false;
                return;
              }

              this.mapRbixObjectMapBackToUnderwritingQuestions(rbixTranscriptionData);
            } else {
              // something went wrong or not mapped
              console.log(`Error - ${rbixTranscription.message}`);
              console.log(`Text - ${rbixTranscription.data?.questionText}`);
            }
            // this.playAudio(convertedBase64);
            SharedConfig.isProcessing = false;
            chunks = [];
            // const audioURL = URL.createObjectURL(blob);
            console.log('recorder stopped');
            SharedConfig.isRecording = false;
            SharedConfig.isProcessing = false;
          } catch (error) {
            SharedConfig.isRecording = false;
            SharedConfig.isProcessing = false;
            console.error(`The following error occurred: ${error}`);
          }
        };

        SharedConfig.mediaRecorder.ondataavailable = (e) => {
          chunks.push(e.data);
        };
      } catch (error) {
        SharedConfig.isRecording = false;
        SharedConfig.isProcessing = false;
        console.error(`The following error occurred: ${error}`);
      }
    }

    SharedConfig.mediaRecorder.start();
  }

  async rbixTranscribeObjectMapAudio(blob: Blob): Promise<ReqResponse> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('authorization', `Bearer ${this.sharedConfig.getAppCredentials.rbix_token}`);

    const body = new FormData();
    body.append('file', blob, 'recording.wav');
    body.append('collectionId', SharedConfig.selectedAssetUnderwritingQuestions[0].underwriting_answers[0].object_name);

    const response: ReqResponse = await firstValueFrom(
      this.httpService.post(`${this.sharedConfig.getAppCredentials.rbix_api_url}/transcribe/object-map/audio`, body, headers)
    );
    return response;
  }

  async rbixTranscribeObjectMapText(message: string): Promise<ReqResponse> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('authorization', `Bearer ${this.sharedConfig.getAppCredentials.rbix_token}`);

    const body = {
      message,
      collectionId: SharedConfig.selectedAssetUnderwritingQuestions[0].underwriting_answers[0].object_name,
    };

    const response: ReqResponse = await firstValueFrom(
      this.httpService.post(`${this.sharedConfig.getAppCredentials.rbix_api_url}/transcribe/object-map/text`, body, headers)
    );

    return response;
  }

  async mapRbixObjectMapBackToUnderwritingQuestions(objectMapResponse: IRbixObjectMapResponse) {
    for (
      let selectedUnderwritingQuestionIndex = 0;
      selectedUnderwritingQuestionIndex < SharedConfig.selectedAssetUnderwritingQuestions.length;
      selectedUnderwritingQuestionIndex++
    ) {
      const selectedAssetUnderwritingQuestion = SharedConfig.selectedAssetUnderwritingQuestions[selectedUnderwritingQuestionIndex];

      for (
        let selectedUnderwritingAnswerIndex = 0;
        selectedUnderwritingAnswerIndex < selectedAssetUnderwritingQuestion.underwriting_answers.length;
        selectedUnderwritingAnswerIndex++
      ) {
        const selectedUnderwritingAnswer = selectedAssetUnderwritingQuestion.underwriting_answers[selectedUnderwritingAnswerIndex];
        const foundMap = objectMapResponse.objectMaps.find((x) => x.hashKey === selectedUnderwritingAnswer.object_field);
        if (foundMap && foundMap.answer !== undefined && !foundMap.error) {
          SharedConfig.selectedAssetUnderwritingQuestions[selectedUnderwritingQuestionIndex].underwriting_answers[
            selectedUnderwritingAnswerIndex
          ].object_answer = foundMap.answer;
          delete SharedConfig.selectedAssetUnderwritingQuestions[selectedUnderwritingQuestionIndex].underwriting_answers[
            selectedUnderwritingAnswerIndex
          ].object_answer_error;
        } else if (foundMap && foundMap.error) {
          SharedConfig.selectedAssetUnderwritingQuestions[selectedUnderwritingQuestionIndex].underwriting_answers[
            selectedUnderwritingAnswerIndex
          ].object_answer_error = foundMap.error;
          delete SharedConfig.selectedAssetUnderwritingQuestions[selectedUnderwritingQuestionIndex].underwriting_answers[
            selectedUnderwritingAnswerIndex
          ].object_answer;
        }
      }
    }

    for (let questionIndex = 0; questionIndex < SharedConfig.selectedAssetUnderwritingQuestions.length; questionIndex++) {
      const selectedAssetUnderwritingQuestion = SharedConfig.selectedAssetUnderwritingQuestions[questionIndex];

      // Remove children without true parents and copy Prefill questions in
      if (
        (selectedAssetUnderwritingQuestion.dependency &&
          selectedAssetUnderwritingQuestion.dependency.dependency_type === 'Parent' &&
          selectedAssetUnderwritingQuestion.underwriting_answers[0].object_answer === undefined) ||
        selectedAssetUnderwritingQuestion.underwriting_answers[0].object_answer === false
      ) {
        const selectedAssetToRemove = SharedConfig.selectedAssetUnderwritingQuestions.find(
          (x) =>
            x.dependency?.dependency_type === 'Child' && x.dependency?.dependency_key === selectedAssetUnderwritingQuestion.dependency?.dependency_key
        );

        if (selectedAssetToRemove)
          for (const underwriting_answer of selectedAssetToRemove.underwriting_answers) {
            delete underwriting_answer.object_answer;
          }
      }

      if (
        selectedAssetUnderwritingQuestion.underwriting_answers[0].object_answer !== undefined &&
        selectedAssetUnderwritingQuestion.underwriting_answers[0].object_answer !== null &&
        selectedAssetUnderwritingQuestion.type === QuestionType.select
      ) {
        // Get options.
        if (!selectedAssetUnderwritingQuestion.options.find((x) => x === selectedAssetUnderwritingQuestion.underwriting_answers[0].object_answer)) {
          delete selectedAssetUnderwritingQuestion.underwriting_answers[0].object_answer;
        }
      }
    }
    this.busnQuestionService.sanitizeQuestionsComparingToDBSource(SharedConfig.user);
  }

  async queryDocumentInitialize(imageUrlList: string[]) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('authorization', `Bearer ${this.sharedConfig.getAppCredentials.rbix_token}`);

    const response: ReqResponse = await firstValueFrom(
      this.httpService.post(
        `${this.sharedConfig.getAppCredentials.rbix_api_url}/file/upload/url/user`,
        {
          fileUrls: imageUrlList,
        },
        headers
      )
    );
    return response;
  }

  async assistant(message: string, rbixThread: IRbixMessageThread): Promise<ReqResponse> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('authorization', `Bearer ${this.sharedConfig.getAppCredentials.rbix_token}`);

    const body: IRbixThreadQuery = {
      thread_id: rbixThread.threadId,
      thread_message: message,
      text_to_speech: false,
      file_ids: rbixThread.fileIds,
    };

    const response: ReqResponse = await firstValueFrom(
      this.httpService.post(`${this.sharedConfig.getAppCredentials.rbix_api_url}/assistant`, body, headers)
    );
    return response;
  }

  async queryDocumentUrl(message: string, rbixThread: IRbixMessageThread): Promise<ReqResponse> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('authorization', `Bearer ${this.sharedConfig.getAppCredentials.rbix_token}`);

    const body: IRbixThreadQuery = {
      thread_id: rbixThread.threadId,
      thread_message: message,
      text_to_speech: false,
      file_ids: rbixThread.fileIds,
    };

    const response: ReqResponse = await firstValueFrom(
      this.httpService.post(`${this.sharedConfig.getAppCredentials.rbix_api_url}/document/query/url`, body, headers)
    );
    return response;
  }

  getAllAnsweredQuestions() {
    return this.selectedAssetUnderwritingQuestions.filter((x) => {
      const notAllAnswered = x.underwriting_answers.find((a) => a.object_answer === null || a.object_answer === undefined);
      if (!notAllAnswered) return x;
    });
  }

  getAllUnansweredQuestions() {
    return this.selectedAssetUnderwritingQuestions.filter((x) => {
      const notAllAnswered = x.underwriting_answers.find((a) => a.object_answer === null || a.object_answer === undefined);
      if (notAllAnswered) return x;
    });
  }

  isQuestionMapAvailable(): boolean {
    if (!this.sharedConfig.appSettings.ai_question_map_enabled || this.sharedConfig.appSettings.ai_user_access === 'DISABLED') return false;

    if (this.sharedConfig.appSettings.ai_user_access === 'ALLOW_ALL') return true;

    if (this.sharedConfig.appSettings.ai_user_access === 'INVITE_ONLY') {
      if (this.sharedConfig.appSettings.ai_user_access_list.includes(this.sharedConfig?.firebaseUser?.email)) {
        return true;
      } else return false;
    }
  }

  isScheduleInterpreterAvailable(): boolean {
    if (!this.sharedConfig.appSettings.ai_schedule_interpreter_enabled || this.sharedConfig.appSettings.ai_user_access === 'DISABLED') return false;

    if (this.sharedConfig.appSettings.ai_user_access === 'ALLOW_ALL') return true;

    if (this.sharedConfig.appSettings.ai_user_access === 'INVITE_ONLY') {
      if (this.sharedConfig.appSettings.ai_user_access_list.includes(this.sharedConfig?.firebaseUser?.email)) {
        return true;
      } else return false;
    }
  }

  isObjectMapAvailable(): boolean {
    if (!this.sharedConfig.appSettings.ai_object_map_enabled || this.sharedConfig.appSettings.ai_user_access === 'DISABLED') return false;

    if (this.sharedConfig.appSettings.ai_user_access === 'ALLOW_ALL') return true;

    if (this.sharedConfig.appSettings.ai_user_access === 'INVITE_ONLY') {
      if (this.sharedConfig.appSettings.ai_user_access_list.includes(this.sharedConfig?.firebaseUser?.email)) {
        return true;
      } else return false;
    }
  }
}
