import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { AlertButton, AlertController } from '@ionic/angular';
import { IQuestion, SharedConfig } from '@takectrl-multiapp/svcs-share-config';

@Component({
  selector: 'input-check-list-single-select-search',
  templateUrl: './input-check-list-single-select-search.component.html',
  styleUrls: ['./input-check-list-single-select-search.component.scss'],
})
export class InputCheckListSingleSelectSearchComponent implements OnInit {
  @Input() question: IQuestion;
  @Input() showHeading: boolean;
  @Input() readOnly: boolean;
  @Output() answerQuestion: EventEmitter<IQuestion> = new EventEmitter();

  filteredQuestionList: string[];
  keyDownCurrentIndex = 0;
  keyDownPressIndex = 0;
  searchInput = '';
  @ViewChild('optionsInput') optionsInput;

  constructor(private readonly alertController: AlertController, public sharedConfig: SharedConfig) {}

  ngOnInit(): void {
    this.filteredQuestionList = this.question.options;
    if (this.question.underwriting_answers[0].object_answer) {
      this.searchInput = this.question.underwriting_answers[0].object_answer;
      this.filteredQuestionList = this.filterOptions(this.searchInput);
    }
  }

  filterOptions(input: string): string[] {
    const lowerInput = input.toLowerCase();
    return this.question.options.filter((option) => option && option.toLowerCase().includes(lowerInput));
  }

  answerValue(answer: string) {
    if (answer.toLocaleLowerCase() === 'yes' || answer.toLocaleLowerCase() === 'no') {
      this.question.underwriting_answers[0].object_answer = answer.toLocaleLowerCase() === 'yes';
    } else {
      this.question.underwriting_answers[0].object_answer = answer;
    }
    this.answerQuestion.emit(this.question);
  }

  searchKeyDown(e: Event) {
    const searchValue = e.target['value'];

    if (!searchValue || searchValue.length <= 0) {
      this.filteredQuestionList = this.question.options;
    } else {
      this.filteredQuestionList = this.filterOptions(searchValue);
    }
  }

  async selectAnswer(answer: string) {
    this.question.underwriting_answers[0].object_answer = answer;
    this.answerQuestion.emit(this.question);
  }

  clearInput() {
    this.searchInput = '';
    this.filteredQuestionList = this.filterOptions(this.searchInput);
  }

  async showGenericAlert(header: string, subHeader: string, buttons: Array<string | AlertButton>) {
    const callAlert = await this.alertController.create({
      header,
      subHeader,
      buttons,
    });
    await callAlert.present();
  }
}
