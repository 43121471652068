  export enum AssetType {
    Vehicles = 'vehicles',
    Motorcycles = 'motorcycles',
    Caravans = 'caravans',
    Buildings = 'buildings',
    Contents = 'contents',
    AllRisks = 'all_risks',
    SpecifiedItems = 'specified_items',
    Other = 'other',
    UserPersonalDetails = 'users.personal_details',
    UserInsuranceHistory = 'users.insurance_history',
    Vaps = 'vaps',
  }