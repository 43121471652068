<!-- THIS IS THE NEW GENERIC POPUP THAT SHOULD REPLACE ALL THE OTHER POPUPS -->

<div class="backdrop"></div>
<div class="popup-standard limit-width-on-desktop-float" [ngClass]="{ 'popup-dailog-bottom': setBottom }">
  <!-- Close button for the popup -->
  <div class="close-popup" *ngIf="sharedConfig.popupDialogButtonTopCloseShow" (click)="closePopup()">
    <p>X</p>
  </div>
  <div class="text-center">
    <h2 class="h2-bold" style="color: black">{{ sharedConfig.popupDialogHeader }}</h2>
  </div>
  <div class="sub-header">
    <p style="color: black">{{ sharedConfig.popupDialogSubHeader }}</p>
  </div>
  <img
    *ngIf="sharedConfig.popupDialogImageUrl && (sharedConfig.isRecording || sharedConfig.isProcessing)"
    [src]="sharedConfig.popupDialogImageUrl"
    alt="image"
    style="display: block; width: 100%; height: 200px"
  />

  <ion-list *ngIf="sharedConfig.popupDialogTextAreaPlaceholder && !sharedConfig.isRecording && !sharedConfig.isProcessing">
    <div class="input-section">
      <ion-item mode="ios">
        <!-- <ion-label position="floating">Type your request here </ion-label> -->
        <ion-input autofocus="true" (keydown)="nextOnEnter($event)" type="textarea" (ionInput)="onTextAreaChange($event)"></ion-input>
      </ion-item>
    </div>
  </ion-list>

  <!-- <ion-button
    *ngIf="!sharedConfig.isRecording && sharedConfig.popupDialogButtonThreeText && !sharedConfig.isProcessing"
    (click)="buttonThreeAction()"
    style="padding-bottom: 40px"
    mode="ios"
    class="secondary-button button-record"
  >
    <ion-icon slot="icon-only" src="assets/icon/toolbar-icons/lyrics-microphone-music-svgrepo-com.svg" size="small"></ion-icon>
    <small> {{ sharedConfig.popupDialogButtonThreeText }}</small>
  </ion-button> -->

  <div style="padding-bottom: 40px" style="text-align: center; margin: 20px 0">
    <label style="color: black"> {{ sharedConfig.popupDialogTextAreaResultsValue }}</label>
  </div>

  <div class="buttons-wrapper">
    <div *ngIf="sharedConfig.popupDialogShowThreeButtons">
      <ion-button mode="ios" class="secondary-button mt-4" (click)="buttonOneAction()">{{ sharedConfig.popupDialogButtonOneText }} </ion-button>
      <ion-button mode="ios" class="secondary-button mt-4" (click)="buttonTwoAction()">{{ sharedConfig.popupDialogButtonTwoText }}</ion-button>
      <ion-button mode="ios" class="primary-button mt-4" (click)="buttonThreeAction()">{{ sharedConfig.popupDialogButtonThreeText }}</ion-button>
    </div>
    <div *ngIf="!sharedConfig.popupDialogShowThreeButtons">
      <ion-button *ngIf="sharedConfig.popupDialogButtonOneText" mode="ios" class="primary-button" (click)="buttonOneAction()">{{
        sharedConfig.popupDialogButtonOneText
      }}</ion-button>
      <ion-button *ngIf="sharedConfig.popupDialogButtonTwoText" mode="ios" class="secondary-button" (click)="buttonTwoAction()">
        {{ sharedConfig.popupDialogButtonTwoText }}
      </ion-button>
    </div>
  </div>
</div>
