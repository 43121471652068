import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IAppCredentials } from '../interfaces/app.interface';

import { IUser } from '../interfaces/user.interface';
import firebase from 'firebase/compat/app';
import { IEnvironment } from '../interfaces/portal.interface';

@Injectable({
  providedIn: 'root',
})
export class SharedConfigBrokerService {
  // User-related state
  private userState = {
    user: new BehaviorSubject<IUser | null>(null),
    userEmail: new BehaviorSubject<string>(''),
    userIsOTPEnabled: new BehaviorSubject<string>(''),
  };

  // Auth-related state
  private authState = {
    firebaseUser: new BehaviorSubject<firebase.User | null>(null),
    firebaseUserEmail: new BehaviorSubject<string>(''),
    firebaseUserKey: new BehaviorSubject<string>(''),
    appCredentials: new BehaviorSubject<IAppCredentials | null>(null),
  };

  // App-related state
  private appState = {
    environment: new BehaviorSubject<IEnvironment | null>(null),
  };
  // User-related observable getters and setters
  user$ = this.userState.user.asObservable();
  userEmail$ = this.userState.userEmail.asObservable();

  setUser(value: IUser) {
    this.userState.user.next(value);
  }

  setUserEmail(value: string) {
    this.userState.userEmail.next(value);
  }
  setUserIsOTPEnabled(value: string) {
    this.userState.userIsOTPEnabled.next(value);
  }

  // Auth-related observable getters and setters
  firebaseUser$ = this.authState.firebaseUser.asObservable();
  firebaseUserEmail$ = this.authState.firebaseUserEmail.asObservable();
  firebaseUserKey$ = this.authState.firebaseUserKey.asObservable();
  appCredentials$ = this.authState.appCredentials.asObservable();

  setFirebaseUser(value: firebase.User | null) {
    this.authState.firebaseUser.next(value);
  }
  setFirebaseUserEmail(value: string) {
    this.authState.firebaseUserEmail.next(value);
  }
  setFirebaseUserKey(value: string) {
    this.authState.firebaseUserKey.next(value);
  }
  setAppCredentials(value: IAppCredentials | null) {
    this.authState.appCredentials.next(value);
  }

  // Environment observable getters and setters
  environment$ = this.appState.environment.asObservable();

  setEnvironment(value: IEnvironment) {
    this.appState.environment.next(value);
  }

  // Clear SharedConfig method adapted to use nested state
  clearSharedConfig() {
    this.userState.user.next(null);
    this.userState.userEmail.next('');
    this.userState.userIsOTPEnabled.next('');

    this.authState.firebaseUser.next(null);
    this.authState.firebaseUserEmail.next('');
    this.authState.firebaseUserKey.next('');
    this.authState.appCredentials.next(null);
  }
}
