<div class="input-wrapper">
    <ion-item mode="ios">
        <ion-label *ngIf="showHeading" position="floating">{{question.underwriting_answers[0].place_holder}}</ion-label>
        <ion-input type="text" readonly="{{readOnly}}" maxlength="4" class="bold-text" (ionInput)="search($event)" value="{{question.underwriting_answers[0].object_answer}}"></ion-input>
    </ion-item>
    <p class="error-message" *ngIf="error">{{error}}</p>
    <ion-list *ngIf="showResults" class="search-result-list">        
        <ion-item mode="ios" lines="none" *ngFor="let occupation of apiRes">
            <ion-label (click)="setSearchResult(occupation)">{{occupation.english}}</ion-label>
        </ion-item>
    </ion-list>
</div>

<!-- TODO REFACTOR: THIS SHOULD BE MOVED TO GENERIC SEARCH  - THIS IS NOT A "SINGLE SEARCH" THIS IS OCCUPATION SEARCH. THIS IS NOT GENERIC -->