<div class="wrapper-no-height limit-width-on-desktop-float">
  <div class="heading">
    <h2 class="h2-bold">Set a new password</h2>
    <p>Choose a password that you're not using on other accounts.</p>
  </div>
  <div class="input-section">
    <div>
      <ion-item mode="ios">
        <ion-label position="floating">Old password</ion-label>
        <ion-input type="password" [(ngModel)]="oldPass"></ion-input>
      </ion-item>

      <ion-item mode="ios">
        <ion-label position="floating">New password</ion-label>
        <ion-input type="password" [(ngModel)]="newPass" (onChange)="checkPasswords()" name="passwordNew"> </ion-input>
      </ion-item>

      <ion-item mode="ios">
        <ion-label position="floating">Confirm password</ion-label>
        <ion-input type="password" [(ngModel)]="confirmPass" name="passwordConfirm"></ion-input>
      </ion-item>
      <div *ngIf="!checkPasswords()">
        <p class="error-message">Password does not match!</p>
      </div>
    </div>
  </div>
  <div class="button-rows">
    <ion-button mode="ios" [disabled]="!confirmPass || !checkPasswords() || !oldPass" class="primary-button" (click)="changeDetails()">
      <span class="button-inner"> Confirm </span>
    </ion-button>
    <ion-button mode="ios" class="secondary-button" (click)="cancelScreen()"> Cancel </ion-button>
  </div>
</div>

<popup-with-dismiss-button *ngIf="sharedConfig.showPopupWithDismissButton"></popup-with-dismiss-button>

<!-- TODO ALL OTHER POPUPS SHOULD BE REPLACED BY THIS GENERIC POPUP -->
<popup-dialog id="change.password.component" id="home.component" *ngIf="sharedConfig.popupDialogShow"></popup-dialog>
