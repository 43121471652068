import { IVehicle } from './../../../../../svcs-share-config/src/lib/interfaces/asset.interface';
import { BUSNQuoteScheduleService } from '@takectrl-multiapp/svcs-core-business';
import {
  IBankingDetails,
  SharedConfig,
  IQuoteConvertResponse,
  SharedFormulaService,
  IKPChilliPremiumTier,
  SharedDialogService,
  QuoteRequestStatusType,
} from '@takectrl-multiapp/svcs-share-config';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import '@lottiefiles/lottie-player';
declare const dataLayer: any[];

@Component({
  selector: 'quote-accept',
  templateUrl: './quote-accept.component.html',
  styleUrls: ['./quote-accept.component.scss'],
})
export class QuoteAcceptComponent implements OnInit {
  @Output()
  navigateSideMenuChangeEmail: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() navigateQuoteComplete: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() navigateHomeWallet: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output()
  navigateQuoteComparisonOverall: EventEmitter<any> = new EventEmitter<any>();
  @Output() navigateQuoteHome: EventEmitter<any> = new EventEmitter<any>();
  clientName = this.sharedConfig.user.personal_details.name + ' ' + this.sharedConfig.user.personal_details.surname;
  policy_number = SharedConfig.selectedQuote.insurer_id === "KINGPRICE" || SharedConfig.selectedQuote.insurer_id === "MIWAY" ? 'TBA' : SharedConfig.selectedQuote.quote_nr.replace("Q", '');
  effective_date = this.sharedConfig.quoteRequest.cover_start_date.toString().split('T')[0].substring(0, 10);
  insurer_id = SharedConfig.selectedQuote.insurer_id;
  // Varables is for client-card details: END
  public fullClientName = this.sharedConfig.appClientDetail?.display_name_full;
  public fsb_number = this.sharedConfig.appSettings.fsb_number;
  chilliPremiums: Array<IKPChilliPremiumTier> = [];
  loader = this.loading.create();
  total_premium = SharedConfig.selectedQuote.total_premium;
  public banking_details: IBankingDetails;
  public bankQueryParamsForAcceptScreen: any;
  public abTotal = 0;
  private readonly warningsText = [];
  public trackerPrem = 0;
  public miWayPremtotal = 0;
  public actualEffectiveDate: any;
  public sasriaTotal = 0;
  public selectedQuote = SharedConfig.selectedQuote;
  deductionDateOrdinal: string;
  showDisclosurePopup = false;
  showFetchingQuotesAnimation = false;
  showErrorAnimation = false;
  showKingPriceAnimation = false;
  constructor(
    private readonly busnQuoteScheduleService: BUSNQuoteScheduleService,
    private readonly loading: LoadingController,
    public sharedConfig: SharedConfig,
    private readonly busnThirdPartyService: BUSNThirdPartyService,
    private readonly sharedFormulaService: SharedFormulaService,
    public sharedDialogService: SharedDialogService
  ) {}

  ngOnInit(): void {
    dataLayer.push({
      event: 'page_show',
      screenPath: 'quote-accept',
      screenName: 'quote-accept',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });

    if (SharedConfig.quoteBankingDetails) {
      this.banking_details = SharedConfig.quoteBankingDetails;
      this.deductionDateOrdinal = this.sharedFormulaService.getDateOrdinal(this.banking_details.collection_day).day_ordinal_display_name;
    }

    const total_premium = SharedConfig.selectedQuote.total_premium;
    const coverDate = new Date(this.sharedConfig.quoteRequest.cover_start_date);
    if (coverDate < new Date()) {
      this.actualEffectiveDate = Date.now();
    } else {
      this.actualEffectiveDate = this.sharedConfig.quoteRequest.cover_start_date;
    }

    if (SharedConfig.selectedQuote.assistance_benefits) {
      SharedConfig.selectedQuote.assistance_benefits.forEach((ab) => {
        if (ab.premium) {
          this.abTotal += ab.premium;
        }
      });
    }
    this.miWayPremtotal = total_premium - SharedConfig.selectedQuote.broker_fee;
    this.sasriaTotal = SharedConfig.selectedQuote.sasria;

    if (SharedConfig.selectedQuote.quote_vehicles) {
      SharedConfig.selectedQuote.quote_vehicles.forEach((vehicle: IVehicle) => {
        if (vehicle.tracker_lead) {
          const leadDescription = vehicle.tracker_lead.description;
          const asset = vehicle.tracker_lead.asset_description;
          this.warningsText.push({
            leadDescription: leadDescription,
            asset: asset,
          });
        }
      });
    }
    console.log(this.warningsText);
  }

  goToWallet() {
    SharedConfig.showFullScreen = false;
    this.navigateQuoteHome.emit();
  }

  async acceptDisclosurePopup() {
    this.showDisclosurePopup = true;
    SharedConfig.showFullScreen = true;
  }

  navFromKPtoWallet() {
    SharedConfig.showFullScreen = false;
    SharedConfig.schedulesActiveInitialCount++;
    SharedConfig.onTrackPopupHeader = 'Heads up';
    SharedConfig.onTrackPopupSubheading = 'We will add the assets you want to insure with King Price as soon as they are processed.';
    this.navigateHomeWallet.emit();
  }

  closeDisclosurePopup() {
    this.showDisclosurePopup = false;
    SharedConfig.showFullScreen = false;
  }

  async confirmQuoteRequest() {
    if (this.insurer_id === 'KINGPRICE') {
      await this.confirmKingPriceQuoteRequest();
    } else if (this.insurer_id === 'MIWAY') {
      await this.confirmMiwayQuoteRequest();
    } else {
      await this.confirmDefaultQuoteRequest();
    }
  }

  async confirmDefaultQuoteRequest() {
    if (this.sharedConfig.quoteRequest.status === 'LIVE') {
      this.sharedDialogService.showPopupDialog({
        header: 'Something went wrong!',
        subHeader: 'This quote is no longer available. Please contact us if you have any questions',
        buttonOneText: 'OK',
        buttonOneCallback: this.sharedDialogService.hidePopupDialog,
      });
    } else {
      this.showFetchingQuotesAnimation = true;
      SharedConfig.showFullScreen = true;
      this.sharedConfig.quoteRequest.accepted = new Date().getTime();
      this.sharedConfig.quoteRequest.banking_details = this.banking_details;
      await this.busnQuoteScheduleService.updateQuoteRequest(this.sharedConfig.quoteRequest);

      try {
        const quoteConvertResponse: IQuoteConvertResponse = await this.busnQuoteScheduleService.quoteConvert(
          this.sharedConfig.quoteRequest.id,
          SharedConfig.selectedQuote.quote_nr,
          SharedConfig.quoteBankingDetails
        );

        console.warn('CONVERT RESPONSE:', quoteConvertResponse);

        if (quoteConvertResponse && quoteConvertResponse.success) {
          SharedConfig.quoteConvertResponse = quoteConvertResponse;
          this.busnQuoteScheduleService.updateQuoteRequest(this.sharedConfig.quoteRequest);
          this.busnThirdPartyService.logEvent('action_quote_accepted', {
            currencyCode: 'ZAR',
            broker_fee: SharedConfig.selectedQuote.broker_fee,
            commission: SharedConfig.selectedQuote.commission,
            rating_excess: SharedConfig.selectedQuote.rating_excess,
            total_premium: SharedConfig.selectedQuote.total_premium,
            total_sum_insured: SharedConfig.selectedQuote.total_sum_insured,
          });
          SharedConfig.schedulesActiveInitialCount++;
          SharedConfig.scheduleHasBeenSetToActive = true;
          SharedConfig.onTrackPopupHeader = 'On track';
          SharedConfig.onTrackPopupSubheading =
            'Your policy has been activated and you are now covered! We are busy updating your app to reflect your new policy. We should be done in a minute.';
            this.navigateQuoteComplete.emit();
          SharedConfig.showFullScreen = false;
        } else {
          this.showFetchingQuotesAnimation = false;
          SharedConfig.showFullScreen = true;
          this.showErrorAnimation = true;
        }
      } catch (error) {
        this.showFetchingQuotesAnimation = false;
        SharedConfig.showFullScreen = true;
        console.error(error);

        this.showErrorAnimation = true;
        console.error(`Error converting policy: ${error.message}`);
      }
    }
  }

  async confirmMiwayQuoteRequest() {
    this.sharedConfig.quoteRequest.accepted = new Date().getTime();
    this.sharedConfig.quoteRequest.banking_details = this.banking_details;
    await this.busnQuoteScheduleService.updateQuoteRequest(this.sharedConfig.quoteRequest);

    this.sharedConfig.setShowCustomLoader(false)
    this.showFetchingQuotesAnimation = true;
    SharedConfig.showFullScreen = true;
    this.busnQuoteScheduleService.convertMiway(this.sharedConfig.quoteRequest.id).then(async (apiQuoteConvertResponse) => {
      console.log(apiQuoteConvertResponse);
      if (apiQuoteConvertResponse) {
        this.busnThirdPartyService.logEvent('action_quote_accepted', {
          currencyCode: 'ZAR',
          broker_fee: SharedConfig.selectedQuote.broker_fee,
          commission: SharedConfig.selectedQuote.commission,
          rating_excess: SharedConfig.selectedQuote.rating_excess,
          total_premium: SharedConfig.selectedQuote.total_premium,
          total_sum_insured: SharedConfig.selectedQuote.total_sum_insured,
        });
        SharedConfig.quoteConvertResponse = apiQuoteConvertResponse;
        SharedConfig.schedulesActiveInitialCount++;
        SharedConfig.scheduleHasBeenSetToActive = true;
        SharedConfig.onTrackPopupHeader = 'On track';
        SharedConfig.onTrackPopupSubheading =
          'Your policy has been activated and you are now covered! We are busy updating your app to reflect your new policy. We should be done in a minute.';
        this.navigateQuoteComplete.emit();
        SharedConfig.showFullScreen = false;
      } else {
        this.showFetchingQuotesAnimation = false;
        SharedConfig.showFullScreen = true;
        this.showErrorAnimation = true;
      }
    }).catch(error => {
      console.log('Miway error : ' + error)
      this.showFetchingQuotesAnimation = false;
      SharedConfig.showFullScreen = true;
      this.showErrorAnimation = true;
    });
  }

  goBackToCompare() {
    SharedConfig.showFullScreen = false;
    this.showErrorAnimation = false;
    this.navigateQuoteComparisonOverall.emit();
  }

  showIntercom() {
    this.busnThirdPartyService.intercomDisplayLauncher();
  }

  async confirmKingPriceQuoteRequest() {
    try {
      this.sharedConfig.setShowCustomLoader(true)
      await this.busnQuoteScheduleService.kingPriceConfirm(this.sharedConfig.quoteRequest.id).then(
        async (apiQuoteConvertResponse) => {
          console.log(apiQuoteConvertResponse)
          if (apiQuoteConvertResponse) {
            this.busnThirdPartyService.logEvent('business_king_price_user_activate_successful');
            this.busnThirdPartyService.logEvent('action_quote_accepted', {
              currencyCode: 'ZAR',
              broker_fee: SharedConfig.selectedQuote.broker_fee,
              commission: SharedConfig.selectedQuote.commission,
              rating_excess: SharedConfig.selectedQuote.rating_excess,
              total_premium: SharedConfig.selectedQuote.total_premium,
              total_sum_insured: SharedConfig.selectedQuote.total_sum_insured,
            });
            this.busnThirdPartyService.logEvent('action_quote_banking_details_completed', {
              timestamp: new Date(),
              quote_request_id: this.sharedConfig.quoteRequest.id,
            });
            console.log('Quote request ID sent to API');
            this.sharedConfig.quoteRequest.status =  QuoteRequestStatusType.WaitingOnInsurer;
            // await this.busnQuoteScheduleService.updateQuoteRequestStatus(this.sharedConfig.quoteRequest, this.sharedConfig.quoteRequest.status);
            // (await this.loader).dismiss();
            this.sharedConfig.setShowCustomLoader(false)
            SharedConfig.showFullScreen = true;
            this.showKingPriceAnimation = true;
          } else {
            this.sharedConfig.setShowCustomLoader(false)
            SharedConfig.showFullScreen = true;
            this.showErrorAnimation = true;
          }
        },
        async (error) => {
          this.handleKingPriceError(error);
        }
      );
    } catch (error) {
      console.error(`Error converting King Price: ${  error}`);
    }
  }

  async handleKingPriceError(error: any) {
    this.sharedConfig.setShowCustomLoader(false)
    if (error.error.code === 'AlreadyActivated') {
      this.busnThirdPartyService.logEvent('business_king_price_user_already_activated_error');

      SharedConfig.showFullScreen = true;
      this.showErrorAnimation = true;
    } else {
      let message = 'An Error occured';
      if (error && error.error) {
        message = error.error.exception;
      }
      this.busnThirdPartyService.logEvent('business_king_price_general_error', {
        message: message,
      });
      SharedConfig.showFullScreen = true;
      this.showErrorAnimation = true;
    }
  }
}
