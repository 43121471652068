import { BUSNQuoteScheduleService } from '@takectrl-multiapp/svcs-core-business';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
  ICasaCountries,
  ICasaEmploymentStatuses,
  ICasaNationalities,
  ICasaOccupations,
  ICasaSourceOfIncomes,
  SharedConfig,
  SharedDialogService,
} from '@takectrl-multiapp/svcs-share-config';
import { LoadingController, AlertController } from '@ionic/angular';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
declare const dataLayer: unknown[];

@Component({
  selector: 'quote-banking-details-casa',
  templateUrl: './quote-banking-details-casa.component.html',
  styleUrls: ['./quote-banking-details-casa.component.scss'],
})
export class QuoteBankingDetailsCasaComponent implements OnInit {
  @Output() navigateQuoteAccept: EventEmitter<unknown> = new EventEmitter<unknown>();
  public corList: Array<ICasaCountries>;
  public cobList: Array<ICasaCountries>;
  public empStatusList: Array<ICasaEmploymentStatuses>;
  public occupationList: Array<ICasaOccupations>;
  public incomeList: Array<ICasaSourceOfIncomes>;
  public nationalityList: Array<ICasaNationalities>;
  public cobCode: string;
  public cobDesc: string;
  public corCode: string;
  public corDesc: string;
  public nationalityDesc: string;
  public nationalityCode: string;
  public sourceOfIncomeDesc: string;
  public sourceOfIncomeCode: string;
  public empCode: string;
  public empDesc: string;
  public occupation_code: string;
  public occupationDesc: string;
  showSelectionPopup = false;
  selectPopupHeader: string;
  loopThrough: string;
  constructor(
    private readonly busnQuoteScheduleService: BUSNQuoteScheduleService,
    public sharedConfig: SharedConfig,
    public loadingCtrl: LoadingController,
    public alertController: AlertController,
    public thirdpartyService: BUSNThirdPartyService,
    public sharedDialogService: SharedDialogService
  ) {
    this.populateCasalists();
  }

  ngOnInit(): void {
    dataLayer.push({
      event: 'page_show',
      screenPath: 'quote-banking-details-casa',
      screenName: 'quote-banking-details-casa',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }

  openPopup(loop: string, header: string) {
    const intervalId = setInterval(() => {
      SharedConfig.showFullScreen = true;
      this.showSelectionPopup = true;
      this.selectPopupHeader = header;
      this.loopThrough = loop;
      clearInterval(intervalId);
    }, 100);
  }

  closePopup() {
    SharedConfig.showFullScreen = false;
    this.showSelectionPopup = false;
    this.selectPopupHeader = '';
    this.loopThrough = '';
  }

  changeCOB(cob: string) {
    this.cobList.forEach((item) => {
      if (item.casaCountryDesc === cob) {
        this.cobCode = item.casaCountryCode;
        this.cobDesc = cob;
        this.closePopup();
      }
    });
  }

  changeCOR(cor: string) {
    this.corList.forEach((item) => {
      if (item.casaCountryDesc === cor) {
        this.corCode = item.casaCountryCode;
        this.corDesc = cor;
        this.closePopup();
      }
    });
  }

  changeNationality(nationality: string) {
    this.nationalityList.forEach((item) => {
      if (item.casaNationalityDesc === nationality) {
        this.nationalityCode = item.casaNationalityCode;
        this.nationalityDesc = nationality;
        this.closePopup();
      }
    });
  }
  changeSourceOfIncome(soi: string) {
    this.incomeList.forEach((item) => {
      if (item.casaSourceOfIncomeDesc === soi) {
        this.sourceOfIncomeCode = item.casaSourceOfIncomeCode;
        this.sourceOfIncomeDesc = soi;
        this.closePopup();
      }
    });
  }

  changeEmploymentStatus(employmentStatus: string) {
    this.empStatusList.forEach((item) => {
      if (item.casaEmploymentStatusDesc === employmentStatus) {
        this.empCode = item.casaEmploymentStatusCode;
        this.empDesc = employmentStatus;
        this.closePopup();
      }
    });
  }

  changeOccupation(occupation: string) {
    this.occupationList.forEach((item) => {
      if (item.casaOccupationDesc === occupation) {
        this.occupation_code = item.casaOccupationCode;
        this.occupationDesc = occupation;
        this.closePopup();
      }
    });
  }

  async populateCasalists() {
    this.sharedConfig.setShowCustomLoader(true)
    await this.busnQuoteScheduleService
      .getCasaList()
      .then((res) => {
        this.corList = res.casaCountries;
        this.cobList = res.casaCountries;
        this.empStatusList = res.casaEmploymentStatuses;
        this.incomeList = res.casaSourceOfIncomes;
        this.occupationList = res.casaOccupations;
        this.nationalityList = res.casaNationalities;
        this.corDesc = 'SOUTH AFRICA';
        this.cobDesc = 'SOUTH AFRICA';
        this.nationalityDesc = 'SOUTH AFRICAN';

        if (this.sharedConfig.user.personal_details.country_of_birth_code && this.sharedConfig.user.personal_details.country_of_birth_description) {
          this.cobCode = this.sharedConfig.user.personal_details.country_of_birth_code;
          this.cobDesc = this.sharedConfig.user.personal_details.country_of_birth_description;
        }
        if (
          this.sharedConfig.user.personal_details.country_of_residence_code &&
          this.sharedConfig.user.personal_details.country_of_residence_description
        ) {
          this.corCode = this.sharedConfig.user.personal_details.country_of_residence_code;
          this.corDesc = this.sharedConfig.user.personal_details.country_of_residence_description;
        }
        if (this.sharedConfig.user.personal_details.casa_nationality_code && this.sharedConfig.user.personal_details.casa_nationality_description) {
          this.nationalityCode = this.sharedConfig.user.personal_details.casa_nationality_code;
          this.nationalityDesc = this.sharedConfig.user.personal_details.casa_nationality_description;
        }
        if (
          this.sharedConfig.user.personal_details.casa_source_of_income_code &&
          this.sharedConfig.user.personal_details.casa_source_of_income_description
        ) {
          this.sourceOfIncomeCode = this.sharedConfig.user.personal_details.casa_source_of_income_code;
          this.sourceOfIncomeDesc = this.sharedConfig.user.personal_details.casa_source_of_income_description;
        }
        if (
          this.sharedConfig.user.personal_details.casa_employment_status_code &&
          this.sharedConfig.user.personal_details.casa_employment_status_description
        ) {
          this.empCode = this.sharedConfig.user.personal_details.casa_employment_status_code;
          this.empDesc = this.sharedConfig.user.personal_details.casa_employment_status_description;
        }
        if (this.sharedConfig.user.personal_details.casa_occupation_code && this.sharedConfig.user.personal_details.casa_occupation_description) {
          this.occupation_code = this.sharedConfig.user.personal_details.casa_occupation_code;
          this.occupationDesc = this.sharedConfig.user.personal_details.casa_occupation_description;
        }
        this.sharedConfig.setShowCustomLoader(false)
      })
      .catch(async (error) => {
        this.sharedConfig.setShowCustomLoader(false)
        if (error) {
          this.sharedDialogService.showPopupDialog({
            header: 'Something went wrong!',
            subHeader: "There was an issue returning verification details from the insurer. Let's chat about it to see what we can do.",
            buttonOneText: 'OK',
            buttonOneCallback: this.sharedDialogService.hidePopupDialog,
          });
        }
      });
  }

  submitCasaDetails() {
    this.changeCOB(this.cobDesc);
    this.changeCOR(this.corDesc);
    this.changeNationality(this.nationalityDesc);
    try {
      this.busnQuoteScheduleService.updateUserCasa(
        this.corCode,
        this.corDesc,
        this.cobCode,
        this.cobDesc,
        this.nationalityCode,
        this.nationalityDesc,
        this.sourceOfIncomeCode,
        this.sourceOfIncomeDesc,
        this.empCode,
        this.empDesc,
        this.occupation_code,
        this.occupationDesc
      );

      this.navigateQuoteAccept.emit();
    } catch (error) {
      console.error(error);
    }
  }
}
