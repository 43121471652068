import { Component, OnInit, NgZone } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { BUSNUserService } from '@takectrl-multiapp/svcs-core-business';
import { SharedConfig, SharedDialogService } from '@takectrl-multiapp/svcs-share-config';
import { PageType } from 'libs/svcs-share-config/src/lib/types/page.type';
declare const dataLayer: unknown[];

@Component({
  selector: 'change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss'],
})
export class ChangeEmailComponent implements OnInit {
  newEmail: string;
  currentEmail: string;
  errorText: string;
  showCustomPopup = false;
  public enableButton = false;
  passwordField: string;
  constructor(
    public userService: BUSNUserService,
    public alertController: AlertController,
    public thirdpartyService: BUSNThirdPartyService,
    public sharedDialogService: SharedDialogService,
    private readonly zone: NgZone,
    private readonly navCtrl: NavController,
    public sharedConfig: SharedConfig
  ) {
    this.confirmEmailChanged = this.confirmEmailChanged.bind(this);
    SharedConfig.selectedPage = PageType.changeEmail;
  }

  ngOnInit(): void {
    dataLayer.push('page_show', {
      url: 'change-email',
      title: 'change-email',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });

    this.currentEmail = this.sharedConfig.user.personal_details.email;
  }

  checkEmail(e: Event) {
    const emailRegex =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailInput: string = e.target['value'];
    if (!emailRegex.test(emailInput)) {
      this.errorText = 'Invalid email';
      this.enableButton = false;
    } else {
      this.errorText = null;
      this.enableButton = true;
    }
  }

  async changeDetails() {
    if (this.newEmail !== '') {
      this.showCustomPopup = true;
      SharedConfig.showFullScreen = true;
    }
  }

  cancelScreen() {
    this.zone.run(() => {
      this.navCtrl.navigateRoot('home/home-wallet');
    });
  }

  async confirmEmailChange() {
    this.sharedConfig.setShowCustomLoader(true)
    this.userService.checkEmailForSpam(this.newEmail.trim()).then(async (isSafe) => {
      if (isSafe.pass_validation) {
        const changeMailRes = await this.userService.changeEmailAddress(this.newEmail);
        this.dismissCustomPopup();
        this.sharedConfig.setShowCustomLoader(false)

        if (changeMailRes.success) {
          this.sharedConfig.user.personal_details.email = this.newEmail;
          this.currentEmail = this.newEmail;
          this.sharedDialogService.showPopupDialog({
            header: 'Updated',
            subHeader: 'Your email has been change to ' + this.newEmail,
            buttonOneText: 'Ok',
            buttonOneCallback: this.confirmEmailChanged,
          });
        } else {
          this.sharedConfig.setShowCustomLoader(false)
          this.sharedDialogService.showPopupDialog({
            header: 'Something went wrong',
            subHeader: 'Please try again later',
            buttonTwoText: 'Close',
            buttonTwoCallback: this.sharedDialogService.hidePopupDialog,
          });
        }
      } else {
        this.sharedConfig.setShowCustomLoader(false)
        const callAlert = await this.alertController.create({
          header: 'Uh oh!',
          subHeader: 'Your email appears to be invalid; before proceeding, be sure to verify or provide an alternative email address.',
          buttons: [
            {
              text: 'Close',
            },
          ],
        });
        await callAlert.present();
      }
    });
  }

  confirmEmailChanged() {
    this.sharedDialogService.hidePopupDialog();
    this.zone.run(() => {
      this.navCtrl.navigateRoot('home/home-wallet');
    });
  }

  dismissCustomPopup() {
    this.showCustomPopup = false;
    SharedConfig.showFullScreen = false;
  }
}
