<ion-buttons *ngIf="busnRbixService.isObjectMapAvailable()" slot="end">
  <ion-icon
    slot="icon-only"
    *ngIf="!sharedConfig.isRecording && !sharedConfig.isProcessing"
    (click)="startRecording()"
    class="chat-icon"
    src="assets/icon/toolbar-icons/lyrics-microphone-music-svgrepo-com.svg"
  ></ion-icon>
  <ion-icon
    slot="icon-only"
    *ngIf="sharedConfig.isRecording && !sharedConfig.isProcessing"
    (click)="stopRecording()"
    class="chat-icon"
    src="assets/icon/loader/recording.svg"
    ></ion-icon>
  <ion-icon slot="icon-only" *ngIf="sharedConfig.isProcessing" class="chat-icon" src="assets/icon/toolbar-icons/loading-svgrepo-com.svg"></ion-icon>
  <div class="badge" *ngIf="sharedConfig.intercomBadgeCount > 0"></div>
</ion-buttons>
