import {
  BUSNQuestionService,
  BUSNQuoteScheduleService,
  BUSNRbixService,
  BUSNRenewalService,
  BUSNUserService,
} from '@takectrl-multiapp/svcs-core-business';
import { LoadingController } from '@ionic/angular';
import { Component, Input, EventEmitter, Output, OnInit, AfterViewInit } from '@angular/core';
import {
  SharedConfig,
  AssetType,
  ISchedule,
  SharedDialogService,
  QuoteRequestType,
  QuoteRequestStatusType,
  IRenewal,
} from '@takectrl-multiapp/svcs-share-config';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import '@lottiefiles/lottie-player';
import { AuthStorageService } from '@takectrl-multiapp/svcs-ctrl-api';
import { PageType } from 'libs/svcs-share-config/src/lib/types/page.type';
import { ServiceWorkerService } from '@takectrl-multiapp/svcs-core';
import { ICtrlResponse } from 'libs/svcs-ctrl-api/src/lib/interfaces/api.interface';

declare const dataLayer: any[];

@Component({
  selector: 'wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss'],
})
export class WalletComponent implements OnInit, AfterViewInit {
  @Input() asset_type: AssetType;
  @Output() navigateForwardToPage: EventEmitter<unknown> = new EventEmitter();
  @Output() navigateRootToToPage: EventEmitter<unknown> = new EventEmitter();
  @Output() navigateAssetPage: EventEmitter<unknown> = new EventEmitter();
  @Output() navigateRenewalPage: EventEmitter<unknown> = new EventEmitter();
  @Output() navigatePersonalDetails: EventEmitter<unknown> = new EventEmitter();
  @Output() navigateEndorsementAcceptPage: EventEmitter<unknown> = new EventEmitter();
  @Output()
  navigateQuotePage: EventEmitter<unknown> = new EventEmitter();
  @Output() navigateQuoteNew: EventEmitter<unknown> = new EventEmitter<unknown>();
  showSHit = false;
  showAn = true;
  password: string;
  kpPopup = true;
  private readonly loader = this.loadingController.create();
  public pendingEndorsementSchedule: ISchedule;
  public endorsementUpdate: boolean;
  public enableSetPasswordButton = false;
  @Input() showRegisterSuccess: boolean;
  animationSrc = '../assets/lottie-animations/successful-register.json';
  sub_heading = 'First up, let’s complete the rest of your personal details.';
  isAllAssetsComplete: boolean;
  popupRestartQuote = false;
  popupHeader: string;
  popupMessage: string;
  QuoteRequestStatusType = QuoteRequestStatusType;
  QuoteRequestType = QuoteRequestType;
  AssetType = AssetType;

  constructor(
    public sharedConfig: SharedConfig,
    public authStorageService: AuthStorageService,
    private readonly loadingController: LoadingController,
    private readonly busnRenewalService: BUSNRenewalService,
    public busnThirdPartyService: BUSNThirdPartyService,
    private readonly sharedDialogService: SharedDialogService,
    private readonly serviceWorkerService: ServiceWorkerService,
    public busnRbixService: BUSNRbixService,
    public busnQuestionService: BUSNQuestionService,
    public businUserService: BUSNUserService,
    public busnQuoteScheduleService: BUSNQuoteScheduleService
  ) {
    SharedConfig.selectedPage = PageType.wallet;
    this.openIntercomChat = this.openIntercomChat.bind(this);
    this.logout = this.logout.bind(this);
    this.newEndorsement = this.newEndorsement.bind(this);
    this.newQuoteRequest = this.newQuoteRequest.bind(this);
  }

  async ngOnInit(): Promise<void> {
    dataLayer.push({
      event: 'page_show',
      screenPath: 'wallet',
      screenName: 'wallet',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
    this.checkIfWaitingOnQuoteToBeAccepted();
    this.checkIfQuoteRequestsAreInAndReadyToCompare();
    this.openChat = this.openChat.bind(this);
    this.newEndorsement = this.newEndorsement.bind(this);
  }

  checkIfQuoteRequestsAreInAndReadyToCompare() {
    const per = this.sharedConfig?.userPermissions?.can_quote
    const quoteRequest =this.sharedConfig.quoteRequest
    if (
      this.sharedConfig?.userPermissions?.can_quote &&
      ((this.sharedConfig.quoteRequest) &&
        (this.sharedConfig.quoteRequest?.status !== QuoteRequestStatusType.Submitted && this.sharedConfig.quoteRequest?.status !== QuoteRequestStatusType.WaitingOnInsurer &&
          this.sharedConfig.quoteRequest?.type === QuoteRequestType.Quote))
    ) {
      this.navigateRootToToPage.emit('home/home-quote');
    }
  }

  async ngAfterViewInit() {
    await this.checkPremUpdate();
    if (
      (SharedConfig.showOnTrackPopup && SharedConfig.scheduleHasBeenSetToActive) ||
      SharedConfig.quoteRequest?.status === QuoteRequestStatusType.Pending
    ) {
      this.sharedDialogService.showPopupDialog({
        header: 'On track',
        subHeader:
          'Your policy has been activated and you are now covered! We are busy updating your app to reflect your new policy. We should be done in a minute.',
        buttonOneText: 'Contact us',
        buttonOneCallback: this.openIntercomChat,
        buttonTwoText: 'Logout',
        buttonTwoCallback: this.logout,
      });
    } else {
      this.sharedDialogService.hidePopupDialog();
    }

    this.checkForUserRenewals();

  }

  async checkPremUpdate() {
    if (this.sharedConfig?.schedulesActive?.length > 0 && !SharedConfig.didCheckPremiumChange) {
      let result = await this.busnQuoteScheduleService.checkForUpdatedPremiums();
      if (result) {
        SharedConfig.didCheckPremiumChange = true;
      }
    }
  }

  async compFloatingAction() {
    this.navigateRootToToPage.emit('ctrl-center');
  }

  showFloatingButton() {
    const accessList = this.sharedConfig.appSettings.ai_user_access_list;
    if (accessList.includes(this.sharedConfig?.firebaseUser?.email)) {
      return true;
    } else return false;
  }

  openIntercomChat() {
    this.busnThirdPartyService.intercomDisplayLauncher();
  }

  async logout() {
    this.sharedDialogService.hidePopupDialog();
    this.busnThirdPartyService.firebaseSignOut();
    this.navigateRootToToPage.emit('public-landing');
  }

  closeKpPopup() {
    this.kpPopup = false;
  }

  chatToUs() {
    this.kpPopup = false;
    this.busnThirdPartyService.intercomDisplayLauncher();
  }

  async cancelQuoteRequest() {
    this.sharedConfig.setShowCustomLoader(true)
    await this.busnQuoteScheduleService.cancelQuoteRequest();
    this.sharedConfig.setShowCustomLoader(false)
  }

  checkIfWaitingOnQuoteToBeAccepted() {
    if (
      this.sharedConfig.schedulesActive &&
      SharedConfig.schedulesActiveInitialCount !== this.sharedConfig.schedulesActive.length &&
      !SharedConfig.scheduleHasBeenSetToActive
    ) {
      this.sharedDialogService.showPopupDialog({
        header: SharedConfig.onTrackPopupHeader,
        subHeader: SharedConfig.onTrackPopupSubheading,
        buttonOneText: 'OK',
        buttonOneCallback: this.sharedDialogService.hidePopupDialog,
      });
    }
    if (
      this.sharedConfig.schedulesActive &&
      SharedConfig.schedulesActiveInitialCount !== this.sharedConfig.schedulesActive.length &&
      SharedConfig.scheduleHasBeenSetToActive
    ) {
      SharedConfig.showOnTrackPopup = true;
    }
  }

  async checkForUserRenewals() {
    // (await this.loader).present();
    if (!SharedConfig.didViewRenewal) {
      this.busnRenewalService.checkForRenewal().then(async (renewal: ICtrlResponse<Array<IRenewal>>) => {
        SharedConfig.didViewRenewal = true;
        if (renewal && renewal.success && renewal?.results?.length > 0) {
          this.navigateRenewalPage.emit({ renewal: renewal });
        } else {
          console.log('There are no renewals to fetch');
        }
      });
    }
  }

  navFromRefPage(page: string) {
    if (page === 'personal-details') {
      this.navigatePersonalDetails.emit();
    } else {
      this.navigateRootToToPage.emit(page);
    }
  }

  getAssetTypeDetails(asset_type: AssetType): [number, string] {
    switch (asset_type) {
      case AssetType.Contents: {
        return [this.sharedConfig.schedulesPremiumDetails.contents_premium, AssetType.Contents];
      }
      case AssetType.AllRisks: {
        return [
          this.sharedConfig.schedulesPremiumDetails.all_risks_premium + this.sharedConfig.schedulesPremiumDetails.specified_items_premium,
          'all risk',
        ];
      }
      case AssetType.Buildings: {
        return [this.sharedConfig.schedulesPremiumDetails.buildings_premium, AssetType.Buildings];
      }
      case AssetType.Vehicles: {
        return [this.sharedConfig.schedulesPremiumDetails.vehicles_premium, AssetType.Vehicles];
      }
      case AssetType.Other: {
        return [this.sharedConfig.schedulesPremiumDetails.otherPremium, 'any other'];
      }
      case AssetType.Motorcycles: {
        return [this.sharedConfig.schedulesPremiumDetails.motorcycles_premium, AssetType.Motorcycles];
      }
      case AssetType.Caravans: {
        return [this.sharedConfig.schedulesPremiumDetails.caravans_premium, 'caravan/trailer'];
      }
      case AssetType.Vaps: {
        return [this.sharedConfig.schedulesPremiumDetails.vaps_premium, AssetType.Vaps];
      }
    }
  }

  assetSelected(asset_type: AssetType) {
    const assetSelectDetails = this.getAssetTypeDetails(asset_type);
    if (
      (this.sharedConfig.schedulesActive && this.sharedConfig.schedulesActive[0]?.status === 'PENDING_ENDORSE') ||
      this.sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingOnInsurer ||
      this.sharedConfig.quoteRequest?.status === QuoteRequestStatusType.Submitted ||
      this.sharedConfig.quoteRequest?.status === QuoteRequestStatusType.Pending
    ) {
      this.sharedDialogService.showPopupDialog({
        header: 'We are busy updating your policy.',
        buttonOneText: 'OK',
        buttonOneCallback: this.sharedDialogService.hidePopupDialog,
      });
    } else if (assetSelectDetails && assetSelectDetails[0] > 0) {
      this.navigateAssetPage.emit(asset_type);
    } else {
      this.sharedDialogService.showPopupDialog({
        header: `You don't have ${assetSelectDetails[1]} cover yet.`,
        subHeader: 'Add it to your policy by clicking on the “Insure something else” button at the bottom of this page.',
        buttonOneText: 'OK',
        buttonOneCallback: this.sharedDialogService.hidePopupDialog,
      });
    }
  }

  async startNewOrContinueEndorsement() {
    if (
      SharedConfig.quoteRequest &&
      SharedConfig?.quoteRequest?.type === QuoteRequestType.EndorsementAdd &&
      this.sharedConfig.schedulesActive.length === 1 &&
      (SharedConfig.quoteRequest.status === QuoteRequestStatusType.WaitingOnQuotes ||
        SharedConfig.quoteRequest.status === QuoteRequestStatusType.Submitted)
    ) {
      this.sharedDialogService.showPopupDialog({
        header: 'Hold on',
        subHeader: 'You have an open request to update your policy. Adding an asset will cancel this request, would you like to continue?',
        buttonOneText: 'Yes',
        buttonOneCallback: this.newEndorsement,
        buttonTwoText: 'No',
        buttonTwoCallback: this.sharedDialogService.hidePopupDialog,
      });
    } else if (this.sharedConfig.schedulesActive.length > 1) {
      this.sharedConfig.setShowCustomLoader(true)
      if (this.sharedConfig.schedulesActive.length > 1) {
        this.sharedDialogService.showPopupDialog({
          header: 'Hold on',
          subHeader: "We see that you have more than one policy with us. Let's chat to determine where to add the new asset(s).",
          buttonOneText: 'Contact us',
          buttonOneCallback: this.openChat,
          buttonTwoText: 'Cancel',
          buttonTwoCallback: this.sharedDialogService.hidePopupDialog,
        });
        this.sharedConfig.setShowCustomLoader(false)
      }
    } else if (this.sharedConfig.schedulesActive.find((x) => x.insurer_id === 'MIWAY' || x.insurer_id === 'KINGPRICE')) {
      this.sharedDialogService.showPopupDialog({
        header: 'Hold on',
        subHeader: 'Unfortunately your insurer does not allow changes to your policy via the app yet. Please chat to us for assistance.',
        buttonOneText: 'Contact us',
        buttonOneCallback: this.openChat,
        buttonTwoText: 'Cancel',
        buttonTwoCallback: this.sharedDialogService.hidePopupDialog,
      });
      this.sharedConfig.setShowCustomLoader(false)
    } else {
      SharedConfig.hideHeader = false;
      this.busnThirdPartyService.logEvent('action_endorsement');
      try {
        if (this.sharedConfig.quoteRequest && this.sharedConfig.quoteRequest.status === QuoteRequestStatusType.Open) {
          this.navigateQuoteNew.emit();
        } else {
          // OPTION TO STILL DO : SELECT YOUR POLICIES HERE => IF MORE THAN 1
          // give option to get new quotes OR to add
          if (this.sharedConfig?.userPermissions?.can_quote && this.sharedConfig?.userPermissions?.add_asset_to_policy) {
            this.sharedDialogService.showPopupDialog({
              header: 'Insure something else',
              subHeader: 'Add an asset to your current policy or request a new quote from other insurers',
              buttonOneText: 'Add assets',
              buttonTwoText: 'Get new quotes',
              buttonTopCloseShow: true,
              buttonOneCallback: this.newEndorsement,
              buttonTwoCallback: this.newQuoteRequest,
            });
          } else if (!this.sharedConfig?.userPermissions?.can_quote && this.sharedConfig?.userPermissions?.add_asset_to_policy) {
            this.sharedDialogService.showPopupDialog({
              header: 'Insure something else',
              subHeader: 'Add an asset to your current policy.',
              buttonOneText: 'Add assets',
              buttonTopCloseShow: true,
              buttonOneCallback: this.newEndorsement,
            });
          } else if (this.sharedConfig?.userPermissions?.can_quote && !this.sharedConfig?.userPermissions?.add_asset_to_policy) {
            this.sharedDialogService.showPopupDialog({
              header: 'Insure something else',
              subHeader: 'Request a new quote from other insurers',
              buttonTwoText: 'Get new quotes',
              buttonTopCloseShow: true,
              buttonTwoCallback: this.newQuoteRequest,
            });
          }

          //  this.sharedConfig.setShowCustomLoader(true)
        }
      } catch (error) {
        this.sharedDialogService.showPopupDialog({
          header: 'Something went wrong!',
          subHeader: error.message,
          buttonOneText: 'OK',
          buttonOneCallback: this.sharedDialogService.hidePopupDialog,
        });
        this.sharedConfig.setShowCustomLoader(false)
      }
    }
  }

  newQuoteRequest() {
    this.sharedConfig.setShowCustomLoader(true)
    this.sharedDialogService.hidePopupDialog();
    this.busnQuoteScheduleService.createNewQuoteRequest().then(async (quoteRequest) => {
      this.sharedConfig.setShowCustomLoader(false)
      this.navigateQuoteNew.emit();
    });
  }

  newEndorsement() {
    this.sharedConfig.setShowCustomLoader(true)
    this.sharedDialogService.hidePopupDialog();
    this.busnQuoteScheduleService.createNewEndorsementRequest(QuoteRequestType.EndorsementAdd).then(async (quoteRequest) => {
      this.sharedConfig.setShowCustomLoader(false)
      this.navigateQuoteNew.emit();
    });
  }

  navToHomeQuote() {
    this.navigateForwardToPage.emit('home/home-quote');
  }

  navToHomeQuoteList() {
    this.navigateForwardToPage.emit('quote-list-retrieved-summary');
  }

  navQuotePage() {
    this.navigateQuotePage.emit();
  }

  async viewEndorsementAcceptPage() {
    this.navigateEndorsementAcceptPage.emit();
  }

  openChat() {
    this.busnThirdPartyService.intercomDisplayLauncher();
    this.sharedDialogService.hidePopupDialog();
  }
}
