import { NavController, LoadingController, IonContent, IonTextarea, AlertController } from '@ionic/angular';
import { Component, OnInit, NgZone, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { IRbixDocumentQueryResponse, IRbixMessage, SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import { BUSNRbixService, BUSNUserService } from '@takectrl-multiapp/svcs-core-business';
import { ICtrlFileResponse } from 'libs/svcs-ctrl-api/src/lib/interfaces/api.interface';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'ctrl-center',
  templateUrl: './ctrl-center.component.html',
  styleUrls: ['./ctrl-center.component.scss'],
})
export class CtrlCenterComponent implements OnInit, AfterViewInit {
  @Output() navigateToPage: EventEmitter<string> = new EventEmitter<string>();
  @Output() messageSend: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild(IonContent, { static: false }) content: IonContent;
  @ViewChild('messageInput', { static: false }) messageInput: IonTextarea;

  documents: ICtrlFileResponse[];
  filesUrls: string[] = [];

  constructor(
    public navCtrl: NavController,
    public zone: NgZone,
    public loadingCtrl: LoadingController,
    public route: Router,
    private sanitizer: DomSanitizer,
    public busnRbixService: BUSNRbixService,
    public busnUserService: BUSNUserService,
    private readonly alertCtrl: AlertController,
    public sharedConfig: SharedConfig
  ) {}

  ngOnInit(): void {
    const fileIds = SharedConfig.rbixMessageThread?.fileIds;

    if (!fileIds || fileIds.length <= 0) {
      this.initialize();
    }
  }
  ngAfterViewInit() {}

  ngAfterViewChecked(): void {}

  initialize(): void {
    SharedConfig.rbixMessageThread = {
      date: new Date(),
      messages: [
        {
          key: '1',
          from: 'system',
          date: new Date(),
          text:  `Hi ${SharedConfig.user.personal_details.name}. I'm your digital Ctrl assistant, and I run things here at the Ctrl Center😊. Feel free to ask me any question regarding your policy(s) or insurance. 🚗🏍️🏡`,
        },
      ],
    };

    
    const documentsBeingQueriedOn: string[] = [];

    this.busnUserService.getDocuments().then(async (docs) => {
      for (const doc of docs) {
        if (doc.url !== 'https://ctrl-web-external.s3-eu-west-1.amazonaws.com/Ctrl-Need-to-know-v1.pdf' && !doc.name.includes('Policy Wording')) {
          this.filesUrls.push(doc.url);
          documentsBeingQueriedOn.push(doc.name);
        }
      }

      const fileResponse = await this.busnRbixService.queryDocumentInitialize(this.filesUrls);
      if (fileResponse.success) {
        this.sharedConfig.rbixMessageThread.fileIds = fileResponse.data;

        if (documentsBeingQueriedOn.length > 0) {
          SharedConfig.rbixMessageThread.messages.push({
            key: '2',
            from: 'system',
            date: new Date(),
            text: `Fetching schedules...`,
          });
    
          for (let index = 0; index < documentsBeingQueriedOn.length; index++) {
            const element = documentsBeingQueriedOn[index];
            SharedConfig.rbixMessageThread.messages.push({
              key: (index + 3).toString(),
              from: 'system',
              date: new Date(),
              text: `📄 ${element}`,
            });
          }
        }
      } else {
        const alertNotFound = await this.alertCtrl.create({
          header: 'Something went wrong',
          message: 'Please try again later',
          buttons: [
            {
              text: 'OK',
              handler: () => {},
            },
          ],
          backdropDismiss: false,
        });
        alertNotFound.present();
        return;
      }
    });

    

    
  }

  private scrollToBottom(): void {}

  formatMessage(text: string): SafeHtml {
    // Remove the reference tags
    let formattedText = text.replace(/【\d+:\d+†source】/g, '');
  
    // Rest of your formatting code...
    formattedText = formattedText
      .replace(/\n\n/g, '<br><br>')
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
      .replace(/^### (.*$)/gm, '<h3>$1</h3>')
      .replace(/^#### (.*$)/gm, '<h4>$1</h4>')
      .replace(/^\- (.*$)/gm, '<li>$1</li>')
      .replace(/<\/li>\n<li>/g, '</li><li>');
  
    formattedText = '<div>' + formattedText + '</div>';
    
    return this.sanitizer.bypassSecurityTrustHtml(formattedText);
  }

  async sendMessage(newMessage: string): Promise<void> {
    SharedConfig.rbixIsProcessingThread = true;
    if (newMessage.trim()) {
      const message: IRbixMessage = {
        key: Date.now().toString(),
        from: 'user',
        date: new Date(),
        text: newMessage.trim(),
      };
      this.sharedConfig.rbixMessageThread.messages.push(message);

      await this.busnRbixService
        .assistant(newMessage, this.sharedConfig.rbixMessageThread)
        .then((threadResult) => {
          if (threadResult.success) {
            const threadMessage = threadResult.data as IRbixDocumentQueryResponse;
            this.sharedConfig.rbixMessageThread.threadId = threadMessage.threadId;

            const message: IRbixMessage = {
              key: Date.now().toString(),
              from: 'system',
              date: new Date(),
              text: threadResult.data['responseReturnText'],
            };
            this.sharedConfig.rbixMessageThread.messages.push(message);
          } else {
            alert('something went wrong');
            console.error(threadResult.data);
          }
          SharedConfig.rbixIsProcessingThread = false;
        })
        .catch((exception) => {
          SharedConfig.rbixIsProcessingThread = false;
          alert('something went wrong');
          console.error(exception.message);
        });
    }
    this.scrollToBottom();
  }
}
