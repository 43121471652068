<ion-tabs class="bg-white">
  <ion-tab-bar class="limit-width-on-desktop-float" [ngClass]="{ hide: sharedConfig.showFooterTabs !== true }" mode="ios" slot="bottom">
    <ion-tab-button tab="home-wallet">
      <ion-icon class="my-cover-icon" src="assets/icon/tabs-icons/footer-my-cover.svg"></ion-icon>
      <ion-label>My cover</ion-label>
    </ion-tab-button>
    <!-- <ion-tab-button tab="home-quote">
         <ion-icon class="quote-icon" src="assets/icon/tabs-icons/footer-quote.svg"></ion-icon>
         <ion-label>Quote</ion-label>
      </ion-tab-button> -->
    <ion-tab-button tab="home-claim">
      <ion-icon class="claim-icon" src="assets/icon/tabs-icons/footer-claim.svg"></ion-icon>
      <ion-label>Claim</ion-label>
    </ion-tab-button>
    <ion-tab-button tab="home-emergency">
      <ion-icon class="emergency-icon" src="assets/icon/tabs-icons/footer-call.svg"></ion-icon>
      <ion-label>Emergency</ion-label>
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>

<!-- TODO ALL OTHER POPUPS SHOULD BE REPLACED BY THIS GENERIC POPUP -->
<popup-dialog  id="home.component" *ngIf="sharedConfig.popupDialogShow"></popup-dialog>
