

export enum UserDataSubscriptionType {
  addresses = 'addresses',
  allRisks = 'all_risks',
  buildings = 'buildings',
  caravans = 'caravans',
  contents = 'contents',
  motorcycles = 'motorcycles',
  quoteRequests = 'quote_requests',
  quotes = 'quotes',
  schedules = 'schedules',
  specifiedItems = 'specifiedItems',
  users = 'users',
  usersAccountDetails = 'users_account_details',
  usersPersonalDetails = 'users_personal_details',
  vehicles = 'vehicles',
}

export enum UserQuoteState {
  none = 'NONE',
  openQuoteRequest = 'OPEN_QUOTE_REQUEST',
  waitingForQuotes = 'WAITING_FOR_QUOTES',
}