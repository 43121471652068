import { Component, Input, OnInit } from '@angular/core';
import '@lottiefiles/lottie-player';
import { SharedConfig } from '@takectrl-multiapp/svcs-share-config';
@Component({
  selector: 'up-next-animation',
  templateUrl: './up-next-animation.component.html',
  styleUrls: ['./up-next-animation.component.scss'],
})
export class UpNextAnimationComponent implements OnInit {
  @Input() animationName: string;
  @Input() displayText: string;
  @Input() upNextSectionText: string;
  constructor(public sharedConfig: SharedConfig) {}

  ngOnInit(): void {
    this.showUpNextComp();
  }

  showUpNextComp() {
    let counter = 3;
    const intervalId = setInterval(() => {
      counter = counter - 1;
      if (counter === 0) {
        SharedConfig.showUpNext = false;
        clearInterval(intervalId);
      }
    }, 1000);
  }
}
