<ion-buttons *ngIf="sharedConfig?.firebaseUser?.email === sharedConfig?.firebaseUserEmail && sharedConfig.userPermissions?.intercom" slot="end">
  <ion-icon slot="icon-only" (click)="openIntercom()" class="chat-icon" src="assets/icon/toolbar-icons/header-chat.svg"></ion-icon>
  <div class="badge" *ngIf="sharedConfig.intercomBadgeCount > 0"></div>
</ion-buttons>
<ion-buttons *ngIf="sharedConfig.userPermissions?.phone_broker" slot="end" (click)="presentPopover($event)">
  <ion-icon slot="icon-only" class="chat-icon icon-stroke-dark" src="assets/icon/genetic-icons/call-icon.svg">
  </ion-icon>
</ion-buttons>



<ion-popover #popover [isOpen]="isOpen" (didDismiss)="isOpen = false">
  <ng-template>
    <ion-content class="ion-padding">
      <ion-list>
        <ion-item mode="ios" (click)="callBroker()">
          <ion-icon slot="start" class="chat-icon icon-stroke-dark" src="assets/icon/genetic-icons/call-icon.svg">
          </ion-icon>
          <ion-label><b>Contact Broker</b></ion-label>
        </ion-item>
      </ion-list>
      <!-- <ion-list *ngFor="let insurer of sharedConfig.appClientDetail.insurerContactDetails">
        <ion-item (click)="call(insurer)" mode="ios">
          <ion-icon slot="start" class="chat-icon icon-stroke-dark" src="assets/icon/genetic-icons/call-icon.svg">
          </ion-icon>
          <ion-label><b>{{insurer.name}}</b></ion-label>
        </ion-item>
      </ion-list> -->
      <ion-list>
        <a href={{href_string}}>
          <ion-item mode="ios">
            <ion-icon slot="start" class="chat-icon icon-stroke-dark" name="mail-outline">
            </ion-icon>
            <ion-label><b>Technical Support</b></ion-label>
          </ion-item>
        </a>
      </ion-list>
    </ion-content>
  </ng-template>
</ion-popover>