import { ClientEnum } from '../enums/app.enum';

export const AppClientDetails = {
  CTRL: {
    domain: 'za.co.takectrl.app',
    display_name: 'Ctrl',
    display_name_full: 'Ctrl',
    client_name_key: 'ctrl',
    version: '5.3.66',
    enum_name: ClientEnum.CTRL,
    static_wallet_page_image: false,
    static_refer_friend_page_image: false,
    show_cover_start_date: true,
    app_client_specific_text_settings: {
      added_benefits_text: 'King Price and MiWay',
      quote_complete_header_text: 'Welcome to the CTRL family',
      quote_complete_button_text: 'OK, thanks',
    },
    app_client_specific_colours: {
      quote_percentage_circle_recommended_color: '#00B761',
      quote_percentage_circle_not_recommended_color_svga_element: '#044dff',
      titleColor: '#0d1317',
    },
    landing_videos: {
      step_one_video_url: 'https://ctc-shared-external.s3.eu-west-1.amazonaws.com/videos/ctrl/stepOne.mp4',
      step_two_video_url: 'https://ctc-shared-external.s3.eu-west-1.amazonaws.com/videos/ctrl/stepTwo.mp4',
      step_three_video_url: 'https://ctc-shared-external.s3.eu-west-1.amazonaws.com/videos/ctrl/stepThree.mp4',
      step_four_video_url: 'https://ctc-shared-external.s3.eu-west-1.amazonaws.com/videos/ctrl/stepFour.mp4',
    },
  },
  MOMENTUM: {
    domain: 'za.co.consultinsure',
    domainReversed: 'consultinsure.co.za',
    display_name: 'Momentum',
    display_name_full: 'Momentum Consult',
    client_name_key: 'momentum',
    version: '5.3.66',
    enum_name: ClientEnum.MOMENTUM,
    static_wallet_page_image: true,
    static_refer_friend_page_image: false,
    show_cover_start_date: true,
    app_client_specific_text_settings: {
      added_benefits_text: 'King Price and MiWay',
      quote_complete_header_text: 'Welcome to the family',
      quote_complete_button_text: 'I agree',
    },
    app_client_specific_colours: {
      quote_percentage_circle_recommended_color: '#00B761',
      quote_percentage_circle_not_recommended_color_svga_element: '#e11b22',
      titleColor: '#4d4d4f',
    },
    landing_videos: {
      step_one_video_url: 'https://ctc-shared-external.s3.eu-west-1.amazonaws.com/videos/momentum/stepOne.mp4',
      step_two_video_url: 'https://ctc-shared-external.s3.eu-west-1.amazonaws.com/videos/momentum/stepTwo.mp4',
      step_three_video_url: 'https://ctc-shared-external.s3.eu-west-1.amazonaws.com/videos/momentum/stepThree.mp4',
      step_four_video_url: 'https://ctc-shared-external.s3.eu-west-1.amazonaws.com/videos/momentum/stepFour.mp4',
    },
  },
  OWRM: {
    domain: '',
    display_name: 'Owrm Digital',
    display_name_full: 'Owrm Digital',
    client_name_key: 'owrm',
    version: '5.3.66',
    enum_name: ClientEnum.OWRM,
    static_wallet_page_image: true,
    static_refer_friend_page_image: true,
    show_cover_start_date: true,
    app_client_specific_text_settings: {
      added_benefits_text: 'King Price and MiWay',
      quote_complete_header_text: 'Welcome to the family',
      quote_complete_button_text: 'I agree',
    },
    app_client_specific_colours: {
      quote_percentage_circle_recommended_color: '#00B761',
      quote_percentage_circle_not_recommended_color_svga_element: '#a8d717',
      titleColor: '#0d1317',
    },
    landing_videos: {
      step_one_video_url: 'https://ctc-shared-external.s3.eu-west-1.amazonaws.com/videos/owrm/stepOne.mp4',
      step_two_video_url: 'https://ctc-shared-external.s3.eu-west-1.amazonaws.com/videos/owrm/stepTwo.mp4',
      step_three_video_url: 'https://ctc-shared-external.s3.eu-west-1.amazonaws.com/videos/owrm/stepThree.mp4',
      step_four_video_url: 'https://ctc-shared-external.s3.eu-west-1.amazonaws.com/videos/owrm/stepFour.mp4',
    },
  },
  BLUPIXL_INDWE: {
    domain: 'za.co.blupixl.app',
    reverseDomain: 'app.blupixl.co.za',
    display_name: 'BluPixl',
    display_name_full: 'Indwe Risk Services',
    client_name_key: 'blupixl',
    version: '5.3.66',
    enum_name: ClientEnum.BLUPIXL_INDWE,
    static_wallet_page_image: false,
    static_refer_friend_page_image: false,
    show_cover_start_date: true,
    app_client_specific_text_settings: {
      added_benefits_text: 'MiWay',
      quote_complete_header_text: 'Welcome to the family',
      quote_complete_button_text: 'I agree',
    },
    app_client_specific_colours: {
      quote_percentage_circle_recommended_color: '#00B761',
      quote_percentage_circle_not_recommended_color_svga_element: '#0066a2',
      titleColor: '#0d1317',
    },
    landing_videos: {
      step_one_video_url: 'https://ctc-shared-external.s3.eu-west-1.amazonaws.com/videos/blupixl/stepOne.mp4',
      step_two_video_url: 'https://ctc-shared-external.s3.eu-west-1.amazonaws.com/videos/blupixl/stepTwo.mp4',
      step_three_video_url: 'https://ctc-shared-external.s3.eu-west-1.amazonaws.com/videos/blupixl/stepThree.mp4',
      step_four_video_url: 'https://ctc-shared-external.s3.eu-west-1.amazonaws.com/videos/blupixl/stepFour.mp4',
    },
  },
  TRACKER: {
    domain: '',
    display_name: 'Tracker',
    display_name_full: 'Tracker Financial Services',
    client_name_key: 'tracker',
    version: '5.3.66',
    enum_name: ClientEnum.TRACKER,
    static_wallet_page_image: true,
    static_refer_friend_page_image: true,
    show_cover_start_date: true,
    app_client_specific_text_settings: {
      added_benefits_text: 'MiWay',
      quote_complete_header_text: 'Welcome to the family',
      quote_complete_button_text: 'I agree',
    },
    app_client_specific_colours: {
      quote_percentage_circle_recommended_color: '#00B761',
      quote_percentage_circle_not_recommended_color_svga_element: '#ffcc00',
      titleColor: '#000000',
    },
    landing_videos: {
      step_one_video_url: 'https://ctc-shared-external.s3.eu-west-1.amazonaws.com/videos/tracker/stepOne.mp4',
      step_two_video_url: 'https://ctc-shared-external.s3.eu-west-1.amazonaws.com/videos/tracker/stepTwo.mp4',
      step_three_video_url: 'https://ctc-shared-external.s3.eu-west-1.amazonaws.com/videos/tracker/stepThree.mp4',
      step_four_video_url: 'https://ctc-shared-external.s3.eu-west-1.amazonaws.com/videos/tracker/stepFour.mp4',
    },
  },
  NEXUS: {
    domain: '',
    display_name: 'Nexus',
    display_name_full: 'Nexus',
    client_name_key: 'nexus',
    version: '5.3.66',
    enum_name: ClientEnum.NEXUS,
    static_wallet_page_image: false,
    static_refer_friend_page_image: false,
    show_cover_start_date: true,
    app_client_specific_text_settings: {
      added_benefits_text: 'King Price and MiWay',
      quote_complete_header_text: 'Welcome to the Nexus family',
      quote_complete_button_text: 'OK, thanks',
    },
    app_client_specific_colours: {
      quote_percentage_circle_recommended_color: '#00B761',
      quote_percentage_circle_not_recommended_color_svga_element: '#171d4c',
      titleColor: '#0d1317',
    },
    landing_videos: {
      step_one_video_url: 'https://ctc-shared-external.s3.eu-west-1.amazonaws.com/videos/nexus/stepOne.mp4',
      step_two_video_url: 'https://ctc-shared-external.s3.eu-west-1.amazonaws.com/videos/nexus/stepTwo.mp4',
      step_three_video_url: 'https://ctc-shared-external.s3.eu-west-1.amazonaws.com/videos/nexus/stepThree.mp4',
      step_four_video_url: 'https://ctc-shared-external.s3.eu-west-1.amazonaws.com/videos/nexus/stepFour.mp4',
    },
  },
  CLEVERINSURE: {
    domain: '',
    display_name: 'Clever Insure',
    display_name_full: 'Clever Insure',
    client_name_key: 'cleverinsure',
    version: '5.3.66',
    enum_name: ClientEnum.NEXUS,
    static_wallet_page_image: false,
    static_refer_friend_page_image: false,
    show_cover_start_date: true,
    app_client_specific_text_settings: {
      added_benefits_text: 'King Price and MiWay',
      quote_complete_header_text: 'Welcome to the Clever Insure family',
      quote_complete_button_text: 'OK, thanks',
    },
    app_client_specific_colours: {
      quote_percentage_circle_recommended_color: '#00B761',
      quote_percentage_circle_not_recommended_color_svga_element: '#171d4c',
      titleColor: '#0d1317',
    },
    landing_videos: {
      step_one_video_url: 'https://clever-co-prod-web-external.s3.eu-west-1.amazonaws.com/step-1.mp4',
      step_two_video_url: 'https://clever-co-prod-web-external.s3.eu-west-1.amazonaws.com/step-2.mp4',
      step_three_video_url: 'https://clever-co-prod-web-external.s3.eu-west-1.amazonaws.com/step-3.mp4',
      step_four_video_url: 'https://clever-co-prod-web-external.s3.eu-west-1.amazonaws.com/step-4.mp4',
    },
  },
};
