import { Component, OnInit, EventEmitter, Output, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IonSlides, AlertController, AlertButton } from '@ionic/angular';
import { ServiceWorkerService, TestingService, UserTrackingService } from '@takectrl-multiapp/svcs-core';
import { BUSNBrokerService, BUSNThirdPartyService, BUSNUserService } from '@takectrl-multiapp/svcs-core-business';
import { BusnPopiaService } from '@takectrl-multiapp/svcs-core-business';
import {
  IBroker,
  IFirebaseLogin,
  IPopiaConsentFeedback,
  InputType,
  SharedConfig,
  SharedDialogService,
  SharedEncryptionService,
} from '@takectrl-multiapp/svcs-share-config';
import { UIAppInitializationService } from '../../services/ui-app-initialization.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import '@lottiefiles/lottie-player';
import { ApiAuthService, AuthStorageService } from '@takectrl-multiapp/svcs-ctrl-api';
import { firstValueFrom } from 'rxjs';
import { SharedConfigService } from 'libs/svcs-share-config/src/lib/services/shared-config.service';
declare var dataLayer: any[];

@Component({
  selector: 'sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit, AfterViewInit {
  @ViewChild(IonSlides) slides: IonSlides;
  @ViewChild('brokerCodeInput') brokerCodeInput: { value: string };
  @Output() navigateLogin: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() navigateWallet: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() showBackButton: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() navigateForceUpdateScreen: EventEmitter<unknown> = new EventEmitter();
  password: string;
  confirmPassword: string;
  public registerForm: FormGroup;
  public hard_of_hearing = false;
  public numberInName = false;
  public passwordType = 'password';
  public passwordIcon = 'view';
  showHardOfHearingText = false;
  public brokerSearchCode: string;
  public brokerName: string;
  public brokerAgent: string;
  public enableBrokerNextButton = false;
  brokers: IBroker[] = [];
  selectedBroker: IBroker;
  showBrokerResults = false;
  enableRegisterButton = true;
  brokerAutoAssigned = false;
  animationSrc = 'assets/lottie-animations/successful-register.json';
  sub_heading = "Let’s start your quote. First up we'll need some personal details.";
  whichPopup: string;
  screenIndex = 1;
  didGetAllBrokers = false;
  allBrokers: IBroker[] = [];
  selectedAdvisor: string;
  showSelectBroker = false;
  userSelectedBroker: IBroker;
  brokerCodeValue = '';
  showAuthRegLoading = false;

  constructor(
    public formBuilder: FormBuilder,
    private readonly cd: ChangeDetectorRef,
    public busnBrokerService: BUSNBrokerService,
    public authStorageService: AuthStorageService,
    public userService: BUSNUserService,
    public testingService: TestingService,
    public apiAuthService: ApiAuthService,
    public alertController: AlertController,
    public uiAppInitializationService: UIAppInitializationService,
    public popiaService: BusnPopiaService,
    private readonly busnThirdPartyService: BUSNThirdPartyService,
    public sharedEncryptionService: SharedEncryptionService,
    private readonly userTrackingService: UserTrackingService,
    public sharedConfig: SharedConfig,
    private sharedConfigService: SharedConfigService,
    private readonly iab: InAppBrowser,
    public sharedDialogService: SharedDialogService,
    private readonly serviceWorkerService: ServiceWorkerService
  ) {}

  ngOnInit(): void {
    this.uiAppInitializationService.preUserValidationInitialization();
    const noSpacePattern = /^[^\s].*[^\s]$/;
    const emailPattern =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const passWordLength = 6;
    this.registerForm = this.formBuilder.group({
      popia_consent: [''],
      popia_message: ['', Validators.compose([])],
      name: [SharedConfig.routeParamUserFirstName, Validators.compose([Validators.required, Validators.pattern(noSpacePattern)])],
      surname: [SharedConfig.routeParamUserLastName, Validators.compose([Validators.required, Validators.pattern(noSpacePattern)])],
      email: [SharedConfig.routeParamUserEmail, Validators.compose([Validators.required, Validators.email, Validators.pattern(emailPattern)])],
      password1: ['', Validators.compose([Validators.required, Validators.minLength(passWordLength), Validators.pattern(noSpacePattern)])],
      referralCode: [SharedConfig.routeParamDealershipReferralCode, Validators.compose([Validators.required])],
      hard_of_hearing: [false, Validators.compose([Validators.required])],
    });
    this.userTrackingService.init();
    if (this.screenIndex === 1) {
      this.slideAtStart();
    }

    this.busnThirdPartyService.logEvent('page_register');

    dataLayer.push({
      event: 'page_show',
      screenPath: 'sign-up',
      screenName: 'sign-up',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
    this.selectBroker = this.selectBroker.bind(this);
    this.cancelSelectBroker = this.cancelSelectBroker.bind(this);
    this.login = this.login.bind(this);
    this.clearAndSelectAdvisor = this.clearAndSelectAdvisor.bind(this);
    this.clearAndSearchAdvisorCode = this.clearAndSearchAdvisorCode.bind(this);
  }

  async ngAfterViewInit(): Promise<void> {
    this.uiAppInitializationService.getAppSettings();
  }

  async openSelectAdvisor() {
    if (this.brokers && this.brokers.length > 0) {
      this.sharedDialogService.showPopupDialog({
        header: 'Advisor already selected',
        subHeader: 'You have already entered an advisor code. Would you prefer to clear your selection and choose an advisor from the list?',
        buttonOneText: 'Select advisor',
        buttonOneCallback: this.clearAndSelectAdvisor,
        buttonTwoText: 'Cancel',
        buttonTwoCallback: this.sharedDialogService.hidePopupDialog,
      });
    } else {
      this.openSelectAdvisorPopup();
    }
  }

  clearAndSelectAdvisor() {
    this.brokers = [];
    this.brokerCodeInput.value = this.sharedConfig.appSettings.broker_search_prefix;
    this.brokerCodeValue = '';
    this.sharedDialogService.hidePopupDialog();
    this.openSelectAdvisorPopup();
  }

  openSelectAdvisorPopup() {
    this.sharedConfig.setShowCustomLoader(true);
    if (this.allBrokers && this.allBrokers.length > 0) {
      const intervalId = setInterval(() => {
        this.sharedConfig.setShowCustomLoader(false);
        this.showSelectBroker = true;
        SharedConfig.showFullScreen = true;
        clearInterval(intervalId);
      }, 500);
    } else {
      this.sharedDialogService.showPopupDialog({
        header: 'Something went wrong',
        subHeader: 'The list of brokers did not return.',
        buttonOneText: 'OK',
        buttonOneCallback: this.sharedDialogService.hidePopupDialog,
      });
    }
  }

  selectAdvisor(broker: IBroker) {
    this.userSelectedBroker = broker;
    this.selectedAdvisor = broker.adviser_name_town;
    this.showSelectBroker = false;
    SharedConfig.showFullScreen = false;
    this.enableBrokerNextButton = true;
  }

  hardOfHearingChange() {
    if (!this.registerForm.value.hard_of_hearing) {
      this.registerForm.value.hard_of_hearing = true;
    }

    if (!this.showHardOfHearingText) {
      this.showHardOfHearingText = true;
    } else if (this.showHardOfHearingText) {
      this.showHardOfHearingText = false;
    }
  }

  autoPopulateBroker() {
    this.busnBrokerService.getBrokers(SharedConfig.routeParamBrokerCode).then(async (brokerResult) => {
      this.brokers = brokerResult;
      if (this.brokers && this.brokers.length === 1) {
        const broker = this.brokers[0];
        if (broker) {
          this.selectedBroker = broker;
          SharedConfig.broker = broker;
          this.brokerName = this.selectedBroker.contact;
          this.brokerAgent = this.selectedBroker.brokerages.agent;
          this.brokerSearchCode = this.selectedBroker.search_code;
          this.brokerAutoAssigned = true;
        }
      }
    });
  }

  clearAndSearchAdvisorCode() {
    this.selectedAdvisor = '';
    this.userSelectedBroker = null;
    this.sharedDialogService.hidePopupDialog();
    this.doSearchForBrokers();
  }

  doSearchForBrokers() {
    this.sharedConfig.setShowCustomLoader(true);
    this.busnBrokerService.getBrokers(this.brokerCodeValue).then(async (brokerResult) => {
      this.brokers = brokerResult;
      this.sharedConfig.setShowCustomLoader(false);
      if (this.brokers && this.brokers.length > 0 && this.brokers.filter((x) => x.active === true).length > 0) {
        this.enableBrokerNextButton = true;
        this.assignBrokerAndGoNext();
      } else {
        this.brokerCodeInput.value = this.sharedConfig.appSettings.broker_search_prefix;
        this.brokerCodeValue = '';
        this.sharedDialogService.showPopupDialog({
          header: 'Incorrect franchise code',
          subHeader:
            'That does not seem to be a valid franchise code. Please try again, alternatively, contact your financial adviser to confirm the code.',
          buttonOneText: 'OK',
          buttonOneCallback: this.sharedDialogService.hidePopupDialog,
        });
      }
    });
  }

  searchBrokers(e: Event) {
    this.enableBrokerNextButton = false;
    this.brokerCodeValue = e.target['value'];

    if (this.brokerCodeValue.length === 5) {
      if (this.selectedAdvisor && this.selectedAdvisor.length > 0) {
        this.sharedDialogService.showPopupDialog({
          header: 'Advisor already selected',
          subHeader:
            'You have already selected an advisor below. Would you prefer to clear your selection and search for an advisor using their code?',
          buttonOneText: 'Search advisor',
          buttonOneCallback: this.clearAndSearchAdvisorCode,
          buttonTwoText: 'Cancel',
          buttonTwoCallback: this.sharedDialogService.hidePopupDialog,
        });
      } else {
        this.doSearchForBrokers();
      }
    } else {
      this.enableBrokerNextButton = false;
    }
  }

  assignBrokerAndGoNext() {
    const broker = this.brokers[0];
    if (broker && this.userSelectedBroker) {
      this.sharedDialogService.showPopupDialog({
        header: 'Multiple advisers selected',
        subHeader: 'Please either enter a unique code or choose an adviser on the list.',
        buttonOneText: 'OK',
        buttonOneCallback: this.sharedDialogService.hidePopupDialog,
      });
    } else if (broker || this.userSelectedBroker) {
      this.selectedBroker = broker ? broker : this.userSelectedBroker;
      SharedConfig.broker = broker ? broker : this.userSelectedBroker;
      this.brokerName = this.selectedBroker.contact;
      this.brokerAgent = this.selectedBroker.brokerages.agent;
      this.brokerSearchCode = this.selectedBroker.search_code;
      this.registerFormSlideNext();
    }
  }

  async autoAssignDefaultBroker() {
    this.sharedConfig.setShowCustomLoader(true);
    this.enableBrokerNextButton = true;
    const brokerDefaultCode = this.sharedConfig.appSettings.broker_search_default_code;
    this.busnBrokerService.getBrokers(brokerDefaultCode).then(async (brokerResult) => {
      const defaultBroker = brokerResult[0];
      this.selectedBroker = defaultBroker;
      SharedConfig.broker = defaultBroker;
      this.brokerName = this.selectedBroker.contact;
      this.brokerAgent = this.selectedBroker.brokerages.agent;
      this.brokerSearchCode = this.selectedBroker.search_code;
      this.sharedConfig.setShowCustomLoader(false);
      this.registerFormSlideNext();
    });
  }

  async showBrokerDetails(broker: IBroker) {
    this.showBrokerResults = false;
    this.selectedBroker = broker;
    this.sharedDialogService.showPopupDialog({
      header: broker.contact,
      subHeader: broker.email,
      buttonOneText: 'Select broker',
      buttonOneCallback: this.selectBroker,
      buttonTwoText: 'Cancel',
      buttonTwoCallback: this.cancelSelectBroker,
    });
  }

  selectBroker() {
    this.showBrokerResults = false;
    SharedConfig.broker = this.selectedBroker;
    this.brokerName = this.selectedBroker.contact;
    this.brokerAgent = this.selectedBroker.brokerages.agent;
    this.brokerSearchCode = this.selectedBroker.search_code;
    console.log(this.selectedBroker);
    this.registerFormSlideNext();
    this.dismissSmallPopup();
  }

  cancelSelectBroker() {
    this.showBrokerResults = true;
    this.dismissSmallPopup();
  }

  dismissSmallPopup() {
    this.sharedDialogService.hidePopupDialog();
  }

  async showRegistrationAlert(header: string, subHeader: string, buttons: (string | AlertButton)[]) {
    const callAlert = await this.alertController.create({
      header,
      subHeader,
      buttons,
    });
    await callAlert.present();
  }

  nextOnEnter(event) {
    const key = event.keyCode;
    if (key == 13) {
      if (this.registerForm.value.name && this.registerForm.value.surname) {
        this.registerFormSlideNext();
      }
    }
  }

  registerOnEnter(event) {
    const key = event.keyCode;
    if (key == 13) {
      if (this.registerForm.value.email && this.registerForm.value.password1) {
        if (this.sharedConfig?.appSettings?.list_brokers_on_registration && !this.brokerAutoAssigned) {
          this.registerFormSlideNext();
        } else {
          this.initializeRegistration();
        }
      }
    }
  }

  registerFormSlideNext() {
    if (!this.sharedConfig?.appSettings?.consent_enabled || this.registerForm.value?.popia_consent) {
      this.screenIndex++;
      this.busnThirdPartyService.logEvent('page_registration_step', { stepIndex: this.screenIndex });
      this.showBackButton.emit(true);

      if (this.sharedConfig.appSettings?.list_brokers_on_registration && !this.brokerAutoAssigned && !this.didGetAllBrokers) {
        this.didGetAllBrokers = true;
        const checkForActiveBroker: IBroker[] = [];
        this.busnBrokerService.getAllBrokers().then(async (results) => {
          Object.keys(results).forEach((key) => {
            checkForActiveBroker.push(results[key]);
          });
          this.allBrokers = checkForActiveBroker.filter((x) => x.active === true);
        });
      }
    } else {
      this.popiaService.popiaConsentGivenByUser().then((consentFeedback: IPopiaConsentFeedback) => {
        if (consentFeedback.consent_given) {
          this.registerForm.controls['popia_message'].setValue(consentFeedback.consent_message);
          this.registerForm.controls['popia_consent'].setValue(consentFeedback.consent_given);
          this.busnThirdPartyService.logEvent('action_popia_consent_given');
          this.registerFormSlideNext();
        }
      });
    }
  }

  dismissChooseBrokerPopup() {
    this.showSelectBroker = false;
    SharedConfig.showFullScreen = false;
  }

  loginPage() {
    this.navigateLogin.emit();
  }

  slideAtStart() {
    this.showBackButton.emit(false);
  }

  prevQuestion() {
    this.screenIndex--;
    if (this.screenIndex === 1) {
      this.slideAtStart();
    }
  }

  initializeRegistration() {
    this.registerUser(
      this.registerForm.value.email.toString().trim().toLowerCase(),
      this.registerForm.value.password1.trim(),
      this.registerForm.value.name.trim(),
      this.registerForm.value.surname.trim(),
      this.registerForm.value.popia_consent === '' ? false : true,
      this.registerForm.value.referralCode,
      this.hard_of_hearing,
      this.registerForm.value.popia_message
    );
  }

  async registerUser(
    email: string,
    password: string,
    name: string,
    surname: string,
    popia_consent: boolean,
    referralCode: string,
    hard_of_hearing: boolean,
    popia_message: string,
    RunCompleteRegistration = true
  ): Promise<IFirebaseLogin> {
    this.enableRegisterButton = false;
    this.sharedConfig.setShowCustomLoader(true);
    return await this.userService.checkEmailForSpam(email.trim()).then(async (isSafe) => {
      if (!isSafe || isSafe.pass_validation) {
        return await this.userService.registerUser(email.trim(), password).then(async (data) => {
          if (data.success) {
            if (SharedConfig.routeParamBrokerCode) {
              this.autoPopulateBroker();
            }
            if (RunCompleteRegistration) {
              await this.createDBUser(email, password, name, surname, popia_consent, referralCode, hard_of_hearing, popia_message);
              await this.completeSignUpProcess(email, data.user_credential.user.uid);
              this.sharedConfig.setShowCustomLoader(false);
            }
          } else {
            await this.showRegistrationError(data);
            this.sharedConfig.setShowCustomLoader(false);
          }
          return data;
        });
      } else {
        this.sharedConfig.setShowCustomLoader(false);
        this.showRegistrationAlert(
          'Uh oh!',
          'Your email appears to be invalid; before proceeding, be sure to verify or provide an alternative email address.',
          [
            {
              text: 'Close',
            },
          ]
        );
      }
    });
  }

  private async showRegistrationError(data: IFirebaseLogin) {
    this.enableRegisterButton = true;
    this.sharedConfig.setShowCustomLoader(false);
    this.sharedDialogService.showPopupDialog({
      header: 'Uh oh!',
      subHeader: data.message,
      buttonOneText: 'Login',
      buttonOneCallback: this.login,
      buttonTwoText: 'Close',
      buttonTwoCallback: this.sharedDialogService.hidePopupDialog,
    });
  }

  login() {
    this.registerForm.reset({
      email: '',
      password1: '',
    });
    this.loginPage();
    this.dismissSmallPopup();
  }

  async createDBUser(
    email: string,
    password: string,
    name: string,
    surname: string,
    popia_consent: boolean,
    referralCode: string,
    hard_of_hearing: boolean,
    popia_message: string
  ) {
    const capitalizeName = name.charAt(0).toUpperCase() + name.slice(1);
    const capitalizeSurname = surname.charAt(0).toUpperCase() + surname.slice(1);

    this.sharedConfig.setFirebaseUserEmail(email);
    SharedConfig.firebaseUserPswrd = password;

    const userResponse = await this.userService.createUser(
      email.toString().trim().toLowerCase(),
      capitalizeName,
      capitalizeSurname,
      referralCode,
      hard_of_hearing,
      popia_consent,
      popia_message
    );

    this.sharedConfig.setUser(userResponse.results);
    this.sharedConfigService.setUser(userResponse.results);

    await firstValueFrom(this.apiAuthService.authAuthenticate(this.sharedConfig.firebaseUserEmail, SharedConfig.firebaseUserPswrd));
  }

  private async completeSignUpProcess(email: string, uid: string) {
    await this.uiAppInitializationService.hotReloadCore();
    this.busnThirdPartyService.logEvent('app_registration_complete');
    this.sharedConfig.setShowCustomLoader(false);
    SharedConfig.swipeGesture = true;
    SharedConfig.showLottieFullScreen = true;
    this.cd.detectChanges();
  }

  termsPage() {
    this.iab.create(this.sharedConfig.appSettings.terms_url, '_system');
  }

  showPassword() {
    this.passwordType = this.passwordType === InputType.text ? 'password' : InputType.text;
    this.passwordIcon = this.passwordIcon === 'view-close' ? 'view' : 'view-close';
  }

  async compFloatingAction() {
    this.showAuthRegLoading = true;
    try {
      const name = this.testingService.generateRandomName();
      const surname = this.testingService.generateRandomSurname();
      const email = this.testingService.generateRandomUniqueFakeEmail();
      const password = (SharedConfig.firebaseUserPswrd = '1234321');
      const popia_consent = this.registerForm.value.popia_consent === '' ? false : true;
      const referralCode = this.registerForm.value.referralCode;
      const hard_of_hearing = this.hard_of_hearing;
      const popia_message = this.registerForm.value.popia_message;
      this.sharedConfig.setFirebaseUserEmail(email);
      const firebaseUser = await this.registerUser(
        email,
        password,
        name,
        surname,
        popia_consent,
        referralCode,
        hard_of_hearing,
        popia_message,
        false
      );

      await this.createDBUser(email, password, name, surname, popia_consent, referralCode, hard_of_hearing, popia_message);
      await this.testingService.setTestingUserDetails(name, surname);
      await this.completeSignUpProcess(email, firebaseUser.user_credential.user.uid);
    } catch (_error) {
      console.log('_error');
      console.warn(_error);
      this.sharedConfig.setShowCustomLoader(false);
    }
  }
}
