import { NavController, AlertController, IonItemSliding } from '@ionic/angular';
import { Component, OnInit, NgZone, Output, EventEmitter, QueryList, ViewChildren, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  SharedConfig,
  IQuestion,
  IVehicle,
  IMotorcycle,
  ICaravan,
  IBuilding,
  IContent,
  IAllRiskItem,
  ISpecifiedItem,
  QuoteType,
  AssetType,
  IPageAction,
  SharedDialogService,
  QuoteRequestType,
  IDeleteAssets,
  IQuoteRequest,
} from '@takectrl-multiapp/svcs-share-config';
import { BUSNAssetService, BUSNQuestionService, BUSNQuoteScheduleService } from '@takectrl-multiapp/svcs-core-business';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { Storage } from '@capacitor/storage';
import { PageType } from 'libs/svcs-share-config/src/lib/types/page.type';
import { SharedConfigService } from 'libs/svcs-share-config/src/lib/services/shared-config.service';
import { Subscription } from 'rxjs';
declare const dataLayer: any[];

@Component({
  selector: 'quote-new',
  templateUrl: './quote-new.component.html',
  styleUrls: ['./quote-new.component.scss'],
})
export class QuoteNewComponent implements OnInit, OnDestroy {
  @Output() navigateQuoteQuestions: EventEmitter<object> = new EventEmitter<object>();
  @Output() navigateQuoteObjectMappingQuestions: EventEmitter<object> = new EventEmitter<object>();
  @Output() navigatePersonalDetails: EventEmitter<object> = new EventEmitter<object>();
  @Output() navigateNeedsAnalysis: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() navigateGetQuotes: EventEmitter<object> = new EventEmitter<object>();
  @Output() navigateHomeWallet: EventEmitter<void> = new EventEmitter<void>();
  @Output() navHomeQuote: EventEmitter<object> = new EventEmitter<object>();
  @ViewChildren('sliding') itemSlidingList: QueryList<IonItemSliding>;
  assetsToAdd = [];
  amountOfVehicles = 0;
  amountOfMotorcycles = 0;
  amountOfBuildings = 0;
  amountOfContents = 0;
  amountOfCaravans = 0;
  showAddAssetModal = false;
  removeAssetCompletely = false;
  amountOfSpecifiedItems = 0;
  showContinueQuotePopup = false;
  assetToRemove: IBuilding | ICaravan | IAllRiskItem | IVehicle | ISpecifiedItem | IMotorcycle | IContent;
  selectedAssetToRemove: AssetType;
  public questions: Array<IQuestion>;
  public quoteAsset = [];
  public isPersonalDetailsComplete = false;
  showRemoveAssetPopup = false;
  showRemoveAssetConfirmation = false;
  showRemoveAssetConfirmationToggle: boolean;
  QuoteRequestType = QuoteRequestType;
  AssetType = AssetType;
  private quoteRequestSubscription: Subscription;
  quoteRequest: IQuoteRequest; 

  constructor(
    public navCtrl: NavController,
    public zone: NgZone,
    private readonly activatedRoute: ActivatedRoute,
    private readonly sharedDialogService: SharedDialogService,
    public route: Router,
    public alertController: AlertController,
    public questionService: BUSNQuestionService,
    public busnQuoteScheduleService: BUSNQuoteScheduleService,
    public busnQuestionService: BUSNQuestionService,
    public thirdpartyService: BUSNThirdPartyService,
    public busnAssetService: BUSNAssetService,
    public sharedConfig: SharedConfig,
    public sharedConfigService: SharedConfigService,
  ) {
    SharedConfig.selectedPage = PageType.quoteNew;
    this.cancelQuoteRequestEndorsement = this.cancelQuoteRequestEndorsement.bind(this);
    this.navigateWalletAndClosePopup = this.navigateWalletAndClosePopup.bind(this);
  }

  ngOnInit(): void {
   
    dataLayer.push({
      event: 'page_show',
      screenPath: 'quote-new',
      screenName: 'quote-new',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });

    this.quoteRequestSubscription = this.sharedConfigService.quoteRequest$.subscribe(
      quoteRequest => {
        this.quoteRequest = quoteRequest;
      }
    );
    this.initialize();
  }

  async initialize() {
    await this.initiatePageAction();

    const showRemoveStorageVar = (await Storage.get({ key: 'showRemoveAssetConfirmation' })).value;

    if (showRemoveStorageVar) {
      this.showRemoveAssetConfirmation = showRemoveStorageVar === 'true' ? true : false;
    } else {
      this.showRemoveAssetConfirmation = true;
    }

    SharedConfig.sneakPeak = (await Storage.get({ key: 'didViewSneakPeak' })).value;
    this.sharedConfig.setShowCustomLoader(false)
  }

  async initiatePageAction() {
    const queryParams = this.activatedRoute.snapshot.queryParams;

    let pageAction: IPageAction;
    if (queryParams && queryParams.action) {
      pageAction = JSON.parse(queryParams.action) as IPageAction;

      switch (pageAction.actionCode) {
        case 'QUOTE_NEW_BUILDING':
          const building = await this.busnAssetService.addBuilding();
          this.buildingQuestions(building);
          break;

        case 'QUOTE_NEW_VEHICLE':
          const vehicle = await this.busnAssetService.addVehicle();
          this.vehicleQuestions(vehicle);
          break;

        case 'QUOTE_NEW_MOTORCYCLE':
          const motorcycle = await this.busnAssetService.addMotorcycle();
          this.motorcycleQuestions(motorcycle);
          break;

        case 'QUOTE_NEW_CARAVAN':
          const caravan = await this.busnAssetService.addCaravan();
          this.vehicleQuestions(caravan);
          break;

        case 'QUOTE_NEW_CONTENT':
          const content = await this.busnAssetService.addContent();
          this.contentQuestions(content);
          break;

        case 'QUOTE_NEW_ALL_RISK':
          const specifiedItem = await this.busnAssetService.addSpecifiedItem();
          if (
            this.quoteRequest.assets &&
            (!this.quoteRequest.assets.all_risks || this.quoteRequest.assets.all_risks.length === 0)
          ) {
            if (
              this.quoteRequest.type === QuoteRequestType.EndorsementAdd &&
              (!this.sharedConfig.insured_all_risk || this.sharedConfig.insured_all_risk.length === 0)
            ) {
              await this.busnAssetService.addAllRiskItem();
            } else if (this.quoteRequest.type === QuoteRequestType.Quote) {
              await this.busnAssetService.addAllRiskItem();
            }
          }
          this.specifiedItemQuestions(specifiedItem);

          break;

        default:
          break;
      }
    }
  }

  async cancelQuoteRequestEndorsementConfirmation() {
    let heading = 'Cancel endorsement update';
    let subHeading = 'Cancelling your endorsement update will undo the changes you have made. Would you like to continue?';
    if (this.quoteRequest.type === QuoteRequestType.Quote) {
      heading = 'Cancel quote';
      subHeading = 'Cancelling your quote add will undo the changes you have made. Would you like to continue?';
    } else if (this.quoteRequest.type === QuoteRequestType.EndorsementAdd) {
      heading = 'Cancel endorsement add';
      subHeading = 'Cancelling your quote add will undo the changes you have made. Would you like to continue?';
    }

    this.sharedDialogService.showPopupDialog({
      header: heading,
      subHeader: subHeading,
      buttonOneCallback: this.cancelQuoteRequestEndorsement,
      buttonOneText: 'Yes',
      buttonTwoText: 'No',
      buttonTwoCallback: this.sharedDialogService.hidePopupDialog,
    });
  }

  async cancelQuoteRequestEndorsement() {
    this.sharedDialogService.hidePopupDialog();
    this.sharedConfig.setShowCustomLoader(true)
    await this.busnQuoteScheduleService.cancelQuoteRequest();
    this.sharedConfig.setShowCustomLoader(false)
    this.navigateHomeWallet.emit();
  }

  async personalDetailsQuestions() {
    this.navigatePersonalDetails.emit();
  }

  isQuoteAssetsEmpty() {
    if (
      this.quoteRequest?.assets?.buildings?.length > 0 ||
      this.quoteRequest?.assets?.contents?.length > 0 ||
      this.quoteRequest?.assets?.vehicles?.length > 0 ||
      this.quoteRequest?.assets?.motorcycles?.length > 0 ||
      this.quoteRequest?.assets?.caravans?.length > 0 ||
      this.quoteRequest?.assets?.specified_items?.length > 0 ||
      this.quoteRequest?.assets?.all_risks?.length > 0
    ) {
      return false;
    } else {
      return true;
    }
  }

  async navGetQuote() {
    if (this.quoteRequest.type !== QuoteRequestType.Quote) {
      this.submitEndorsementRequest();
      let message =
        !this.sharedConfig?.userPermissions?.accept_add_asset_to_policy && !this.sharedConfig?.userPermissions?.accept_amend_asset_on_policy
          ? 'Your broker has been informed of your changes and will be in contact shortly.'
          : 'We’ve asked the insurer to let us know how much it will cost to add this to your policy. You’ll be able to view and accept the change in a few minutes.';
      this.sharedDialogService.showPopupDialog({
        header: 'Note!',
        subHeader: message,
        buttonOneText: 'OK',
        buttonOneCallback: this.navigateWalletAndClosePopup,
      });
    } else {
      this.navigateGetQuotes.emit();
    }
  }

  navigateWalletAndClosePopup() {
    this.navigateHomeWallet.emit();
  }

  async submitEndorsementRequest() {
    SharedConfig.showFullScreen = true;
    this.busnQuoteScheduleService.submitEndorsementRequest();
  }

  async vehicleQuestions(vehicle: IVehicle) {
    SharedConfig.selectedAssetVehicle = vehicle;
    console.log(vehicle);
    this.navigateQuoteQuestions.emit({
      assetTypeDisplayName: 'Vehicle',
      asset_type: AssetType.Vehicles,
      quoteType: QuoteType.StandardQuoteRequest as QuoteType,
    });
  }

  async motorcycleQuestions(motorcycle: IMotorcycle) {
    SharedConfig.selectedAssetMotorcycle = motorcycle;
    this.navigateQuoteQuestions.emit({
      assetTypeDisplayName: 'Motorcycle',
      asset_type: AssetType.Motorcycles,
      quoteType: QuoteType.StandardQuoteRequest as QuoteType,
    });
  }

  disabledQuoteContinuePopup() {
    this.sharedDialogService.showPopupDialog({
      header: 'Missing information',
      subHeader:
        'You currently have an asset where some information is still required. Please complete or alternatively swipe left to delete the asset.',
      buttonOneText: 'OK',
      buttonOneCallback: this.sharedDialogService.hidePopupDialog,
    });
  }

  async caravanQuestions(caravan: ICaravan) {
    SharedConfig.selectedAssetCaravan = caravan;
    this.navigateQuoteQuestions.emit({
      assetTypeDisplayName: 'Caravan/Trailer',
      asset_type: AssetType.Caravans,
      quoteType: QuoteType.StandardQuoteRequest as QuoteType,
    });
  }

  async buildingQuestions(building: IBuilding) {
    SharedConfig.selectedAssetBuilding = building;
    this.navigateQuoteQuestions.emit({
      assetTypeDisplayName: 'Building',
      asset_type: AssetType.Buildings,
      quoteType: QuoteType.StandardQuoteRequest as QuoteType,
    });
  }

  async contentQuestions(content: IContent) {
    SharedConfig.selectedAssetContent = content;
    this.navigateQuoteQuestions.emit({
      assetTypeDisplayName: 'Content',
      asset_type: AssetType.Contents,
      quoteType: QuoteType.StandardQuoteRequest as QuoteType,
    });
  }

  async allRiskQuestions(allRiskItem: IAllRiskItem) {
    SharedConfig.selectedAssetAllRiskItem = allRiskItem;
    this.navigateQuoteQuestions.emit({
      assetTypeDisplayName: 'All Risk',
      asset_type: AssetType.AllRisks,
      quoteType: QuoteType.StandardQuoteRequest as QuoteType,
    });
  }

  async specifiedItemQuestions(specified_item: ISpecifiedItem) {
    SharedConfig.selectedAssetSpecifiedItem = specified_item;
    this.navigateQuoteQuestions.emit({
      assetTypeDisplayName: 'Specified Item',
      asset_type: AssetType.SpecifiedItems,
      quoteType: QuoteType.StandardQuoteRequest as QuoteType,
    });
  }

  async addAsset() {
    this.amountOfVehicles = 0;
    this.amountOfMotorcycles = 0;
    this.amountOfBuildings = 0;
    this.amountOfContents = 0;
    this.amountOfCaravans = 0;
    this.amountOfSpecifiedItems = 0;
    this.showAddAssetModal = true;
    SharedConfig.showFullScreen = true;
  }

  plusAsset(asset_type: any) {
    this.assetsToAdd.push(asset_type);
    switch (asset_type) {
      case AssetType.Vehicles:
        this.amountOfVehicles++;
        break;
      case AssetType.Motorcycles:
        this.amountOfMotorcycles++;
        break;
      case AssetType.Buildings:
        this.amountOfBuildings++;
        break;
      case AssetType.Contents:
        this.amountOfContents++;
        break;
      case AssetType.Caravans:
        this.amountOfCaravans++;
        break;
      case AssetType.SpecifiedItems:
        this.amountOfSpecifiedItems++;
        break;
      default:
        break;
    }
  }

  minusAsset(asset_type: AssetType) {
    const idx = this.assetsToAdd.indexOf(asset_type);
    this.assetsToAdd.splice(idx, 1);
    switch (asset_type) {
      case AssetType.Vehicles:
        this.amountOfVehicles--;
        break;
      case AssetType.Motorcycles:
        this.amountOfMotorcycles--;
        break;
      case AssetType.Buildings:
        this.amountOfBuildings--;
        break;
      case AssetType.Contents:
        this.amountOfContents--;
        break;
      case AssetType.Caravans:
        this.amountOfCaravans--;
        break;
      case AssetType.SpecifiedItems:
        this.amountOfSpecifiedItems--;
        break;
      default:
        break;
    }
  }

  async generateAssets() {
    this.sharedConfig.setShowCustomLoader(true)
    for (const asset of this.assetsToAdd) {
      if (asset === AssetType.Vehicles) {
        await this.busnAssetService.addVehicle();
      } else if (asset === AssetType.Motorcycles) {
        await this.busnAssetService.addMotorcycle();
      } else if (asset === AssetType.Buildings) {
        await this.busnAssetService.addBuilding();
      } else if (asset === AssetType.Contents) {
        await this.busnAssetService.addContent();
      } else if (asset === AssetType.Caravans) {
        await this.busnAssetService.addCaravan();
      } else if (asset === AssetType.SpecifiedItems) {
        await this.busnAssetService.addSpecifiedItem();
        if (
          this.quoteRequest.assets &&
          (!this.quoteRequest.assets.all_risks || this.quoteRequest.assets.all_risks.length === 0)
        ) {
          if (
            this.quoteRequest.type === QuoteRequestType.EndorsementAdd &&
            (!this.sharedConfig.insured_all_risk || this.sharedConfig.insured_all_risk.length === 0)
          ) {
            await this.busnAssetService.addAllRiskItem();
          } else if (this.quoteRequest.type === QuoteRequestType.Quote) {
            await this.busnAssetService.addAllRiskItem();
          }
        }
      }
    }
    this.sharedConfig.setShowCustomLoader(false)
    this.showAddAssetModal = false;
    SharedConfig.showFullScreen = false;
    this.assetsToAdd = [];
    this.busnQuestionService.setIsCompleteFlagOnAllAssetsIfCompleted(SharedConfig.user);
  }

  async removeAssetPopup(asset_type: AssetType, asset: IBuilding | ICaravan | IAllRiskItem | IVehicle | ISpecifiedItem | IMotorcycle | IContent) {
    if (asset.is_completed) {
      this.removeAssetCompletely = false;
    } else {
      this.removeAssetCompletely = true;
    }
    if (this.showRemoveAssetConfirmation) {
      this.showRemoveAssetPopup = true;
      SharedConfig.showFullScreen = true;
      this.selectedAssetToRemove = asset_type
      this.assetToRemove = asset;
    } else {
      this.removeAsset(asset_type, asset);
    }
  }

  async removeAsset(asset_type: AssetType, asset: IBuilding | ICaravan | IAllRiskItem | IVehicle | ISpecifiedItem | IMotorcycle | IContent) {
    if (this.showRemoveAssetConfirmationToggle) {
      Storage.set({
        key: 'showRemoveAssetConfirmation',
        value: 'false',
      });
      this.showRemoveAssetConfirmationToggle = false;
      this.showRemoveAssetConfirmation = false;
    }
    this.showRemoveAssetPopup = false;
    SharedConfig.showFullScreen = false;
    this.sharedConfig.setShowCustomLoader(true)

    const deleteAssetBody: IDeleteAssets = {
      all_risks: [],
      buildings: [],
      caravans: [],
      contents: [],
      motorcycles: [],
      specified_items: [],
      user_id: this.sharedConfig.user.id,
      vehicles: [],
    };

    switch (asset_type) {
      case AssetType.AllRisks:
        deleteAssetBody.all_risks.push(asset.id);
        break;
      case AssetType.Buildings:
        deleteAssetBody.buildings.push(asset.id);
        break;
      case AssetType.Caravans:
        deleteAssetBody.caravans.push(asset.id);
        break;
      case AssetType.Contents:
        deleteAssetBody.contents.push(asset.id);
        break;
      case AssetType.Motorcycles:
        deleteAssetBody.motorcycles.push(asset.id);
        break;
      case AssetType.SpecifiedItems:
        deleteAssetBody.specified_items.push(asset.id);
        break;
      case AssetType.Vehicles:
        deleteAssetBody.vehicles.push(asset.id);
        break;
      // Add additional cases if necessary.
      default:
        console.error('Unsupported asset type');
        this.sharedConfig.setShowCustomLoader(false)
        return;
    }

    await this.busnAssetService.removeAssetsFromQuote(deleteAssetBody, this.quoteRequest.id);

    this.quoteRequest.assets[asset_type] = this.quoteRequest.assets[asset_type].filter((x: { id: any }) => x.id !== asset.id);
    this.sharedConfig.setShowCustomLoader(false)

    this.busnQuestionService.setIsCompleteFlagOnAllAssetsIfCompleted(SharedConfig.user);
  }

  cancelRemove() {
    this.showRemoveAssetPopup = false;
    SharedConfig.showFullScreen = false;
  }

  getQuotes() {
    this.navigateNeedsAnalysis.emit();
  }

  rbixAccessList() {
    if (this.sharedConfig.appSettings.ai_user_access === 'INVITE_ONLY') {
      if (this.sharedConfig.appSettings.ai_user_access_list.includes(this.sharedConfig.firebaseUser.email)) {
        return true;
      } else return false;
    }
  }

  ngOnDestroy(): void {
    if (this.quoteRequestSubscription) {
      this.quoteRequestSubscription.unsubscribe();
    }
  }
}
