import { Directive, HostListener, Input } from '@angular/core';
import { Rbix } from '../services/third-party/rbix.service'; // Ensure the correct path

@Directive({
  selector: '[appEventTracking]'
})
export class EventTrackingDirective {
  @Input() eventType: 'button' | 'submit' | 'randompageclick' = 'randompageclick';

  constructor(private rbix: Rbix) {}

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    const eventDetails = {
      type: this.eventType,
      tagName: (event.target as HTMLElement).tagName,
      id: (event.target as HTMLElement).id || undefined,
      classList: (event.target as HTMLElement).className || undefined
    };
    this.rbix.logUserEvent(eventDetails);
  }
}