import { AfterViewInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ServiceWorkerService, UserTrackingService } from '@takectrl-multiapp/svcs-core';
import { QuoteRequestStatusType, SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import { UIAppInitializationService } from '../../services/ui-app-initialization.service';
import { BUSNThirdPartyService, BUSNUserService } from '@takectrl-multiapp/svcs-core-business';
import { Storage } from '@capacitor/storage';
declare const dataLayer: any[];

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit {
  showOTPScreen = false;
  @Output() navigateForceUpdateScreen: EventEmitter<unknown> = new EventEmitter();
  @Output() navigateHomeQuote: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() navigateHomeWallet: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() navForgotPasswordPage: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() navigateSignUpPage: EventEmitter<unknown> = new EventEmitter<unknown>();

  constructor(
    private readonly userTrackingService: UserTrackingService,
    public userService: BUSNUserService,
    public sharedConfig: SharedConfig,
    private readonly serviceWorkerService: ServiceWorkerService,
    public uiAppInitializationService: UIAppInitializationService,
    public busnThirdPartyService: BUSNThirdPartyService
  ) {}

  async ngOnInit() {
    console.log(`APP VERSION : ${this.sharedConfig.appClientDetail.version}`);
    this.sharedConfig.setShowCustomLoader(true)
  }

  async ngAfterViewInit(): Promise<void> {
    this.userTrackingService.init();
    this.uiAppInitializationService.getAppSettings();
    this.sharedConfig.setShowCustomLoader(false)

    this.busnThirdPartyService.logEvent('page_login');
    if (!SharedConfig.routeParamUserMsisdn && SharedConfig.routeParamUserEmail && !SharedConfig.routeParamUserEmailQuoteOnBehalf) {
      this.setupViaEmail();
    }

    dataLayer.push({
      event: 'page_show',
      screenPath: 'login',
      screenName: 'login',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }

  routeUserToCorrectHomePage() {
    let activeQuoteRequest = null;
    let openQuoteRequest = null;
    if (this.sharedConfig.quoteRequests) {
      activeQuoteRequest = this.sharedConfig.quoteRequests.find(
        (x) =>
          x.status === QuoteRequestStatusType.Submitted ||
          x.status === QuoteRequestStatusType.WaitingOnQuotes ||
          x.status === QuoteRequestStatusType.WaitingToAccept
      );
      openQuoteRequest = this.sharedConfig.quoteRequests.find(
        (x) => x.status === QuoteRequestStatusType.Open || x.status === QuoteRequestStatusType.Cancelled
      );
    }

    if (this.sharedConfig.schedulesActive?.length === 0 && activeQuoteRequest) {
      this.navigateHomeQuote.emit();
    } else if (this.sharedConfig.schedulesActive?.length > 0 || (this.sharedConfig.schedulesActive?.length === 0 && openQuoteRequest)) {
      this.navigateHomeWallet.emit();
    } else {
      this.navigateHomeWallet.emit();
    }
  }

  resetPassword(){
    this.navForgotPasswordPage.emit();
  }

  setupViaEmail() {
    this.userService.resetPassword(SharedConfig.routeParamUserEmail)
     this.navForgotPasswordPage.emit();
   
  }

  updateApp() {
    this.serviceWorkerService.updateApp();
  }

  forgotPassword() {
    this.navForgotPasswordPage.emit();
  }

  async signUp() {
    this.navigateSignUpPage.emit();
  }

  async clearLocalStorage() {
    await Storage.remove({ key: 'userEmail' });
  }
}
