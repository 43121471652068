import { Component, NgZone, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { SharedConfig } from '@takectrl-multiapp/svcs-share-config';
@Component({
  selector: 'popup-with-dismiss-button',
  templateUrl: './popup-with-dismiss-button.component.html',
  styleUrls: ['./popup-with-dismiss-button.component.scss'],
})
export class PopupWithDismissButtonComponent implements OnInit {
  setBottom: boolean
  constructor(public sharedConfig: SharedConfig, private readonly zone: NgZone, private readonly navCtrl: NavController) {}

  ngOnInit(): void {
    if (SharedConfig.showFooterTabs) {
      this.setBottom = true;
    } else {
      this.setBottom = false;
    }
  }

  dismiss() {
    SharedConfig.showPopupWithDismissButton = false;
    SharedConfig.headerForPopupWithDismissButton = '';
    SharedConfig.messageForPopupWithDismissButton = '';
    SharedConfig.buttonTextForPopupWithDismissButton = '';
    SharedConfig.showFullScreen = false;
    if (SharedConfig.popupDialogReturnRoute) {
      this.zone.run(() => {
        this.navCtrl.navigateRoot([SharedConfig.popupDialogReturnRoute]);
      });
    }
    delete SharedConfig.popupDialogReturnRoute;
  }
}
