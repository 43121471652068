<!-- ITC Check -->
<div *ngIf="!this.sharedConfig?.user?.account_details?.itc_check && !this.sharedConfig?.user?.account_details?.ctrl_consent_check">
  <div class="wrapper limit-width-on-desktop-float">
    <div class="top-section">
      <h2 class="h2-bold">You give the go-ahead</h2>
      <p>As with all insurance quotes, we need to request credit bureau info in order to get you the best premiums possible.</p>
    </div>
    <div class="middle-class">
      <ion-grid>
        <ion-row>
          <ion-col size="1" class="mr-12">
            <ion-icon src="assets/icon/genetic-icons/checkbox-tick.svg" class="icon-fill-success"></ion-icon>
          </ion-col>
          <ion-col>
            <p>Requesting quotes creates no obligation and could save you money.</p>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="1" class="mr-12">
            <ion-icon src="assets/icon/genetic-icons/checkbox-tick.svg" class="icon-fill-success"></ion-icon>
          </ion-col>
          <ion-col>
            <p>Your information stays safe and private. No one can access or contact you with marketing content.</p>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
  <div class="button-bot">
    <ion-button mode="ios" (click)="chatToUs()" class="secondary-button limit-width-desktop-on-button">Not sure? Contact us</ion-button>
    <ion-button mode="ios" (click)="itcCheckAgree(true)" class="primary-button limit-width-desktop-on-button">Sounds good, yes</ion-button>
  </div>
</div>

<!-- Added benefits -->
<div
  class="wrapper limit-width-on-desktop-float"
  *ngIf="sharedConfig?.user?.account_details?.itc_check && sharedConfig?.user?.account_details?.ctrl_consent_check"
>
  <div class="header-wrapper">
    <h2 class="h2-bold">Do you want to include emergency assistance?</h2>
    <p>
      For an additional <b>R{{ sharedConfig.appSettings?.brolink_assist_amount }}pm</b>, add emergency assistance as an added benefit. It is free with
      {{ this.sharedConfig.appClientDetail.app_client_specific_text_settings.added_benefits_text }}.
    </p>
  </div>
  <div class="table-wrapper">
    <ion-grid>
      <ion-row>
        <ion-col size="1" class="mr-12">
          <ion-icon src="assets/icon/genetic-icons/tick.svg" class="icon-fill-primary icon-size"></ion-icon>
        </ion-col>
        <ion-col>
          <b class="b-primary">Roadside</b>
        </ion-col>
        <ion-col>
          <div class="right-wrapper">Accident, flat tyre or battery, etc.</div>
        </ion-col>
      </ion-row>
      <hr />
      <ion-row>
        <ion-col size="1" class="mr-12">
          <ion-icon src="assets/icon/genetic-icons/tick.svg" class="icon-fill-primary icon-size"></ion-icon>
        </ion-col>
        <ion-col>
          <b class="b-primary">Home</b>
        </ion-col>
        <ion-col>
          <div class="right-wrapper">Plumber, electrician, locksmith, etc.</div>
        </ion-col>
      </ion-row>
      <hr />
      <ion-row>
        <ion-col size="1" class="mr-12">
          <ion-icon src="assets/icon/genetic-icons/tick.svg" class="icon-fill-primary icon-size"></ion-icon>
        </ion-col>
        <ion-col>
          <b class="b-primary">Take me home</b>
        </ion-col>
        <ion-col size="5">
          <div class="right-wrapper">If you've been drinking alcohol.</div>
        </ion-col>
      </ion-row>
      <hr />
      <ion-row>
        <ion-col size="1" class="mr-12">
          <ion-icon src="assets/icon/genetic-icons/tick.svg" class="icon-fill-primary icon-size"></ion-icon>
        </ion-col>
        <ion-col>
          <b class="b-primary">Legal</b>
        </ion-col>
        <ion-col>
          <div class="right-wrapper padding-8">Advice from attorneys.</div>
        </ion-col>
      </ion-row>
      <hr />
      <ion-row>
        <ion-col size="1" class="mr-12">
          <ion-icon src="assets/icon/genetic-icons/tick.svg" class="icon-fill-primary icon-size"></ion-icon>
        </ion-col>
        <ion-col>
          <b class="b-primary">Medical</b>
        </ion-col>
        <ion-col>
          <div class="right-wrapper padding-8">Telephonic.</div>
        </ion-col>
      </ion-row>
      <hr />
    </ion-grid>
  </div>
</div>

<div class="footer-button-wrapper" *ngIf="sharedConfig?.user?.account_details?.itc_check && sharedConfig?.user?.account_details?.ctrl_consent_check">
  <div class="fade"></div>
  <div class="footer-button-background">
    <ion-button mode="ios" class="secondary-button limit-width-desktop-on-button" (click)="updateQuoteRequestAddedBenefitsAndContinue()">
      No thanks
    </ion-button>
    <ion-button mode="ios" class="primary-button limit-width-desktop-on-button" (click)="updateQuoteRequestAddedBenefitsAndContinue(true)">
      Yes please
    </ion-button>
  </div>
</div>

<!-- TODO ALL OTHER POPUPS SHOULD BE REPLACED BY THIS GENERIC POPUP -->
<popup-dialog  id="quote.added.component" *ngIf="sharedConfig.popupDialogShow"></popup-dialog>
