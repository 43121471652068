<div class="wrap-around">
  <div class="progress-header">
    <p
      class="limit-width-on-desktop-float"
      *ngIf="sharedConfig.quoteRequest?.status === QuoteRequestStatusType.Submitted || sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingOnQuotes"
    >
      Finding you the best prices...
    </p>
    <p
      class="added-padding limit-width-on-desktop-float"
      *ngIf="sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingToAccept"
    >
      All your quotes are in! Compare and accept a quote.
    </p>
    <div
      class="container limit-width-on-desktop-float"
      *ngIf="sharedConfig.quoteRequest?.status === QuoteRequestStatusType.Submitted || sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingOnQuotes"
    >
      <div class="progress-bar-container">
        <div
          class="progress-bar"
          [ngStyle]="{ width: 'calc(' + (sharedConfig?.quoteRequest?.quotes?.length / up_to_number_of_quotes) * 100 + '% )' }"
        >
          <div class="linear-movement"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="quotes limit-width-on-desktop-float">
    <!-- Recomended Quote section -->
    <div *ngIf="sharedConfig.recommendedQuote?.recommended" class="quote-details-recomended">
      <div class="quote-details-row">
        <div class="quote-col-1" (click)="navQuoteComparisonOverall()">
          <div class="quote-details-col3">
            <circle-progress
              [percent]="sharedConfig.recommendedQuote.rating_ctrl * 100"
              [radius]="18"
              [outerStrokeColor]="this.sharedConfig.appClientDetail.app_client_specific_colours.quote_percentage_circle_recommended_color"
              [innerStrokeWidth]="3"
              [innerStrokeColor]="'#dcdedf'"
              [outerStrokeWidth]="3"
              [animation]="true"
              [animationDuration]="300"
              [space]="-3"
              [responsive]="false"
              [showSubtitle]="false"
              [titleFontSize]="16"
              [titleFontWeight]="700"
              [titleColor]="this.sharedConfig.appClientDetail.app_client_specific_colours.titleColor"
              [showUnits]="false"
              [toFixed]="0"
            ></circle-progress>
          </div>
          <div class="logo">
            <img
              alt=""
              src="assets/images/insurer-logos/left-color-logos/{{ sharedConfig.recommendedQuote.insurer_id | lowercase }}-colour.svg"
              class="ctrl-logo"
            />
          </div>
        </div>
        <div class="quote-col-3">
          <div class="quote-notice">
            <h6>Recommended</h6>
          </div>
          <div class="quote-details-col2-recommended">
            <b>R{{ sharedConfig.recommendedQuote.total_premium | number : '1.0-0' }}</b>
          </div>
          <div class="bell-right">
            <ion-icon
              *ngIf="sharedConfig.recommendedQuote.mandate_violations_draft"
              (click)="mandateBellAlert()"
              src="assets/icon/app-icons/mandate.svg"
            ></ion-icon>
            <ion-icon *ngIf="!sharedConfig.recommendedQuote.mandate_violations_draft" src="assets/icon/app-icons/mandate-grey.svg"></ion-icon>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <!-- REST OF QUOTES -->
    <div>
      <div *ngFor="let quote of sharedConfig.quotesOther" class="quote-details-not-recomended">
        <!-- Quotes not Recomended -->
        <div class="row">
          <div class="quote-details-row">
            <div class="quote-col-1" (click)="navQuoteComparisonOverall()">
              <div class="quote-details-col3">
                <circle-progress
                  [percent]="quote.rating_ctrl * 100"
                  [radius]="18"
                  [outerStrokeColor]="
                    this.sharedConfig.appClientDetail.app_client_specific_colours.quote_percentage_circle_not_recommended_color_svga_element
                  "
                  [innerStrokeWidth]="3"
                  [innerStrokeColor]="'#dcdedf'"
                  [outerStrokeWidth]="3"
                  [animation]="true"
                  [animationDuration]="300"
                  [space]="-3"
                  [responsive]="false"
                  [showSubtitle]="false"
                  [titleFontSize]="16"
                  [titleFontWeight]="700"
                  [titleColor]="this.sharedConfig.appClientDetail.app_client_specific_colours.titleColor"
                  [showUnits]="false"
                  [toFixed]="0"
                ></circle-progress>
              </div>
              <div class="logo">
                <img alt="" src="assets/images/insurer-logos/left-color-logos/{{ quote.insurer_id | lowercase }}-colour.svg" class="ctrl-logo" />
              </div>
            </div>

            <div class="quote-col-3">
              <div class="quote-details-col2">
                <b>R{{ quote.total_premium | number : '1.0-0' }} </b>
              </div>
              <div class="bell-right">
                <ion-icon *ngIf="quote.mandate_violations_draft" (click)="mandateBellAlert()" src="assets/icon/app-icons/mandate.svg"> </ion-icon>
                <ion-icon *ngIf="!quote.mandate_violations_draft" src="assets/icon/app-icons/mandate-grey.svg"></ion-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="footer-button-wrapper">
  <div class="fade"></div>
  <div class="footer-button-background">
    <ion-button mode="ios" (click)="navQuoteComparisonOverall()" class="footer-button limit-width-desktop-on-button" expand="block">
      Compare
    </ion-button>
  </div>
</div>

<popup-dialog id="quote.list.retrieved.component" *ngIf="sharedConfig.popupDialogShow"></popup-dialog>
