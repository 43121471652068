<ion-buttons slot="start" class="buttons-first-slot">
  <div slot="icon-only" class="icon" (click)="showSideMenu()">
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="icn/menu/profile" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path
          d="M14.5,15.5 C16.0309347,15.8061869 17.2831606,16.9014857 17.7914963,18.3752426 C16.3015514,19.8440117 14.2565781,20.75 12,20.75 C9.74342186,20.75 7.69844862,19.8440117 6.20898071,18.3759355 L6.19377423,18.4186773 C6.69232395,16.9230282 7.95406293,15.8091874 9.5,15.5 C11.1503252,15.169935 12.8496748,15.169935 14.5,15.5 Z M12,6 C13.9329966,6 15.5,7.56700338 15.5,9.5 C15.5,11.4329966 13.9329966,13 12,13 C10.0670034,13 8.5,11.4329966 8.5,9.5 C8.5,7.56700338 10.0670034,6 12,6 Z"
          id="Shape"
          class="icon-fill-primary"
        ></path>
        <path
          d="M12,1.25 C5.78679656,1.25 0.75,6.28679656 0.75,12.5 C0.75,18.7132034 5.78679656,23.75 12,23.75 C18.2132034,23.75 23.25,18.7132034 23.25,12.5 C23.25,6.28679656 18.2132034,1.25 12,1.25 Z M12,2.75 C17.3847763,2.75 21.75,7.11522369 21.75,12.5 C21.75,17.8847763 17.3847763,22.25 12,22.25 C6.61522369,22.25 2.25,17.8847763 2.25,12.5 C2.25,7.11522369 6.61522369,2.75 12,2.75 Z"
          id="Oval"
          class="icon-fill-dark"
          fill-rule="nonzero"
        ></path>
      </g>
    </svg>
  </div>
  <div
    class="badge"
    *ngIf="
      sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingToAccept ||
      sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingOnQuotes 
    "
  ></div>
</ion-buttons>
