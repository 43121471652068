import { IQuestion } from '@takectrl-multiapp/svcs-share-config';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'popup-summary-select',
  templateUrl: './popup-summary-select.component.html',
  styleUrls: ['./popup-summary-select.component.scss'],
})
export class PopupSummarySelectComponent implements OnInit {
  @Input() questionSelected: IQuestion;
  @Output() questionSelect: EventEmitter<unknown> = new EventEmitter();
  activeAnswer: string;
  constructor() {}

  ngOnInit(): void {}

  setAnswer(newChoice) {
    this.activeAnswer = newChoice;
    this.questionSelect.emit(newChoice);
  }
}
