<div class="header limit-width-on-desktop-float">
  <h2 class="h2-bold">There’s a new update!</h2>
  <p>We’ve added new features for an improved experience. This version is no longer supported.</p>
</div>
<div class="content limit-width-on-desktop-float">
  <img src="assets/images/placeholder/app-update-screen@4x.png" />
</div>
<div class="button-row">
  <ion-button mode="ios" (click)="clickUpdate()" class="primary-button limit-width-desktop-on-button">Update now</ion-button>
</div>
