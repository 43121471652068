import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SharedConfig } from '@takectrl-multiapp/svcs-share-config';
declare const dataLayer: any[];

@Component({
  selector: 'needs-info',
  templateUrl: './needs-info.component.html',
  styleUrls: ['./needs-info.component.scss'],
})
export class NeedsInfoComponent implements OnInit {
  @Output() navigateToNeedsSlider: EventEmitter<unknown> = new EventEmitter<unknown>();
  constructor(public sharedConfig: SharedConfig) {}

  ngOnInit(): void {
    dataLayer.push({
      event: 'page_show',
      screenPath: 'needs-info',
      screenName: 'needs-info',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }

  next(pageName: string) {
    this.navigateToNeedsSlider.emit({ pageName: pageName });
  }
}
