import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import { ICtrlResponse } from '../interfaces/api.interface';
import { IAPIAuthRequest } from '../interfaces/auth.interface';
import { APIHttpService } from './api-http.service';
import { AuthStorageService } from './api-auth-storage.service.service';

@Injectable({
  providedIn: 'root',
})
export class ApiAuthService {
  constructor(private readonly apiHttpService: APIHttpService, private readonly authStorageService: AuthStorageService) {}

  register(user_uid: string, email: string): Observable<boolean> {
    const payload = {
      firebase_uuid: user_uid,
      personal_details: {
        email: email,
      },
    };

    return this.apiHttpService.post<ICtrlResponse<boolean>>(`/users/`, payload).pipe(
      map((response) => response.success),
      catchError((error) => {
        console.warn('CTRL register failed.', error);
        return of(false);
      })
    );
  }

  login(email: string, password: string, id_token?: string): Observable<IAPIAuthRequest> {
    const payload = { email, password, id_token };

    return this.apiHttpService.post<IAPIAuthRequest>(`/login/`, payload).pipe(
      map((authResult) => {
        return authResult;
      })
    );
  }

  authAuthenticate(email: string, password: string): Observable<IAPIAuthRequest> {
    return this.login(email, password).pipe(
      tap((authResult) => {
        this.authStorageService.setAuthToken(authResult.access_token);
      })
    );
  }
}
