import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { SharedConfig } from '@takectrl-multiapp/svcs-share-config';
declare const dataLayer: any[];

@Component({
  selector: 'schedules-remove-asset',
  templateUrl: './schedules-remove-asset.component.html',
  styleUrls: ['./schedules-remove-asset.component.scss'],
})
export class SchedulesRemoveAssetComponent implements OnInit {
  @Input() removeAssetStatus: string;
  @Output() navigateClose: EventEmitter<unknown> = new EventEmitter();
  @Output() navigateReferFriend: EventEmitter<unknown> = new EventEmitter();
  constructor(public thirdpartyService: BUSNThirdPartyService, public sharedConfig: SharedConfig) {}

  ngOnInit(): void {
    dataLayer.push({
      event: 'page_show',
      screenPath: 'schedules-remove-asset',
      screenName: 'schedules-remove-asset',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }

  close() {
    this.navigateClose.emit();
  }

  referFriend() {
    this.navigateReferFriend.emit();
  }
}
