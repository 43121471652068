import { IonContent, IonSlides, LoadingController } from '@ionic/angular';
import { OnInit, Input, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { BUSNPolicyService, BUSNAssetService, BUSNQuoteScheduleService } from '@takectrl-multiapp/svcs-core-business';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { ActionSheetController } from '@ionic/angular';
import {
  AssetType,
  ICancelPolicyAsset,
  IInsuredAllRisk,
  IInsuredAsset,
  IInsuredBuilding,
  IInsuredCaravan,
  IInsuredContent,
  IInsuredMotorcycle,
  IInsuredSpecifiedItem,
  IInsuredVehicle,
  IQuoteExcess,
  ILevelOfCover,
  ISchedule,
  IT2wof,
  SharedAPIDataCalculationService,
  SharedConfig,
  SharedDialogService,
  IInsuredVaps,
  SharedFormulaService,
  QuoteRequestType,
  QuoteRequestStatusType,
  IAlertBrokerOfEndorsement,
  QuoteType,
} from '@takectrl-multiapp/svcs-share-config';
import { firstValueFrom } from 'rxjs';
interface IOptionalAndValueAddedCoverDisplay {
  icon: string;
  name: string;
  active: boolean;
  asset_type?: AssetType;
}
declare const dataLayer: any[];

@Component({
  selector: 'wallet-asset',
  templateUrl: './wallet-asset.component.html',
  styleUrls: ['./wallet-asset.component.scss'],
})
export class WalletAssetComponent implements OnInit {
  @ViewChild(IonSlides) slides: IonSlides;
  @ViewChild(IonContent, { static: false }) public content: IonContent;
  @Input() asset_type: AssetType;
  @Output() navigateWalletAssetDetailsPage: EventEmitter<unknown> = new EventEmitter();
  @Output() navigateRemoveAssetPage: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() navigateQuoteNew: EventEmitter<unknown> = new EventEmitter();
  @Output() navigateViewAssetQuestionsPage: EventEmitter<unknown> = new EventEmitter();
  selectedAssetCounterCounter: number;
  insuredAssets: Array<IInsuredAsset> = null;
  public headerIcon: string;
  public headerText: string;
  public headerSubText: string;
  public premium: number;
  public sum_insured: number;
  public special_conditions: string[];
  public level_of_cover: ILevelOfCover;
  public t2wofs: Array<IT2wof> = [];
  public policy_number: string;
  public insurer_id: string;
  public selectedInsurerId: string;
  public excesses?: Array<IQuoteExcess>;
  public effective_date: string;
  loader = this.loadingCtrl.create();
  public specialConditionsArray = [
    'You are resonsible for keeping your vehicle roadworthy.',
    'Your vehicle is not covered if used for business purposes',
  ];
  public t2wofArray = ['Driving under the influence', 'Driving without a valid license', 'If the vehicle is not roadworthy'];
  public otherCosts = false;
  public sasria: number;
  public adviseFee: number;
  public showT2wof = true;
  public schedule: ISchedule;
  public personalLiabilityPremium: number;
  public optionalAndValueAddedCoverDisplay: Array<IOptionalAndValueAddedCoverDisplay> = [];
  public assistance_benefits_requested: boolean;
  public assistance_benefits: Array<unknown>;
  public basicExcess: any;
  public windowExcess: any;
  insured_vehicles?: Array<IInsuredVehicle>;
  insured_contents?: Array<IInsuredContent>;
  insured_buildings?: Array<IInsuredBuilding>;
  insured_motorcycles?: Array<IInsuredMotorcycle>;
  insured_caravans?: Array<IInsuredCaravan>;
  insured_vaps?: Array<IInsuredVaps>;
  insuredAllRiskAndSpecifiedItems?: Array<IInsuredAllRisk>;

  selectedInsuredVehicle?: IInsuredVehicle;
  selectedInsuredContent?: IInsuredContent;
  selectedInsuredBuilding?: IInsuredBuilding;
  selectedInsuredMotorcycle?: IInsuredMotorcycle;
  selectedInsuredCaravan?: IInsuredCaravan;
  selectedInsuredSpecifiedItem?: IInsuredSpecifiedItem;
  selectedInsuredAllRisk?: IInsuredAllRisk;
  selectedInsuredVaps?: IInsuredVaps;
  selectedAllRiskAndSpecifiedItems?: any;
  index = 0;
  currentlySelectedIndex = 0;
  currentlySelectedAsset: IInsuredAsset;
  showDeleteAssetConfirmationSheet = false;
  idNumberField: string;
  whichPopup: string;
  showMoreOptions = false;
  updateOrContinue: string;
  showRenewLicenseActionSheet = false;
  expiry_date: string;
  readOnly = false;
  cover_end_date: string;

  AssetType = AssetType;

  constructor(
    public busnPolicyService: BUSNPolicyService,
    private readonly loadingCtrl: LoadingController,
    private readonly sharedAPIDataCalculationService: SharedAPIDataCalculationService,
    private readonly busnThirdPartyService: BUSNThirdPartyService,
    private readonly busnQuoteSchduleService: BUSNQuoteScheduleService,
    public sharedConfig: SharedConfig,
    public actionSheetController: ActionSheetController,
    private readonly sharedDialogService: SharedDialogService,
    private readonly busnAssetService: BUSNAssetService,
    public sharedFormulaService: SharedFormulaService
  ) {
    this.createQuoteRequestEndorsement = this.createQuoteRequestEndorsement.bind(this);
    this.updateAsset = this.updateAsset.bind(this);
    this.deleteAsset = this.deleteAsset.bind(this);
  }

  ngOnInit(): void {
    dataLayer.push({
      event: 'page_show',
      screenPath: 'wallet-asset',
      screenName: 'wallet-asset',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });

    this.insuredAllRiskAndSpecifiedItems = [];
    if (this.sharedConfig.insured_specified_items) {
      this.insuredAllRiskAndSpecifiedItems.push(...this.sharedConfig.insured_specified_items);
    }
    if (this.sharedConfig.insured_all_risk) {
      this.insuredAllRiskAndSpecifiedItems.push(...this.sharedConfig.insured_all_risk);
    }

    this.insured_buildings = this.sharedConfig.insured_buildings ?? [];
    this.insured_caravans = this.sharedConfig.insured_caravans ?? [];
    this.insured_contents = this.sharedConfig.insured_contents ?? [];
    this.insured_motorcycles = this.sharedConfig.insured_motorcycles ?? [];
    this.insured_vehicles = this.sharedConfig.insured_vehicles ?? [];
    this.insured_vaps = this.sharedConfig.insured_vaps ?? [];
    this.selectAsset();
    console.log(this.insured_vaps);
    this.openChat = this.openChat.bind(this);
  }

  ngAfterViewInit() {
    if (this.content) {
      this.content.getScrollElement().then((scrollElement) => {
        SharedConfig.pageHasScrollbar = scrollElement.scrollHeight > scrollElement.clientHeight;
      });
    }
  }

  ionSlideDidChange() {
    this.slides.getActiveIndex().then((index) => {
      this.currentlySelectedIndex = index;
      this.selectAsset();
    });
  }

  next() {
    this.currentlySelectedIndex = this.currentlySelectedIndex + 1;
    this.selectAsset();
    this.slides.slideNext(100, false);
  }

  prev() {
    this.currentlySelectedIndex = this.currentlySelectedIndex - 1;
    this.selectAsset();
    this.slides.slidePrev(100, false);
  }

  showIntercom() {
    this.busnThirdPartyService.intercomDisplayLauncher();
  }

  maxSelectedIndex(): number {
    switch (this.asset_type) {
      case AssetType.Vehicles:
        return this.insured_vehicles.length ?? 0;
      case AssetType.Motorcycles:
        return this.insured_motorcycles.length ?? 0;
      case AssetType.Caravans:
        return this.insured_caravans.length ?? 0;
      case AssetType.Buildings:
        return this.insured_buildings.length ?? 0;
      case AssetType.Contents:
        return this.insured_contents.length ?? 0;
      case AssetType.AllRisks:
        return this.insuredAllRiskAndSpecifiedItems.length ?? 0;
      case AssetType.Other:
        return this.sharedConfig.schedulesActive.length ?? 0;
      case AssetType.Vaps:
        return this.insured_vaps.length ?? 0;
      default:
        return 0;
    }
  }

  checkLicenseDate(tempDate: Date): number {
    const dateNow = new Date();
    const temp_date = tempDate.getDate();
    const temp_month = tempDate.getMonth();
    const temp_year = tempDate.getFullYear();
    let yearsHavePassed = dateNow.getFullYear() - temp_year;
    const month = dateNow.getMonth() - temp_month;
    if (month < 0 || (month === 0 && dateNow.getDate() < temp_date)) {
      yearsHavePassed--;
    }
    return yearsHavePassed;
  }

  selectAsset() {
    const selectedAssetCounter = this.currentlySelectedIndex;
    this.sharedConfig.setShowCustomLoader(true)
    delete this.basicExcess;
    delete this.windowExcess;
    const dateNow = new Date();
    switch (this.asset_type) {
      case AssetType.Vehicles:
        if (this.insured_vehicles && this.insured_vehicles.length >= selectedAssetCounter) {
          this.busnThirdPartyService.logEvent('page_wallet', { asset_type: AssetType.Vehicles as AssetType });
          this.insuredAssets = this.insured_vehicles;
          this.selectedInsuredVehicle = this.insured_vehicles[selectedAssetCounter];
          this.currentlySelectedAsset = this.selectedInsuredVehicle;
          this.headerIcon = AssetType.Vehicles;
          this.headerText = this.insured_vehicles[selectedAssetCounter]?.asset_detail.make;
          this.headerSubText = this.insured_vehicles[selectedAssetCounter]?.asset_detail.model;
          this.premium = this.insured_vehicles[selectedAssetCounter].premium;
          this.sum_insured = this.insured_vehicles[selectedAssetCounter].sum_insured;
          this.optionalAndValueAddedCoverDisplay = this.setOptionalVehicle(this.insured_vehicles[selectedAssetCounter]);
          this.special_conditions = []; //TODO

          this.schedule = this.sharedConfig.schedulesActive.find((x) => x.id === this.insured_vehicles[selectedAssetCounter].schedule_id);
          this.policy_number = this.schedule.policy_number;
          this.effective_date = this.insured_vehicles[selectedAssetCounter]?.cover_start_date
            ? this.insured_vehicles[selectedAssetCounter]?.cover_start_date.split('T')[0]
            : null;
          this.insurer_id = this.schedule.insurer_id;
          this.excesses = this.insured_vehicles[selectedAssetCounter].excess;
          this.selectedInsurerId = this.insurer_id;
          this.excesses.forEach((element) => {
            if (element.description === 'Basic excess') {
              this.basicExcess = element;
            }
            if (element.description === 'Windows') {
              this.windowExcess = element;
            }
          });

          this.getT2wofs(this.schedule, this.asset_type, null).then((t2wof) => {
            this.t2wofs = t2wof;
          });
          if (this.selectedInsuredVehicle?.regular_driver_license_expiry_date) {
            const licenseDate = this.checkLicenseDate(
              new Date(
                parseInt(this.selectedInsuredVehicle?.regular_driver_license_expiry_date.substring(0, 4)),
                parseInt(this.selectedInsuredVehicle?.regular_driver_license_expiry_date.substring(5, 7)) - 1,
                parseInt(this.selectedInsuredVehicle?.regular_driver_license_expiry_date.substring(8, 10))
              )
            );
            if (licenseDate < 1) {
              this.expiry_date = this.selectedInsuredVehicle?.regular_driver_license_expiry_date;
            } else {
              this.expiry_date = `${dateNow.getFullYear()}-${this.selectedInsuredVehicle?.regular_driver_license_expiry_date.substring(
                5,
                7
              )}-${this.selectedInsuredVehicle?.regular_driver_license_expiry_date.substring(8, 10)}`;
              this.saveExpiryDate();
            }
          }
        }
        this.sharedConfig.setShowCustomLoader(false)
        break;
      case AssetType.Motorcycles:
        this.busnThirdPartyService.logEvent('page_wallet', { asset_type: AssetType.Motorcycles });
        this.insuredAssets = this.insured_motorcycles;
        this.selectedInsuredMotorcycle = this.insured_motorcycles[selectedAssetCounter];
        this.currentlySelectedAsset = this.selectedInsuredMotorcycle;

        this.headerIcon = AssetType.Motorcycles;
        this.headerText = this.insured_motorcycles[selectedAssetCounter]?.details.make;
        this.headerSubText = this.insured_motorcycles[selectedAssetCounter]?.details.model;
        this.premium = this.insured_motorcycles[selectedAssetCounter].premium;
        this.sum_insured = this.insured_motorcycles[selectedAssetCounter].sum_insured;
        this.optionalAndValueAddedCoverDisplay = this.setOptionalMotorcycle(this.insured_motorcycles[selectedAssetCounter]);

        this.schedule = this.sharedConfig.schedulesActive.find((x) => x.id === this.insured_motorcycles[selectedAssetCounter].schedule_id);

        this.special_conditions = this.specialConditionsArray;
        this.policy_number = this.schedule.policy_number;
        this.effective_date = this.insured_motorcycles[selectedAssetCounter]?.cover_start_date
          ? this.insured_motorcycles[selectedAssetCounter]?.cover_start_date.split('T')[0]
          : null;
        this.insurer_id = this.schedule.insurer_id;
        this.excesses = this.insured_motorcycles[selectedAssetCounter].excess;
        this.selectedInsurerId = this.insurer_id;
        this.excesses.forEach((element) => {
          if (element.description === 'Basic excess') {
            this.basicExcess = element;
          }
          if (element.description === 'Windows') {
            this.windowExcess = element;
          }
        });
        this.getT2wofs(this.schedule, this.asset_type, null).then((t2wof) => {
          this.t2wofs = t2wof;
        });
        this.sharedConfig.setShowCustomLoader(false)
        if (this.selectedInsuredMotorcycle?.regular_driver_license_expiry_date) {
          const licenseDate = this.checkLicenseDate(
            new Date(
              parseInt(this.selectedInsuredMotorcycle?.regular_driver_license_expiry_date.substring(0, 4)),
              parseInt(this.selectedInsuredMotorcycle?.regular_driver_license_expiry_date.substring(5, 7)) - 1,
              parseInt(this.selectedInsuredMotorcycle?.regular_driver_license_expiry_date.substring(8, 10))
            )
          );
          if (licenseDate < 1) {
            this.expiry_date = this.selectedInsuredMotorcycle?.regular_driver_license_expiry_date;
          } else {
            this.expiry_date = `${dateNow.getFullYear()}-${this.selectedInsuredMotorcycle?.regular_driver_license_expiry_date.substring(
              5,
              7
            )}-${this.selectedInsuredMotorcycle?.regular_driver_license_expiry_date.substring(8, 10)}`;
            this.saveExpiryDate();
          }
        }
        break;
      case AssetType.Caravans:
        this.busnThirdPartyService.logEvent('page_wallet', { asset_type: AssetType.Caravans });
        this.insuredAssets = this.insured_caravans;
        this.selectedInsuredCaravan = this.insured_caravans[selectedAssetCounter];
        this.currentlySelectedAsset = this.selectedInsuredCaravan;

        this.headerIcon = AssetType.Caravans;
        this.headerText = this.insured_caravans[selectedAssetCounter]?.details.make;
        this.headerSubText = this.insured_caravans[selectedAssetCounter]?.details.model;
        this.premium = this.insured_caravans[selectedAssetCounter].premium;
        this.sum_insured = this.insured_caravans[selectedAssetCounter].sum_insured;
        this.optionalAndValueAddedCoverDisplay = this.setOptionalCaravan(this.insured_caravans[selectedAssetCounter]);

        this.schedule = this.sharedConfig.schedulesActive.find((x) => x.id === this.insured_caravans[selectedAssetCounter].schedule_id);

        this.special_conditions = this.specialConditionsArray;
        this.policy_number = this.schedule.policy_number;
        this.effective_date = this.insured_caravans[selectedAssetCounter]?.cover_start_date
          ? this.insured_caravans[selectedAssetCounter]?.cover_start_date.split('T')[0]
          : null;
        this.insurer_id = this.schedule.insurer_id;
        this.excesses = this.insured_caravans[selectedAssetCounter].excess;
        this.selectedInsurerId = this.insurer_id;
        this.excesses.forEach((element) => {
          if (element.description === 'Basic excess') {
            this.basicExcess = element;
          }
          if (element.description === 'Windows') {
            this.windowExcess = element;
          }
        });
        this.getT2wofs(this.schedule, this.asset_type, null).then((t2wof) => {
          this.t2wofs = t2wof;
        });
        this.sharedConfig.setShowCustomLoader(false)
        if (this.selectedInsuredCaravan?.regular_driver_license_expiry_date) {
          const licenseDate = this.checkLicenseDate(
            new Date(
              parseInt(this.selectedInsuredCaravan?.regular_driver_license_expiry_date.substring(0, 4)),
              parseInt(this.selectedInsuredCaravan?.regular_driver_license_expiry_date.substring(5, 7)) - 1,
              parseInt(this.selectedInsuredCaravan?.regular_driver_license_expiry_date.substring(8, 10))
            )
          );
          if (licenseDate < 1) {
            this.expiry_date = this.selectedInsuredCaravan?.regular_driver_license_expiry_date;
          } else {
            this.expiry_date = `${dateNow.getFullYear()}-${this.selectedInsuredCaravan?.regular_driver_license_expiry_date.substring(
              5,
              7
            )}-${this.selectedInsuredCaravan?.regular_driver_license_expiry_date.substring(8, 10)}`;
            this.saveExpiryDate();
          }
        }
        break;
      case AssetType.Buildings:
        this.busnThirdPartyService.logEvent('page_wallet', { asset_type: AssetType.Buildings });
        this.insuredAssets = this.insured_buildings;
        this.selectedInsuredBuilding = this.insured_buildings[selectedAssetCounter];
        this.currentlySelectedAsset = this.selectedInsuredBuilding;
        this.headerIcon = AssetType.Buildings;
        this.headerText = this.insured_buildings[selectedAssetCounter]?.details.risk_address_line1;
        this.headerSubText = this.insured_buildings[selectedAssetCounter]?.details.risk_address_line2;
        this.premium = this.insured_buildings[selectedAssetCounter].premium;
        this.sum_insured = this.insured_buildings[selectedAssetCounter].sum_insured;
        this.optionalAndValueAddedCoverDisplay = this.setOptionalBuilding(this.insured_buildings[selectedAssetCounter]);

        this.schedule = this.sharedConfig.schedulesActive.find((x) => x.id === this.insured_buildings[selectedAssetCounter].schedule_id);
        this.special_conditions = this.specialConditionsArray;

        this.policy_number = this.schedule.policy_number;

        this.effective_date = this.insured_buildings[selectedAssetCounter]?.cover_start_date
          ? this.insured_buildings[selectedAssetCounter]?.cover_start_date.split('T')[0]
          : null;
        this.insurer_id = this.schedule.insurer_id;
        this.excesses = this.insured_buildings[selectedAssetCounter].excess;
        this.selectedInsurerId = this.insurer_id;
        this.excesses.forEach((element) => {
          if (element.description === 'Basic excess') {
            this.basicExcess = element;
          }
        });
        this.getT2wofs(this.schedule, this.asset_type, null).then((t2wof) => {
          this.t2wofs = t2wof;
        });
        this.sharedConfig.setShowCustomLoader(false)
        break;
      case AssetType.Contents:
        this.busnThirdPartyService.logEvent('page_wallet', { asset_type: AssetType.Contents });
        this.insuredAssets = this.insured_contents;
        this.selectedInsuredContent = this.insured_contents[selectedAssetCounter];
        this.currentlySelectedAsset = this.selectedInsuredContent;
        this.headerIcon = AssetType.Contents;
        this.headerText = this.insured_contents[selectedAssetCounter]?.details.risk_address_line1;
        this.headerSubText = this.insured_contents[selectedAssetCounter]?.details.risk_address_line2;
        this.premium = this.insured_contents[selectedAssetCounter].premium;
        this.sum_insured = this.insured_contents[selectedAssetCounter].sum_insured;
        this.optionalAndValueAddedCoverDisplay = this.setOptionalContent(this.insured_contents[selectedAssetCounter]);
        this.schedule = this.sharedConfig.schedulesActive.find((x) => x.id === this.insured_contents[selectedAssetCounter].schedule_id);

        this.special_conditions = this.specialConditionsArray;
        this.policy_number = this.schedule.policy_number;
        this.effective_date = this.insured_contents[selectedAssetCounter]?.cover_start_date
          ? this.insured_contents[selectedAssetCounter]?.cover_start_date.split('T')[0]
          : null;
        this.insurer_id = this.schedule.insurer_id;
        this.excesses = this.insured_contents[selectedAssetCounter].excess;
        this.selectedInsurerId = this.insurer_id;
        this.excesses.forEach((element) => {
          if (element.description === 'Basic excess') {
            this.basicExcess = element;
          }
        });
        this.getT2wofs(this.schedule, this.asset_type, null).then((t2wof) => {
          this.t2wofs = t2wof;
        });
        this.sharedConfig.setShowCustomLoader(false)
        break;
      case AssetType.AllRisks:
        this.insuredAssets = this.insuredAllRiskAndSpecifiedItems;
        this.busnThirdPartyService.logEvent('page_wallet', { asset_type: AssetType.AllRisks });
        this.selectedAllRiskAndSpecifiedItems = this.insuredAllRiskAndSpecifiedItems[selectedAssetCounter];
        this.currentlySelectedAsset = this.selectedAllRiskAndSpecifiedItems;
        this.headerIcon = AssetType.AllRisks;
        this.headerText = this.insuredAllRiskAndSpecifiedItems[selectedAssetCounter]?.asset_detail.description
          ? this.insuredAllRiskAndSpecifiedItems[selectedAssetCounter]?.asset_detail.description
          : this.insuredAllRiskAndSpecifiedItems[selectedAssetCounter]?.description;
        this.headerSubText = '';
        this.premium = this.insuredAllRiskAndSpecifiedItems[selectedAssetCounter].premium;
        this.sum_insured = this.insuredAllRiskAndSpecifiedItems[selectedAssetCounter].sum_insured;
        this.schedule = this.sharedConfig.schedulesActive.find(
          (x) => x.id === this.insuredAllRiskAndSpecifiedItems[selectedAssetCounter].schedule_id
        );

        this.special_conditions = this.specialConditionsArray;
        this.policy_number = this.schedule.policy_number;
        this.effective_date = this.insuredAllRiskAndSpecifiedItems[selectedAssetCounter]?.cover_start_date
          ? this.insuredAllRiskAndSpecifiedItems[selectedAssetCounter]?.cover_start_date.split('T')[0]
          : null;
        this.insurer_id = this.schedule.insurer_id;
        this.excesses = this.insuredAllRiskAndSpecifiedItems[selectedAssetCounter].excess;
        console.log(this.excesses);
        this.selectedInsurerId = this.insurer_id;
        console.log(this.insuredAssets);
        this.excesses.forEach((element) => {
          console.log(element);
          if (element.description === 'Basic excess' || element.description === 'Excess') {
            this.basicExcess = element;
          }
        });
        this.getT2wofs(this.schedule, AssetType.AllRisks, null).then((t2wof) => {
          this.t2wofs = t2wof;
        });
        this.sharedConfig.setShowCustomLoader(false)
        break;
      case AssetType.Other:
        this.busnThirdPartyService.logEvent('page_wallet', { asset_type: AssetType.Other });
        this.headerIcon = AssetType.Other;
        this.headerText = 'Everything else';
        this.headerSubText = '';
        this.premium = this.calculateOtherPremium(selectedAssetCounter);
        this.personalLiabilityPremium = this.sharedConfig.schedulesActive[selectedAssetCounter]?.personal_liability?.premium;
        this.sum_insured = this.sharedConfig.schedulesActive[selectedAssetCounter]?.personal_liability?.sum_insured;

        this.assistance_benefits_requested = this.sharedConfig.schedulesActive[selectedAssetCounter].assistance_benefits_requested;
        this.adviseFee = this.sharedConfig.schedulesActive[selectedAssetCounter].admin_fee;
        this.assistance_benefits = this.sharedConfig.schedulesActive[selectedAssetCounter].assistance_benefits;
        this.otherCosts = true;
        this.sasria = this.sharedConfig.schedulesActive[selectedAssetCounter].sasria;
        this.showT2wof = false;
        this.policy_number = this.sharedConfig.schedulesActive[selectedAssetCounter].policy_number;
        this.effective_date = this.sharedConfig.schedulesActive[selectedAssetCounter].effective_date
          ? this.sharedConfig.schedulesActive[selectedAssetCounter].effective_date.toString().split('T')[0]
          : null;
        this.insurer_id = this.sharedConfig.schedulesActive[selectedAssetCounter].insurer_id;
        this.selectedInsurerId = this.insurer_id;
        this.sharedConfig.setShowCustomLoader(false)
        break;
      case AssetType.Vaps: {
        if (this.insured_vaps && this.insured_vaps.length >= selectedAssetCounter) {
          console.log(this.insured_vaps[selectedAssetCounter]?.level_of_cover);
          this.insuredAssets = this.insured_vaps;
          this.selectedInsuredVaps = this.insured_vaps[selectedAssetCounter];
          this.currentlySelectedAsset = this.selectedInsuredVaps;
          this.busnThirdPartyService.logEvent('page_wallet', { asset_type: AssetType.Vaps });
          this.headerIcon = this.insured_vaps[selectedAssetCounter]?.asset_class;
          this.headerText = this.insured_vaps[selectedAssetCounter]?.product_type;
          if (
            this.insured_vaps[selectedAssetCounter]?.asset_class === AssetType.Vehicles ||
            this.insured_vaps[selectedAssetCounter]?.asset_class === AssetType.Motorcycles ||
            this.insured_vaps[selectedAssetCounter]?.asset_class === AssetType.Caravans
          ) {
            this.headerSubText = `${this.insured_vaps[selectedAssetCounter]?.details?.make} ${this.insured_vaps[selectedAssetCounter]?.details?.model}`;
          } else if (
            this.insured_vaps[selectedAssetCounter]?.asset_class === AssetType.Buildings ||
            this.insured_vaps[selectedAssetCounter]?.asset_class === AssetType.Contents
          ) {
            this.headerSubText = this.insured_vaps[selectedAssetCounter]?.details?.risk_address_line1;
          } else {
            this.headerSubText = this.insured_vaps[selectedAssetCounter]?.specified_item?.description;
          }
          this.level_of_cover = this.insured_vaps[selectedAssetCounter].level_of_cover;
          this.premium = this.insured_vaps[selectedAssetCounter].premium;
          this.policy_number = this.sharedConfig.schedulesActive[selectedAssetCounter].policy_number;
          this.effective_date = this.sharedConfig.schedulesActive[selectedAssetCounter].effective_date.toString();
          this.cover_end_date = this.insured_vaps[selectedAssetCounter]?.cover_end_date;
          this.schedule = this.insured_vaps[selectedAssetCounter].schedule;
          this.getT2wofs(this.insured_vaps[selectedAssetCounter].schedule, this.asset_type, this.insured_vaps[selectedAssetCounter].product_id).then(
            (t2wof) => {
              this.t2wofs = t2wof;
            }
          );
          this.insurer_id = this.insured_vaps[selectedAssetCounter].schedule.insurer_id;
          this.selectedInsurerId = this.insurer_id;
          this.sharedConfig.setShowCustomLoader(false)
        }
      }
      default:
        break;
    }
  }

  calculateOtherPremium(selectedAssetCounter) {
    const schedule = this.sharedConfig.schedulesActive[selectedAssetCounter];
    return this.sharedAPIDataCalculationService.getOtherPremiumFromSchedule(schedule);
  }

  async getT2wofs(schedule: ISchedule, asset_type: string, productId): Promise<IT2wof[]> {
    return this.busnPolicyService.getT2wof(schedule.insurer_id, asset_type.toLocaleUpperCase()).then((t2wof) => {
      if (this.asset_type === AssetType.Vaps) {
        return this.busnPolicyService.getT2wof(schedule.insurer_id, productId).then((vapsT2wof) => {
          return vapsT2wof;
        });
      } else if (!t2wof || t2wof.length <= 0) {
        return this.busnPolicyService.getT2wof(this.sharedConfig.appSettings.default_insurer_id, AssetType.Vehicles).then((defaultT2wof) => {
          return defaultT2wof;
        });
      } else {
        return t2wof;
      }
    });
  }

  setOptionalVehicle(insuredVehicle: IInsuredVehicle): Array<IOptionalAndValueAddedCoverDisplay> {
    return [
      {
        name: 'Car hire',
        icon: 'car_hire',
        active: insuredVehicle.extension_car_hire,
      },
      {
        name: 'Roadside assistance',
        icon: 'road_assistance',
        active: insuredVehicle.roadside_assistance,
      },
      {
        name: 'Excess waiver',
        icon: 'excess_waiver',
        active: insuredVehicle.optional_excess_waiver,
      },
      {
        name: 'Credit shortfall',
        icon: 'credit_shortfall',
        active: insuredVehicle.optional_credit_shortfall,
      },
      {
        name: 'Specified accessories',
        icon: 'car_radio',
        active: insuredVehicle.extension_specified_accessories,
      },
      {
        name: '4x4 extension',
        icon: '4x4',
        active: insuredVehicle.optional4x4,
      },
    ];
  }

  setOptionalMotorcycle(optional_and_value_added_cover: IInsuredMotorcycle): Array<IOptionalAndValueAddedCoverDisplay> {
    return [
      {
        name: 'Car hire',
        icon: 'car_hire',
        active: optional_and_value_added_cover.extension_car_hire,
      },
      {
        name: 'Roadside assistance',
        icon: 'road_assistance',
        active: optional_and_value_added_cover.roadside_assistance,
      },
      {
        name: 'Excess waiver',
        icon: 'excess_waiver',
        active: optional_and_value_added_cover.optional_excess_waiver,
      },
    ];
  }

  setOptionalCaravan(optional_and_value_added_cover: IInsuredCaravan): Array<IOptionalAndValueAddedCoverDisplay> {
    return [
      {
        name: 'Roadside assistance',
        icon: 'road_assistance',
        active: optional_and_value_added_cover.roadside_assistance,
      },
      {
        name: 'Excess waiver',
        icon: 'excess_waiver',
        active: optional_and_value_added_cover.optional_excess_waiver,
      },
      {
        name: 'Specified accessories',
        icon: 'car_radio',
        active: optional_and_value_added_cover.extension_specified_accessories,
      },
    ];
  }

  setOptionalBuilding(optional_and_value_added_cover: IInsuredBuilding): Array<IOptionalAndValueAddedCoverDisplay> {
    return [
      {
        name: 'Accidental damage to machinery',
        icon: 'accidental_damage_building',
        active: optional_and_value_added_cover.extension_accidental_damage_to_machinery,
      },
      {
        name: 'Geyser maintenance',
        icon: 'geyser',
        active: optional_and_value_added_cover.geyser,
      },
      {
        name: 'Subsidence and landslip extension',
        icon: 'landslip',
        active: optional_and_value_added_cover.optional_subsidence,
      },
      {
        name: 'Home assist',
        icon: 'home_assist',
        active: optional_and_value_added_cover.home_assist,
      },
      {
        name: 'Power surge',
        icon: 'power_surge',
        active: optional_and_value_added_cover.extension_power_surge,
      },
      {
        name: 'Baboon and monkey',
        icon: 'monkey_baboon',
        active: optional_and_value_added_cover.optional_baboon_cover,
      },
    ];
  }

  setOptionalContent(optional_and_value_added_cover: IInsuredContent): Array<IOptionalAndValueAddedCoverDisplay> {
    return [
      {
        name: 'Power surge',
        icon: 'power_surge',
        active: optional_and_value_added_cover.extension_power_surge,
      },
      {
        name: 'Home assist',
        icon: 'home_assist',
        active: optional_and_value_added_cover.home_assist,
      },
      {
        name: 'Subsidence and landslip extension',
        icon: 'landslip',
        active: optional_and_value_added_cover.optional_subsidence,
      },
      {
        name: 'Baboon and monkey',
        icon: 'monkey_baboon',
        active: optional_and_value_added_cover.optional_baboon_cover,
      },
      {
        name: 'Accidental damage',
        icon: 'accidental_damage_contents',
        active: optional_and_value_added_cover.extension_accidental_damage,
      },
      {
        name: 'Mechanical /electric /electronic damage',
        icon: 'mechanical_damage',
        active: optional_and_value_added_cover.extension_mech_elec_electronic_damage,
      },
    ];
  }

  showDetail() {
    this.navigateWalletAssetDetailsPage.emit({
      data: this.currentlySelectedAsset,
      selectedInsurerId: this.selectedInsurerId,
      asset_type: this.asset_type,
    });
  }

  async confirmDeleteAsset() {
    if (this.idNumberField === this.sharedConfig.user.personal_details.id_nr) {
      const cancelAsset: ICancelPolicyAsset = {
        asset_info: 'None',
        asset_type: this.asset_type,
        brolink_asset_id: this.currentlySelectedAsset.brolink_risk_id,
        asset_id: this.currentlySelectedAsset.id,
        policy_id: this.policy_number,
      };
      (await this.loader).present();
      this.busnPolicyService.cancelAssetFromPolicy(cancelAsset).then(async (sub) => {
        (await this.loader).dismiss();
        if (!sub) {
          this.sharedDialogService.showPopupDialog({
            header: 'Something went wrong!',
            subHeader: 'Something went wrong, please try again',
            buttonOneText: 'OK',
            buttonOneCallback: this.sharedDialogService.hidePopupDialog,
          });
          this.dismissCustomPopup();
        } else {
          if (sub.success) {
            this.navigateRemoveAssetPage.emit('successfull');
            this.dismissCustomPopup();
          } else if (!sub.success) {
            if (sub['err']['error'].hasOwnProperty('noCancel')) {
              this.navigateRemoveAssetPage.emit('failed');
              this.dismissCustomPopup();
            }
          } else {
            this.sharedDialogService.showPopupDialog({
              header: 'Something went wrong!',
              subHeader: 'Something went wrong, please try again',
              buttonOneText: 'OK',
              buttonOneCallback: this.sharedDialogService.hidePopupDialog,
            });
            this.dismissCustomPopup();
          }
        }
      });
    } else {
      this.sharedDialogService.showPopupDialog({
        header: 'Incorrect ID number',
        subHeader: 'You have entered your ID number incorrectly please try again.',
        buttonOneText: 'OK',
        buttonOneCallback: this.sharedDialogService.hidePopupDialog,
      });
      this.dismissCustomPopup();
    }
  }
  dismissCustomPopup() {
    this.showDeleteAssetConfirmationSheet = false;
    SharedConfig.showFullScreen = false;
  }

  async deleteAsset() {
    this.showDeleteAssetConfirmationSheet = true;
    SharedConfig.showFullScreen = true;
  }

  async deleteAssetChat() {
    this.sharedDialogService.showPopupDialog({
      header: 'Hold on',
      subHeader: 'Please chat to us about removing this asset from this insurer',
      buttonOneText: 'Contact us',
      buttonOneCallback: this.openChat,
      buttonTwoText: 'Cancel',
      buttonTwoCallback: this.sharedDialogService.hidePopupDialog,
    });
  }

  async showActionSheetStartUpdate() {
    this.showMoreOptions = true;
    SharedConfig.showFullScreen = true;
    if (this.sharedConfig.schedulesActive[0].policy_id !== this.insuredAssets[this.currentlySelectedIndex].policy_id) {
      this.updateOrContinue = 'update';
    } else if (
      SharedConfig.quoteRequest &&
      SharedConfig.quoteRequest?.type === QuoteRequestType.EndorsementUpdate &&
      SharedConfig.quoteRequest?.status === QuoteRequestStatusType.Open
    ) {
      this.updateOrContinue = 'continue';
    } else {
      this.updateOrContinue = 'update';
    }
  }

  removeAsset() {
    this.sharedDialogService.hidePopupDialog();
    if (this.insurer_id === 'MIWAY' || this.insurer_id === 'KINGPRICE') {
      this.deleteAssetChat();
    } else {
      this.deleteAsset();
    }
    this.closeMoreOptionsPopup();
  }

  removeAssetConfirmation() {
    this.showMoreOptions = false;
    SharedConfig.showFullScreen = false;
    if (this.sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingToAccept) {
      this.sharedDialogService.showPopupDialog({
        header: 'Hold on',
        subHeader:
          'You have an open request on your policy. Should you wish to alter an asset on your policy, add an asset, or request a new quotation, your previous request will be cancelled.',
        buttonOneCallback: this.removeAsset,
        buttonOneText: 'Continue',
        buttonTwoText: 'Cancel',
        buttonTwoCallback: this.sharedDialogService.hidePopupDialog,
      });
    } else {
      this.removeAsset();
    }
  }

  async updateAssetConfirmation() {
    this.showMoreOptions = false;
    SharedConfig.showFullScreen = false;
    if (this.insurer_id === 'MIWAY' || this.insurer_id === 'KINGPRICE') {
      this.sharedDialogService.showPopupDialog({
        header: 'Hold on',
        subHeader: 'Please contact us about removing this asset from this insurer',
        buttonOneText: 'Contact us',
        buttonOneCallback: this.openChat,
        buttonTwoText: 'Cancel',
        buttonTwoCallback: this.sharedDialogService.hidePopupDialog,
      });
    } else {
      if (this.sharedConfig.userPermissions?.amend_asset_on_policy) {
        if (this.sharedConfig.quoteRequest?.status === 'WAITING_TO_ACCEPT') {
          this.sharedDialogService.showPopupDialog({
            header: 'Hold on',
            subHeader:
              'You have an open request on your policy. Should you wish to alter an asset on your policy, add an asset, or request a new quotation, your previous request will be cancelled.',
            buttonOneCallback: this.updateAsset,
            buttonOneText: 'Continue',
            buttonTwoText: 'Cancel',
            buttonTwoCallback: this.sharedDialogService.hidePopupDialog,
          });
        } else {
          this.updateAsset();
        }
      } else {
        let payload: IAlertBrokerOfEndorsement = {
          asset_type: this.asset_type,
          schedule_id: this.currentlySelectedAsset.schedule_id.toString(),
          asset_id: this.currentlySelectedAsset.brolink_risk_id,
        };
        let results = await this.busnQuoteSchduleService.endorsementUpdateAlertBroker(payload);
        if (results.success) {
          this.sharedDialogService.showPopupDialog({
            header: 'Request in progress',
            subHeader: 'Your broker has been informed of your request and will be in contact shortly.',
            buttonOneCallback: this.sharedDialogService.hidePopupDialog,
            buttonOneText: 'OK',
            navRoute: 'home/home-wallet',
          });
        }
      }
    }
  }

  updateAsset() {
    this.sharedDialogService.hidePopupDialog();
    this.startNewEndorsementUpdate();
    this.closeMoreOptionsPopup();
  }

  viewAsset() {
    this.viewAssetQuestionSummary(this.asset_type);
    this.closeMoreOptionsPopup();
  }

  closeMoreOptionsPopup() {
    this.showMoreOptions = false;
    SharedConfig.showFullScreen = false;
  }

  async viewAssetQuestionSummary(asset_type: AssetType) {
    switch (asset_type) {
      case AssetType.Vehicles:
        SharedConfig.selectedAssetVehicle = await this.busnAssetService.getVehicle(
          this.insured_vehicles[this.currentlySelectedIndex].asset_detail.id
        );
        this.navigateViewAssetQuestionsPage.emit({
          assetTypeDisplayName: 'My wheels',
          asset_type: AssetType.Vehicles,
          quoteType: QuoteType.ViewAssetOnly,
          endorsementUpdateRequiredData: {
            schedule_id: this.insuredAssets[this.currentlySelectedIndex].schedule_id,
            policy_id: this.insuredAssets[this.currentlySelectedIndex].policy_id,
            insurer_id: this.selectedInsurerId,
          },
        });
        break;
      case AssetType.Motorcycles:
        SharedConfig.selectedAssetMotorcycle = await this.busnAssetService.getMotorcycle(
          this.insured_motorcycles[this.currentlySelectedIndex].asset_detail.id
        );
        this.navigateViewAssetQuestionsPage.emit({
          assetTypeDisplayName: 'My motorbike',
          asset_type: AssetType.Motorcycles,
          quoteType: QuoteType.ViewAssetOnly,
          endorsementUpdateRequiredData: {
            schedule_id: this.insuredAssets[this.currentlySelectedIndex].schedule_id,
            policy_id: this.insuredAssets[this.currentlySelectedIndex].policy_id,
            insurer_id: this.selectedInsurerId,
          },
        });
        break;
      case AssetType.Caravans:
        SharedConfig.selectedAssetCaravan = await this.busnAssetService.getCaravan(
          this.insured_caravans[this.currentlySelectedIndex].asset_detail.id
        );
        this.navigateViewAssetQuestionsPage.emit({
          assetTypeDisplayName: 'My caravan/trailer',
          asset_type: AssetType.Caravans,
          quoteType: QuoteType.ViewAssetOnly,
          endorsementUpdateRequiredData: {
            schedule_id: this.insuredAssets[this.currentlySelectedIndex].schedule_id,
            policy_id: this.insuredAssets[this.currentlySelectedIndex].policy_id,
            insurer_id: this.selectedInsurerId,
          },
        });
        break;
      case AssetType.Buildings:
        SharedConfig.selectedAssetBuilding = await this.busnAssetService.getBuilding(
          this.insured_buildings[this.currentlySelectedIndex].asset_detail.id
        );
        this.navigateViewAssetQuestionsPage.emit({
          assetTypeDisplayName: 'Home sweet home',
          asset_type: AssetType.Buildings,
          quoteType: QuoteType.ViewAssetOnly,
          endorsementUpdateRequiredData: {
            schedule_id: this.insuredAssets[this.currentlySelectedIndex].schedule_id,
            policy_id: this.insuredAssets[this.currentlySelectedIndex].policy_id,
            insurer_id: this.selectedInsurerId,
          },
        });
        break;
      case AssetType.Contents:
        SharedConfig.selectedAssetContent = await this.busnAssetService.getContent(
          this.insured_contents[this.currentlySelectedIndex].asset_detail.id
        );
        this.navigateViewAssetQuestionsPage.emit({
          assetTypeDisplayName: 'Under my roof',
          asset_type: AssetType.Contents,
          quoteType: QuoteType.ViewAssetOnly,
          endorsementUpdateRequiredData: {
            schedule_id: this.insuredAssets[this.currentlySelectedIndex].schedule_id,
            policy_id: this.insuredAssets[this.currentlySelectedIndex].policy_id,
            insurer_id: this.selectedInsurerId,
          },
        });
        break;
      case AssetType.AllRisks:
        // @JOSH -> asset_details.id?
        // On the below lie You had
        // this.insuredAllRiskAndSpecifiedItems[this.currentlySelectedIndex].asset_detail.id
        // Ive changed to
        // this.insuredAllRiskAndSpecifiedItems[this.currentlySelectedIndex].specified_items_id
        // Please confirm.

        SharedConfig.selectedAssetSpecifiedItem = await this.busnAssetService.getSpecifiedItem(
          this.insuredAllRiskAndSpecifiedItems[this.currentlySelectedIndex].specified_items_id
        );
        SharedConfig.selectedAssetAllRiskItem = await this.busnAssetService.getAllRisk(
          this.insuredAllRiskAndSpecifiedItems[this.currentlySelectedIndex].all_risks_id
        );
        if (!SharedConfig.selectedAssetAllRiskItem?.id) {
          this.navigateViewAssetQuestionsPage.emit({
            assetTypeDisplayName: 'Stuff I take with',
            asset_type: AssetType.SpecifiedItems,
            quoteType: QuoteType.ViewAssetOnly,
            endorsementUpdateRequiredData: {
              schedule_id: this.insuredAssets[this.currentlySelectedIndex].schedule_id,
              policy_id: this.insuredAssets[this.currentlySelectedIndex].policy_id,
              insurer_id: this.selectedInsurerId,
            },
          });
        } else if (!SharedConfig.selectedAssetSpecifiedItem?.id) {
          this.navigateViewAssetQuestionsPage.emit({
            assetTypeDisplayName: 'Stuff I take with',
            asset_type: AssetType.AllRisks,
            quoteType: QuoteType.ViewAssetOnly,
            endorsementUpdateRequiredData: {
              schedule_id: this.insuredAssets[this.currentlySelectedIndex].schedule_id,
              policy_id: this.insuredAssets[this.currentlySelectedIndex].policy_id,
              insurer_id: this.selectedInsurerId,
            },
          });
        }

        break;
      case AssetType.Other:
        break;
      default:
        break;
    }
  }

  async startNewEndorsementUpdate() {
    const policyNumber = this.sharedConfig.schedulesActive.find((x) => x.id == SharedConfig.quoteRequest?.schedule_id)?.policy_number;
    if (
      SharedConfig.quoteRequest &&
      SharedConfig.quoteRequest.type === QuoteRequestType.EndorsementAdd &&
      (SharedConfig.quoteRequest.status === QuoteRequestStatusType.Submitted ||
        SharedConfig.quoteRequest.status === QuoteRequestStatusType.WaitingOnInsurer ||
        SharedConfig.quoteRequest.status === QuoteRequestStatusType.WaitingOnQuotes)
    ) {
      this.sharedDialogService.showPopupDialog({
        header: 'Hold on',
        subHeader: 'You have an open request to add to your policy. Updating your asset will cancel this request, would you like to continue?',
        buttonOneText: 'Yes',
        buttonTwoText: 'No',
        buttonOneCallback: this.createQuoteRequestEndorsement,
        buttonTwoCallback: this.sharedDialogService.hidePopupDialog,
      });
    } else if (this.selectedInsurerId === 'KINGPRICE' || this.selectedInsurerId === 'MIWAY') {
      this.sharedDialogService.showPopupDialog({
        header: 'Hold on',
        subHeader: 'Unfortunately your insurer does not allow changes to your policy via the app yet. Please chat to us for assistance.',
        buttonOneText: 'Contact us',
        buttonOneCallback: this.openChat,
        buttonTwoText: 'Cancel',
        buttonTwoCallback: this.sharedDialogService.hidePopupDialog,
      });
    } else if (SharedConfig.quoteRequest && policyNumber && policyNumber !== this.insuredAssets[this.currentlySelectedIndex].policy_id) {
      this.sharedDialogService.showPopupDialog({
        header: 'Hold on',
        subHeader: `You have an open request to update policy ${policyNumber}. Going forward with this update will cancel your open request, would you like to continue?`,
        buttonOneText: 'Yes',
        buttonTwoText: 'No',
        buttonOneCallback: this.createQuoteRequestEndorsement,
        buttonTwoCallback: this.sharedDialogService.hidePopupDialog,
      });
      SharedConfig.showFullScreen = true;
    } else {
      this.createQuoteRequestEndorsement(this.schedule.id, QuoteRequestType.EndorsementUpdate);
    }
  }

  continueUpdateAsset() {
    this.navigateQuoteNew.emit();
  }

  createQuoteRequestEndorsement(schedule_id: number, quoteRequestType = QuoteRequestType.EndorsementAdd) {
    this.sharedConfig.setShowCustomLoader(true)
    this.busnQuoteSchduleService.createNewEndorsementRequest(quoteRequestType, schedule_id).then((quoteEndorsement) => {
      this.sharedDialogService.hidePopupDialog();
      if (quoteEndorsement) {
        this.sharedConfig.setShowCustomLoader(false)
        this.navigateQuoteNew.emit();
      } else {
        this.sharedConfig.setShowCustomLoader(false)
        this.sharedDialogService.showPopupDialog({
          header: 'Something went wrong!',
          subHeader: 'We could not create an endorsement request for you, please try again',
          buttonOneText: 'OK',
          buttonOneCallback: this.sharedDialogService.hidePopupDialog,
        });
      }
    });
  }

  renewLicense() {
    this.showRenewLicenseActionSheet = true;
    this.showMoreOptions = false;
    if (!this.expiry_date) {
      this.readOnly = false;
    } else {
      this.readOnly = true;
    }
  }

  openCalendar() {
    if (!this.readOnly) {
      SharedConfig.showCalendarPopup = true;
    }
  }

  setExpiryDate(value) {
    this.expiry_date = value.split('T')[0];
    const x = this.expiry_date;
  }

  saveExpiryDate() {
    this.readOnly = true;
    switch (this.asset_type) {
      case AssetType.Vehicles:
        this.selectedInsuredVehicle.regular_driver_license_expiry_date = this.expiry_date;
        this.busnAssetService.updateVehicle(this.selectedInsuredVehicle);
        break;
      case AssetType.Motorcycles:
        this.selectedInsuredMotorcycle.regular_driver_license_expiry_date = this.expiry_date;
        this.busnAssetService.updateMotorcycle(this.selectedInsuredMotorcycle);
        break;
      case AssetType.Caravans:
        this.selectedInsuredCaravan.regular_driver_license_expiry_date = this.expiry_date;
        this.busnAssetService.updateCaravan(this.selectedInsuredCaravan);
        break;
      default:
        break;
    }
    this.readOnly = true;
  }

  redirectToRenewLicenseUrl() {
    this.closeMoreOptionsPopup();
  }

  updateRenewalDate() {
    this.readOnly = false;
    this.expiry_date = '';
  }

  dismissRenewPopup() {
    this.showRenewLicenseActionSheet = false;
    SharedConfig.showFullScreen = false;
  }

  openChat() {
    this.busnThirdPartyService.intercomDisplayLauncher();
    this.sharedDialogService.hidePopupDialog();
  }
}
