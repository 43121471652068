import { BUSNRenewalService } from '@takectrl-multiapp/svcs-core-business';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { IRenewal, SharedConfig, IPersonalDetails } from '@takectrl-multiapp/svcs-share-config';
import { ICtrlResponse } from 'libs/svcs-ctrl-api/src/lib/interfaces/api.interface';
declare const dataLayer: any[];

@Component({
  selector: 'renewal',
  templateUrl: './renewal.component.html',
  styleUrls: ['./renewal.component.scss'],
})
export class RenewalComponent implements OnInit {
  @Output() navigateRenewalDetailsPage: EventEmitter<unknown> = new EventEmitter();
  public renewalData: IRenewal;
  public personal_details: IPersonalDetails = this.sharedConfig.user.personal_details;
  constructor(
    public sharedConfig: SharedConfig,
    private readonly route: ActivatedRoute,
    public navCtrl: NavController,
    private readonly busnRenewalService: BUSNRenewalService
  ) {}

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParams;
    const renewalResponse: ICtrlResponse<IRenewal> = queryParams.renewal;
    if(renewalResponse?.results){
      this.renewalData = renewalResponse?.results[0];
    }

    dataLayer.push({
      event: 'page_show',
      screenPath: 'renewal',
      screenName: 'renewal',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }

  viewDetails() {
    this.navigateRenewalDetailsPage.emit({ renewal: this.renewalData });
  }

  async closeRenewalPage() {
    this.busnRenewalService.closeRenewal();
  }

  contactBroker() {
    this.busnRenewalService.contactBroker();
  }
}
