import { BUSNQuestionService } from '@takectrl-multiapp/svcs-core-business';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AssetType,
  IQuestion,
  QuoteType,
  SharedConfig,
  SharedDialogService,
  QuestionFilteringType,
  QuestionType,
} from '@takectrl-multiapp/svcs-share-config';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';

@Component({
  selector: 'quote-summary-not-editable',
  templateUrl: './quote-summary-not-editable.component.html',
  styleUrls: ['./quote-summary-not-editable.component.scss'],
})
export class QuoteSummaryNotEditableComponent implements OnInit, AfterViewInit {
  @Output() navigateQuoteNew: EventEmitter<void> = new EventEmitter<void>();
  @Output() navigateWalletPage: EventEmitter<void> = new EventEmitter<void>();
  @Output() navigateWalletAsset: EventEmitter<unknown> = new EventEmitter();
  @Output() navigateSummaryEditPage: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Input() quoteType: QuoteType;
  selectedAssetUnderwritingQuestions: IQuestion[];
  uniqueHeadings: string[];
  questionGroup = [];
  headings = [];
  header = "Let's make sure we got everything right";
  popUpHeading: string;
  popupMessage: string;
  subHeader: string;
  showUpdatePopup = false;
  vehicleYear: string;
  vehicleMake: string;
  vehicleModelSeries: string;
  vehicleModel: string;
  QuestionType = QuestionType;
  QuoteType = QuoteType;
  constructor(
    private readonly busnQuestionService: BUSNQuestionService,
    public sharedConfig: SharedConfig,
    public busnThirdPartyService: BUSNThirdPartyService,
    private readonly sharedDialogService: SharedDialogService
  ) {}

  ngOnInit(): void {
    this.showIntercom = this.showIntercom.bind(this);
  }

  async ngAfterViewInit(): Promise<void> {
    if (this.quoteType === QuoteType.MyProfile) {
      this.selectedAssetUnderwritingQuestions = this.busnQuestionService.getPersonalOrAssetSpecificQuestions(
        SharedConfig.user,
        JSON.parse(JSON.stringify(SharedConfig.underwritingQuestionsAll)),
        AssetType.UserPersonalDetails
      );
      this.header = 'My Profile';
    } else {
      this.selectedAssetUnderwritingQuestions = this.busnQuestionService.getPersonalOrAssetSpecificQuestions(
        SharedConfig.user,
        JSON.parse(JSON.stringify(SharedConfig.underwritingQuestionsAll)),
        SharedConfig.selectedAssetUnderwritingQuestions[0].underwriting_answers[0].object_name,
        QuestionFilteringType.hideHiddenQuestionsForSummary
      );
    }
    if (this.quoteType === QuoteType.ViewAssetOnly) {
      this.header = 'View asset details below.';
    }
    this.questionGroup = this.busnQuestionService.initializeIndividualQuestionLoaders(
      null,
      'quote_summary_not_editable',
      this.selectedAssetUnderwritingQuestions,
      this.quoteType
    );
    for (const qGroup of this.questionGroup) {
      const vehicleSearch = qGroup.answeredQuestions.find((x) => x.type === QuestionType.vehicleSearch);
      if (vehicleSearch) {
        this.vehicleYear = vehicleSearch.underwriting_answers.find((x) => x.object_field === 'year_of_manufacture').object_answer;
        this.vehicleMake = vehicleSearch.underwriting_answers.find((x) => x.object_field === 'make').object_answer;
        this.vehicleModelSeries = vehicleSearch.underwriting_answers.find((x) => x.object_field === 'model_series').object_answer;
        this.vehicleModel = vehicleSearch.underwriting_answers.find((x) => x.object_field === 'model').object_answer;
      }
    }
  }

  async edit(groupHeading: string) {
    if (this.quoteType === QuoteType.MyProfile) {
      this.sharedDialogService.showPopupDialog({
        header: 'Information',
        subHeader: 'If you would like to update your personal information, please chat to us',
        buttonOneText: 'Chat to us',
        buttonOneCallback: this.showIntercom,
        buttonTwoText: 'Cancel',
        buttonTwoCallback: this.sharedDialogService.hidePopupDialog,
      });
    } else {
      this.navigateSummaryEditPage.emit(groupHeading);
      SharedConfig.summaryFilteredHeading = groupHeading;
    }
  }

  showIntercom() {
    this.busnThirdPartyService.intercomDisplayLauncher();
    this.sharedDialogService.hidePopupDialog();
  }

  confirm() {
    if (this.quoteType === QuoteType.StandardQuoteRequest) {
      this.navigateQuoteNew.emit();
    } else {
      this.navigateWalletPage.emit();
    }
  }
  setDateFormat(date_full) {
    const date = new Date(date_full);
    return date.toLocaleString('default', { month: 'long', year: 'numeric' });
  }
}
