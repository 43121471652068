import { Component, OnInit } from '@angular/core';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { SharedConfig } from '@takectrl-multiapp/svcs-share-config';

@Component({
  selector: 'popup-with-chat-button',
  templateUrl: './popup-with-chat-button.component.html',
  styleUrls: ['./popup-with-chat-button.component.scss'],
})
export class PopupWithChatButtonComponent implements OnInit {
  constructor(public sharedConfig: SharedConfig, private readonly busnThirdPartyService: BUSNThirdPartyService) {}

  ngOnInit(): void {}

  openChat() {
    this.busnThirdPartyService.intercomDisplayLauncher();
    this.dismiss();
  }

  dismiss() {
    SharedConfig.showPopupWithChat = false;
    SharedConfig.showFullScreen = false;
    SharedConfig.headerForPopupWithChat = '';
    SharedConfig.messageForPopupWithChat = '';
  }
}
