<div class="wrapper bottom-padding">
  <div class="heading limit-width-on-desktop-float">
    <h2 class="h2-bold">Please check that your claims history is up to date</h2>
  </div>

  <div class="card limit-width-on-desktop-float" *ngFor="let group of questionGroup">
    <div class="section-heading">
      <h3>{{ group.headings }}</h3>
    </div>
    <div *ngFor="let questionAnswers of group.answeredQuestions">
      <div [ngSwitch]="questionAnswers?.type">
        <div *ngSwitchCase="QuestionType.multiSelect">
          <div class="questions-wrapper" *ngIf="multiSelectAnswers.length === 0">
            <label>{{ questionAnswers.wording_for_summary }}</label>
            <p><b>No</b></p>
          </div>
          <div class="questions-wrapper-multi" *ngIf="multiSelectAnswers.length > 0">
            <label>{{ questionAnswers.wording_for_summary }}</label>
            {{ multiSelectAnswers.place_holder }}
            <div *ngFor="let answer of multiSelectAnswers">
              <p>
                <b>{{ answer.place_holder }}</b>
              </p>
            </div>
          </div>
        </div>
        <div *ngSwitchDefault>
          <div class="questions-wrapper" *ngFor="let answer of questionAnswers.underwriting_answers">
            <label>{{ questionAnswers.wording_for_summary }}</label>
            <p>
              <b>{{ answer.object_answer }}</b>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="button-row">
      <ion-button mode="ios" class="secondary-button" (click)="edit('Insurance history', AssetType.UserInsuranceHistory, null)">Update </ion-button>
    </div>
  </div>

  <div class="card limit-width-on-desktop-float" *ngFor="let asset of sharedConfig.quoteRequest.assets.vehicles">
    <div class="section-heading">
      <h3>{{ asset.year_of_manufacture }} {{ asset.make }} {{ asset.model }}</h3>
      <h5>Driver: {{ asset.regularDriver }}</h5>
    </div>

    <div *ngIf="asset.claims && asset.claims.length > 0 && asset.claims[0].reason !== 'No claims'">
      <div class="questions-wrapper">
        <div class="claims-wrapper" *ngFor="let claim of asset.claims">
          <ion-item class="claims" lines="none" mode="ios">
            <ion-label slot="start" class="label-event"
              ><b>{{ claim.reason }}</b></ion-label
            >
            <ion-label slot="end" class="label-date">{{ setDateFormat(claim.date_full) }}</ion-label>
          </ion-item>
        </div>
      </div>
    </div>
    <div *ngIf="!asset.claims || asset.claims.length <= 0 ||asset.claims[0].reason === 'No claims'">
      <ion-item class="no-claims" lines="none" mode="ios">
        <ion-text>
          <b>No claims listed</b>
        </ion-text>
      </ion-item>
    </div>
    <div class="button-row">
      <ion-button mode="ios" class="secondary-button" (click)="edit(null, AssetType.Vehicles, asset)">Update </ion-button>
    </div>
  </div>

  <div class="card limit-width-on-desktop-float" *ngFor="let asset of sharedConfig.quoteRequest.assets.motorcycles">
    <div class="section-heading">
      <h3>{{ asset.year_of_manufacture }} {{ asset.make }} {{ asset.model }}</h3>
      <h5>Driver {{ asset.regularDriver }}</h5>
    </div>
    <div>
      <div *ngIf="asset.claims && asset.claims.length > 0 && asset.claims[0].reason !== 'No claims'">
        <div class="questions-wrapper">
          <div class="claims-wrapper" *ngFor="let claim of asset.claims">
            <ion-item class="claims" lines="none" mode="ios">
              <ion-label slot="start" class="label-event"
                ><b>{{ claim.reason }}</b></ion-label
              >
              <ion-label slot="end" class="label-date">{{ setDateFormat(claim.date_full) }}</ion-label>
            </ion-item>
          </div>
        </div>
      </div>
      <div *ngIf="!asset.claims || asset.claims.length <= 0 || asset.claims[0].reason === 'No claims'">
        <ion-item class="no-claims" lines="none" mode="ios">
          <ion-text>
            <b>No claims listed</b>
          </ion-text>
        </ion-item>
      </div>
    </div>
    <div class="button-row">
      <ion-button mode="ios" class="secondary-button" (click)="edit(null, AssetType.Motorcycles, asset)">Update </ion-button>
    </div>
  </div>

  <div class="card limit-width-on-desktop-float" *ngFor="let asset of sharedConfig.quoteRequest.assets.caravans">
    <div class="section-heading">
      <h3>{{ asset.year_of_manufacture }} {{ asset.make }} {{ asset.model }}</h3>
      <h5>Caravan</h5>
    </div>
    <div>
      <div *ngIf="asset.claims && asset.claims.length > 0 && asset.claims[0].reason !== 'No claims'">
        <div class="questions-wrapper">
          <div class="claims-wrapper" *ngFor="let claim of asset.claims">
            <ion-item class="claims" lines="none" mode="ios">
              <ion-label slot="start" class="label-event"
                ><b>{{ claim.reason }}</b></ion-label
              >
              <ion-label slot="end" class="label-date">{{ setDateFormat(claim.date_full) }}</ion-label>
            </ion-item>
          </div>
        </div>
      </div>
      <div *ngIf="!asset.claims || asset.claims.length <= 0 || asset.claims[0].reason === 'No claims'">
        <ion-item class="no-claims" lines="none" mode="ios">
          <ion-text>
            <b>No claims listed</b>
          </ion-text>
        </ion-item>
      </div>
    </div>
    <div class="button-row">
      <ion-button mode="ios" class="secondary-button" (click)="edit(null, AssetType.Caravans, asset)">Update </ion-button>
    </div>
  </div>

  <div class="card limit-width-on-desktop-float" *ngFor="let asset of sharedConfig.quoteRequest.assets.buildings">
    <div class="section-heading">
      <h3>{{ asset.address?.risk_address_line1 }}, {{ asset.address?.risk_address_line2 }}</h3>
      <h5>Building</h5>
    </div>
    <div>
      <div *ngIf="asset.claims && asset.claims.length > 0 && asset.claims[0].reason !== 'No claims'">
        <div class="questions-wrapper">
          <div class="claims-wrapper" *ngFor="let claim of asset.claims">
            <ion-item class="claims" lines="none" mode="ios">
              <ion-label slot="start" class="label-event"
                ><b>{{ claim.reason }}</b></ion-label
              >
              <ion-label slot="end" class="label-date">{{ setDateFormat(claim.date_full) }}</ion-label>
            </ion-item>
          </div>
        </div>
      </div>
      <div *ngIf="!asset.claims || asset.claims.length <= 0 || asset.claims[0].reason === 'No claims'">
        <ion-item class="no-claims" lines="none" mode="ios">
          <ion-text>
            <b>No claims listed</b>
          </ion-text>
        </ion-item>
      </div>
    </div>
    <div class="button-row">
      <ion-button mode="ios" class="secondary-button" (click)="edit(null, AssetType.Buildings, asset)">Update </ion-button>
    </div>
  </div>

  <div class="card limit-width-on-desktop-float" *ngFor="let asset of sharedConfig.quoteRequest.assets.contents">
    <div class="section-heading">
      <h3>{{ asset.address?.risk_address_line1 }}, {{ asset.address?.risk_address_line2 }}</h3>
      <h5>Contents</h5>
    </div>
    <div>
      <div *ngIf="asset.claims && asset.claims.length > 0 && asset.claims[0].reason !== 'No claims'">
        <div class="questions-wrapper">
          <div class="claims-wrapper" *ngFor="let claim of asset.claims">
            <ion-item class="claims" lines="none" mode="ios">
              <ion-label slot="start" class="label-event"
                ><b>{{ claim.reason }}</b></ion-label
              >
              <ion-label slot="end" class="label-date">{{ setDateFormat(claim.date_full) }}</ion-label>
            </ion-item>
          </div>
        </div>
      </div>
      <div *ngIf="!asset.claims || asset.claims.length <= 0 || asset.claims[0].reason === 'No claims'">
        <ion-item class="no-claims" lines="none" mode="ios">
          <ion-text>
            <b>No claims listed</b>
          </ion-text>
        </ion-item>
      </div>
    </div>
    <div class="button-row">
      <ion-button mode="ios" class="secondary-button" (click)="edit(null, AssetType.Contents, asset)">Update </ion-button>
    </div>
  </div>
</div>
<div class="footer-button-wrapper">
  <div class="fade"></div>
  <div class="footer-button-background">
    <ion-button mode="ios" class="footer-button limit-width-desktop-on-button" expand="block" (click)="confirm()"> Looks good! </ion-button>
  </div>
</div>

<!-- TODO ALL OTHER POPUPS SHOULD BE REPLACED BY THIS GENERIC POPUP -->
<popup-dialog id="quote.claims.history.check.component" *ngIf="sharedConfig.popupDialogShow"></popup-dialog>
