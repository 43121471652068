<div class="loader-background"></div>
<div id="divElement" class="div-element">
  <table align="center">
    <tr height="100%">
      <td height="100%" width="100%" valign="middle" align="center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          style="margin: auto; background: none; display: block; shape-rendering: auto"
          width="200px"
          height="200px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <defs>
            <filter id="ldio-ug0avrszcx-filter" x="-100%" y="-100%" width="300%" height="300%" color-interpolation-filters="sRGB">
              <feGaussianBlur in="SourceGraphic" stdDeviation="2.8000000000000003"></feGaussianBlur>
              <feComponentTransfer result="cutoff">
                <feFuncA type="table" tableValues="0 0 0 0 0 0 1 1 1 1 1"></feFuncA>
              </feComponentTransfer>
            </filter>
          </defs>
          <g filter="url(#ldio-ug0avrszcx-filter)">
            <g transform="translate(50 50)">
              <g>
                <circle cx="30" cy="0" r="5" class="icon-fill-primary">
                  <animate
                    attributeName="r"
                    keyTimes="0;0.5;1"
                    values="4.2;9.799999999999999;4.2"
                    dur="5s"
                    repeatCount="indefinite"
                    begin="-0.20000000000000004s"
                  ></animate>
                </circle>
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  keyTimes="0;1"
                  values="0;360"
                  dur="5s"
                  repeatCount="indefinite"
                  begin="0s"
                ></animateTransform>
              </g>
            </g>
            <g transform="translate(50 50)">
              <g>
                <circle cx="30" cy="0" r="5" class="icon-fill-primary">
                  <animate
                    attributeName="r"
                    keyTimes="0;0.5;1"
                    values="4.2;9.799999999999999;4.2"
                    dur="2.5s"
                    repeatCount="indefinite"
                    begin="-0.16666666666666666s"
                  ></animate>
                </circle>
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  keyTimes="0;1"
                  values="0;360"
                  dur="2.5s"
                  repeatCount="indefinite"
                  begin="-0.03333333333333333s"
                ></animateTransform>
              </g>
            </g>
            <g transform="translate(50 50)">
              <g>
                <circle cx="30" cy="0" r="5" class="icon-fill-primary">
                  <animate
                    attributeName="r"
                    keyTimes="0;0.5;1"
                    values="4.2;9.799999999999999;4.2"
                    dur="1.6666666666666665s"
                    repeatCount="indefinite"
                    begin="-0.13333333333333333s"
                  ></animate>
                </circle>
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  keyTimes="0;1"
                  values="0;360"
                  dur="1.6666666666666665s"
                  repeatCount="indefinite"
                  begin="-0.06666666666666667s"
                ></animateTransform>
              </g>
            </g>
            <g transform="translate(50 50)">
              <g>
                <circle cx="30" cy="0" r="5" class="icon-fill-primary">
                  <animate
                    attributeName="r"
                    keyTimes="0;0.5;1"
                    values="4.2;9.799999999999999;4.2"
                    dur="1.25s"
                    repeatCount="indefinite"
                    begin="-0.10000000000000002s"
                  ></animate>
                </circle>
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  keyTimes="0;1"
                  values="0;360"
                  dur="1.25s"
                  repeatCount="indefinite"
                  begin="-0.10000000000000002s"
                ></animateTransform>
              </g>
            </g>
            <g transform="translate(50 50)">
              <g>
                <circle cx="30" cy="0" r="5" class="icon-fill-secondary">
                  <animate
                    attributeName="r"
                    keyTimes="0;0.5;1"
                    values="4.2;9.799999999999999;4.2"
                    dur="1s"
                    repeatCount="indefinite"
                    begin="-0.06666666666666667s"
                  ></animate>
                </circle>
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  keyTimes="0;1"
                  values="0;360"
                  dur="1s"
                  repeatCount="indefinite"
                  begin="-0.13333333333333333s"
                ></animateTransform>
              </g>
            </g>
            <g transform="translate(50 50)">
              <g>
                <circle cx="30" cy="0" r="5" class="icon-fill-primary">
                  <animate
                    attributeName="r"
                    keyTimes="0;0.5;1"
                    values="4.2;9.799999999999999;4.2"
                    dur="0.8333333333333333s"
                    repeatCount="indefinite"
                    begin="-0.03333333333333333s"
                  ></animate>
                </circle>
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  keyTimes="0;1"
                  values="0;360"
                  dur="0.8333333333333333s"
                  repeatCount="indefinite"
                  begin="-0.16666666666666666s"
                ></animateTransform>
              </g>
            </g>
          </g>
        </svg>
      </td>
    </tr>
  </table>
</div>
