import { BUSNQuestionService, BUSNQuoteScheduleService } from '@takectrl-multiapp/svcs-core-business';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AssetType, IQuestion, QuestionType, SharedConfig } from '@takectrl-multiapp/svcs-share-config';

export interface IQeustionGroup {
  index: number;
  answeredQuestions: IQuestion[];
  headings: string;
}
@Component({
  selector: 'quote-claims-history-check',
  templateUrl: './quote-claims-history-check.component.html',
  styleUrls: ['./quote-claims-history-check.component.scss'],
})
export class QuoteClaimsHistoryCheckComponent implements OnInit {
  @Output() navigateQuoteNew: EventEmitter<void> = new EventEmitter<void>();
  @Output() navigateQuoteAddedBenefits: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() navigateSummaryEditPage: EventEmitter<unknown> = new EventEmitter<unknown>();

  questionGroup = [];
  uniqueHeadings: string[];
  selectedAssetUnderwritingQuestions: IQuestion[];
  multiSelectAnswers = [];
  hasBeenChecked = false;
  QuestionType = QuestionType;
  AssetType = AssetType;
  constructor(
    private readonly busnQuestionService: BUSNQuestionService,
    public sharedConfig: SharedConfig,
    private readonly busnQuoteScheduleService: BUSNQuoteScheduleService
  ) {}

  ngOnInit(): void {}

  initComponent() {
    this.selectedAssetUnderwritingQuestions = this.busnQuestionService.getPersonalOrAssetSpecificQuestions(
      SharedConfig.user,
      JSON.parse(JSON.stringify(SharedConfig.underwritingQuestionsAll)),
      AssetType.UserInsuranceHistory
    );
    this.questionGroup = this.busnQuestionService.initializeIndividualQuestionLoaders(
      null,
      'quote_claims_history_check',
      this.selectedAssetUnderwritingQuestions
    );
  }

  async ngAfterViewInit(): Promise<void> {
    this.initComponent();
  }

  ngAfterContentChecked() {
    if (!this.hasBeenChecked) {
      this.hasBeenChecked = true;
      this.checkAnswersAndDecideWhatToShow();
    }
  }

  async checkAnswersAndDecideWhatToShow() {
    this.initComponent();
    this.multiSelectAnswers = [];
    for (const group of this.questionGroup) {
      for (const groupQuestions of group.answeredQuestions) {
        if (groupQuestions.type === QuestionType.multiSelect) {
          const answersTrue = await groupQuestions.underwriting_answers.filter((x) => x.object_answer === true);
          console.log(answersTrue);
          if (answersTrue.length > 0) {
            answersTrue.forEach((element) => {
              this.multiSelectAnswers.push(element);
            });
          } else {
            this.multiSelectAnswers = [];
          }
        }
      }
    }
  }
  async edit(groupHeading: string, asset_type: AssetType, asset: any) {
    if (asset) {
      SharedConfig.quoteSelectedAssetType = asset_type;
      switch (asset_type) {
        case AssetType.Vehicles:
          SharedConfig.selectedAssetVehicle = asset;
          break;
        case AssetType.Motorcycles:
          SharedConfig.selectedAssetMotorcycle = asset;
          break;
        case AssetType.Caravans:
          SharedConfig.selectedAssetCaravan = asset;
          break;
        case AssetType.Contents:
          SharedConfig.selectedAssetContent = asset;
          break;
        case AssetType.Buildings:
          SharedConfig.selectedAssetBuilding = asset;
          break;
        case AssetType.AllRisks:
          SharedConfig.selectedAssetAllRiskItem = asset;
        case AssetType.SpecifiedItems:
          SharedConfig.selectedAssetSpecifiedItem = asset;
        default:
          break;
      }
      const headingPart1 = asset.year_of_manufacture ? asset.year_of_manufacture : asset.address.risk_address_line1;
      const headingPart2 = asset.make ? asset.make : asset.address.risk_address_line2;
      const headingPart3 = asset.model ? asset.model : '';
      SharedConfig.summaryFilteredHeading = `${headingPart1} ${headingPart2} ${headingPart3}`;
    } else {
      SharedConfig.summaryFilteredHeading = 'Insurance claims';
    }
    SharedConfig.selectedAssetUnderwritingQuestions = this.busnQuestionService.getPersonalOrAssetSpecificQuestions(
      SharedConfig.user,
      JSON.parse(JSON.stringify(SharedConfig.underwritingQuestionsAll)),
      asset_type
    );
    this.questionGroup = [];
    this.navigateSummaryEditPage.emit(groupHeading);
    this.hasBeenChecked = false;
  }

  setDateFormat(date_full) {
    const date = new Date(date_full);
    return date.toLocaleString('default', { month: 'long', year: 'numeric' });
  }

  confirm() {
    this.navigateQuoteAddedBenefits.emit();
    this.hasBeenChecked = false;
  }
}
