<div class="wrap-around" *ngIf="!showFetchingQuotesAnimation">
  <div class="wrapper limit-width-on-desktop-float">
    <h2 class="h2-bold">This is important to you</h2>
    <p>We’ll rate each insurer based on your preferences to make sure we get the best fit for you.</p>
    <div class="body">
      <ion-grid>
        <ion-row *ngFor="let priorityRating of priorityRatings">
          <ion-col size="1">
            <div class="rank-position">
              <b>{{ priorityRating.ranking_position }}</b>
            </div>
          </ion-col>
          <ion-col class="text-col"
            ><p>{{ priorityRating.name }}</p></ion-col
          >
        </ion-row>
      </ion-grid>
    </div>
  </div>
</div>
<div class="footer-button-wrapper" *ngIf="!showFetchingQuotesAnimation">
  <div class="fade"></div>
  <div class="footer-button-background">
    <p>Great! Now that we know your preferences, we can get you some quotes.</p>
    <ion-button mode="ios" class="footer-button limit-width-desktop-on-button" (click)="submitQuoteRequest()" expand="block">Submit</ion-button>
  </div>
</div>

<animation-please-wait-notification *ngIf="showFetchingQuotesAnimation"></animation-please-wait-notification>
<success-with-push-notification-animation *ngIf="showSuccess"></success-with-push-notification-animation>
<popup-with-dismiss-button *ngIf="sharedConfig.showPopupWithDismissButton"></popup-with-dismiss-button>
<!-- TODO ALL OTHER POPUPS SHOULD BE REPLACED BY THIS GENERIC POPUP -->
<popup-dialog  id="needs.results.component" *ngIf="sharedConfig.popupDialogShow"></popup-dialog>
