import { Injectable } from '@angular/core';
import { AssetType, IAppSettings, IInsurer, IUser, SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import {
  BUSNBrokerService,
  BUSNClaimsService,
  BUSNPolicyService,
  BUSNQuestionService,
  BUSNQuoteScheduleService,
  BUSNUserService,
} from '@takectrl-multiapp/svcs-core-business';
import { ApiAuthService, APISettingsService } from '@takectrl-multiapp/svcs-ctrl-api';
import { NavController, Platform } from '@ionic/angular';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { firstValueFrom } from 'rxjs';
import { Rbix, ServiceWorkerService, TestingService } from '@takectrl-multiapp/svcs-core';
import { SharedConfigService } from 'libs/svcs-share-config/src/lib/services/shared-config.service';
import { IAPIAuthRequest } from 'libs/svcs-ctrl-api/src/lib/interfaces/auth.interface';

@Injectable({
  providedIn: 'root',
})
export class UIAppInitializationService {
  constructor(
    public navCtrl: NavController,
    public busnUserService: BUSNUserService,
    public busnPolicyService: BUSNPolicyService,
    public busnQuoteScheduleService: BUSNQuoteScheduleService,
    public busnClaimsService: BUSNClaimsService,
    public platform: Platform,
    public busnQuestionService: BUSNQuestionService,
    public apiAuthService: ApiAuthService,
    public sharedConfig: SharedConfig,
    public testingService: TestingService,
    public sharedConfigService: SharedConfigService,
    public busnBrokerService: BUSNBrokerService,
    public apiSettingsService: APISettingsService,
    public rbix: Rbix,
    private readonly serviceWorkerService: ServiceWorkerService,
    private readonly busnThirdPartyService: BUSNThirdPartyService
  ) {}

  async getAppSettings() {
    if (!SharedConfig.appSettingsRetrieved) {
      SharedConfig.appSettingsRetrieved = true;
      await this.setAppSettings();
    }
  }

  async preUserValidationInitialization() {
    // if (!SharedConfig.appSettingsRetrieved) {
    this.serviceWorkerService.subscribeForUpdates();
    this.setPlatform();
    await Promise.all([this.initializeFirebaseAnalytics(), this.getAppSettings()]);
    //}
  }

  // async postLogin(user: IUser) {
  //   this.checkUserAddressIsPartialAndRemoveHisAddress(user); // this should ideally only happen after login.
  // }

  // --------------------------
  // this.sharedConfig.setUserPermission(user.user_permissions); // HACK INTRODUCED : BACKEND NO ENDPOINT TO GET RIGHTS, ADDED TO RECURRING GET ALL USER DATA
  // Also, intercom should be registered here an not in the firebase subscriber.
  // --------------------------

  async hotReloadCore() {
    // if (this.sharedConfig.didHotReload) {
    //   console.log('Already hot reload.');
    //   return;
    // }
    // todo : refactor this : Should / can be refactored when the "Set your password page has been split out"

    const showPasswordComponent = this.sharedConfig.showSetNewPasswordComponent;
    if (showPasswordComponent) return;

    this.sharedConfig.setShowCustomLoader(true, 'hotreload');

    if (!SharedConfig.firebaseUser) {
      SharedConfig.firebaseUser = await this.busnThirdPartyService.firebaseCurrentUser();
      SharedConfig.firebaseUserKey = SharedConfig.firebaseUser.uid;
      this.sharedConfig.setFirebaseUserEmail(SharedConfig.firebaseUser.email);
    }

    if (!SharedConfig.firebaseUser) {
      this.busnThirdPartyService.firebaseSignOut();
      this.navCtrl.navigateRoot(['public-landing']);
      return;
    }

    const user = await this.getUser();
    await this.preUserValidationInitialization();
    await this.getAppSettings();
    SharedConfig.underwritingQuestionsAll = await this.busnQuestionService.getUnderwritingQuestions();

    let userPermissions = await this.busnUserService.getPermissions();
    this.sharedConfig.setUserPermission(userPermissions);

    await this.busnUserService.setAllUserRelatedDataSubscriber(user.id);

    const intercomInitialized = SharedConfig.intercomInitialized;
    if (!intercomInitialized) {
      let brokerages = await this.busnThirdPartyService.getBrokerage();
      await this.busnThirdPartyService.initIntercom(brokerages, user);
      SharedConfig.intercomInitialized = true;
      const res = await this.busnUserService.generateUserIntercomID();
    }

    console.log(`Intercom initialized ${SharedConfig.intercomInitialized} `);

    this.subscribeToFirebaseSubscriptionWatch(user.id);
    this.setLocallyInsurers().then((_insurers) => {
      SharedConfig.insurers = _insurers;
    });
    SharedConfig.schedulesActiveInitialCount = this.sharedConfig.schedulesActive?.length ?? 0;

    this.busnClaimsService.getClaimCategories().then((categories) => {
      SharedConfig.claimCategories = categories;
    });

    this.sharedConfig.setShowCustomLoader(false, 'hotreload');
    this.sharedConfig.setDidHotReload(true);
  }

  // start var monitors if needed
  //  this.testingService.monitorObservable(this.sharedConfigService.currentUserQuoteState$, 'currentUserQuoteState');
  //  this.testingService.monitorObservable(this.sharedConfigService.quoteRequest$, 'quoteRequest');

  async authAuthenticate(email: string, password: string): Promise<IAPIAuthRequest> {
    const result = await firstValueFrom(this.apiAuthService.authAuthenticate(email, password));
    return result;
  }

  async getBrokerages() {
    const brokerages = await this.busnBrokerService.getBrokerage();

    if (brokerages && this.sharedConfig.user.broker && brokerages?.intercom_id) {
      SharedConfig.appCredentials.intercom_app_id = brokerages.intercom_id;
    }
  }

  async subscribeToFirebaseSubscriptionWatch(userId: number) {
    this.busnUserService.subscribeToFirebaseSubscriptionWatch(userId);
  }

  async setLocallyInsurers(): Promise<Array<IInsurer>> {
    return await this.busnPolicyService.getInsurers();
  }

  async initializeFirebaseAnalytics() {
    await this.busnThirdPartyService.initializeFirebaseAnalytics();
  }

  setPlatform() {
    if (this.platform.is('mobileweb')) {
      SharedConfig.platformType = 'mobileweb';
    }
    if (this.platform.is('cordova')) {
      SharedConfig.platformType = 'cordova';
    }
    if (this.platform.is('desktop')) {
      SharedConfig.platformType = 'desktop';
    }
    if (this.platform.is('electron')) {
      SharedConfig.platformType = 'electron';
    }
    if (this.platform.is('hybrid')) {
      SharedConfig.platformType = 'hybrid';
    }
    if (this.platform.is('ios')) {
      SharedConfig.platformType = 'ios';
    }
    if (this.platform.is('ipad')) {
      SharedConfig.platformType = 'ipad';
    }
    if (this.platform.is('mobile')) {
      SharedConfig.platformType = 'mobile';
    }
    if (this.platform.is('mobileweb')) {
      SharedConfig.platformType = 'mobileweb';
    }
    if (this.platform.is('phablet')) {
      SharedConfig.platformType = 'phablet';
    }
    if (this.platform.is('pwa')) {
      SharedConfig.platformType = 'pwa';
    }
    if (this.platform.is('tablet')) {
      SharedConfig.platformType = 'tablet';
    }
    if (this.platform.is('desktop')) {
      SharedConfig.platformType = 'desktop';
    }
  }

  async getUser() {
    return await this.busnUserService.getUser(SharedConfig.firebaseUserKey);
  }

  async setQuoteRequests() {
    this.sharedConfig.setQuoteRequests(await this.busnQuoteScheduleService.getQuoteRequests());
  }

  async setAppSettings(): Promise<IAppSettings> {
    const settings = await firstValueFrom(this.apiSettingsService.getAppSettings());
    this.sharedConfig.setAppSettings(settings.results);
    return this.sharedConfig.appSettings;
  }
}
