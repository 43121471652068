import { Injectable } from '@angular/core';
import { IQuote } from '../interfaces/quote.interface';

@Injectable({
  providedIn: 'root',
})
export class SharedObjectTransformationService {
  constructor() {}

  incrementGloballyComparedQuoteOne(
    quotes: Array<IQuote>,
    currentlySelectedQuoteOne: IQuote,
    currentlySelectedQuoteTwo: IQuote,
    runningIndex = -1
  ): IQuote {
    if (quotes.length > 2) {
      let newQuoteToCompareOne: IQuote;

      if (runningIndex > -1) {
        newQuoteToCompareOne = quotes[runningIndex];
      } else {
        const selectedIndex = quotes.findIndex((x) => x.id === currentlySelectedQuoteOne.id) + 1;
        newQuoteToCompareOne = quotes[selectedIndex];
      }

      if (newQuoteToCompareOne && newQuoteToCompareOne.id != currentlySelectedQuoteTwo.id) {
        return newQuoteToCompareOne;
      } else if (!newQuoteToCompareOne) {
        return this.incrementGloballyComparedQuoteOne(quotes, currentlySelectedQuoteOne, currentlySelectedQuoteTwo, (runningIndex = 0));
      } else {
        const selectedIndex = quotes.findIndex((x) => x.id === newQuoteToCompareOne.id) + 1;
        return this.incrementGloballyComparedQuoteOne(quotes, currentlySelectedQuoteOne, currentlySelectedQuoteTwo, selectedIndex);
      }
    } else {
      return currentlySelectedQuoteOne;
    }
  }

  incrementGloballyComparedQuoteTwo(
    quotes: Array<IQuote>,
    currentlySelectedQuoteOne: IQuote,
    currentlySelectedQuoteTwo: IQuote,
    runningIndex = -1
  ): IQuote {
    if (quotes.length > 2) {
      let newQuoteToCompareTwo: IQuote;

      if (runningIndex > -1) {
        newQuoteToCompareTwo = quotes[runningIndex];
      } else {
        const selectedIndex = quotes.findIndex((x) => x.id === currentlySelectedQuoteTwo.id) + 1;
        newQuoteToCompareTwo = quotes[selectedIndex];
      }

      if (newQuoteToCompareTwo && newQuoteToCompareTwo.id != currentlySelectedQuoteOne.id) {
        return newQuoteToCompareTwo;
      } else if (!newQuoteToCompareTwo) {
        return this.incrementGloballyComparedQuoteTwo(quotes, currentlySelectedQuoteOne, currentlySelectedQuoteTwo, (runningIndex = 0));
      } else {
        const selectedIndex = quotes.findIndex((x) => x.id === newQuoteToCompareTwo.id) + 1;
        return this.incrementGloballyComparedQuoteTwo(quotes, currentlySelectedQuoteOne, currentlySelectedQuoteTwo, selectedIndex);
      }
    } else {
      return currentlySelectedQuoteTwo;
    }
  }
}
