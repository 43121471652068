import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IQuestion, SharedDialogService } from '@takectrl-multiapp/svcs-share-config';

@Component({
  selector: 'input-check-list-single-select',
  templateUrl: './input-check-list-single-select.component.html',
  styleUrls: ['./input-check-list-single-select.component.scss'],
})
export class InputCheckListSingleSelectComponent implements OnInit {
  @Input() question: IQuestion;
  @Input() showHeading: boolean;
  @Input() readOnly: boolean;
  @Output() answerQuestion: EventEmitter<IQuestion> = new EventEmitter();
  constructor(
    public sharedDialogService: SharedDialogService,
  ) {}

  ngOnInit(): void {
  }

  answerValue(answer: string) {
    if (answer.toLocaleLowerCase() === 'yes' || answer.toLocaleLowerCase() === 'no') {
      this.question.underwriting_answers[0].object_answer = answer.toLocaleLowerCase() === 'yes';
    } else {
      this.question.underwriting_answers[0].object_answer = answer;
    }
    this.answerQuestion.emit(this.question);
  }
}
