import { Component, OnInit } from '@angular/core';
import { SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import '@lottiefiles/lottie-player';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
@Component({
  selector: 'kingprice-wallet-animation',
  templateUrl: './kingprice-wallet-animation.component.html',
  styleUrls: ['./kingprice-wallet-animation.component.scss'],
})
export class KingpriceWalletAnimationComponent implements OnInit {
  showAn: boolean = true;
  insurerPopup: boolean = false;
  constructor(public sharedConfig: SharedConfig, public busnThirdPartyService: BUSNThirdPartyService) {}

  ngOnInit(): void {}

  showIntercom() {
    this.busnThirdPartyService.intercomDisplayLauncher();
  }

  // TODO: USE BELOW TO CLOSE INSURER POPUP

  closeInsurerPopup() {
    SharedConfig.insurerPopup = false;
  }
}
