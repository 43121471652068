import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import { UIAppInitializationService } from '../../services/ui-app-initialization.service';
declare const dataLayer: any[];

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewChecked {
  public show = true;
  constructor(
    public thirdpartyService: BUSNThirdPartyService,
    public uiAppInitializationService: UIAppInitializationService,
    public sharedConfig: SharedConfig
  ) {}

  async ngAfterViewChecked(): Promise<void> {}

  async ngOnInit(): Promise<void> {
    dataLayer.push({
      event: 'page_show',
      screenPath: 'home',
      screenName: 'home',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }
}
