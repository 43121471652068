<login-with-email-password
  *ngIf="(!sharedConfig.routeParamUserMsisdn || sharedConfig.routeParamLogInWithOTP) && !showOTPScreen"
  (navForgotPasswordPage)="forgotPassword()"
  navForgotPasswordPage
  (signUp)="signUp()"
  (routeUserToCorrectHomePage)="routeUserToCorrectHomePage()"
></login-with-email-password>
<login-with-otp
  *ngIf="sharedConfig.routeParamUserMsisdn || showOTPScreen"
  (routeUserToCorrectHomePage)="routeUserToCorrectHomePage()"
  (navToResetPasswordPage)="resetPassword()"
></login-with-otp>
