import { AppClientDetails } from '@takectrl-multiapp/svcs-share-config';

export const environment = {
  production: false,
  app_client_detail: AppClientDetails.CTRL,
  firebase: {
    apiKey: 'AIzaSyDy9QHx_X7qpgG9lj-xLyxBGhXzsqgoBDA',
    authDomain: 'takectrl-709cd.firebaseapp.com',
    databaseURL: 'https://takectrl-709cd.firebaseio.com',
    projectId: 'takectrl-709cd',
    storageBucket: 'takectrl-709cd.appspot.com',
    messagingSenderId: '60080011007',
    appId: '1:60080011007:web:06b3646e68fd7002717eca',
    measurementId: 'G-X6SMB70Z7H',
  },
  application: {
    login_heading: 'EXPERIMENTAL 🚀',
    login_heading_color: '#c93ac2',
  },
  bugsnagKey: '',
  ctrl_api_url: 'https://staging-api-flask.ctrl.co.za/api',
  intercom_app_id: 'dbdmmuo7',
  mix_panel_key: 'dd37f5b585c6e0eb5263c21673de0937',
  facebook_web_logging_enabled: false,
  firebase_functions_url: 'https://us-central1-ctrl-prod.cloudfunctions.net',
  postal_search_api_url: 'https://staging-postalcodes.takectrl.co.za',
  //rbix_api_url: 'http://127.0.0.1:8080/rubinx-2696d/us-central1/rbix',
  rbix_api_url: 'https://us-central1-rubinx-2696d.cloudfunctions.net/rbix',
  rbix_token:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJrZXkiOiIwSng4Q2tXU05XWmJPSmtwMG1KV3ZYU3poVTgzIiwiZW1haWwiOiJhZG1pbkB0YWtlY3RybC5jby56YSIsImZpcnN0TmFtZSI6IlBpZXRlciIsImxhc3ROYW1lIjoiVmVudGVyIiwiY2xpZW50T3JnYW5pemF0aW9uTmFtZSI6IkN0cmwgVGVjaG5vbG9naWVzIChQdHkpIEx0ZCIsImNsaWVudEtleSI6Ii1OY1JfZG54eXN3UElwZWdrQ01FIiwiaWF0IjoxNjkyNjk2NzUwfQ.cLcZrSNjpzKYP5KeJ44yxe7bSI_nE4T2TNrz6PUx2QE',
};
