import { Injectable } from '@angular/core';
import { IEmergencyCategories, SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import { APIQuestionService } from './api-question.service';
import { APIHttpService } from './api-http.service';
import { Observable } from 'rxjs';
import { ICtrlResponse } from '../interfaces/api.interface';
@Injectable({
  providedIn: 'root',
})
export class APIQuoteScheduleFirebaseService {
  constructor(
    public sharedConfig: SharedConfig,
    private readonly ctrlHttp: APIHttpService,
    public apiQuestionService: APIQuestionService
  ) {}

  async getUnderwritingQuestions() {
    if (!SharedConfig.underwritingQuestionsAll) {
      SharedConfig.underwritingQuestionsAll = await this.apiQuestionService.getUnderwritingQuestions();
    }
  }

  emergencyCategories(insurer: string): Observable<ICtrlResponse<IEmergencyCategories>> {
    return this.ctrlHttp.get(`/emergency-categories/`, { insurer });
  }
}
