import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IQuestion, SharedConfig, SharedFormulaService } from '@takectrl-multiapp/svcs-share-config';
@Component({
  selector: 'input-datepicker',
  templateUrl: './input-datepicker.component.html',
  styleUrls: ['./input-datepicker.component.scss'],
})
export class InputDatepickerComponent implements OnInit {
  maxDate: any;
  @Input() question: IQuestion;
  @Input() showHeading: boolean;
  @Input() error: string;
  @Input() readOnly: boolean;
  @Output() answerQuestion: EventEmitter<IQuestion> = new EventEmitter();
  currentYear: number;
  constructor(public sharedConfig: SharedConfig, public sharedFormulaService: SharedFormulaService) {}

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.currentYear = this.currentYear + 5;
    this.maxDate = this.currentYear;
    if (this.question.underwriting_answers[0].object_field === 'regular_driver_license_date') {
      this.maxDate = this.sharedFormulaService.formatDateyyymmdd(Date.now());
    }
    // This should not be here, the below piece of code auto submits the summary page continuously
    // if (this.question.underwriting_answers[0].object_answer) {
    //   this.answerQuestion.emit(this.question)
    // }
  }

  openCalendar() {
    if (this.question.underwriting_answers[0].object_field === 'regular_driver_license_expiry_date') {
      this.maxDate = this.currentYear;
    }
    SharedConfig.showCalendarPopup = true;
    SharedConfig.hideProgressBar = true;
    SharedConfig.showFullScreen = true;
  }

  answerValue(dateValue) {
    this.question.underwriting_answers[0].object_answer = dateValue.split('T')[0];
    this.answerQuestion.emit(this.question);
  }
}
