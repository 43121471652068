import { NgModule, inject } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, UrlSerializer } from '@angular/router';
import { canActivate } from '@takectrl-multiapp/ui-pages';
import { UIAppInitializationService } from 'libs/ui-pages/src/lib/services/ui-app-initialization.service';
import { CustomUrlSerializer } from './custom-url-serializer.module';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'public-landing',
    pathMatch: 'full',
  },
  {
    path: 'public-landing',
    loadChildren: () => import('./pages/public-landing/public-landing.module').then((m) => m.PublicLandingPageModule),
  },
 
  {
    path: 'public-login',
    loadChildren: () => import('./pages/public-login/public-login.module').then((m) => m.PublicLoginPageModule),
  },
  {
    path: 'public-terms-conditions',
    loadChildren: () => import('./pages/public-terms-conditions/public-terms-conditions.module').then((m) => m.PublicTermsConditionsPageModule),
  },
  {
    path: 'public-forgot-password',
    loadChildren: () => import('./pages/public-forgot-password/public-forgot-password.module').then((m) => m.PublicForgotPasswordPageModule),
  },
  {
    path: 'public-sign-up',
    loadChildren: () => import('./pages/public-sign-up/public-sign-up.module').then((m) => m.PublicSignUpPageModule),
  },
 
  {
    path: 'public-delete-account-info',
    loadChildren: () =>
      import('./pages/public-delete-account-info/public-delete-account-info.module').then((m) => m.PublicDeleteAccountInfoPageModule),
  },
  {
    path: 'download-app',
    loadChildren: () => import('./pages/download-app/download-app.module').then((m) => m.DownloadAppPageModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'home-claim',
    loadChildren: () => import('./pages/home-claim/home-claim.module').then((m) => m.HomeClaimPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'home-emergency',
    loadChildren: () => import('./pages/home-emergency/home-emergency.module').then((m) => m.HomeEmergencyPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'home-quote',
    loadChildren: () => import('./pages/home-quote/home-quote.module').then((m) => m.HomeQuotePageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'home-wallet',
    loadChildren: () => import('./pages/home-wallet/home-wallet.module').then((m) => m.HomeWalletPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'side-menu-change-email',
    loadChildren: () => import('./pages/side-menu-change-email/side-menu-change-email.module').then((m) => m.SideMenuChangeEmailPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'side-menu-change-password',
    loadChildren: () => import('./pages/side-menu-change-password/side-menu-change-password.module').then((m) => m.SideMenuChangePasswordPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'side-menu-referral',
    loadChildren: () => import('./pages/side-menu-referral/side-menu-referral.module').then((m) => m.SideMenuReferralPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'side-menu-terms-conditions',
    loadChildren: () =>
      import('./pages/side-menu-terms-conditions/side-menu-terms-conditions.module').then((m) => m.SideMenuTermsConditionsPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'side-menu-fsb-licence',
    loadChildren: () => import('./pages/side-menu-fsb-licence/side-menu-fsb-licence.module').then((m) => m.SideMenuFsbLicencePageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'side-menu-useful-documents',
    loadChildren: () =>
      import('./pages/side-menu-useful-documents/side-menu-useful-documents.module').then((m) => m.SideMenuUsefulDocumentsPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'policy-renewal',
    loadChildren: () => import('./pages/policy-renewal/policy-renewal.module').then((m) => m.PolicyRenewalPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'policy-renewal-details',
    loadChildren: () => import('./pages/policy-renewal-details/policy-renewal-details.module').then((m) => m.PolicyRenewalDetailsPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'quote-added-benefits',
    loadChildren: () => import('./pages/quote-added-benefits/quote-added-benefits.module').then((m) => m.QuoteAddedBenefitsPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'wallet-asset-detail',
    loadChildren: () => import('./pages/wallet-asset-details/wallet-asset-details.module').then((m) => m.WalletAssetDetailsPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'wallet-asset',
    loadChildren: () => import('./pages/wallet-asset/wallet-asset.module').then((m) => m.WalletAssetPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'wallet-asset',
    loadChildren: () => import('./pages/wallet-asset/wallet-asset.module').then((m) => m.WalletAssetPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'wallet-asset-details',
    loadChildren: () => import('./pages/wallet-asset-details/wallet-asset-details.module').then((m) => m.WalletAssetDetailsPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'quote-questions',
    loadChildren: () => import('./pages/quote-questions/quote-questions.module').then((m) => m.QuoteQuestionsPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'quote-questions-object-mapping',
    loadChildren: () =>
      import('./pages/quote-questions-object-mapping/quote-questions-object-mapping.module').then((m) => m.QuoteQuestionsPageObjectMappingModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'quote-banking-details',
    loadChildren: () => import('./pages/quote-banking-details/quote-banking-details.module').then((m) => m.QuoteBankingDetailsPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'quote-accept',
    loadChildren: () => import('./pages/quote-accept/quote-accept.module').then((m) => m.QuoteAcceptPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'quote-complete',
    loadChildren: () => import('./pages/quote-complete/quote-complete.module').then((m) => m.QuoteCompletePageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'quote-list-retrieved-summary',
    loadChildren: () =>
      import('./pages/quote-list-retrieved-summary/quote-list-retrieved-summary.module').then((m) => m.QuoteListRetrievedSummaryPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'quote-question-summary',
    loadChildren: () => import('./pages/quote-question-summary/quote-question-summary.module').then((m) => m.QuoteQuestionSummaryPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'quote-new',
    loadChildren: () => import('./pages/quote-new/quote-new.module').then((m) => m.QuoteNewPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'quote-claims-history-check',
    loadChildren: () =>
      import('./pages/quote-claims-history-check/quote-claims-history-check.module').then((m) => m.QuoteClaimsHistoryCheckPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'quote-comparison-overall',
    loadChildren: () => import('./pages/quote-comparison-overall/quote-comparison-overall.module').then((m) => m.QuoteComparisonOverallPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'quote-comparison-details',
    loadChildren: () => import('./pages/quote-comparison-details/quote-comparison-details.module').then((m) => m.QuoteComparisonDetailsPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'quote-info',
    loadChildren: () => import('./pages/quote-info/quote-info.module').then((m) => m.QuoteInfoPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'update',
    loadChildren: () => import('./pages/update/update.module').then((m) => m.UpdatePageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'personal-details',
    loadChildren: () => import('./pages/personal-details/personal-details.module').then((m) => m.PersonalDetailsPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'quote-needs-analysis',
    loadChildren: () => import('./pages/quote-needs-analysis/quote-needs-analysis.module').then((m) => m.QuoteNeedsAnalysisPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'quote-banking-details-casa',
    loadChildren: () =>
      import('./pages/quote-banking-details-casa/quote-banking-details-casa.module').then((m) => m.QuoteBankingDetailsCasaPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'quote-endorsement-accept',
    loadChildren: () => import('./pages/quote-endorsement-accept/quote-endorsement-accept.module').then((m) => m.QuoteEndorsementAcceptPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'schedules-remove-asset',
    loadChildren: () => import('./pages/schedules-remove-asset/schedules-remove-asset.module').then((m) => m.SchedulesRemoveAssetPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'download-app',
    loadChildren: () => import('./pages/download-app/download-app.module').then((m) => m.DownloadAppPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },

  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/external-privacy-policy/external-privacy-policy.module').then((m) => m.PrivacyPolicyPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'quote-summary-not-editable',
    loadChildren: () =>
      import('./pages/quote-summary-not-editable/quote-summary-not-editable.module').then((m) => m.QuoteSummaryNotEditablePageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'admin-panel',
    loadChildren: () => import('./pages/admin-panel/admin-panel.module').then((m) => m.AdminPanelPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'admin-panel-quote-on-behalf',
    loadChildren: () => import('./pages/admin-panel-quote-on-behalf/admin-panel-quote-on-behalf.module').then((m) => m.AdminPanelQuoteOnBehalfModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
  {
    path: 'side-menu-motor-group-details',
    loadChildren: () =>
      import('./pages/side-menu-motor-group-details/side-menu-motor-group-details.module').then((m) => m.SideMenuMotorGroupDetailsPageModule),
  },
  {
    path: 'ctrl-center',
    loadChildren: () => import('./pages/ctrl-center/ctrl-center.module').then((m) => m.CtrlCenterPageModule),
    canActivate: [canActivate],
    resolve: { data: () => inject(UIAppInitializationService).hotReloadCore() },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  providers: [{ provide: UrlSerializer, useClass: CustomUrlSerializer }],
})
export class AppRoutingModule {}
