import { Injectable, NgZone } from '@angular/core';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(
    private readonly zone: NgZone,
    private readonly navCtrl: NavController,
  ) {}

  navigatePageForward(page: string) {
    this.zone.run(async () => {
      this.navCtrl.navigateForward([page]);
    });
  }

  navigatePageRoot(page: string) {
    this.zone.run(async () => {
      this.navCtrl.navigateRoot([page]);
    });
  }

  navigatePageBack(page: string) {
    this.zone.run(async () => {
      this.navCtrl.navigateBack([page]);
    });
  }

  navigatePopBack() {
    this.zone.run(() => {
      this.navCtrl.pop();
    });
  }
  
}
