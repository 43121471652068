import { NavController, LoadingController, AlertController } from '@ionic/angular';
import { Component, OnInit, NgZone, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { SharedConfig } from '@takectrl-multiapp/svcs-share-config';
declare const dataLayer: unknown[];

@Component({
  selector: 'admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss'],
})
export class AdminPanelComponent implements OnInit {
  @Output() navigatePage: EventEmitter<string> = new EventEmitter<string>();
  constructor(
    public navCtrl: NavController,
    public zone: NgZone,
    public loadingCtrl: LoadingController,
    public route: Router,
    public alertController: AlertController,
    public sharedConfig: SharedConfig
  ) {}

  ngOnInit(): void {
    dataLayer.push({
      event: 'page_show',
      screenPath: 'admin-panel',
      screenName: 'admin-panel',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }

  async ngAfterViewInit(): Promise<void> {}

  navQuoteOnBehalf() {
    this.navigatePage.emit('admin-panel-quote-on-behalf');
  }
}
