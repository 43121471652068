import { Injectable } from '@angular/core';
import { APIHttpService } from './api-http.service';
import { IRenewal, IRenewalUpdateResponse } from '@takectrl-multiapp/svcs-share-config';
import { Observable } from 'rxjs';
import { ICtrlResponse } from '../interfaces/api.interface';

@Injectable({
  providedIn: 'root',
})
export class APIRenewalService {
  constructor(private readonly ctrlHttp: APIHttpService) {}

  checkRenewals(): Observable<ICtrlResponse<Array<IRenewal>>> {
    return this.ctrlHttp.get(`/renewals/`);
  }

  updateRenewal(schedule_id: number): Observable<ICtrlResponse<IRenewalUpdateResponse>> {
    const scheduleStatusUpdatePayload = {
      status: 'RENEWAL_ACCEPTED',
    };
    return this.ctrlHttp.patch(`/schedules/${schedule_id}/`, scheduleStatusUpdatePayload);
  }
}
