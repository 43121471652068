<div class="backdrop"></div>
<div class="popup-native-select limit-width-on-desktop-float">
  <div class="text-center">
    <h2 class="h2-bold">{{ questionSelected.wording_for_summary }}</h2>
  </div>
  <div class="select-wrapper">
    <ion-item
      mode="md"
      *ngFor="let selectOption of questionSelected.options"
      lines="none"
      [ngClass]="{ active: selectOption == questionSelected.underwriting_answers[0].object_answer }"
      (click)="setAnswer(selectOption)"
    >
      <ion-text>{{ selectOption }}</ion-text>
    </ion-item>
  </div>
  <div class="buttons-wrapper">
    <ng-content></ng-content>
  </div>
</div>
