import { SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { PageType } from 'libs/svcs-share-config/src/lib/types/page.type';
declare const dataLayer: unknown[];

@Component({
  selector: 'claim',
  templateUrl: './claim.component.html',
  styleUrls: ['./claim.component.scss'],
})
export class ClaimComponent implements OnInit, AfterViewChecked {
  constructor(public sharedConfig: SharedConfig) {}

  ngOnInit(): void {
    SharedConfig.selectedPage = PageType.claim;
    dataLayer.push({
      event: 'page_show',
      screenPath: 'claim',
      screenName: 'claim',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }

  ngAfterViewChecked(): void {}
}
