import { Injectable } from '@angular/core';
import { AssetType, IFirebaseLogin, IUser, QuoteRequestStatusType, QuoteRequestType } from '@takectrl-multiapp/svcs-share-config';
import { LoadingController } from '@ionic/angular';
import {
  DateService,
  IEmailAuth,
  IInsuranceHistory,
  IPersonalDetails,
  IQuestion,
  IReferralMailPayload,
  IUserAllRelatedData,
  SharedAPIDataCalculationService,
  SharedConfig,
  SharedDialogService,
  UserFactory,
} from '@takectrl-multiapp/svcs-share-config';
import { APIMailService, APIQuestionService, APIQuoteScheduleService, APIUserService, ApiAuthService } from '@takectrl-multiapp/svcs-ctrl-api';
import { ReqResponse } from '@takectrl-multiapp/svcs-share-config';
import { ICtrlResponse } from 'libs/svcs-ctrl-api/src/lib/interfaces/api.interface';
import { BUSNQuestionService } from './busn-question.service';
import { BUSNQuoteScheduleService } from './busn-quote-schedule.service';
import { Storage } from '@capacitor/storage';
import { APIHttpService } from 'libs/svcs-ctrl-api/src/lib/services/api-http.service';
import { firstValueFrom } from 'rxjs';
import { BUSNThirdPartyService } from './busn-third-party.service';
import { SharedConfigService } from 'libs/svcs-share-config/src/lib/services/shared-config.service';
import { PageType } from 'libs/svcs-share-config/src/lib/types/page.type';

@Injectable({
  providedIn: 'root',
})
export class BUSNUserService {
  public submittedForm = false;
  subscriberHitTimeout = 0;

  constructor(
    public loadingCtrl: LoadingController,
    public apiQuestionService: APIQuestionService,
    public apiUserService: APIUserService,
    public apiAuthService: ApiAuthService,
    public dateService: DateService,
    private readonly sharedQuoteScheduleService: SharedAPIDataCalculationService,
    public busnQuestionService: BUSNQuestionService,
    public busnQuoteScheduleService: BUSNQuoteScheduleService,
    private readonly sharedDialogService: SharedDialogService,
    public sharedConfig: SharedConfig,
    public sharedConfigService: SharedConfigService,
    public apiQuoteScheduleService: APIQuoteScheduleService,
    public busnThirdPartyService: BUSNThirdPartyService,
    private readonly apiMailService: APIMailService,
    private readonly httpService: APIHttpService
  ) {}
  // TODO REFACTOR REMOVE PROMISES
  async getUser(firebase_uuid: string): Promise<IUser> {
    const userResult = await firstValueFrom(this.apiUserService.getUser(firebase_uuid));
    return userResult.results;
  }

  async getPermissions() {
    const permissions = await firstValueFrom(this.apiUserService.getUserPermissions());
    return permissions.results;
  }

  async getUserByEmail(email: string): Promise<IUser> {
    return (await firstValueFrom(this.apiUserService.getUserByEmail(email))).results;
  }

  async updateUser(user: IUser): Promise<void> {
    await firstValueFrom(this.apiUserService.updateUser(user));
  }

  async getAllUserRelatedData(): Promise<IUserAllRelatedData> {
    return (await firstValueFrom(this.apiUserService.getAllUserRelatedData())).results as IUserAllRelatedData;
  }

  async updateFCMToken(token: string): Promise<IUser> {
    this.sharedConfig.user.account_details.fcm_token = token;
    return (await firstValueFrom(this.apiUserService.updateUser(this.sharedConfig.user))).results;
  }

  async createUser(
    email: string,
    name: string,
    surname: string,
    referralCode: string,
    hard_of_hearing: boolean,
    popiaConsentGiven = false,
    popia_consent_message = ''
  ): Promise<ICtrlResponse<IUser>> {
    const user: IUser = UserFactory.initUser(
      email,
      name,
      surname,
      referralCode,
      hard_of_hearing,
      popiaConsentGiven,
      popia_consent_message,
      this.sharedConfig.appSettings.referral_code_prefix +
        name.charAt(0).toUpperCase() +
        surname.charAt(0).toUpperCase() +
        Math.random().toString(36).substring(3).slice(0, 3).toUpperCase()
    );
    const userResponse = await firstValueFrom(this.apiUserService.createUser(user));
    return userResponse;
  }

  async updatePersonalDetails(personalDetails: IPersonalDetails) {
    await firstValueFrom(this.apiUserService.updatePersonalDetails(personalDetails));
  }

  async updateUserInsuranceHistory(insuranceHistory: IInsuranceHistory) {
    await firstValueFrom(this.apiUserService.updateUserInsuranceHistory(insuranceHistory));
  }

  async createUserInsuranceHistory() {
    await firstValueFrom(this.apiUserService.createUserInsuranceHistory(this.sharedConfig.user.insurance_history));
  }

  async updateUserInsuranceHistories(questions: Array<IQuestion>) {
    if (!this.sharedConfig.user.insurance_history) this.sharedConfig.user.insurance_history = {};
    for (const question of questions) {
      for (const underwritingAnswer of question.underwriting_answers) {
        this.sharedConfig.user.insurance_history[underwritingAnswer.object_field] = underwritingAnswer.object_answer;
      }
    }
    this.updateUserInsuranceHistory(this.sharedConfig.user.insurance_history);
  }

  async updateUserDetailsAnswers(questions: Array<IQuestion>) {
    for (const question of questions) {
      for (const underwritingAnswer of question.underwriting_answers) {
        this.sharedConfig.user.personal_details[underwritingAnswer.object_field] = underwritingAnswer.object_answer;
      }
    }
    this.updatePersonalDetails(this.sharedConfig.user.personal_details);
  }

  async changeEmailAddress(new_email: string): Promise<ICtrlResponse<unknown>> {
    return await firstValueFrom(this.apiUserService.updateUserEmail(new_email));
  }

  changePassword(oldPassword: string, newPassword: string): void {
    this.busnThirdPartyService.firebaseChangePassword(oldPassword, newPassword);
  }

  resetPassword(userEmail: string): Promise<unknown> {
    return this.busnThirdPartyService.firebaseResetPassword(userEmail);
  }

  async setUserPassword(uid: string, email: string, new_password: string): Promise<ReqResponse> {
    const url = `${SharedConfig.appCredentials.firebase_functions_url}/authentication/update-user-password`;
    return (await firstValueFrom(
      this.httpService.post(url, {
        uid,
        email,
        new_password,
      })
    )) as ReqResponse;
  }

  async checkEmailForSpam(email: string): Promise<IEmailAuth> {
    if (!this.sharedConfig.appSettings?.validate_email_for_spam_enabled) {
      return {
        pass_validation: true,
        email: email,
        success: true,
      };
    } else {
      return await firstValueFrom(this.apiUserService.checkEmailForSpam(email)).then((data) => {
        return data;
      });
    }
  }

  async registerUser(email: string, password: string): Promise<IFirebaseLogin> {
    return this.busnThirdPartyService.firebaseSignUpWithEmailAndPassword(email, password);
  }

  async generateUserIntercomID(): Promise<IUser> {
    console.log('Generating Intercom ID from backend');
    const response = await firstValueFrom(this.apiUserService.generateUserIntercomID());
    return response.results;
  }

  async deleteUserAccount(): Promise<ICtrlResponse<unknown>> {
    return await firstValueFrom(this.apiUserService.deleteUserAccount());
  }

  async checkIfUserMsisdnIsRegistered(phoneNumber: string): Promise<ReqResponse> {
    return await firstValueFrom(this.apiUserService.checkIfUserMsisdnIsRegistered(phoneNumber));
  }

  async referFriend(name: string, surname: string, email: string) {
    this.apiUserService.addReferral(name, surname, email);
  }

  async newReferralEmail(name: string, surname: string, email: string): Promise<ICtrlResponse<unknown>> {
    const payload: IReferralMailPayload = {
      user_id: this.sharedConfig.user.personal_details.user_id,
      email: email,
      preferred_name: name,
      surname: surname,
    };
    return await firstValueFrom(this.apiMailService.newReferralEmail(payload));
  }

  subscribeToFirebaseSubscriptionWatch(userId: number): void {
    // Check if there's already an active subscription for this user ID
    if (SharedConfig.activeSubscriptions.has(userId)) {
      console.log(`Subscription for user ${userId} is already active.`);
      return;
    }

    // Mark the subscription as active
    SharedConfig.activeSubscriptions.add(userId);

    // Perform the Firebase subscription
    this.busnThirdPartyService.firebaseDatabaseSubscribe(`subscriptions/${userId}`, async () => {
      await this.setAllUserRelatedDataSubscriber(userId);
    });
  }

  // Note: Updated to take userId as parameter to clean up the active subscription set when done
  async setAllUserRelatedDataSubscriber(userId: number) {
    const userData: IUserAllRelatedData = await this.getAllUserRelatedData();
    this.checkUserAddressIsPartialAndRemoveHisAddress(userData.user);

    if (userData.user.personal_details) {
      userData.user.personal_details.is_completed =
        this.busnQuestionService.isAssetQuestionsComplete(AssetType.UserPersonalDetails, userData.user.personal_details, userData.user) &&
        this.busnQuestionService.isAssetQuestionsComplete(
          AssetType.UserInsuranceHistory,
          userData.user.insurance_history?.insurance_history,
          userData.user
        );
      this.sharedConfig.setUser(userData.user);
      this.sharedConfigService.setUser(userData.user);
    }

    //this.sharedConfig.setQuoteRequests(userData.quote_requests);
    if (userData.quote_requests && userData.quote_requests.length > 0) {
      const latestQuoteRequest = userData.quote_requests.reduce((prev, current) => (prev.id > current.id ? prev : current));
      this.sharedConfig.setQuoteRequest(latestQuoteRequest);
      this.sharedConfigService.setQuoteRequest(latestQuoteRequest);
      const hasSeenErrorPopup = (await Storage.get({ key: 'hasSeenErrorPopup' })).value;
      if (
        latestQuoteRequest.status === QuoteRequestStatusType.Failed &&
        !SharedConfig.acknowledgedQuoteRequestError &&
        hasSeenErrorPopup === 'false'
      ) {
        SharedConfig.headerForPopupWithChat = 'Oops';
        if (latestQuoteRequest.type === QuoteRequestType.Quote) {
          SharedConfig.messageForPopupWithChat =
            'Seems like something went wrong when trying to get you your quotes. Please try again, or let us know and we’ll investigate.';
        } else {
          SharedConfig.messageForPopupWithChat =
            'Seems like something went wrong when trying to get you your endorsement. Please try again, or let us know and we’ll investigate.';
        }
        SharedConfig.showPopupWithChat = true;
        SharedConfig.acknowledgedQuoteRequestError = true;
        Storage.set({
          key: 'hasSeenErrorPopup',
          value: 'true',
        });
      }
    } else {
      delete SharedConfig.quoteRequest;
    }

    this.busnQuestionService.setIsCompleteFlagOnAllAssetsIfCompleted(userData.user);

    this.sharedConfig.setSchedulesActive(userData.schedules);
    if (userData.schedules && userData.schedules.length > 0) {
      SharedConfig.showOnTrackPopup = false;
    }
    if (this.sharedConfig.schedulesActive && this.sharedConfig.schedulesActive.length > 0)
      SharedConfig.schedulesPremiumDetails = this.sharedQuoteScheduleService.getSchedulesTotalPremium(this.sharedConfig.schedulesActive);

    if (
      SharedConfig.showOnTrackPopup ||
      !this.sharedConfig.schedulesActive ||
      this.sharedConfig.schedulesActive.length === 0 ||
      (SharedConfig.quoteRequest &&
        SharedConfig.quoteRequest?.type === QuoteRequestType.Quote &&
        SharedConfig.quoteRequest?.status === QuoteRequestStatusType.Pending) ||
      SharedConfig.showSetNewPasswordComponent
    ) {
      SharedConfig.showFooterTabs = false;
    } else {
      SharedConfig.showFooterTabs = true;
    }

    SharedConfig.insured_vehicles = [];
    SharedConfig.insured_contents = [];
    SharedConfig.insured_buildings = [];
    SharedConfig.insured_motorcycles = [];
    SharedConfig.insured_caravans = [];
    SharedConfig.insured_vaps = [];
    SharedConfig.insured_all_risk = [];
    SharedConfig.insured_specified_items = [];

    for (const schedulesActive of this.sharedConfig.schedulesActive) {
      for (const vehicle of schedulesActive.insured_assets?.vehicles || []) {
        vehicle.policy_id = schedulesActive.policy_id;
        SharedConfig.insured_vehicles.push(vehicle);
      }
      for (const contents of schedulesActive.insured_assets?.contents || []) {
        contents.policy_id = schedulesActive.policy_id;
        SharedConfig.insured_contents.push(contents);
      }
      for (const buildings of schedulesActive.insured_assets?.buildings || []) {
        buildings.policy_id = schedulesActive.policy_id;
        SharedConfig.insured_buildings.push(buildings);
      }
      for (const motorcycles of schedulesActive.insured_assets?.motorcycles || []) {
        motorcycles.policy_id = schedulesActive.policy_id;
        SharedConfig.insured_motorcycles.push(motorcycles);
      }
      for (const caravans of schedulesActive.insured_assets?.caravans || []) {
        caravans.policy_id = schedulesActive.policy_id;
        SharedConfig.insured_caravans.push(caravans);
      }
      for (const all_risk of schedulesActive.insured_assets?.all_risks || []) {
        all_risk.policy_id = schedulesActive.policy_id;
        SharedConfig.insured_all_risk.push(all_risk);
      }
      for (const specified_items of schedulesActive.insured_assets?.specified_items || []) {
        specified_items.policy_id = schedulesActive.policy_id;
        SharedConfig.insured_specified_items.push(specified_items);
      }
      for (const vaps of schedulesActive.insured_assets?.vaps || []) {
        vaps.policy_id = schedulesActive.policy_id;
        SharedConfig.insured_vaps.push(vaps);
      }
    }

    this.checkIfWalletPageOnTrackPopupShouldBeHidden();

    // if (SharedConfig.selectedPage !==  PageType.quoteQuestions ) {
    //   this.sharedConfig.setShowCustomLoader(false)
    // }
  }

  async checkUserAddressIsPartialAndRemoveHisAddress(user: IUser) {
    if (!user.addresses) return;

    const isNullOrEmpty = (value: string) => value === null || value === undefined || value === '';

    const addressFields = [
      'risk_address_line1',
      'risk_address_code_id',
      'risk_address_line2',
      'risk_address_code_id_sa02',
      'risk_address_code_id_mnfg',
      'postal_code',
    ];

    if (addressFields.some((field) => isNullOrEmpty(user.addresses[field]))) {
      addressFields.forEach((field) => {
        user.addresses[field] = null;
      });

      user.addresses.risk_address_code = null;

      this.sharedConfig.user.addresses = null;
      this.sharedConfig.user.address_id = null;
      this.updateUser(this.sharedConfig.user);
    }
  }

  checkIfWalletPageOnTrackPopupShouldBeHidden() {
    const showOnTrackPopup = SharedConfig.showOnTrackPopup;
    const scheduleHasBeenSetToActive = SharedConfig.scheduleHasBeenSetToActive;
    const selectedPage = SharedConfig.selectedPage;
    if (
      !(SharedConfig.showOnTrackPopup && SharedConfig.scheduleHasBeenSetToActive) &&
      SharedConfig.quoteRequest?.status !== QuoteRequestStatusType.Pending &&
      (SharedConfig.selectedPage === 'wallet' || SharedConfig.selectedPage === 'home-quote')
    ) {
      this.sharedDialogService.hidePopupDialog();
    }
  }

  setWalletQuoteButton() {
    SharedConfig.quoteStatusButtonText = 'Start a new quote';
    if (this.sharedConfig.quoteRequest) {
      switch (this.sharedConfig?.quoteRequest?.status) {
        case QuoteRequestStatusType.Open:
          SharedConfig.quoteStatusButtonText = 'Continue my quote';
          break;
        case QuoteRequestStatusType.Submitted:
          SharedConfig.quoteStatusButtonText = 'Busy getting quotes';
          break;
        case QuoteRequestStatusType.Pending:
          SharedConfig.quoteStatusButtonText = 'Loading your policy';
          break;
        case QuoteRequestStatusType.WaitingOnInsurer:
          SharedConfig.quoteStatusButtonText = 'Waiting for Insurer';
          break;
        case QuoteRequestStatusType.WaitingOnQuotes:
          SharedConfig.quoteStatusButtonText = 'View my quote';
          break;
        case QuoteRequestStatusType.WaitingToAccept:
          SharedConfig.quoteStatusButtonText = 'View my quotes';
          break;
        default:
      }
    }
  }

  async updateUserItc(): Promise<void> {
    const timeStamp = new Date().getTime();
    this.sharedConfig.user.account_details.itc_check = true;
    this.sharedConfig.user.account_details.ctrl_consent_check = true;
    this.sharedConfig.user.account_details.itc_check_timestamp = timeStamp;
    this.sharedConfig.user.account_details.ctrl_consent_timestamp = timeStamp;

    firstValueFrom(
      this.apiUserService.updateUserAccountDetails({
        id: this.sharedConfig.user.account_details.id,
        itc_check: true,
        ctrl_consent_check: true,
        itc_check_timestamp: timeStamp,
        ctrl_consent_timestamp: timeStamp,
      })
    );
  }

  async getDocuments(): Promise<any[]> {
    const result = await firstValueFrom(this.apiUserService.getDocuments());
    return result['files'];
  }

  // TODO Has Role access check
  hasAccess(): boolean {
    return false;
  }
}
