<div class="input-wrapper">
  <ion-radio-group *ngIf="!readOnly">
    <ion-item
      mode="md"
      *ngFor="let underwritingAnswer of question.underwriting_answers; let i = index"
      lines="none"
      [ngClass]="{ active: underwritingAnswer.object_answer === true }"
      (click)="answerValue(i)"
    >
      <ion-text>{{ underwritingAnswer.place_holder }}</ion-text>
      <ion-icon
        class="checked"
        [ngClass]="{ selected: underwritingAnswer.object_answer !== undefind && underwritingAnswer.object_answer === true }"
        src="assets/icon/app-icons/tick-primary.svg"
        slot="end"
      ></ion-icon>
    </ion-item>
    <hr class="small-hr" />
    <ion-item mode="md" lines="none" [ngClass]="{ active: isNoneSelectedButtonChecked() }" (click)="noneSelectedDeselectAllOtherAnswers()">
      <ion-text>None of the above</ion-text>
      <ion-icon
        class="checked"
        [ngClass]="{ selected: isNoneSelectedButtonChecked() }"
        src="assets/icon/app-icons/tick-primary.svg"
        slot="end"
      ></ion-icon>
    </ion-item>
  </ion-radio-group>
  <ion-radio-group *ngIf="readOnly">
    <ion-item
      mode="md"
      *ngFor="let underwritingAnswer of question.underwriting_answers; let i = index"
      lines="none"
      [ngClass]="{ active: underwritingAnswer.object_answer === true }"
    >
      <ion-text>{{ underwritingAnswer.place_holder }}</ion-text>
      <ion-icon
        class="checked"
        [ngClass]="{ selected: underwritingAnswer.object_answer !== undefind && underwritingAnswer.object_answer === true }"
        src="assets/icon/app-icons/tick-primary.svg"
        slot="end"
      ></ion-icon>
    </ion-item>
    <hr class="small-hr" />
    <ion-item mode="md" lines="none" [ngClass]="{ active: isNoneSelectedButtonChecked() }">
      <ion-text>None of the above</ion-text>
      <ion-icon
        class="checked"
        [ngClass]="{ selected: isNoneSelectedButtonChecked() }"
        src="assets/icon/app-icons/tick-primary.svg"
        slot="end"
      ></ion-icon>
    </ion-item>
  </ion-radio-group>
</div>
