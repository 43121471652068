import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { SharedConfig } from '@takectrl-multiapp/svcs-share-config';
declare const dataLayer: any[];

@Component({
  selector: 'quote-info',
  templateUrl: './quote-info.component.html',
  styleUrls: ['./quote-info.component.scss'],
})
export class QuoteInfoComponent implements OnInit {
  @ViewChild(IonSlides) slides: IonSlides;
  @Output() navigateHomeQuote: EventEmitter<unknown> = new EventEmitter();
  disableLeft = true;
  disableRight: boolean;
  slideOpts = {
    slidesPerView: 1,
    loop: false,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
      stopOnLast: true,
    },
  };
  constructor(public thirdpartyService: BUSNThirdPartyService, public sharedConfig: SharedConfig) {}

  ngOnInit(): void {
    dataLayer.push({
      event: 'page_show',
      screenPath: 'quote-info',
      screenName: 'quote-info',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }

  navHomeQuote() {
    this.navigateHomeQuote.emit();
  }

  sliderAtEnd() {
    this.slides.stopAutoplay();
  }

  slideDidChange() {
    const promiseBeginning = this.slides.isBeginning();
    const promiseEnd = this.slides.isEnd();
    Promise.all([promiseBeginning, promiseEnd]).then((data) => {
      data[0] ? (this.disableLeft = true) : (this.disableLeft = false);
      data[1] ? (this.disableRight = true) : (this.disableRight = false);
    });
  }

  slidePrevious() {
    this.slides.slidePrev(100, false);
    const promiseBeginning = this.slides.isBeginning();
    Promise.all([promiseBeginning]).then((data) => {
      data[0] ? (this.disableRight = true) : (this.disableRight = false);
      if (data[0]) {
        this.disableLeft = true;
        this.disableRight = false;
      }
    });
  }

  slideNext() {
    this.disableLeft = false;
    this.slides.slideNext(100, false);
    const promiseEnd = this.slides.isEnd();
    Promise.all([promiseEnd]).then((data) => {
      data[0] ? (this.disableRight = true) : (this.disableRight = false);
      if (data[0]) {
        this.disableRight = true;
        this.disableLeft = false;
      }
    });
  }
}
