import { APIPolicyService } from '@takectrl-multiapp/svcs-ctrl-api';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AssetType, IClaim, IClaimCategories, IClaimMailPayload, IInsuredAsset, SharedConfig, SharedDialogService, ClaimsTriggerType } from '@takectrl-multiapp/svcs-share-config';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { CommunicationService } from '@takectrl-multiapp/svcs-core';
import { firstValueFrom } from 'rxjs';
import { BUSNCommunicationService } from './busn-communication.service';

@Injectable({
  providedIn: 'root',
})
export class BUSNClaimsService {
  Iclaim: IClaim;
  public claimAssets = new Array<string>();
  public selectedAssetsArray = new Array<IInsuredAsset>();
  selected = false;
  constructor(
    private readonly communicationService: CommunicationService,
    private readonly busnCommunicationService: BUSNCommunicationService,
    private readonly sharedConfig: SharedConfig,
    private readonly apiPolicyService: APIPolicyService,
    private readonly busnThirdPartyService: BUSNThirdPartyService,
    private readonly platform: Platform,
    private readonly sharedDialogService: SharedDialogService
  ) {}

  submitClaimsPhoneCall(phoneNumber: string, assetsToClaim: any[]) {
    const date: string = new Date().toISOString();
    const peerlessClientId = this.sharedConfig.user.providers.peerless_client_id;
    const firstAsset = assetsToClaim[0];
    const brolinkId = peerlessClientId && firstAsset.insurer_id === 'PEERLESS' ? peerlessClientId : '';

    const logsPayload = {
      brolink_id: brolinkId,
      asset: this.createAssetList(assetsToClaim),
      user_id: SharedConfig.firebaseUserKey,
      insurer: firstAsset.insurer_id,
      timestamp: date,
    };

    const claimAssetsPayload = {
      clientId: SharedConfig.firebaseUserKey,
      eventData: {
        claim: {
          asset: logsPayload.asset,
          brolink_id: brolinkId,
          email: this.sharedConfig.user.personal_details.email,
          name: this.sharedConfig.user.personal_details.name,
          user_id: SharedConfig.firebaseUserKey,
          insurer: firstAsset.insurer_id,
          policy_id: firstAsset.policy_id,
          claimType: 'CALL',
        },
      },
      services: '',
    };

    console.log(claimAssetsPayload);
    this.busnThirdPartyService.logEvent('action_call_us_now', logsPayload);

    if (this.platform.is('mobileweb')) {
      window.open(`tel:${phoneNumber}`, '_blank');
    } else if (!this.platform.is('desktop')) {
      this.communicationService.makePhoneCall(phoneNumber);
    }

    this.claimAssets = [];
  }

  async submitClaimsEmail(assetsToClaim: any[], trigger: ClaimsTriggerType, insurer_id: string) {
    this.sharedConfig.setShowCustomLoader(true)

    const claimItems = this.createAssetList(assetsToClaim);
    const date: string = new Date().toISOString();
    const peerlessClientId = this.sharedConfig.user.providers.peerless_client_id;
    const firstAsset = assetsToClaim[0];
    const brolinkId = peerlessClientId && insurer_id === 'PEERLESS' ? peerlessClientId : '';

    const logsPayload = {
      name: this.sharedConfig.user.personal_details.name,
      email: this.sharedConfig.user.personal_details.email,
      broker_email: this.sharedConfig.user.broker.email,
      brolink_id: brolinkId,
      asset: claimItems,
      user_id: SharedConfig.firebaseUserKey,
      insurer: insurer_id,
      timestamp: date,
      trigger,
    };

    const emailPayload: IClaimMailPayload = {
      email: this.sharedConfig.user.personal_details.email,
      brolink_id: brolinkId,
      asset: claimItems,
      user_id: SharedConfig.firebaseUserKey,
      insurer: insurer_id,
      policy_id: firstAsset.policy_id,
      broker_email: this.sharedConfig.user.broker.email,
      trigger,
    };

    console.log(emailPayload);
    this.busnThirdPartyService.logEvent('action_call_me_back', logsPayload);

    await this.emailStatusAlert(emailPayload, assetsToClaim);

    this.sharedConfig.setShowCustomLoader(false)
  }

  async emailStatusAlert(payload: IClaimMailPayload, assets: any[]) {
    try {
      const serverStatus = await this.busnCommunicationService.sendClaimEmail(payload);
      if (serverStatus.success) {
        if (payload.trigger === 'email') {
          this.sharedDialogService.showPopupDialog({
            header: 'Claim email sent',
            subHeader: "We've sent your claim to the insurer",
            buttonOneText: 'Ok',
            buttonOneCallback: () => this.sharedDialogService.hidePopupDialog(),
          });
        }
        this.clearAll(assets);
      } else {
        throw new Error('Server failed to send email');
      }
    } catch (error) {
      this.sharedDialogService.showPopupDialog({
        header: 'Something went wrong',
        subHeader: 'We could not send your referral, please try again',
        buttonOneText: 'Ok',
        buttonOneCallback: () => this.sharedDialogService.hidePopupDialog(),
      });
      this.clearAll(assets);
    }
  }

  addNewClaimToDB(scheduleId: number, reason = '') {
    const claim: IClaim = {
      schedule_id: scheduleId,
      reason: reason,
      logged_date: new Date(),
    };
    this.apiPolicyService.addClaim(claim);
  }

  async getClaimCategories(): Promise<Array<IClaimCategories>> {
    const response = await firstValueFrom(this.apiPolicyService.getClaimCategories());
    return response.results;
  }

  private createAssetList(assetItems: any[]): string[] {
    this.claimAssets = assetItems.map((assetItem) => {
      switch (assetItem.type) {
        case AssetType.Vehicles:
          return `Vehicle: ${assetItem?.details?.make}, ${assetItem?.details?.model} - ${assetItem?.details?.registration_nr}`;
        case AssetType.Buildings:
          return `Building: ${assetItem?.details?.risk_address_line1}, ${assetItem?.details?.risk_address_line2}`;
        case AssetType.Contents:
          return `Content: ${assetItem?.details?.risk_address_line1}, ${assetItem?.details?.risk_address_line2}`;
        case AssetType.SpecifiedItems:
          return `specified_items: ${assetItem?.details}`;
        case AssetType.AllRisks:
          return `allRisk: ${assetItem?.details}`;
        case AssetType.Motorcycles:
          return `Motorcycle: ${assetItem?.details?.make}, ${assetItem?.details?.model} - ${assetItem?.details?.registration_nr}`;
        case AssetType.Caravans:
          return `Caravan: ${assetItem?.details?.make}, ${assetItem?.details?.model} - ${assetItem?.details?.registration_nr}`;
        default:
          return 'Unknown asset type';
      }
    });
    return this.claimAssets;
  }

  clearAll(assets: any[]) {
    assets.forEach((item) => {
      item['selected'] = false;
      this.selected = false;
    });
    this.selectedAssetsArray = [];
  }
}
