<div class="input-wrapper">
  <!-- Adding Claim -->
  <div *ngIf="sharedConfig.addingClaim">
    <div class="header">
      <h2 class="question-heading" *ngIf="showHeading">{{ question.secondPageQuestion }}</h2>
    </div>
    <!-- Date picker -->
    <ion-item mode="ios" (click)="showCalender()">
      <ion-label position="stacked">Select date</ion-label>
      <ion-input type="text" readonly="true" placeholder="YYYY-MM" [(ngModel)]="dateFormate"></ion-input>
      <ion-icon name="calendar-outline" slot="end" class="calendar-icon"></ion-icon>
    </ion-item>
    <div class="sub-heading">
      <h2 class="question-heading">Reason for this claim</h2>
    </div>
    <!-- Select option -->
    <ion-radio-group value="{{ claimReason }}">
      <ion-item
        class="ion-item-sub"
        mode="md"
        *ngFor="let _claimReason of claimReasons"
        lines="none"
        (click)="selectReason(_claimReason)"
        [ngClass]="{ active: _claimReason == selectedClaim?.reason }"
      >
        <ion-text>{{ _claimReason }}</ion-text>
        <ion-icon
          class="checked"
          [ngClass]="{ selected: _claimReason == selectedClaim?.reason }"
          src="assets/icon/app-icons/tick-primary.svg"
          slot="end"
        ></ion-icon>
      </ion-item>
    </ion-radio-group>
  </div>
  <!-- All added claims -->
  <div *ngIf="!sharedConfig.addingClaim">
    <div class="header" *ngIf="!summary">
      <h2 class="question-heading">{{ question.sub_heading }}</h2>
    </div>
    <div class="top-button">
      <ion-button mode="ios" (click)="newClaim()" class="secondary-button">{{ buttonText }}</ion-button>
    </div>
    <div  *ngFor="let previousClaim of previousClaims">
      <ion-item class="ion-item-claim" mode="md" *ngIf="previousClaim.reason !== 'No claims'"  lines="none"
        (click)="selectPreviousClaim(previousClaim)">
        <ion-text>
          <ion-label>{{ previousClaim.reason }}</ion-label>
          <div *ngIf="previousClaim.reason !== 'No claims'">
            <small>{{ setDateFormat(previousClaim?.date_full) }}</small>
          </div>
        </ion-text>
        <ion-icon class="swiper-icon icon-stroke-primary" slot="end" src="assets/icon/genetic-icons/arrow-right-circle.svg">
        </ion-icon>
      </ion-item>
    </div>
  </div>
</div>
<div class="button-row" [ngClass]="{ 'mb-8': sharedConfig.hideHeaderWhenAddedClaimType, 'edit-row': editClaim }">
  <ion-button mode="ios" *ngIf="sharedConfig.addingClaim && editClaim" style="margin-bottom: 8px" class="secondary-button" (click)="showRemoveClaim()"
    >Remove claim</ion-button
  >
  <ion-button
    mode="ios"
    *ngIf="sharedConfig.addingClaim"
    [disabled]="!selectedClaim?.reason || !selectedClaim?.date_full"
    (click)="addEditClaim()"
    class="primary-button"
    >Done</ion-button
  >
  <ion-button mode="ios" *ngIf="!readOnly && !sharedConfig.addingClaim && showHeading" (click)="nextQuestion()" class="primary-button"
    >Next</ion-button
  >
</div>

<!-- Remove claim popup -->
<div class="backdrop" *ngIf="showRemoveClaimPopup"></div>
<div class="popup-standard limit-width-on-desktop-float" *ngIf="showRemoveClaimPopup">
  <div class="heading">
    <h2 class="h2-bold">Are you sure you want to remove this claim?</h2>
  </div>
  <div class="buttons-wrapper">
    <ion-button mode="ios" class="primary-button" (click)="removeClaim()">Remove claim </ion-button>
    <ion-button mode="ios" class="secondary-button" (click)="cancelRemove()">Cancel</ion-button>
  </div>
</div>

<popup-calendar  *ngIf="sharedConfig.showCalendarPopup" [heading]="'Select date:'" (setDate)="pickDate($event)" [dateFormat]="'month-year'" [maxDate]="maxDate" [minDate]="minDate" [ngModal]="selectedClaim.date_full"></popup-calendar>

