import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LandingComponent } from './pages/landing/landing.component';
import { IonicModule } from '@ionic/angular';
import { LoginComponent } from './pages/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './pages/home/home.component';
import { ChangeEmailComponent } from './pages/change-email/change-email.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { ReferralComponent } from './pages/referral/referral.component';
import { ClaimComponent } from './pages/claim/claim.component';
import { EmergencyComponent } from './pages/emergency/emergency.component';
import { QuoteComponent } from './pages/quote/quote.component';
import { WalletComponent } from './pages/wallet/wallet.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { UsefulDocumentsComponent } from './pages/useful-documents/useful-documents.component';
import { FsbLicenceComponent } from './pages/fsb-licence/fsb-licence.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { SideMenuButtonComponent } from './components/side-menu-button/side-menu-button.component';
import { RenewalDetailsComponent } from './pages/renewal-details/renewal-details.component';
import { RenewalComponent } from './pages/renewal/renewal.component';
import { ChatButtonComponent } from './components/chat-button/chat-button.component';
import { FloatingButtonComponent } from './components/floating-button/floating-button.component';
import { QuoteAddedBenefitsComponent } from './pages/quote-added-benefits/quote-added-benefits.component';
import { WalletAssetDetailComponent } from './pages/wallet-asset-detail/wallet-asset-detail.component';
import { WalletAssetComponent } from './pages/wallet-asset/wallet-asset.component';
import { InputTextSingleLineComponent } from './components/input-text-single-line/input-text-single-line.component';
import { InputCheckListSingleSelectComponent } from './components/input-check-list-single-select/input-check-list-single-select.component';
import { InputCheckListMultiSelectComponent } from './components/input-check-list-multi-select/input-check-list-multi-select.component';
import { ClientCardComponent } from './components/client-card/client-card.component';
import { QuoteAcceptComponent } from './pages/quote-accept/quote-accept.component';
import { QuoteCompleteComponent } from './pages/quote-complete/quote-complete.component';
import { QuoteListRetrievedSummaryComponent } from './pages/quote-list-retrieved-summary/quote-list-retrieved-summary.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { QuestionSummaryComponent } from './pages/question-summary/question-summary.component';
import { QuoteNewComponent } from './pages/quote-new/quote-new.component';
import { QuoteComparisonOverallComponent } from './pages/quote-comparison-overall/quote-comparison-overall.component';
import { QuoteComparisonTopVsView } from './components/quote-comparison-top-vs-view/quote-comparison-top-vs-view.component';
import { QuoteRatingIndicatorComponent } from './components/quote-rating-indicator/quote-rating-indicator.component';
import { QuoteComparisonDetailsComponent } from './pages/quote-comparison-details/quote-comparison-details.component';
import { QuoteComparisonAssetDetailsTabsComponent } from './components/quote-comparison-asset-details-tabs/quote-comparison-asset-details-tabs.component';
import { UIAppInitializationService } from './services/ui-app-initialization.service';
import { QuoteInfoComponent } from './pages/quote-info/quote-info.component';
import { UpdateComponent } from './pages/update/update.component';
import { QuoteStatusButtonComponent } from './components/quote-status-button/quote-status-button.component';
import { QuestionQuoteComponent } from './pages/question-quote/question-quote.component';
import { QuestionComponent } from './components/question/question.component';
import { SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import { InputDropdownMotorSelectorComponent } from './components/input-dropdown-motor-selector/input-dropdown-motor-selector.component';
import { InputTextAddressComponent } from './components/input-text-address/input-text-address.component';
import { InputDatepickerComponent } from './components/input-datepicker/input-datepicker.component';
import { InputSingleSearchComponent } from './components/input-single-search/input-single-search.component';
import { InputTextMultiLinesComponent } from './components/input-text-multi-lines/input-text-multi-lines.component';
import { QuestionPersonalDetailsComponent } from './pages/question-personal-details/question-personal-details.component';
import { NeedsInfoComponent } from './pages/needs-info/needs-info.component';
import { NeedsSlidersComponent } from './pages/needs-sliders/needs-sliders.component';
import { NeedsResultsComponent } from './pages/needs-results/needs-results.component';
import { QuoteFetchingComponent } from './pages/quote-fetching/quote-fetching.component';
import { QuoteBankingDetailsCasaComponent } from './pages/quote-banking-details-casa/quote-banking-details-casa.component';
import { QuoteBankingDetailsComponent } from './pages/quote-banking-details/quote-banking-details.component';
import { QuoteEndorsementAcceptComponent } from './pages/quote-endorsement-accept/quote-endorsement-accept.component';
import { EventTrackingDirective, FirebaseAnalyticsService } from '@takectrl-multiapp/svcs-core';
import { SchedulesRemoveAssetComponent } from './pages/schedules-remove-asset/schedules-remove-asset.component';
import { SchedulesRemoveAssetSuccessComponent } from './components/schedules-remove-asset-success/schedules-remove-asset-success.component';
import { SchedulesRemoveAssetFailComponent } from './components/schedules-remove-asset-fail/schedules-remove-asset-fail.component';
import { DownloadAppComponent } from './pages/download-app/download-app.component';
import { UpNextAnimationComponent } from './components/up-next-animation/up-next-animation.component';
import { SuccessAnimationComponent } from './components/success-animation/success-animation.component';
import { InputPreviousClaimsComponent } from './components/input-previous-claims/input-previous-claims.component';
import { QuoteSummaryNotEditableComponent } from './pages/quote-summary-not-editable/quote-summary-not-editable.component';
import { QuoteClaimsHistoryCheckComponent } from './pages/quote-claims-history-check/quote-claims-history-check.component';
import { CtrlCenterComponent } from './pages/ctrl-center/ctrl-center.component';

import { QuoteQuestionsObjectMappingComponent } from './pages/quote-questions-object-mapping/quote-questions-object-mapping.component';

import { SmallPopupComponent } from './components/small-popup/small-popup.component';
import { SuccessWithPushNotificationAnimationComponent } from './components/success-with-push-notification-animation/success-with-push-notification-animation.component';
import { CustomPopupComponent } from './components/custom-popup/custom-popup.component';
import { KingpriceWalletAnimationComponent } from './components/kingprice-wallet-animation/kingprice-wallet-animation.component';
import { PopupWithDismissButtonComponent } from './components/popup-with-dismiss-button/popup-with-dismiss-button.component';
import { PopupWithChatButtonComponent } from './components/popup-with-chat-button/popup-with-chat-button.component';
import { AnimationPleaseWaitNotificationComponent } from './components/animation-please-wait-notification/animation-please-wait-notification.component';
import { CustomLoaderComponent } from './components/custom-loader/custom-loader.component';
import { PopupSummarySelectComponent } from './components/popup-summary-select/popup-summary-select.component';
import { AdminPanelComponent } from './pages/admin-panel/admin-panel.component';
import { AdminPanelQuoteOnBehalfComponent } from './pages/admin-panel-quote-on-behalf/admin-panel-quote-on-behalf.component';
import { MotorGroupDetailsComponent } from './pages/motor-group-details/motor-group-details.component';
import { PopupCalendarComponent } from './components/popup-calendar/popup-calendar.component';
import { RbixQuestionMapRecordButtonComponent } from './components/rbix-question-map-record-button/rbix-question-map-record-button.component';
import { RbixObjectMapRecordButtonComponent } from './components/rbix-object-map-record-button/rbix-object-map-record-button.component';
import { RbixScheduleInterpreterButtonComponent } from './components/rbix-schedule-interpreter-button/rbix-schedule-interpreter-button.component';
import { PopupDialogComponent } from './components/popup-dialog/popup-dialog.component';
import { PopupDialogQuestionAnswerComponent } from './components/popup-dialog-question-answer/popup-dialog-question-answer.component';
import { InputCheckListSingleSelectSearchComponent } from './components/input-check-list-single-select-search/input-check-list-single-select-search.component';
import { DeleteAccountInfoComponent } from './pages/delete-account-info/delete-account-info.component';
import { QuoteRequestStartedComponent } from './pages/quote-request-started/quote-request-started.component';
import { QuoteRequestNotStartedComponent } from './pages/quote-request-not-started/quote-request-not-started.component';
import { SetNewPasswordComponent } from './pages/set-new-password/set-new-password.component';
import { ClaimEmptyStateComponent } from './pages/claim-empty-state/claim-empty-state.component';
import { EmergencyEmptyStateComponent } from './pages/emergency-empty-state/emergency-empty-state.component';
import { EmergencyWithoutAssistanceBenefitsComponent } from './pages/emergency-without-assistance-benefits/emergency-without-assistance-benefits.component';
import { EmergencyWithAssistanceBenefitsComponent } from './pages/emergency-with-assistance-benefits/emergency-with-assistance-benefits.component';
import { ClaimOnActivePolicyComponent } from './pages/claim-on-active-policy/claim-on-active-policy.component';
import { LoginWithEmailPasswordComponent } from './pages/login-with-email-password/login-with-email-password.component';
import { LoginWithOtpComponent } from './pages/login-with-otp/login-with-otp.component';
import { EndorsementAddAcceptComponent } from './pages/endorsement-add-accept/endorsement-add-accept.component';
import { EndorsementUpdateAcceptComponent } from './pages/endorsement-update-accept/endorsement-update-accept.component';
import { GetStartedAnimationModule } from './components/get-started-animation/get-started-animation.module';
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      animationDuration: 300,
    }),
    GetStartedAnimationModule,
  ],
  exports: [
    LoginComponent,
    LandingComponent,
    HomeComponent,
    ChangeEmailComponent,
    ChangePasswordComponent,
    ReferralComponent,
    ClaimComponent,
    EmergencyComponent,
    QuoteComponent,
    WalletComponent,
    DeleteAccountInfoComponent,
    ForgotPasswordComponent,
    SignUpComponent,
    UsefulDocumentsComponent,
    FsbLicenceComponent,
    TermsConditionsComponent,
    SideMenuComponent,
    SideMenuButtonComponent,
    RenewalDetailsComponent,
    RenewalComponent,
    ChatButtonComponent,
    FloatingButtonComponent,
    RbixQuestionMapRecordButtonComponent,
    RbixScheduleInterpreterButtonComponent,
    RbixObjectMapRecordButtonComponent,
    WalletAssetComponent,
    WalletAssetDetailComponent,
    QuoteAddedBenefitsComponent,
    InputTextSingleLineComponent,
    InputCheckListSingleSelectComponent,
    InputCheckListSingleSelectSearchComponent,
    QuestionComponent,
    InputCheckListMultiSelectComponent,
    InputPreviousClaimsComponent,
    QuoteBankingDetailsComponent,
    QuoteBankingDetailsCasaComponent,
    ClientCardComponent,
    QuoteAcceptComponent,
    QuoteCompleteComponent,
    QuoteListRetrievedSummaryComponent,
    QuestionSummaryComponent,
    QuoteNewComponent,
    QuoteComparisonOverallComponent,
    QuoteClaimsHistoryCheckComponent,
    QuoteRatingIndicatorComponent,
    QuoteComparisonDetailsComponent,
    QuoteComparisonAssetDetailsTabsComponent,
    QuoteInfoComponent,
    UpdateComponent,
    QuoteStatusButtonComponent,
    QuestionQuoteComponent,
    InputDropdownMotorSelectorComponent,
    InputTextAddressComponent,
    InputDatepickerComponent,
    InputSingleSearchComponent,
    InputTextMultiLinesComponent,
    QuoteFetchingComponent,
    QuestionPersonalDetailsComponent,
    NeedsInfoComponent,
    NeedsSlidersComponent,
    NeedsResultsComponent,
    QuoteEndorsementAcceptComponent,
    SchedulesRemoveAssetComponent,
    SchedulesRemoveAssetSuccessComponent,
    SchedulesRemoveAssetFailComponent,
    DownloadAppComponent,
    UpNextAnimationComponent,
    SuccessAnimationComponent,
    QuoteSummaryNotEditableComponent,
    SmallPopupComponent,
    SuccessWithPushNotificationAnimationComponent,
    AnimationPleaseWaitNotificationComponent,
    CustomPopupComponent,
    KingpriceWalletAnimationComponent,
    PopupWithDismissButtonComponent,
    PopupDialogQuestionAnswerComponent,
    PopupDialogComponent,
    PopupWithChatButtonComponent,
    PopupSummarySelectComponent,
    CustomLoaderComponent,
    AdminPanelComponent,
    AdminPanelQuoteOnBehalfComponent,
    MotorGroupDetailsComponent,
    QuoteQuestionsObjectMappingComponent,
    CtrlCenterComponent,
    QuoteRequestStartedComponent,
    QuoteRequestNotStartedComponent,
    SetNewPasswordComponent,
    ClaimEmptyStateComponent,
    EmergencyEmptyStateComponent,
    EmergencyWithoutAssistanceBenefitsComponent,
    EmergencyWithAssistanceBenefitsComponent,
    ClaimOnActivePolicyComponent,
    LoginWithEmailPasswordComponent,
    LoginWithOtpComponent,
    EndorsementAddAcceptComponent,
    EndorsementUpdateAcceptComponent,
  ],
  declarations: [
    EventTrackingDirective,
    LoginComponent,
    LandingComponent,
    HomeComponent,
    ChangeEmailComponent,
    ChangePasswordComponent,
    ReferralComponent,
    ClaimComponent,
    EmergencyComponent,
    QuoteComponent,
    WalletComponent,
    ForgotPasswordComponent,
    SignUpComponent,
    UsefulDocumentsComponent,
    FsbLicenceComponent,
    TermsConditionsComponent,
    SideMenuComponent,
    SideMenuButtonComponent,
    RenewalDetailsComponent,
    RenewalComponent,
    DeleteAccountInfoComponent,
    ChatButtonComponent,
    FloatingButtonComponent,
    RbixQuestionMapRecordButtonComponent,
    RbixObjectMapRecordButtonComponent,
    RbixScheduleInterpreterButtonComponent,
    QuoteAddedBenefitsComponent,
    WalletAssetDetailComponent,
    WalletAssetComponent,
    InputTextSingleLineComponent,
    InputCheckListSingleSelectComponent,
    InputCheckListSingleSelectSearchComponent,
    QuestionComponent,
    InputCheckListMultiSelectComponent,
    InputPreviousClaimsComponent,
    QuoteBankingDetailsComponent,
    ClientCardComponent,
    QuoteAcceptComponent,
    QuoteCompleteComponent,
    QuoteListRetrievedSummaryComponent,
    QuestionSummaryComponent,
    QuoteNewComponent,
    QuoteComparisonOverallComponent,
    QuoteClaimsHistoryCheckComponent,
    QuoteComparisonTopVsView,
    QuoteRatingIndicatorComponent,
    QuoteComparisonDetailsComponent,
    QuoteComparisonAssetDetailsTabsComponent,
    QuoteInfoComponent,
    UpdateComponent,
    QuoteStatusButtonComponent,
    QuestionQuoteComponent,
    InputDropdownMotorSelectorComponent,
    InputTextAddressComponent,
    InputDatepickerComponent,
    InputSingleSearchComponent,
    InputTextMultiLinesComponent,
    QuestionPersonalDetailsComponent,
    NeedsInfoComponent,
    NeedsSlidersComponent,
    NeedsResultsComponent,
    QuoteFetchingComponent,
    QuoteBankingDetailsCasaComponent,
    QuoteEndorsementAcceptComponent,
    SchedulesRemoveAssetComponent,
    SchedulesRemoveAssetSuccessComponent,
    SchedulesRemoveAssetFailComponent,
    DownloadAppComponent,
    UpNextAnimationComponent,
    SuccessAnimationComponent,
    QuoteSummaryNotEditableComponent,
    SmallPopupComponent,
    SuccessWithPushNotificationAnimationComponent,
    AnimationPleaseWaitNotificationComponent,
    CustomPopupComponent,
    KingpriceWalletAnimationComponent,
    PopupWithDismissButtonComponent,
    PopupDialogComponent,
    PopupDialogQuestionAnswerComponent,
    PopupWithChatButtonComponent,
    PopupSummarySelectComponent,
    CustomLoaderComponent,
    AdminPanelComponent,
    AdminPanelQuoteOnBehalfComponent,
    MotorGroupDetailsComponent,
    PopupCalendarComponent,
    QuoteQuestionsObjectMappingComponent,
    CtrlCenterComponent,
    QuoteRequestStartedComponent,
    QuoteRequestNotStartedComponent,
    SetNewPasswordComponent,
    ClaimEmptyStateComponent,
    EmergencyEmptyStateComponent,
    EmergencyWithoutAssistanceBenefitsComponent,
    EmergencyWithAssistanceBenefitsComponent,
    ClaimOnActivePolicyComponent,
    LoginWithEmailPasswordComponent,
    LoginWithOtpComponent,
    EndorsementAddAcceptComponent,
    EndorsementUpdateAcceptComponent,
  ],
  providers: [SharedConfig, UIAppInitializationService, FirebaseAnalyticsService],
})
export class UiPagesModule {}
