import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthStorageService {
  private readonly tokenKey = 'accessToken';
  private accessToken$ = new BehaviorSubject<string | null>(null);

  constructor() {
    this.accessToken$
      .pipe(
        take(1),
        switchMap((token) => {
          if (!token) {
            const storedToken = localStorage.getItem(this.tokenKey);
            if (storedToken) {
              return of(storedToken);
            }
          }
          return of(null);
        })
      )
      .subscribe((token) => {
        if (token) {
          this.accessToken$.next(token);
        }
      });
  }

  getAuthToken(): Observable<string | null> {
    return this.accessToken$.asObservable();
  }

  setAuthToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
    this.accessToken$.next(token);
  }

  removeAuthToken(): void {
    localStorage.removeItem(this.tokenKey);
    this.accessToken$.next(null);
  }
}
