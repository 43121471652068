<div class="progress-wrapper">
  <div class="progress-header limit-width-on-desktop-float">
    <h3>My Vehicle {{ sharedConfig.popupDialogAnswerQuestionShow }}</h3>
  </div>
  <div class="container limit-width-on-desktop-float">
    <div class="progress-bar-container" [ngStyle]="{ width: 100 + '%' }">
      <div
        class="progress-bar"
        [ngStyle]="{ width: 'calc(' + (getAllAnsweredQuestions().length / getAllUnansweredQuestions().length) * 100 + '% )' }"
      ></div>
    </div>
  </div>
</div>

<div
  *ngIf="!fullScreenRecording"
  id="background-screen-parent"
  #backgroundScreenParent
  class="wrapper"
  style="padding-bottom: 200px; padding-top: 40px"
>
  <div class="heading limit-width-on-desktop-float">

    <div class="content">
      <div class="asset-types limit-width-on-desktop-float">
        <ion-list (click)="assetQuestionAnswerAction(question)" tappable *ngFor="let question of sharedConfig.selectedAssetUnderwritingQuestions">
          <ion-item-sliding>
            <ion-item mode="ios" lines="none">
              <svg
                class="asset-icon"
                *ngIf="isAllUnderwritingQuestionsAnswered(question) && !hasUnderwritingQuestionError(question)"
                slot="start"
                width="57"
                height="56"
                viewBox="0 0 57 56"
                id="Icon1"
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"
              >
                <g id="SVGRepo_bgCarrier1" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier1" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier1">
                  <defs>
                    <style>
                      .cls-1 {
                        fill: #0074ff;
                      }
                      .cls-2 {
                        fill: #6ae5af;
                      }
                    </style>
                  </defs>
                  <title></title>
                  <path
                    class="cls-1"
                    d="M28.75,55.5a23.5,23.5,0,1,1,14-42.38,2,2,0,0,1-2.38,3.21A19.51,19.51,0,1,0,48.25,32,19.65,19.65,0,0,0,48,28.93a2,2,0,1,1,4-.62A23.85,23.85,0,0,1,52.25,32,23.52,23.52,0,0,1,28.75,55.5Z"
                  ></path>
                  <path
                    class="cls-2"
                    d="M31.25,39.5a2,2,0,0,1-1.41-.59l-9.5-9.5a2,2,0,0,1,2.82-2.82l8.09,8.08L55.34,10.59a2,2,0,0,1,2.82,2.82l-25.5,25.5A2,2,0,0,1,31.25,39.5Z"
                  ></path>
                </g>
              </svg>
              <svg
                class="asset-icon"
                *ngIf="!isAllUnderwritingQuestionsAnswered(question) && !hasUnderwritingQuestionError(question)"
                slot="start"
                width="57"
                height="56"
                viewBox="0 0 57 56"
                id="Icon2"
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"
              >
                <g id="SVGRepo_bgCarrier2" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier2" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier2">
                  <defs>
                    <style>
                      .cls-1_2 {
                        fill: #ffffff;
                      }
                      .cls-2_2 {
                        fill: #ffffff;
                      }
                    </style>
                  </defs>
                  <title></title>
                  <path
                    class="cls-1_2"
                    d="M28.75,55.5a23.5,23.5,0,1,1,14-42.38,2,2,0,0,1-2.38,3.21A19.51,19.51,0,1,0,48.25,32,19.65,19.65,0,0,0,48,28.93a2,2,0,1,1,4-.62A23.85,23.85,0,0,1,52.25,32,23.52,23.52,0,0,1,28.75,55.5Z"
                  ></path>
                  <path
                    class="cls-2_2"
                    d="M31.25,39.5a2,2,0,0,1-1.41-.59l-9.5-9.5a2,2,0,0,1,2.82-2.82l8.09,8.08L55.34,10.59a2,2,0,0,1,2.82,2.82l-25.5,25.5A2,2,0,0,1,31.25,39.5Z"
                  ></path>
                </g>
              </svg>
              <svg
                class="asset-icon"
                *ngIf="hasUnderwritingQuestionError(question)"
                slot="start"
                width="57"
                height="56"
                viewBox="0 0 57 56"
                id="Layer_3"
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"
              >
                <g id="SVGRepo_bgCarrier_3" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier_3" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier_3">
                  <defs>
                    <style>
                      .cls-1_3 {
                        fill: #ff2424;
                      }
                      .cls-2_3 {
                        fill: #ff2424;
                      }
                    </style>
                  </defs>
                  <title></title>
                  <path
                    class="cls-1_3"
                    d="M58,56.5H54.54a2,2,0,0,1,0-4L32,13.5,9.48,52.5H43.79a2,2,0,0,1,0,4H6a2,2,0,0,1-1.73-3l26-45a2,2,0,0,1,3.46,0l26,45a2,2,0,0,1-1.73,3Z"
                  ></path>
                  <path class="cls-2_3" d="M32,48.54a2,2,0,0,1-2-2V45.21a2,2,0,0,1,4,0v1.33A2,2,0,0,1,32,48.54Z"></path>
                  <path class="cls-2_3" d="M32,41.54a2,2,0,0,1-2-2V27.21a2,2,0,0,1,4,0V39.54A2,2,0,0,1,32,41.54Z"></path>
                </g>
              </svg>
              <!-- <svg  class="asset-icon"  *ngIf="!isAllUnderwritingQuestionsAnswered(question)" viewBox="0 0 64 64" data-name="Layer 1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><defs><style>.cls-1{fill:#0074ff;}.cls-2{fill:#ffb300;}</style></defs><title></title><path class="cls-1" d="M58,56.5H54.54a2,2,0,0,1,0-4L32,13.5,9.48,52.5H43.79a2,2,0,0,1,0,4H6a2,2,0,0,1-1.73-3l26-45a2,2,0,0,1,3.46,0l26,45a2,2,0,0,1-1.73,3Z"></path><path class="cls-2" d="M32,48.54a2,2,0,0,1-2-2V45.21a2,2,0,0,1,4,0v1.33A2,2,0,0,1,32,48.54Z"></path><path class="cls-2" d="M32,41.54a2,2,0,0,1-2-2V27.21a2,2,0,0,1,4,0V39.54A2,2,0,0,1,32,41.54Z"></path></g></svg> -->
              <ion-text>
                <ion-label style="white-space: normal" *ngIf="question.wording_for_summary"> {{ question.wording_for_summary }}</ion-label>
                <ion-label style="white-space: normal" *ngIf="!question.wording_for_summary"> {{ question.heading }}</ion-label>
                <div *ngIf="!isAllUnderwritingQuestionsAnswered(question) && !hasUnderwritingQuestionError(question)">
                  <small style="color: #6ae5af;">Please complete </small>
                </div>
                <div *ngFor="let underwriting_answer of question.underwriting_answers; let i = index">
                  <div *ngIf="hasUnderwritingQuestionError(question)">
                    <small> {{ underwriting_answer.object_answer_error }}</small>
                  </div>
                  <div
                    *ngIf="
                      underwriting_answer.object_answer !== undefined && underwriting_answer.object_answer !== null && question.type === QuestionType.boolean
                    "
                    class="update-btn"
                  >
                    <small> {{ underwriting_answer.object_answer === true ? 'Yes' : 'No' }}</small>
                  </div>

                  <div
                    *ngIf="
                      underwriting_answer.object_answer !== undefined &&
                      underwriting_answer.object_answer !== null &&
                      underwriting_answer.object_answer === true &&
                      question.type === QuestionType.multiSelect
                    "
                    class="update-btn"
                  >
                    <small>
                      {{ underwriting_answer.place_holder }}
                    </small>
                  </div>
                  <div
                    *ngIf="
                      underwriting_answer.object_answer !== undefined &&
                      underwriting_answer.object_answer !== null &&
                      [QuestionType.boolean, QuestionType.vehicleSearch, QuestionType.multiSelect, QuestionType.address].indexOf(question.type) === -1
                    "
                    class="update-btn"
                  >
                    <ng-container [ngSwitch]="underwriting_answer.object_field">
                      <small *ngSwitchCase="'days_work_from_home'">{{ underwriting_answer.object_answer }} days work from home</small>
                      <small *ngSwitchCase="'monthly_mileage'">{{ underwriting_answer.object_answer }} monthly mileage</small>
                      <small *ngSwitchDefault>{{ underwriting_answer.object_answer }}</small>
                    </ng-container>
                  </div>

                  <div
                    *ngIf="
                      underwriting_answer.object_answer !== undefined &&
                      underwriting_answer.object_answer !== null &&
                      question.type === QuestionType.address &&
                      i < 3
                    "
                    class="update-btn"
                  >
                    <small>{{ underwriting_answer.object_answer }} </small>
                  </div>

                  <div
                    *ngIf="
                      underwriting_answer.object_answer !== undefined &&
                      underwriting_answer.object_answer !== null &&
                      question.type === QuestionType.vehicleSearch &&
                      (underwriting_answer.object_field === 'year_of_manufacture' ||
                        underwriting_answer.object_field === 'make' ||
                        underwriting_answer.object_field === 'model' ||
                        underwriting_answer.object_field === 'model_series')
                    "
                    class="update-btn"
                  >
                    <small>{{ underwriting_answer.object_answer }} </small>
                  </div>
                </div>

                <!-- This div will appear if the question type is MULTISELECT and all object_answer's are False. -->
                <div *ngIf="isMultiselectAnswerNone(question)" class="update-btn">
                  <small>None</small>
                </div>
              </ion-text>
              <!-- <ion-icon
              *ngIf="!vehicle.is_completed"
              class="swiper-icon icon-stroke-primary"
              slot="end"
              src="assets/icon/genetic-icons/arrow-right-circle.svg"
            ></ion-icon> -->
              <!-- <ion-icon *ngIf="vehicle.is_completed" class="edit-icon" slot="end" src="assets/icon/misc-icons/edit.svg"></ion-icon> -->
              <ion-icon *ngIf="question.object_answer" class="edit-icon" slot="end" src="assets/icon/misc-icons/edit.svg"></ion-icon>
            </ion-item>
            <!-- <ion-item-options side="end">
              <ion-button mode="ios" class="slider-button">
                <ion-icon slot="icon-only" src="assets/icon/genetic-icons/trash.svg"></ion-icon>
              </ion-button>
            </ion-item-options> -->
          </ion-item-sliding>
        </ion-list>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!fullScreenRecording" class="footer-button-wrapper">
  <div class="fade"></div>
  <div class="footer-button-background">
    <ion-button
      mode="ios"
      *ngIf="true"
      class="footer-button limit-width-desktop-on-button"
      expand="block"
      [disabled]="!isAllQuestionsAndUnderwritingQuestionsAnswered()"
      (click)="saveAndBack()"
    >
      <div *ngIf="isAllQuestionsAndUnderwritingQuestionsAnswered()">Looks good!</div>
      <div *ngIf="!isAllQuestionsAndUnderwritingQuestionsAnswered()">Information Needed</div>
    </ion-button>

    <ion-button mode="ios" (click)="saveAndBack()" class="secondary-button">
      <ion-icon slot="icon-only" size="small"></ion-icon>
      back</ion-button
    >
  </div>
</div>

<div *ngIf="sharedConfig.user && fullScreenRecording">
  <div *ngIf="sharedConfig.user && fullScreenRecording" class="loader-backdrop"></div>
  <div *ngIf="sharedConfig.user && fullScreenRecording" class="center-outer">
    <div class="center-inner">
      <div class="center-auto">
        <div class="loader-wrapper">
          <div role="status">
            <lottie-player autoplay loop mode="normal" src="assets/lottie-animations/recording.json"> </lottie-player>
          </div>
        </div>
        <div class="loader-message">
          <h2>Tell us about your vehicle</h2>
          <h4>Give as much information as you can.</h4>
        </div>
      </div>
    </div>
  </div>
  <div class="up-next-banner">
    <div class="text-wrapper">
      <ion-button mode="ios" class="footer-button limit-width-desktop-on-button" (click)="stopRecording()" expand="block"> Continue </ion-button>
      <ion-button mode="ios" class="footer-button second-button limit-width-desktop-on-button" (click)="cancel()">Cancel</ion-button>
    </div>
  </div>
</div>
<small-popup *ngIf="showUpdatePopup" [header]="popUpHeading" [message]="popupMessage">
  <ion-button mode="ios" class="primary-button" (click)="showIntercom()">Contact us </ion-button>
  <ion-button mode="ios" class="secondary-button" (click)="closePopup()">Cancel </ion-button>
</small-popup>

<popup-dialog id="quote.question.object.mapping.component" *ngIf="sharedConfig.popupDialogShow || sharedConfig.isProcessing"></popup-dialog>
<popup-dialog-question-answer *ngIf="sharedConfig.popupDialogAnswerQuestionShow"></popup-dialog-question-answer>
