import { AfterViewChecked, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { NavController, MenuController, IonContent, AlertController, Platform } from '@ionic/angular';
import { SharedConfig, QuoteType, QuoteRequestStatusType, QuoteRequestType } from '@takectrl-multiapp/svcs-share-config';
import { BUSNThirdPartyService, BUSNUserService } from '@takectrl-multiapp/svcs-core-business';

@Component({
  selector: 'side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  @ViewChild(IonContent, { static: false }) public content: IonContent;
  enableDisableWording = 'Enable';
  initials: string;
  showCustomLoader = false;
  showMoreOption = false;
  QuoteRequestStatusType = QuoteRequestStatusType;
  QuoteRequestType = QuoteRequestType;
  constructor(
    public navCtrl: NavController,
    public menu: MenuController,
    private readonly alertController: AlertController,
    private readonly zone: NgZone,
    public busnUserService: BUSNUserService,
    public busnThirdPartyService: BUSNThirdPartyService,
    public sharedConfig: SharedConfig,
    public platform: Platform
  ) {}

  ngOnInit(): void {
    this.initials = this.sharedConfig?.user?.personal_details?.name?.substring(1) + this.sharedConfig?.user?.personal_details?.surname.substring(1);
  }

  async deleteProfile() {
    const callAlert = await this.alertController.create({
      subHeader: 'Are you sure?',
      message: 'You are about to delete your profile',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Yes, Delete my profile',
          handler: () => {
            this.showCustomLoader = true;
            this.busnUserService.deleteUserAccount().then(async (response) => {
              if (response.success) {
                this.showCustomLoader = false;
                this.logOut();
              } else {
                this.showCustomLoader = false;
                const alert = await this.alertController.create({
                  header: "Can't delete at this time",
                  message: 'Please contact us',
                  buttons: [
                    {
                      text: 'OK',
                    },
                  ],
                });
                alert.present();
              }
            });
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
        },
      ],
    });
    await callAlert.present();
  }

  openMyProfile() {
    const quoteType = QuoteType.MyProfile;
    this.zone.run(() => {
      this.navCtrl.navigateForward('quote-summary-not-editable', { queryParams: { quoteType: quoteType } });
    });
    this.menu.close('sideMenu');
  }

  openQuoteTab() {
    this.zone.run(() => {
      this.navCtrl.navigateRoot('home/home-quote');
    });
    this.menu.close('sideMenu');
  }

  closeSideMenu() {
    this.menu.close('sideMenu');
  }
  openFirst() {
    this.menu.enable(true, 'first');
    this.menu.open('first');
  }

  openEnd() {
    this.menu.open('end');
  }

  openCustom() {
    this.menu.enable(true, 'custom');
    this.menu.open('custom');
  }

  openReferPage() {
    this.zone.run(() => {
      this.navCtrl.navigateForward('side-menu-referral');
    });
    this.menu.close('sideMenu');
  }

  openFeatureFlags() {
    this.zone.run(() => {
      this.navCtrl.navigateForward('feature-flags');
    });
    this.menu.close('sideMenu');
  }

  openMotorGroupDetailsPage() {
    this.zone.run(() => {
      this.navCtrl.navigateForward('side-menu-motor-group-details');
    });
    this.menu.close('sideMenu');
  }

  openFsbPage() {
    this.zone.run(() => {
      this.navCtrl.navigateForward('side-menu-fsb-licence');
    });
    this.menu.close('sideMenu');
    this.showMoreOptions();
  }

  openLegalPage() {
    this.menu.close('sideMenu');
    this.showMoreOptions();
  }

  openUsefulDocsPage() {
    this.zone.run(() => {
      this.navCtrl.navigateForward('side-menu-useful-documents');
    });
    this.menu.close('sideMenu');
  }

  openUpdateEmailPage() {
    this.zone.run(() => {
      this.navCtrl.navigateForward('side-menu-change-email');
    });
    this.menu.close('sideMenu');
    this.showMoreOptions();
  }

  openUpdatePasswordPage() {
    this.zone.run(() => {
      this.navCtrl.navigateForward('side-menu-change-password');
    });
    this.menu.close('sideMenu');
    this.showMoreOptions();
  }

  downloadApp() {
    this.zone.run(() => {
      this.navCtrl.navigateForward('download-app');
    });
    this.menu.close('sideMenu');
    this.showMoreOptions();
  }

  showMoreOptions() {
    if (this.showMoreOption === false) {
      this.showMoreOption = true;
    } else {
      this.showMoreOption = false;
    }
  }

  async logOut() {
    this.showCustomLoader = true;
    await this.busnThirdPartyService.firebaseSignOut();
    this.showCustomLoader = false;
    this.zone.run(() => {
      this.navCtrl.navigateRoot('public-landing');
    });
    this.menu.close('sideMenu');
  }
}
