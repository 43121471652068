<div class="wrapper limit-width-on-desktop">
  <div class="head-section">
    <div class="img-div">
      <img src="assets/logos/primary.svg" alt="Company Logo"/>
    </div>
    <div class="top-text">
      <h2 class="h2-medium">Delete My Account</h2>
    </div>
    <!-- Begin: Account Deletion Steps -->
    <div class="account-deletion-steps">
      <h3 class="deletion-steps-list">Follow the steps below to delete your account:</h3>
      <ol class="deletion-steps-list">
        <li>Log in to your account by clicking the button below.</li>
        <li>Open the side menu on the left-hand side of the page.</li>
        <li>Scroll to the bottom of the side menu.</li>
        <li>Locate and click on the "<span class="important-text">Delete Profile</span>" button in red.</li>
        <li>Confirm the deletion of your profile.
          <strong>Please note:</strong> This action is permanent and cannot be undone.
        </li>
      </ol>
      <p>
        <ion-button mode="ios" class="primary-button" (click)="navLogin()">
          Log in
        </ion-button>
      </p>
    </div>
    <!-- End: Account Deletion Steps -->
  </div>
</div>