<div class="input-wrapper">
  <div [style.display]="sharedConfig.inputTextAddressShowScreenOverrideToMain ? 'block' : 'none'">
    <ion-item mode="ios">
      <ion-label position="floating">{{ question.heading }}</ion-label>
      <ion-input class="bold-text" readonly="true" [(ngModel)]="searchInput"> </ion-input>
    </ion-item>
  </div>

  <div class="lookup-component-wrapper">
    <ion-item class="ion-item-lcw" mode="ios" native>
      <ion-label position="floating">Search</ion-label>
      <ion-input
        id="{{ question.underwriting_answers[0].object_answer }}"
        #optionsInput
        class="bold-text"
        mode="ios"
        (ionInput)="searchKeyDown($event)"
        [(ngModel)]="searchInput"
      ></ion-input>
      <ion-icon name="close" slot="end" (click)="clearInput()" class="ion-icon-lcw ion-align-self-center"></ion-icon>
    </ion-item>
    <div class="search-results-wrapper limit-width-on-desktop-float">
      <ion-radio-group value="{{ question.underwriting_answers[0].object_answer }}">
        <ion-item
          mode="md"
          *ngFor="let questionOption of filteredQuestionList"
          lines="none"
          [ngClass]="{
            active: questionOption === question.underwriting_answers[0].object_answer,
            options: true
          }"
          (click)="answerValue(questionOption)"
        >
          <ion-text>{{ questionOption }}</ion-text>
          <ion-icon
            *ngIf="questionOption === question.underwriting_answers[0].object_answer"
            class="checked"
            [ngClass]="{
              selected: questionOption === question.underwriting_answers[0].object_answer
            }"
            src="assets/icon/app-icons/tick-primary.svg"
            slot="end"
          ></ion-icon>
        </ion-item>
      </ion-radio-group>
    </div>
  </div>
</div>

<popup-with-dismiss-button *ngIf="sharedConfig.showPopupWithDismissButton"></popup-with-dismiss-button>
