<div class="white-bg">
  <div class="wrapper kp-wrapper limit-width-on-desktop-float">
    <div *ngIf="showAn" class="animation-wrapper">
      <lottie-player autoplay mode="normal" loop src="https://assets9.lottiefiles.com/packages/lf20_oafyxhze.json"></lottie-player>
    </div>
    <h2 class="h2-bold">{{ sharedConfig.user.personal_details.name }}, we’re nearly there.</h2>
    <p>All your assets with their premiums will be listed here once your King Price quote has been processed</p>
  </div>
  <div class="bottom-text limit-width-on-desktop-float">
    <p>
      Have any questions? <a (click)="showIntercom()"><b>Contact us</b></a>
    </p>
  </div>
</div>

<!--TODO: USE THIS POPUP FOR ALL INSURER POPUPS -->

<custom-popup *ngIf="sharedConfig.insurerPopup">
  <div class="custom-popup">
    <div class="heading">
      <h2 class="h2-bold">Contact Insurer</h2>
    </div>
    <div class="user-found">
      <!-- <ion-list *ngFor="let insurer of sharedConfig.appClientDetail.insurerContactDetails">
              <ion-item (click)="call(insurer)" mode="ios">
                <ion-icon slot="start" class="chat-icon icon-stroke-dark" src="assets/icon/genetic-icons/call-icon.svg">
                </ion-icon>
                <ion-label><b>{{insurer.name}}</b></ion-label>
              </ion-item>
            </ion-list> -->
    </div>
    <div class="buttons-wrapper">
      <ion-button mode="ios" class="secondary-button" (click)="closeInsurerPopup()">Close</ion-button>
    </div>
  </div>
</custom-popup>
