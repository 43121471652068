import { Component, OnInit } from '@angular/core';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { SharedConfig } from '@takectrl-multiapp/svcs-share-config';
declare const dataLayer: any[];

@Component({
  selector: 'quote-fetching',
  templateUrl: './quote-fetching.component.html',
  styleUrls: ['./quote-fetching.component.scss'],
})
export class QuoteFetchingComponent implements OnInit {
  constructor(public thirdpartyService: BUSNThirdPartyService) {}

  ngOnInit(): void {
    
 dataLayer.push({
      event: 'page_show',
      screenPath: 'quote-fetching',
      screenName: 'quote-fetching',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });

  }
}
