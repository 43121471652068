<div class="wrapper around limit-width-on-desktop-float">
    <div class="header-wrapper">
        <h2 class="h2-bold text-center">
            We're updating your policy
        </h2>
        <div class="header-image">
            <div class="loader-wrapper" *ngIf="showLottie">
                <div role="status">
                    <lottie-player autoplay mode="normal" src="assets/lottie-animations/thumbs-up.json">
                    </lottie-player>
                </div>
            </div>
        </div>
    </div>
    <div class="content-wrapper">
        <div>
            <p class="p-medium text-center">We will notify you as soon as your policy is updated. This will only take a few minutes.</p>
        </div>
    </div>
</div>

<div *ngIf="!sharedConfig.appSettings.refer_a_friend_enabled" class="footer-button-wrapper">
    <div class="fade"></div>
    <div class="footer-button-background">
        <ion-button mode="ios" (click)="goHome()" class="footer-button" expand="block">
            OK, thanks
        </ion-button>
    </div>
</div>
<div *ngIf="sharedConfig.appSettings.refer_a_friend_enabled" class="footer-wrapper">
    <ion-grid *ngIf="sharedConfig.appSettings.refer_a_friend_enabled" class="limit-width-on-desktop-float">
        <div class="footer-text-wrapper">
            <div>
                <ion-icon src="assets/icon/misc-icons/gift.svg"></ion-icon>
            </div>
            <div>
                <b>Impressed with what you have seen so far?</b>
                <p class="p-medium">Spread the word by referring a friend. There’s even something in it for you!</p>
            </div>
        </div>
        <ion-row class="button-row">
            <ion-col>
                <ion-button (click)="goHome()" mode="ios" class="later-button">Maybe later</ion-button>
            </ion-col>
            <ion-col>
                <ion-button (click)="referafriend()" mode="ios" class="refer-button">Refer a friend</ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</div>