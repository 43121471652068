<div class="wrapper limit-width-on-desktop">
  <div class="head-section">
    <div class="img-div">
      <img src="assets/logos/primary.svg" alt="" />
    </div>
    <div class="top-text">
      <h2 class="h2-medium">Welcome</h2>
    </div>
  </div>
  <div class="login-form">
    <ion-item mode="ios" [class.error-input]="emailError">
      <ion-label position="floating" [class.error-lable]="emailError" color="text-grey"> Email </ion-label>
      <ion-input
        #emailField
        (keydown)="onEnterEmailField($event)"
        (input)="validateEmail($event)"
        value="{{ userEmail }}"
        type="email"
      ></ion-input>
    </ion-item>
    <div *ngIf="emailError">
      <p class="error-message">Please enter a valid email</p>
    </div>
    <ion-item mode="ios" class="password-item" native>
      <ion-label color="text-grey" [class.error-lable]="passwordError" position="floating" class="ion-align-self-left">Password</ion-label>
      <ion-input
        #passwordField
        (keydown)="onEnterPasswordField($event)"
        (input)="validatePassword($event)"
        type="{{ passwordType }}"
        class="ion-align-self-center"
      ></ion-input>
      <ion-icon slot="end" src="assets/icon/input-icons/{{ passwordIcon }}.svg" class="password-icon" (click)="showPassword()"></ion-icon>
    </ion-item>
    <div *ngIf="passwordError">
      <p class="error-message">Password must be more that 6 characters long</p>
    </div>

    <a class="forgot" appEventTracking eventType="button" (click)="forgotPassword()"
      >Forgot password</a
    >
    <br />
    <div class="button-row">
      <ion-button mode="ios" class="primary-button" (click)="loginWithEmailAndPassword()" [disabled]="disableButtonEmail || disableButtonPassword">
        Log in
      </ion-button>
    </div>
    <div class="button-row" *ngIf="!sharedConfig.routeParamUserMsisdn && sharedConfig.routeParamLogInWithOTP && !showOTPScreen">
      <ion-button mode="ios" class="primary-button" (click)="requestOTPValidateIfUserExistInDB()" [disabled]="disableButtonCell"> Request OTP </ion-button>
    </div>
    <div
      class="pb-230"
      *ngIf="!sharedConfig.routeParamUserMsisdn && sharedConfig.showSetNewPasswordButtonOnLogin && !sharedConfig.routeParamLogInWithOTP"
    >
      <ion-button mode="ios" class="secondary-button" (click)="forgotPassword()"> Set a new password </ion-button>
    </div>
  </div>
</div>

<popup-dialog  id="loginWithEmailAndPassword.component" *ngIf="sharedConfig.popupDialogShow"></popup-dialog>
<!-- todo: Josh, this popup is completely DARK/Blank whne opening up - Only on staging (using hosted endtpoin). locally it works fine -->
<!-- <small-popup *ngIf="sharedConfig.showPopupWithDismissButton_updateApp" [header]="'New version available'" [message]="'Updating to latest version...'">
  <ion-button mode="ios" class="primary-button" (click)="closeUpdateAppPage()"> Ok </ion-button>
</small-popup> -->