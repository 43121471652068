import { Component, NgZone, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import '@lottiefiles/lottie-player';
import { BUSNUserService } from '@takectrl-multiapp/svcs-core-business';

@Component({
  selector: 'success-with-push-notification-animation',
  templateUrl: './success-with-push-notification-animation.component.html',
  styleUrls: ['./success-with-push-notification-animation.component.scss'],
})
export class SuccessWithPushNotificationAnimationComponent implements OnInit {
  show = true;
  pushNotifications: boolean;
  constructor(
    public busnUserService: BUSNUserService,
    public navCtrl: NavController,
    private readonly zone: NgZone,
    private readonly sharedConfig: SharedConfig
  ) {}

  ngOnInit(): void {}

  next() {
    SharedConfig.showFullScreen = false;
    if (this.sharedConfig.schedulesActive.length > 0) {
      this.zone.run(() => {
        this.navCtrl.navigateRoot('home/home-wallet');
      });
    } else {
      this.zone.run(() => {
        this.navCtrl.navigateRoot('home/home-quote');
      });
    }
  }
}
