import { Component, NgZone, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { BUSNThirdPartyService, BUSNUserService } from '@takectrl-multiapp/svcs-core-business';
import { SharedConfig, SharedDialogService } from '@takectrl-multiapp/svcs-share-config';
import { LoadingController } from '@ionic/angular';
declare const dataLayer: any[];

@Component({
  selector: 'referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.scss'],
})
export class ReferralComponent implements OnInit {
  friendName: string;
  friendSurname: string;
  friendEmail: string;
  errorText: string;
  public showSucessPage = false;
  public referral_amount = this.sharedConfig.appSettings.referral_amount;
  public userReferralCode = this.sharedConfig.user.account_details.user_referral_code;
  constructor(
    public userService: BUSNUserService,
    public navCtrl: NavController,
    public zone: NgZone,
    public loadingCtrl: LoadingController,
    private readonly busnThirdPartyService: BUSNThirdPartyService,
    public sharedConfig: SharedConfig,
    public sharedDialogService: SharedDialogService
  ) {}

  ngOnInit(): void {
    dataLayer.push({
      event: 'page_show',
      screenPath: 'referral',
      screenName: 'referral',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }

  checkEmail(e: Event) {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailInput: string = e.target['value'];
    if (!emailRegex.test(emailInput)) {
      this.errorText = 'Invalid email';
    } else {
      this.errorText = null;
    }
  }

  async send() {
    this.sharedConfig.setShowCustomLoader(true)
    this.busnThirdPartyService.logEvent('action_sent_friend_referral', { preferred_name: this.friendName, surname: this.friendSurname });
    this.userService.newReferralEmail(this.friendName, this.friendSurname, this.friendEmail).then(async (val) => {
      if (val.success === true) {
        this.showSucessPage = true;
        this.sharedConfig.setShowCustomLoader(false)
      } else {
        this.sharedConfig.setShowCustomLoader(false)
        this.showSucessPage = false;
        this.sharedDialogService.showPopupDialog({
          header: 'Something went wrong',
          subHeader: "We could not send your referral, please try again",
          buttonOneText: 'OK',
          buttonOneCallback: this.sharedDialogService.hidePopupDialog,
        });
      }
    });
  }

  done() {
    if (this.sharedConfig.schedulesActive.length > 0) {
      this.zone.run(() => {
        this.navCtrl.navigateRoot('home/home-wallet');
      });
    } else {
      this.zone.run(() => {
        this.navCtrl.navigateRoot('home/home-quote');
      });
    }
  }
}
