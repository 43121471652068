import { Injectable } from '@angular/core';
import {
  IUser,
} from '@takectrl-multiapp/svcs-share-config';
import { APIHttpService } from './api-http.service';
import { Observable } from 'rxjs';
import { ICtrlResponse, IPortalTableResponse } from '../interfaces/api.interface';

@Injectable({
  providedIn: 'root',
})
export class APIPortalService {
  constructor(private readonly httpService: APIHttpService) {}

  getPortalLoggedInUser(): Observable<ICtrlResponse<IUser>> {
    return this.httpService.get(`/users/`)
  }

  getTableData(endpoint: string) : Observable<ICtrlResponse<IPortalTableResponse>> {
    return this.httpService.get(endpoint);
  }
  
}
