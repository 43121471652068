import { ApiNeedsAnalysisService } from '@takectrl-multiapp/svcs-ctrl-api';
import { IPastFNA, IPriorityRating, SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BusnNeedsAnalysisService {
  constructor(public apiNeedsAnalysisService: ApiNeedsAnalysisService, public sharedConfig: SharedConfig) {}

  async getQuoteFNA(): Promise<IPastFNA> {
    const quote_request_id = this.sharedConfig.quoteRequest.id;
    return await this.apiNeedsAnalysisService.getQuoteFinancialNeedAnalysis(quote_request_id);
  }

  async updateFNA(
    premium_vs_core_cover: number,
    premium_vs_nice_to_haves: number,
    premium_vs_low_excess: number,
    core_cover_vs_nice_to_haves: number,
    core_cover_vs_low_excess: number,
    nice_to_haves_vs_low_excess: number
  ): Promise<IPriorityRating[]> {
    const quote_request_id = this.sharedConfig.quoteRequest.id;
    return (await firstValueFrom(
      this.apiNeedsAnalysisService.updateFinancialNeedsAnalysis(
        quote_request_id,
        premium_vs_core_cover,
        premium_vs_nice_to_haves,
        premium_vs_low_excess,
        core_cover_vs_nice_to_haves,
        core_cover_vs_low_excess,
        nice_to_haves_vs_low_excess
      )
    )).results;
  }
}
