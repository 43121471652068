<quote-request-started
  *ngIf="sharedConfig?.quoteRequest && sharedConfig?.quoteRequest.type === QuoteRequestType.Quote && sharedConfig?.quoteRequest?.status === 'OPEN'"
  (navigatePage)="navFromRefPage($event)"
>
</quote-request-started>

<quote-request-not-started
  *ngIf="
    !sharedConfig?.quoteRequest ||
    sharedConfig?.quoteRequest.type !== QuoteRequestType.Quote ||
    sharedConfig.quoteRequest?.status === 'LIVE' ||
    sharedConfig.quoteRequest?.status === QuoteRequestStatusType.Failed ||
    sharedConfig.quoteRequest?.status === 'ACCEPTED' ||
    sharedConfig.quoteRequest?.status === QuoteRequestStatusType.Cancelled
  "
  (navigatePage)="navFromRefPage($event)"
>
</quote-request-not-started>

<div
  class="wrapper limit-width-on-desktop-float"
  *ngIf="
    sharedConfig.quoteRequest &&
    sharedConfig.quoteRequest.type === QuoteRequestType.Quote &&
    (sharedConfig.quoteRequest?.status === QuoteRequestStatusType.Submitted ||
      sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingToAccept ||
      sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingOnInsurer ||
      sharedConfig.quoteRequest?.status === QuoteRequestStatusType.Pending ||
      sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingOnQuotes)
  "
>
  <div class="top-section">
    <h2 class="h2-bold">Hi {{ sharedConfig.user.personal_details.name }}</h2>
    <p [hidden]="sharedConfig.quoteRequest && sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingToAccept">
      Hang tight while we find you the best prices.
    </p>
    <p *ngIf="sharedConfig.quoteRequest && sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingToAccept">
      We found you the best prices.
    </p>
  </div>
  <div class="card-quotes">
    <div class="button-header">
      <ion-item
        mode="ios"
        lines="none"
        (click)="startNewQuote()"
        [hidden]="
          sharedConfig.quoteRequest &&
          (sharedConfig.quoteRequest?.status === QuoteRequestStatusType.Submitted ||
            sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingToAccept ||
            sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingOnInsurer ||
            sharedConfig.quoteRequest?.status === QuoteRequestStatusType.Open ||
            sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingOnQuotes ||
            sharedConfig.quoteRequest?.status === QuoteRequestStatusType.Pending)
        "
      >
        <ion-text>
          <b>Start my quote</b>
        </ion-text>
        <ion-icon class="swiper-icon icon-stroke-primary" slot="end" src="assets/icon/genetic-icons/arrow-right-circle.svg"></ion-icon>
      </ion-item>
      <ion-item mode="ios" lines="none" (click)="startNewQuote()" *ngIf="sharedConfig.quoteRequest?.status === QuoteRequestStatusType.Open">
        <ion-text>
          <b>Continue with my quote</b>
        </ion-text>
        <ion-icon class="swiper-icon icon-stroke-primary" slot="end" src="assets/icon/genetic-icons/arrow-right-circle.svg"></ion-icon>
      </ion-item>
      <ion-item mode="ios" lines="none" *ngIf="sharedConfig.quoteRequest?.status === QuoteRequestStatusType.Pending">
        <ion-text>
          <b>Loading your policy</b>
        </ion-text>
        <ion-icon class="swiper-icon icon-stroke-primary" slot="end" src="assets/icon/genetic-icons/arrow-right-circle.svg"></ion-icon>
      </ion-item>
      <ion-item
        mode="ios"
        lines="none"
        *ngIf="sharedConfig.quoteRequest === QuoteRequestType.Quote && sharedConfig.quoteRequest?.status === QuoteRequestStatusType.Submitted"
      >
        <ion-text>
          <b>Fetching your quotes...</b>
        </ion-text>
      </ion-item>
      <ion-item
        mode="ios"
        (click)="openViewNewQuotesPage()"
        lines="none"
        *ngIf="
          sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingToAccept ||
          sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingOnQuotes ||
          sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingOnInsurer
        "
      >
        <ion-text>
          <b
            *ngIf="
              sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingOnQuotes ||
              sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingOnInsurer
            "
            >You’ve got some quotes.</b
          >
          <b *ngIf="sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingToAccept">All your quotes are in!</b>
        </ion-text>
        <ion-icon class="swiper-icon icon-stroke-primary" slot="end" src="assets/icon/genetic-icons/arrow-right-circle.svg"></ion-icon>
      </ion-item>
    </div>
    <div
      class="progress-wrapper"
      *ngIf="
        sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingOnQuotes ||
        sharedConfig.quoteRequest?.status === QuoteRequestStatusType.Submitted ||
        sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingOnInsurer
      "
    >
      <p class="progress-heading" *ngIf="sharedConfig.quoteRequest?.status === QuoteRequestStatusType.Submitted">
        Your quotes will be ready to view soon.
      </p>
      <p
        class="progress-heading"
        *ngIf="
          sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingOnQuotes ||
          sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingOnInsurer
        "
      >
        All your quotes will be ready to view soon.
      </p>
      <div class="container">
        <div class="progress-bar-container">
          <div *ngIf="!sharedConfig?.quoteRequest?.quotes || sharedConfig?.quoteRequest?.quotes.length === 0" class="progress-bar">
            <div class="linear-movement"></div>
          </div>
          <div
            class="progress-bar"
            *ngIf="sharedConfig?.quoteRequest?.quotes?.length > 0"
            [ngStyle]="{ width: 'calc(' + (sharedConfig?.quoteRequest?.quotes?.length / up_to_number_of_quotes) * 100 + '% )' }"
          >
            <div class="linear-movement"></div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="sharedConfig.quoteRequest?.status === QuoteRequestStatusType.Submitted" class="card-quotes-coming-in">
      <ion-item *ngFor="let insurer of getInsurerThatReturnedQuotesList()" mode="ios" lines="none">
        <ion-img class="quote-icon" slot="start" src="{{ insurer.img_source }}"> </ion-img>
        <ion-icon slot="end" [src]="'assets/icon/genetic-icons/quote-success.svg'"></ion-icon>
      </ion-item>
    </div>

    <div
      *ngIf="
        sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingToAccept ||
        sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingOnQuotes
      "
      class="card-quotes-coming-in"
      (click)="openViewNewQuotesPage()"
    >
      <ion-item *ngFor="let insurer of getInsurerThatReturnedQuotesList()" mode="ios" lines="none">
        <ion-img class="quote-icon" slot="start" src="{{ insurer.img_source }}"> </ion-img>
        <ion-icon slot="end" [src]="'assets/icon/quote-icon/quote-success.svg'"></ion-icon>
      </ion-item>
    </div>

    <div *ngIf="sharedConfig.quoteRequest?.status === QuoteRequestStatusType.Submitted" class="card-quotes-coming-in">
      <ion-item *ngFor="let insurer of getInsurerThatHasNotReturnedQuotesList(sharedConfig.quoteRequest.quotes)" mode="ios" lines="none">
        <ion-img class="quote-icon" slot="start" src="{{ insurer.img_source }}"> </ion-img>
        <ion-icon slot="end" [src]="'assets/icon/genetic-icons/quote-pending.svg'"></ion-icon>
      </ion-item>
    </div>

    <div
      *ngIf="
        sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingOnQuotes ||
        sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingOnInsurer
      "
      class="card-quotes-coming-in"
      (click)="openViewNewQuotesPage()"
    >
      <ion-item *ngFor="let insurer of getInsurerThatHasNotReturnedQuotesList(sharedConfig.quoteRequest.quotes)" mode="ios" lines="none">
        <ion-img class="quote-icon" slot="start" src="{{ insurer.img_source }}"> </ion-img>
        <ion-icon slot="end" [src]="'assets/icon/quote-icon/quote-pending.svg'"></ion-icon>
      </ion-item>
    </div>

    <!-- IF ALL QUOTES IS DONE FETCHING -> NOT QUOTED ON -->
    <div *ngIf="sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingToAccept" class="did-not-quote-wrapper">
      <hr />
      <ion-item mode="ios" lines="none">
        <ion-text class="tab-text"
          ><p>
            <b>{{ getInsurerThatHasNotReturnedQuotesList(sharedConfig.quoteRequest.quotes).length }} insurers did not quote</b>
          </p>
          <ion-icon (click)="showPopupDidNotQuote()" src="assets/icon/genetic-icons/info.svg"></ion-icon
        ></ion-text>
        <ion-icon
          class="chevron-icon"
          (click)="showHideDidNotQuotes()"
          *ngIf="!showQuotesTab"
          slot="end"
          src="assets/icon/genetic-icons/chevron-down.svg"
        ></ion-icon>
        <ion-icon
          class="chevron-icon"
          (click)="showHideDidNotQuotes()"
          *ngIf="showQuotesTab"
          slot="end"
          src="assets/icon/genetic-icons/chevron-up.svg"
        ></ion-icon>
      </ion-item>
      <div class="hidden-tab-wrapper" *ngIf="showQuotesTab">
        <!-- IF ALL QUOTES IS DONE FETCHING -> NOT QUOTED ON -->
        <ion-item *ngFor="let insurer of getInsurerThatHasNotReturnedQuotesList(sharedConfig.quoteRequest.quotes)" mode="ios" lines="none">
          <ion-img class="quote-icon" slot="start" src="{{ insurer.img_source }}"> </ion-img>
          <ion-icon slot="end" [src]="'assets/icon/genetic-icons/quote-failed.svg'"></ion-icon>
        </ion-item>
      </div>
    </div>
  </div>
  <div class="new-quote-btn" *ngIf="sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingToAccept">
    <ion-button class="secondary-button" (click)="startNewQuoteVerifyDialog()">Restart quote</ion-button>
    <ion-button class="secondary-button" (click)="backToWalletPage()">Back</ion-button>
  </div>
  <div class="spacing"></div>
</div>

<small-popup *ngIf="showPopup" [header]="popupHeader" [message]="popupMessage">
  <ion-button mode="ios" *ngIf="popupRestartQuote" class="primary-button" (click)="startNewQuote()">Restart my quote </ion-button>
  <ion-button mode="ios" *ngIf="popupRestartQuote" class="secondary-button" (click)="cancel()">Cancel</ion-button>
  <ion-button mode="ios" *ngIf="popupDidNotQuote" class="primary-button" (click)="cancel()">Ok got it </ion-button>
  <ion-button mode="ios" *ngIf="popupDidNotQuote" class="secondary-button" (click)="showIntercom()">Contact us</ion-button>
</small-popup>

<popup-with-chat-button *ngIf="sharedConfig.showPopupWithChat"></popup-with-chat-button>
<popup-with-dismiss-button *ngIf="sharedConfig.showPopupWithDismissButton"></popup-with-dismiss-button>

<!-- todo: Josh, this popup is completely DARK/Blank whne opening up - Only on staging (using hosted endtpoin). locally it works fine -->
<!-- <small-popup *ngIf="sharedConfig.showPopupWithDismissButton_updateApp" [header]="'New version available'" [message]="'Update to latest version.'">
  <ion-button mode="ios" class="primary-button" (click)="updateApp()"> Update </ion-button>
</small-popup> -->

<!-- TODO ALL OTHER POPUPS SHOULD BE REPLACED BY THIS GENERIC POPUP -->
<popup-dialog id="quote.component" *ngIf="sharedConfig.popupDialogShow"></popup-dialog>
