import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { SharedConfig } from '@takectrl-multiapp/svcs-share-config';
declare const dataLayer: any[];

@Component({
  selector: 'update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss'],
})
export class UpdateComponent implements OnInit {
  public storeUrl: string;
  constructor(public thirdpartyService: BUSNThirdPartyService, private readonly plateform: Platform, private readonly sharedConfig: SharedConfig) {}

  ngOnInit(): void {
    dataLayer.push({
      event: 'page_show',
      screenPath: 'update',
      screenName: 'update',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }

  clickUpdate() {
    window.open(this.sharedConfig.appSettings.app_download_url, '_system');
  }
}
