<get-started-animation
  *ngIf="this.sharedConfig.showLottieFullScreen"
  [animationSrc]="animationSrc"
  [sub_heading]="sub_heading"
></get-started-animation>

<form [formGroup]="registerForm" novalidate>
  <div class="h-100" *ngIf="screenIndex === 1">
    <div class="slider-wrapper limit-width-on-desktop">
      <div class="wrapper">
        <div class="heading-section">
          <h2 class="h2-bold">Let's get to know you better</h2>
        </div>
        <div class="input-section">
          <!-- These values are set once the user accepts the popia Popup -->
          <ion-checkbox slot="end" formControlName="popia_consent" hidden></ion-checkbox>
          <ion-input formControlName="popia_message" [ngModelOptions]="{ standalone: true }" type="text" hidden></ion-input>
          <ion-item mode="ios">
            <ion-label position="floating">First name</ion-label>
            <ion-input
              (keydown)="nextOnEnter($event)"
              class="capitalize"
              autocapitalize="words"
              formControlName="name"
              type="text"
              [ngModelOptions]="{ standalone: true }"
            ></ion-input>
          </ion-item>
          <p *ngIf="!registerForm.controls.name.valid && registerForm.controls.name.dirty" class="error-message">Enter a valid name</p>
          <ion-item mode="ios">
            <ion-label position="floating">Surname</ion-label>
            <ion-input
              (keydown)="nextOnEnter($event)"
              class="capitalize"
              autocapitalize="words"
              formControlName="surname"
              type="text"
              [ngModelOptions]="{ standalone: true }"
            ></ion-input>
          </ion-item>
          <p class="error-message" *ngIf="!registerForm.controls.surname.valid && registerForm.controls.surname.dirty">Enter a valid surname</p>
          <p *ngIf="numberInName === true" class="error-message">Number not allowed in name and surname</p>
        </div>
        <div class="button-section limit-width-on-desktop">
          <ion-button
            mode="ios"
            type="button"
            class="primary-button"
            (click)="registerFormSlideNext()"
            [disabled]="!registerForm.controls.name.valid || !registerForm.controls.surname.valid"
          >
            Next
          </ion-button>
          <div class="terms-wrapper">
            <p>
              By registering you agree to our <br /><a href="{{ sharedConfig.appSettings?.terms_url }}" target="_blank"
                >Terms and Conditions and Privacy Policy</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-banner">
      <ion-grid class="footer-grid limit-width-on-desktop">
        <ion-row>
          <ion-col size="9">Already have an account?</ion-col>
          <ion-col size="3" class="text-right"><a (click)="loginPage()">Log in</a></ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
  <div *ngIf="screenIndex === 2">
    <div class="slider-wrapper">
      <div class="wrapper limit-width-on-desktop">
        <div class="heading-section">
          <h2 class="h2-bold">Nice to meet you! Let’s set up your account</h2>
          <p class="mail-text">We’ll need your email and a password. You'll use these to log in.</p>
        </div>
        <div class="input-section">
          <ion-item mode="ios">
            <ion-label position="floating">Email address</ion-label>
            <ion-input (keydown)="registerOnEnter($event)" formControlName="email" [ngModelOptions]="{ standalone: true }" type="email"></ion-input>
          </ion-item>
          <p *ngIf="!registerForm.controls.email.valid && registerForm.controls.email.dirty" class="error-message">Please provide a valid email</p>
          <ion-item mode="ios">
            <ion-label position="floating">Password</ion-label>
            <ion-input
              (keydown)="registerOnEnter($event)"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="password"
              formControlName="password1"
              type="{{ passwordType }}"
            ></ion-input>
            <ion-icon slot="end" src="assets/icon/input-icons/{{ passwordIcon }}.svg" class="passwordIcon" (click)="showPassword()"></ion-icon>
          </ion-item>
          <p class="error-message" *ngIf="!registerForm.controls.password1.valid && registerForm.controls.password1.dirty">Invalid password</p>
        </div>
        <div class="button-section">
          <div *ngIf="sharedConfig?.appSettings?.list_brokers_on_registration && !brokerAutoAssigned" class="button-section">
            <ion-button
              mode="ios"
              type="button"
              class="primary-button"
              [disabled]="!registerForm.controls.email.valid || !registerForm.controls.password1.valid"
              (click)="registerFormSlideNext()"
            >
              Next
            </ion-button>
          </div>
          <div *ngIf="!sharedConfig?.appSettings?.list_brokers_on_registration || brokerAutoAssigned" class="button-section">
            <ion-button
              mode="ios"
              (click)="initializeRegistration()"
              [disabled]="!registerForm.controls.email.valid || !registerForm.controls.password1.valid"
              class="primary-button"
            >
              Register
            </ion-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="screenIndex === 3 && sharedConfig?.appSettings?.list_brokers_on_registration && !brokerAutoAssigned">
    <div class="slider-wrapper">
      <div class="broker-progress"></div>
      <div class="wrapper limit-width-on-desktop">
        <div class="heading-section remove-bottom-padding">
          <h2 class="h2-bold">Add your adviser</h2>
          <p class="p-meduim">Please enter your unique 5-digit code that was provided by your adviser.</p>
        </div>
        <div class="input-section">
          <ion-item mode="ios">
            <ion-label position="floating">Your 5-digit code starting with {{ sharedConfig.appSettings.broker_search_prefix }}</ion-label>
            <ion-input
              #brokerCodeInput
              *ngIf="brokers"
              maxlength="5"
              value="{{ brokerCodeValue ? brokerCodeValue : sharedConfig.appSettings.broker_search_prefix }}"
              debounce="2000"
              (ionInput)="searchBrokers($event)"
              [ngModelOptions]="{ standalone: true }"
            >
            </ion-input>
          </ion-item>
          <div class="search-results-wrapper" *ngIf="showBrokerResults === true">
            <div class="inner-wrapper" *ngFor="let broker of brokers">
              <p (click)="showBrokerDetails(broker)">{{ broker.contact }}</p>
            </div>
          </div>
        </div>
        <div class="info-card">
          <p>
            Don't have a code? Please contact your financial adviser to get their unique franchise code - this will ensure that you are allocated to
            the correct adviser. Alternatively, please select a financial adviser below.
          </p>
        </div>
        <div class="input-section">
          <ion-item mode="ios">
            <ion-label position="floating">Choose an advisor</ion-label>
            <ion-input
              [ngModelOptions]="{ standalone: true }"
              readonly="true"
              [(ngModel)]="selectedAdvisor"
              (click)="openSelectAdvisor()"
            ></ion-input>
          </ion-item>
        </div>

        <div class="button-section">
          <ion-button [disabled]="!enableBrokerNextButton" mode="ios" (click)="assignBrokerAndGoNext()" class="primary-button"> Next </ion-button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="screenIndex === 4 && sharedConfig?.appSettings?.list_brokers_on_registration && !brokerAutoAssigned">
    <div class="slider-wrapper">
      <div class="wrapper limit-width-on-desktop">
        <div class="success-card">
          <ul class="custom">
            <li style="background: url(assets/icon/app-icons/checkbox-tick-success.svg) no-repeat">
              <h2 class="h2-bold">Success</h2>
            </li>
            <li>
              <b>{{ brokerName }} ({{ brokerSearchCode }})</b> from <b>{{ brokerAgent }}</b> was added as your adviser.
            </li>
          </ul>
        </div>
        <div class="start-over">
          <small>Not the correct adviser? <a (click)="prevQuestion()">Start over</a></small>
        </div>

        <div class="button-section">
          <ion-button [disabled]="!enableRegisterButton" mode="ios" (click)="registerUser()" class="primary-button"> Register </ion-button>
        </div>
      </div>
    </div>
  </div>
</form>

<div class="backdrop" *ngIf="showSelectBroker"></div>
<div class="popup-dates limit-width-on-desktop-float" *ngIf="showSelectBroker">
  <div class="text-center">
    <h2 class="h2-bold">Choose an advisor</h2>
  </div>
  <div class="dates">
    <div class="date-wrapper clickable" *ngFor="let brokers of allBrokers" (click)="selectAdvisor(brokers)">
      {{ brokers.adviserNameTown }}
    </div>
  </div>
  <ion-button class="secondary-button" (click)="dismissChooseBrokerPopup()">Close</ion-button>
</div>

<popup-dialog id="signup.component" *ngIf="sharedConfig.popupDialogShow"></popup-dialog>

<!-- <floating-button
  *ngIf="testingService.isTester() && !showAuthRegLoading"
  [buttonLabel]="'Auto Register'"
  (buttonAction)="compFloatingAction()"
></floating-button> -->
