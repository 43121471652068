import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { CommunicationService } from '@takectrl-multiapp/svcs-core';
import { BUSNPolicyService } from '@takectrl-multiapp/svcs-core-business';
import { IDealershipNumbers, SharedConfig, SharedDialogService } from '@takectrl-multiapp/svcs-share-config';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Component({
  selector: 'motor-group-details',
  templateUrl: './motor-group-details.component.html',
  styleUrls: ['./motor-group-details.component.scss'],
})
export class MotorGroupDetailsComponent implements OnInit {
  dealerships = [];
  selectedDealership: string;
  dealershipsNumbers: IDealershipNumbers;
  showDealerships = false;
  showSmallPopup = false;
  smallPopupHeader: string;
  smallPopupMessage: string;
  smallPopupButtonText: string;
  callDealerNumber: string;
  allDealerShips: Array<IDealershipNumbers>;
  constructor(
    private readonly busnPolicyService: BUSNPolicyService,
    private readonly communicationService: CommunicationService,
    public platform: Platform,
    public sharedConfig: SharedConfig,
    private readonly iab: InAppBrowser,
    private sharedDialogService: SharedDialogService,
  ) {}

  async ngOnInit() {
    this.sharedConfig.setShowCustomLoader(true)
    this.busnPolicyService.getDealerShips(this.sharedConfig.appSettings.motor_group_key).then((dealerships) => {
      if (dealerships)
      this.allDealerShips = dealerships[this.sharedConfig.appSettings.motor_group_key];
        for (const allDealers of Object.keys(dealerships[this.sharedConfig.appSettings.motor_group_key])) {
          this.dealerships.push(allDealers);
        }
    });
    this.sharedConfig.setShowCustomLoader(false)
    this.makeCall = this.makeCall.bind(this);
  }

  showWebsite() {
    this.iab.create(this.sharedConfig.appSettings.motor_group_url, '_system');
  }

  callDealership(numberToCall, department) {
    this.callDealerNumber = numberToCall;
    this.sharedDialogService.showPopupDialog({
      header: `${department  } number`,
      subHeader: numberToCall,
      buttonOneText: this.platform.is('desktop') ? 'OK' : 'Call',
      buttonOneCallback: this.makeCall,
      buttonTwoText: 'Close',
      buttonTwoCallback: this.sharedDialogService.hidePopupDialog
    });
  }

  makeCall() {
    if (this.platform.is('mobileweb')) {
      document.location.href = `tel:${  this.callDealerNumber}`;
      this.sharedDialogService.hidePopupDialog();
    } else if (this.platform.is('desktop')) {
      this.sharedDialogService.hidePopupDialog();
    } else {
      this.communicationService.makePhoneCall(this.callDealerNumber);
      this.sharedDialogService.hidePopupDialog();
    }
  }

  openDealersPopup() {
    this.sharedConfig.setShowCustomLoader(true)
    const intervalId = setInterval(() => {
      this.sharedConfig.setShowCustomLoader(false)
        this.showDealerships = true;
        SharedConfig.showFullScreen = true;
        clearInterval(intervalId);
    }, 500);
  }

  async selectedDealer(dealer) {
    this.sharedConfig.setShowCustomLoader(true)
    this.selectedDealership = dealer;
    this.dealershipsNumbers = this.allDealerShips[dealer]
    this.dismissPopup();
    this.sharedConfig.setShowCustomLoader(false)
  }

  dismissPopup() {
    this.showDealerships = false;
    SharedConfig.showFullScreen = false;
  }
}
