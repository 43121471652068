<div class="backdrop"></div>
<div class="popup-calendar limit-width-on-desktop-float">
    <div class="text-center">
        <h2 class="h2-bold">{{heading}}</h2>
    </div>
    <div>
        <ion-datetime #datePicker class="bold-text" min="{{ minDate }}" max="{{ maxDate }}"
           (ionChange)="pickDate(datePicker.value)" presentation="{{dateFormat}}" [(ngModel)]="ngModal">
        </ion-datetime>
    </div>
    <div class="buttons-wrapper">
        <ion-button mode="ios" class="primary-button" [disabled]="!hasValue" (click)="saveDate(datePicker.value)">Select</ion-button>
        <ion-button mode="ios" class="secondary-button" (click)="closeDate()">Cancel</ion-button>
    </div>
</div>
