import { Injectable } from '@angular/core';
import {
  IAPIQuoteConvertResponse,
  IBank,
  IBankBranches,
  ICasaDetails,
  IQuote,
  IQuoteConvertResponse,
  IQuoteRequest,
  ISchedule,
  ISpecialConditionAssets,
  QuoteRequestStatusType,
  SharedConfig,
  IAddresses,
  IBankingDetails,
  AssetType,
  IInsuredAsset,
  IInsurers,
  IQuoteCreateResponse,
  IAlertBrokerOfEndorsement,
  IScheduleInsuredAssets,
} from '@takectrl-multiapp/svcs-share-config';
import { APIHttpService } from './api-http.service';
import { APIPolicyService } from './api-policy.service';
import { APIUserService } from './api-user.service';
import { APIAssetService } from './api-asset.service';
import { Observable, take } from 'rxjs';
import { ICtrlResponse } from '../interfaces/api.interface';

@Injectable({
  providedIn: 'root',
})
export class APIQuoteScheduleService {
  constructor(
    public apiUserService: APIUserService,
    public sharedConfig: SharedConfig,
    public apiPolicyService: APIPolicyService,
    public apiAssetService: APIAssetService,
    private readonly ctrlHttp: APIHttpService
  ) {}

  getAllSchedules(): Observable<ICtrlResponse<Array<ISchedule>>> {
    return this.ctrlHttp.get(`/schedules/`, { all_related: true });
  }

  premiumCheck(): Observable<ICtrlResponse<unknown>> {
    return this.ctrlHttp.post(`/providers/brolink/policies/update_premiums/`, {});
  }

  getInsurers(): Observable<ICtrlResponse<Array<IInsurers>>> {
    return this.ctrlHttp.get(`/insurers/`);
  }

  submitKingPriceConfirmation(quoteRequestID: number): Observable<ICtrlResponse<unknown>> {
    return this.ctrlHttp.post('/quotes/activate/king_price/' + quoteRequestID + '/', {});
  }

  initiateQuoteRequestProcessing(quoteRequestID: number): Observable<ICtrlResponse<IQuoteCreateResponse>> {
    return this.ctrlHttp.post('/request-quotes/' + quoteRequestID + '/', {});
  }

  initiateEndorsementRequestProcessing(quoteRequestID: number): Observable<ICtrlResponse<IQuoteCreateResponse>> {
    return this.ctrlHttp.post('/endorsements-submit/' + quoteRequestID + '/', {});
  }

  patchQuoteRequest(requestPayload: IQuoteRequest): Observable<ICtrlResponse<IQuoteRequest>> {
    return this.ctrlHttp.patch(`/quote-requests/${requestPayload.id}/`, requestPayload);
  }

  endorsementUpdateAlertBroker(alertBrokerPayload: IAlertBrokerOfEndorsement): Observable<ICtrlResponse<IAlertBrokerOfEndorsement>> {
    return this.ctrlHttp.post('/endorsements-submit/request_update/', { ...alertBrokerPayload });
  }

  createNewQuoteRequest(requestPayload: object): Observable<ICtrlResponse<IQuoteRequest>> {
    return this.ctrlHttp.post(`/quote-requests/`, requestPayload);
  }

  setQuoteRequest(quoteRequest: IQuoteRequest): Observable<ICtrlResponse<IQuoteRequest>> {
    return this.ctrlHttp.patch('/quote-requests/' + quoteRequest.id + '/', quoteRequest);
  }

  getQuoteRequestById(quote_id: string): Observable<ICtrlResponse<IQuoteRequest>> {
    return this.ctrlHttp.get('/quote-requests/' + quote_id + '/');
  }

  getQuoteRequest(): Observable<Array<IQuoteRequest>> {
    return this.ctrlHttp.get('/quote-request/');
  }

  getQuoteRequests(): Observable<ICtrlResponse<IQuoteRequest[]>> {
    return this.ctrlHttp.get<ICtrlResponse<IQuoteRequest[]>>('/quote-requests/');
  }

  brolinkConvert(quoteRequestID: number, quote_nr: string, bankingDetails: IBankingDetails): Observable<ICtrlResponse<IQuoteConvertResponse>> {
    return this.ctrlHttp.post('/quotes/put/convert/brolink/' + quoteRequestID + '/' + quote_nr + '/', bankingDetails);
  }

  acceptEndorsement(quoteRequestID: number): Observable<ICtrlResponse<String>> {
    return this.ctrlHttp.post('/endorsements-accept/' + quoteRequestID + '/accepted/', {});
  }

  declineEndorsement(quoteRequestID: number): Observable<ICtrlResponse<String>> {
    return this.ctrlHttp.post('/endorsements-accept/' + quoteRequestID + '/declined/', {});
  }

  miwayConvert(quoteRequestID: number): Observable<ICtrlResponse<IAPIQuoteConvertResponse>> {
    return this.ctrlHttp.post(`/quotes/converts/miway/`, {
      user_id: this.sharedConfig.user.id,
      quote_request_id: quoteRequestID,
      account_holder: SharedConfig.quoteBankingDetails.account_holder,
      account_number: SharedConfig.quoteBankingDetails.account_number,
      branch_code: SharedConfig.quoteBankingDetails.branch_code,
      branch_name: SharedConfig.quoteBankingDetails.branch_name,
      collection_day: SharedConfig.quoteBankingDetails.collection_day,
    });
  }

  getQuote(user_id: string): Observable<ICtrlResponse<IQuote>> {
    return this.ctrlHttp.get(`/quotes/${user_id}`);
  }

  getSpecialConditions(): Observable<ICtrlResponse<ISpecialConditionAssets>> {
    return this.ctrlHttp.get(`/special-conditions/`);
  }

  setUnderwritingQuestionsCompleted(quote_request: IQuoteRequest): Observable<ICtrlResponse<IQuoteRequest>> {
    quote_request.underwriting_questions_completed = new Date().getTime();
    return this.setQuoteRequest(quote_request);
  }

  setAddress(address: IAddresses): Observable<ICtrlResponse<IAddresses>> {
    return this.ctrlHttp.post(`/addresses/`, address);
  }

  setOptionalsCompleted(quote_request: IQuoteRequest): Observable<ICtrlResponse<IQuoteRequest>> {
    quote_request.optionals_completed = new Date().getTime();
    return this.setQuoteRequest(quote_request);
  }

  setConfirmationCompleted(quote_request: IQuoteRequest): Observable<ICtrlResponse<IQuoteRequest>> {
    quote_request.status = QuoteRequestStatusType.Submitted;
    quote_request.confirmation_completed = new Date().getTime();
    return this.setQuoteRequest(quote_request);
  }

  updateQuoteRequestStatus(quote_request: IQuoteRequest, status: QuoteRequestStatusType): Observable<ICtrlResponse<IQuoteRequest>> {
    quote_request.status = status;
    return this.setQuoteRequest(quote_request);
  }

  updateCoverStartDate(date: string, quote_request: IQuoteRequest): Observable<ICtrlResponse<IQuoteRequest>> {
    quote_request.cover_start_date = new Date(date);
    return this.setQuoteRequest(quote_request);
  }

  getBankBranches(bank: string): Observable<ICtrlResponse<IBankBranches>> {
    return this.ctrlHttp.get(`/bankbranchcodes/${bank}`);
  }

  getBanks(): Observable<ICtrlResponse<IBank[]>> {
    return this.ctrlHttp.get(`/banks/`);
  }

  loadCASAList(): Observable<ICtrlResponse<ICasaDetails>> {
    return this.ctrlHttp.get(`/casa/combined`);
  }

  async updateCASA(
    corCode: string,
    corDesc: string,
    cobCode: string,
    cobDesc: string,
    nationalityCode: string,
    nationalityDesc: string,
    sourceOfIncomeCode: string,
    sourceOfIncomeDesc: string,
    empCode: string,
    empDesc: string,
    occupation_code: string,
    occupationDesc: string
  ) {
    this.sharedConfig.user.personal_details.country_of_birth_code = cobCode;
    this.sharedConfig.user.personal_details.country_of_birth_description = cobDesc;
    this.sharedConfig.user.personal_details.country_of_residence_code = corCode;
    this.sharedConfig.user.personal_details.country_of_residence_description = corDesc;
    this.sharedConfig.user.personal_details.casa_nationality_code = nationalityCode;
    this.sharedConfig.user.personal_details.casa_nationality_description = nationalityDesc;
    this.sharedConfig.user.personal_details.casa_source_of_income_code = sourceOfIncomeCode;
    this.sharedConfig.user.personal_details.casa_source_of_income_description = sourceOfIncomeDesc;
    this.sharedConfig.user.personal_details.casa_employment_status_code = empCode;
    this.sharedConfig.user.personal_details.casa_employment_status_description = empDesc;
    this.sharedConfig.user.personal_details.casa_occupation_code = occupation_code;
    this.sharedConfig.user.personal_details.casa_occupation_description = occupationDesc;

   this.apiUserService.updatePersonalDetails(this.sharedConfig.user.personal_details).pipe(take(1)).subscribe();
  }

  async setScheduleLinkedAssets(activeSchedules: Array<ISchedule>): Promise<Array<ISchedule>> {
    const insuranceTypes: Array<{ type: AssetType; key: keyof IScheduleInsuredAssets }> = [
      { type: AssetType.Contents, key: 'contents' },
      { type: AssetType.Vehicles, key: 'vehicles' },
      { type: AssetType.Motorcycles, key: 'motorcycles' },
      { type: AssetType.Caravans, key: 'caravans' },
      { type: AssetType.Buildings, key: 'buildings' },
      { type: AssetType.SpecifiedItems, key: 'specified_items' },
      { type: AssetType.AllRisks, key: 'all_risks' },
      { type: AssetType.Vaps, key: 'vaps' },
    ];
    const insuranceItemsPromises = insuranceTypes.map((it) => this.apiPolicyService.getInsuredItems<IInsuredAsset>(activeSchedules, it.type));
    const insuranceItemsResults = await Promise.all(insuranceItemsPromises);
    const insuranceItemsMap = insuranceTypes.reduce((acc, it, index) => {
      acc[it.type] = insuranceItemsResults[index];
      return acc;
    }, {} as { [type in AssetType]?: IInsuredAsset[] });
    activeSchedules.forEach((schedule) => {
      insuranceTypes.forEach((it) => {
        const insuredItems = insuranceItemsMap[it.type];
        if (insuredItems) {
          (schedule.insured_assets[it.key] as IInsuredAsset[]) = insuredItems.filter((item) => item.schedule.id === schedule.id);
        }
      });
    });
    return activeSchedules;
  }
}
