import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { BUSNUserService } from '@takectrl-multiapp/svcs-core-business';
import { SharedConfig } from '@takectrl-multiapp/svcs-share-config';
declare const dataLayer: any[];

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  @Output() cancel: EventEmitter<unknown> = new EventEmitter<unknown>();
  oldPass: string;
  newPass: string;
  confirmPass: string;
  public disableButton = true;
  public errorMessage: string;

  constructor(private readonly thirdpartyService: BUSNThirdPartyService, public userService: BUSNUserService, public sharedConfig: SharedConfig) {}

  ngOnInit(): void {
    dataLayer.push({
      event: 'page_show',
      screenPath: 'change-password',
      screenName: 'change-password',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }

  checkPasswords(): boolean {
    if (this.newPass === this.confirmPass) {
      return true;
    } else {
      return false;
    }
  }

  changeDetails() {
    this.userService.changePassword(this.oldPass, this.newPass);
  }

  cancelScreen() {
    this.cancel.emit();
  }
}
