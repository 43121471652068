<div class="wrapper limit-width-on-desktop-float" *ngIf="!showDeductionDay">
  <div class="header">
    <h2 class="question-heading">Before you accept the quote we need some details.</h2>
  </div>

  <div class="content">
    <div class="update-email">
      <div class="text-wrapper">
        <p><b>Confirm email address</b></p>
        <p>
          <small>{{ sharedConfig.user.personal_details.email }}</small>
        </p>
      </div>
      <div class="update-btn">
        <a (click)="openUpdateEmailPopup()"><b>Update</b></a>
      </div>
    </div>

    <ion-item mode="ios" (click)="openCalendar()">
      <ion-label position="stacked">Cover start date</ion-label>
      <ion-input type="text" readonly="true" placeholder="YYYY-MM-DD" [(ngModel)]="cover_start_date"></ion-input>
      <ion-icon name="calendar-outline" slot="end" class="calendar-icon"></ion-icon>
      <!-- <ion-label *ngIf="sharedConfig.isMobileApp" position="floating">Cover start date</ion-label> -->
      <!-- <ion-datetime
        *ngIf="sharedConfig.isMobileApp"
        #datePicker
        (ionChange)="setCoverStartDate(datePicker.value)"
        displayFormat="YYYY/MM/DD"
        min="{{ minDate }}"
        max="{{ maxDate }}"
        [(ngModel)]="cover_start_date"
      ></ion-datetime>
      <ion-input
        *ngIf="!sharedConfig.isMobileApp"
        type="date"
        #dateDesktopPicker
        (ionChange)="setCoverStartDate(dateDesktopPicker.value)"
        displayFormat="YYYY/MM/DD"
        min="{{ minDate }}"
        max="{{ maxDate }}"
        [(ngModel)]="cover_start_date"
      ></ion-input> -->
    </ion-item>
    <h2 class="question-heading form-header">Enter banking details</h2>
    <small *ngIf="insurer_id !== 'KINGPRICE' && insurer_id !== 'MIWAY'"
      >Please note: Your insurer has appointed Brolink to collect your debit order.</small
    >
    <div class="kp-banner" *ngIf="insurer_id === 'KINGPRICE'">
      <ion-grid>
        <ion-row>
          <ion-col size="1">
            <ion-icon src="assets/icon/genetic-icons/lightbulb.svg" class="icon-fill-secondary"></ion-icon>
          </ion-col>
          <ion-col>
            <p><b>Please note: </b>King Price will call you to setup up your banking details.</p>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <div class="form" *ngIf="insurer_id !== 'KINGPRICE'">
      <ion-item mode="ios">
        <ion-label position="floating">Account number</ion-label>
        <ion-input type="number" class="bold-text" value="{{ banking_details.account_number }}" (input)="accountNumberValueChange($event)">
        </ion-input>
      </ion-item>
      <ion-item mode="ios" (click)="openBankSelectPopup()">
        <ion-label position="floating">Bank</ion-label>
        <ion-input class="bold-text" readonly="true" [(ngModel)]="banking_details.financial_institution"> </ion-input>
        <ion-icon slot="end" class="end-icon" name="chevron-down"></ion-icon>
      </ion-item>
      <ion-item mode="ios">
        <ion-label position="floating">Branch & code</ion-label>
        <ion-input readonly="true" class="bold-text" [(ngModel)]="branchAndCode"></ion-input>
        <ion-icon slot="end" class="end-icon" name="chevron-down"></ion-icon>
      </ion-item>
      <ion-item (click)="openDeductionDay()" mode="ios">
        <ion-label position="floating">Deduction day</ion-label>
        <ion-input class="bold-text" readonly="true" [(ngModel)]="collection_day"></ion-input>
        <ion-icon slot="end" class="end-icon" name="chevron-down"></ion-icon>
      </ion-item>
    </div>

    <!-- UPDATE EMAIL -->
    <div class="component-wrapper" *ngIf="showUpdateEmailPopup">
      <div class="component-padding limit-width-on-desktop-float">
        <ion-item lines="none" mode="ios">
          <ion-label position="floating">Current email address</ion-label>
          <ion-input class="bold-text" [value]="sharedConfig.user.personal_details.email" readonly="true"></ion-input>
        </ion-item>
        <ion-item mode="ios">
          <ion-label position="floating">New email address</ion-label>
          <ion-input type="text" class="bold-text" [(ngModel)]="newEmail"></ion-input>
        </ion-item>
      </div>
      <div class="component-button-wrapper">
        <ion-button mode="ios" class="primary-button limit-width-desktop-on-button" expand="block" [disabled]="!newEmail" (click)="updateEmail()"
          >Update
        </ion-button>
        <ion-button mode="ios" class="secondary-button limit-width-desktop-on-button" expand="block" (click)="cancelPopupEmail()">Cancel</ion-button>
      </div>
    </div>

    <!-- SELECTING BANK -->
    <div class="component-wrapper" *ngIf="bankSelectPopup">
      <div class="component-padding limit-width-on-desktop-float">
        <ion-item mode="ios">
          <ion-label position="floating">Search bank</ion-label>
          <ion-input type="text" class="text-bold" [(ngModel)]="bankSearchText" (ngModelChange)="bankSearchValueChange($event)"></ion-input>
        </ion-item>

        <div class="banks-wrapper">
          <div *ngIf="bankSelectPopup && showPopular">
            <p><b>Popular</b></p>
            <div class="bank-select" (click)="selectBank(bank)" mode="md" *ngFor="let bank of banksPopular">
              {{ bank.display }}
            </div>
          </div>
          <div *ngIf="bankSelectPopup && banksFiltered && banksFiltered.length > 0">
            <p><b *ngIf="!bankSearchText">All</b></p>
            <div class="bank-select" (click)="selectBank(bank)" *ngFor="let bank of banksFiltered">
              {{ bank.display }}
            </div>
          </div>
          <div *ngIf="!banksFiltered || banksFiltered.length <= 0" class="nothing-found-wrapper">
            <h2 class="h2-bold">Hmmm...</h2>
            <p>We couldn’t find a bank matching your description. Please check your spelling.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="footer-button-wrapper">
  <div class="fade"></div>
  <div class="footer-button-background">
    <ion-button
      *ngIf="insurer_id !== 'KINGPRICE'"
      mode="ios"
      class="footer-button limit-width-desktop-on-button"
      (click)="continue()"
      [disabled]="
        !this.cover_start_date ||
        !this.banking_details.account_number ||
        !this.banking_details.financial_institution ||
        !this.banking_details.branch_name ||
        !this.banking_details.branch_code ||
        !this.banking_details.collection_day
      "
      expand="block"
      >Next
    </ion-button>
    <ion-button
      *ngIf="insurer_id === 'KINGPRICE'"
      mode="ios"
      class="footer-button limit-width-desktop-on-button"
      (click)="continue()"
      [disabled]="!this.cover_start_date"
      expand="block"
      >Next
    </ion-button>
  </div>
</div>
<!-- DEDUCTION DAY -->
<div class="backdrop" *ngIf="showDeductionDay"></div>
<div class="popup-dates limit-width-on-desktop-float" *ngIf="showDeductionDay">
  <div class="text-center">
    <h2 class="h2-bold">Select deduction day</h2>
  </div>
  <div class="dates">
    <div class="date-wrapper" *ngFor="let collectionDate of collectionDates" (click)="onCollectionDaySelected(collectionDate)">
      {{ collectionDate.day_ordinal_display_name }}
    </div>
  </div>
</div>

<custom-popup *ngIf="showCustomPopup">
  <div class="heading">
    <h2 class="h2-bold">Enter password</h2>
  </div>
  <div class="sub-header">
    <p>Please enter your password to confirm your new email address : {{ newEmail }}</p>
  </div>
  <div class="input-wrapper">
    <ion-item mode="ios">
      <ion-label position="floating">Password</ion-label>
      <ion-input type="password" [(ngModel)]="passwordField"></ion-input>
    </ion-item>
  </div>
  <div class="buttons-wrapper">
    <ion-button mode="ios" class="primary-button" (click)="confirmEmailChange()"> Confirm </ion-button>
    <ion-button mode="ios" class="secondary-button" (click)="dismissCustomPopup()"> Cancel </ion-button>
  </div>
</custom-popup>

<popup-with-dismiss-button *ngIf="sharedConfig.showPopupWithDismissButton"></popup-with-dismiss-button>
<popup-calendar
  *ngIf="sharedConfig.showCalendarPopup"
  [heading]="'Cover start date'"
  (setDate)="setCoverStartDate($event)"
  [dateFormat]="'date'"
  [maxDate]="maxDate"
  [minDate]="minDate"
  [ngModal]="cover_start_date"
></popup-calendar>

<!-- TODO ALL OTHER POPUPS SHOULD BE REPLACED BY THIS GENERIC POPUP -->
<popup-dialog id="quote.banking.component" *ngIf="sharedConfig.popupDialogShow"></popup-dialog>
