<div class="wrapper-no-height limit-width-on-desktop-float">
  <div class="top-section">
    <h2 class="h2-bold">Welcome to our dealership</h2>
  </div>
  <div class="content-section">
    <div class="web-section">
      <p>You can browse our selection of vehicles below.</p>
      <p class="web-url"><a href="{{sharedConfig.appSettings.motor_group_url}}" target="_blank">Visit our website</a></p>
    </div>
    <div class="search-dealer-section">
      <p>Alternatively you can call one of our dealerships directly to speak to one of our friendly staff.</p>
      <ion-item class="question-spacing" mode="ios">
        <ion-label position="floating">Dealership</ion-label>
        <ion-input readonly="true" [(ngModel)]="selectedDealership" (click)="openDealersPopup()"></ion-input>
      </ion-item>
      <div class="results" *ngIf="dealershipsNumbers">
        <div *ngFor="let numbers of dealershipsNumbers">
          <ion-item class="clickable" lines="none" (click)="callDealership(numbers.value, numbers.description)">
            <ion-text slot="start">{{ numbers.description }}</ion-text>
            <ion-icon slot="end" class="icon-stroke-primary" src="assets/icon/genetic-icons/call-icon.svg"></ion-icon>
          </ion-item>
          <hr />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="backdrop" *ngIf="showDealerships"></div>
<div class="popup-standard limit-width-on-desktop-float" *ngIf="showDealerships">
  <div class="text-center">
    <h2 class="h2-bold">Select Dealership</h2>
  </div>
  <div class="dates">
    <div class="date-wrapper clickable" *ngFor="let dealership of dealerships" (click)="selectedDealer(dealership)">
      {{ dealership }}
    </div>
  </div>
  <ion-button class="secondary-button" (click)="dismissPopup()">Close</ion-button>
</div>

<popup-dialog   id="motor.group.component" *ngIf="sharedConfig.popupDialogShow"></popup-dialog>
