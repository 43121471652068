import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IQuestion, SharedConfig } from '@takectrl-multiapp/svcs-share-config';

@Component({
  selector: 'input-text-multi-lines',
  templateUrl: './input-text-multi-lines.component.html',
  styleUrls: ['./input-text-multi-lines.component.scss'],
})
export class InputTextMultiLinesComponent implements OnInit {
  @Input() question: IQuestion;
  @Input() showHeading: boolean;
  @Input() error: string;
  @Input() readOnly: boolean;
  @Output() answerQuestion: EventEmitter<IQuestion> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {
    for (const underwritingAnswer of this.question.underwriting_answers) {
      if (underwritingAnswer.object_answer === undefined || underwritingAnswer.object_answer === null) {
        underwritingAnswer.object_answer = 0;
      }
    }

    // this is to emit the default 0 value as an answer to enable the next button
    if (SharedConfig.selectedPage === 'quote-questions') {
      this.answerQuestion.emit(this.question);
    }
  }

  answerValue(e: Event, index) {
    this.question.underwriting_answers[index].object_answer = e.target['value'].trim();
    const answerIsValidNumber = /^\d+$/.test(this.question.underwriting_answers[index].object_answer);
    if (answerIsValidNumber) {
      this.error = null;
      if (SharedConfig.selectedPage === 'quote-questions') {
        this.answerQuestion.emit(this.question);
      }
    } else {
      this.error = 'Please provide a valid number (no decimals or negative numbers allowed)';
    }
  }
}
