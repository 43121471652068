import { Injectable } from '@angular/core';
import { ThirdPartyEventType } from '../../types/event.type';
import Intercom, { show, hide, showMessages, showNewMessage, trackEvent, update, onUnreadCountChange } from '@intercom/messenger-js-sdk';

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  constructor() {}

  init(userEmail: string, userFullName: string, firebaseKey: string, intercom_id: string):void {
    Intercom({
      app_id: intercom_id,
      name: userFullName,
      email: userEmail,
      user_id: firebaseKey,
    });
  }

  displayLauncher() {
    show();
  }

  closeIntercom() {
    hide();
  }

  displayMessenger() {
    showMessages();
  }

  sendMessage(message: string) {
    showNewMessage(message);
  }

  logEvent(eventType: ThirdPartyEventType, ...args: any[]) {
    trackEvent(eventType, ...args);
  }

  async getUnreadCount(): Promise<number> {
    return new Promise((resolve) => {
      onUnreadCountChange((unreadCount: number) => {
        resolve(unreadCount);
      });
    });
  }

  async checkMessages() {
    const unreadCount = await this.getUnreadCount();
    // Handle the unread count as needed
    console.log(`Unread message count: ${unreadCount}`);
  }
}
