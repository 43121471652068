import { Injectable } from '@angular/core';
import { IFirebaseLogin, SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import { FirebaseAuthService } from '@takectrl-multiapp/svcs-firebase';
import firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/compat/database';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  constructor(public firebaseAuthService: FirebaseAuthService, public sharedConfig: SharedConfig, public angularFireDatabase: AngularFireDatabase) {}

  async signInWithEmailAndPassword(email: string, password: string): Promise<IFirebaseLogin> {
    return await this.firebaseAuthService.signInWithEmailAndPassword(email, password);
  }

  async signUpWithEmailAndPassword(email: string, password: string): Promise<IFirebaseLogin> {
    return this.firebaseAuthService.signUpWithEmailAndPassword(email, password).then((data) => {
      return data;
    });
  }

  sendSignInLinkToEmail(phoneNumber: string, applicationVerifier: any) {
    this.firebaseAuthService.sendSignInLinkToEmail(phoneNumber, applicationVerifier);
  }

  signInWithPhoneNumber(phoneNumber: string, platformType: string): Promise<firebase.auth.ConfirmationResult> {
    return this.firebaseAuthService.signInWithPhoneNumber(phoneNumber, platformType);
  }

  async isSignInWithEmailLink(emailLink?: string): Promise<boolean> {
    return this.firebaseAuthService.isSignInWithEmailLink(emailLink);
  }

  async sendSMSOTP(emailLink?: string): Promise<boolean> {
    return this.firebaseAuthService.isSignInWithEmailLink(emailLink);
  }

  signInWithEmailLink(email: string, emailLink?: string): Promise<IFirebaseLogin> {
    return this.firebaseAuthService.signInWithEmailLink(email, emailLink);
  }

  async signOut(): Promise<void> {
    SharedConfig.clearSharedConfig();
    return this.firebaseAuthService.signOut();
  }

  async changeFirebaseEmailAddress(oldEmail: string, newEmail: string, password: string) {
    await this.firebaseAuthService.updateFirebaseEmail(oldEmail, newEmail, password);
  }
  

  changeFirebasePassword(oldPassword: string, newPassword: string) {
    this.firebaseAuthService.updateFirebasePassword(this.sharedConfig.user.personal_details.email, oldPassword, newPassword);
  }

  resetPassword(userEmail: string) {
    return this.firebaseAuthService.resetPassword(userEmail);
  }

  currentUser(): Promise<firebase.User> {
    return this.firebaseAuthService.currentUser();
  }

  currentUserObservable(): Observable<firebase.User> {
    return this.firebaseAuthService.user();
  }

  async databaseSet(child: string, object: any): Promise<void> {
    await this.angularFireDatabase.database.ref().child(child).set({ object });
  }

  async databaseGetObject(child: string): Promise<void> {
    const data = await this.angularFireDatabase.database.ref(child).once('value');

    return this.snapshotToArray(data);
  }

  databaseSubscribe(child: string, callback: () => Promise<void>) {
    this.angularFireDatabase.database.ref(child).on('value', async (_snapshot) => {
      await callback();
    });
  }
  
  snapshotToArray(snapshot: any): any {
    const dataArray: Array<any> = [];
    if (snapshot.val()) {
      for (const key of Object.keys(snapshot.val())) {
        dataArray.push(snapshot.val()[key]);
      }
      return dataArray;
    } else {
      return [];
    }
  }
}
