import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { BUSNQuestionService } from '@takectrl-multiapp/svcs-core-business';
import { AssetType, QuoteType, SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import { QuestionComponent } from '../../components/question/question.component';
declare const dataLayer: any[];

@Component({
  selector: 'question-personal-details',
  templateUrl: './question-personal-details.component.html',
  styleUrls: ['./question-personal-details.component.scss'],
})
export class QuestionPersonalDetailsComponent implements OnInit {
  @ViewChild(QuestionComponent) questionComponent: QuestionComponent;
  @Input() quoteType: QuoteType;
  @Output() showBackButton: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() navigateQuestionSummary: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() navigateHomeQuote: EventEmitter<void> = new EventEmitter<void>();
  @Output() navigateHomeEndorsement: EventEmitter<void> = new EventEmitter<void>();
  @Output() navigateWalletPage: EventEmitter<void> = new EventEmitter<void>();
  constructor(public thirdpartyService: BUSNThirdPartyService, public sharedConfig: SharedConfig, public busnQuestionService: BUSNQuestionService) {}

  ngOnInit() {
    SharedConfig.selectedAssetUnderwritingQuestions = this.busnQuestionService.getPersonalOrAssetSpecificQuestions(
      SharedConfig.user,
      JSON.parse(JSON.stringify(SharedConfig.underwritingQuestionsAll)),
      AssetType.UserPersonalDetails
    );

    dataLayer.push({
      event: 'page_show',
      screenPath: 'question-personal-details',
      screenName: 'question-personal-details',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }

  prevQuestion() {
    this.questionComponent.previousQuestion();
  }

  compShowBackButton(value: boolean) {
    this.showBackButton.emit(value);
  }

  compNavigateQuestionSummary() {
    this.navigateQuestionSummary.emit();
  }

  compNavigateWalletPage() {
    this.navigateWalletPage.emit();
  }

  compNavigateHomeQuote() {
    this.navigateHomeQuote.emit();
  }
}
