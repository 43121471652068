<div class="loader-backdrop"></div>
<div class="center-outer">
  <div class="loader-wrapper">
    <div role="status">
      <lottie-player autoplay mode="normal" src="{{ animationSrc }}"> </lottie-player>
    </div>
  </div>
  <div class="loader-message">
    <h2 class="h2-bold">Your account has been created!</h2>
    <p>{{ sub_heading }}</p>
  </div>
  <div class="bottom">
    <div class="info limit-width-on-desktop">
      <div class="bottom-text">
        <p>
          <ion-icon src="assets/icon/genetic-icons/lightbulb.svg" class="icon-fill-secondary"></ion-icon>
          <small>Save and exit at any point and come back to complete your quote.</small>
        </p>
      </div>
    </div>
    <div class="limit-width-on-desktop">
      <ion-button mode="ios" (click)="getStarted()" class="primary-button">Get started</ion-button>
    </div>
  </div>
</div>
