<div class="white-bg">
  <div class="wrapper limit-width-on-desktop-float">
    <div class="bottom-section">
      <h2 class="h2-bold">Emergency?</h2>
      <p>You'll be able to get emergency assistance by clicking here once you become a client. So give us a try and request a
      quote.</p>
      <quote-status-button></quote-status-button>
    </div>
  </div>
  <div class="img-bottom limit-width-on-desktop-float">
    <img src="assets/images/placeholder/emergency.png" alt="" />
  </div>
</div>