import { Injectable } from '@angular/core';
import { CommunicationService } from '@takectrl-multiapp/svcs-core';
import { SharedConfig, IEmergencyCategories, AssetType, IEmergencyMailPayload } from '@takectrl-multiapp/svcs-share-config';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { Platform } from '@ionic/angular';
import { APIQuoteScheduleFirebaseService } from '@takectrl-multiapp/svcs-ctrl-api';
import { firstValueFrom } from 'rxjs';
import { BUSNCommunicationService } from './busn-communication.service';

@Injectable({
  providedIn: 'root',
})
export class BUSNEmergencyService {
  public emergencyAssets = new Array<unknown>();
  public selectedAssetsArray = new Array<IEmergencyCategories>();
  constructor(
    private readonly busnThirdPartyService: BUSNThirdPartyService,
    private readonly busnCommunicationService: BUSNCommunicationService,
    public sharedConfig: SharedConfig,
    public communicationService: CommunicationService,
    private readonly platform: Platform,
    private readonly apiQuoteScheduleFirebaseService: APIQuoteScheduleFirebaseService
  ) {}

  async emergencyCategories(insurerId: string): Promise<IEmergencyCategories> {
    return (await firstValueFrom(this.apiQuoteScheduleFirebaseService.emergencyCategories(insurerId))).results;
  }

  submitEmergencyPhoneCall(emergencyPhoneNumber: string, assetsOfEmergency: Array<IEmergencyCategories>, insurer_id: string, policy_number: string) {
    const date: string = new Date(Date.now()).toLocaleString();
    const logsPayload = {
      name: this.sharedConfig.user.personal_details.name,
      brolink_id:
        this.sharedConfig.user.providers.peerless_client_id && insurer_id === 'PEERLESS'
          ? this.sharedConfig.user.providers.peerless_client_id
          : '',
      service: assetsOfEmergency[0].description,
      user_id: SharedConfig.firebaseUserKey,
      timestamp: date,
    };

     const emailPayload: IEmergencyMailPayload = {
      email: this.sharedConfig.user.personal_details.email,
      brolink_id: logsPayload.brolink_id,
      service: logsPayload.service,
      user_id: SharedConfig.user.id.toString(),
      insurer: insurer_id,
      policy_id: policy_number,
      broker_email: this.sharedConfig.user.broker.email,
      timestamp: logsPayload.timestamp
    };
    console.log(emailPayload);
    this.busnThirdPartyService.logEvent('action_assistance_call_us', logsPayload);
    this.busnCommunicationService.sendBrokerEmail(emailPayload);
    if (this.platform.is('mobileweb')) {
      window.open(`tel:${emergencyPhoneNumber}`, '_blank');
    } else if (this.platform.is('desktop')) {
      // Do nothing
    } else {
      this.communicationService.makePhoneCall(emergencyPhoneNumber);
    }
    this.emergencyAssets = [];
  }

  createAssetList(assetItems: any) {
    assetItems.forEach((assetItem: { type: string; details: { make: any; model: any; registration_nr: any; risk_address_line1: any; risk_address_line2: any; description: any; }; }) => {
      if (assetItem.type === AssetType.Vehicles) {
        this.emergencyAssets.push(`Vehicle: ${assetItem.details.make}, ${assetItem.details.model} - ${assetItem.details.registration_nr}`);
      } else if (assetItem.type === AssetType.Buildings) {
        this.emergencyAssets.push(`Building: ${assetItem.details.risk_address_line1}, ${assetItem.details.risk_address_line2}`);
      } else if (assetItem.type === AssetType.Contents) {
        this.emergencyAssets.push(`Content: ${assetItem.details.risk_address_line1}, ${assetItem.details.risk_address_line2}`);
      } else if (assetItem.type === 'specified_item') {
        this.emergencyAssets.push(`specified_item: ${assetItem.details.description}`);
      } else if (assetItem.type === AssetType.AllRisks) {
        this.emergencyAssets.push(`all_risks: ${assetItem.details.description}`);
      } else if (assetItem.type === AssetType.Motorcycles) {
        this.emergencyAssets.push(`Motorcycle: ${assetItem.details.make}, ${assetItem.details.model} - ${assetItem.details.registration_nr}`);
      } else if (assetItem.type === AssetType.Caravans) {
        this.emergencyAssets.push(`Caravan: ${assetItem.details.make}, ${assetItem.details.model} - ${assetItem.details.registration_nr}`);
      }
    });
    return this.emergencyAssets;
  }

  clearAll(asset: any[]) {
    asset.forEach((item: { selected: boolean; }) => {
      item.selected = false;
    });
    this.selectedAssetsArray = [];
  }
}
