import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { SharedConfig, IFirebaseLogin, IFirebaseRegister, SharedEncryptionService, SharedDialogService } from '@takectrl-multiapp/svcs-share-config';
import { AuthException } from '../interfaces/auth.interface';
import { NavController, LoadingController, AlertController } from '@ionic/angular';
import { APISettingsService } from '@takectrl-multiapp/svcs-ctrl-api';
import firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FirebaseAuthService {
  constructor(
    public angularFire: AngularFireAuth,
    public sharedEncryptionService: SharedEncryptionService,
    public navCtrl: NavController,
    public apiSettingsService: APISettingsService,
    public sharedConfig: SharedConfig,
    public loadingCtrl: LoadingController,
    public alertController: AlertController,
    public sharedDialogService: SharedDialogService,
    private readonly zone: NgZone
  ) {}

  async signInWithEmailAndPassword(email: string, password: string): Promise<IFirebaseLogin> {
    // .setPersistence('local) to keep the user logged in at all times.
    // .setPersistence('session') to only keep the session active while the browser is open.
    // .setPersistence('none) => To keep the session in memory and clear it if the user reloads.
    email = email.toLowerCase();
    return this.angularFire.setPersistence('local').then(async (_) => {
      return this.angularFire
        .signInWithEmailAndPassword(email, password)
        .then((user_credential) => {
          SharedConfig.firebaseUser = user_credential.user;
          SharedConfig.firebaseUserKey = user_credential.user.uid;
          this.sharedConfig.setFirebaseUserEmail(user_credential.user.email);
          SharedConfig.firebaseUserPswrd = password;
          return {
            user_credential: user_credential,
            message: 'Logged in',
            success: true,
          };
        })
        .catch((err) => {
          const exception: AuthException = err;
          console.log(exception);
          let returnMessage = '';
          switch (exception.code) {
            case 'auth/user-not-found':
              returnMessage = 'No account found, please register';
              break;

            case 'auth/invalid-email':
              returnMessage = 'Invalid email entered';
              break;

            case 'auth/invalid-password':
              returnMessage = 'Incorrect password entered';
              break;

            case 'auth/wrong-password':
              returnMessage = 'Incorrect password entered';
              break;

            default:
              returnMessage = 'Something went wrong, please try again';
              break;
          }
          return {
            message: returnMessage,
            success: false,
          };
        });
    });
  }

  async signUpWithEmailAndPassword(email: string, password: string): Promise<IFirebaseRegister> {
    // .setPersistence('local) to keep the user logged in at all times.
    // .setPersistence('session') to only keep the session active while the browser is open.
    // .setPersistence('none) => To keep the session in memory and clear it if the user reloads.
    return this.angularFire.setPersistence('local').then(async (_) => {
      return this.angularFire
        .createUserWithEmailAndPassword(email, password)
        .then((user_credential) => {
          SharedConfig.firebaseUser = user_credential.user;
          SharedConfig.firebaseUserKey = user_credential.user.uid;
          this.sharedConfig.setFirebaseUserEmail(user_credential.user.email);
          return {
            user_credential: user_credential,
            message: 'Registered',
            success: true,
          };
        })
        .catch((err) => {
          const exception: AuthException = err;
          console.log(exception);
          let returnMessage = '';
          switch (exception.code) {
            case 'auth/invalid-email':
              returnMessage = 'Invalid email entered';
              break;

            case 'auth/invalid-password':
              returnMessage = 'Incorrect password entered';
              break;

            case 'auth/email-already-in-use':
              returnMessage = 'The email you entered is already in use';
              break;

            default:
              returnMessage = 'Something went wrong, please try again';
              break;
          }
          return {
            message: returnMessage,
            success: false,
          };
        });
    });
  }

  currentUser(): Promise<firebase.User> {
    return this.angularFire.currentUser;
  }

  user(): Observable<firebase.User> {
    return this.angularFire.user;
  }

  async isSignInWithEmailLink(emailLink?: string): Promise<boolean> {
    return await this.angularFire.isSignInWithEmailLink(emailLink);
  }

  async signInWithEmailLink(email: string, emailLink?: string): Promise<IFirebaseLogin> {
    return this.angularFire.isSignInWithEmailLink(emailLink).then((isSignInWithEmailLink) => {
      if (isSignInWithEmailLink) {
        this.angularFire
          .signInWithEmailLink(email, emailLink)
          .then((user_credential) => {
            SharedConfig.firebaseUser = user_credential.user;
            SharedConfig.firebaseUserKey = user_credential.user.uid;
            this.sharedConfig.setFirebaseUserEmail (user_credential.user.email);
            return {
              user_credential: user_credential,
              message: 'Logged in',
              success: true,
            };
          })
          .catch((err) => {
            const exception: AuthException = err;
            console.log(exception);
            let returnMessage = '';
            switch (exception.code) {
              case 'auth/user-not-found':
                returnMessage = 'No account found, please register';
                break;

              case 'auth/invalid-email':
                returnMessage = 'Invalid email entered';
                break;

              case 'auth/invalid-password':
                returnMessage = 'Incorrect password entered';
                break;

              default:
                returnMessage = 'Something went wrong, please try again';
                break;
            }
            return {
              message: returnMessage,
              success: false,
            };
          });
      } else {
        return {
          message: 'Not Signin Link',
          success: false,
        };
      }
    });
  }

  async sendSignInLinkToEmail(email: string, platformType: string) {
    if (platformType === 'web') {
      this.angularFire.sendSignInLinkToEmail(email, {
        url: 'http://localhost:4200',
        handleCodeInApp: true,
      });
    } else {
      this.angularFire.sendSignInLinkToEmail(email, {
        url: 'http://localhost:4200',
        iOS: {
          bundleId: 'za.co.takectrl.app',
        },
        handleCodeInApp: true,
      });
    }
  }

  signInWithPhoneNumber(phoneNumber: string, applicationVerifier: any): Promise<firebase.auth.ConfirmationResult> {
    return this.angularFire.signInWithPhoneNumber(phoneNumber, applicationVerifier);
  }

  signOut(): Promise<void> {
    return this.angularFire.signOut();
  }


  // TODO : MOVE SIGN IN TO OUTSIDE THIS FUNCTION - > 1FUCN PER FUCNTION
  async updateFirebaseEmail(oldEmail: string, newEmail: string, password: string) {
    this.sharedConfig.setShowCustomLoader(true);
    this.signInWithEmailAndPassword(oldEmail, password)
      .then(async () => {
        (await this.angularFire.currentUser)
          .updateEmail(newEmail)
          .then(async () => {
            this.angularFire
              .signOut()
              .then(() => {
                this.zone.run(() => {
                  this.navCtrl.navigateRoot(['public-landing']);
                  this.sharedDialogService.showPopupDialog({
                    header: 'Success',
                    subHeader:
                      'Your email has been updated. For security reasons you have now been logged out. Please log in with your updated email.',
                    buttonOneText: 'OK',
                    buttonOneCallback: this.sharedDialogService.hidePopupDialog,
                  });
                });
                this.sharedConfig.setShowCustomLoader(false)
              })
              .catch((error) => {
                console.error(error);
                this.sharedDialogService.showPopupDialog({
                  header: 'Something went wrong!',
                  subHeader: error.message,
                  buttonOneText: 'OK',
                  buttonOneCallback: this.sharedDialogService.hidePopupDialog,
                });
              });

            this.sharedConfig.setShowCustomLoader(false)
          })
          .catch(async (error) => {
            console.log('user email NOT updated!');
            console.error(error);
            this.sharedDialogService.showPopupDialog({
              header: 'Something went wrong!',
              subHeader: error.message,
              buttonOneText: 'OK',
              buttonOneCallback: this.sharedDialogService.hidePopupDialog,
            });

            this.sharedConfig.setShowCustomLoader(false)
          });
      })
      .catch(async (error) => {
        this.sharedDialogService.showPopupDialog({
          header: 'Something went wrong!',
          subHeader: error.message,
          buttonOneText: 'OK',
          buttonOneCallback: this.sharedDialogService.hidePopupDialog,
        });
        this.sharedConfig.setShowCustomLoader(false)
      });
  }

  async updateFirebasePassword(userEmail: string, oldPassword: string, newPassword: string) {
    this.sharedConfig.setShowCustomLoader(true)
    this.angularFire
      .signInWithEmailAndPassword(userEmail, oldPassword)
      .then(async () => {
        (await this.angularFire.currentUser)
          .updatePassword(newPassword)
          .then(async () => {
            this.sharedDialogService.showPopupDialog({
              header: 'Success!',
              subHeader: 'Your password has been updated. You will now be logged out for security reasons. Please log in with your updated password.',
              buttonOneText: 'OK',
              buttonOneCallback: this.sharedDialogService.hidePopupDialog,
              navRoute: 'public-login',
            });
            this.sharedConfig.setShowCustomLoader(false)
          })
          .catch(async (error) => {
            this.sharedDialogService.showPopupDialog({
              header: 'Something went wrong!',
              subHeader: error.message,
              buttonOneText: 'OK',
              buttonOneCallback: this.sharedDialogService.hidePopupDialog,
            });

            this.sharedConfig.setShowCustomLoader(false)
          });
      })
      .catch(async (error) => {
        console.error(error);
        if (error.code === 'auth/wrong-password') {
          this.sharedDialogService.showPopupDialog({
            header: 'Something went wrong!',
            subHeader: 'Existing password is incorrect.',
            buttonOneText: 'OK',
            buttonOneCallback: this.sharedDialogService.hidePopupDialog,
          });
          this.sharedConfig.setShowCustomLoader(false)
        }
      });
  }

  async resetPassword(userEmail: string): Promise<unknown> {
    return this.angularFire.sendPasswordResetEmail(userEmail);
  }
}
