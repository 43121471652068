<!-- <div class="wrapper" *ngFor="let question of questionsArray"> -->
<div
  class="progress-wrapper"
  *ngIf="
    !sharedConfig.hideProgressBar &&
    !sharedConfig.addingClaim &&
    sharedConfig.inputTextAddressShowScreen !== AddressSelectorScreen.StreetAddress &&
    sharedConfig.inputTextAddressShowScreen !== AddressSelectorScreen.PostalCode
  "
>
  <div class="progress-header limit-width-on-desktop-float">
    <h3>{{ sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]?.heading }}</h3>
  </div>
  <div class="container limit-width-on-desktop-float">
    <div
      class="progress-bar-container"
      *ngFor="let grouped of questionGroup; let i = index"
      [ngStyle]="{ width: grouped.width_of_progress_bar + '%' }"
    >
      <div class="progress-bar" [ngStyle]="{ width: 'calc(' + (grouped.total_answered / grouped.size) * 100 + '% )' }"></div>
    </div>
  </div>
</div>
<div class="bg-gray">
  <div class="wrapper" [ngClass]="{
      'full-comp': sharedConfig.addingClaim,
      'top-rm': sharedConfig.inputTextAddressShowScreen === AddressSelectorScreen.StreetAddress || sharedConfig.inputTextAddressShowScreen === AddressSelectorScreen.PostalCode
    }">
    <div class="header limit-width-on-desktop-float side-padding"
      *ngIf="sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]?.type !== QuestionType.claimList">
      <h2 class="question-heading">{{ sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]?.sub_heading }}
      </h2>
      <p>{{ sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]?.sub_paragraph }}</p>
    </div>
    <div class="input-section limit-width-on-desktop-float side-padding"
      [ngSwitch]="sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]?.type">
      <div *ngIf="
          sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]?.underwriting_answers[0]?.object_name === AssetType.Buildings ||
          sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]?.underwriting_answers[0]?.object_name === AssetType.Contents ||
          sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]?.underwriting_answers[0]?.object_name === AssetType.Vehicles ||
          sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]?.underwriting_answers[0]?.object_name === AssetType.Caravans ||
          sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]?.underwriting_answers[0]?.object_name === AssetType.Motorcycles
        ">
        <div *ngSwitchCase="QuestionType.address">
          <ion-item *ngFor="let address of addressesToCopyFrom_userOrContentsOrBuildings" lines="none"
            (click)="selectAddressCopyQuestionsOver(address)">
            <ion-icon slot="start" class="checkbox-square" name="square-outline"
              *ngIf="address.risk_address_line1 !== activeAddress"></ion-icon>
            <ion-icon slot="start" class="checkbox-active" name="checkbox"
              *ngIf="address.risk_address_line1 === activeAddress"></ion-icon>
            <p class="address-text">{{ address.risk_address_line1 }}, {{ address.risk_address_line2 }}</p>
          </ion-item>
        </div>
      </div>
  
      <input-text-single-line *ngSwitchCase="QuestionType.text"
        [question]="sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]" [readOnly]="false"
        [showHeading]="true" (answerQuestion)="compAnswerQuestion($event)"
        [error]="errorDescription"></input-text-single-line>
      <input-text-single-line *ngSwitchCase="QuestionType.number"
        [question]="sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]" [readOnly]="false"
        [showHeading]="true" (answerQuestion)="compAnswerQuestion($event)"
        [error]="errorDescription"></input-text-single-line>
      <input-text-multi-lines *ngSwitchCase="QuestionType.multi"
        [question]="sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]" [readOnly]="false"
        [showHeading]="true" (answerQuestion)="compAnswerQuestion($event)"
        [error]="errorDescription"></input-text-multi-lines>
      <input-check-list-single-select *ngSwitchCase="QuestionType.select"
        [question]="sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]" [readOnly]="false"
        [showHeading]="true" (answerQuestion)="compAnswerQuestion($event)"></input-check-list-single-select>
      <input-check-list-single-select-search *ngSwitchCase="'SELECTSEARCH'"
        [question]="sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]" [readOnly]="false"
        [showHeading]="true" (answerQuestion)="compAnswerQuestion($event)"></input-check-list-single-select-search>
      <input-check-list-single-select *ngSwitchCase="QuestionType.boolean"
        [question]="sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]" [readOnly]="false"
        [showHeading]="true" (answerQuestion)="compAnswerQuestion($event)"></input-check-list-single-select>
      <input-check-list-multi-select *ngSwitchCase="QuestionType.multiSelect"
        [question]="sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]" [readOnly]="false"
        [showHeading]="true" (answerQuestion)="compAnswerQuestion($event)"></input-check-list-multi-select>
      <input-dropdown-motor-selector *ngSwitchCase="QuestionType.vehicleSearch"
        [question]="sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]" [readOnly]="false"
        [showHeading]="true" (answerQuestion)="compAnswerQuestion($event)"
        [labelPosition]="labelPosition"></input-dropdown-motor-selector>
      <input-text-address *ngSwitchCase="QuestionType.address" [page]="page"
        [question]="sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]" [readOnly]="false"
        [showHeading]="true" (answerQuestion)="compAnswerQuestion($event)"
        [error]="errorDescription"></input-text-address>
      <input-datepicker *ngSwitchCase="QuestionType.date"
        [question]="sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]" [showHeading]="true"
        [readOnly]="false" (answerQuestion)="compAnswerQuestion($event)" [error]="errorDescription"></input-datepicker>
      <input-datepicker *ngSwitchCase="QuestionType.dateTime"
        [question]="sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]" [showHeading]="true"
        [readOnly]="false" (answerQuestion)="compAnswerQuestion($event)" [error]="errorDescription"></input-datepicker>
      <input-single-search *ngSwitchCase="QuestionType.search"
        [question]="sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]" [showHeading]="true"
        [readOnly]="false" (answerQuestion)="compAnswerQuestion($event)" [error]="errorDescription"></input-single-search>
      <input-previous-claims *ngSwitchCase="'CLAIMLIST'"
        [question]="sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]" [showHeading]="true"
        [readOnly]="false" (answerQuestion)="compAnswerQuestion($event)"
        [error]="errorDescription"></input-previous-claims>
  
      <ion-item lines="none" *ngSwitchDefault>
        <!-- <ion-text>No Questions to ask</ion-text> -->
      </ion-item>
      <div class="helper-text">
        <small>{{ sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]?.wording }}</small>
      </div>
    </div>
    <div class="button-row limit-width-on-desktop-float side-padding"
      [ngClass]="{ 'remove-mt': sharedConfig.hideHeaderWhenAddedClaimType === true }">
      <ion-button mode="ios" [disabled]="!enableNext" *ngIf="
          sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]?.type === QuestionType.text ||
          sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]?.type === QuestionType.number ||
          sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]?.type === QuestionType.vehicleSearch ||
          sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]?.type === QuestionType.address ||
          sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]?.type === QuestionType.date ||
          sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]?.type === QuestionType.dateTime ||
          sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]?.type === QuestionType.search ||
          sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]?.type === QuestionType.multi ||
          sharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]?.type === QuestionType.multiSelect
        " (click)="nextQuestion()" class="primary-button">Next</ion-button>
      <ion-button mode="ios" (click)="saveAndClose()" *ngIf="!sharedConfig.addingClaim" class="secondary-button">Save and
        close</ion-button>
  
    </div>
  </div>
</div>
<up-next-animation
  *ngIf="sharedConfig.showUpNext"
  [animationName]="animationName"
  [displayText]="displayText"
  [upNextSectionText]="upNextSectionText"
></up-next-animation>
<success-animation
  *ngIf="showSuccess"
  [displayHeader]="displayHeader"
  [animationName]="animationName"
  [displayText]="displayText"
  [quoteType]="quoteType"
></success-animation>

<popup-dialog id="question.component" *ngIf="sharedConfig.popupDialogShow"></popup-dialog>