import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { BUSNQuoteScheduleService } from '@takectrl-multiapp/svcs-core-business';
import { IPriorityRating, SharedConfig, SharedDialogService } from '@takectrl-multiapp/svcs-share-config';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
declare const dataLayer: any[];

@Component({
  selector: 'needs-results',
  templateUrl: './needs-results.component.html',
  styleUrls: ['./needs-results.component.scss'],
})
export class NeedsResultsComponent implements OnInit {
  @Output() navigateToQuoteScreenAfterQuoteRequest: EventEmitter<void> = new EventEmitter<void>();
  @Output() navigateToNeedsSliders: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Input() priorityRatings: Array<IPriorityRating>;
  showSuccess = false;
  showFetchingQuotesAnimation = false;
  constructor(
    public busnQuoteScheduleService: BUSNQuoteScheduleService,
    public sharedConfig: SharedConfig,
    public loadingCtrl: LoadingController,
    private readonly busnThirdPartyService: BUSNThirdPartyService,
    private readonly sharedDialogService: SharedDialogService
  ) {}

  ngOnInit(): void {
    
      dataLayer.push({
        event: 'page_show',
        screenPath: 'needs-results',
        screenName: 'needs-results',
        userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
      });
  }

  navNeedsSliders(pageName: string) {
    this.navigateToNeedsSliders.emit({ pageName: pageName, rankings: [] });
  }

  async submitQuoteRequest() {
    this.showFetchingQuotesAnimation = true;
    SharedConfig.showFullScreen = true;
    SharedConfig.quoteRequest.fna = this.priorityRatings;
   this.busnQuoteScheduleService.submitQuoteRequest().then( (response) => {
      this.showFetchingQuotesAnimation = false;
      if (response.success) {
        this.showSuccess = true;
      } else {
        this.sharedDialogService.showPopupDialog({
          header: 'Something went wrong',
          subHeader: response.message,
          buttonOneText: 'OK',
          buttonOneCallback: this.sharedDialogService.hidePopupDialog,
        });
      }
    });
  }
}
