import { Injectable } from '@angular/core';
import { APIAssetService } from '@takectrl-multiapp/svcs-ctrl-api';
import {
  AssetType,
  IAllRiskItem,
  IBuilding,
  ICaravan,
  IContent,
  IDeleteAssets,
  IMotorcycle,
  ISpecifiedItem,
  IVehicle,
  SharedConfig,
} from '@takectrl-multiapp/svcs-share-config';
import * as _ from 'lodash';
import { firstValueFrom } from 'rxjs';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';

@Injectable({
  providedIn: 'root',
})
export class BUSNAssetService {
  constructor(public apiAssetService: APIAssetService, public sharedConfig: SharedConfig, public busnThirdPartyService: BUSNThirdPartyService) {}

  async getVehicles(): Promise<Array<IVehicle>> {
    return (await firstValueFrom(this.apiAssetService.getVehicles())).results;
  }
  async getVehicle(asset_id: number): Promise<IVehicle> {
    return (await firstValueFrom(this.apiAssetService.getVehicle(asset_id))).results;
  }
  async getMotorcycle(asset_id: number): Promise<IMotorcycle> {
    return (await firstValueFrom(this.apiAssetService.getMotorcycle(asset_id))).results;
  }
  async getCaravan(asset_id: number): Promise<ICaravan> {
    return (await firstValueFrom(this.apiAssetService.getCaravan(asset_id))).results;
  }
  async getBuilding(asset_id: number): Promise<IBuilding> {
    return (await firstValueFrom(this.apiAssetService.getBuilding(asset_id))).results;
  }
  async getContent(asset_id: number): Promise<IContent> {
    return (await firstValueFrom(this.apiAssetService.getContent(asset_id))).results;
  }
  async getSpecifiedItem(asset_id: number): Promise<ISpecifiedItem> {
    return (await firstValueFrom(this.apiAssetService.getSpecifiedItem(asset_id))).results;
  }
  async getAllRisk(asset_id: number): Promise<IAllRiskItem> {
    return (await firstValueFrom(this.apiAssetService.getAllRisk(asset_id))).results;
  }
  async getContents(): Promise<Array<IContent>> {
    return (await firstValueFrom(this.apiAssetService.getContents())).results;
  }
  async getMotorcycles(): Promise<Array<IMotorcycle>> {
    return (await firstValueFrom(this.apiAssetService.getMotorcycles())).results;
  }
  async getCaravans(): Promise<Array<ICaravan>> {
    return (await firstValueFrom(this.apiAssetService.getCaravans())).results;
  }

  async getBuildings(): Promise<Array<IBuilding>> {
    return (await firstValueFrom(this.apiAssetService.getBuildings())).results;
  }
  async getSpecifiedItems(): Promise<Array<ISpecifiedItem>> {
    return (await firstValueFrom(this.apiAssetService.getSpecifiedItems())).results;
  }

  async getAllRiskItems(): Promise<Array<IAllRiskItem>> {
    return (await firstValueFrom(this.apiAssetService.getAllRiskItems())).results;
  }

  private initializeQuoteAssets() {
    if (!this.sharedConfig?.quoteRequest?.assets) {
      this.sharedConfig.quoteRequest.assets = {
        all_risks: [],
        buildings: [],
        caravans: [],
        contents: [],
        motorcycles: [],
        specified_items: [],
        vehicles: [],
      };
    }
  }

  async addVehicle() {
    let vehicle: IVehicle = {
      user_id: this.sharedConfig.user.personal_details.user_id,
      quote_request_id: this.sharedConfig.quoteRequest.id,
      date_added: new Date().getTime().toString(),
    };

    this.initializeQuoteAssets();
    vehicle = (await firstValueFrom(this.apiAssetService.addVehicle(vehicle))).results;
    if (!this.sharedConfig.quoteRequest.assets.vehicles) this.sharedConfig.quoteRequest.assets.vehicles = [];
    this.sharedConfig.quoteRequest.assets.vehicles.push(vehicle);

    this.busnThirdPartyService.logEvent('action_asset', { asset_id: vehicle.id, asset_type: AssetType.Vehicles, action: 'added' });
    return vehicle;
  }

  async addMotorcycle(): Promise<IMotorcycle> {
    let motorcycle: IMotorcycle = {
      user_id: this.sharedConfig.user.personal_details.user_id,
      quote_request_id: this.sharedConfig.quoteRequest.id,
    };
    this.initializeQuoteAssets();
    motorcycle = (await firstValueFrom(this.apiAssetService.addMotorcycle(motorcycle))).results;

    if (!this.sharedConfig.quoteRequest.assets.motorcycles) this.sharedConfig.quoteRequest.assets.motorcycles = [];
    this.sharedConfig.quoteRequest.assets.motorcycles.push(motorcycle);

    this.busnThirdPartyService.logEvent('action_asset', { asset_id: motorcycle.id, asset_type: AssetType.Motorcycles, action: 'added' });
    return motorcycle;
  }

  async addCaravan(): Promise<ICaravan> {
    let caravan: ICaravan = {
      user_id: this.sharedConfig.user.personal_details.user_id,
      quote_request_id: this.sharedConfig.quoteRequest.id,
    };
    this.initializeQuoteAssets();
    caravan = (await firstValueFrom(this.apiAssetService.addCaravan(caravan))).results;

    if (!this.sharedConfig.quoteRequest.assets.caravans) this.sharedConfig.quoteRequest.assets.caravans = [];
    this.sharedConfig.quoteRequest.assets.caravans.push(caravan);

    this.busnThirdPartyService.logEvent('action_asset', { asset_id: caravan.id, asset_type: AssetType.Caravans, action: 'added' });
    return caravan;
  }

  async addBuilding(): Promise<IBuilding> {
    let building: IBuilding = {
      user_id: this.sharedConfig.user.personal_details.user_id,
      quote_request_id: this.sharedConfig.quoteRequest.id,
    };
    this.initializeQuoteAssets();
    building = (await firstValueFrom(this.apiAssetService.addBuilding(building))).results;
    if (!this.sharedConfig.quoteRequest.assets.buildings) this.sharedConfig.quoteRequest.assets.buildings = [];
    this.sharedConfig.quoteRequest.assets.buildings.push(building);

    this.busnThirdPartyService.logEvent('action_asset', { asset_id: building.id, asset_type: AssetType.Buildings, action: 'added' });
    return building;
  }

  async addContent(): Promise<IContent> {
    let content: IContent = {
      user_id: this.sharedConfig.user.personal_details.user_id,
      quote_request_id: this.sharedConfig.quoteRequest.id,
    };
    this.initializeQuoteAssets();
    content = (await firstValueFrom(this.apiAssetService.addContent(content))).results;
    if (!this.sharedConfig.quoteRequest.assets.contents) this.sharedConfig.quoteRequest.assets.contents = [];
    this.sharedConfig.quoteRequest.assets.contents.push(content);

    this.busnThirdPartyService.logEvent('action_asset', { assetId: content.id, asset_type: AssetType.Contents, action: 'added' });
    return content;
  }

  async addAllRiskItem(): Promise<IAllRiskItem> {
    let all_risks: IAllRiskItem = {
      user_id: this.sharedConfig.user.personal_details.user_id,
      quote_request_id: this.sharedConfig.quoteRequest.id,
    };
    this.initializeQuoteAssets();

    all_risks = (await firstValueFrom(this.apiAssetService.addAllRiskItem(all_risks))).results;

    if (!this.sharedConfig.quoteRequest.assets.all_risks) this.sharedConfig.quoteRequest.assets.all_risks = [];

    this.sharedConfig.quoteRequest.assets.all_risks.push(all_risks);

    this.busnThirdPartyService.logEvent('action_asset', { assetId: all_risks.id, asset_type: AssetType.AllRisks, action: 'added' });
    return all_risks;
  }

  async addSpecifiedItem(): Promise<ISpecifiedItem> {
    let specified_item: ISpecifiedItem = {
      user_id: this.sharedConfig.user.personal_details.user_id,
      quote_request_id: this.sharedConfig.quoteRequest.id,
    };
    this.initializeQuoteAssets();

    specified_item = (await firstValueFrom(this.apiAssetService.addSpecifiedItem(specified_item))).results;

    if (!this.sharedConfig.quoteRequest.assets.specified_items) this.sharedConfig.quoteRequest.assets.specified_items = [];

    this.sharedConfig.quoteRequest.assets.specified_items.push(specified_item);

    this.busnThirdPartyService.logEvent('action_asset', { assetId: specified_item.id, asset_type: 'specified_item', action: 'added' });
    return specified_item;
  }

  async removeAssetsFromQuote(deleteAssetBody: IDeleteAssets, quoteRequestId: number): Promise<void> {
    await firstValueFrom(this.apiAssetService.removeAsset(deleteAssetBody, quoteRequestId));
    this.busnThirdPartyService.logEvent('action_asset_deleted', deleteAssetBody);
  }

  // async removeAssetFromQuote(asset_type: AssetType, user_id: number, quoteRequestId: number): Promise<void> {
  //   const deleteAssetBody: unknown = {
  //     asset_type,
  //     user_id,
  //   };

  //   await firstValueFrom(this.apiAssetService.removeAsset(deleteAssetBody, quoteRequestId));
  //   this.busnThirdPartyService.logEvent('action_asset_deleted', deleteAssetBody);
  // }

  async updateVehicle(vehicle: IVehicle): Promise<IVehicle> {
    const cloned_vehicle = _.cloneDeep(vehicle);
    delete cloned_vehicle.daytime_address;
    delete cloned_vehicle.overnight_address;
    vehicle = (await firstValueFrom(this.apiAssetService.updateVehicle(cloned_vehicle))).results;
    this.busnThirdPartyService.logEvent('action_asset', { asset_id: vehicle.id, asset_type: AssetType.Vehicles, action: 'updated' });
    return vehicle;
  }

  async updateMotorcycle(motorcycle: IMotorcycle): Promise<IMotorcycle> {
    const cloned_motorcycle = _.cloneDeep(motorcycle);
    delete cloned_motorcycle.daytime_address;
    delete cloned_motorcycle.overnight_address;
    motorcycle = (await firstValueFrom(this.apiAssetService.updateMotorcycle(cloned_motorcycle))).results;
    this.busnThirdPartyService.logEvent('action_asset', { asset_id: motorcycle.id, asset_type: AssetType.Motorcycles, action: 'updated' });
    return motorcycle;
  }

  async updateCaravan(caravan: ICaravan): Promise<ICaravan> {
    const cloned_caravan = _.cloneDeep(caravan);
    delete cloned_caravan.daytime_address;
    delete cloned_caravan.overnight_address;
    caravan = (await firstValueFrom(this.apiAssetService.updateCaravan(cloned_caravan))).results;
    this.busnThirdPartyService.logEvent('action_asset', { asset_id: caravan.id, asset_type: AssetType.Caravans, action: 'updated' });
    return caravan;
  }

  async updateBuilding(building: IBuilding): Promise<IBuilding> {
    const cloned_building = _.cloneDeep(building);
    if (cloned_building.address) {
      console.log('NO ADDRESS ----> CAN REMOVE THE DELETE FUNCTION');
    } else {
      console.log('address still present -->>> needs delete fucntion? weird');
    }
    delete cloned_building.address;
    building = (await firstValueFrom(this.apiAssetService.updateBuilding(cloned_building))).results;
    this.busnThirdPartyService.logEvent('action_asset', { asset_id: building.id, asset_type: AssetType.Buildings, action: 'updated' });
    return building;
  }

  async updateContent(content: IContent): Promise<IContent> {
    const cloned_content = _.cloneDeep(content);
    delete cloned_content.address;
    content = (await firstValueFrom(this.apiAssetService.updateContent(cloned_content))).results;
    this.busnThirdPartyService.logEvent('action_asset', { asset_id: content.id, asset_type: AssetType.Contents, action: 'updated' });
    return content;
  }
  async updateAllRiskItem(allRisk: IAllRiskItem): Promise<IAllRiskItem> {
    allRisk = (await firstValueFrom(this.apiAssetService.updateAllRiskItem(allRisk))).results;
    this.busnThirdPartyService.logEvent('action_asset', { asset_id: allRisk.id, asset_type: AssetType.AllRisks, action: 'updated' });
    return allRisk;
  }
  async updateSpecifiedItem(specifiedItem: ISpecifiedItem): Promise<ISpecifiedItem> {
    specifiedItem = (await firstValueFrom(this.apiAssetService.updateSpecifiedItem(specifiedItem))).results;
    this.busnThirdPartyService.logEvent('action_asset', { asset_id: specifiedItem.id, asset_type: 'specifiedItem', action: 'updated' });
    return specifiedItem;
  }

  async addAsset(assetType: AssetType): Promise<any> {
    switch (assetType) {
      case AssetType.Vehicles:
        return this.addVehicle();
      case AssetType.Motorcycles:
        return this.addMotorcycle();
      case AssetType.Buildings:
        return this.addBuilding();
      case AssetType.Contents:
        return this.addContent();
      case AssetType.Caravans:
        return this.addCaravan();
      case AssetType.SpecifiedItems:
        return this.addSpecifiedItem();
      case AssetType.AllRisks:
        return this.addAllRiskItem();
      default:
        throw new Error(`Unsupported asset type: ${assetType}`);
    }
  }
}
