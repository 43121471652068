import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavController } from '@ionic/angular';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { BUSNQuestionService, BUSNRbixService } from '@takectrl-multiapp/svcs-core-business';
import { SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import { APIHttpService } from 'libs/svcs-ctrl-api/src/lib/services/api-http.service';

@Component({
  selector: 'rbix-object-map-record-button',
  templateUrl: './rbix-object-map-record-button.component.html',
  styleUrls: ['./rbix-object-map-record-button.component.scss'],
})
export class RbixObjectMapRecordButtonComponent implements OnInit {
  @Output() navToPage: EventEmitter<string> = new EventEmitter<string>();
  constructor(
    public busnThirdPartyService: BUSNThirdPartyService,
    public navCtrl: NavController,
    public httpService: APIHttpService,
    private readonly cd: ChangeDetectorRef,
    public sharedConfig: SharedConfig,
    public busnQuestionService: BUSNQuestionService,
    public busnRbixService: BUSNRbixService
  ) {}

  ngOnInit(): void {
    if (this.busnRbixService.isObjectMapAvailable() && SharedConfig.allowsRecording && !SharedConfig.startRecordingByPassPageDetectChanges) {
      this.busnRbixService.initializeAndStartRecordingForObjectMap();
      this.cd.detectChanges();
    } 
    SharedConfig.startRecordingByPassPageDetectChanges = true
  }

  async startRecording() {
    if (SharedConfig.allowsRecording) {
      this.busnRbixService.initializeAndStartRecordingForObjectMap();
      this.cd.detectChanges();
    }
  }

  stopRecording() {
    this.busnRbixService.stopRecording();
    this.cd.detectChanges();
  }

  playAudio(base64Audio: string) {
    this.busnRbixService.playAudio(base64Audio);
    // SharedConfig.isProcessing = false;
    // // Create a new audio element
    // // Set the audio source to the base64 string
    // SharedConfig.audio.src = `data:audio/mpeg;base64,${base64Audio}`;
    // // Set the audio type to mp3
    // SharedConfig.audio.setAttribute('type', 'audio/mpeg');
    // // Play the audio
    // SharedConfig.audio.play();

    // // Add an event listener to log the text "stopped" when the audio has stopped playing
    // SharedConfig.audio.addEventListener('ended', () => {
    //   SharedConfig.isProcessing = false;
    //   this.cd.detectChanges();
    //   console.log('stopped');
    // });
  }

}
