import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'small-popup',
  templateUrl: './small-popup.component.html',
  styleUrls: ['./small-popup.component.scss'],
})
export class SmallPopupComponent implements OnInit {
  @Input() header: string;
  @Input() message: string;
  constructor() {}

  ngOnInit(): void {}
}
