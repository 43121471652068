import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { environment } from '../environments/environment';
import { IAppClientDetail, SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import { APIHttpService } from 'libs/svcs-ctrl-api/src/lib/services/api-http.service';

@Component({
  selector: 'takectrl-multiapp-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private readonly platform: Platform,
    public apiHttpService: APIHttpService,
    public sharedConfig: SharedConfig // private themeSwitcher : ThemeSwitcherService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // below theme switcher for mnultitenancy.
      // this.themeSwitcher.setTheme(environment.client.client_name_key)
      SharedConfig.appCredentials = {
        firebase_credentials: environment.firebase,
        ctrl_api_url: environment.ctrl_api_url,
        intercom_app_id: environment.intercom_app_id,
        mix_panel_key: environment.mix_panel_key,
        facebook_web_logging_enabled: environment.facebook_web_logging_enabled,
        firebase_functions_url: environment.firebase_functions_url,
        postal_search_api_url: environment.postal_search_api_url,
        rbix_api_url: environment.rbix_api_url,
        rbix_token: environment.rbix_token,
        application: environment.application,
      };
      SharedConfig.production = environment.production;
      SharedConfig.production = environment.production;
      this.sharedConfig.setAppClientDetail(environment.app_client_detail as IAppClientDetail);
      this.apiHttpService.setBaseUrl(environment.ctrl_api_url);

      //this.statusBar.styleDefault();
      //this.splashScreen.hide();
    });
  }
}
