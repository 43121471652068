<div class="wrapper limit-width-on-desktop-float">
  <div class="header">
    <h2 class="h2-bold">You're almost covered</h2>
    <p>You're a click away from being covered. Just confirm below and we're good to go.</p>
  </div>
  <client-card [insurer_id]="insurer_id" [clientName]="clientName" [policy_number]="policy_number" [effective_date]="effective_date"></client-card>
  <div class="content-wrapper">
    <div class="rows">
      <b class="head">My monthly premium</b>
      <ion-grid>
        <ion-row>
          <ion-col>
            <div *ngIf="!selectedQuote.insurer_id" class="table-cell value1">
              {{ insurer_id }}
            </div>
            <div *ngIf="selectedQuote.insurer_description" class="table-cell value1">
              {{ selectedQuote.insurer_description }}
            </div>
          </ion-col>
          <div *ngIf="insurer_id === 'MIWAY'; then miWayPrem; else normalPrem"></div>
          <ng-template #miWayPrem>
            <ion-col class="text-right"> R{{ miWayPremtotal | number : '1.2-2' }} </ion-col>
          </ng-template>
          <ng-template #normalPrem>
            <ion-col class="text-right"> R{{ total_premium - sasriaTotal - abTotal - selectedQuote.broker_fee | number : '1.2-2' }} </ion-col>
          </ng-template>
        </ion-row>
        <ion-row class="grey-row">
          <ion-col class="value1"> Sasria </ion-col>
          <div *ngIf="insurer_id === 'MIWAY'; then miWaySas; else normalSas"></div>
          <ng-template #miWaySas>
            <ion-col class="text-right"> Included </ion-col>
          </ng-template>
          <ng-template #normalSas>
            <ion-col class="text-right"> R{{ sasriaTotal | number : '1.2-2' }} </ion-col>
          </ng-template>
        </ion-row>
        <ion-row>
          <div *ngIf="insurer_id === 'MIWAY'; then miWayValue; else normalValue"></div>
          <ng-template #miWayValue>
            <ion-col class="value1" style="white-space: nowrap"> Tracking device provider </ion-col>
            <ion-col class="text-right"> R{{ trackerPrem | number : '1.2-2' }} </ion-col>
          </ng-template>
          <ng-template #normalValue>
            <ion-col class="value1"> Value added products </ion-col>
            <ion-col class="text-right"> R{{ abTotal | number : '1.2-2' }} </ion-col>
          </ng-template>
        </ion-row>
        <ion-row class="grey-row">
          <ion-col class="value1"> Advice fee </ion-col>
          <ion-col class="text-right"> R{{ selectedQuote.broker_fee | number : '1.2-2' }} </ion-col>
        </ion-row>
        <ion-row class="total-row">
          <ion-col class="total"> Total </ion-col>
          <ion-col class="text-right">
            <div *ngIf="insurer_id === 'MIWAY'; then miWayTotalPrem; else normalTotalPrem"></div>
            <ng-template #miWayTotalPrem>
              <b> R{{ miWayPremtotal | number : '1.2-2' }} </b>
            </ng-template>
            <ng-template #normalTotalPrem>
              <b> R{{ total_premium | number : '1.2-2' }} </b>
            </ng-template>
          </ion-col>
        </ion-row>
      </ion-grid>
      <p class="com-in-prem">
        The premium shown includes commission of <b>R{{ selectedQuote.commission | number : '1.2-2' }}</b>
      </p>
    </div>
    <div class="rows" *ngIf="insurer_id !== 'KINGPRICE'">
      <b>Debit order</b>
      <p class="please-note">
        <small *ngIf="insurer_id !== 'KINGPRICE' && insurer_id !== 'MIWAY'"
          ><b>Please note: Your insurer has appointed Brolink to collect your debit order.</b></small
        >
      </p>

      <ion-grid>
        <ion-row class="grey-row">
          <ion-col class="value1"> Bank </ion-col>
          <ion-col class="text-right">
            <div class="table-cell value">
              {{ banking_details?.financial_institution }}
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="value1"> Account no </ion-col>
          <ion-col class="text-right">
            {{ banking_details?.account_number }}
          </ion-col>
        </ion-row>
        <ion-row class="grey-row">
          <ion-col class="value1"> Deduction date </ion-col>
          <ion-col class="text-right"> {{ deductionDateOrdinal }} of the month </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <div class="miway-text" *ngIf="insurer_id === 'MIWAY'">
      <p>
        If the inception of your policy does not coincide with your debit order deduction date, MiWay will deduct a once-off pro-rata premium to
        ensure you are covered for the period prior to your first deduction. Also note that MiWay may collect your premium earlier than the agreed
        date in order to ensure cover.
      </p>
    </div>
    <div class="acknowledge-text">
      <h2 class="question-heading">I acknowledge</h2>
      <div [ngSwitch]="true">
        <div *ngSwitchCase="insurer_id === 'KINGPRICE'">
          <p>
            That after accepting the King Price quote, I will receive a confirmation email which includes my policy wording and policy schedule, and
            that I may be required to take some action.
          </p>
          <p>
            That by accepting the King Price quote telephonically I will be appointing {{ fullClientName }} (FSP {{ fsb_number }}) as my broker,
            earning an advice fee as described above.
          </p>
          <p>
            That all information provided is correct and that I have disclosed anything that could influence the insurer’s risk, since
            misrepresentation or non-disclosure may lead to the rejection of claims.
          </p>
        </div>
        <div *ngSwitchCase="insurer_id === 'ABSAPLUS' || insurer_id === 'ABSAPREMIUM'">
          <p>
            That I will receive a confirmation email which includes my policy wording and policy schedule, and that I may be required to take some
            action.
          </p>
          <p>That I hereby appoint {{ fullClientName }} (FSP {{ fsb_number }}) as my broker, earning an advice fee as described above.</p>
          <p>That ABSA will do a quick anti money laundering check* to ensure that we protect you by keeping the bad apples out.</p>
          <p>
            That all information provided is correct and that I have disclosed anything that could influence the insurer’s risk, since
            misrepresentation or non-disclosure may lead to the rejection of claims.
          </p>
          <p>*Customer Acceptance Screening Application</p>
        </div>
        <div *ngSwitchCase="insurer_id === 'MIWAY'">
          <div *ngIf="selectedQuote.hasOwnProperty('warnings')">
            <div *ngFor="let warning of warningsText">
              <p>
                That I need to install a {{ warning.leadDescription }} (Monthly with no upfront installation fee) per month fitted through MiWay on my
                {{ warning.asset }} within 14 days for the cover to be effective. This premium is payable directly to the tracking company.
              </p>
            </div>
          </div>
          <p>
            That I will receive a confirmation email which includes my policy wording and policy schedule, and that I may be required to take some
            action.
          </p>
          <p>That I hereby appoint {{ fullClientName }} (FSP {{ fsb_number }}) as my broker, earning an advice fee as described above.</p>
          <p>That I understand that I will only have cover if I pay my premiums.</p>
          <p>
            That I grant MiWay permission to obtain profile information from data suppliers and for MiWay to disclose any information relating to your
            policy, claims, insurance and financial history.
          </p>
          <p>
            That all information provided is correct and that I have disclosed anything that could influence the insurer’s risk, since
            misrepresentation or non-disclosure may lead to the rejection of claims.
          </p>
          <p>That I understand that claims can be submitted to MiWay directly from the app once I accept this policy.</p>
          <p>That I understand that I can submit a complaint at <a>complaints&#64;miway.co.za</a>.</p>
        </div>
        <div *ngSwitchDefault>
          <p>
            That I will receive a confirmation email which includes my policy wording and policy schedule, and that I may be required to take some
            action.
          </p>
          <p>That I hereby appoint {{ fullClientName }} (FSP {{ fsb_number }}) as my broker, earning an advice fee as described above.</p>
          <p>
            That all information provided is correct and that I have disclosed anything that could influence the insurer’s risk, since
            misrepresentation or non-disclosure may lead to the rejection of claims.
          </p>
        </div>
      </div>
      <div class="accept-sentence">
        <p>That by accepting this quote, I declare that <a class="popup-text" (click)="acceptDisclosurePopup()">this is true.</a></p>
      </div>
    </div>
  </div>
</div>
<div class="button-row">
  <ion-button mode="ios" class="primary-button limit-width-desktop-on-button" (click)="confirmQuoteRequest()"> I agree </ion-button>
  <ion-button mode="ios" (click)="showIntercom()" class="secondary-button limit-width-desktop-on-button"> Not sure? Contact us </ion-button>
</div>
<!-- Disclosure Popup -->
<div class="backdrop" *ngIf="showDisclosurePopup"></div>
<div class="popup-standard limit-width-on-desktop-float" *ngIf="showDisclosurePopup">
  <div class="heading">
    <h2 class="h2-bold">I declare</h2>
  </div>
  <div class="sub-header">
    <p>I confirm that a welcome email, setting out the financial adviser’s particulars, has been made available to me.</p>
    <p>
      I understand that the objective of a Financial Needs Analysis process is to provide me with an analysis of my financial needs, goals and
      situation and to develop recommendations to address these needs and goals.
    </p>
    <p>
      Where the adviser was unable to do an analysis because all the information was not provided, I will take particular care to determine whether
      the advice is appropriate considering my financial objectives and current financial position. I furthermore understand that this is not a
      comprehensive risk analysis due to the limited information provided by myself and since the adviser can only focus on my short-term insurance
      needs.
    </p>
    <p>
      The quotation(s) for the product(s) selected was shown to me and the principal terms and conditions have been available on the various app
      screens. I have familiarised myself with and understand all costs, charges, excesses, fees and commission payable where applicable.
    </p>
    <p>
      I confirm that if the product(s) selected are intended to replace any existing financial products held by me, I understand that the adviser may
      not have specific knowledge of the product being replaced and therefore the responsibility to consequences of this replacement rests with me.
    </p>
    <p>
      I understand that the accuracy of the recommendation is dependent on the information obtained by or provided to the adviser and I understand
      that material non-disclosure could result in an inappropriate product(s) being recommended to me as well as the incorrect premium charged.
    </p>
    <p>
      Notwithstanding the information provided by the adviser, I acknowledge that I have an obligation to familiarise myself with the terms and
      conditions of the product(s) that I have purchased.
    </p>
    <p>I acknowledge that the premium- and excess structure is affordable to me, given my overall financial position.</p>
    <p>
      Lastly, please take note that we are not qualified evaluators and encourage all our clients to consider the appointment of a qualified valuator
      to ensure your assets are insured for the up to date replacement value.
    </p>
  </div>
  <div class="buttons-wrapper">
    <ion-button mode="ios" class="primary-button" (click)="closeDisclosurePopup()">I confirm </ion-button>
  </div>
</div>

<!-- Fetching Quotes Animation -->
<div class="animation-component-wrapper" *ngIf="showFetchingQuotesAnimation">
  <div class="animation-wrapper limit-width-on-desktop-float">
    <lottie-player autoplay mode="normal" loop src="assets/lottie-animations/fetching-policy.json"></lottie-player>
  </div>
  <div class="content-wrapper limit-width-on-desktop-float">
    <h2 class="h2-bold">Fetching your insurance policy</h2>
    <p>Hang tight, it takes us less than a minute to confirm your policy number.</p>
  </div>

  <div class="footer-button-wrapper">
    <div class="bottom-text">
      <p>
        Have any questions? <a (click)="showIntercom()"><b>Contact us</b></a>
      </p>
    </div>
    <div *ngIf="sharedConfig.schedulesActive && sharedConfig.schedulesActive.length > 0" class="footer-button-background">
      <ion-button mode="ios" (click)="goToWallet()" class="secondary-button limit-width-desktop-on-button"> Got it </ion-button>
    </div>
  </div>
</div>

<!-- Error Animation -->
<div class="animation-component-wrapper" *ngIf="showErrorAnimation">
  <div class="error-animation-wrapper limit-width-on-desktop-float">
    <lottie-player autoplay mode="normal" loop src="assets/lottie-animations/error.json"></lottie-player>
  </div>
  <div class="error-content-wrapper limit-width-on-desktop-float">
    <h2 class="h2-bold">Unfortunately, we were not able to commit and fetch this policy.</h2>
    <p>It seems that certain details may be incorrect or missing. Please check your details and resubmit or contact your broker
    for further information.</p>
  </div>

  <div class="footer-button-wrapper">
    <div class="footer-button-background">
      <ion-button mode="ios" class="primary-button limit-width-desktop-on-button" (click)="goBackToCompare()"> Check details </ion-button>
      <ion-button mode="ios" (click)="showIntercom()" class="secondary-button limit-width-desktop-on-button"> Contact us </ion-button>
    </div>
  </div>
</div>

<!-- King Price -->
<div class="animation-component-wrapper" *ngIf="showKingPriceAnimation">
  <div class="kp-animation-wrapper limit-width-on-desktop-float">
    <lottie-player autoplay mode="normal" loop src="assets/lottie-animations/call.json"></lottie-player>
  </div>
  <div class="error-content-wrapper limit-width-on-desktop-float">
    <h2 class="h2-bold">King Price will call you shortly!</h2>
    <p>
      They will contact you on
      <a
        ><b>{{ sharedConfig.user.personal_details.cell_nr }}</b></a
      >
      to set up your banking details.
    </p>
  </div>

  <div class="footer-button-wrapper">
    <div class="footer-button-background">
      <ion-button mode="ios" (click)="navFromKPtoWallet()" class="primary-button limit-width-desktop-on-button"> Got it </ion-button>
    </div>
  </div>
</div>

<popup-with-dismiss-button *ngIf="sharedConfig.showPopupWithDismissButton"></popup-with-dismiss-button>

<!-- TODO ALL OTHER POPUPS SHOULD BE REPLACED BY THIS GENERIC POPUP -->
<popup-dialog  id="quote.accept.component" *ngIf="sharedConfig.popupDialogShow"></popup-dialog>
