<div class="wrapper limit-width-on-desktop-float">
  <div class="header-wrapper">
    <h2 class="h2-bold">It’s time for your renewal</h2>
    <p>Everything you need to know about the standard annual renewal by the insurer.</p>
  </div>

  <div class="new-premium-wrapper">
    <ion-grid>
      <ion-row>
        <ion-col size="8">
          <div class="premium-details">
            <small> New premium </small>
            <h3>
              <b class="text-blue">R{{ renewalData?.policy_diff.schedule.total_premium.renewal | number : '1.0-0' }} pm</b>
            </h3>
            <small
              ><b
                >Effective from <a class="text-blue">{{ renewalData?.renewal_date }}</a></b
              ></small
            >
          </div>
        </ion-col>
        <ion-col size="4" (click)="viewDetails()">
          <ion-button mode="ios" class="view-details">
            <ion-label>View details</ion-label>
            <ion-icon slot="end" src="assets/icon/app-icons/arrow-right-primary.svg"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
    <div class="current-premium">
      <hr />
      <p>
        Current premium is <b>R{{ renewalData?.policy_diff?.schedule.total_premium.current | number : '1.0-0' }} pm</b>
      </p>
    </div>
  </div>
  <div class="list-wording">
    <ion-grid>
      <ion-row>
        <ion-col size="1">
          <ion-icon src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
        </ion-col>
        <ion-col>
          We’ll send your updated Schedule, Record of Advice (ROA) and Policy wording to
          <b class="text-blue">{{ personal_details?.email }}</b
          >.
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="1">
          <ion-icon src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
        </ion-col>
        <ion-col> Any changes in excesses and premiums can be viewed in the detailed comparison when you click on "View detail". </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="1">
          <ion-icon src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
        </ion-col>
        <ion-col>
          If we don't hear from you before the renewal date, we will assume that you are happy with everything. Please contact us if you have any
          queries.
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <div class="bottom-btn">
    <ion-button mode="ios" class="primary-button" (click)="closeRenewalPage()">Ok, got it.</ion-button>
    <ion-button mode="ios" class="secondary-button" (click)="contactBroker()">Contact broker</ion-button>
  </div>
</div>

<!-- TODO ALL OTHER POPUPS SHOULD BE REPLACED BY THIS GENERIC POPUP -->
<popup-dialog id="renewal.component" *ngIf="sharedConfig.popupDialogShow"></popup-dialog>
