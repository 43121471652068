<div class="wrapper limit-width-on-desktop-float">
  <div class="logo-div">
    <img src="assets/logos/primary-full.svg" />
  </div>

  <div class="card card-md">
    <ol class="section">
      <!-- 1. -->
      <li>
        <h3>Introduction</h3>

        <p>
          These are the general terms of our relationship with you. They cover any transactions where we provide services to you. Under these terms:
        </p>

        <ul>
          <li>
            <strong>we</strong> are the service provider – Ctrl Financial Intermediaries (Pty) Ltd (Registration number: 2017/191425/07), also known
            as Ctrl
          </li>
          <li><strong>you</strong> are the customer – someone who uses our services</li>
        </ul>

        <p>
          A confirmation is a document or form or displayed screen with content that contains the commercial terms of each specific transaction and
          incorporates these terms.
        </p>
      </li>

      <!-- 2. -->
      <li>
        <h3>Agreement</h3>

        <ol>
          <li>
            <strong><em>Composition</em></strong
            >. The agreement consists of these terms of service and any confirmations or any other specific terms applicable to the services.
          </li>
          <li>
            <strong><em>Definitions</em></strong
            >. In the agreement:

            <p><strong>application</strong> means the Ctrl mobile application</p>

            <p>
              <strong>business day</strong> means any day other than a Saturday, Sunday, or holiday (including a public or bank holiday) in the
              jurisdiction where we are organised
            </p>

            <p><strong>business hours</strong> means our normal business hours on business days</p>
            <p>
              <strong>confirmation</strong> means the screen or electronic document that we display on the website or application when you request a
              policy schedule from an insurer (or email to you afterwards) which sets out the commercial details of the transaction
            </p>
            <p>
              <strong>day</strong> means a day counted from midnight to midnight, including all days of the month, Saturdays, Sundays, and public
              holidays
            </p>
            <p>
              <strong>sign</strong> means the handwritten signature or an electronic signature that the parties agree to use, of each of the parties’
              duly authorised representatives
            </p>
            <p><strong>we, us,</strong> or <strong>our</strong> means the service provider</p>
            <p>
              <strong>writing</strong> means the reproduction of information or data in physical form or any mode of reproducing information or data
              in electronic form that the parties agree to use, but excludes information or data in the form of chat messages or email
            </p>
            <p><strong>you</strong> or <strong>your</strong> means the customer</p>
          </li>

          <li>
            <strong><em>Interpretation</em></strong
            >. The following rules apply to the interpretation of the agreement:

            <ul>
              <li><strong>reference headings</strong> - clause and subclause headings are for reference only and do not affect interpretation</li>
              <li>
                <strong>non-exhaustive lists</strong> - whenever a clause lists specific examples or items following a listing word, such as
                ‘including’, ‘includes’, ‘excluding’, or ‘excludes’, they will not limit its scope
              </li>
              <li>
                <strong>undefined words or phrases</strong> - all words or phrases that the agreement does not define have their ordinary English
                meaning
              </li>
              <li><strong>references to enactments</strong> - references to any enactment include it as re-enacted, amended, or extended</li>
              <li><strong>references to people</strong> - references to a person includes a natural and juristic person</li>
              <li><strong>references to parties</strong> - references to a party includes their successors or permitted assigns</li>
              <li>
                <strong>number of days</strong> - when any number of days is prescribed, the first day will be excluded and the last day included
              </li>
              <li>
                <strong>no interpretation against the draftsman</strong> - the rule of construction that an agreement must be interpreted against the
                party responsible for its drafting or preparation does not apply
              </li>
              <li><strong>time calculations</strong> - the parties will use GMT +2 to calculate any times</li>
            </ul>
          </li>

          <li>
            <strong><em>Departure</em></strong
            >. These terms apply to all our customers and are not generally open to negotiation for reasons of consistency. Should the parties
            negotiate any departure from these terms, they will record that departure in the relevant confirmation or other specific terms.
          </li>

          <li>
            <strong><em>Conflict</em></strong
            >. If there is a conflict of meaning between these terms and any word or phrase in a confirmation or other specific terms, the meaning in
            the confirmation or specific terms will prevail in respect of the relevant services.
          </li>
        </ol>
      </li>

      <!-- 3. -->
      <li>
        <h3>Duration</h3>
        <p>These terms start whenever you accept them by:</p>
        <ul>
          <li>
            <strong>doing so explicitly</strong> - such as by checking a checkbox saying that you do or agreeing to an order that incorporates them by
            reference
          </li>
          <li><strong>using the services in any way</strong> - such as by accessing them</li>
          <li>or exercising any rights granted to you under the agreement</li>
        </ul>
        <p>and continue until terminated.</p>
      </li>

      <!-- 4. -->
      <li>
        <h3>Confirmations</h3>

        <ol>
          <li>
            <strong><em>Requesting confirmations.</em></strong> You request confirmations from us whenever you request or start using the services
            through the website or application. These requests are offers to us to solicit our services.
          </li>
          <li>
            <strong><em>Capacity and authority.</em></strong> You promise that you have the legal capacity and authority to enter into the agreement.
          </li>
          <li>
            <strong><em>Invitation to do business.</em></strong> Marketing is merely an invitation to do business and we only conclude the agreement
            when we actually provide the services to you. This happens when we accept your offer.
          </li>
          <li>
            <strong><em>Cancellations.</em></strong> We may cancel any confirmation in our absolute discretion to the extent allowed by applicable
            law.
          </li>
          <li>
            <strong><em>Time and place.</em></strong> We conclude the agreement when we accept the request for a confirmation and where we are
            domiciled when we do.
          </li>
          <li>
            <strong><em>Separate agreements.</em></strong> Each confirmation is a separate agreement, but you are deemed to have breached all of them
            if you breach one of them.
          </li>
        </ol>
      </li>

      <!-- 5. -->
      <li>
        <h3>Services</h3>

        <ol>
          <li>
            <strong><em>Right.</em></strong> We grant you a right to use the services subject to the following limitations:
            <ul>
              <li><strong>duration of agreement</strong> - you may only use the services for the duration of the agreement</li>
              <li><strong>limited to terms</strong> - you may only use the services according to these terms</li>
              <li><strong>non-exclusive</strong> - we may allow anyone else to use the services</li>
              <li><strong>non-transferable</strong> - you may not transfer the right to anyone else</li>
              <li>
                <strong>specified purposes</strong> - you may only use the services for the specified purposes that we’ve communicated to you in
                writing from time to time
              </li>
              <li>and any other limitations agreed between the parties in writing</li>
            </ul>
          </li>
          <li>
            <strong><em>Breach.</em></strong> We may cancel your right if you breach the agreement.
          </li>
        </ol>
      </li>

      <!-- 6. -->
      <li>
        <h3>Online services</h3>

        <ol>
          <li>
            <strong><em>Basis.</em></strong> We provide the online services to you on the following basis:
            <ul>
              <li>you give us permission to monitor how you use them for security and stability purposes</li>
              <li>you agree that our records are undisputed evidence of the services provided to you</li>
            </ul>
          </li>
          <li>
            <strong><em>Access conditions.</em></strong> We will only provide online service access to you or your authorised users (where you are a
            juristic person) on the conditions that you or each one of them will:
            <ul>
              <li>accurately provide us with any information that we ask for on registration or account creation</li>
              <li>create or have the necessary credentials (such as a username and password) assigned to them on registration or account creation</li>
              <li>look after their credentials and not give them to anyone else</li>
              <li>not interfere with or introduce any malicious software into the online services or otherwise misuse them</li>
              <li>
                be responsible for any activity that happens under their account, even if someone else was actually acting under their credentials
              </li>
              <li>have the necessary infrastructure, equipment, and software to access the online services</li>
              <li>abide by the agreement and any policies that we communicate to them in writing</li>
              <li>and any other conditions agreed between the parties in writing</li>
            </ul>
          </li>
          <li>
            <strong><em>Availability.</em></strong> We will do our best to make the online services available at all times, however we cannot
            guarantee that they will always be available. We may make them unavailable for scheduled and emergency maintenance.
          </li>
        </ol>
      </li>

      <!-- 7. -->
      <li>
        <h3>Financial services</h3>

        <ol>
          <li>
            <strong><em>Confirmations.</em></strong> We do our best to make the confirmation an accurate summary of your policy wording and schedule
            issued by your insurer. However, our website or application is merely a tool to help you apply for insurance. You remain responsible for
            reading the actual policy wording and schedule issued by your insurer. We are not liable for any differences between the confirmation and
            the actual policy wording and schedule issued by your insurer.
          </li>
          <li>
            <strong><em>Authorised financial service provider.</em></strong> We are an Authorised Financial Service Provider with FSP License number
            48557, which means that the Financial Services Board of South Africa has authorised us to provide advice and intermediary services on
            short-term Insurance products in terms of the Financial Advisory and Intermediary Services Act 37 of 2002 (<strong>FAIS</strong>).
          </li>
          <li>
            <strong><em>License certificate.</em></strong> You can view our FSP license certificate in the options menu in our application or at our
            offices where we’ve displayed our FSP license certificate.
          </li>
        </ol>
      </li>

      <!-- 8. -->
      <li>
        <h3>Chat function</h3>

        <ol>
          <li>
            <strong><em>Basis.</em></strong> We offer a chat service as part of our application’s functionality where the parties can chat with each
            other. We’ll push generic messages to you depending on your behaviour on our application and may initiate live conversations with you from
            time to time. You can also initiate a live chat with us and you will be able to ask us questions. Depending on whether you are an active
            client who mandated us for intermediary services or simply a curious visitor, we may or may not respond.
          </li>
          <li>
            <strong><em>Response times.</em></strong> We will respond during normal offices hours and do our best to do so after hours where possible.
          </li>
          <li>
            <strong><em>Monitor abuse.</em></strong> We monitor your use of the chat function for abuse and may block you or a conversation involving
            you where necessary without prior notification to you.
          </li>
        </ol>
      </li>

      <!-- 9. -->
      <li>
        <h3>Intermediary agency agreement</h3>

        <ol>
          <li>
            <strong><em>Background.</em></strong> We have entered into an intermediary agency agreement with a third party service provider which
            means that they will perform certain back-office functions to administer your insurance policy, in certain circumstances.
          </li>
          <li>
            <strong><em>Their rights.</em></strong> The third party service provider and the insurer have the rights to:
            <ul>
              <li>alter or amend the terms and conditions of policies whether issued or not</li>
              <li>alter the amount of the premium payable by a policyholder</li>
              <li>collect the necessary fees or premiums in respect of the policies issued in terms of our intermediary agreement</li>
            </ul>
          </li>
          <li>
            <strong><em>Functions.</em></strong> The third party service provider will perform various functions in terms of FAIS, including:
            <ul>
              <li>handling claims</li>
              <li>portfolio analysis</li>
              <li>all inspections of third party claims</li>
              <li>
                recovery of excesses (for which third parties may require assistance from Ctrl in obtaining information and or documentation from you)
              </li>
            </ul>
          </li>
        </ol>
      </li>

      <!-- 10. -->
      <li>
        <h3>Complaints</h3>

        <ol>
          <li>
            <strong><em>Complaints policy.</em></strong> We have a complaints policy that we will adhere to.
          </li>
          <li>
            <strong><em>How to complain.</em></strong> If you feel that we have not treated you fairly, you can complain to us at any time by emailing
            us at complaints&#64;takectrl.co.za.
          </li>
          <li>
            <strong><em>How we handle complaints.</em></strong> We will
            <ul>
              <li>
                promptly acknowledge receipt of your complaint in writing and give you the contact details of our staff who will help resolve it
              </li>
              <li>handle your complaint in a timely and fair manner</li>
              <li>consider each complaint properly</li>
              <li>manage the complaints process appropriately and effectively</li>
              <li>inform you of the outcome of your enquiry within four weeks of receiving your complaint</li>
              <li>
                and advise you of any further steps which may be available to you in terms of FAIS or any other law, where your complaint is not
                resolved to your satisfaction
              </li>
            </ul>
            <p>If the outcome of a complaint is not in your favour, we will:</p>
            <ul>
              <li>provide you with full written reasons for not deciding in your favour</li>
              <li>
                make sure that you understand your rights to further pursue your complaint with the relevant regulatory authority, within six months
              </li>
            </ul>
          </li>
        </ol>
      </li>

      <!-- 11. -->
      <li>
        <h3>Feedback</h3>

        <ol>
          <li>
            <strong><em>Introduction.</em></strong> We value your feedback about the service. It helps us improve it for you and our other customers.
            Feedback means any comments or suggestions that you send us or post on a website that we control related to the service.
          </li>
          <li>
            <strong><em>No obligation to you.</em></strong> We are not obliged to compensate or credit you for your feedback in any way unless we have
            a written agreement with you to do so.
          </li>
          <li>
            <strong><em>Your obligations to us.</em></strong> When you submit your feedback to us, you:
            <ul>
              <li>
                grant us a perpetual, non-exclusive, royalty-free licence to use, reproduce and, modify your feedback for any purpose related to the
                service
              </li>
              <li>
                agree to deliver all documents and perform all actions necessary to ensure that our rights to use, reproduce, and modify your feedback
                are effective and enforceable
              </li>
              <li>
                give up any claim that our use, reproduction, or modification of your feedback violates any of your rights, including your
                intellectual property rights or your moral rights (the rights not to have your work distorted and to be credited for your work).
              </li>
            </ul>
          </li>
          <li>
            <strong><em>Submission.</em></strong> You can submit any feedback to us via our chat function, by email or through any of our other
            feedback channels.
          </li>
          <li>
            <strong><em>May or may not take action.</em></strong> We will consider your feedback and may or may not decide to act on it in our
            absolute discretion in terms of our roadmap for the service.
          </li>
        </ol>
      </li>

      <!-- 12. -->
      <li>
        <h3>Acceptable use</h3>

        <ol>
          <li>
            <strong><em>True, accurate and up-to-date information.</em></strong> We need true, accurate and up-to-date information from you to provide
            you with the services – because, for example, false information will lead to your risk profile being inaccurate, which may cause you
            monthly premiums to be incorrect and could lead to insurers repudiating your future claims. You warrant that all information that you
            provide to us will be accurate (to the best of your knowledge) and agree to keep that information up-to-date by contacting us to change it
            when necessary. A warranty is a legally enforceable promise. You also agree to indemnify us against any harm caused by the information
            that you give us being false, to the extent allowed by applicable law. This means that you are liable for any damages if you misrepresent
            any information that you give to us as being accurate where it is not or where that information turns out to be false, to the extent
            allowed by applicable law.
          </li>

          <li>
            <strong><em>Right of admission.</em></strong> We may refuse or revoke your ability to access our website or application at any time in our
            absolute discretion, to the extent allowed by applicable law.
          </li>
          <li>
            <strong><em>Responsibility.</em></strong> You are responsible for your conduct on our website or application. You understand that your
            actions could have consequences, and may result in criminal or civil liability. We do not endorse, take any responsibility, or accept
            liability for your actions.
          </li>
          <li>
            <strong><em>Prohibited conduct.</em></strong> You may not use, or help others to use, our website or application or its services to
            interfere with the connections or security of networks, systems, or other users including:
            <ul>
              <li>
                <strong>unauthorized access</strong> - including accessing or attempting to access a network, system or user’s profile without
                permission
              </li>
              <li>
                <strong>surveillance</strong> - including the surveillance of any data on a network, system or user’s profile without permission
              </li>
              <li>
                <strong>intentional harm</strong> - including intentionally damaging the structure or efficient working of a network, system or user’s
                profile through physical or electronic means (such as Denial of Service attacks)
              </li>
              <li><strong>spam</strong> - including sending or promoting unsolicited mass electronic communications without permission</li>
              <li>
                <strong>data manipulation</strong> - including the malicious and fraudulent altering of any data on or sent by a network, system, or
                user’s profile without permission
              </li>
              <li>
                or <strong>system manipulation</strong> - including the malicious and fraudulent alteration of a network, system, or user’s profile
                without permission
              </li>
            </ul>
          </li>
          <li>
            <strong><em>Investigation and enforcement.</em></strong> We will investigate any breach of this clause.
          </li>
          <li>
            <strong><em>Your duty to report breaches.</em></strong> You agree to notify us of any breach of this clause, via email or any other
            reporting process that we have set up.
          </li>
        </ol>
      </li>

      <!-- 13. -->
      <li>
        <h3>Security</h3>

        <ol>
          <li>
            <strong><em>Basis.</em></strong> We know that your data is precious to you and we do various things to keep your data safe as set out in
            this clause.
          </li>
          <li>
            <strong><em>Our security responsibilities.</em></strong> We will:
            <ul>
              <li>store very limited personal information on your device, so that you can access our application without an internet connection</li>
              <li>
                host our website and cloud-based database on a secure server that uses a firewall and other security measures to prevent unauthorised
                access
              </li>
              <li>
                encrypt all data transfers between our application and our cloud-based database to make sure that all data passed between our
                application and cloud servers remain private
              </li>
              <li>
                otherwise protect your information that we store in or passes through our systems using encryption or other appropriate information
                security measures
              </li>
              <li>ensure that links from our systems to systems that third parties control are secure</li>
              <li>backup all data on our systems so that we can recover it in case of disaster</li>
              <li>and log all access to our systems to better identify and resolve unauthorised access issues</li>
            </ul>
          </li>
          <li>
            <strong><em>Disclaimer.</em></strong> We will do our best to prevent our website, application and your information from being compromised
            and will help you resolve a security problem whenever we can. However, we are not responsible for compromises caused by:
            <ul>
              <li><strong>harmful code</strong> - entering our website, such as viruses, bugs, Trojan horses, spyware, or adware</li>
              <li>
                <strong>your fault</strong> - problems or loss caused by your information you provide to us (such as inaccurate personal information)
                or your computer or other device being compromised (such as being accessed by an unauthorised person or otherwise hacked)
              </li>
              <li>factors <strong>beyond human control</strong> such as fires, floods, or other natural disasters</li>
              <li>or any other factors that cause it to be breached, damaged, lost or otherwise compromised to the extent applicable law allows</li>
            </ul>
          </li>
          <li>
            <strong><em>Third party systems.</em></strong> Third parties are responsible for the security of information collected by, stored on, or
            passing through their systems, even if we link to those systems.
          </li>
          <li>
            <strong><em>Your security responsibilities.</em></strong> You will:
            <ul>
              <li>
                keep the application up-to-date by making sure that you have the latest version available via the relevant app store installed on your
                device
              </li>
              <li>not do (or let anyone else do) anything that might compromise our system</li>
              <li>
                take the following steps to secure your credentials: never share them with anyone; never send them via email or mobile message; and
                make any passwords as strong as possible and keep them secret
              </li>
            </ul>
          </li>
        </ol>
      </li>

      <!-- 14. -->
      <li>
        <h3>Your data</h3>

        <ol>
          <li>
            <strong><em>Definition.</em></strong> Your data is any data belonging to you that:
            <ul>
              <li>you (or any third party on your behalf) provide to us</li>
              <li>we generate, process, or supply to you in providing the services</li>
            </ul>
            <p>
              but excludes any derived data that we create for our own purposes or which is proprietary or confidential to us or our third party
              contractors.
            </p>
          </li>
          <li>
            <strong><em>You own it.</em></strong> You own all your data, but give us a right to use it to provide the services when you provide us
            with access to it.
          </li>
          <li>
            <strong><em>We do not own it.</em></strong> We do not own any of your data. However, we do own our derived data. Your data does not
            include any derived data that we create for our own internal purposes. Derived data is any of our own data that we create from your data,
            such as through aggregation, de-identification, or anonymisation.
          </li>
          <li>
            <strong><em>Responsibility.</em></strong> We take the protection of your data very seriously and will always do everything in our power to
            protect it. However, we are not responsible for any of your data stored on the online services to the extent allowed by applicable law,
            you provide it to us at your own risk, and you indemnify us against any liability for it to the extent allowed by applicable law,
            including liability for data breaches, unauthorised access, and third party claims.
          </li>
          <li>
            <strong><em>Subcontracting.</em></strong> Subcontracting involves engaging a subcontractor outside our organisation to do work as part of
            providing the services. We may subcontract work involving your data to the extent allowed by applicable law.
          </li>
          <li>
            <strong><em>Location.</em></strong> Your data will remain wherever we place it initially, unless we have to transfer it to another country
            to comply with our obligations to you. You consent to us transferring it to our group of companies, associated companies, service
            providers, or agents who may be located in other countries for the purpose of providing the services.
          </li>
        </ol>
      </li>

      <!-- 15. -->
      <li>
        <h3>Intellectual property</h3>

        <ol>
          <li>
            <strong><em>Ownership.</em></strong> We or our third party licensors own all proprietary rights in our services and we or they may
            prosecute you for any violations of those rights.
          </li>
          <li>
            <strong><em>Our technology.</em></strong> Our technology is anything that we have or acquire rights in and may use to perform our
            obligations under the agreement.
          </li>
          <li>
            <strong><em>Retention of rights.</em></strong> We own all intellectual property rights in our technology and you may not use those rights
            without our permission. You do not acquire any rights in our technology if we use it to provide services to you.
          </li>
          <li>
            <strong><em>Our trademarks.</em></strong> Our trademarks are our property and you may not use them without our permission. All other
            trademarks are their respective owners’ property.
          </li>
          <li>
            <strong><em>Restrictions.</em></strong> You may not change, hire out, reverse engineer, or copy the services without our prior written
            permission.
          </li>
        </ol>
      </li>

      <!-- 16. -->
      <li>
        <h3>Our warranties</h3>

        <ol>
          <li>
            <strong><em>Service warranties.</em></strong> We warrant that we will:
            <ul>
              <li>use reasonable efforts consistent with prevailing industry standards to maintain the services</li>
              <li>provide the services in accordance with all applicable laws</li>
            </ul>
          </li>
          <li>
            <strong><em>General warranties.</em></strong> We warrant further that we:
            <ul>
              <li>have the legal right and authority to perform our obligations under the agreement</li>
              <li>will not knowingly introduce any malicious software into your systems</li>
            </ul>
          </li>
        </ol>
      </li>

      <!-- 17. -->
      <li>
        <h3>Disclaimer of warranties</h3>

        <ol>
          <li>
            <strong
              ><em
                >Disclaimer. You use the services at your own risk and we disclaim all other warranties to the extent allowed by applicable law. We
                are not liable for any defect that you cause.</em
              ></strong
            >
          </li>
          <li>
            <strong
              ><em
                >Exclusion of liability. Despite our warranties, we are not liable for any defects that your negligence, failure to follow our
                instructions, or misuse causes.</em
              ></strong
            >
          </li>
        </ol>
      </li>

      <!-- 18. -->
      <li>
        <h3>Your warranties</h3>

        <ol>
          <li>
            <strong><em>Agreement warranties.</em> You warrant that:</strong>
            <ul>
              <li>
                <strong>no one has induced you to enter into the agreement by any prior representations, warranties, or guarantees</strong>
              </li>
              <li>
                <strong>you are not breaching any other agreement by entering into the agreement</strong>
              </li>
            </ul>
          </li>
          <li>
            <strong
              ><em>Indemnity.</em> You indemnify us against any claim for damages by any third party resulting from a breach of your warranties,
              including all legal costs. Legal costs means the costs that a lawyer may recover from their client for their disbursements and
              professional services if permissible under applicable law.</strong
            >
          </li>
        </ol>
      </li>

      <!-- 19. -->
      <li>
        <h3>Limitation of liability</h3>

        <ol>
          <li>
            <strong
              ><em>Direct damages limited.</em> We are only liable to you for any direct damages that the services may cause up to the total amount of
              fees that you have already paid us for them.</strong
            >
          </li>
          <li>
            <strong><em>Indirect damages excluded.</em> We are not liable for any other damages or losses that the services may cause you.</strong>
          </li>
          <li>
            <strong><em>Your default.</em> We are not liable for any damage or loss that your breach, misrepresentation, or mistake causes.</strong>
          </li>
        </ol>
      </li>

      <!-- 20. -->
      <li>
        <h3>Breach and termination</h3>

        <ol>
          <li>
            <strong><em>Breach.</em></strong> If either party
            <ul>
              <li>does not fix a breach within seven days of receiving written notice from the other party</li>
              <li>breaches the agreement materially twice or more in six months</li>
              <li>is bankrupt or has some legal disability</li>
              <li>takes steps to close or is closed down (such as becoming insolvent or entering sequestration)</li>
              <li>makes any settlement or arrangement with their creditors</li>
              <li>or fails to pay a court order against themselves for a significant amount within 21 days</li>
            </ul>
            <p>then the other party may:</p>
            <ul>
              <li>make the party comply with the agreement</li>
              <li>or immediately cancel the agreement in writing and claim damages from the other party, including fees already due</li>
            </ul>
          </li>
          <li>
            <strong><em>Suspension.</em></strong> We may immediately suspend your right to use the services if:
            <ul>
              <li>you try to gain unauthorised access to them</li>
              <li>we decide that your use poses a security threat to us or another user other than you</li>
              <li>there is evidence of fraud on your account</li>
              <li>or we believe you are using them for an illegal purpose or in way that infringes a third party’s rights</li>
            </ul>
          </li>
        </ol>
      </li>

      <!-- 21. -->
      <li>
        <h3>Termination</h3>

        <ol>
          <li>
            <strong><em>Termination for good cause.</em></strong> We may need to terminate the agreement immediately if we:
            <ul>
              <li>discontinue or stop providing the services</li>
              <li>believe providing the services could burden or pose a risk to us</li>
              <li>have to terminate to comply with a law</li>
              <li>or determine that providing the services has become impractical</li>
            </ul>
            <p>If we need to terminate, we will give you as much notice as reasonably possible in writing.</p>
          </li>
          <li>
            <strong><em>Duties on termination.</em></strong> We will stop providing the services, you will no longer be able to access them, and we
            may erase your data on termination, cancellation, or expiry of the agreement.
          </li>
        </ol>
      </li>

      <!-- 22. -->
      <li>
        <h3>Effect of termination</h3>

        <ol>
          <li>
            <strong><em>Assistance.</em></strong> We may provide you with post termination assistance (such as data retrieval) subject to additional
            fees and conditions, but are not obliged to.
          </li>
          <li>
            <strong><em>No expectation.</em></strong> The agreement does not create any expectation of continued service, agreement renewal, or any
            further agreement between the parties.
          </li>
        </ol>
      </li>

      <!-- 23. -->
      <li>
        <h3>General</h3>

        <ol>
          <li>
            <strong><em>Governing law.</em></strong> South African law governs this agreement.
          </li>
          <li>
            <strong><em>Mediation.</em></strong> If negotiation fails, the parties must refer the dispute to mediation under AFSA’s rules. AFSA means
            the Arbitration Foundation of Southern Africa (or its successor or body nominated in writing by it in its stead).
          </li>
          <li>
            <strong><em>Arbitration.</em></strong> If mediation fails, the parties must refer the dispute within 15 business days to arbitration
            (including any appeal against the arbitrator’s decision) under AFSA’s latest rules for expedited arbitrations. The arbitration will be
            held in English in Johannesburg. The parties will agree and appoint one arbitrator. If the parties cannot agree on the arbitrator within
            10 business days after the referral, the Secretariat of AFSA will appoint the arbitrator.
          </li>
          <li>
            <strong><em>Jurisdiction.</em></strong> You consent to the jurisdiction of the Magistrate’s Court in respect of any action or proceedings
            that we may bring against you in connection with this agreement, even if the action or proceedings would otherwise be beyond its
            jurisdiction without prejudice to our right to institute any action in any other court having jurisdiction.
          </li>
          <li>
            <strong><em>Resolving disputes.</em></strong> Either party may inform the other in writing if there is a dispute. The parties must first
            try to negotiate to end the dispute, then enter into mediation if negotiation fails, and finally go to arbitration if mediation fails. If
            they go to arbitration, they will agree in writing on a recognized and appropriate forum for arbitration that is accessible to both
            parties.
          </li>
          <li>
            <strong><em>Notices and domicile.</em></strong> The parties will send all notices to each others’ email addresses and choose their
            respective street addresses as their service addresses for all legal documents. Our email and street addresses are available on our
            website, while you provide your email and street addresses to us when concluding the agreement. The parties may change either address on
            14 calendar days written notice to the other.
          </li>
          <li>
            <strong><em>Beyond human control.</em></strong> Neither party is responsible for breach of the agreement caused by circumstances beyond
            human control, but the other party may cancel the agreement on written notice to the other if the circumstances persist for more than 60
            calendar days.
          </li>
          <li>
            <strong><em>Assignment.</em></strong> You may not assign the agreement to anyone. We may assign it to any successor or purchaser of our
            business or some of our assets.
          </li>
          <li>
            <strong><em>Relationship.</em></strong> The agreement does not create an employment relationship between the parties.
          </li>
          <li>
            <strong><em>Entire agreement.</em></strong> The agreement is the entire agreement between the parties on the subject.
          </li>
          <li>
            <strong><em>Changes.</em></strong> We will notify you of any changes to the agreement by email. Those changes will only apply to future
            services confirmations. If you do not agree with the changes, you must stop using the services. If you continue to use the services
            following notification of a change, the changed terms will apply to you and you will be deemed to have accepted them.
          </li>
          <li>
            <strong><em>Waiver.</em></strong> Any favour we may allow you will not affect any of our rights against you.
          </li>
          <li>
            <strong><em>Severability.</em></strong> Any term that is invalid, unenforceable, or illegal may be removed from the agreement without
            affecting the rest of it.
          </li>
        </ol>
      </li>
    </ol>

    <p align="center">© Copyright 2002-2018 Michalsons (www.michalsons.com). All rights reserved. Version 1.3</p>
  </div>

  <h2>Privacy Policy</h2>

  <div class="card card-md">
    <ol class="section">
      <!-- 1 -->
      <li>
        <h3>Introduction</h3>
        <p>
          We respect your privacy and take it very seriously. We are Ctrl Financial Intermediaries (Pty) Ltd (Registration number 2017/191425/07) and
          this is our plan of action for the protection of your personal information. It describes how we <strong><u>collect</u></strong
          >, <strong><u>use</u></strong
          >, <strong><u>disclose</u></strong
          >, and <strong><u>store</u></strong> your personal information.
        </p>
      </li>

      <!-- 2 -->
      <li>
        <h3>Application</h3>
        <p>This policy applies to you when you visit our website, use our application, or order our services as a customer.</p>
      </li>

      <!-- 3 -->
      <li>
        <h3>Personal information</h3>

        <ol>
          <li>
            <strong><em>Scope.</em></strong> Your <strong>personal information</strong> includes information we collect:
            <ul>
              <li>automatically when you visit our website or use our application</li>
              <li>accept on registration or submission</li>
              <li>get through an order</li>
              <li>or you provide voluntarily</li>
              <li>but excludes anonymous, de-identified, statistical, and public information</li>
            </ul>
          </li>
          <li>
            <strong><em>Insurance information.</em></strong> As a financial intermediary, we need to collect a large amount of personal information to
            allow various insurers to provide you with accurate pricing (quotations) inside our application (<strong>insurance information</strong>).
            We use this same personal information to ensure that you do not have to enter the same information twice and therefore avoid the
            time-consuming process of getting new quotes.
          </li>
          <li>
            <strong><em>Your obligations</em></strong> You:
            <ul>
              <li>may only send us your own personal information or someone else’s personal information if you have their permission to do so</li>
              <li>and warrant that that information is true and accurate to the best of your knowledge</li>
            </ul>
          </li>
        </ol>
      </li>

      <!-- 4 -->
      <li>
        <h3>Acceptance and changes</h3>

        <ol>
          <li>
            <strong><em>Acceptance.</em></strong> You may not order any of our services if you do not accept this policy or any changes to it.
          </li>
          <li>
            <strong><em>Changes.</em></strong> We may change this policy at any time and will notify you of the changes on our website, through our
            application, or by email. The changed policy applies to you if you continue using our website, our application, or our services following
            the notification. We have posted a version number at the bottom of this page to make sure that you know when we’ve made a change to any
            wording on it.
          </li>
        </ol>
      </li>

      <!-- 5 -->
      <li>
        <h3>Collection</h3>

        <ol>
          <li>
            <strong><em>Automatically through website.</em></strong> When you visit our website, we automatically collect your
            <strong>Internet usage information</strong>, including your IP address, browser details, and usage information, which we may use to
            display our website correctly, track your activities on it, or for other necessary purposes.
          </li>
          <li>
            <strong><em>Automatically through application.</em></strong> When you use our application, we automatically collect your
            <strong>application usage information</strong> through analytics software and other technologies, which we use to optimise our offering to
            you.
          </li>
          <li>
            <strong><em>On registration or submission.</em></strong> We may ask you to provide us with certain
            <strong>identifying information</strong> when you register on or otherwise submit information through our website or application for the
            first time.
          </li>
          <li>
            <strong><em>Through an order.</em></strong> We ask you to provide us certain <strong>services information</strong> when you order our
            services.
          </li>
          <li>
            <strong><em>Voluntarily.</em></strong> We may ask you to provide us certain <strong>optional information</strong> on a voluntary basis.
          </li>
          <li>
            <strong><em>Consent to collection.</em></strong> We will get your consent to collect your personal information when required by applicable
            law when you provide us with it.
          </li>
          <li>
            <strong><em>Purpose for collection.</em></strong> We may process your information that you provide to us for the purposes that you
            indicated when you agreed to provide it to us. Processing includes gathering your personal information, disclosing it, and combining it
            with other personal information.
          </li>
        </ol>
      </li>

      <!-- 6 -->
      <li>
        <h3>Use</h3>

        <ol>
          <li>
            <strong><em>Processing.</em></strong> We may process your personal information to fulfil our obligations to you.
          </li>
          <li>
            <strong><em>Cookies.</em></strong> We may place small text files on your device when you visit our website that allow us to provide you
            with a personalised experience by associating your personal information with your device. They let us remember your preferences, allow
            third parties to provide services to you, and otherwise serve useful purposes for you. Your internet browser generally accepts them
            automatically, but you can often change this setting or delete them manually. However, we won’t be able to provide you with access to
            certain aspects of our website where cookies are necessary if you do so. We have no access to or control over any cookies that our
            business partners use on our website and they have their own privacy polices that govern them.
          </li>
          <li>
            <strong><em>Messages.</em></strong> We may use your identifying information to send you administrative and update messages about our
            website or application.
          </li>
          <li>
            <strong><em>Promotional messages.</em></strong> We may also sometimes send you promotional messages, but you may choose to opt-out of
            them.
          </li>
          <li>
            <strong><em>Targeted content.</em></strong> We may use your personal information to provide you with targeted content through our website
            or application in a completely automated process.
          </li>
        </ol>
      </li>

      <!-- 7 -->
      <li>
        <h3>Disclosure</h3>

        <ol>
          <li>
            <strong><em>Credit check.</em></strong> You give us consent by using our application to do the following in order for you to obtain an
            insurance quotation:
            <ul>
              <li>access to your credit information held by one or more of the registered credit bureaus</li>
              <li>and to verify and share policy and insurance claim information with insurers and other institutions</li>
            </ul>
            <p>or for a third party service provider to do so on our behalf.</p>
          </li>
          <li>
            <strong><em>Sharing.</em></strong> We may share your personal information with third parties for the purposes of fulfilling our
            obligations to you, including with:
            <ul>
              <li>other companies or divisions within our group;</li>
              <li>our contractors who help provide part of our services, including those that help us understand your personal information; or</li>
              <li>third parties as required by applicable law.</li>
            </ul>
          </li>
          <li>
            <strong><em>Honour this policy.</em></strong> We will require anyone that we share your personal information with to honour this policy
            whenever possible in terms of applicable law.
          </li>
          <li>
            <strong><em>Mandatory disclosure.</em></strong> We may disclose personal information to third parties if required for legal reasons, such
            as:
            <ul>
              <li>to regulators as law or governmental audit requires</li>
              <li>law enforcement as subpoena or court order requires</li>
              <li>or third parties as applicable law requires</li>
            </ul>
          </li>
          <li>
            <strong><em>Marketing purposes.</em></strong> We may disclose aggregate statistical information that we have derived from your and other
            people’s personal information to our advertisers or business partners.
          </li>
          <li>
            <strong><em>Personnel.</em></strong> We may need to disclose personal information to our personnel to do their jobs, but will not do so
            unnecessarily.
          </li>
          <li>
            <strong><em>Change of ownership.</em></strong> We may assign our rights to the personal information we process to our new owners if our
            ownership changes for any reason.
          </li>
        </ol>
      </li>

      <!-- 8 -->
      <li>
        <h3>Storage</h3>

        <ol>
          <li>
            <strong><em>Insurance information.</em></strong> FAIS requires us to store all information relating to your insurance, including the
            advice we have offered and the information upon which someone generated a quote for a period of five years. We store this information
            securely.
          </li>
          <li>
            <strong><em>Access and deletion.</em></strong> You may ask us to:
            <ul>
              <li>provide you with the personal information that we hold on you</li>
              <li>or delete it, provided that we do not need it to fulfil our obligations to you or applicable law does not require us to keep it</li>
            </ul>
            <p>
              We will take all reasonable steps to verify your identity before doing so. We may charge a fee to recoup our costs associated with this
              request if there is a cost to us associated with it and provided that applicable law allows us to do so. Please contact us with your
              name and any other information needed to identify you correctly if you want access to information or want us to correct, update or
              delete it.
            </p>
          </li>
          <li>
            <strong><em>Accuracy.</em></strong> We will do our best to keep your personal information that we collect accurate, complete, and up to
            date.
          </li>
          <li>
            <strong><em>Participation.</em></strong> We may ask you to update your personal information from time to time, but will take steps to
            verify your identity to prevent unauthorised access when doing so.
          </li>
          <li>
            <strong><em>Retention.</em></strong> We will only keep your personal information for as long as is necessary to fulfil our obligations to
            you, unless you have given us permission to keep it longer or we are otherwise legally allowed to do so.
          </li>
          <li>
            <strong><em>Transfer.</em></strong> We may transfer your personal information outside of the country in which it was collected to a
            foreign country. You consent to us processing your personal information in a foreign country with less stringent data protection laws than
            the country in which it was collected.
          </li>
        </ol>
      </li>

      <!-- 9 -->
      <li>
        <h3>General</h3>

        <ol>
          <li>
            <strong><em>Updating or removing.</em></strong> You may update or remove your personal information through our website or application or
            by contacting us.
          </li>
          <li>
            <strong><em>Limitation.</em></strong> We are not responsible for anyone else’s privacy policies or practices.
          </li>
          <li>
            <strong><em>Enquiries.</em></strong> Please contact us if you have any questions about this privacy policy or how we handle your personal
            information.
          </li>
        </ol>
      </li>
    </ol>

    <p align="center">© Copyright 2002-2018 Michalsons (www.michalsons.com). All rights reserved. Version 1.0</p>
  </div>
</div>
