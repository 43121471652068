import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { IQuoteComparisonAssetTab, QuoteComparisonDetailType } from '@takectrl-multiapp/svcs-share-config';
@Component({
  selector: 'quote-comparison-asset-details-tabs',
  templateUrl: './quote-comparison-asset-details-tabs.component.html',
  styleUrls: ['./quote-comparison-asset-details-tabs.component.scss'],
})
export class QuoteComparisonAssetDetailsTabsComponent implements OnInit {
  @ViewChild(IonSlides) slides: IonSlides;
  @Input() quoteComparisonAssetTabs: Array<IQuoteComparisonAssetTab>;
  @Output() setActiveComparisonAssetTab: EventEmitter<IQuoteComparisonAssetTab> = new EventEmitter<IQuoteComparisonAssetTab>();
  disableLeft = true;
  @Input() disableRight: boolean;
  @Input() quoteComparisonDetailType: string;
  QuoteComparisonDetailType = QuoteComparisonDetailType
  item: any;
  currentI: any;
  slideOpts = {
    slidesPerView: 3,
  };
  activeClass: boolean;
  constructor() {}

  ngOnInit(): void {}

  slideDidChange() {}

  tabSelected(selectedComparisonAssetTab: IQuoteComparisonAssetTab) {
    this.quoteComparisonAssetTabs.forEach((quoteComparisonAssetTab) => {
      if (quoteComparisonAssetTab.active) {
        quoteComparisonAssetTab.active = false;
      }
    });
    selectedComparisonAssetTab.active = !selectedComparisonAssetTab.active;

    this.setActiveComparisonAssetTab.emit(selectedComparisonAssetTab);
  }

  slidePrevious() {
    this.slides.slidePrev(100, false);
    const promiseBeginning = this.slides.isBeginning();
    Promise.all([promiseBeginning]).then((data) => {
      if (data[0]) {
        this.disableLeft = true;
        this.disableRight = false;
      } else {
        this.disableLeft = false;
        this.disableRight = false;
      }
    });
  }

  slideNext() {
    if (this.item > 3) {
      this.disableLeft = false;
    }
    this.slides.slideNext(100, false);
    const promiseEnd = this.slides.isEnd();
    Promise.all([promiseEnd]).then((data) => {
      if (data[0]) {
        this.disableRight = true;
        this.disableLeft = false;
      } else {
        this.disableRight = false;
        this.disableLeft = false;
      }
    });
  }
}
