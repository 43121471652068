<!-- STATE ONE: user fill in form START -->
<div class="wrapper limit-width-on-desktop-float" *ngIf="!showSucessPage">
  <div class="top-heading">
    <h2 class="h2-bold">Spread the word</h2>
    <p>Refer a friend and get rewarded. You’ll receive a R{{ referral_amount }} Takealot voucher if your friend accepts one of our quotes.</p>
  </div>
  <div class="form">
    <ion-item mode="ios">
      <ion-label position="floating">My friend’s name</ion-label>
      <ion-input autocapitalize="on" type="text" [(ngModel)]="friendName"></ion-input>
    </ion-item>
    <ion-item mode="ios">
      <ion-label position="floating">My friend’s surname</ion-label>
      <ion-input autocapitalize="on" type="text" [(ngModel)]="friendSurname"></ion-input>
    </ion-item>
    <ion-item mode="ios" class="email">
      <ion-label position="floating">My friend’s email address</ion-label>
      <ion-input type="email" [(ngModel)]="friendEmail" (ionInput)="checkEmail($event)"></ion-input>
    </ion-item>
    <p class="error-message" *ngIf="errorText">{{ errorText }}</p>
    <ion-item mode="ios" *ngIf="user_referral_code">
      <ion-label position="floating">My referral code</ion-label>
      <ion-input readonly="true" type="text" value="{{ user_referral_code }}"></ion-input>
    </ion-item>

    <div class="button-div">
      <ion-button mode="ios" class="primary-button" (click)="send()" [disabled]="!friendName || !friendSurname || !friendEmail || errorText">
        Send
      </ion-button>
    </div>
  </div>
</div>
<!-- STATE ONE: END -->
<!-- STATE TWO: Referrel send success START -->
<div class="wrapper limit-width-on-desktop-float" *ngIf="showSucessPage">
  <div class="top-heading">
    <h2 class="h2-bold">You’re a real pal</h2>
    <p>Thanks for referring your friend.</p>
  </div>

  <div *ngIf="!sharedConfig.appClientDetail.static_refer_friend_page_image" class="next-screen">
    <div class="voucher">
      <div class="top-part">
        <img src="assets/images/gift-card/takealot.svg" alt="" />
      </div>
      <div class="bottom-part">
        <ion-grid>
          <ion-row>
            <ion-col size="8">
              <b>Treat yourself to a </b>
              <h3>R{{ referral_amount }}</h3>
              <b>gift voucher*</b>
            </ion-col>
            <ion-col size="4">
              <div class="like-img">
                <img src="assets/images/gift-card/like.svg" alt="" />
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </div>
    <div class="small-text">
      <ul>
        <li>
          <small
            >That’s if your friend downloads the app using your referral code, accepts one of our quotes and pays their first premium. So chances are
            really good. Thanks for your support.</small
          >
        </li>
      </ul>
    </div>
  </div>

  <div class="static-image-wrapper" *ngIf="sharedConfig.appClientDetail.static_refer_friend_page_image">
    <img src="assets/images/placeholder/welcome@4x.png" />
  </div>
</div>
<div class="button-row show-success" *ngIf="showSucessPage">
  <ion-button mode="ios" class="primary-button" (click)="done()"> Done </ion-button>
</div>
<!-- STATE TWO: END -->

<!-- TODO ALL OTHER POPUPS SHOULD BE REPLACED BY THIS GENERIC POPUP -->
<popup-dialog  id="referral.component" *ngIf="sharedConfig.popupDialogShow"></popup-dialog>
