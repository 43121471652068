import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { APIPolicyService } from '@takectrl-multiapp/svcs-ctrl-api';
import {
  IClaim,
  IClaimNumber,
  IEmergencyNumber,
  IInsurer,
  IPolicyBenefit,
  IT2wof,
  AssetType,
  CoverType,
  ICancelPolicyAsset,
  IDealerships,
} from '@takectrl-multiapp/svcs-share-config';
import { ICtrlResponse } from 'libs/svcs-ctrl-api/src/lib/interfaces/api.interface';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BUSNPolicyService {
  constructor(public firebase: AngularFireDatabase, private readonly apiPolicyService: APIPolicyService) {}

  async getPolicyBenefits(asset_type: AssetType, coverType: CoverType): Promise<IPolicyBenefit> {
    return await this.apiPolicyService.getPolicyBenefit(asset_type, coverType);
  }

  async getVapsPolicyBenefits(insurer_id: string, coverType: CoverType, productId: any): Promise<IPolicyBenefit> {
    return (await firstValueFrom(this.apiPolicyService.getVapsPolicyBenefit(insurer_id, coverType, productId))).results;
  }

  async getAllPolicyBenefits(coverType: CoverType = CoverType.Comprehensive): Promise<Array<IPolicyBenefit>> {
    return (await firstValueFrom(this.apiPolicyService.getAllPolicyBenefits(coverType))).results;
  }

  async getVehiclePolicyBenefit(coverType: CoverType = CoverType.Comprehensive): Promise<IPolicyBenefit> {
    return await this.apiPolicyService.getPolicyBenefit(AssetType.Vehicles, coverType);
  }

  async getMotorcyclePolicyBenefit(coverType: CoverType = CoverType.Comprehensive): Promise<IPolicyBenefit> {
    return await this.apiPolicyService.getPolicyBenefit(AssetType.Motorcycles, coverType);
  }

  async getCaravanPolicyBenefit(coverType: CoverType = CoverType.Comprehensive): Promise<IPolicyBenefit> {
    return await this.apiPolicyService.getPolicyBenefit(AssetType.Caravans, coverType);
  }

  async getT2wof(insurer_id: string, category: string): Promise<Array<IT2wof>> {
    const t2wofs = (await firstValueFrom(this.apiPolicyService.getT2wof())).results;

    const insurert2wof = t2wofs[insurer_id];
    const insurerCategoryt2wof = insurert2wof[category];

    return insurerCategoryt2wof;
  }

  async getInsurers(): Promise<Array<IInsurer>> {
    return (await firstValueFrom(this.apiPolicyService.getInsurers())).results;
  }

  async addClaim(claim: IClaim): Promise<void> {
    await firstValueFrom(this.apiPolicyService.addClaim(claim));
  }

  async getEmergencyNumbers(): Promise<Array<IEmergencyNumber>> {
    return (await firstValueFrom(this.apiPolicyService.getEmergencyNumber())).results;
  }

  async getDealerShips(b2b_client): Promise<Array<IDealerships>> {
    return (await firstValueFrom(this.apiPolicyService.getDealerships(b2b_client))).results;
  }

  async getClaimNumbers(): Promise<Array<IClaimNumber>> {
    return (await firstValueFrom(this.apiPolicyService.getClaimNumbers())).results;
  }

  async cancelAssetFromPolicy(cancelPolicyAsset: ICancelPolicyAsset): Promise<ICtrlResponse<unknown>> {
    return (await firstValueFrom(this.apiPolicyService.cancelAssetFromPolicy(cancelPolicyAsset)));
  }
}
