<div class="wrapper-no-height limit-width-on-desktop-float">
  <div class="heading">
    <h2 class="h2-bold">Update my email</h2>
    <p>
      Your current email address is <br />
      <b>{{ currentEmail }}</b>
    </p>
  </div>
  <div class="input-section">
    <ion-item mode="ios">
      <ion-label position="floating">New email address</ion-label>
      <ion-input type="email" name="emailNew" [(ngModel)]="newEmail" (ionInput)="checkEmail($event)"></ion-input>
    </ion-item>
    <p class="error-message" *ngIf="errorText">{{ errorText }}</p>
  </div>
  <div class="button-rows">
    <ion-button mode="ios" [disabled]="!enableButton" class="primary-button" (click)="changeDetails()">
      <span class="button-inner"> Confirm </span>
    </ion-button>
    <ion-button mode="ios" class="secondary-button" (click)="cancelScreen()"> Cancel </ion-button>
  </div>
</div>

<custom-popup *ngIf="showCustomPopup">
  <div class="heading">
    <h2 class="h2-bold">Enter password</h2>
  </div>
  <div class="sub-header">
    <p>Please enter your password to confirm your new email address : {{ newEmail }}</p>
  </div>
  <div class="input-wrapper">
    <ion-item mode="ios">
      <ion-label position="floating">Password</ion-label>
      <ion-input type="password" [(ngModel)]="passwordField"></ion-input>
    </ion-item>
  </div>
  <div class="buttons-wrapper">
    <ion-button mode="ios" class="primary-button" (click)="confirmEmailChange()"> Confirm </ion-button>
    <ion-button mode="ios" class="secondary-button" (click)="dismissCustomPopup()"> Cancel </ion-button>
  </div>
</custom-popup>

<popup-with-dismiss-button *ngIf="sharedConfig.showPopupWithDismissButton"></popup-with-dismiss-button>

<!-- TODO ALL OTHER POPUPS SHOULD BE REPLACED BY THIS GENERIC POPUP -->
<popup-dialog id="change.email.component" *ngIf="sharedConfig.popupDialogShow"></popup-dialog>
