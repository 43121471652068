import { Component, OnInit } from '@angular/core';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { SharedConfig } from '@takectrl-multiapp/svcs-share-config';
declare const dataLayer: any[];

@Component({
  selector: 'fsb-licence',
  templateUrl: './fsb-licence.component.html',
  styleUrls: ['./fsb-licence.component.scss'],
})
export class FsbLicenceComponent implements OnInit {
  public fsbImageUrl = this.sharedConfig.appSettings.fsb_certification_url;

  constructor(public thirdpartyService: BUSNThirdPartyService, public sharedConfig: SharedConfig) {}

  ngOnInit(): void {
    dataLayer.push({
      event: 'page_show',
      screenPath: 'fsb-licence',
      screenName: 'fsb-licence',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }
}
