/// <reference types="@types/googlemaps" />
import { AlertButton, AlertController, Platform } from '@ionic/angular';
import { BUSNQuestionService, BUSNUserService } from '@takectrl-multiapp/svcs-core-business';
import { Component, NgZone, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import {
  AssetType,
  SharedDialogService,
  IPostalCodeArea,
  IPostalCodes,
  IQuestion,
  SharedConfig,
  UnderwritingAnswer,
  AddressSelectorScreen,
  IPostalCodeAreas,
  IPostalDetails,
} from '@takectrl-multiapp/svcs-share-config';
import { PostalCodeType } from '@takectrl-multiapp/svcs-core';
import { APIHttpService } from 'libs/svcs-ctrl-api/src/lib/services/api-http.service';
import * as _ from 'lodash';

export interface IPostalCodeMatched {
  postal_code?: string;
  postal_code_id?: string;
  score?: number;
  suburb_name?: string;
}

export interface IPostalCode {
  line1?: string;
  line2?: string;
  matched?: {
    mnfg?: IPostalCodeMatched[];
    sa02?: IPostalCodeMatched[];
    sapo?: IPostalCodeMatched[];
  };
  postal_code?: string;
  unmatched?: {
    mnfg?: IPostalCodeMatched[];
    sa02?: IPostalCodeMatched[];
    sapo?: IPostalCodeMatched[];
  };
}
@Component({
  selector: 'input-text-address',
  templateUrl: './input-text-address.component.html',
  styleUrls: ['./input-text-address.component.scss'],
})
export class InputTextAddressComponent implements OnInit, AfterViewInit {
  @Input() question: IQuestion;
  @Input() showHeading: boolean;
  @Input() error: string;
  @Input() page: string;
  @Input() readOnly: boolean;
  @Output() answerQuestion: EventEmitter<IQuestion> = new EventEmitter();
  @ViewChild('streetAddressInput') streetInputOne;
  @ViewChild('postalInput') postalInputOne;
  AddressSelectorScreen = AddressSelectorScreen;

  public streetAddress: string;
  public postalCode: string;
  address: string;

  public postalCodeUnderwritingAnswer: UnderwritingAnswer;
  public addressLine1UnderwritingAnswer: UnderwritingAnswer;
  public addressLine2UnderwritingAnswer: UnderwritingAnswer;
  public postalCodeIdUnderwritingAnswer: UnderwritingAnswer;
  public postalCodeIdMNFGUnderwritingAnswer: UnderwritingAnswer;
  public postalCodeIdSA02UnderwritingAnswer: UnderwritingAnswer;

  postalCodes: IPostalCodeArea[];
  postalCodesMNFG: IPostalCodeArea[];
  postal_codes_sa02: IPostalCodeArea[];
  currentPostalCodes: IPostalCodeArea[];
  currentPostalResultsShown: PostalCodeType = 'None';
  chosenPostalCodes: IPostalCodes = {};
  showNothingFound = false;
  autocomplete: google.maps.places.Autocomplete;
  searchValue: string;
  searchTimedOut = false;
  searchedAddresses: any[] = [];
  postalResult: IPostalCode = {};
  inputTextAddressShowScreenID: string;

  keyDownCurrentIndex = 0;
  keyDownPressIndex = 0;

  enableAddressFreeText = false;
  showPostalResultsMNFG = false;
  showPostalResultsSA02 = false;
  checkedMNFG: boolean;
  checkedSA02: boolean;
  postalCodesMNFGSaveValue = [];
  postalCodesSA02SaveValue = [];
  selectedSearchResMNFG: any;
  objectNameType: AssetType;
  nothingFoundError = false;
  placeService: google.maps.places.PlacesService;
  partialPostalLookupList: IPostalCodeMatched[];
  postalCodeAreas: IPostalCodeAreas;

  constructor(
    private readonly alertController: AlertController,
    public httpService: APIHttpService,
    private readonly zone: NgZone,
    public sharedDialogService: SharedDialogService,
    public busnUserService: BUSNUserService,
    public busnQuestionService: BUSNQuestionService,
    public sharedConfig: SharedConfig,
    public platform: Platform
  ) {
    this.displaySuggestions = this.displaySuggestions.bind(this);
  }

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.question.underwriting_answers.forEach((a) => {
      this.objectNameType = this.question.underwriting_answers[0].object_name;
      switch (a.object_field) {
        case 'risk_address_line1':
          const addressCodeIndex = this.question.underwriting_answers.findIndex((x) => x.object_field === 'risk_address_code');
          if (addressCodeIndex >= 0) {
            this.initUnderwritingAnswers('risk_address', 'risk_address_code', 'risk_address_line1', 'risk_address_line2');
          } else {
            this.initUnderwritingAnswers('risk_address', 'postal_code', 'risk_address_line1', 'risk_address_line2');
          }
          break;
        case 'daytime_address_line1':
          this.initUnderwritingAnswers('daytime_postal', 'daytime_postal_code', 'daytime_address_line1', 'daytime_address_line2');
          break;
        case 'overnight_address_line2':
          this.initUnderwritingAnswers('overnight_postal', 'overnight_postal_code', 'overnight_address_line1', 'overnight_address_line2');
          break;
        default:
          break;
      }
    });

    if (this.question.underwriting_answers[0].object_answer && this.question.underwriting_answers[0].object_answer.length > 0) {
      this.streetAddress = this.question.underwriting_answers[0].object_answer;
      this.postalCode = `${this.question.underwriting_answers[1].object_answer}, ${this.question.underwriting_answers[2].object_answer}`;
    }
    this.changeScreen(AddressSelectorScreen.Main);
  }

  timeoutWhileTyping(type: string) {
    setTimeout(() => {
      this.keyDownCurrentIndex++;

      if (this.keyDownCurrentIndex === this.keyDownPressIndex) {
        if (type === 'address') {
          this.initiateGoogleMapsSearch();
        } else if (type === 'postal') {
          this.initiatePostalCodeSearch();
        }
      }
    }, 1000);
  }

  searchKeyDown(e: Event) {
    const value = e.target['value'];
    if (SharedConfig.inputTextAddressShowScreen === AddressSelectorScreen.PostalCode && !this.sharedConfig.inputTextAddressShowScreenOverrideToMain) {
      if (value.length === 4) {
        this.keyDownPressIndex++;
        this.timeoutWhileTyping('postal');
      }
    } else {
      if (value.length > 2) {
        this.address = value;
        this.keyDownPressIndex++;
        this.timeoutWhileTyping('address');
      }
    }
  }

  initiateGoogleMapsSearch() {
    let input: HTMLInputElement;
    if (SharedConfig.inputTextAddressShowScreen === AddressSelectorScreen.StreetAddress) {
      input = document.getElementById(`${this.question.underwriting_answers[0].object_field}address`) as HTMLInputElement;
    } else {
      input = document.getElementById(`${this.question.underwriting_answers[0].object_field}postal`) as HTMLInputElement;
    }

    const query = input.value;
    const service = new google.maps.places.AutocompleteService();
    this.placeService = new google.maps.places.PlacesService(
      document.getElementById(`${this.question.underwriting_answers[0].object_field}results`) as HTMLDivElement
    );
    service.getPlacePredictions({ input: query, componentRestrictions: { country: 'za' } }, this.displaySuggestions);
  }

  initiatePostalCodeSearch(postalCodeOverride?: string) {
    delete this.postalCodeAreas;
    this.postalCodeIdMNFGUnderwritingAnswer.object_answer = null;
    this.postalCodeIdSA02UnderwritingAnswer.object_answer = null;
    this.postalCodeIdUnderwritingAnswer.object_answer = null;
    let postalCode;
    if (!postalCodeOverride) {
      let input: HTMLInputElement;
      if (SharedConfig.inputTextAddressShowScreen === AddressSelectorScreen.StreetAddress) {
        input = document.getElementById(`${this.question.underwriting_answers[0].object_field}address`) as HTMLInputElement;
      } else {
        input = document.getElementById(`${this.question.underwriting_answers[0].object_field}postal`) as HTMLInputElement;
      }
      postalCode = input.value;
    } else {
      postalCode = postalCodeOverride;
    }

    this.sharedConfig.setShowCustomLoader(true);
    this.busnQuestionService.searchCityWithPostal(postalCode).then(async (_postalCodeAreas) => {
      this.postalCodeAreas = _postalCodeAreas;

      if (!this.postalCodeAreas) {
        const alert = await this.alertController.create({
          header: 'Backend is down',
          message: 'Please try again later',
          buttons: ['OK'],
        });
        await alert.present();
      }

      if (
        this.postalCodeAreas.matched.mnfg.length <= 0 ||
        this.postalCodeAreas.matched.sa02.length <= 0 ||
        this.postalCodeAreas.matched.sapo.length <= 0
      ) {
        const alert = await this.alertController.create({
          header: 'P.O. Box Codes not allowed',
          message: 'Please try agian with your residential code',
          buttons: ['OK'],
        });
        await alert.present();
      }
      this.sharedConfig.setShowCustomLoader(false);
    });
  }

  async selectPostalCodemnfg(postalDetails: IPostalDetails) {
    this.postalCodeIdMNFGUnderwritingAnswer.object_answer = postalDetails.postal_code_id;
    this.postalCodeUnderwritingAnswer.object_answer = postalDetails.postal_code;
    this.addressLine2UnderwritingAnswer.object_answer = postalDetails.suburb_name;

    const sa02Address = this.postalCodeAreas.matched.sa02.find(
      (x) =>
        x.postal_code.replace(/\s/g, '').toLowerCase() === postalDetails.postal_code.replace(/\s/g, '').toLowerCase() &&
        x.suburb_name.replace(/\s/g, '').toLowerCase() === postalDetails.suburb_name.replace(/\s/g, '').toLowerCase()
    );
    const sapoAddress = this.postalCodeAreas.matched.sapo.find(
      (x) =>
        x.postal_code.replace(/\s/g, '').toLowerCase() === postalDetails.postal_code.replace(/\s/g, '').toLowerCase() &&
        x.suburb_name.replace(/\s/g, '').toLowerCase() === postalDetails.suburb_name.replace(/\s/g, '').toLowerCase()
    );

    if (sapoAddress) {
      this.postalCodeIdUnderwritingAnswer.object_answer = sapoAddress.postal_code_id;
    }

    if (sa02Address) {
      this.postalCodeIdSA02UnderwritingAnswer.object_answer = sa02Address.postal_code_id;
    }

    this.postalCode = `${postalDetails.suburb_name}, ${postalDetails.postal_code}`;

    if (
      this.postalCodeIdMNFGUnderwritingAnswer.object_answer &&
      this.postalCodeIdSA02UnderwritingAnswer.object_answer &&
      this.postalCodeIdUnderwritingAnswer.object_answer
    ) {
      this.setUndwerwritingAnswers();
      this.changeScreen(AddressSelectorScreen.Main);
    } else {
      this.sharedDialogService.showPopupDialog({
        header: 'Additional information',
        subHeader: 'Some of our insurers can’t find your suburb, so please select the one nearest to you.',
        buttonOneText: 'OK',
        buttonOneCallback: this.sharedDialogService.hidePopupDialog,
      });
    }
  }

  async selectPostalCodesa02(postalDetails: IPostalDetails) {
    this.postalCodeIdSA02UnderwritingAnswer.object_answer = postalDetails.postal_code_id;

    const sapoAddress = this.postalCodeAreas.matched.sapo.find(
      (x) =>
        x.postal_code.replace(/\s/g, '').toLowerCase() === postalDetails.postal_code.replace(/\s/g, '').toLowerCase() &&
        x.suburb_name.replace(/\s/g, '').toLowerCase() === postalDetails.suburb_name.replace(/\s/g, '').toLowerCase()
    );
    if (sapoAddress) {
      this.postalCodeIdUnderwritingAnswer.object_answer = sapoAddress.postal_code_id;
    }
    this.postalCode = `${postalDetails.suburb_name}, ${postalDetails.postal_code}`;

    if (
      this.postalCodeIdUnderwritingAnswer.object_answer &&
      this.postalCodeIdSA02UnderwritingAnswer.object_answer &&
      this.postalCodeIdMNFGUnderwritingAnswer.object_answer
    ) {
      this.setUndwerwritingAnswers();
      this.changeScreen(AddressSelectorScreen.Main);
    } else {
      this.sharedDialogService.showPopupDialog({
        header: 'Additional information',
        subHeader: 'Some of our insurers can’t find your suburb, so please select the one nearest to you.',
        buttonOneText: 'OK',
        buttonOneCallback: this.sharedDialogService.hidePopupDialog,
      });
    }
  }

  selectPostalCodesapo(postalDetails: IPostalDetails) {
    this.postalCodeIdUnderwritingAnswer.object_answer = postalDetails.postal_code_id;

    if (
      this.postalCodeIdUnderwritingAnswer.object_answer &&
      this.postalCodeIdSA02UnderwritingAnswer.object_answer &&
      this.postalCodeIdMNFGUnderwritingAnswer.object_answer
    ) {
      this.setUndwerwritingAnswers();
    }
    this.postalCode = `${postalDetails.suburb_name}, ${postalDetails.postal_code}`;

    this.changeScreen(AddressSelectorScreen.Main);
  }

  displaySuggestions(predictions, status) {
    if (status !== google.maps.places.PlacesServiceStatus.OK) {
      document.getElementById(`${this.question.underwriting_answers[0].object_field}error`).style.display = 'block';
      document.getElementById(`${this.question.underwriting_answers[0].object_field}defaultNextButton`).style.display = 'none';
      this.searchedAddresses = [];
      return;
    }

    document.getElementById(`${this.question.underwriting_answers[0].object_field}errorPostalCode`).style.display = 'none';
    document.getElementById(`${this.question.underwriting_answers[0].object_field}error`).style.display = 'none';
    document.getElementById(`${this.question.underwriting_answers[0].object_field}defaultNextButton`).style.display = 'block';
    document.getElementById(`${this.question.underwriting_answers[0].object_field}results`).innerHTML = '';

    const arrPr = [];
    predictions.forEach((prediction) => {
      arrPr.push(this.getPredictionDetails(prediction.place_id));
    });

    Promise.all(arrPr)
      .then((results) => {
        this.searchedAddresses = [];
        const tempAddressArray = [];
        results.forEach((result) => {
          tempAddressArray.push(result);
        });
        this.zone.run(() => {
          this.searchedAddresses = tempAddressArray;
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getPredictionDetails(placeId) {
    const promise = new Promise((resolve, reject) => {
      this.placeService.getDetails(
        {
          placeId: placeId,
        },
        function (result, status) {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            resolve(result);
          } else {
            reject(status);
          }
        }
      );
    });

    return promise;
  }

  async selectAddress(addressResult: any, screen: AddressSelectorScreen) {
    this.sharedConfig.setShowCustomLoader(true);
    this.busnQuestionService.getAddressFromString(addressResult).then(async (data) => {
      this.postalResult = data as IPostalCodeAreas;

      if (!this.postalResult) {
        const alert = await this.alertController.create({
          header: 'Backend is down',
          message: 'Please try again later',
          buttons: ['OK'],
        });
        await alert.present();
      } else {
        if (!this.enableAddressFreeText) {
          if (!this.postalResult.line1 || this.postalResult.line1.length <= 0) {
            this.addressLine1UnderwritingAnswer.object_answer = this.streetAddress;
          } else {
            this.addressLine1UnderwritingAnswer.object_answer = this.streetAddress = this.postalResult.line1;
          }
        }

        this.addressLine2UnderwritingAnswer.object_answer = this.postalResult.line2;
        this.postalCodeUnderwritingAnswer.object_answer = this.postalResult.postal_code;
        this.postalCodeIdUnderwritingAnswer.object_answer =
          this.postalResult.matched.sapo && this.postalResult.matched.sapo.length > 0 ? this.postalResult.matched.sapo[0].postal_code_id : undefined;
        this.postalCodeIdSA02UnderwritingAnswer.object_answer =
          this.postalResult.matched.sa02 && this.postalResult.matched.sa02.length > 0 ? this.postalResult.matched.sa02[0].postal_code_id : undefined;
        this.postalCodeIdMNFGUnderwritingAnswer.object_answer =
          this.postalResult.matched.mnfg && this.postalResult.matched.mnfg.length > 0 ? this.postalResult.matched.mnfg[0].postal_code_id : undefined;

        this.postalCode = `${this.postalResult.line2}, ${this.postalResult.postal_code}`;

        if (
          this.postalCodeIdUnderwritingAnswer.object_answer &&
          this.postalCodeIdSA02UnderwritingAnswer.object_answer &&
          this.postalCodeIdMNFGUnderwritingAnswer.object_answer
        ) {
          this.setUndwerwritingAnswers();
        } else {
          delete this.postalCode;
        }

        this.changeScreen(AddressSelectorScreen.Main);
        this.searchedAddresses = [];
      }

      this.sharedConfig.setShowCustomLoader(false);
    });
  }

  clearInput(type: AddressSelectorScreen) {
    if (type === AddressSelectorScreen.StreetAddress) {
      this.streetAddress = null;
      this.address = null;
      this.searchedAddresses = [];
    } else {
      this.postalCode = null;
      delete this.postalCodeAreas;
    }
  }

  public changeScreen(screen: AddressSelectorScreen) {
    SharedConfig.inputTextAddressShowScreenOverrideToMain = false;
    SharedConfig.inputTextAddressShowScreen = screen;
    this.inputTextAddressShowScreenID = this.question.underwriting_answers[0].object_field + screen;
    if (screen === AddressSelectorScreen.StreetAddress) {
      setTimeout(() => this.streetInputOne.setFocus(), 300);
    } else if (screen === AddressSelectorScreen.PostalCode) {
      setTimeout(() => this.postalInputOne.setFocus(), 300);
    }
  }

  addressNotFound() {
    // It shouldnt set enableAddressFreeText true here, if user chooses on of the provided addresses on the list it should override the user typed in one.
    this.postalCode = null;
    this.postalCodeIdUnderwritingAnswer.object_answer = null;
    this.addressLine1UnderwritingAnswer.object_answer = this.streetAddress = this.address.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
      letter.toUpperCase()
    );
    this.changeScreen(AddressSelectorScreen.Main);
  }

  initUnderwritingAnswers(genericField: string, postalCodeField: string, addressLine1Field: string, addressLine2Field: string): void {
    this.postalCodeUnderwritingAnswer = this.question.underwriting_answers.find((q) => q.object_field === postalCodeField);
    this.addressLine1UnderwritingAnswer = this.question.underwriting_answers.find((q) => q.object_field === addressLine1Field);
    this.addressLine2UnderwritingAnswer = this.question.underwriting_answers.find((q) => q.object_field === addressLine2Field);

    this.postalCodeIdUnderwritingAnswer = this.question.underwriting_answers.find((q) => q.object_field.endsWith('_code_id'));
    this.postalCodeIdSA02UnderwritingAnswer = this.question.underwriting_answers.find((q) => q.object_field.includes('_code_id_mnfg'));
    this.postalCodeIdMNFGUnderwritingAnswer = this.question.underwriting_answers.find((q) => q.object_field.includes('_code_id_mnfg'));

    if (this.addressLine2UnderwritingAnswer) {
      this.objectNameType = this.addressLine2UnderwritingAnswer.object_name;
    }
    this.postalCodeUnderwritingAnswer.object_answer =
      this.postalCodeUnderwritingAnswer.object_answer === undefined ? null : this.postalCodeUnderwritingAnswer.object_answer;
    this.addressLine1UnderwritingAnswer.object_answer =
      this.addressLine1UnderwritingAnswer.object_answer === undefined ? null : this.addressLine1UnderwritingAnswer.object_answer;
    this.addressLine2UnderwritingAnswer.object_answer =
      this.addressLine2UnderwritingAnswer.object_answer === undefined ? null : this.addressLine2UnderwritingAnswer.object_answer;

    if (!this.postalCodeIdUnderwritingAnswer) {
      this.postalCodeIdUnderwritingAnswer = {
        object_name: this.objectNameType,
        object_field: `${genericField}_code_id`,
        object_answer: null,
      };
    }

    if (!this.postalCodeIdSA02UnderwritingAnswer) {
      this.postalCodeIdSA02UnderwritingAnswer = {
        object_name: this.objectNameType,
        object_field: `${genericField}_code_id_sa02`,
        object_answer: null,
      };
    }

    if (!this.postalCodeIdMNFGUnderwritingAnswer) {
      this.postalCodeIdMNFGUnderwritingAnswer = {
        object_name: this.objectNameType,
        object_field: `${genericField}_code_id_mnfg`,
        object_answer: null,
      };
    }

    // removed the "hack" not sure what unbroke now.
    // if (
    //   this.postalCodeUnderwritingAnswer.object_answer === null ||
    //   this.addressLine1UnderwritingAnswer.object_answer === null ||
    //   this.addressLine2UnderwritingAnswer.object_answer === null ||
    //   this.postalCodeIdMNFGUnderwritingAnswer.object_answer === null ||
    //   this.postalCodeIdUnderwritingAnswer.object_answer === null ||
    //   this.postalCodeIdSA02UnderwritingAnswer.object_answer === null
    // ) {
    // this.postalCodeUnderwritingAnswer.object_answer = null;
    // this.addressLine1UnderwritingAnswer.object_answer = null;
    // this.addressLine2UnderwritingAnswer.object_answer = null;
    // this.postalCodeIdUnderwritingAnswer.object_answer = null;
    // this.postalCodeIdSA02UnderwritingAnswer.object_answer = null;
    // this.postalCodeIdMNFGUnderwritingAnswer.object_answer = null;

    // this.sharedConfig.hackBecauseOfAddressOnlyReturningThreeFieldsShouldClearAll = true;
    //  }

    this.objectNameType = this.addressLine2UnderwritingAnswer.object_name;
  }

  setUndwerwritingAnswers() {
    this.question.underwriting_answers = [
      this.addressLine1UnderwritingAnswer,
      this.addressLine2UnderwritingAnswer,
      this.postalCodeUnderwritingAnswer,
      this.postalCodeIdUnderwritingAnswer,
      this.postalCodeIdSA02UnderwritingAnswer,
      this.postalCodeIdMNFGUnderwritingAnswer,
    ];
    console.log(this.question);
    if (this.addressLine1UnderwritingAnswer.object_answer && this.addressLine2UnderwritingAnswer.object_answer) {
      this.answerQuestion.emit(this.question);
    }
  }

  async showGenericAlert(header: string, subHeader: string, buttons: Array<string | AlertButton>) {
    const callAlert = await this.alertController.create({
      header,
      subHeader,
      buttons,
    });
    await callAlert.present();
  }
}
