<div class="wrapper limit-width-on-desktop-float" *ngIf="!showSelectionPopup">
  <div class="header">
    <h2 class="question-heading">Before accepting a quote from Absa, you just need to verify the following by selecting from the drop-down menus.</h2>
  </div>
  <div class="form">
    <ion-item (click)="openPopup('cob', 'Country of birth')" mode="ios">
      <ion-label position="floating">Country of birth</ion-label>
      <ion-input class="bold-text" readonly="true" [(ngModel)]="cobDesc"></ion-input>
      <ion-icon slot="end" class="end-icon" name="chevron-down"></ion-icon>
    </ion-item>
    <ion-item (click)="openPopup('cor', 'Country of residence')" mode="ios">
      <ion-label position="floating">Country of residence</ion-label>
      <ion-input class="bold-text" readonly="true" [(ngModel)]="corDesc"></ion-input>
      <ion-icon slot="end" class="end-icon" name="chevron-down"></ion-icon>
    </ion-item>
    <ion-item (click)="openPopup('nat', 'Nationality')" mode="ios">
      <ion-label position="floating">Nationality</ion-label>
      <ion-input class="bold-text" readonly="true" [(ngModel)]="nationalityDesc"></ion-input>
      <ion-icon slot="end" class="end-icon" name="chevron-down"></ion-icon>
    </ion-item>
    <ion-item (click)="openPopup('soi', 'Source of income')" mode="ios">
      <ion-label position="floating">Source of income</ion-label>
      <ion-input class="bold-text" readonly="true" [(ngModel)]="sourceOfIncomeDesc"></ion-input>
      <ion-icon slot="end" class="end-icon" name="chevron-down"></ion-icon>
    </ion-item>
    <ion-item (click)="openPopup('emp', 'Employment status')" mode="ios">
      <ion-label position="floating">Employment status</ion-label>
      <ion-input class="bold-text" readonly="true" [(ngModel)]="empDesc"></ion-input>
      <ion-icon slot="end" class="end-icon" name="chevron-down"></ion-icon>
    </ion-item>
    <ion-item (click)="openPopup('occ', 'Occupation')" mode="ios">
      <ion-label position="floating">Occupation</ion-label>
      <ion-input class="bold-text" readonly="true" [(ngModel)]="occupationDesc"></ion-input>
      <ion-icon slot="end" class="end-icon" name="chevron-down"></ion-icon>
    </ion-item>
  </div>
</div>
<div class="footer-button-wrapper">
  <div class="fade"></div>
  <div class="footer-button-background">
    <ion-button
      mode="ios"
      class="footer-button limit-width-desktop-on-button"
      [disabled]="!occupationDesc || !empDesc || !sourceOfIncomeDesc || !nationalityDesc || !corDesc || !cobDesc"
      expand="block"
      (click)="submitCasaDetails()"
      >Next
    </ion-button>
  </div>
</div>

<popup-with-dismiss-button *ngIf="sharedConfig.showPopupWithDismissButton"></popup-with-dismiss-button>

<div class="backdrop" *ngIf="showSelectionPopup"></div>
<div class="popup-dates limit-width-on-desktop-float" *ngIf="showSelectionPopup">
  <div class="text-center">
    <h2 class="h2-bold">{{ selectPopupHeader }}</h2>
  </div>
  <div *ngIf="loopThrough === 'cob'" class="dates">
    <div class="date-wrapper" *ngFor="let cob of cobList" (click)="changeCOB(cob.casaCountryDesc)">
      {{ cob.casaCountryDesc }}
    </div>
  </div>
  <div *ngIf="loopThrough === 'cor'" class="dates">
    <div class="date-wrapper" *ngFor="let cor of corList" (click)="changeCOR(cor.casaCountryDesc)">
      {{ cor.casaCountryDesc }}
    </div>
  </div>
  <div *ngIf="loopThrough === 'nat'" class="dates">
    <div class="date-wrapper" *ngFor="let nationality of nationalityList" (click)="changeNationality(nationality.casaNationalityDesc)">
      {{ nationality.casaNationalityDesc }}
    </div>
  </div>
  <div *ngIf="loopThrough === 'soi'" class="dates">
    <div class="date-wrapper" *ngFor="let soi of incomeList" (click)="changeSourceOfIncome(soi.casaSourceOfIncomeDesc)">
      {{ soi.casaSourceOfIncomeDesc }}
    </div>
  </div>
  <div *ngIf="loopThrough === 'emp'" class="dates">
    <div class="date-wrapper" *ngFor="let empStatus of empStatusList" (click)="changeEmploymentStatus(empStatus.casaEmploymentStatusDesc)">
      {{ empStatus.casaEmploymentStatusDesc }}
    </div>
  </div>
  <div *ngIf="loopThrough === 'occ'" class="dates">
    <div class="date-wrapper" *ngFor="let occupation of occupationList" (click)="changeOccupation(occupation.casaOccupationDesc)">
      {{ occupation.casaOccupationDesc }}
    </div>
  </div>
</div>

<!-- TODO ALL OTHER POPUPS SHOULD BE REPLACED BY THIS GENERIC POPUP -->
<popup-dialog id="quote.banking.details.component" *ngIf="sharedConfig.popupDialogShow"></popup-dialog>
