import { Component, OnInit, ViewChild } from '@angular/core';
import { SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import { PopoverController } from '@ionic/angular';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { BUSNCommunicationService } from 'libs/svcs-core-business/src/lib/services/busn-communication.service';

@Component({
  selector: 'chat-button',
  templateUrl: './chat-button.component.html',
  styleUrls: ['./chat-button.component.scss'],
})
export class ChatButtonComponent implements OnInit {
  @ViewChild('popover') popover;
  href_string: string =
    'mailto:?to=support@ctrltechnologies.co.za&subject=' +
    this.sharedConfig.appClientDetail.display_name +
    ' - ' +
    this.sharedConfig.user?.personal_details?.name +
    ' ' +
    this.sharedConfig.user?.personal_details?.surname +
    ' technical support query';
  isOpen = false;
  constructor(
    public busnThirdPartyService: BUSNThirdPartyService,
    public sharedConfig: SharedConfig,
    public popoverCtrl: PopoverController,
    public busnCommunicationService: BUSNCommunicationService
  ) {}

  ngOnInit(): void {}

  presentPopover(e: Event) {
    this.popover.event = e;
    this.isOpen = true;
  }

  callBroker() {
    this.isOpen = false;
    this.busnCommunicationService.callBroker();
  }

  openIntercom() {
    this.busnThirdPartyService.intercomDisplayLauncher();
  }
}
