export enum InputType {
  decimal = 'decimal',
  email = 'email',
  none = 'none',
  number = 'number',
  search = 'search',
  tel = 'tel',
  text = 'text',
  url = 'url',
  idNumber = 'idNumber',
  undefined = 'undefined',
}

export enum QuestionType {
  select = 'SELECT',
  selectSearch = 'SELECTSEARCH',
  text = 'TEXT',
  number = 'NUMBER',
  boolean = 'BOOLEAN',
  address = 'ADDRESS',
  vehicleSearch = 'VEHICLESEARCH',
  search = 'SEARCH',
  multi = 'MULTI',
  date = 'DATE',
  dateTime = 'DATETIME',
  multiSelect = 'MULTISELECT',
  claimList = 'CLAIMLIST',
}

export enum VehicleType {
  motorcycles = 'motorcycles',
  caravans = 'caravans',
  vehicles = 'vehicles',
}

export enum QuestionDependencyType {
  parent = 'Parent',
  child = 'Child',
}

export enum QuestionFilteringType {
  hideHiddenQuestionsForSummary = 'hideHiddenQuestionsForSummary',
  getHiddenQuestionsForQuestionPage = 'getHiddenQuestionsForQuestionPage',
}
