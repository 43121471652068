export enum PageType {
  login = 'login',
  register = 'register',
  changeEmail = 'change-email',
  wallet = 'wallet',
  homeQuote = 'home-quote',
  homeWallet = 'home-wallet',
  quoteNew = 'quote-new',
  quoteQuestions = 'quote-questions',
  questions = 'questions',
  questionRbMaps = 'question-rb-maps',
  animationPleaseWait = 'animation-please-wait',
  summary = 'summary',
  quoteEndorsementAccept = 'quote-endorsement-accept',
  claim = 'claim'
}