<div class="wrapper bottom-padding">
  <div class="heading limit-width-on-desktop-float" *ngIf="this.quoteType !== QuoteType.MyProfile">
    <h2 class="h2-bold">{{ header }}</h2>
    <p>{{ subHeader }}</p>
  </div>
  <div class="card limit-width-on-desktop-float" *ngFor="let group of questionGroup" [ngClass]="{ 'profile-card': this.quoteType === QuoteType.MyProfile }">
    <div class="section-heading" *ngIf="this.quoteType !== QuoteType.MyProfile">
      <h3>{{ group.headings }}</h3>
    </div>
    <div *ngFor="let questionAnswers of group.answeredQuestions">
      <div [ngSwitch]="questionAnswers?.type">
        <div *ngSwitchCase="QuestionType.vehicleSearch">
          <div class="questions-wrapper">
            <label>Year</label>
            <p>
              <b>{{ vehicleYear }}</b>
            </p>
          </div>
          <div class="questions-wrapper">
            <label>Make</label>
            <p>
              <b>{{ vehicleMake }}</b>
            </p>
          </div>
          <div class="questions-wrapper">
            <label>Model</label>
            <p>
              <b>{{ vehicleModelSeries }}</b>
            </p>
          </div>
          <div class="questions-wrapper">
            <label>Variant</label>
            <p>
              <b>{{ vehicleModel }}</b>
            </p>
          </div>
        </div>
        <div *ngSwitchCase="QuestionType.address">
          <div class="questions-wrapper">
            <label>{{ questionAnswers.wording_for_summary }}</label>
            <p>
              <b>{{ questionAnswers.underwriting_answers[0].object_answer }}</b>
            </p>
            <p>
              <b>{{ questionAnswers.underwriting_answers[1].object_answer }}</b>
            </p>
            <p>
              <b>{{ questionAnswers.underwriting_answers[2].object_answer }}</b>
            </p>
          </div>
        </div>
        <div *ngSwitchCase="QuestionType.boolean">
          <div class="questions-wrapper" *ngFor="let answer of questionAnswers.underwriting_answers">
            <label *ngIf="questionAnswers.wording_for_summary">{{ questionAnswers.wording_for_summary }}</label>
            <label *ngIf="!questionAnswers.wording_for_summary">{{ answer.place_holder }}</label>
            <p *ngIf="answer.object_answer === true"><b>Yes</b></p>
            <p *ngIf="answer.object_answer === false"><b>No</b></p>
          </div>
        </div>
        <div *ngSwitchCase="'CLAIMLIST'">
          <div class="questions-wrapper" *ngFor="let underwritingAnswer of questionAnswers.underwriting_answers">
            <label>{{ questionAnswers.wording_for_summary }}</label>
            <div *ngFor="let object_answer of underwritingAnswer.object_answer">
              <ion-item class="claims" lines="none" mode="ios">
                <ion-label slot="start" class="label-event"
                  ><b>{{ object_answer?.reason }}</b></ion-label
                >
                <ion-label *ngIf="object_answer.reason !== 'No claims'" slot="end" class="label-date">{{ setDateFormat(object_answer?.date_full) }}</ion-label>
              </ion-item>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="QuestionType.multiSelect">
          <h5 *ngIf="questionAnswers.wording_for_summary">{{ questionAnswers.wording_for_summary }}</h5>
          <div class="questions-wrapper mt-4" *ngFor="let answer of questionAnswers.underwriting_answers">
            <label *ngIf="!answer.place_holder">{{ questionAnswers.wording_for_summary }}</label>
            <label *ngIf="answer.place_holder">{{ answer.place_holder }}</label>
            <p *ngIf="answer.object_answer === true"><b>Yes</b></p>
            <p *ngIf="answer.object_answer === false"><b>No</b></p>
          </div>
        </div>
        <div *ngSwitchCase="QuestionType.dateTime">
          <div class="questions-wrapper" *ngFor="let answer of questionAnswers.underwriting_answers">
            <label>{{ questionAnswers.wording_for_summary }}</label>
            <p>
              <b>{{ answer.object_answer }}</b>
            </p>
          </div>
        </div>
        <div *ngSwitchCase="QuestionType.date">
          <div class="questions-wrapper" *ngFor="let answer of questionAnswers.underwriting_answers">
            <label>{{ questionAnswers.wording_for_summary }}</label>
            <p>
              <b>{{ answer.object_answer }}</b>
            </p>
          </div>
        </div>
        <div *ngSwitchDefault>
          <div class="questions-wrapper" *ngFor="let answer of questionAnswers.underwriting_answers">
            <label *ngIf="questionAnswers.wording_for_summary">{{ questionAnswers.wording_for_summary }}</label>
            <label *ngIf="!questionAnswers.wording_for_summary">{{ answer.place_holder }}</label>
            <p>
              <b>{{ answer.object_answer }}</b>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="button-row">
      <ion-button *ngIf="quoteType !== QuoteType.ViewAssetOnly" mode="ios" class="secondary-button" (click)="edit(group.headings)"
        >Edit&nbsp;<i>{{ group.headings }}</i></ion-button
      >
    </div>
  </div>
</div>
<div class="footer-button-wrapper" *ngIf="quoteType !== QuoteType.MyProfile">
  <div class="fade"></div>
  <div class="footer-button-background">
    <ion-button mode="ios" class="footer-button limit-width-desktop-on-button" expand="block" (click)="confirm()"> Looks good! </ion-button>
  </div>
</div>

<small-popup *ngIf="showUpdatePopup" [header]="popUpHeading" [message]="popupMessage">
  <ion-button mode="ios" class="primary-button" (click)="showIntercom()">Contact us </ion-button>
  <ion-button mode="ios" class="secondary-button" (click)="closePopup()">Cancel </ion-button>
</small-popup>

<!-- TODO ALL OTHER POPUPS SHOULD BE REPLACED BY THIS GENERIC POPUP -->
<popup-dialog id="quote.summary.not.editible.component" *ngIf="sharedConfig.popupDialogShow"></popup-dialog>
