import { Injectable } from '@angular/core';
import { BUSNQuestionService, BUSNUserService } from '@takectrl-multiapp/svcs-core-business';
import { IUser, SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import { SharedConfigService } from 'libs/svcs-share-config/src/lib/services/shared-config.service';
import { Observable, Observer, firstValueFrom, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TestingService {
  constructor(
    public busnUserService: BUSNUserService,
    public sharedConfig: SharedConfig,
    public sharedConfigService: SharedConfigService,
    public busnQuestionService: BUSNQuestionService
  ) {}

  init() {}

  monitorObservable<T>(observable: Observable<T>, label: string = 'Observable'): void {
    const observer: Observer<T> = {
      next: (value) => {
        const formattedValue = typeof value === 'object' && value !== null ? JSON.stringify(value, null, 2) : value;
        console.log(`[${new Date().toISOString()}] ${label} emitted value: ${formattedValue}`);
      },
      error: (error) => console.error(`[${new Date().toISOString()}] ${label} encountered an error: ${error}`),
      complete: () => console.log(`[${new Date().toISOString()}] ${label} completed.`),
    };

    observable.subscribe(observer);
  }

  isTester(): boolean {
    return true;
  }

  generateRandomUniqueFakeEmail(): string {
    const domains = ['fakectrl.com'];
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';

    function getRandomString(length) {
      let result = '';
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return result;
    }

    function getRandomDomain() {
      return domains[Math.floor(Math.random() * domains.length)];
    }

    const username = getRandomString(5); // Generate a random string of length 5 for the username
    const timestamp = Date.now(); // Get the current Unix timestamp
    const domain = getRandomDomain();

    return `${timestamp}@${domain}`;
  }

  generateRandomName(): string {
    const names = [
      'John',
      'Jane',
      'Michael',
      'Emily',
      'Chris',
      'Hannah',
      'David',
      'Emma',
      'Daniel',
      'Olivia',
      'Matthew',
      'Sophia',
      'Andrew',
      'Isabella',
      'Joshua',
      'Ava',
      'James',
      'Mia',
    ];

    return names[Math.floor(Math.random() * names.length)];
  }

  generateRandomSurname(): string {
    const surnames = [
      'Smith',
      'Johnson',
      'Williams',
      'Brown',
      'Jones',
      'Garcia',
      'Miller',
      'Davis',
      'Rodriguez',
      'Martinez',
      'Hernandez',
      'Lopez',
      'Gonzalez',
      'Wilson',
      'Anderson',
      'Thomas',
      'Moore',
      'Martin',
      'Jackson',
      'Thompson',
    ];

    return surnames[Math.floor(Math.random() * surnames.length)];
  }

  async setTestingUserDetails(name: string, surname: string) {
    // Setting sharedConfig user details synchronously
    const testUser: IUser = {...this.sharedConfig.user}
    
   testUser.personal_details.user_id = this.sharedConfig.user.id;
   testUser.personal_details.id_nr = '8806044800086';
   testUser.personal_details.cell_nr = '0988888888';
   testUser.personal_details.is_completed = true;
   testUser.personal_details.name = name;
   testUser.personal_details.surname = surname;
   testUser.personal_details.conducting_business_from_home = false;
   testUser.personal_details.marital_status = 'Single';
   testUser.insurance_history.user_id = this.sharedConfig.user.id;
   testUser.insurance_history.claims_in1_year = '0';
   testUser.insurance_history.claims_in5_years = '0';
   testUser.insurance_history.convicted_of_offence = false;
   testUser.insurance_history.created_time = '2024-05-30T10:56:02.401064';
   testUser.insurance_history.driving_offence = null;
   testUser.insurance_history.has_an_application_for_insurance_been_declined = false;
   testUser.insurance_history.has_an_insurer_applied_special_conditions = false;
   testUser.insurance_history.has_insurance_declined_cancelled_refused_imposed_conditions = false;
   testUser.insurance_history.has_policy_lapsed_due_to_non_payment = false;
   testUser.insurance_history.insurance_cancelled_refused = false;
   testUser.insurance_history.length_of_continuous_previous_insurance = 'More than 12 months';
   testUser.insurance_history.length_of_previous_insurance = 20;
   testUser.insurance_history.litigation_or_judgement = false;
   testUser.insurance_history.losses_in3_years = '0';
   testUser.insurance_history.modified_time = '2024-05-30T11:35:58.968946';
   testUser.insurance_history.period = 'Monthly';
   testUser.insurance_history.previous_insurer = 'Unknown';
   testUser.insurance_history.previous_insurer_expiry_date = '2023-07-01T00:00:00';
   testUser.insurance_history.previous_policy_number = 'TBA';
   testUser.insurance_history.renewal_period = 'Automatic';
   testUser.insurance_history.uninterrupted_cover = '7 years and more';

    // Address setup
    const address = {
      postal_code: '1692',
      risk_address_code_id: '1692:Midstream Estate:0001_SAPO',
      risk_address_code_id_mnfg: '1692:Midstream Estate:0001_MNFG',
      risk_address_code_id_sa02: '1692:Midstream Estate Ext 2:0000_SA02',
      risk_address_line1: '12 Brentford Crescent',
      risk_address_line2: 'Midstream Estate',
      user_id: this.sharedConfig.user.id,
    };


    const addressRequest = await this.busnQuestionService.setAddress(address);

    testUser.address_id = addressRequest.id; // TODO SHARED CONFIG REMOVE
    testUser.addresses = addressRequest; // TODO SHARED CONFIG REMOVE

    // Converting Observable to promise
    //const user = await firstValueFrom(this.sharedConfigService.user$.pipe(take(1)));

    this.sharedConfig.setUser(testUser) 
    this.sharedConfigService.setUser(testUser) 

    // Awaiting all user update requests
    await Promise.all([
      this.busnUserService.updateUser(testUser),
      this.busnUserService.updatePersonalDetails(testUser.personal_details),
      this.busnUserService.updateUserInsuranceHistory(testUser.insurance_history),
    ]);
    const ddd = testUser.personal_details
  }
}
