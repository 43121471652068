import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { InputType, SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import { UIAppInitializationService } from '../../services/ui-app-initialization.service';

declare const dataLayer: unknown[];

@Component({
  selector: 'set-new-password',
  templateUrl: './set-new-password.component.html',
  styleUrls: ['./set-new-password.component.scss'],
})
export class SetNewPasswordComponent implements OnInit {
  password: string;
  public enableSetPasswordButton = false;
  public passwordType = 'password';
  public passwordIcon = 'view';
  public showRegisterSuccess: boolean = false;

  // @Output() finishedPasswordUpdate: EventEmitter<unknown> = new EventEmitter<unknown>();

  constructor(public sharedConfig: SharedConfig, public uiAppInitializationService: UIAppInitializationService) {}

  ngOnInit(): void {
    dataLayer.push({
      event: 'page_show',
      screenPath: 'set-new-password',
      screenName: 'set-new-password',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }

  async ngAfterViewInit(): Promise<void> {}

  passwordFieldOnChange() {
    if (this.password.length > 5) {
      this.enableSetPasswordButton = true;
    } else {
      this.enableSetPasswordButton = false;
    }
  }

  showPassword() {
    this.passwordType = this.passwordType === InputType.text ? 'password' : InputType.text;
    this.passwordIcon = this.passwordIcon === 'view-close' ? 'view' : 'view-close';
  }

  setNewPassword() {
    try {
      this.sharedConfig.setShowCustomLoader(true);
      delete SharedConfig.routeParamUserMsisdn;
      delete SharedConfig.showSetNewPasswordButtonOnLogin;

      // No idea why email was updated here. User gets signin request via email. NO need to update here.
      // await SharedConfig.firebaseUser.updateEmail(this.sharedConfig.user.personal_details.email).catch((error) => {});
      this.sharedConfig.firebaseUser.updatePassword(this.password).then((_) => {
        SharedConfig.showSetNewPasswordComponent = false;

        const routeParamUseEmail = SharedConfig.routeParamUserEmail;
        this.uiAppInitializationService.authAuthenticate(routeParamUseEmail, this.password).then((_) => {
          delete SharedConfig.routeParamUserEmail;
          this.uiAppInitializationService.hotReloadCore().then((_) => {
            SharedConfig.hideHeader = false;
            this.sharedConfig.setShowCustomLoader(false);
            SharedConfig.showLottieFullScreen = false;
            SharedConfig.showFullScreen = false;
            this.showRegisterSuccess = true;
            // this.finishedPasswordUpdate.emit();
          });
        });
      });
    } catch (error) {
      SharedConfig.showLottieFullScreen = false;
      SharedConfig.showFullScreen = false;
      // this.finishedPasswordUpdate.emit();
    }
  }
}
