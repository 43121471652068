import { Injectable } from '@angular/core';
import { APIHttpService } from './api-http.service';
import { Observable } from 'rxjs';
import { ICtrlResponse } from '../interfaces/api.interface';
import { IBroker, IBrokerage } from '@takectrl-multiapp/svcs-share-config';

@Injectable({
  providedIn: 'root',
})
export class APIBrokerService {
  constructor(private readonly ctrlApiHttpService: APIHttpService) {}

  getBrokers(brokerName: string): Observable<ICtrlResponse<IBroker[]>> {
    return this.ctrlApiHttpService.get(`/brokers/search?searchTerm=${brokerName}&cutoff=50`, {});
  }

  getBrokerage(): Observable<ICtrlResponse<IBrokerage>> {
    return this.ctrlApiHttpService.get(`/brokerage/`, {});
  }
}
