import { Injectable } from '@angular/core';
import {
  IQuestion,
  IOccupations,
  IAddresses,
  IPostalCodeAreas,
  AssetType,
  QuestionType,
  SharedConfig,
} from '@takectrl-multiapp/svcs-share-config';
import { APIHttpService } from './api-http.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { ICtrlResponse } from '../interfaces/api.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class APIQuestionService {
  occupation: IOccupations;
  constructor(private readonly ctrlApiHttpService: APIHttpService, public firebase: AngularFireDatabase) {}

  async getUnderwritingQuestions(): Promise<Array<IQuestion>> {
    const response = await this.firebase.database.ref('underwriting_questions/').once('value');
    const questions = response.val() as Array<IQuestion>;

    for (const question of questions) {
      if (question.type === QuestionType.boolean) {
        question.options = ['Yes', 'No'];
      }

      if (
        question.sub_heading &&
        (question.sub_heading.includes('What is the address where the vehicle is parked during the day? ') ||
          question.sub_heading.includes('What is the address where the vehicle is parked overnight? ') ||
          question.sub_heading.includes('What is the address where the motorcycle is parked overnight?') ||
          question.sub_heading.includes('What is the address where the caravan/trailer is parked overnight?') ||
          question.sub_heading.includes('What is the residential address of the building you want to insure?') ||
          question.sub_heading.includes('What is the residential address where the content you want to insure is held?') ||
          question.sub_heading.includes('Where do you live, your physical address? Note that this should not be a postal address.'))
      ) {
        question.type = QuestionType.address;
      }

      if (
        (question.sub_heading === 'What is the make of your vehicle?' ||
          question.sub_heading === 'What is the model of your vehicle?' ||
          question.sub_heading === 'In which year was it manufactured?' ||
          question.sub_heading === 'What is the make of your motorcycle?') &&
        (question.underwriting_answers[0].object_name === AssetType.Vehicles ||
          question.underwriting_answers[0].object_name === AssetType.Motorcycles)
      ) {
        question.type = QuestionType.vehicleSearch;
      }

      if (
        question.sub_heading === 'How many buildings claims did you have in the last...' ||
        question.sub_heading === 'How many motorcycle claims did the regular driver have in the last...' ||
        question.sub_heading === 'How many content-related claims did you have in the last...' ||
        question.sub_heading === 'How many caravan/trailer claims did you have in the last...'
      ) {
        question.type = QuestionType.multi;
      }

      if (question.sub_heading === 'What is your occupation?') {
        question.type = QuestionType.search;
      }
    }
    return questions;
  }

  setAddress(address: IAddresses): Observable<ICtrlResponse<IAddresses>> {
    return this.ctrlApiHttpService.post(`/addresses/`, address);
  }

  // this has not yet been refactored  by backend
  // https://ctrltech.atlassian.net/browse/CT-6029
  getCarMake(): Observable<any> {
    return this.ctrlApiHttpService.get(`/motorvehicles/makes/`);
  }

  // this has not yet been refactored  by backend
  // https://ctrltech.atlassian.net/browse/CT-6029
  getMotorcycleMake(): Observable<any> {
    return this.ctrlApiHttpService.get(`/motorcycles/makes/`);
  }

  // this has not yet been refactored  by backend
  // https://ctrltech.atlassian.net/browse/CT-6029
  getCarModel(make: string, year: string): Observable<any> {
    return this.ctrlApiHttpService.get(`/motorvehicles/makes/${encodeURIComponent(make)}/models/${year}`);
  }

  // this has not yet been refactored  by backend
  // https://ctrltech.atlassian.net/browse/CT-6029
  getMotorcycleModel(make: string, year: string): Observable<any> {
    return this.ctrlApiHttpService.get(`/motorcycles/makes/${encodeURIComponent(make)}/models/${year}`);
  }

  // this has not yet been refactored  by backend
  // https://ctrltech.atlassian.net/browse/CT-6029
  getCarModelVariant(make: string, year: string, model: string): Observable<any> {
    return this.ctrlApiHttpService.get(`/motorvehicles/model/details/?year=${year}&make=${make}&model=${model}`);
  }

  // this has not yet been refactored  by backend
  // https://ctrltech.atlassian.net/browse/CT-6029
  getMotorcycleModelVariant(make: string, year: string, model: string): Observable<any> {
    return this.ctrlApiHttpService.get(`/motorcycles/model/details/?year=${year}&make=${make}&model=${model}`);
  }

  getPostalCodeAreas(code: string): Observable<ICtrlResponse<IPostalCodeAreas>> {
    return this.ctrlApiHttpService.post(
      `${SharedConfig.appCredentials.postal_search_api_url}/v1/address/postal`,
      { postal_code: code, suburb: '' },
      null
    );
  }

  getAddressFromString(addressResult: any): Observable<ICtrlResponse<IPostalCodeAreas>> {
    return this.ctrlApiHttpService.post(`${SharedConfig.appCredentials.postal_search_api_url}/v1/address`, addressResult, null);
  }
}
