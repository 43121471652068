<div class="backdrop"></div>
<div class="popup-standard limit-width-on-desktop-float" [ngClass]="{'popup-dailog-bottom': setBottom}">
  <div class="text-center">
    <h2 class="h2-bold">{{ sharedConfig.headerForPopupWithDismissButton }}</h2>
  </div>
  <div class="sub-header">
    <p>{{ sharedConfig.messageForPopupWithDismissButton }}</p>
  </div>
  <div class="buttons-wrapper">
    <ion-button mode="ios" class="primary-button" (click)="dismiss()">{{
      sharedConfig.buttonTextForPopupWithDismissButton }}</ion-button>
  </div>
</div>