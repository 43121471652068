import { Injectable, InjectionToken, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import firebase from 'firebase/compat/app';
import { IAllRiskItem, IBuilding, ICaravan, IContent, IMotorcycle, ISpecifiedItem, IVehicle } from './interfaces/asset.interface';
import { IBankingDetails } from './interfaces/bank.interface';
import {
  IInsuredAllRisk,
  IInsuredBuilding,
  IInsuredCaravan,
  IInsuredContent,
  IInsuredMotorcycle,
  IInsuredSpecifiedItem,
  IInsuredVaps,
  IInsuredVehicle,
} from './interfaces/insured.interface';
import { PageType } from './types/page.type';
import { IAppClientDetail, IAppSettings, IAppCredentials } from './interfaces/app.interface';
import { IBroker } from './interfaces/broker.interface';
import { IClaimCategories } from './interfaces/claim.interface';
import { IInsurer } from './interfaces/insurer.interface';
import { IVehicleLookupYearMap, IQuestion, IVehicleMakeModel, IResponseMotorVehicles } from './interfaces/question.interface';
import { IQuoteRequest, IQuoteConvertResponse, IQuote, ISpecialConditionAssets } from './interfaces/quote.interface';
import { IRbixMessageThread } from './interfaces/rbix.interface';
import { ISchedule, ISchedulesPremiumDetails } from './interfaces/schedule.interface';
import { IUser, IUserDataSubscription, IUserPermission } from './interfaces/user.interface';
import { AssetType } from './types/asset.type';
import { HeadersType } from './types/headers.type';
import { AddressSelectorScreen } from './types/quote.type';

export const _MixPanelService = new InjectionToken<unknown>('clientDetailsConfig');

@Injectable({
  providedIn: 'root',
})
export class SharedConfig {
  public static appClientDetail: IAppClientDetail;
  public static user: IUser;
  static userPermissions: IUserPermission;
  static userIsOTPEnabled: string;
  static isBiometricsEnabled: string;
  public static isPushNotificationsEnabled: boolean;
  public static encryptedPass: string;
  public static activeSubscriptions: Set<number> = new Set();

  private static didHotReload: boolean = false;

  static pageHasScrollbar: boolean;
  public static allowsRecording: boolean;
  public static isRecording: boolean;
  public static startRecordingByPassPageDetectChanges = false;
  public static isProcessing: boolean;
  public static stopTranscriptionProcessing: boolean;
  public static mediaRecorder: MediaRecorder;
  public static audio = new Audio();

  public static vehicleLookupYearMap: IVehicleLookupYearMap;
  public static motorcycleLookupYearMap: IVehicleLookupYearMap;

  static routeParamUserMsisdn: string;
  static routeParamUserFirstName: string;
  static routeParamUserLastName: string;
  static routeParamUserEmail: string;
  static routeParamUserEmailQuoteOnBehalf: string;
  static routeParamUserPaswrd: string;
  static routeParamDealershipReferralCode: string;
  static routeParamBrokerCode: string;
  static routeParamLogInWithOTP: string;
  static showSetNewPasswordButtonOnLogin = true;

  static decryptionKey = 'BAKDO2APWP8KK00!';
  static claimCategories: Array<IClaimCategories> = [];
  static insurers: Array<IInsurer> = [];

  public static firebaseUserKey: string;
  private static firebaseUserEmail: string;
  public static firebaseUser: firebase.User;
  public static firebaseUserPswrd: string;
  public static: firebase.User;
  public static intercomInitialized = false;
  private static appSettings: IAppSettings;
  public static appCredentials: IAppCredentials;
  public static userDataSubscription: IUserDataSubscription;

  public static selectedPage: PageType;
  public static platformType: string;

  static rbixIsProcessingThread = false;
  static rbixMessageThread: IRbixMessageThread;

  static quoteRequest: IQuoteRequest;
  private static quoteRequests: Array<IQuoteRequest>;
  public static quoteConvertResponse: IQuoteConvertResponse;

  public static selectedQuote: IQuote;
  public static selectedQuoteSpecialConditions: ISpecialConditionAssets;
  private static schedulesActive: ISchedule[] = [];
  public static schedulesActiveInitialCount: number; // this is used to show the on track popup in wallet
  private static schedulesWithAssistanceBenefits: ISchedule[] = [];

  public static schedulesPremiumDetails: ISchedulesPremiumDetails;

  public static selectedQuoteToCompareOne: IQuote;
  public static selectedQuoteToCompareTwo: IQuote;
  public static underwritingQuestionsAll: Array<IQuestion>;
  public static selectedAssetUnderwritingQuestions: Array<IQuestion>;
  public static selectedAssetVehicleMakes: Array<string>;
  public static selectedAssetVehicleModels: Array<IVehicleMakeModel>;
  public static selectedAssetVehicleModelVariants: Array<IResponseMotorVehicles>;

  public static quoteSelectedAssetTypeDisplayName: string;
  public static quoteSelectedAssetType: AssetType;
  public static intercomBadgeCount: number;
  public static acknowledgedQuoteRequestError = false;

  public static selectedAssetVehicle: IVehicle;
  public static selectedAssetMotorcycle: IMotorcycle;
  public static selectedAssetCaravan: ICaravan;
  public static selectedAssetBuilding: IBuilding;
  public static selectedAssetContent: IContent;
  public static selectedAssetSpecifiedItem: ISpecifiedItem;
  public static selectedAssetAllRiskItem: IAllRiskItem;

  static insured_motorcycles: Array<IInsuredMotorcycle>;
  static insured_contents: Array<IInsuredContent>;
  static insured_vehicles: Array<IInsuredVehicle>;
  static insured_caravans: Array<IInsuredCaravan>;
  static insured_buildings: Array<IInsuredBuilding>;
  static insured_specified_items: Array<IInsuredSpecifiedItem>;
  static insured_all_risk: Array<IInsuredAllRisk>;
  static insured_vaps: Array<IInsuredVaps>;

  public static showFooterTabs = true;
  public static showHomeQuotePageBackButton = true;
  public static showHomeQuotePageHamburgerButton = false;
  public static swipeGesture = false;
  public static showUpNext = false;
  public static summaryFilteredHeading = 'Edit';
  public static addingClaim: boolean;
  public static showAddAssetModal = false;
  public static hideHeaderWhenAddedClaimType = false;
  public static thirdPartyServiceInitialized: boolean;
  public static intercomServiceInitialized: boolean;
  public static appsFlyerServiceInitialized: boolean;
  public static mixpanelServiceInitialized: boolean;
  public static segmentServiceInitialized: boolean;
  public static firebaseAnalyticsServiceInitialized: boolean;
  public static showFullScreen = false;
  public static sneakPeak: string;
  public static showCalendarPopup = false;

  static quoteStatusButtonText = 'Start a new quote';

  static didViewRenewal: boolean;

  public static quoteBankingDetails: IBankingDetails;

  public static broker: IBroker;
  public static listenerEndorsementRequests: unknown;
  public static listenerQuoteRequests: unknown;
  public static onTrackPopupSubheading: string;
  public static onTrackPopupHeader: string;
  static showLottieFullScreen: boolean;
  static showSetNewPasswordComponent: boolean;
  static production: boolean;
  static regularDriverIdNumber: string;
  static regular_driver_license_type: string;
  static appSettingsRetrieved = false;
  public static hideHeader = false;
  public static inputTextAddressShowScreen: AddressSelectorScreen = AddressSelectorScreen.Main;
  public static inputTextAddressShowScreenOverrideToMain = false;
  public static showHeaderForType: HeadersType;

  public static popupDialogAnswerQuestionShow = false;
  public static popupDialogAnswerSelectedQuestion: IQuestion;
  public static popupDialogShow = false;
  public static popupDialogHeader: string;
  public static popupDialogSubHeader: string;
  public static popupDialogImageUrl: string;
  public static popupDialogTextAreaValue: string;
  public static popupDialogTextAreaResultsValue: string;
  public static popupDialogTextAreaPlaceholder: string;
  public static popupDialogButtonThreeText: string;
  public static popupDialogButtonOneText: string;
  public static popupDialogShowThreeButtons: boolean = false;
  public static popupDialogButtonTopCloseShow: boolean;
  public static popupDialogButtonTwoText: string;
  public static popupDialogButtonOneCallBack: Function;
  public static popupDialogButtonTwoCallBack: Function;
  public static popupDialogButtonThreeCallBack: Function;

  public static showPopupWithDismissButton = false;
  public static showPopupWithDismissButton_updateApp = false;
  public static headerForPopupWithDismissButton: string;
  public static messageForPopupWithDismissButton: string;
  public static buttonTextForPopupWithDismissButton: string;
  public static popupDialogReturnRoute: string;

  public static showPopupWithChat = false;
  public static headerForPopupWithChat: string;
  public static messageForPopupWithChat: string;

  public static showCustomLoader: boolean = false;
  public static scheduleHasBeenSetToActive: boolean = false;
  public static showOnTrackPopup: boolean = false;
  public static hideProgressBar: boolean = false;
  public static didCheckPremiumChange: boolean = false;
  public static insurerPopup: boolean = false;
  hackBecauseOfAddressOnlyReturningThreeFieldsShouldClearAll: boolean = false;

  static clearSharedConfig() {
    delete SharedConfig.userPermissions;
    delete SharedConfig.user;
    delete SharedConfig.didCheckPremiumChange;
    delete SharedConfig.firebaseUser;
    delete SharedConfig.firebaseUserEmail;
    delete SharedConfig.firebaseUserKey;
    delete SharedConfig.selectedPage;
    delete SharedConfig.quoteRequest;
    delete SharedConfig.quoteRequests;
    delete SharedConfig.selectedQuoteToCompareOne;
    delete SharedConfig.selectedQuoteToCompareTwo;
    delete SharedConfig.quoteConvertResponse;
    delete SharedConfig.schedulesActive;
    delete SharedConfig.schedulesPremiumDetails;
    delete SharedConfig.intercomServiceInitialized;
    delete SharedConfig.appsFlyerServiceInitialized;
    delete SharedConfig.mixpanelServiceInitialized;
    delete SharedConfig.insured_vehicles;
    delete SharedConfig.insured_vaps;
    delete SharedConfig.insured_all_risk;
    delete SharedConfig.insured_buildings;
    delete SharedConfig.insured_caravans;
    delete SharedConfig.insured_contents;
    delete SharedConfig.insured_motorcycles;
    delete SharedConfig.insured_specified_items;
    delete SharedConfig.regular_driver_license_type;
    delete SharedConfig.regularDriverIdNumber;
    delete SharedConfig.summaryFilteredHeading;
    delete SharedConfig.showHeaderForType;
    delete SharedConfig.intercomInitialized;
    delete SharedConfig.didHotReload;
    SharedConfig.showFooterTabs = true;
  }

  get didCheckPremiumChange() {
    return SharedConfig.didCheckPremiumChange;
  }

  get isProcessing() {
    return SharedConfig.isProcessing;
  }

  get acknowledgedQuoteRequestError() {
    return SharedConfig.acknowledgedQuoteRequestError;
  }

  get motorcycleLookupYearMap() {
    return SharedConfig.motorcycleLookupYearMap;
  }

  get vehicleLookupYearMap() {
    return SharedConfig.vehicleLookupYearMap;
  }

  get stopTranscriptionProcessing() {
    return SharedConfig.stopTranscriptionProcessing;
  }

  get mediaRecorder() {
    return SharedConfig.mediaRecorder;
  }

  get audio() {
    return SharedConfig.audio;
  }

  get isRecording() {
    return SharedConfig.isRecording;
  }

  get startRecordingByPassPageDetectChanges() {
    return SharedConfig.startRecordingByPassPageDetectChanges;
  }

  get intercomBadgeCount() {
    return SharedConfig.intercomBadgeCount;
  }

  get showCalendarPopup() {
    return SharedConfig.showCalendarPopup;
  }

  get hideProgressBar() {
    return SharedConfig.hideProgressBar;
  }

  get insurerPopup() {
    return SharedConfig.insurerPopup;
  }

  get scrollPixelWidth() {
    return;
  }

  get firebaseUserEmail() {
    return SharedConfig.firebaseUserEmail;
  }

  get firebaseUser() {
    return SharedConfig.firebaseUser;
  }

  get userPermissions() {
    return SharedConfig.userPermissions;
  }

  get firebaseUserKey() {
    return SharedConfig.firebaseUserKey;
  }

  get showOnTrackPopup() {
    return SharedConfig.showOnTrackPopup;
  }

  get schedulesActiveInitialCount() {
    return SharedConfig.schedulesActiveInitialCount;
  }

  get scheduleHasBeenSetToActive() {
    return SharedConfig.scheduleHasBeenSetToActive;
  }

  get popupDialogAnswerSelectedQuestion() {
    return SharedConfig.popupDialogAnswerSelectedQuestion;
  }

  get rbixIsProcessingThread() {
    return SharedConfig.rbixIsProcessingThread;
  }
  get rbixMessageThread() {
    return SharedConfig.rbixMessageThread;
  }

  get popupDialogAnswerQuestionShow() {
    return SharedConfig.popupDialogAnswerQuestionShow;
  }

  get popupDialogShow() {
    return SharedConfig.popupDialogShow;
  }
  get popupDialogHeader() {
    return SharedConfig.popupDialogHeader;
  }
  get popupDialogSubHeader() {
    return SharedConfig.popupDialogSubHeader;
  }
  get popupDialogImageUrl() {
    return SharedConfig.popupDialogImageUrl;
  }

  get popupDialogTextAreaValue() {
    return SharedConfig.popupDialogTextAreaValue;
  }

  get popupDialogTextAreaResultsValue() {
    return SharedConfig.popupDialogTextAreaResultsValue;
  }

  get popupDialogTextAreaPlaceholder() {
    return SharedConfig.popupDialogTextAreaPlaceholder;
  }

  get popupDialogButtonThreeText() {
    return SharedConfig.popupDialogButtonThreeText;
  }

  get popupDialogButtonThreeCallBack() {
    return SharedConfig.popupDialogButtonThreeCallBack;
  }

  get popupDialogButtonTopCloseShow() {
    return SharedConfig.popupDialogButtonTopCloseShow;
  }

  get popupDialogButtonOneText() {
    return SharedConfig.popupDialogButtonOneText;
  }

  get popupDialogShowThreeButtons() {
    return SharedConfig.popupDialogShowThreeButtons;
  }

  get popupDialogButtonTwoText() {
    return SharedConfig.popupDialogButtonTwoText;
  }
  get popupDialogButtonOneCallBack() {
    return SharedConfig.popupDialogButtonOneCallBack;
  }
  get popupDialogButtonTwoCallBack() {
    return SharedConfig.popupDialogButtonTwoCallBack;
  }

  get showPopupWithDismissButton() {
    return SharedConfig.showPopupWithDismissButton;
  }

  get showPopupWithDismissButton_updateApp() {
    return SharedConfig.showPopupWithDismissButton_updateApp;
  }

  get showCustomLoader() {
    return SharedConfig.showCustomLoader;
  }

  get showPopupWithChat() {
    return SharedConfig.showPopupWithChat;
  }

  get headerForPopupWithChat() {
    return SharedConfig.headerForPopupWithChat;
  }

  get messageForPopupWithChat() {
    return SharedConfig.messageForPopupWithChat;
  }

  get popupDialogReturnRoute() {
    return SharedConfig.popupDialogReturnRoute;
  }

  get headerForPopupWithDismissButton() {
    return SharedConfig.headerForPopupWithDismissButton;
  }

  get messageForPopupWithDismissButton() {
    return SharedConfig.messageForPopupWithDismissButton;
  }

  get buttonTextForPopupWithDismissButton() {
    return SharedConfig.buttonTextForPopupWithDismissButton;
  }

  get sneakPeak() {
    return SharedConfig.sneakPeak;
  }
  get showHeaderForType() {
    return SharedConfig.showHeaderForType;
  }
  get inputTextAddressShowScreen() {
    return SharedConfig.inputTextAddressShowScreen;
  }
  get inputTextAddressShowScreenOverrideToMain() {
    return SharedConfig.inputTextAddressShowScreenOverrideToMain;
  }

  get hideHeader() {
    return SharedConfig.hideHeader;
  }
  get summaryFilteredHeading() {
    return SharedConfig.summaryFilteredHeading;
  }
  get showAddAssetModal() {
    return SharedConfig.showAddAssetModal;
  }
  get addingClaim() {
    return SharedConfig.addingClaim;
  }
  get hideHeaderWhenAddedClaimType() {
    return SharedConfig.hideHeaderWhenAddedClaimType;
  }
  get showFullScreen() {
    return SharedConfig.showFullScreen;
  }
  get showSetNewPasswordButtonOnLogin() {
    return SharedConfig.showSetNewPasswordButtonOnLogin;
  }
  get routeParamUserMsisdn() {
    return SharedConfig.routeParamUserMsisdn;
  }
  get routeParamLogInWithOTP() {
    return SharedConfig.routeParamLogInWithOTP;
  }
  get routeParamUserEmail() {
    return SharedConfig.routeParamUserEmail;
  }
  get showLottieFullScreen() {
    return SharedConfig.showLottieFullScreen;
  }
  get showSetNewPasswordComponent() {
    return SharedConfig.showSetNewPasswordComponent;
  }
  get routeParamUserLastName() {
    return SharedConfig.routeParamUserLastName;
  }
  get routeParamUserFirstName() {
    return SharedConfig.routeParamUserFirstName;
  }

  get regularDriverIdNumber() {
    return SharedConfig.regularDriverIdNumber;
  }

  get regular_driver_license_type() {
    return SharedConfig.regular_driver_license_type;
  }

  get quoteStatusButtonText() {
    return SharedConfig.quoteStatusButtonText;
  }

  get production() {
    return SharedConfig.production;
  }

  get user() {
    return SharedConfig.user;
  }

  get didHotReload() {
    return SharedConfig.didHotReload;
  }

   setDidHotReload(value: boolean) {
    SharedConfig.didHotReload = value;
  }

  setShowCustomLoader(show: boolean, page: string = '') {
    SharedConfig.showCustomLoader = show;
    //  console.log('>>>> Setting Loader to : ' + show + ' on page/function : ' +page )
  }

  getSelectedAsset(asset_type: AssetType) {
    switch (asset_type) {
      case AssetType.Vehicles:
        return SharedConfig.selectedAssetVehicle;
      case AssetType.Motorcycles:
        return SharedConfig.selectedAssetMotorcycle;
      case AssetType.Buildings:
        return SharedConfig.selectedAssetBuilding;
      case AssetType.Contents:
        return SharedConfig.selectedAssetContent;
      case AssetType.Caravans:
        return SharedConfig.selectedAssetCaravan;
      case AssetType.AllRisks:
        return SharedConfig.selectedAssetAllRiskItem;
      case AssetType.SpecifiedItems:
        return SharedConfig.selectedAssetSpecifiedItem;
      case AssetType.UserPersonalDetails:
        return SharedConfig.user.personal_details;
      case AssetType.UserInsuranceHistory:
        return SharedConfig.user.insurance_history;
      default:
        return null;
    }
  }

  setSelectedAssetObjectAnswer(question: IQuestion) {
    const asset_type = SharedConfig.selectedAssetUnderwritingQuestions[0].underwriting_answers[0].object_name;

    for (const underwriting_answer of question.underwriting_answers) {
      switch (asset_type) {
        case AssetType.Vehicles:
          (SharedConfig.selectedAssetVehicle as any)[underwriting_answer.object_field] = underwriting_answer.object_answer;
          (SharedConfig.selectedAssetVehicle as any)[underwriting_answer.object_answer_error] = underwriting_answer.object_answer_error;
          break;
        case AssetType.Motorcycles:
          (SharedConfig.selectedAssetMotorcycle as any)[underwriting_answer.object_field] = underwriting_answer.object_answer;
          (SharedConfig.selectedAssetMotorcycle as any)[underwriting_answer.object_answer_error] = underwriting_answer.object_answer_error;

          break;
        case AssetType.Buildings:
          (SharedConfig.selectedAssetBuilding as any)[underwriting_answer.object_field] = underwriting_answer.object_answer;
          (SharedConfig.selectedAssetBuilding as any)[underwriting_answer.object_answer_error] = underwriting_answer.object_answer_error;

          break;
        case AssetType.Contents:
          (SharedConfig.selectedAssetContent as any)[underwriting_answer.object_field] = underwriting_answer.object_answer;
          (SharedConfig.selectedAssetContent as any)[underwriting_answer.object_answer_error] = underwriting_answer.object_answer_error;
          break;
        case AssetType.Caravans:
          (SharedConfig.selectedAssetCaravan as any)[underwriting_answer.object_field] = underwriting_answer.object_answer;
          (SharedConfig.selectedAssetCaravan as any)[underwriting_answer.object_answer_error] = underwriting_answer.object_answer_error;
          break;
        case AssetType.AllRisks:
          (SharedConfig.selectedAssetAllRiskItem as any)[underwriting_answer.object_field] = underwriting_answer.object_answer;
          (SharedConfig.selectedAssetAllRiskItem as any)[underwriting_answer.object_answer_error] = underwriting_answer.object_answer_error;
          break;
        case AssetType.SpecifiedItems:
          (SharedConfig.selectedAssetSpecifiedItem as any)[underwriting_answer.object_field] = underwriting_answer.object_answer;
          (SharedConfig.selectedAssetSpecifiedItem as any)[underwriting_answer.object_answer_error] = underwriting_answer.object_answer_error;
          break;
        case AssetType.UserPersonalDetails:
          (SharedConfig.user.personal_details as any)[underwriting_answer.object_field] = underwriting_answer.object_answer;
          (SharedConfig.user.personal_details as any)[underwriting_answer.object_answer_error] = underwriting_answer.object_answer_error;
          break;
        case AssetType.UserInsuranceHistory:
          (SharedConfig.user.insurance_history as any)[underwriting_answer.object_field] = underwriting_answer.object_answer;
          (SharedConfig.user.insurance_history as any)[underwriting_answer.object_answer_error] = underwriting_answer.object_answer_error;
          break;
        default:
          return;
      }
    }
  }

  removeChildrenFromEmptyParents(question: IQuestion, SharedConfigAsset: any) {
    if (question.dependency && question.dependency.dependency_type === 'Parent') {
      const childDependencyQuestions = SharedConfig.selectedAssetUnderwritingQuestions.filter(
        (x) => x.dependency && x.dependency.dependency_key === question.dependency.dependency_key && x.dependency.dependency_type === 'Child'
      );
      if (childDependencyQuestions && childDependencyQuestions.length > 0 && question.underwriting_answers[0].object_answer === false) {
        for (const childQuestion of childDependencyQuestions) {
          for (const underwritingAnswer of childQuestion.underwriting_answers) {
            SharedConfigAsset[underwritingAnswer.object_field] = null;
          }
        }
      }
    }
  }

  setUser(user: IUser) {
    if (user.addresses && (!user.addresses?.risk_address_code_id_sa02 || !user.addresses?.risk_address_code_id_mnfg)) {
      delete user.addresses?.risk_address_code;
      delete user.addresses?.risk_address_code_id;
    } else if (!user.addresses) {
      user.addresses = {};
    }
    SharedConfig.user = user;
    SharedConfig.user.addresses.risk_address_code = user.addresses.postal_code;
  }

  setUserPermission(userPermission: IUserPermission) {
    SharedConfig.userPermissions = userPermission;
  }

  get schedulesActive() {
    return SharedConfig.schedulesActive;
  }
  setSchedulesActive(schedules: Array<ISchedule>) {
    SharedConfig.schedulesActive = schedules;
  }

  get pageHasScrollbar() {
    return SharedConfig.pageHasScrollbar;
  }

  get schedulesWithAssistanceBenefits(): ISchedule[] {
    return SharedConfig.schedulesWithAssistanceBenefits;
  }
  setschedulesWithAssistanceBenefits(schedules: Array<ISchedule>) {
    SharedConfig.schedulesWithAssistanceBenefits = schedules;
  }

  get quoteConvertResponse(): IQuoteConvertResponse {
    return SharedConfig.quoteConvertResponse;
  }

  get insured_motorcycles(): Array<IInsuredMotorcycle> {
    return SharedConfig.insured_motorcycles;
  }
  setInsuredMotorcycles(insured_motorcycles: Array<IInsuredMotorcycle>) {
    SharedConfig.insured_motorcycles = insured_motorcycles;
  }

  get insured_contents(): Array<IInsuredContent> {
    return SharedConfig.insured_contents;
  }
  setInsuredContents(shedulePendingEndorsement: Array<IInsuredContent>) {
    SharedConfig.insured_contents = shedulePendingEndorsement;
  }

  get insured_vehicles(): Array<IInsuredVehicle> {
    return SharedConfig.insured_vehicles;
  }
  setInsuredVehicles(insured_vehicles: Array<IInsuredVehicle>) {
    SharedConfig.insured_vehicles = insured_vehicles;
  }

  get insured_vaps(): Array<IInsuredVaps> {
    return SharedConfig.insured_vaps;
  }
  setInsuredVaps(insured_vaps: Array<IInsuredVaps>) {
    SharedConfig.insured_vaps = insured_vaps;
  }

  get insured_caravans(): Array<IInsuredCaravan> {
    return SharedConfig.insured_caravans;
  }
  setInsuredCaravans(insured_caravans: Array<IInsuredCaravan>) {
    SharedConfig.insured_caravans = insured_caravans;
  }

  get insured_buildings(): Array<IInsuredBuilding> {
    return SharedConfig.insured_buildings;
  }
  setInsuredBuildings(insured_buildings: Array<IInsuredBuilding>) {
    SharedConfig.insured_buildings = insured_buildings;
  }

  get insured_specified_items(): Array<IInsuredSpecifiedItem> {
    return SharedConfig.insured_specified_items;
  }
  setIsuredSpecifiedItem(insured_specified_items: Array<IInsuredSpecifiedItem>) {
    SharedConfig.insured_specified_items = insured_specified_items;
  }

  get insured_all_risk(): Array<IInsuredAllRisk> {
    return SharedConfig.insured_all_risk;
  }
  setInsuredAllRisk(insured_all_risk: Array<IInsuredAllRisk>) {
    SharedConfig.insured_all_risk = insured_all_risk;
  }

  setAppClientDetail(appClientDetail: IAppClientDetail) {
    SharedConfig.appClientDetail = appClientDetail;
  }

  get appClientDetail() {
    return SharedConfig.appClientDetail;
  }

  get showFooterTabs() {
    return SharedConfig.showFooterTabs;
  }
  get showUpNext() {
    return SharedConfig.showUpNext;
  }
  get swipeGesture() {
    return SharedConfig.swipeGesture;
  }
  get showHomeQuotePageBackButton() {
    return SharedConfig.showHomeQuotePageBackButton;
  }
  get showHomeQuotePageHamburgerButton() {
    return SharedConfig.showHomeQuotePageHamburgerButton;
  }

  get schedulesPremiumDetails(): ISchedulesPremiumDetails {
    if (!SharedConfig.schedulesPremiumDetails) {
      return {
        all_risks_policies_count: 0,
        all_risks_premium: 0,
        buildings_policies_count: 0,
        buildings_premium: 0,
        caravan_policies_count: 0,
        caravans_premium: 0,
        contents_policies_count: 0,
        contents_premium: 0,
        motorcycle_policies_count: 0,
        motorcycles_premium: 0,
        otherPremium: 0,
        specified_items_premium: 0,
        total_premium: 0,
        vehicle_policies_count: 0,
        vehicles_premium: 0,
        vaps_policies_count: 0,
        vaps_premium: 0,
      };
    } else {
      return SharedConfig.schedulesPremiumDetails;
    }
  }

  get quoteRequest() {
    return SharedConfig.quoteRequest;
  }

  get recommendedQuote() {
    return SharedConfig.quoteRequest.quotes.find((x) => x.recommended);
  }

  get quotesOther() {
    const otherQuotes = SharedConfig.quoteRequest.quotes.filter((x) => !x.recommended);
    return otherQuotes.sort((a, b) => b.rating_ctrl - a.rating_ctrl);
  }

  setFirebaseUser(user: firebase.User) {
    SharedConfig.firebaseUser = user;
  }

  setFirebaseUserKey(key: string) {
    SharedConfig.firebaseUserKey = key;
  }
  setFirebaseUserEmail(email: string) {
    SharedConfig.firebaseUserEmail = email;
  }

  setQuoteRequest(quoteRequest: IQuoteRequest): IQuoteRequest {
    SharedConfig.quoteRequest = quoteRequest;
    return SharedConfig.quoteRequest;
  }

  get quoteRequests() {
    return SharedConfig.quoteRequests;
  }

  setQuoteRequests(quoteRequests: IQuoteRequest[]) {
    SharedConfig.quoteRequests = quoteRequests;
  }

  get selectedAssetUnderwritingQuestions() {
    return SharedConfig.selectedAssetUnderwritingQuestions;
  }

  get appSettings() {
    return SharedConfig.appSettings;
  }

  setAppSettings(appSettings: IAppSettings) {
    SharedConfig.appSettings = appSettings;
  }

  get getAppCredentials() {
    return SharedConfig.appCredentials;
  }

  get getSelectedQuote() {
    return SharedConfig.selectedQuote;
  }
}

@NgModule({
  imports: [CommonModule],
  providers: [],
})
export class SvcsShareConfigModule {}
