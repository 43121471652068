<div class="wrapper limit-width-on-desktop-float" *ngIf="!sharedConfig.routeParamUserEmail && !sharedConfig.routeParamUserMsisdn">
  <form [formGroup]="resetPasswordForm" (submit)="resetPassword()" novalidate>
    <h2 class="h2-bold">Set your password</h2>
    <p>We will send you a <b>password setup</b> link to your email. Once you follow the steps you will be able to log into the app.</p>

    <ion-item mode="ios">
      <ion-label position="floating">Email address</ion-label>
      <ion-input
        formControlName="email"
        type="email"
        [class.invalid]="!resetPasswordForm.controls.email.valid && resetPasswordForm.controls.email.dirty"
      >
      </ion-input>
    </ion-item>

    <div *ngIf="!resetPasswordForm.controls.email.valid && resetPasswordForm.controls.email.dirty">
      <p class="error-message">Provide a valid email</p>
    </div>

    <div class="button-row">
      <ion-button mode="ios" type="submit" class="primary-button" [disabled]="!this.resetPasswordForm.valid"> Email link </ion-button>
      <ion-button mode="ios" (click)="cancel()" class="secondary-button"> Cancel </ion-button>
    </div>
  </form>
</div>

<div
  class="wrapper limit-width-on-desktop-float"
  *ngIf="
    (sharedConfig.routeParamUserEmail && sharedConfig.routeParamUserMsisdn) ||
    (sharedConfig.routeParamUserMsisdn && !sharedConfig.routeParamUserEmail) ||
    (!sharedConfig.routeParamUserMsisdn && sharedConfig.routeParamUserEmail)
  "
>
  <div class="success-icon-wrapper mb-40 mt-32">
    <ion-icon src="assets/icon/genetic-icons/big-success-tick.svg" class="icon-fill-success"></ion-icon>
  </div>
  <h2 class="h2-bold text-center">We sent an email to {{ displayEmail }}</h2>
  <p class="mb-40 text-center">Check your email and follow the link we sent to set up your password.</p>
  <p class="text-center">Once you have set up your password you can log in.</p>
  <div class="login-button-row">
    <ion-button mode="ios" (click)="login()" class="secondary-button"> Login </ion-button>
  </div>
</div>

<popup-dialog id="forgot.password.component" *ngIf="sharedConfig.popupDialogShow"></popup-dialog>
