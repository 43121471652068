<div *ngIf="sharedConfig.showUpNext" class="loader-backdrop"></div>
<div *ngIf="sharedConfig.showUpNext" class="center-outer">
  <div class="center-inner">
    <div class="center-auto">
      <div class="loader-wrapper">
        <div role="status">
          <lottie-player autoplay mode="normal" src="assets/lottie-animations/{{ animationName }}.json"> </lottie-player>
        </div>
      </div>
      <div class="loader-message">
        <h4>{{ displayText }}</h4>
      </div>
    </div>
  </div>
</div>
<div *ngIf="sharedConfig.showUpNext" class="up-next-banner">
  <div class="progress-timmer-wrapper">
    <div class="container">
      <div class="progress-bar-container">
        <div class="progress-bar"></div>
      </div>
    </div>
  </div>
  <div class="text-wrapper">
    <h4>Up next</h4>
    <p>
      {{ upNextSectionText }}
    </p>
  </div>
</div>
