<div class="input-wrapper">
  <ion-item mode="ios" *ngFor="let underwritingAnswer of question.underwriting_answers; let i = index">
    <ion-label *ngIf="showHeading" position="floating">{{ underwritingAnswer.place_holder }}</ion-label>
    <ion-input
      readonly="{{ readOnly }}"
      class="bold-text"
      type="number"
      value="{{ underwritingAnswer.object_answer }}"
      min="0"
      (input)="answerValue($event, i)"
    ></ion-input>
  </ion-item>
  <p class="error-message" *ngIf="error">{{ error }}</p>
</div>
