<div class="wrapper-no-height limit-width-on-desktop-float">
  <div class="heading">
    <h2 class="h2-bold">Download the app</h2>
    <p>To download the app simply scan this QR code or select the link provided:</p>
  </div>
  <div class="qr-code-wrapper">
    <ion-img src="{{ sharedConfig.appSettings.download_qr_code_image_url }}"></ion-img>
  </div>
  <div class="link-wrapper">
    <p>Download link:</p>
    <a href="{{sharedConfig.appSettings.app_download_url}}">{{ sharedConfig.appSettings.app_download_url }}</a>
  </div>
</div>

<!-- TODO ALL OTHER POPUPS SHOULD BE REPLACED BY THIS GENERIC POPUP -->
<popup-dialog   id="download.app.component" *ngIf="sharedConfig.popupDialogShow"></popup-dialog>
