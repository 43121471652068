<div class="tabs-wrapper" [ngClass]="{ 'mt-m14': quoteComparisonDetailType !== QuoteComparisonDetailType.SpecialConditions }">
  <ion-grid class="limit-width-on-desktop-float p-20">
    <ion-row>
      <div (click)="slidePrevious()">
        <ion-icon
          class="left-arrow"
          src="assets/icon/app-icons/arrow-left-black.svg"
          [ngClass]="{ disabled: disableLeft }"
          (click)="slidePrevious()"
        ></ion-icon>
      </div>
      <ion-slides [options]="slideOpts" (ionSlideWillChange)="slideDidChange()">
        <ion-slide *ngFor="let quoteComparisonAssetTab of quoteComparisonAssetTabs; let i = index">
          <div class="tab" [ngClass]="{ 'active-tab': quoteComparisonAssetTab.active }" (click)="tabSelected(quoteComparisonAssetTab)">
            <p>{{ quoteComparisonAssetTab.name }}</p>
          </div>
        </ion-slide>
      </ion-slides>
      <div (click)="slideNext()">
        <ion-icon class="right-arrow" src="assets/icon/app-icons/arrow-right-black.svg" [ngClass]="{ disabled: disableRight }"></ion-icon>
      </div>
    </ion-row>
  </ion-grid>
</div>
