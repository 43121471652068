import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { APIBrokerService } from '@takectrl-multiapp/svcs-ctrl-api';
import { firstValueFrom } from 'rxjs';
import { IBroker, IBrokerage } from '@takectrl-multiapp/svcs-share-config';

@Injectable({
  providedIn: 'root',
})
export class BUSNBrokerService {
  constructor(private readonly ctrlApiHttpService: APIBrokerService, public angularFireDatabase: AngularFireDatabase) {}

  async getBrokers(brokerName: string): Promise<IBroker[]> {
    return (await firstValueFrom(this.ctrlApiHttpService.getBrokers(brokerName))).results as IBroker[];
  }

  async getAllBrokers() {
    return await this.angularFireDatabase.database
      .ref('brokers')
      .once('value')
      .then((data) => {
        return data.val();
      })
      .catch((error) => {
        console.error(error);
        return null;
      });
  }

  async getBrokerage(): Promise<IBrokerage> {
    const brokerResult = await firstValueFrom(this.ctrlApiHttpService.getBrokerage());
    return brokerResult.results;
  }
}
