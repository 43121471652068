import { NavController, LoadingController, AlertController } from '@ionic/angular';
import { Component, OnInit, NgZone, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { QuoteRequestStatusType, SharedConfig, SharedDialogService } from '@takectrl-multiapp/svcs-share-config';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { BUSNQuoteScheduleService } from '@takectrl-multiapp/svcs-core-business';
declare const dataLayer: unknown[];

@Component({
  selector: 'quote-request-started',
  templateUrl: './quote-request-started.component.html',
  styleUrls: ['./quote-request-started.component.scss'],
})
export class QuoteRequestStartedComponent implements OnInit {
  @Output() navigatePage: EventEmitter<unknown> = new EventEmitter();
  constructor(
    public navCtrl: NavController,
    public zone: NgZone,
    public loadingCtrl: LoadingController,
    public route: Router,
    public alertController: AlertController,
    public sharedConfig: SharedConfig,
    public busnThirdPartyService: BUSNThirdPartyService,
    public busnQuoteScheduleService: BUSNQuoteScheduleService,
    private readonly sharedDialogService: SharedDialogService
  ) {}

  isAllAssetsComplete: boolean;
  
  ngOnInit(): void {
    dataLayer.push({
      event: 'page_show',
      screenPath: 'quote-request-started',
      screenName: 'quote-request-started',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
    this.restartQuote = this.restartQuote.bind(this);
  }

  async ngAfterViewInit(): Promise<void> {}



  navPersonalDetails() {
    this.navigatePage.emit('personal-details');
  }

  async navToQuoteNew() {
    if (
      !this.sharedConfig.quoteRequest ||
      this.sharedConfig.quoteRequest.status === QuoteRequestStatusType.Failed ||
      this.sharedConfig.quoteRequest.status === QuoteRequestStatusType.Cancelled
    ) {
      this.sharedConfig.setShowCustomLoader(true)
      this.busnThirdPartyService.logEvent('action_start_new_quote');
      this.busnQuoteScheduleService.createNewQuoteRequest().then(async (quoteRequest) => {
        this.sharedConfig.setShowCustomLoader(false)
        this.navigatePage.emit('quote-new');
      });
    } else {
      this.navigatePage.emit('quote-new');
    }
  }

  startNewQuoteOnOpenStatus() {
    this.sharedDialogService.showPopupDialog({
      header: 'Are you sure you want to restart your quote?',
      subHeader: 'We will keep the progress made on the assets you have added.',
      buttonOneCallback: this.restartQuote,
      buttonOneText: 'Yes',
      buttonTwoText: 'No',
      buttonTwoCallback: this.sharedDialogService.hidePopupDialog,
    });
  }

  restartQuote() {
    this.sharedDialogService.hidePopupDialog();
    this.sharedConfig.setShowCustomLoader(true)
    SharedConfig.hideHeader = false;
    this.busnThirdPartyService.logEvent('action_start_new_quote');
    try {
      this.busnQuoteScheduleService.createNewQuoteRequest().then(async () => {
        this.navigatePage.emit('quote-new');
        this.sharedConfig.setShowCustomLoader(false)
      });
    } catch (error) {
      this.sharedDialogService.showPopupDialog({
        header: 'Something went wrong!',
        subHeader: error.message,
        buttonOneText: 'OK',
        buttonOneCallback: this.sharedDialogService.hidePopupDialog,
      });
      this.sharedConfig.setShowCustomLoader(false)
    }
  }
}
