import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { BUSNQuoteScheduleService } from '@takectrl-multiapp/svcs-core-business';
import {
  AssetType,
  SharedConfig,
  IInsuredContent,
  IInsuredSpecifiedItem,
  IInsuredMotorcycle,
  IInsuredVehicle,
  IInsuredBuilding,
  IInsuredAllRisk,
  IInsuredCaravan,
  SharedDialogService,
  IQuoteRequest,
  ISpecifiedItem,
  QuoteRequestType,
} from '@takectrl-multiapp/svcs-share-config';
declare const dataLayer: any[];

@Component({
  selector: 'endorsement-update-accept',
  templateUrl: './endorsement-update-accept.component.html',
  styleUrls: ['./endorsement-update-accept.component.scss'],
})
export class EndorsementUpdateAcceptComponent implements OnInit {
  @Output() navigateWalletAssetDetailsPage: EventEmitter<unknown> = new EventEmitter();
  @Output() navigateWalletPage: EventEmitter<unknown> = new EventEmitter();
  public insuredWithEndorseScheduleIdCaravans: Array<IInsuredCaravan>;
  public insuredWithEndorseScheduleIdMotorcycles: Array<IInsuredMotorcycle>;
  public insuredWithEndorseScheduleIdVehicles: Array<IInsuredVehicle>;
  public insuredWithEndorseScheduleIdBuildings: Array<IInsuredBuilding>;
  public insuredWithEndorseScheduleIdContents: Array<IInsuredContent>;
  public insuredWithEndorseScheduleIdAllRisk: Array<IInsuredAllRisk>;
  public insuredWithEndorseScheduleIdSpecifiedItems: Array<IInsuredSpecifiedItem>;

  public currentSchedulePremium: number;
  public updatedSchedulePremium: number;
  showCustomPopup = false;
  idNumberField: string;
  customPopupHeader: string;
  customPopupMessage: string;
  didAccept: string;
  QuoteRequestType = QuoteRequestType;
  AssetType = AssetType;
  constructor(
    private readonly busnThirdPartyService: BUSNThirdPartyService,
    private readonly busnQuoteScheduleService: BUSNQuoteScheduleService,
    public sharedConfig: SharedConfig,
    public loadingCtrl: LoadingController,
    public sharedDialogService: SharedDialogService
  ) {}
  ngOnInit(): void {
    this.getSingleSchedulePremium(this.sharedConfig.quoteRequest);
    this.findInsuredAssetsWithEndorseScheduleId(this.sharedConfig.quoteRequest.schedule_id);
    this.navigateToWalletAndClosePopup = this.navigateToWalletAndClosePopup.bind(this);
    dataLayer.push({
      event: 'page_show',
      screenPath: 'endorsement-update-accept',
      screenName: 'endorsement-update-accept',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }

  getSingleSchedulePremium(quoteRequest: IQuoteRequest) {
    const endorseSchedule = this.sharedConfig.schedulesActive.filter((x) => x.id === quoteRequest.schedule_id);
    this.currentSchedulePremium = endorseSchedule[0].total_premium;
    this.updatedSchedulePremium = quoteRequest.quotes[0].total_premium;
  }

  findInsuredAssetsWithEndorseScheduleId(schedule_id: number) {
    const tempVehicles: Array<IInsuredVehicle> = [];
    const tempMotorcycles: Array<IInsuredMotorcycle> = [];
    const tempCaravans: Array<IInsuredCaravan> = [];
    const tempBuildings = [];
    const tempContents = [];
    const tempAllRisk = [];
    const tempSpecifiedItems = [];

    for (const insuredVehicle of this.sharedConfig?.insured_vehicles) {
      if (insuredVehicle.schedule_id === schedule_id) {
        insuredVehicle.excess = insuredVehicle.excess.filter((x) => x.description === 'Basic excess' || x.description === 'Excess');
        tempVehicles.push(insuredVehicle);
      }
    }

    for (const insuredMotorcycle of this.sharedConfig?.insured_motorcycles) {
      if (insuredMotorcycle.schedule_id === schedule_id) {
        insuredMotorcycle.excess = insuredMotorcycle.excess.filter((x) => x.description === 'Basic excess' || x.description === 'Excess');
        tempMotorcycles.push(insuredMotorcycle);
      }
    }

    for (const insuredCaravan of this.sharedConfig?.insured_caravans) {
      if (insuredCaravan.schedule_id === schedule_id) {
        insuredCaravan.excess = insuredCaravan.excess.filter((x) => x.description === 'Basic excess' || x.description === 'Excess');
        tempCaravans.push(insuredCaravan);
      }
    }

    for (const insuredBuilding of this.sharedConfig?.insured_buildings) {
      if (insuredBuilding.schedule_id === schedule_id) {
        insuredBuilding.excess = insuredBuilding.excess.filter((x) => x.description === 'Basic excess' || x.description === 'Excess');
        tempBuildings.push(insuredBuilding);
      }
    }

    for (const insuredContent of this.sharedConfig?.insured_contents) {
      if (insuredContent.schedule_id === schedule_id) {
        insuredContent.excess = insuredContent.excess.filter((x) => x.description === 'Basic excess' || x.description === 'Excess');
        tempContents.push(insuredContent);
      }
    }

    if (this.sharedConfig?.insured_all_risk)
      for (const insured_all_risk of this.sharedConfig?.insured_all_risk) {
        if (insured_all_risk.schedule_id === schedule_id) {
          insured_all_risk.excess = insured_all_risk.excess.filter((x) => x.description === 'Basic excess' || x.description === 'Excess');
          tempAllRisk.push(insured_all_risk);
        }
      }

    if (this.sharedConfig?.insured_specified_items)
      for (const insuredSpecifiedItem of this.sharedConfig?.insured_specified_items) {
        if (insuredSpecifiedItem.schedule_id === schedule_id) {
          insuredSpecifiedItem.excess = insuredSpecifiedItem.excess.filter((x) => x.description === 'Basic excess' || x.description === 'Excess');
          tempSpecifiedItems.push(insuredSpecifiedItem);
        }
      }

    if (this.sharedConfig?.insured_all_risk)
      for (const insuredAllRisk of this.sharedConfig?.insured_all_risk) {
        if (insuredAllRisk.schedule_id === schedule_id) {
          insuredAllRisk.excess = insuredAllRisk.excess.filter((x) => x.description === 'Basic excess' || x.description === 'Excess');
          tempSpecifiedItems.push(insuredAllRisk);
        }
      }

    const index = this.sharedConfig.quoteRequest?.quotes[0].quote_specified_items.findIndex((asset) => {
      if (asset && asset.asset_details) {
        const specifiedItem = asset.asset_details as ISpecifiedItem;
        return specifiedItem.description === 'Clothing and Personal Effects';
      }
    });

    if (index !== -1 && this.sharedConfig.quoteRequest?.quotes[0].quote_specified_items) {
      this.sharedConfig.quoteRequest?.quotes[0].quote_specified_items.push(
        this.sharedConfig.quoteRequest?.quotes[0].quote_specified_items.splice(index, 1)[0]
      );
    }

    this.insuredWithEndorseScheduleIdMotorcycles = tempMotorcycles;
    this.insuredWithEndorseScheduleIdCaravans = tempCaravans;
    this.insuredWithEndorseScheduleIdVehicles = tempVehicles;
    this.insuredWithEndorseScheduleIdContents = tempContents;
    this.insuredWithEndorseScheduleIdBuildings = tempBuildings;
    this.insuredWithEndorseScheduleIdAllRisk = tempAllRisk;
    this.insuredWithEndorseScheduleIdSpecifiedItems = tempSpecifiedItems;
  }

  async acceptEndorsementQuoteRequest(didAccept: string) {
    const loader = await this.loadingCtrl.create();
    this.didAccept = didAccept;

    if (didAccept === 'accepted') {
      this.busnThirdPartyService.logEvent('action_endorsement', { action: 'accepted' });
      this.showCustomPopup = true;
      SharedConfig.showFullScreen = true;
      this.customPopupHeader = 'Update assets';
      this.customPopupMessage =
        'You are about to accept the changes you’ve made to your policy with immediate effect. Please type in your ID number to confirm.';
    } else {
      this.busnThirdPartyService.logEvent('action_endorsement', { action: 'declined' });
          this.sharedDialogService.showPopupDialog({
            header: 'Note!',
            subHeader: 'You are not accepting the changes to your policy, so your premium and asset details will remain as is.',
            buttonOneText: 'OK',
            buttonOneCallback: this.navigateToWalletAndClosePopup,
          });
    }
  }

  async navigateToWalletAndClosePopup() {
    this.sharedConfig.setShowCustomLoader(true)
    let data = await this.busnQuoteScheduleService.declineEndorsement(this.sharedConfig.quoteRequest.id);
    if (data.success) {
        this.sharedConfig.setShowCustomLoader(false)
        this.sharedDialogService.hidePopupDialog();
        this.navigateWalletPage.emit();
    } else {
      this.sharedDialogService.showPopupDialog({
        header: 'Something went wrong!',
        subHeader: "Something went wrong, please try again",
        buttonOneText: 'OK',
        buttonOneCallback: this.sharedDialogService.hidePopupDialog,
      });
    }
  }

  viewDetails(asset_type: AssetType, asset: any) {
    this.navigateWalletAssetDetailsPage.emit({
      data: asset,
      selectedInsurerId: this.sharedConfig.schedulesActive[0].insurer_id,
      asset_type: asset_type,
    });
  }

  async addEndorsement() {
    if (this.idNumberField === this.sharedConfig.user.personal_details.id_nr) {
      this.sharedConfig.setShowCustomLoader(true)
      this.busnQuoteScheduleService.acceptEndorsement(this.sharedConfig.quoteRequest.id).then(async (value) => {
        this.sharedConfig.setShowCustomLoader(false)
        if (value && value.hasOwnProperty('success')) {
          if (value.success === true) {
            this.customPopupHeader = 'Processing';
            this.customPopupMessage = 'Your Schedule is being processed.';

            this.showCustomPopup = true;
            this.dismissCustomPopup();
            this.navigateWalletPage.emit();
          } else {
            this.dismissCustomPopup();
            this.sharedDialogService.showPopupDialog({
              header: 'Something went wrong!',
              subHeader: "Something went wrong, please try again",
              buttonOneText: 'OK',
              buttonOneCallback: this.sharedDialogService.hidePopupDialog,
            });
          }
        } else {
          this.dismissCustomPopup();
          this.sharedDialogService.showPopupDialog({
              header: 'Something went wrong!',
              subHeader: "Something went wrong, please try again",
              buttonOneText: 'OK',
              buttonOneCallback: this.sharedDialogService.hidePopupDialog,
            });
        }
      });
    } else {
      this.dismissCustomPopup();
      this.sharedDialogService.showPopupDialog({
        header: 'Something went wrong!',
        subHeader: "Incorrect ID number",
        buttonOneText: 'OK',
        buttonOneCallback: this.sharedDialogService.hidePopupDialog,
      });
      return false;
    }
  }
  dismissCustomPopup() {
    this.showCustomPopup = false;
    SharedConfig.showFullScreen = false;
    SharedConfig.showFooterTabs = true;
    this.customPopupHeader = '';
    this.customPopupMessage = '';
  }
}
