import { Component, OnInit } from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { SharedConfig } from '@takectrl-multiapp/svcs-share-config';
declare const dataLayer: any[];

@Component({
  selector: 'download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss'],
})
export class DownloadAppComponent implements OnInit {
  constructor(public busnThirdPartyService: BUSNThirdPartyService, private readonly iab: InAppBrowser, public sharedConfig: SharedConfig) {}

  ngOnInit(): void {
    console.log(this.sharedConfig.appSettings.download_qr_code_image_url);
    
      dataLayer.push({
        event: 'page_show',
        screenPath: 'download-app',
        screenName: 'download-app',
        userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
      });

  }

  download() {
    this.iab.create(this.sharedConfig.appSettings.app_download_url, '_system');
  }
}
