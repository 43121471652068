<div class="top-section">
  <div class="limit-width-on-desktop-float">
    <h2 class="h2-bold">{{ headerText }}</h2>
    <div class="overflow">
      <p class="p-medium">{{ headerSubText }}</p>
    </div>
  </div>
</div>
<div class="details">
  <div class="detail-tabs">
    <div class="tab-title-container">
      <div class="limit-width-on-desktop-float">
        <div *ngIf="asset_type !== AssetType.Other && asset_type !== AssetType.Vaps" class="row">
          <div (click)="showBenefits('essential')" class="button" [ngClass]="{ 'active-button': visibleBenefitsLabel === 'essential' }">Essential</div>
          <div (click)="showBenefits('convenience')" class="button" [ngClass]="{ 'active-button': visibleBenefitsLabel === 'convenience' }">
            Convenience
          </div>
          <div (click)="showBenefits(QuoteComparisonDetailType.Excess)" class="button" [ngClass]="{ 'active-button': visibleBenefitsLabel === QuoteComparisonDetailType.Excess }">Excess</div>
        </div>
        <div *ngIf="asset_type === AssetType.Other" class="row">
          <div
            (click)="showBenefits('Third party legal liability')"
            class="button-other"
            [ngClass]="{ 'active-button-other': visibleBenefitsLabel === 'Third party legal liability' }"
          >
            Third party legal liability
          </div>
          <div
            (click)="showBenefits('Special cover')"
            class="button-other"
            [ngClass]="{ 'active-button-other': visibleBenefitsLabel === 'Special cover' }"
          >
            Special cover
          </div>
        </div>
        <div *ngIf="asset_type === AssetType.Vaps" class="row">
          <div
            *ngFor="let benefitLabel of policyBenefits?.benefit_groups"
            (click)="showVapsBenefits(benefitLabel.description)"
            class="button-other"
            [ngClass]="{ 'active-button-other': visibleVapsBenefitsLabel == benefitLabel.description }"
          >
            {{ benefitLabel.description }}
          </div>
        </div>
      </div>
    </div>
    <div class="tab-content">
      <div class="tab-page limit-width-on-desktop-float">
        <div class="tab-page-row" *ngFor="let item of visibleItems">
          <div class="tab-page-row-description">
            <h5>{{ item.description }}</h5>
          </div>
          <div class="tab-page-row-value tab-page-row-value-yes">
            <ion-icon
              *ngIf="item.isIcon"
              [ngClass]="{ 'icon-fill-primary': item.setColor === 'Yes', 'icon-fill-dark-grey': item.setColor === 'No' }"
              src="{{ item.value }}"
            ></ion-icon>
            <div *ngIf="!item.isIcon">{{ item.value }}</div>
          </div>
        </div>
        <p *ngIf="subGroupItems" style="margin-top: 0">
          <b>{{ subGroupItemsHeading }}</b>
        </p>
        <div class="tab-page-row" *ngFor="let item of subGroupItems">
          <div class="tab-page-row-description">
            <h5>{{ item.description }}</h5>
          </div>
          <div class="tab-page-row-value tab-page-row-value-yes">
            <ion-icon
              *ngIf="item.isIcon"
              [ngClass]="{
                'icon-fill-primary': item.setColor === 'Yes',
                'icon-fill-dark-grey': item.setColor === 'No',
                'icon-fill-dark-grey': item.setColor === ''
              }"
              src="{{ item.value }}"
            ></ion-icon>
            <div *ngIf="!item.isIcon">{{ item.value }}</div>
          </div>
        </div>
      </div>
      <div class="information wallet-info limit-width-on-desktop-float">
        <h6><i>This is only a summary and all these items have further conditions. Please refer to policy wording for more detail.</i></h6>
      </div>
    </div>
  </div>
</div>

