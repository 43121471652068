<ion-menu style="--min-width: 100%" side="start" menuId="sideMenu" contentId="main" [swipeGesture]="false">
  <!-- <ion-header mode="ios" style="position: relative !important" *ngIf="platform.is('ios')" class="side-menu-header">
    <ion-toolbar>
      <ion-button (click)="closeSideMenu()" slot="end">
        <ion-icon src="assets/icon/toolbar-icons/close.svg"></ion-icon>
      </ion-button>
    </ion-toolbar>
  </ion-header> -->
  <ion-content>
    <ion-header mode="ios" class="side-menu-header">
      <ion-toolbar>
        <ion-button (click)="closeSideMenu()" mode="ios" slot="end">
          <ion-icon src="assets/icon/toolbar-icons/close.svg"></ion-icon>
        </ion-button>
      </ion-toolbar>
    </ion-header>
    <div class="margin-top-wrapper-44">
      <div class="header-wrapper">
        <div class="limit-width-on-desktop-float">
          <div class="avatar-wrapper">
            <div class="avatar" (click)="openMyProfile()">
              <h2>
                {{
                  this.sharedConfig?.user?.personal_details?.name?.substring(0, 1) +
                    this.sharedConfig?.user?.personal_details?.surname?.substring(0, 1)
                }}
              </h2>
            </div>
          </div>
          <h2 (click)="openMyProfile()">
            {{ this.sharedConfig?.user?.personal_details?.name }} {{ this.sharedConfig?.user?.personal_details?.surname }}
          </h2>
        </div>
      </div>

      <ion-list class="side-menu-list limit-width-on-desktop-float">
        <!-- Feature Flags -->
        <!-- <ion-item lines="none" (click)="openFeatureFlags()" >
          <ion-icon slot="start" src="assets/icon/genetic-icons/side-menu-icons/key.svg" class="icon-stroke-primary start-icon"></ion-icon>
          <ion-text>Feature Flags</ion-text>
          <ion-icon slot="end" src="assets/icon/genetic-icons/chevron-right.svg" class="icon-stroke-dark end-icon"></ion-icon>
        </ion-item> -->

        <!-- Start new Quote -->
        <ion-item
          *ngIf="
            sharedConfig?.userPermissions?.can_quote &&
            (!sharedConfig.quoteRequest ||
              (sharedConfig.quoteRequest?.status !== QuoteRequestStatusType.Submitted && sharedConfig.quoteRequest?.type === QuoteRequestType.Quote))
          "
          lines="none"
          (click)="openQuoteTab()"
        >
          <ion-icon slot="start" src="assets/icon/genetic-icons/side-menu-icons/quotes.svg" class="icon-fill-primary start-icon"></ion-icon>
          <ion-text
            *ngIf="
              sharedConfig.quoteRequest?.status !== QuoteRequestStatusType.WaitingToAccept &&
              sharedConfig.quoteRequest?.status !== QuoteRequestStatusType.WaitingOnQuotes
            "
            >Get new quotes</ion-text
          >
          <ion-text
            *ngIf="
              sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingToAccept ||
              sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingOnQuotes
            "
            >View quotes</ion-text
          >
          <div
            class="notification"
            *ngIf="
              sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingToAccept ||
              sharedConfig.quoteRequest?.status === QuoteRequestStatusType.WaitingOnQuotes
            "
            slot="end"
          ></div>
          <ion-icon slot="end" src="assets/icon/genetic-icons/chevron-right.svg" class="icon-stroke-dark end-icon"></ion-icon>
        </ion-item>
        <!-- Useful Documents -->
        <ion-item lines="none" (click)="openUsefulDocsPage()">
          <ion-icon slot="start" src="assets/icon/genetic-icons/side-menu-icons/useful-docs.svg" class="icon-stroke-primary start-icon"></ion-icon>
          <ion-text>Useful documents</ion-text>
          <ion-icon slot="end" src="assets/icon/genetic-icons/chevron-right.svg" class="icon-stroke-dark end-icon"></ion-icon>
        </ion-item>
        <!-- Refer a Friend -->
        <ion-item lines="none" (click)="openReferPage()" *ngIf="sharedConfig.appSettings?.refer_a_friend_enabled">
          <ion-icon slot="start" src="assets/icon/genetic-icons/side-menu-icons/user.svg" class="icon-stroke-primary start-icon"></ion-icon>
          <ion-text>Refer a friend</ion-text>
          <ion-icon slot="end" src="assets/icon/genetic-icons/chevron-right.svg" class="icon-stroke-dark end-icon"></ion-icon>
        </ion-item>
        <!-- Motor Group Details -->
        <ion-item lines="none" (click)="openMotorGroupDetailsPage()" *ngIf="sharedConfig.appSettings?.motor_group_details">
          <ion-icon slot="start" src="assets/icon/genetic-icons/side-menu-icons/info.svg" class="icon-stroke-primary start-icon"></ion-icon>
          <ion-text>{{ sharedConfig.appClientDetail?.display_name_full }}</ion-text>
          <ion-icon slot="end" src="assets/icon/genetic-icons/chevron-right.svg" class="icon-stroke-dark end-icon"></ion-icon>
        </ion-item>
        <!-- More Options -->
        <ion-item lines="none" (click)="showMoreOptions()">
          <ion-icon slot="start" src="assets/icon/genetic-icons/side-menu-icons/more.svg" class="icon-stroke-primary start-icon"></ion-icon>
          <ion-text>More</ion-text>
          <ion-icon slot="end" *ngIf="!showMoreOption" src="assets/icon/genetic-icons/chevron-right.svg" class="icon-stroke-dark end-icon"></ion-icon>
          <ion-icon slot="end" *ngIf="showMoreOption" src="assets/icon/genetic-icons/chevron-down.svg" class="icon-stroke-dark end-icon"></ion-icon>
        </ion-item>
        <!-- More Options box -->
        <div class="more-wrapper" *ngIf="showMoreOption">
          <!-- FSCA Licence -->
          <ion-item lines="none" (click)="openFsbPage()">
            <ion-icon slot="start" src="assets/icon/genetic-icons/side-menu-icons/fsca-license.svg" class="icon-stroke-primary start-icon"></ion-icon>
            <ion-text>FSCA licence</ion-text>
            <ion-icon slot="end" src="assets/icon/genetic-icons/chevron-right.svg" class="icon-stroke-dark end-icon"></ion-icon>
          </ion-item>
          <!-- Legal -->
          <a class="no-format-a" href="{{ this.sharedConfig.appSettings.terms_url }}" target="_blank">
            <ion-item lines="none" (click)="openLegalPage()">
              <ion-icon
                slot="start"
                src="assets/icon/genetic-icons/side-menu-icons/legal-stuff.svg"
                class="icon-stroke-primary start-icon"
              ></ion-icon>
              <ion-text>Legal stuff</ion-text>
              <ion-icon slot="end" src="assets/icon/genetic-icons/chevron-right.svg" class="icon-stroke-dark end-icon"></ion-icon>
            </ion-item>
          </a>
          <!-- Update Email -->
          <ion-item lines="none" (click)="openUpdateEmailPage()">
            <ion-icon slot="start" src="assets/icon/genetic-icons/side-menu-icons/key.svg" class="icon-stroke-primary start-icon"> </ion-icon>
            <ion-text>Change email</ion-text>
            <ion-icon slot="end" src="assets/icon/genetic-icons/chevron-right.svg" class="icon-stroke-dark end-icon"></ion-icon>
          </ion-item>
          <!-- Update Password -->
          <ion-item lines="none" (click)="openUpdatePasswordPage()">
            <ion-icon slot="start" src="assets/icon/genetic-icons/side-menu-icons/lock.svg" class="icon-stroke-primary start-icon"> </ion-icon>
            <ion-text>Change password</ion-text>
            <ion-icon slot="end" src="assets/icon/genetic-icons/chevron-right.svg" class="icon-stroke-dark end-icon"></ion-icon>
          </ion-item>
          <!-- Biometrics -->
          <!-- <ion-item lines="none" *ngIf="sharedConfig.isMobileApp">
            <ion-icon
              slot="start"
              src="assets/icon/genetic-icons/side-menu-icons/fingerprint-accepted.svg"
              class="icon-stroke-primary icon-fill-primary start-icon"
            >
            </ion-icon>
          
          </ion-item> -->
          <!-- Download App -->
          <ion-item lines="none" (click)="downloadApp()">
            <ion-icon slot="start" src="assets/icon/genetic-icons/side-menu-icons/download-app.svg" class="icon-stroke-primary start-icon">
            </ion-icon>
            <ion-text>Download app</ion-text>
            <ion-icon slot="end" src="assets/icon/genetic-icons/chevron-right.svg" class="icon-stroke-dark end-icon"></ion-icon>
          </ion-item>
          <hr />
        </div>
        <!-- Log out -->
        <ion-item lines="none" (click)="logOut()">
          <ion-icon slot="start" src="assets/icon/genetic-icons/side-menu-icons/log-out.svg" class="icon-stroke-primary start-icon"></ion-icon>
          <ion-text>Log out</ion-text>
          <ion-icon slot="end" src="assets/icon/genetic-icons/chevron-right.svg" class="icon-stroke-dark end-icon"></ion-icon>
        </ion-item>
        <div class="version-number">
          <p>
            <small *ngIf="sharedConfig.appClientDetail">Version number {{ sharedConfig.appClientDetail?.version }}</small
            ><small *ngIf="!sharedConfig.production && sharedConfig.appClientDetail"> - STAG</small
            ><small *ngIf="sharedConfig.production && sharedConfig.appClientDetail"> - PROD</small>
            <br />
            <small style="color: red; opacity: 0.5; cursor: pointer" (click)="deleteProfile()">Delete profile</small>
          </p>
        </div>
      </ion-list>
    </div>
  </ion-content>
</ion-menu>
<custom-loader *ngIf="showCustomLoader"></custom-loader>
