<question
  [quoteType]="quoteType"
  [asset_type]="asset_type"
  [assetTypeDisplayName]="assetTypeDisplayName"
  (showBackButton)="compShowBackButton($event)"
  (navigateQuestionSummary)="compNavigateQuestionSummary()"
  (navigateQuoteObjectMappingQuestions)="compNavigateQuoteObjectMappingQuestions($event)"
  (navigateQuoteEndorsement)="compNavigateQuoteEndorsement($event)"
  (navigateHomeQuote)="compNavigateHomeQuote()"
  (navigateWalletPage)="compNavigateWalletPage()"
></question>
