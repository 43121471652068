import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, CanActivateChildFn, Router, RouterStateSnapshot } from '@angular/router';
import { FirebaseService, Rbix } from '@takectrl-multiapp/svcs-core';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
class AuthLogGuardService {
  private lastNavigationTime: number = Date.now();
  private currentPage: string = '';

  constructor(private authService: FirebaseService, private rbix: Rbix , private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.currentUserObservable().pipe(
      map((user) => {
        if (user) {
          this.logPageNavigation(this.currentPage, Date.now() - this.lastNavigationTime);
          this.currentPage = state.url;
          this.lastNavigationTime = Date.now();
          return true;
        }
        this.router.navigate(['public-landing']);
        return false;
      }),
      catchError(() => {
        this.router.navigate(['public-landing']);
        return of(false);
      })
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }

  private logPageNavigation(page: string, timeSpent: number) {
    if (page) {
      this.rbix.logPageNavigation(page, timeSpent / 1000); // convert ms to seconds
    }
  }
}

export const canActivate: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
  inject(AuthLogGuardService).canActivate(route, state);
export const canActivateChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
  inject(AuthLogGuardService).canActivateChild(route, state);
