<div class="input-wrapper">
  <ion-item mode="ios" (click)="openCalendar()">
    <ion-label *ngIf="showHeading" position="floating">{{ question.underwriting_answers[0].place_holder }}</ion-label>
    <ion-input type="text" readonly="true" placeholder="YYYY-MM-DD" [(ngModel)]="question.underwriting_answers[0].object_answer"></ion-input>
    <ion-icon name="calendar-outline" slot="end" class="calendar-icon"></ion-icon>
    <!-- <ion-datetime
      *ngIf="sharedConfig.isMobileApp"
      readonly="{{ readOnly }}"
      #datePicker
      class="bold-text"
      (ionChange)="answerValue(datePicker.value)"
      displayFormat="YYYY/MM/DD"
      max="{{ maxDate }}"
      [(ngModel)]="question.underwriting_answers[0].object_answer"
    >
    </ion-datetime>
    <ion-input
      *ngIf="!sharedConfig.isMobileApp"
      readonly="{{ readOnly }}"
      type="date"
      class="bold-text"
      #dateDesktopPicker
      (ionChange)="answerValue(dateDesktopPicker.value)"
      displayFormat="YYYY/MM/DD"
      max="{{ maxDate }}"
      [(ngModel)]="question.underwriting_answers[0].object_answer"
    ></ion-input> -->
  </ion-item>
  <p class="error-message" *ngIf="error">{{ error }}</p>
</div>

<popup-calendar *ngIf="sharedConfig.showCalendarPopup" [heading]="question.underwriting_answers[0].place_holder" (setDate)="answerValue($event)"
  [dateFormat]="'date'" [maxDate]="maxDate" [minDate]="" [ngModal]=""></popup-calendar>
