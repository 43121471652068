<div class="input-wrapper">
  <div
    [style.display]="
      inputTextAddressShowScreenID === question.underwriting_answers[0].object_field + AddressSelectorScreen.Main || sharedConfig.inputTextAddressShowScreenOverrideToMain
        ? 'block'
        : 'none'
    "
  >
    <ion-item mode="ios">
      <ion-label position="floating">Street address</ion-label>
      <ion-input class="bold-text" readonly="true" [(ngModel)]="streetAddress" (click)="changeScreen(AddressSelectorScreen.StreetAddress)"> </ion-input>
    </ion-item>
    <ion-item [style.display]="streetAddress ? 'block' : 'none'" mode="ios">
      <ion-label position="floating">Postal code</ion-label>
      <ion-input class="bold-text" readonly="true" [(ngModel)]="postalCode" (click)="changeScreen(AddressSelectorScreen.PostalCode)"></ion-input>
      <!-- <ion-input *ngIf="postalCode" class="bold-text" readonly="true" [(ngModel)]="postalCode"></ion-input> -->
    </ion-item>
  </div>
  <!-- SCREEN STREETADDRESS (screen 1) -->
  <div
    [style.display]="
      inputTextAddressShowScreenID === question.underwriting_answers[0].object_field + AddressSelectorScreen.StreetAddress &&
      !sharedConfig.inputTextAddressShowScreenOverrideToMain
        ? 'block'
        : 'none'
    "
  >
    <div class="lookup-component-wrapper">
      <ion-item mode="ios" lines="none" class="search-item limit-width-on-desktop-float" native>
        <ion-label position="floating">Street address </ion-label>
        <ion-input
          id="{{ question.underwriting_answers[0].object_field + 'address' }}"
          #streetAddressInput
          class="bold-text"
          mode="ios"
          (ionInput)="searchKeyDown($event)"
          [(ngModel)]="streetAddress"
        ></ion-input>
        <ion-icon name="close" slot="end" (click)="clearInput(AddressSelectorScreen.StreetAddress)"></ion-icon>
      </ion-item>
      <!-- Search Results -->
      <div class="search-results-wrapper limit-width-on-desktop-float">
        <ion-list>
          <div id="{{ question.underwriting_answers[0].object_field + 'results' }}" style="display: none"></div>
          <div *ngFor="let searchedAddress of searchedAddresses">
            <ion-item (click)="selectAddress(searchedAddress, AddressSelectorScreen.StreetAddress)" mode="ios" lines="none">
              <ion-icon slot="start" src="assets/icon/misc-icons/location-marker.svg"></ion-icon>
              <ion-text>
                <p>{{ searchedAddress.name }} {{ searchedAddress.vicinity }}</p>
              </ion-text>
            </ion-item>
            <hr />
          </div>
        </ion-list>
      </div>
      <!-- Nothing Found Error -->
      <div id="{{ question.underwriting_answers[0].object_field + 'error' }}" class="nothing-found-wrapper limit-width-on-desktop-float">
        <h2 class="h2-bold">Hmmm...</h2>
        <p>Your address may not be listed. Please make sure you spelt it correctly, otherwise keep typing and tap done to add.</p>
        <div class="button-wrapper limit-width-on-desktop-float">
          <ion-button (click)="addressNotFound()" class="primary-button" mode="ios">Next</ion-button>
        </div>
      </div>
      <div id="{{ question.underwriting_answers[0].object_field + 'defaultNextButton' }}" class="nothing-found-wrapper limit-width-on-desktop-float">
        <p>If you can't find your address above, please make sure you spelt it correctly, otherwise keep typing and tap Next to add it manually.</p>
        <div class="button-wrapper limit-width-on-desktop-float">
          <ion-button (click)="addressNotFound()" class="primary-button" mode="ios">Next</ion-button>
        </div>
      </div>
    </div>
  </div>

  <!-- SCREEN  POSTAL (screen 2) -->
  <div
    [style.display]="
      inputTextAddressShowScreenID === question.underwriting_answers[0].object_field + AddressSelectorScreen.PostalCode &&
      !sharedConfig.inputTextAddressShowScreenOverrideToMain
        ? 'block'
        : 'none'
    "
  >
    <div class="lookup-component-wrapper">
      <ion-item mode="ios" lines="none" class="search-item limit-width-on-desktop-float" native>
        <ion-label position="floating">Postal code</ion-label>
        <ion-input
          id="{{ question.underwriting_answers[0].object_field + 'postal' }}"
          #postalInput
          class="bold-text"
          mode="ios"
          (ionInput)="searchKeyDown($event)"
          [(ngModel)]="postalCode"
        ></ion-input>
        <ion-icon name="close" slot="end" (click)="clearInput(AddressSelectorScreen.PostalCode)"></ion-icon>
      </ion-item>
      <!-- Search Results -->
      <div class="search-results-wrapper limit-width-on-desktop-float">
        <!-- MNFG -->
        <ion-list *ngIf="!postalCodeIdMNFGUnderwritingAnswer?.object_answer">
          <div *ngFor="let postal of postalCodeAreas?.matched?.mnfg">
            <ion-item (click)="selectPostalCodemnfg(postal)" mode="ios" lines="none">
              <ion-icon slot="start" src="assets/icon/misc-icons/location-marker.svg"></ion-icon>
              <ion-text>
                <p>{{ postal.suburb_name }}</p>
              </ion-text>
            </ion-item>
            <hr />
          </div>
        </ion-list>

        <!-- SA02 -->
        <ion-list *ngIf="postalCodeIdMNFGUnderwritingAnswer?.object_answer && !postalCodeIdSA02UnderwritingAnswer?.object_answer">
          <div *ngFor="let postal of postalCodeAreas?.matched?.sa02">
            <ion-item (click)="selectPostalCodesa02(postal)" mode="ios" lines="none">
              <ion-icon slot="start" src="assets/icon/misc-icons/location-marker.svg"></ion-icon>
              <ion-text>
                <p>{{ postal.suburb_name }}</p>
              </ion-text>
            </ion-item>
            <hr />
          </div>
        </ion-list>

        <!-- SAPO -->
        <ion-list
          *ngIf="
            postalCodeIdMNFGUnderwritingAnswer?.object_answer &&
            postalCodeIdSA02UnderwritingAnswer?.object_answer &&
            !postalCodeIdUnderwritingAnswer?.object_answer
          "
        >
          <div *ngFor="let postal of postalCodeAreas?.matched?.sapo">
            <ion-item (click)="selectPostalCodesapo(postal)" mode="ios" lines="none">
              <ion-icon slot="start" src="assets/icon/misc-icons/location-marker.svg"></ion-icon>
              <ion-text>
                <p>{{ postal.suburb_name }}</p>
              </ion-text>
            </ion-item>
            <hr />
          </div>
        </ion-list>
      </div>
      <!-- Nothing Found Error -->
      <div id="{{ question.underwriting_answers[0].object_field + 'errorPostalCode' }}" class="nothing-found-wrapper limit-width-on-desktop-float">
        <h2 class="h2-bold">Hmmm...</h2>
        <p>We couldn't find your suburb or postal code. Please make sure you spelt it correctly.</p>
      </div>
    </div>
  </div>
</div>

<popup-with-dismiss-button *ngIf="sharedConfig.showPopupWithDismissButton"></popup-with-dismiss-button>
