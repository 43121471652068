import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IAppSettings, IAppCredentials } from '../interfaces/app.interface';
import { IVehicle, IMotorcycle, ICaravan, IBuilding, IContent, ISpecifiedItem, IAllRiskItem } from '../interfaces/asset.interface';
import { IClaimCategories } from '../interfaces/claim.interface';
import {
  IInsuredMotorcycle,
  IInsuredContent,
  IInsuredVehicle,
  IInsuredCaravan,
  IInsuredBuilding,
  IInsuredSpecifiedItem,
  IInsuredAllRisk,
  IInsuredVaps,
} from '../interfaces/insured.interface';
import { IInsurer } from '../interfaces/insurer.interface';
import { IVehicleLookupYearMap, IQuestion } from '../interfaces/question.interface';
import { IQuoteRequest, IQuoteConvertResponse, IQuote, ISpecialConditionAssets } from '../interfaces/quote.interface';
import { IRbixMessageThread } from '../interfaces/rbix.interface';
import { ISchedule, ISchedulesPremiumDetails } from '../interfaces/schedule.interface';
import {  IUser, IUserDataSubscription } from '../interfaces/user.interface';
import { AssetType } from '../types/asset.type';
import { PageType } from '../types/page.type';
import { HttpHeaders } from '@angular/common/http';
import { AddressSelectorScreen, HeadersType } from '@takectrl-multiapp/svcs-share-config';
import firebase from 'firebase/compat/app';
import { UserQuoteState } from '../types/user.type';

@Injectable({
  providedIn: 'root',
})
export class SharedConfigService {
  // User-related state
  private userState = {
    user: new BehaviorSubject<IUser | null>(null),
    userEmail: new BehaviorSubject<string>(''),
    userIsOTPEnabled: new BehaviorSubject<string>(''),
  };

  // Auth-related state
  private authState = {
    firebaseUser: new BehaviorSubject<firebase.User | null>(null),
    firebaseUserEmail: new BehaviorSubject<string>(''),
    firebaseUserKey: new BehaviorSubject<string>(''),
    appCredentials: new BehaviorSubject<IAppCredentials | null>(null),
  };

  // Quote-related state
  private quoteState = {
    currentUserQuoteState: new BehaviorSubject<UserQuoteState>(UserQuoteState.none),
    quoteRequest: new BehaviorSubject<IQuoteRequest | null>(null),
    quoteRequests: new BehaviorSubject<IQuoteRequest[]>([]),
    quoteConvertResponse: new BehaviorSubject<IQuoteConvertResponse | null>(null),
    selectedQuote: new BehaviorSubject<IQuote | null>(null),
    selectedQuoteSpecialConditions: new BehaviorSubject<ISpecialConditionAssets | null>(null),
    selectedQuoteToCompareOne: new BehaviorSubject<IQuote | null>(null),
    selectedQuoteToCompareTwo: new BehaviorSubject<IQuote | null>(null),
    quotesOther: new BehaviorSubject<IQuote[]>([]),
    quoteSelectedAssetTypeDisplayName: new BehaviorSubject<string>(''),
    quoteSelectedAssetType: new BehaviorSubject<AssetType | null>(null),
    recommendedQuote: new BehaviorSubject<IQuote | null>(null),
    acknowledgedQuoteRequestError: new BehaviorSubject<boolean>(false),
    quoteStatusButtonText: new BehaviorSubject<string>('Start a new quote'),
    stopTranscriptionProcessing: new BehaviorSubject<boolean>(false),
    showSetNewPasswordButtonOnLogin: new BehaviorSubject<boolean>(true),
    didViewRenewal: new BehaviorSubject<boolean>(false),
    showPopupWithDismissButton_updateApp: new BehaviorSubject<boolean>(false),
  };

  // Insurance-related state
  private insuranceState = {
    insuredMotorcycles: new BehaviorSubject<IInsuredMotorcycle[]>([]),
    insuredContents: new BehaviorSubject<IInsuredContent[]>([]),
    insuredVehicles: new BehaviorSubject<IInsuredVehicle[]>([]),
    insuredCaravans: new BehaviorSubject<IInsuredCaravan[]>([]),
    insuredBuildings: new BehaviorSubject<IInsuredBuilding[]>([]),
    insuredSpecifiedItems: new BehaviorSubject<IInsuredSpecifiedItem[]>([]),
    insuredAllRisk: new BehaviorSubject<IInsuredAllRisk[]>([]),
    insuredVaps: new BehaviorSubject<IInsuredVaps[]>([]),
    vehicleLookupYearMap: new BehaviorSubject<IVehicleLookupYearMap | null>(null),
    motorcycleLookupYearMap: new BehaviorSubject<IVehicleLookupYearMap | null>(null),
    schedulesActive: new BehaviorSubject<ISchedule[]>([]),
    schedulesActiveInitialCount: new BehaviorSubject<number>(0),
    schedulesWithAssistanceBenefits: new BehaviorSubject<ISchedule[]>([]),
    schedulesPremiumDetails: new BehaviorSubject<ISchedulesPremiumDetails | null>(null),
  };

  // Miscellaneous state
  private miscState = {
    claimCategories: new BehaviorSubject<IClaimCategories[]>([]),
    insurers: new BehaviorSubject<IInsurer[]>([]),
    routeParamUserMsisdn: new BehaviorSubject<string>(''),
    routeParamUserFirstName: new BehaviorSubject<string>(''),
    routeParamUserLastName: new BehaviorSubject<string>(''),
    routeParamUserEmail: new BehaviorSubject<string>(''),
    routeParamUserEmailQuoteOnBehalf: new BehaviorSubject<string>(''),
    routeParamUserPaswrd: new BehaviorSubject<string>(''),
    routeParamDealershipReferralCode: new BehaviorSubject<string>(''),
    routeParamBrokerCode: new BehaviorSubject<string>(''),
    routeParamLogInWithOTP: new BehaviorSubject<string>(''),
    intercomInitialized: new BehaviorSubject<boolean>(false),
    appSettings: new BehaviorSubject<IAppSettings | null>(null),
    userDataSubscription: new BehaviorSubject<IUserDataSubscription | null>(null),
    platformType: new BehaviorSubject<string>(''),
    rbixIsProcessingThread: new BehaviorSubject<boolean>(false),
    rbixMessageThread: new BehaviorSubject<IRbixMessageThread | null>(null),
    onTrackPopupSubheading: new BehaviorSubject<string>(''),
    onTrackPopupHeader: new BehaviorSubject<string>(''),
    production: new BehaviorSubject<boolean>(false),
    regularDriverIdNumber: new BehaviorSubject<string>(''),
    regularDriverLicenseType: new BehaviorSubject<string>(''),
    appSettingsRetrieved: new BehaviorSubject<boolean>(false),
    ctrlApiHeader: new BehaviorSubject<HttpHeaders>(new HttpHeaders()),
    summaryFilteredHeading: new BehaviorSubject<string>('Edit'),
    addingClaim: new BehaviorSubject<boolean>(false),
  };

  // UI-related state
  private uiState = {
    pageHasScrollbar: new BehaviorSubject<boolean>(false),
    allowsRecording: new BehaviorSubject<boolean>(false),
    isRecording: new BehaviorSubject<boolean>(false),
    startRecordingByPassPageDetectChanges: new BehaviorSubject<boolean>(false),
    mediaRecorder: new BehaviorSubject<MediaRecorder | null>(null),
    audio: new BehaviorSubject<HTMLAudioElement>(new Audio()),
    intercomBadgeCount: new BehaviorSubject<number>(0),
    showCalendarPopup: new BehaviorSubject<boolean>(false),
    showFullScreen: new BehaviorSubject<boolean>(false),
    showLottieFullScreen: new BehaviorSubject<boolean>(false),
    showSetNewPasswordComponent: new BehaviorSubject<boolean>(false),
    showFooterTabs: new BehaviorSubject<boolean>(true),
    showHomeQuotePageBackButton: new BehaviorSubject<boolean>(true),
    showHomeQuotePageHamburgerButton: new BehaviorSubject<boolean>(false),
    swipeGesture: new BehaviorSubject<boolean>(false),
    showUpNext: new BehaviorSubject<boolean>(false),
    showPopupWithDismissButton: new BehaviorSubject<boolean>(false),
    headerForPopupWithDismissButton: new BehaviorSubject<string>(''),
    messageForPopupWithDismissButton: new BehaviorSubject<string>(''),
    buttonTextForPopupWithDismissButton: new BehaviorSubject<string>(''),
    popupDialogReturnRoute: new BehaviorSubject<string>(''),
    showPopupWithChat: new BehaviorSubject<boolean>(false),
    headerForPopupWithChat: new BehaviorSubject<string>(''),
    messageForPopupWithChat: new BehaviorSubject<string>(''),
    showCustomLoader: new BehaviorSubject<boolean>(false),
    scheduleHasBeenSetToActive: new BehaviorSubject<boolean>(false),
    showOnTrackPopup: new BehaviorSubject<boolean>(false),
    hideProgressBar: new BehaviorSubject<boolean>(false),
    selectedPage: new BehaviorSubject<PageType | null>(null),
    hideHeader: new BehaviorSubject<boolean>(false),
    inputTextAddressShowScreen: new BehaviorSubject<AddressSelectorScreen>(AddressSelectorScreen.Main),
    inputTextAddressShowScreenOverrideToMain: new BehaviorSubject<boolean>(false),
    showHeaderForType: new BehaviorSubject<HeadersType | null>(null),
    popupDialogAnswerQuestionShow: new BehaviorSubject<boolean>(false),
    popupDialogAnswerSelectedQuestion: new BehaviorSubject<IQuestion | null>(null),
    popupDialogShow: new BehaviorSubject<boolean>(false),
    popupDialogHeader: new BehaviorSubject<string>(''),
    popupDialogSubHeader: new BehaviorSubject<string>(''),
    popupDialogImageUrl: new BehaviorSubject<string>(''),
    popupDialogTextAreaValue: new BehaviorSubject<string>(''),
    popupDialogTextAreaResultsValue: new BehaviorSubject<string>(''),
    popupDialogTextAreaPlaceholder: new BehaviorSubject<string>(''),
    popupDialogButtonThreeText: new BehaviorSubject<string>(''),
    popupDialogButtonOneText: new BehaviorSubject<string>(''),
    popupDialogShowThreeButtons: new BehaviorSubject<boolean>(false),
    popupDialogButtonTopCloseShow: new BehaviorSubject<boolean>(true),
    popupDialogButtonTwoText: new BehaviorSubject<string>(''),
    popupDialogButtonOneCallBack: new BehaviorSubject<Function | null>(null),
    popupDialogButtonTwoCallBack: new BehaviorSubject<Function | null>(null),
    popupDialogButtonThreeCallBack: new BehaviorSubject<Function | null>(null),
  };

  // Selected Asset state
  private selectedAssetState = {
    vehicle: new BehaviorSubject<IVehicle | null>(null),
    motorcycle: new BehaviorSubject<IMotorcycle | null>(null),
    caravan: new BehaviorSubject<ICaravan | null>(null),
    building: new BehaviorSubject<IBuilding | null>(null),
    content: new BehaviorSubject<IContent | null>(null),
    specifiedItem: new BehaviorSubject<ISpecifiedItem | null>(null),
    allRiskItem: new BehaviorSubject<IAllRiskItem | null>(null),
  };

  liveVariables = {
    isQuoteRequestCompletedAndCanGoNext: false,
  };

  // User-related observable getters and setters
  user$ = this.userState.user.asObservable();
  userEmail$ = this.userState.userEmail.asObservable();
  userIsOTPEnabled$ = this.userState.userIsOTPEnabled.asObservable();

  setUser(user: IUser) {
    
    if (user.addresses && (!user.addresses?.risk_address_code_id_sa02 || !user.addresses?.risk_address_code_id_mnfg)) {
      delete user.addresses?.risk_address_code;
      delete user.addresses?.risk_address_code_id;
    } else if (!user.addresses) {
      user.addresses = {};
    }
    user.addresses.risk_address_code = user.addresses.postal_code;
    this.userState.user.next(user);
  }

  setUserEmail(value: string) {
    this.userState.userEmail.next(value);
  }
  setUserIsOTPEnabled(value: string) {
    this.userState.userIsOTPEnabled.next(value);
  }

  // Auth-related observable getters and setters
  firebaseUser$ = this.authState.firebaseUser.asObservable();
  firebaseUserEmail$ = this.authState.firebaseUserEmail.asObservable();
  firebaseUserKey$ = this.authState.firebaseUserKey.asObservable();
  appCredentials$ = this.authState.appCredentials.asObservable();

  setFirebaseUser(value: firebase.User | null) {
    this.authState.firebaseUser.next(value);
  }
  setFirebaseUserEmail(value: string) {
    this.authState.firebaseUserEmail.next(value);
  }
  setFirebaseUserKey(value: string) {
    this.authState.firebaseUserKey.next(value);
  }
  setAppCredentials(value: IAppCredentials | null) {
    this.authState.appCredentials.next(value);
  }

  // Quote-related observable getters and setters
  currentUserQuoteState$ = this.quoteState.currentUserQuoteState.asObservable();
  quoteRequest$ = this.quoteState.quoteRequest.asObservable();
  quoteRequests$ = this.quoteState.quoteRequests.asObservable();
  quoteConvertResponse$ = this.quoteState.quoteConvertResponse.asObservable();
  selectedQuote$ = this.quoteState.selectedQuote.asObservable();
  selectedQuoteSpecialConditions$ = this.quoteState.selectedQuoteSpecialConditions.asObservable();
  selectedQuoteToCompareOne$ = this.quoteState.selectedQuoteToCompareOne.asObservable();
  selectedQuoteToCompareTwo$ = this.quoteState.selectedQuoteToCompareTwo.asObservable();
  quotesOther$ = this.quoteState.quotesOther.asObservable();
  quoteSelectedAssetTypeDisplayName$ = this.quoteState.quoteSelectedAssetTypeDisplayName.asObservable();
  quoteSelectedAssetType$ = this.quoteState.quoteSelectedAssetType.asObservable();
  recommendedQuote$ = this.quoteState.recommendedQuote.asObservable();
  acknowledgedQuoteRequestError$ = this.quoteState.acknowledgedQuoteRequestError.asObservable();
  quoteStatusButtonText$ = this.quoteState.quoteStatusButtonText.asObservable();
  stopTranscriptionProcessing$ = this.quoteState.stopTranscriptionProcessing.asObservable();
  showSetNewPasswordButtonOnLogin$ = this.quoteState.showSetNewPasswordButtonOnLogin.asObservable();
  didViewRenewal$ = this.quoteState.didViewRenewal.asObservable();
  showPopupWithDismissButtonUpdateApp$ = this.quoteState.showPopupWithDismissButton_updateApp.asObservable();

  setQuoteRequest(value: IQuoteRequest | null) {
    this.quoteState.quoteRequest.next(value);
  }
  setQuoteRequests(value: IQuoteRequest[]) {
    this.quoteState.quoteRequests.next(value);
  }
  setQuoteConvertResponse(value: IQuoteConvertResponse | null) {
    this.quoteState.quoteConvertResponse.next(value);
  }
  setSelectedQuote(value: IQuote | null) {
    this.quoteState.selectedQuote.next(value);
  }
  setSelectedQuoteSpecialConditions(value: ISpecialConditionAssets | null) {
    this.quoteState.selectedQuoteSpecialConditions.next(value);
  }
  setSelectedQuoteToCompareOne(value: IQuote | null) {
    this.quoteState.selectedQuoteToCompareOne.next(value);
  }
  setSelectedQuoteToCompareTwo(value: IQuote | null) {
    this.quoteState.selectedQuoteToCompareTwo.next(value);
  }
  setQuotesOther(value: IQuote[]) {
    this.quoteState.quotesOther.next(value);
  }
  setQuoteSelectedAssetTypeDisplayName(value: string) {
    this.quoteState.quoteSelectedAssetTypeDisplayName.next(value);
  }
  setQuoteSelectedAssetType(value: AssetType | null) {
    this.quoteState.quoteSelectedAssetType.next(value);
  }
  setRecommendedQuote(value: IQuote | null) {
    this.quoteState.recommendedQuote.next(value);
  }
  setAcknowledgedQuoteRequestError(value: boolean) {
    this.quoteState.acknowledgedQuoteRequestError.next(value);
  }
  setQuoteStatusButtonText(value: string) {
    this.quoteState.quoteStatusButtonText.next(value);
  }
  setStopTranscriptionProcessing(value: boolean) {
    this.quoteState.stopTranscriptionProcessing.next(value);
  }
  setShowSetNewPasswordButtonOnLogin(value: boolean) {
    this.quoteState.showSetNewPasswordButtonOnLogin.next(value);
  }
  setDidViewRenewal(value: boolean) {
    this.quoteState.didViewRenewal.next(value);
  }
  setShowPopupWithDismissButtonUpdateApp(value: boolean) {
    this.quoteState.showPopupWithDismissButton_updateApp.next(value);
  }

  // Insurance-related observable getters and setters
  insuredMotorcycles$ = this.insuranceState.insuredMotorcycles.asObservable();
  insuredContents$ = this.insuranceState.insuredContents.asObservable();
  insuredVehicles$ = this.insuranceState.insuredVehicles.asObservable();
  insuredCaravans$ = this.insuranceState.insuredCaravans.asObservable();
  insuredBuildings$ = this.insuranceState.insuredBuildings.asObservable();
  insuredSpecifiedItems$ = this.insuranceState.insuredSpecifiedItems.asObservable();
  insuredAllRisk$ = this.insuranceState.insuredAllRisk.asObservable();
  insuredVaps$ = this.insuranceState.insuredVaps.asObservable();
  vehicleLookupYearMap$ = this.insuranceState.vehicleLookupYearMap.asObservable();
  motorcycleLookupYearMap$ = this.insuranceState.motorcycleLookupYearMap.asObservable();
  schedulesActive$ = this.insuranceState.schedulesActive.asObservable();
  schedulesActiveInitialCount$ = this.insuranceState.schedulesActiveInitialCount.asObservable();
  schedulesWithAssistanceBenefits$ = this.insuranceState.schedulesWithAssistanceBenefits.asObservable();
  schedulesPremiumDetails$ = this.insuranceState.schedulesPremiumDetails.asObservable();

  setInsuredMotorcycles(value: IInsuredMotorcycle[]) {
    this.insuranceState.insuredMotorcycles.next(value);
  }
  setInsuredContents(value: IInsuredContent[]) {
    this.insuranceState.insuredContents.next(value);
  }
  setInsuredVehicles(value: IInsuredVehicle[]) {
    this.insuranceState.insuredVehicles.next(value);
  }
  setInsuredCaravans(value: IInsuredCaravan[]) {
    this.insuranceState.insuredCaravans.next(value);
  }
  setInsuredBuildings(value: IInsuredBuilding[]) {
    this.insuranceState.insuredBuildings.next(value);
  }
  setInsuredSpecifiedItems(value: IInsuredSpecifiedItem[]) {
    this.insuranceState.insuredSpecifiedItems.next(value);
  }
  setInsuredAllRisk(value: IInsuredAllRisk[]) {
    this.insuranceState.insuredAllRisk.next(value);
  }
  setInsuredVaps(value: IInsuredVaps[]) {
    this.insuranceState.insuredVaps.next(value);
  }
  setVehicleLookupYearMap(value: IVehicleLookupYearMap | null) {
    this.insuranceState.vehicleLookupYearMap.next(value);
  }
  setMotorcycleLookupYearMap(value: IVehicleLookupYearMap | null) {
    this.insuranceState.motorcycleLookupYearMap.next(value);
  }
  setSchedulesActive(value: ISchedule[]) {
    this.insuranceState.schedulesActive.next(value);
  }
  setSchedulesActiveInitialCount(value: number) {
    this.insuranceState.schedulesActiveInitialCount.next(value);
  }
  setschedulesWithAssistanceBenefits(value: ISchedule[]) {
    this.insuranceState.schedulesWithAssistanceBenefits.next(value);
  }
  setSchedulesPremiumDetails(value: ISchedulesPremiumDetails | null) {
    this.insuranceState.schedulesPremiumDetails.next(value);
  }

  // Miscellaneous state observable getters and setters
  claimCategories$ = this.miscState.claimCategories.asObservable();
  insurers$ = this.miscState.insurers.asObservable();
  routeParamUserMsisdn$ = this.miscState.routeParamUserMsisdn.asObservable();
  routeParamUserFirstName$ = this.miscState.routeParamUserFirstName.asObservable();
  routeParamUserLastName$ = this.miscState.routeParamUserLastName.asObservable();
  routeParamUserEmail$ = this.miscState.routeParamUserEmail.asObservable();
  routeParamUserEmailQuoteOnBehalf$ = this.miscState.routeParamUserEmailQuoteOnBehalf.asObservable();
  routeParamUserPaswrd$ = this.miscState.routeParamUserPaswrd.asObservable();
  routeParamDealershipReferralCode$ = this.miscState.routeParamDealershipReferralCode.asObservable();
  routeParamBrokerCode$ = this.miscState.routeParamBrokerCode.asObservable();
  routeParamLogInWithOTP$ = this.miscState.routeParamLogInWithOTP.asObservable();
  intercomInitialized$ = this.miscState.intercomInitialized.asObservable();
  appSettings$ = this.miscState.appSettings.asObservable();
  userDataSubscription$ = this.miscState.userDataSubscription.asObservable();
  platformType$ = this.miscState.platformType.asObservable();
  rbixIsProcessingThread$ = this.miscState.rbixIsProcessingThread.asObservable();
  rbixMessageThread$ = this.miscState.rbixMessageThread.asObservable();
  onTrackPopupSubheading$ = this.miscState.onTrackPopupSubheading.asObservable();
  onTrackPopupHeader$ = this.miscState.onTrackPopupHeader.asObservable();
  production$ = this.miscState.production.asObservable();
  regularDriverIdNumber$ = this.miscState.regularDriverIdNumber.asObservable();
  regularDriverLicenseType$ = this.miscState.regularDriverLicenseType.asObservable();
  appSettingsRetrieved$ = this.miscState.appSettingsRetrieved.asObservable();
  summaryFilteredHeading$ = this.miscState.summaryFilteredHeading.asObservable();
  addingClaim$ = this.miscState.addingClaim.asObservable();

  setClaimCategories(value: IClaimCategories[]) {
    this.miscState.claimCategories.next(value);
  }

  setInsurers(value: IInsurer[]) {
    this.miscState.insurers.next(value);
  }
  setRouteParamUserMsisdn(value: string) {
    this.miscState.routeParamUserMsisdn.next(value);
  }
  setRouteParamUserFirstName(value: string) {
    this.miscState.routeParamUserFirstName.next(value);
  }
  setRouteParamUserLastName(value: string) {
    this.miscState.routeParamUserLastName.next(value);
  }
  setRouteParamUserEmail(value: string) {
    this.miscState.routeParamUserEmail.next(value);
  }
  setRouteParamUserEmailQuoteOnBehalf(value: string) {
    this.miscState.routeParamUserEmailQuoteOnBehalf.next(value);
  }
  setRouteParamUserPaswrd(value: string) {
    this.miscState.routeParamUserPaswrd.next(value);
  }
  setRouteParamDealershipReferralCode(value: string) {
    this.miscState.routeParamDealershipReferralCode.next(value);
  }
  setRouteParamBrokerCode(value: string) {
    this.miscState.routeParamBrokerCode.next(value);
  }
  setRouteParamLogInWithOTP(value: string) {
    this.miscState.routeParamLogInWithOTP.next(value);
  }
  setIntercomInitialized(value: boolean) {
    this.miscState.intercomInitialized.next(value);
  }
  setAppSettings(value: IAppSettings | null) {
    this.miscState.appSettings.next(value);
  }
  setUserDataSubscription(value: IUserDataSubscription | null) {
    this.miscState.userDataSubscription.next(value);
  }
  setPlatformType(value: string) {
    this.miscState.platformType.next(value);
  }
  setRbixIsProcessingThread(value: boolean) {
    this.miscState.rbixIsProcessingThread.next(value);
  }
  setRbixMessageThread(value: IRbixMessageThread | null) {
    this.miscState.rbixMessageThread.next(value);
  }

  setOnTrackPopupSubheading(value: string) {
    this.miscState.onTrackPopupSubheading.next(value);
  }
  setOnTrackPopupHeader(value: string) {
    this.miscState.onTrackPopupHeader.next(value);
  }
  setProduction(value: boolean) {
    this.miscState.production.next(value);
  }
  setRegularDriverIdNumber(value: string) {
    this.miscState.regularDriverIdNumber.next(value);
  }
  setRegularDriverLicenseType(value: string) {
    this.miscState.regularDriverLicenseType.next(value);
  }
  setAppSettingsRetrieved(value: boolean) {
    this.miscState.appSettingsRetrieved.next(value);
  }
  setSummaryFilteredHeading(value: string) {
    this.miscState.summaryFilteredHeading.next(value);
  }
  setAddingClaim(value: boolean) {
    this.miscState.addingClaim.next(value);
  }

  // UI-related observable getters and setters
  pageHasScrollbar$ = this.uiState.pageHasScrollbar.asObservable();
  allowsRecording$ = this.uiState.allowsRecording.asObservable();
  isRecording$ = this.uiState.isRecording.asObservable();
  startRecordingByPassPageDetectChanges$ = this.uiState.startRecordingByPassPageDetectChanges.asObservable();
  mediaRecorder$ = this.uiState.mediaRecorder.asObservable();
  audio$ = this.uiState.audio.asObservable();
  intercomBadgeCount$ = this.uiState.intercomBadgeCount.asObservable();
  showCalendarPopup$ = this.uiState.showCalendarPopup.asObservable();
  showFullScreen$ = this.uiState.showFullScreen.asObservable();
  showLottieFullScreen$ = this.uiState.showLottieFullScreen.asObservable();
  showSetNewPasswordComponent$ = this.uiState.showSetNewPasswordComponent.asObservable();
  showFooterTabs$ = this.uiState.showFooterTabs.asObservable();
  showHomeQuotePageBackButton$ = this.uiState.showHomeQuotePageBackButton.asObservable();
  showHomeQuotePageHamburgerButton$ = this.uiState.showHomeQuotePageHamburgerButton.asObservable();
  swipeGesture$ = this.uiState.swipeGesture.asObservable();
  showUpNext$ = this.uiState.showUpNext.asObservable();
  showPopupWithDismissButton$ = this.uiState.showPopupWithDismissButton.asObservable();
  headerForPopupWithDismissButton$ = this.uiState.headerForPopupWithDismissButton.asObservable();
  messageForPopupWithDismissButton$ = this.uiState.messageForPopupWithDismissButton.asObservable();
  buttonTextForPopupWithDismissButton$ = this.uiState.buttonTextForPopupWithDismissButton.asObservable();
  popupDialogReturnRoute$ = this.uiState.popupDialogReturnRoute.asObservable();
  showPopupWithChat$ = this.uiState.showPopupWithChat.asObservable();
  headerForPopupWithChat$ = this.uiState.headerForPopupWithChat.asObservable();
  messageForPopupWithChat$ = this.uiState.messageForPopupWithChat.asObservable();
  showCustomLoader$ = this.uiState.showCustomLoader.asObservable();
  scheduleHasBeenSetToActive$ = this.uiState.scheduleHasBeenSetToActive.asObservable();
  showOnTrackPopup$ = this.uiState.showOnTrackPopup.asObservable();
  hideProgressBar$ = this.uiState.hideProgressBar.asObservable();
  selectedPage$ = this.uiState.selectedPage.asObservable();
  hideHeader$ = this.uiState.hideHeader.asObservable();
  inputTextAddressShowScreen$ = this.uiState.inputTextAddressShowScreen.asObservable();
  inputTextAddressShowScreenOverrideToMain$ = this.uiState.inputTextAddressShowScreenOverrideToMain.asObservable();
  showHeaderForType$ = this.uiState.showHeaderForType.asObservable();
  popupDialogAnswerQuestionShow$ = this.uiState.popupDialogAnswerQuestionShow.asObservable();
  popupDialogAnswerSelectedQuestion$ = this.uiState.popupDialogAnswerSelectedQuestion.asObservable();
  popupDialogShow$ = this.uiState.popupDialogShow.asObservable();
  popupDialogHeader$ = this.uiState.popupDialogHeader.asObservable();
  popupDialogSubHeader$ = this.uiState.popupDialogSubHeader.asObservable();
  popupDialogImageUrl$ = this.uiState.popupDialogImageUrl.asObservable();
  popupDialogTextAreaValue$ = this.uiState.popupDialogTextAreaValue.asObservable();
  popupDialogTextAreaResultsValue$ = this.uiState.popupDialogTextAreaResultsValue.asObservable();
  popupDialogTextAreaPlaceholder$ = this.uiState.popupDialogTextAreaPlaceholder.asObservable();
  popupDialogButtonThreeText$ = this.uiState.popupDialogButtonThreeText.asObservable();
  popupDialogButtonOneText$ = this.uiState.popupDialogButtonOneText.asObservable();
  popupDialogShowThreeButtons$ = this.uiState.popupDialogShowThreeButtons.asObservable();
  popupDialogButtonTopCloseShow$ = this.uiState.popupDialogButtonTopCloseShow.asObservable();
  popupDialogButtonTwoText$ = this.uiState.popupDialogButtonTwoText.asObservable();
  popupDialogButtonOneCallBack$ = this.uiState.popupDialogButtonOneCallBack.asObservable();
  popupDialogButtonTwoCallBack$ = this.uiState.popupDialogButtonTwoCallBack.asObservable();
  popupDialogButtonThreeCallBack$ = this.uiState.popupDialogButtonThreeCallBack.asObservable();

  setPageHasScrollbar(value: boolean) {
    this.uiState.pageHasScrollbar.next(value);
  }
  setAllowsRecording(value: boolean) {
    this.uiState.allowsRecording.next(value);
  }
  setIsRecording(value: boolean) {
    this.uiState.isRecording.next(value);
  }
  setStartRecordingByPassPageDetectChanges(value: boolean) {
    this.uiState.startRecordingByPassPageDetectChanges.next(value);
  }
  setMediaRecorder(value: MediaRecorder | null) {
    this.uiState.mediaRecorder.next(value);
  }
  setAudio(value: HTMLAudioElement) {
    this.uiState.audio.next(value);
  }
  setIntercomBadgeCount(value: number) {
    this.uiState.intercomBadgeCount.next(value);
  }
  setShowCalendarPopup(value: boolean) {
    this.uiState.showCalendarPopup.next(value);
  }
  setShowFullScreen(value: boolean) {
    this.uiState.showFullScreen.next(value);
  }
 howLottieFullScreen(value: boolean) {
    this.uiState.showLottieFullScreen.next(value);
  }
  setShowSetNewPasswordComponent(value: boolean) {
    this.uiState.showSetNewPasswordComponent.next(value);
  }
  setShowFooterQuote(value: boolean) {
    this.uiState.showFooterTabs.next(value);
  }
  setShowHomeQuotePageBackButton(value: boolean) {
    this.uiState.showHomeQuotePageBackButton.next(value);
  }
  setShowHomeQuotePageHamburgerButton(value: boolean) {
    this.uiState.showHomeQuotePageHamburgerButton.next(value);
  }
  setSwipeGesture(value: boolean) {
    this.uiState.swipeGesture.next(value);
  }
  setShowUpNext(value: boolean) {
    this.uiState.showUpNext.next(value);
  }
  setShowPopupWithDismissButton(value: boolean) {
    this.uiState.showPopupWithDismissButton.next(value);
  }
  setHeaderForPopupWithDismissButton(value: string) {
    this.uiState.headerForPopupWithDismissButton.next(value);
  }
  setMessageForPopupWithDismissButton(value: string) {
    this.uiState.messageForPopupWithDismissButton.next(value);
  }
  setButtonTextForPopupWithDismissButton(value: string) {
    this.uiState.buttonTextForPopupWithDismissButton.next(value);
  }
  setPopupDialogReturnRoute(value: string) {
    this.uiState.popupDialogReturnRoute.next(value);
  }
  setShowPopupWithChat(value: boolean) {
    this.uiState.showPopupWithChat.next(value);
  }
  setHeaderForPopupWithChat(value: string) {
    this.uiState.headerForPopupWithChat.next(value);
  }
  setMessageForPopupWithChat(value: string) {
    this.uiState.messageForPopupWithChat.next(value);
  }
  setShowCustomLoader(value: boolean) {
    this.uiState.showCustomLoader.next(value);
  }
  setScheduleHasBeenSetToActive(value: boolean) {
    this.uiState.scheduleHasBeenSetToActive.next(value);
  }
  setShowOnTrackPopup(value: boolean) {
    this.uiState.showOnTrackPopup.next(value);
  }
  setHideProgressBar(value: boolean) {
    this.uiState.hideProgressBar.next(value);
  }
  setSelectedPage(value: PageType | null) {
    this.uiState.selectedPage.next(value);
  }
  setHideHeader(value: boolean) {
    this.uiState.hideHeader.next(value);
  }
  setInputTextAddressShowScreen(value: AddressSelectorScreen) {
    this.uiState.inputTextAddressShowScreen.next(value);
  }
  setInputTextAddressShowScreenOverrideToMain(value: boolean) {
    this.uiState.inputTextAddressShowScreenOverrideToMain.next(value);
  }
  setShowHeaderForType(value: HeadersType | null) {
    this.uiState.showHeaderForType.next(value);
  }
  setPopupDialogAnswerQuestionShow(value: boolean) {
    this.uiState.popupDialogAnswerQuestionShow.next(value);
  }
  setPopupDialogAnswerSelectedQuestion(value: IQuestion | null) {
    this.uiState.popupDialogAnswerSelectedQuestion.next(value);
  }
  setPopupDialogShow(value: boolean) {
    this.uiState.popupDialogShow.next(value);
  }
  setPopupDialogHeader(value: string) {
    this.uiState.popupDialogHeader.next(value);
  }
  setPopupDialogSubHeader(value: string) {
    this.uiState.popupDialogSubHeader.next(value);
  }
  setPopupDialogImageUrl(value: string) {
    this.uiState.popupDialogImageUrl.next(value);
  }
  setPopupDialogTextAreaValue(value: string) {
    this.uiState.popupDialogTextAreaValue.next(value);
  }
  setPopupDialogTextAreaResultsValue(value: string) {
    this.uiState.popupDialogTextAreaResultsValue.next(value);
  }
  setPopupDialogTextAreaPlaceholder(value: string) {
    this.uiState.popupDialogTextAreaPlaceholder.next(value);
  }
  setPopupDialogButtonThreeText(value: string) {
    this.uiState.popupDialogButtonThreeText.next(value);
  }
  setPopupDialogButtonOneText(value: string) {
    this.uiState.popupDialogButtonOneText.next(value);
  }
  setPopupDialogShowThreeButtons(value: boolean) {
    this.uiState.popupDialogShowThreeButtons.next(value);
  }
  setPopupDialogButtonTopCloseShow(value: boolean) {
    this.uiState.popupDialogButtonTopCloseShow.next(value);
  }
  setPopupDialogButtonTwoText(value: string) {
    this.uiState.popupDialogButtonTwoText.next(value);
  }
  setPopupDialogButtonOneCallBack(value: Function | null) {
    this.uiState.popupDialogButtonOneCallBack.next(value);
  }
  setPopupDialogButtonTwoCallBack(value: Function | null) {
    this.uiState.popupDialogButtonTwoCallBack.next(value);
  }
  setPopupDialogButtonThreeCallBack(value: Function | null) {
    this.uiState.popupDialogButtonThreeCallBack.next(value);
  }

  // Selected Asset state observable getters and setters
  selectedAssetVehicle$ = this.selectedAssetState.vehicle.asObservable();
  selectedAssetMotorcycle$ = this.selectedAssetState.motorcycle.asObservable();
  selectedAssetCaravan$ = this.selectedAssetState.caravan.asObservable();
  selectedAssetBuilding$ = this.selectedAssetState.building.asObservable();
  selectedAssetContent$ = this.selectedAssetState.content.asObservable();
  selectedAssetSpecifiedItem$ = this.selectedAssetState.specifiedItem.asObservable();
  selectedAssetAllRiskItem$ = this.selectedAssetState.allRiskItem.asObservable();

  setSelectedAssetVehicle(value: IVehicle | null) {
    this.selectedAssetState.vehicle.next(value);
  }
  setSelectedAssetMotorcycle(value: IMotorcycle | null) {
    this.selectedAssetState.motorcycle.next(value);
  }
  setSelectedAssetCaravan(value: ICaravan | null) {
    this.selectedAssetState.caravan.next(value);
  }
  setSelectedAssetBuilding(value: IBuilding | null) {
    this.selectedAssetState.building.next(value);
  }
  setSelectedAssetContent(value: IContent | null) {
    this.selectedAssetState.content.next(value);
  }
  setSelectedAssetSpecifiedItem(value: ISpecifiedItem | null) {
    this.selectedAssetState.specifiedItem.next(value);
  }
  setSelectedAssetAllRiskItem(value: IAllRiskItem | null) {
    this.selectedAssetState.allRiskItem.next(value);
  }

  // Clear SharedConfig method adapted to use nested state
  clearSharedConfig() {
    this.userState.user.next(null);
    this.userState.userEmail.next('');
    this.userState.userIsOTPEnabled.next('');

    this.authState.firebaseUser.next(null);
    this.authState.firebaseUserEmail.next('');
    this.authState.firebaseUserKey.next('');
    this.authState.appCredentials.next(null);

    this.quoteState.quoteRequest.next(null);
    this.quoteState.quoteRequests.next([]);
    this.quoteState.quoteConvertResponse.next(null);
    this.quoteState.selectedQuote.next(null);
    this.quoteState.selectedQuoteSpecialConditions.next(null);
    this.quoteState.selectedQuoteToCompareOne.next(null);
    this.quoteState.selectedQuoteToCompareTwo.next(null);
    this.quoteState.quotesOther.next([]);
    this.quoteState.quoteSelectedAssetTypeDisplayName.next('');
    this.quoteState.quoteSelectedAssetType.next(null);
    this.quoteState.recommendedQuote.next(null);
    this.quoteState.acknowledgedQuoteRequestError.next(false);
    this.quoteState.quoteStatusButtonText.next('Start a new quote');
    this.quoteState.quoteStatusButtonText.next('Start a new quote');
    this.quoteState.stopTranscriptionProcessing.next(false);
    this.quoteState.showSetNewPasswordButtonOnLogin.next(true);
    this.quoteState.didViewRenewal.next(false);
    this.quoteState.showPopupWithDismissButton_updateApp.next(false);

    this.insuranceState.insuredMotorcycles.next([]);
    this.insuranceState.insuredContents.next([]);
    this.insuranceState.insuredVehicles.next([]);
    this.insuranceState.insuredCaravans.next([]);
    this.insuranceState.insuredBuildings.next([]);
    this.insuranceState.insuredSpecifiedItems.next([]);
    this.insuranceState.insuredAllRisk.next([]);
    this.insuranceState.insuredVaps.next([]);
    this.insuranceState.vehicleLookupYearMap.next(null);
    this.insuranceState.motorcycleLookupYearMap.next(null);
    this.insuranceState.schedulesActive.next([]);
    this.insuranceState.schedulesActiveInitialCount.next(0);
    this.insuranceState.schedulesWithAssistanceBenefits.next([]);
    this.insuranceState.schedulesPremiumDetails.next(null);

    this.miscState.claimCategories.next([]);
    this.miscState.insurers.next([]);
    this.miscState.routeParamUserMsisdn.next('');
    this.miscState.routeParamUserFirstName.next('');
    this.miscState.routeParamUserLastName.next('');
    this.miscState.routeParamUserEmail.next('');
    this.miscState.routeParamUserEmailQuoteOnBehalf.next('');
    this.miscState.routeParamUserPaswrd.next('');
    this.miscState.routeParamDealershipReferralCode.next('');
    this.miscState.routeParamBrokerCode.next('');
    this.miscState.routeParamLogInWithOTP.next('');
    this.miscState.intercomInitialized.next(false);
    this.miscState.appSettings.next(null);
    this.miscState.userDataSubscription.next(null);
    this.miscState.platformType.next('');
    this.miscState.rbixIsProcessingThread.next(false);
    this.miscState.rbixMessageThread.next(null);
    this.miscState.onTrackPopupSubheading.next('');
    this.miscState.onTrackPopupHeader.next('');
    this.miscState.production.next(false);
    this.miscState.regularDriverIdNumber.next('');
    this.miscState.regularDriverLicenseType.next('');
    this.miscState.appSettingsRetrieved.next(false);
    this.miscState.ctrlApiHeader.next(new HttpHeaders());
    this.miscState.summaryFilteredHeading.next('Edit');
    this.miscState.addingClaim.next(false);

    this.uiState.pageHasScrollbar.next(false);
    this.uiState.allowsRecording.next(false);
    this.uiState.isRecording.next(false);
    this.uiState.startRecordingByPassPageDetectChanges.next(false);
    this.uiState.mediaRecorder.next(null);
    this.uiState.audio.next(new Audio());
    this.uiState.intercomBadgeCount.next(0);
    this.uiState.showCalendarPopup.next(false);
    this.uiState.showFullScreen.next(false);
    this.uiState.showLottieFullScreen.next(false);
    this.uiState.showSetNewPasswordComponent.next(false);
    this.uiState.showFooterTabs.next(true);
    this.uiState.showHomeQuotePageBackButton.next(true);
    this.uiState.showHomeQuotePageHamburgerButton.next(false);
    this.uiState.swipeGesture.next(false);
    this.uiState.showUpNext.next(false);
    this.uiState.showPopupWithDismissButton.next(false);
    this.uiState.headerForPopupWithDismissButton.next('');
    this.uiState.messageForPopupWithDismissButton.next('');
    this.uiState.buttonTextForPopupWithDismissButton.next('');
    this.uiState.popupDialogReturnRoute.next('');
    this.uiState.showPopupWithChat.next(false);
    this.uiState.headerForPopupWithChat.next('');
    this.uiState.messageForPopupWithChat.next('');
    this.uiState.showCustomLoader.next(false);
    this.uiState.scheduleHasBeenSetToActive.next(false);
    this.uiState.showOnTrackPopup.next(false);
    this.uiState.hideProgressBar.next(false);
    this.uiState.selectedPage.next(null);
    this.uiState.hideHeader.next(false);
    this.uiState.inputTextAddressShowScreen.next(AddressSelectorScreen.Main);
    this.uiState.inputTextAddressShowScreenOverrideToMain.next(false);
    this.uiState.showHeaderForType.next(null);
    this.uiState.popupDialogAnswerQuestionShow.next(false);
    this.uiState.popupDialogAnswerSelectedQuestion.next(null);
    this.uiState.popupDialogShow.next(false);
    this.uiState.popupDialogHeader.next('');
    this.uiState.popupDialogSubHeader.next('');
    this.uiState.popupDialogImageUrl.next('');
    this.uiState.popupDialogTextAreaValue.next('');
    this.uiState.popupDialogTextAreaResultsValue.next('');
    this.uiState.popupDialogTextAreaPlaceholder.next('');
    this.uiState.popupDialogButtonThreeText.next('');
    this.uiState.popupDialogButtonOneText.next('');
    this.uiState.popupDialogShowThreeButtons.next(false);
    this.uiState.popupDialogButtonTopCloseShow.next(true);
    this.uiState.popupDialogButtonTwoText.next('');
    this.uiState.popupDialogButtonOneCallBack.next(null);
    this.uiState.popupDialogButtonTwoCallBack.next(null);
    this.uiState.popupDialogButtonThreeCallBack.next(null);

    this.selectedAssetState.vehicle.next(null);
    this.selectedAssetState.motorcycle.next(null);
    this.selectedAssetState.caravan.next(null);
    this.selectedAssetState.building.next(null);
    this.selectedAssetState.content.next(null);
    this.selectedAssetState.specifiedItem.next(null);
    this.selectedAssetState.allRiskItem.next(null);
  }
}
