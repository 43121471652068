import { AfterViewInit, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { SharedConfig, SharedDialogService, SharedEncryptionService } from '@takectrl-multiapp/svcs-share-config';
import { UIAppInitializationService } from '../../services/ui-app-initialization.service';
import { BUSNUserService } from '@takectrl-multiapp/svcs-core-business';
import firebase from 'firebase/compat/app';
import '@lottiefiles/lottie-player';
declare const dataLayer: any[];
import { ApiAuthService } from '@takectrl-multiapp/svcs-ctrl-api';
import { ServiceWorkerService } from '@takectrl-multiapp/svcs-core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Component({
  selector: 'login-with-otp',
  templateUrl: './login-with-otp.component.html',
  styleUrls: ['./login-with-otp.component.scss'],
})
export class LoginWithOtpComponent implements AfterViewInit {
  public loginForm: FormGroup;
  public passwordType = 'password';
  public passwordIcon = 'view';
  public passwordError = false;
  public emailError = false;
  msisdnError = false;
  public disableButtonCell = true;
  // private password: string = 'Testing1234!';
  // public userEmail: string = 'aylin.bothafifiteenaugustrefactor@takectrl.co.za';
  showSmallPopup = false;
  smallPopupHeader: string;
  smallPopupMessage: string;
  public disableButtonPassword = false;
  public disableButtonEmail = false;
  public biometricsLoginSetup: string;
  loaderElement: HTMLIonLoadingElement;

  recaptchaVerifier: firebase.auth.RecaptchaVerifier;
  confirmationResult: firebase.auth.ConfirmationResult;

  phoneNumber = SharedConfig.routeParamUserMsisdn;
  //phoneNumber = prompt("Please enter your phone number:");

  showOTPScreen = false;
  verificationCodeInputOne: string;
  verificationCodeInputTwo: string;
  verificationCodeInputThree: string;
  verificationCodeInputFour: string;
  verificationCodeInputFive: string;
  verificationCodeInputSix: string;

  public hasFocus: string;
  public otpError = false;
  public displayNumber: string;
  public didResend = false;
  private intervalLoader: any;
  private intervalResent: any;
  completeVerificationCode: string;
  public showLoader = false;
  public maxLength = 6;
  @Output() routeUserToCorrectHomePage: EventEmitter<unknown> = new EventEmitter();
  @Output() navToResetPasswordPage: EventEmitter<unknown> = new EventEmitter();

  @ViewChild('otpInputOne') otpInputOne: { setFocus: () => void };
  @ViewChild('otpInputTwo') otpInputTwo: { setFocus: () => void };
  @ViewChild('otpInputThree') otpInputThree: { setFocus: () => void };
  @ViewChild('otpInputFour') otpInputFour: { setFocus: () => void };
  @ViewChild('otpInputFive') otpInputFive: { setFocus: () => void };
  @ViewChild('otpInputSix') otpInputSix: { setFocus: () => void };
  retryCount = 0;

  constructor(
    public formBuilder: FormBuilder,
    public apiAuthService: ApiAuthService,
    public userService: BUSNUserService,
    public sharedConfig: SharedConfig,
    public sharedEncryptionService: SharedEncryptionService,
    private readonly serviceWorkerService: ServiceWorkerService,
    public alertController: AlertController,
    private readonly sharedDialogService: SharedDialogService,
    public angularFireAuth: AngularFireAuth,
    public uiAppInitializationService: UIAppInitializationService,
    public busnThirdPartyService: BUSNThirdPartyService
  ) {}

  async ngAfterViewInit(): Promise<void> {
    if (this.phoneNumber) {
      const routeNumber = this.phoneNumber;
      const firstPartOfNumber = routeNumber.substring(3, 5);
      const middlePartOfNumber = routeNumber.substring(5, 8);
      const lastPartOfNumber = routeNumber.substring(8, 12);
      this.displayNumber = `0${firstPartOfNumber} ${middlePartOfNumber} ${lastPartOfNumber}`;

      console.log('Initializing reCAPTCHA...');

      this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        size: 'invisible',
      });

      await this.recaptchaVerifier
        .render()
        .then((widgetId) => {
          console.log('reCAPTCHA rendered, widgetId:', widgetId);
        })
        .catch((err) => {
          console.error('Error rendering reCAPTCHA:', err);
        });

      this.otpInputOne.setFocus();
      this.retryCount = 0;
      await this.sendOTP();
    }

    dataLayer.push({
      event: 'page_show',
      screenPath: 'login-with-otp',
      screenName: 'login-with-otp',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }

  async sendOTP() {
    this.sharedConfig.setShowCustomLoader(true);
    try {
      this.confirmationResult = await this.angularFireAuth.signInWithPhoneNumber(this.phoneNumber, this.recaptchaVerifier).then((result) => {
        console.log(result);
        return result;
      });
      this.sharedConfig.setShowCustomLoader(false);
    } catch (error) {
      this.sharedConfig.setShowCustomLoader(false);
      console.error('Firebase Error Code:', error.code);
      this.sharedDialogService.showPopupDialog({
        header: 'Verification Failed',
        subHeader: error.message,
        buttonOneText: 'OK',
        buttonOneCallback: this.sharedDialogService.hidePopupDialog,
      });
    }
  }

  resendOTP() {
    let loaderCounter = 2;
    this.showLoader = true;
    this.sendOTP();
    this.intervalLoader = setInterval(() => {
      loaderCounter = loaderCounter - 1;
      if (loaderCounter === 0) {
        this.showLoader = false;
        this.showResent();
        clearInterval(this.intervalLoader);
      }
    }, 1000);
  }

  showResent() {
    let codeResentCounter = 2;
    this.didResend = true;
    this.intervalResent = setInterval(() => {
      codeResentCounter = codeResentCounter - 1;
      if (codeResentCounter === 0) {
        this.didResend = false;
        clearInterval(this.intervalResent);
      }
    }, 1000);
  }

  setFocusOne(elementHasFocus: string) {
    this.hasFocus = elementHasFocus;
    if (elementHasFocus === 'otpOne') {
      this.verificationCodeInputOne = '';
    }
    if (elementHasFocus === 'otpTwo') {
      this.verificationCodeInputTwo = '';
    }
    if (elementHasFocus === 'otpThree') {
      this.verificationCodeInputThree = '';
    }
    if (elementHasFocus === 'otpFour') {
      this.verificationCodeInputFour = '';
    }
    if (elementHasFocus === 'otpFive') {
      this.verificationCodeInputFive = '';
    }
    if (elementHasFocus === 'otpSix') {
      this.verificationCodeInputSix = '';
    }
  }

  backspaceInputTwo(event: { keyCode: any; charCode: any }) {
    const key = event.keyCode || event.charCode;
    if (key === 8 || key === 46) {
      this.verificationCodeInputOne = '';
      this.verificationCodeInputTwo = '';
      this.otpInputOne.setFocus();
    }
  }

  backspaceInputThree(event: { keyCode: any; charCode: any }) {
    const key = event.keyCode || event.charCode;
    if (key === 8 || key === 46) {
      this.verificationCodeInputTwo = '';
      this.verificationCodeInputThree = '';
      this.otpInputTwo.setFocus();
    }
  }

  backspaceInputFour(event: { keyCode: any; charCode: any }) {
    const key = event.keyCode || event.charCode;
    if (key === 8 || key === 46) {
      this.verificationCodeInputThree = '';
      this.verificationCodeInputFour = '';
      this.otpInputThree.setFocus();
    }
  }

  backspaceInputFive(event: { keyCode: any; charCode: any }) {
    const key = event.keyCode || event.charCode;
    if (key === 8 || key === 46) {
      this.verificationCodeInputFour = '';
      this.verificationCodeInputFive = '';
      this.otpInputFour.setFocus();
    }
  }

  backspaceInputSix(event: { keyCode: any; charCode: any }) {
    const key = event.keyCode || event.charCode;
    if (key === 8 || key === 46) {
      this.verificationCodeInputFive = '';
      this.verificationCodeInputSix = '';
      this.otpInputFive.setFocus();
    }
  }

  onChangeOtpInputOne(e: Event) {
    this.otpError = false;
    const value: string = e.target['value'];
    if (value.length === 6) {
      this.verificationCodeInputOne = value.substring(0, 1);
      this.verificationCodeInputTwo = value.substring(1, 2);
      this.verificationCodeInputThree = value.substring(2, 3);
      this.verificationCodeInputFour = value.substring(3, 4);
      this.verificationCodeInputFive = value.substring(4, 5);
      this.verificationCodeInputSix = value.substring(5, 6);
      this.otpInputSix.setFocus();
    } else {
      this.verificationCodeInputOne = value;
      if (this.verificationCodeInputOne.length > 0) {
        this.otpInputTwo.setFocus();
      } else {
        this.otpInputOne.setFocus();
      }
    }
    this.verifyLoginCode();
  }

  onChangeOtpInputTwo(e: Event) {
    this.otpError = false;
    this.verificationCodeInputTwo = e.target['value'];
    if (this.verificationCodeInputTwo.length > 0) {
      this.otpInputThree.setFocus();
    } else {
      this.otpInputTwo.setFocus();
    }
    this.verifyLoginCode();
  }

  onChangeOtpInputThree(e: Event) {
    this.otpError = false;
    this.verificationCodeInputThree = e.target['value'];
    if (this.verificationCodeInputThree.length > 0) {
      this.otpInputFour.setFocus();
    } else {
      this.otpInputThree.setFocus();
    }
    this.verifyLoginCode();
  }

  onChangeOtpInputFour(e: Event) {
    this.otpError = false;
    this.verificationCodeInputFour = e.target['value'];
    if (this.verificationCodeInputFour.length > 0) {
      this.otpInputFive.setFocus();
    } else {
      this.otpInputFour.setFocus();
    }
    this.verifyLoginCode();
  }

  onChangeOtpInputFive(e: Event) {
    this.otpError = false;
    this.verificationCodeInputFive = e.target['value'];
    if (this.verificationCodeInputFive.length > 0) {
      this.otpInputSix.setFocus();
    } else {
      this.otpInputFive.setFocus();
    }
    this.verifyLoginCode();
  }

  onChangeOtpInputSix(e: Event) {
    this.otpError = false;
    this.verificationCodeInputSix = e.target['value'];
    if (this.verificationCodeInputSix.length > 0) {
      this.verifyLoginCode();
    } else {
      this.otpInputSix.setFocus();
    }
  }

  async verifyLoginCode() {
    this.completeVerificationCode =
      this.verificationCodeInputOne +
      this.verificationCodeInputTwo +
      this.verificationCodeInputThree +
      this.verificationCodeInputFour +
      this.verificationCodeInputFive +
      this.verificationCodeInputSix;
    if (
      this.verificationCodeInputOne &&
      this.verificationCodeInputTwo &&
      this.verificationCodeInputThree &&
      this.verificationCodeInputFour &&
      this.verificationCodeInputFive &&
      this.verificationCodeInputSix
    ) {
      this.sharedConfig.setShowCustomLoader(true);
      try {
        const userCredential = await this.confirmationResult.confirm(this.completeVerificationCode);
        if (userCredential) {
          this.sharedConfig.setFirebaseUser(userCredential.user);
          this.sharedConfig.setFirebaseUserKey(userCredential.user.uid);
          this.sharedConfig.setFirebaseUserEmail(userCredential.user.email);

          SharedConfig.showSetNewPasswordComponent = true;
          SharedConfig.showFooterTabs = false;
          this.sharedConfig.setShowCustomLoader(false);
         this.routeUserToCorrectHomePage.emit();
  }
      } catch (error) {
        this.otpError = true;
        console.log(error, 'Incorrect code entered?');
        this.sharedConfig.setShowCustomLoader(false);
      }
    }
  }

  setupViaEmail() {
    this.navToResetPasswordPage.emit();
  }

  // compNavigateForwardToPage() {
  //   this.routeUserToCorrectHomePage.emit();
  // }
}
