import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedConfigService } from './services/shared-config.service'; // Adjust the path accordingly

// latest  module to expand on.
@NgModule({
  imports: [CommonModule],
  providers: [SharedConfigService],
})
export class SvcsSharedModule {}
