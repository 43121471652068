<div class="backdrop"></div>
<div class="popup-standard limit-width-on-desktop-float">
  <div class="text-center">
    <h2 class="h2-bold">{{ sharedConfig.headerForPopupWithChat }}</h2>
  </div>
  <div class="sub-header">
    <p>{{ sharedConfig.messageForPopupWithChat }}</p>
  </div>

    <div class="buttons-wrapper">
    <ion-button mode="ios" class="primary-button" (click)="openChat()"> Contact us</ion-button>
    <ion-button mode="ios" class="secondary-button" (click)="dismiss()"> Cancel </ion-button>
  </div>
</div>
