import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SharedConfig } from '@takectrl-multiapp/svcs-share-config';
declare const dataLayer: unknown[];

@Component({
  selector: 'claim-empty-state',
  templateUrl: './claim-empty-state.component.html',
  styleUrls: ['./claim-empty-state.component.scss'],
})
export class ClaimEmptyStateComponent implements OnInit {
  constructor(
    public sharedConfig: SharedConfig
  ) {}

  ngOnInit(): void {
    dataLayer.push({
      event: 'page_show',
      screenPath: 'claim-empty-state',
      screenName: 'claim-empty-state',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }

  async ngAfterViewInit(): Promise<void> {}
}
