import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import {
  IPopiaConsentFeedback,
  SharedConfig,
} from '@takectrl-multiapp/svcs-share-config';

@Injectable({
  providedIn: 'root',
})
export class BusnPopiaService {
  constructor(
    private readonly alertController: AlertController,
    private readonly sharedConfig: SharedConfig
  ) {}

  public async popiaConsentGivenByUser(): Promise<IPopiaConsentFeedback> {
    let resolveFunction: (confirm: IPopiaConsentFeedback) => void;
    const promise = new Promise<IPopiaConsentFeedback>((resolve) => {
      resolveFunction = resolve;
    });
    const message = `${this.sharedConfig.appSettings.popi_consent.consent_message  } ${  this.sharedConfig.appSettings.popi_consent.consent_link}`;
    const header = this.sharedConfig.appSettings.popi_consent.consent_title;
    const consentAlert = await this.alertController.create({
      header,
      message,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Accept',
          handler: () =>
            resolveFunction({
              consent_given: true,
              consent_message: message,
            }),
        },
        {
          text: 'Decline',
          handler: async () => {
            const denyAlert = await this.alertController.create({
              header,
              message: this.sharedConfig.appSettings.popi_consent.deny_message,
              backdropDismiss: false,
              buttons: [
                {
                  text: 'Dismiss',
                  handler: () =>
                    resolveFunction({
                      consent_given: false,
                      consent_message: message,
                    }),
                },
              ],
            });
            await denyAlert.present();
          },
        },
      ],
    });
    await consentAlert.present();
    return promise;
  }
}
