import { IPastFNA, IPriorityRating, SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import { Injectable } from '@angular/core';
import { APIHttpService } from './api-http.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable } from 'rxjs';
import { ICtrlResponse } from '../interfaces/api.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiNeedsAnalysisService {
  constructor(private readonly ctrlApiHttpService: APIHttpService, public firebase: AngularFireDatabase) {}

  async getQuoteFinancialNeedAnalysis(quoteRequestId: number): Promise<IPastFNA> {
    const userUid = SharedConfig.firebaseUserKey;
    const response = this.firebase.database.ref(`/needsAnalysis/${userUid}/${quoteRequestId}`).once('value');
    return (await response).val() as IPastFNA;
  }

  updateFinancialNeedsAnalysis(
    quote_request_id: number,
    premium_vs_core_cover: number,
    premium_vs_nice_to_haves: number,
    premium_vs_low_excess: number,
    core_cover_vs_nice_to_haves: number,
    core_cover_vs_low_excess: number,
    nice_to_haves_vs_low_excess: number
  ): Observable<ICtrlResponse<IPriorityRating[]>> {
    const needsAnalysisPayload = {
      premium_vs_core_cover: premium_vs_core_cover,
      premium_vs_nice_to_haves: premium_vs_nice_to_haves,
      premium_vs_low_excess: premium_vs_low_excess,
      core_cover_vs_nice_to_haves: core_cover_vs_nice_to_haves,
      core_cover_vs_low_excess: core_cover_vs_low_excess,
      nice_to_haves_vs_low_excess: nice_to_haves_vs_low_excess,
      quote_request_id: quote_request_id,
    };
    return this.ctrlApiHttpService.post(`/needs-analysis/`, needsAnalysisPayload);
  }

  getFinancialNeedsAnalysis(): Observable<unknown> {
    return this.ctrlApiHttpService.get(`/needs-analysis/`);
  }
}
