import { Component, OnInit } from '@angular/core';
import { BUSNQuestionService, BUSNQuoteScheduleService } from '@takectrl-multiapp/svcs-core-business';
import { IQuestion, QuestionType, SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import * as _ from 'lodash';
@Component({
  selector: 'popup-dialog-question-answer',
  templateUrl: './popup-dialog-question-answer.component.html',
  styleUrls: ['./popup-dialog-question-answer.component.scss'],
})
export class PopupDialogQuestionAnswerComponent implements OnInit {
  setBottom: boolean;
  cloned_popupDialogAnswerSelectedQuestion: IQuestion;
  QuestionType = QuestionType;
  constructor(
    public sharedConfig: SharedConfig,
    public busnQuestionService: BUSNQuestionService,
    public busnQuoteScheduleService: BUSNQuoteScheduleService
  ) {}

  ngOnInit(): void {
    console.log('POPUP DIALOG QUESTION ANSWER INITIALISED!');
    if (SharedConfig.showFooterTabs) {
      this.setBottom = true;
    } else {
      this.setBottom = false;
    }
  }

  // Normally the OK button
  buttonOneAction() {
    SharedConfig.popupDialogButtonOneCallBack(SharedConfig.popupDialogAnswerSelectedQuestion);
  }

  // Normally the cancel button
  buttonTwoAction() {
    SharedConfig.popupDialogButtonTwoCallBack();
  }

  answerQuestion(answeredQuestion: IQuestion) {
    if (SharedConfig.popupDialogAnswerSelectedQuestion.type !== QuestionType.multiSelect) {
      SharedConfig.popupDialogButtonOneCallBack(answeredQuestion);
    }
  }

  onTextAreaChange(e: Event) {
    SharedConfig.popupDialogTextAreaValue = e.target['value'];
  }

  nextOnEnter(event) {
    const key = event.keyCode;
    if (key == 13) {
      this.buttonOneAction();
    }
  }
}
