import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IQuestion } from '@takectrl-multiapp/svcs-share-config';

@Component({
  selector: 'input-check-list-multi-select',
  templateUrl: './input-check-list-multi-select.component.html',
  styleUrls: ['./input-check-list-multi-select.component.scss'],
})
export class InputCheckListMultiSelectComponent implements OnInit {
  @Input() question: IQuestion;
  @Input() showHeading: boolean;
  @Input() readOnly: boolean;
  @Output() answerQuestion: EventEmitter<IQuestion> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {
    for (const underwritingAnswer of this.question.underwriting_answers) {
      if (underwritingAnswer.object_answer && typeof underwritingAnswer.object_answer !== 'boolean') {
        underwritingAnswer.object_answer = false;
      }
    }
    const someUndefined = this.question.underwriting_answers.find((x) => x.object_answer === undefined);
    const notUndefined = this.question.underwriting_answers.find((x) => x.object_answer !== undefined);

    const partial = someUndefined && notUndefined ? true : false;

    if (partial) {
      for (let index = 0; index < this.question.underwriting_answers.length; index++) {
        const underwritingAnswer = this.question.underwriting_answers[index];
        if (underwritingAnswer.object_answer === undefined) {
          this.question.underwriting_answers[index].object_answer = false;
        }
      }
    }
  }

  answerValue(index) {
    if (this.question.underwriting_answers[index].object_answer === false || this.question.underwriting_answers[index].object_answer === undefined || this.question.underwriting_answers[index].object_answer === null) {
      this.question.underwriting_answers[index].object_answer = true;
    } else {
      this.question.underwriting_answers[index].object_answer = false;
    }

    this.question.underwriting_answers.forEach((e) => {
      if (e.object_answer === undefined || e.object_answer === null) {
        e.object_answer = false;
      }
    });
    this.answerQuestion.emit(this.question);
  }

  isNoneSelectedButtonChecked(): boolean {
    let isSelected = false;
    if (this.question.underwriting_answers.find((x) => x.object_answer === undefined || x.object_answer === null)) {
      isSelected = false;
    } else {
      if (this.question.underwriting_answers.find((x) => x.object_answer === true)) {
        isSelected = false;
      } else {
        isSelected = true;
      }
    }
    return isSelected;
  }

  noneSelectedDeselectAllOtherAnswers() {
    for (const underwritingAnswer of this.question.underwriting_answers) {
      underwritingAnswer.object_answer = false;
    }
    this.answerQuestion.emit(this.question);
  }
}
