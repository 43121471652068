<div class="padding-workaround-because-of-global-overrides"></div>
<ion-list class="chat-container">
  <div *ngFor="let message of sharedConfig.rbixMessageThread?.messages" class="chat-message" [class.incoming]="message.from === 'system'" [class.outgoing]="message.from !== 'system'">
    <div class="message-bubble">
      <div [innerHTML]="formatMessage(message.text)"></div>
      <div class="message-time">{{ message.date | date : 'shortTime' }}</div>
    </div>
  </div>

  <div *ngIf="sharedConfig.rbixIsProcessingThread" class="chat-message" [class.incoming]="true">
    <div class="message-bubble">
      ...
    </div>
  </div>
</ion-list>
<div class="padding-workaround-because-of-global-overrides-bottom"></div>