import { IQuoteExcess, SharedConfig, IPrevousClaims, IVehicle, SharedDialogService, AssetType, QuoteComparisonDetailType, IInsuredAsset, IClaimCategories, IClaimsReason, ClaimsTriggerType } from '@takectrl-multiapp/svcs-share-config';
import { AfterViewChecked, Component, OnInit, ViewChild } from '@angular/core';
import { ActionSheetController, IonContent, IonSlides } from '@ionic/angular';
import { AlertController, Platform } from '@ionic/angular';
import {
  BUSNClaimsService,
  BUSNPolicyService,
  BUSNQuestionService,
  BUSNQuoteScheduleService,
  BUSNAssetService,
} from '@takectrl-multiapp/svcs-core-business';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { InsurerIdType } from 'libs/svcs-share-config/src/lib/types/schedule.type';
declare const dataLayer: unknown[];

@Component({
  selector: 'claim-on-active-policy',
  templateUrl: './claim-on-active-policy.component.html',
  styleUrls: ['./claim-on-active-policy.component.scss'],
})
export class ClaimOnActivePolicyComponent implements OnInit, AfterViewChecked {
  @ViewChild(IonContent, { static: false }) public content: IonContent;
  @ViewChild('claimSlide') slides: IonSlides;
  public hasSchedule = false;
  private readonly noInternet = false;
  public selectedAssetsArray = this.busnClaimService.selectedAssetsArray;
  clientName = `${this.sharedConfig.user.personal_details.name} ${this.sharedConfig.user.personal_details.surname}`;
  claimNumber: string;
  claimEmail: string;
  selectedInsurerId: string;
  smallPopupHeader: string;
  smallPopupMessage: string;
  smallPopupButtonText: string;
  whichPopup: string;
  selectedItem: IInsuredAsset;
  claimReason: string;
  claimReasons: IClaimCategories;
  showClaimReasonPopup = false;
  selectedClaim: IPrevousClaims = {};
  previousClaims: Array<IPrevousClaims> = [];
  showActionSheetPopup = false;
  clientChoiceOnClaim: string;
  selected = false;
  AssetType = AssetType;
  constructor(
    public actionSheetController: ActionSheetController,
    public busnClaimService: BUSNClaimsService,
    public alertController: AlertController,
    public busnPolicyService: BUSNPolicyService,
    public busnThirdPartyService: BUSNThirdPartyService,
    public sharedConfig: SharedConfig,
    private readonly platform: Platform,
    public busnQuestionService: BUSNQuestionService,
    public busnQuoteScheduleService: BUSNQuoteScheduleService,
    public busnAssetService: BUSNAssetService,
    public sharedDialogService: SharedDialogService
  ) {}

  ngOnInit(): void {
    dataLayer.push({
      event: 'page_show',
      screenPath: 'claim-on-active-policy',
      screenName: 'claim-on-active-policy',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });

    this.busnClaimService.getClaimCategories().then((claims) => {
      SharedConfig.claimCategories = claims;
    });
    this.callClaim = this.callClaim.bind(this);
    this.phoneOrEmail = this.phoneOrEmail.bind(this);
    this.phoneClaimsDepartment = this.phoneClaimsDepartment.bind(this);
    this.pleaseContactMe = this.pleaseContactMe.bind(this);
    this.closeActionSheetPopup = this.closeActionSheetPopup.bind(this);
  }

  ngAfterViewInit() {
    if (this.content) {
      this.content.getScrollElement().then((scrollElement) => {
        SharedConfig.pageHasScrollbar = scrollElement.scrollHeight > scrollElement.clientHeight;
      });
    }
  }

  ngAfterViewChecked(): void {}

  async showActionSheet() {
    this.busnPolicyService.getClaimNumbers().then((claimNumbers) => {
      if (claimNumbers)
        for (const id of Object.keys(claimNumbers)) {
          if (id === this.selectedInsurerId) {
            const insurerClaimContactDetails = claimNumbers[id];
            this.claimNumber = insurerClaimContactDetails.number;
            this.claimEmail = insurerClaimContactDetails.email;
          }
        }
    });
    this.sharedDialogService.showPopupDialog({
      header: 'How would you like to process your claim',
      popupDialogShowThreeButtons: true,
      buttonOneText: 'Phone claims department',
      buttonOneCallback: this.phoneClaimsDepartment,
      buttonTwoText: 'Please contact me',
      buttonTwoCallback: this.pleaseContactMe,
      buttonThreeText: 'Close',
      buttonThreeCallback: this.closeActionSheetPopup,
    });
  }

  phoneClaimsDepartment() {
    this.clientChoiceOnClaim = 'phone';
    this.closeButDontClear();
    if (this.selectedItem.type === AssetType.AllRisks || this.selectedItem.type === AssetType.SpecifiedItems) {
      this.showCallAlert(this.claimNumber);
    } else {
      this.getClaimReasons(this.selectedItem.type);
    }
  }

  pleaseContactMe() {
    this.clientChoiceOnClaim = 'email';
    this.closeButDontClear();
    if (this.selectedItem.type === AssetType.AllRisks || this.selectedItem.type === AssetType.SpecifiedItems) {
      this.phoneOrEmail();
    } else {
      this.getClaimReasons(this.selectedItem.type);
    }
  }

  closeActionSheetPopup() {
    this.busnClaimService.clearAll(this.selectedAssetsArray);
    this.selectedItem.selected = false;
    this.sharedDialogService.hidePopupDialog();
    SharedConfig.showFooterTabs = true;
    SharedConfig.showFullScreen = false;
  }

  closeButDontClear() {
    this.selectedItem.selected = false;
    this.busnClaimService.selected = false;
    this.showActionSheetPopup = false;
    this.sharedDialogService.hidePopupDialog();
    SharedConfig.showFooterTabs = true;
    SharedConfig.showFullScreen = false;
  }

  phoneOrEmail() {
    if (this.clientChoiceOnClaim === 'phone') {
      this.showCallAlert(this.claimNumber);
      this.emailClaim('call');
    } else {
      this.emailClaim('email');
    }
  }

  async showCallAlert(claimsNumber: string) {
    this.sharedDialogService.hidePopupDialog();
    this.sharedDialogService.showPopupDialog({
      header: 'Claim contact number',
      subHeader: claimsNumber,
      buttonOneText: this.platform.is('desktop') ? 'OK' : 'Call',
      buttonOneCallback: this.callClaim,
    });
  }

  callClaim() {
    this.busnClaimService.submitClaimsPhoneCall(this.claimNumber, this.selectedAssetsArray);
    this.busnClaimService.clearAll(this.selectedAssetsArray);
    this.sharedDialogService.hidePopupDialog();
  }

  async showEmailAlert() {
    this.whichPopup = 'emailAlert';
    this.sharedDialogService.showPopupDialog({
      header: 'Please contact me',
      subHeader: this.noInternet
        ? 'An email could not be sent because your device is not currently connected to the internet.'
        : 'An email has been sent to the claims department and they will contact you soon!',
      buttonOneText: this.noInternet ? 'Retry' : 'Send Email',
      buttonOneCallback: this.phoneOrEmail,
    });
  }

  emailClaim(trigger: ClaimsTriggerType) {
    if (this.noInternet) {
      this.showEmailAlert();
      SharedConfig.showFooterTabs = true;
    } else {
      this.busnClaimService.submitClaimsEmail(this.busnClaimService.selectedAssetsArray, trigger, this.selectedInsurerId);
    }
  }

  dismissEmail() {
    this.dismissSmallPopup();
    SharedConfig.showFooterTabs = true;
  }

  getClaimReasons(asset_type: AssetType) {
    this.selectedClaim = {};
    this.showClaimReasonPopup = true;
    SharedConfig.showFullScreen = true;
    SharedConfig.showFooterTabs = false;
    this.claimReasons = SharedConfig.claimCategories[asset_type];
  }

  toIsoString(date: Date) {
    const tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function (num: number) {
        return (num < 10 ? '0' : '') + num;
      };

    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
      date.getSeconds()
    )}${dif}${pad(Math.floor(Math.abs(tzo) / 60))}:${pad(Math.abs(tzo) % 60)}`;
  }

  removeNoClaim(value: IClaimsReason, index: number, arr: Array<IClaimsReason>) {
    if (value.id === 0) {
      arr.splice(index, 1);
      return true;
    }
    return false;
  }

  async selectReason(reason: string) {
    const today = new Date();
    this.selectedClaim.reason = reason;
    this.selectedClaim.date = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}`;
    this.selectedClaim.date_full = this.toIsoString(today);
    this.selectedClaim.user_id = this.sharedConfig.user.id;
    switch (this.selectedItem.type) {
      case AssetType.Vehicles:
        const vehicles : IVehicle= await this.busnAssetService.getVehicle(this.selectedItem.vehicles_id);
        this.previousClaims = vehicles.claims ?? [];
        this.previousClaims.filter(this.removeNoClaim);
        this.previousClaims.push(this.selectedClaim);
        this.busnAssetService.updateVehicle(vehicles);
        this.closeClaimsNoClear();
        this.sharedConfig.setShowCustomLoader(true)
        setTimeout(() => {
          this.sharedConfig.setShowCustomLoader(false)
          this.phoneOrEmail();
        }, 700);
        break;
      case AssetType.Motorcycles:
        const motorcycles = await this.busnAssetService.getMotorcycle(this.selectedItem.motorcycles_id);
        this.previousClaims = motorcycles.claims ?? [];
        this.previousClaims.filter(this.removeNoClaim);
        this.previousClaims.push(this.selectedClaim);
        this.busnAssetService.updateMotorcycle(motorcycles);
        this.closeClaimsNoClear();
        this.sharedConfig.setShowCustomLoader(true)
        setTimeout(() => {
          this.sharedConfig.setShowCustomLoader(false)
          this.phoneOrEmail();
        }, 700);
        break;
      case AssetType.Caravans:
        const caravans = await this.busnAssetService.getCaravan(this.selectedItem.caravans_id);
        this.previousClaims = caravans.claims ?? [];
        this.previousClaims.filter(this.removeNoClaim);
        this.previousClaims.push(this.selectedClaim);
        this.busnAssetService.updateCaravan(caravans);
        this.closeClaimsNoClear();
        this.sharedConfig.setShowCustomLoader(true)
        setTimeout(() => {
          this.sharedConfig.setShowCustomLoader(false)
          this.phoneOrEmail();
        }, 700);
        break;
      case AssetType.Buildings:
        const buildings = await this.busnAssetService.getBuilding(this.selectedItem.buildings_id);
        this.previousClaims = buildings.claims ?? [];
        this.previousClaims.filter(this.removeNoClaim);
        this.previousClaims.push(this.selectedClaim);
        this.busnAssetService.updateBuilding(buildings);
        this.closeClaimsNoClear();
        this.sharedConfig.setShowCustomLoader(true)
        setTimeout(() => {
          this.sharedConfig.setShowCustomLoader(false)
          this.phoneOrEmail();
        }, 700);
        break;
      case AssetType.Contents:
        const contents = await this.busnAssetService.getContent(this.selectedItem.contents_id);
        this.previousClaims = contents.claims ?? [];
        this.previousClaims.filter(this.removeNoClaim);
        this.previousClaims.push(this.selectedClaim);
        this.busnAssetService.updateContent(contents);
        this.closeClaimsNoClear();
        this.sharedConfig.setShowCustomLoader(true)
        setTimeout(() => {
          this.sharedConfig.setShowCustomLoader(false)
          this.phoneOrEmail();
        }, 700);
        break;

      default:
        break;
    }
    this.busnClaimService.addNewClaimToDB(this.selectedItem.schedule_id, this.selectedClaim.reason);
  }

  addOrRemoveAssets(item: IInsuredAsset, add: boolean, asset_type: AssetType, insurer_id: InsurerIdType) {
    if (this.selectedItem !== undefined && this.selectedItem !== item) {
      this.busnClaimService.clearAll(this.selectedAssetsArray);
      this.selectedItem.selected = false;
      this.busnClaimService.selected = false;
    }
    this.selectedItem = item;
   // item.insurer_id = insurer_id;
    if (add === undefined || add === false) {
      item.selected = true;
      this.busnClaimService.selected = true;
    } else {
      item.selected = false;
      this.busnClaimService.selected;
    }
    this.selectedInsurerId = insurer_id;
    item.type = asset_type;
    if (item.selected === true) {
      this.busnClaimService.selectedAssetsArray.push(item);
      item.selected = true;
      this.busnClaimService.selected = true;
    } else {
      const idx = this.busnClaimService.selectedAssetsArray.indexOf(item);
      if (idx >= 0) {
        this.busnClaimService.selectedAssetsArray.splice(idx, 1);
        item.selected = false;
        this.busnClaimService.selected = false;
      }
    }
    if (this.busnClaimService.selectedAssetsArray.length > 0) {
      SharedConfig.showFooterTabs = false;
    } else if (this.busnClaimService.selectedAssetsArray.length === 0) {
      SharedConfig.showFooterTabs = true;
    }
  }

  getBasicExcess(excess: IQuoteExcess) {
    return excess.description.trim().toLowerCase() === 'basic excess' || excess.description.trim().toLowerCase() === QuoteComparisonDetailType.Excess;
  }

  closeClaimsReasonPopup() {
    this.busnClaimService.clearAll(this.selectedAssetsArray);
    this.selectedItem.selected = false;
    this.showClaimReasonPopup = false;
    SharedConfig.showFooterTabs = true;
    SharedConfig.showFullScreen = false;
  }

  closeClaimsNoClear() {
    this.selectedItem.selected = false;
    this.showClaimReasonPopup = false;
    SharedConfig.showFooterTabs = true;
    SharedConfig.showFullScreen = false;
  }

  dismissSmallPopup() {
    this.sharedDialogService.hidePopupDialog();
    this.busnClaimService.clearAll(this.selectedAssetsArray);
  }

  prevSlide() {
    this.slides.slidePrev();
  }

  nextSlide() {
    this.slides.slideNext();
  }
}
