import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import { catchError, delay, tap, switchMap, filter, take, map } from 'rxjs/operators';

import { ResponseCodeEnum, SharedConfig, SharedLoggingService } from '@takectrl-multiapp/svcs-share-config';
import { AuthStorageService } from './api-auth-storage.service.service';

@Injectable({
  providedIn: 'root',
})
export class APIHttpService {
  private lastRequestSentToBackend = 0;
  private readonly delayBetweenRequests = 1000;
  private baseUrl$ = new BehaviorSubject<string | null>(null);
  private headers$ = new BehaviorSubject<HttpHeaders | null>(null);

  constructor(
    @Optional() @Inject('BASE_URL') private readonly optionalBaseUrl: string | null,
    @Optional() @Inject('HTTP_HEADERS') private readonly optionalHttpHeaders: HttpHeaders | null,
    private readonly httpClient: HttpClient,
    private readonly authStorageService: AuthStorageService,
    private readonly sharedConfig: SharedConfig,
    private readonly sharedLoggingService: SharedLoggingService
  ) {
    if (this.optionalBaseUrl) {
      this.baseUrl$.next(this.optionalBaseUrl);
    }
    if (this.optionalHttpHeaders) {
      this.headers$.next(this.optionalHttpHeaders);
    }
  }

  setBaseUrl(baseUrl: string) {
    this.baseUrl$.next(baseUrl);
  }

  setHeaders(headers: HttpHeaders) {
    this.headers$.next(headers);
  }

  get<T>(
    url: string,
    params?: HttpParams | { [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean> },
    headers?: HttpHeaders,
    apiVersion: string = 'v2'
  ): Observable<T> {
    return this.makeRequest('get', `${url}`, apiVersion, undefined, params, headers);
  }

  post<T>(url: string, body: unknown, headers?: HttpHeaders, apiVersion = 'v2'): Observable<T> {
    return this.makeRequest('post', url, apiVersion, body, undefined, headers);
  }

  put<T>(url: string, body: unknown, headers?: HttpHeaders, apiVersion: string = 'v2'): Observable<T> {
    return this.makeRequest('put', url, apiVersion, body, undefined, headers);
  }

  patch<T>(
    url: string,
    body: unknown,
    params?: HttpParams | { [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean> },
    headers?: HttpHeaders,
    apiVersion: string = 'v2'
  ): Observable<T> {
    return this.makeRequest('patch', url, apiVersion, body, params, headers);
  }

  delete<T>(url: string, headers?: HttpHeaders, apiVersion: string = 'v2'): Observable<T> {
    return this.makeRequest('delete', url, apiVersion, undefined, undefined, headers);
  }

  private reauthenticateAndRetry<T>(obs$: Observable<T>): Observable<T> {
    console.log('Attempting to reauthenticate and retry request');
    return this.authStorageService.getAuthToken().pipe(
      tap((token) => console.log('Auth token retrieved:', token ? 'Token present' : 'No token')),
      switchMap((token) => {
        if (token) {
          console.log('Retrying request with new token');
          return obs$;
        } else {
          console.error('Reauthentication failed: No auth token available');
          return throwError('No auth token available');
        }
      })
    );
  }

  private makeRequest<T>(
    method: 'get' | 'post' | 'put' | 'patch' | 'delete',
    pathOrFullUrl: string,
    apiVersion: string,
    body?: unknown,
    params?: HttpParams | { [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean> },
    headers?: HttpHeaders
  ): Observable<T> {
    const isFullUrl = /^https?:\/\//i.test(pathOrFullUrl);

    return this.baseUrl$.pipe(
      filter((baseUrl) => !!baseUrl),
      take(1),
      switchMap((baseUrl) => {
        const url = isFullUrl ? pathOrFullUrl : `${baseUrl}/${apiVersion}${pathOrFullUrl}`;

      //  console.log(`REQUESTING: ${method.toUpperCase()} : ${url}`);
      //  console.log('Request params:', params);
      //  console.log('Request body:', body);

        return this.checkDelay().pipe(
          tap((_) => {
            this.lastRequestSentToBackend = Date.now();
         //   console.log('Request delay applied. Last request time:', this.lastRequestSentToBackend);
          }),
          switchMap((_) => this.prepareHeaders(headers)),
          switchMap((mergedHeaders) => {
       //     console.log('Final request headers:', mergedHeaders);

            return this.httpClient.request<T>(method, url, { body, params, headers: mergedHeaders }).pipe(
              tap((response) => {
                console.log('>>> :', url);
                console.log('<<< Response received:', response);
              }),
              catchError((error: HttpErrorResponse) => {
                console.error('Request error:', error);
                if (error.status === 401) {
                  console.log('401 Unauthorized error. Attempting to reauthenticate...');
                  const retryRequest = this.httpClient.request<T>(method, url, { body, params, headers: mergedHeaders });
                  return this.reauthenticateAndRetry(retryRequest);
                } else {
                  return this.handleHttpError(error);
                }
              })
            );
          })
        );
      })
    );
  }

  private prepareHeaders(headers?: HttpHeaders): Observable<HttpHeaders> {
    return this.headers$.pipe(
      take(1),
      switchMap((storedHeaders) => {
        let mergedHeaders = storedHeaders || new HttpHeaders();
        let authorizationHeaderFromInput = headers?.get('Authorization');

        if (headers) {
          headers.keys().forEach((key) => {
            const value = headers.getAll(key);
            if (value) {
              mergedHeaders = mergedHeaders.append(key, value);
            }
          });
        }

        if (authorizationHeaderFromInput) {
          mergedHeaders = mergedHeaders.set('Authorization', authorizationHeaderFromInput);
        }

        const authTokenObservable: Observable<string | null> = authorizationHeaderFromInput
          ? of(null)
          : this.authStorageService.getAuthToken().pipe(take(1));

        return authTokenObservable.pipe(
        //  tap((token) => console.log('Auth token for request:', token ? 'Token present' : 'No token')),
          map((token) => {
            if (token && !authorizationHeaderFromInput) {
              mergedHeaders = mergedHeaders.set('Authorization', `Bearer ${token}`);
            }
            return mergedHeaders;
          })
        );
      })
    );
  }

  private handleHttpError(error: HttpErrorResponse): Observable<never> {
    console.error('HTTP Error:', error);
    if (this.sharedConfig?.appSettings?.debug_user === this.sharedConfig.firebaseUserEmail) {
      this.sharedLoggingService.showDebugUserAlertDialog(`CTRL API ERROR : ${JSON.stringify(error)}`);
    }

    if (error.status === ResponseCodeEnum.forbidden.code) {
      console.warn('Forbidden error encountered');
      // handle forbidden error (for role based auth)
    }
    return throwError(() => error);
  }

  private checkDelay(): Observable<number> {
    const timeElapsed = Date.now() - this.lastRequestSentToBackend;
    if (timeElapsed < this.delayBetweenRequests) {
     // console.log(`WAITING >>>>>> Applying delay of ${this.delayBetweenRequests - timeElapsed}ms`);
      return of(null).pipe(delay(this.delayBetweenRequests - timeElapsed));
    }
    return of(Date.now());
  }
}
