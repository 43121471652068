import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  AddressSelectorScreen,
  AssetType,
  IAddresses,
  IBuilding,
  IContent,
  IQuestion,
  IQuestionGroup,
  QuestionFilteringType,
  QuestionType,
  QuoteType,
  SharedConfig,
  SharedDialogService,
  UnderwritingAnswer,
} from '@takectrl-multiapp/svcs-share-config';
import { LoadingController, AlertController, NavController, Platform } from '@ionic/angular';
import {
  BUSNAssetService,
  BUSNQuestionService,
  BUSNQuoteScheduleService,
  BUSNUserService,
  BUSNRbixService,
} from '@takectrl-multiapp/svcs-core-business';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { PageType } from 'libs/svcs-share-config/src/lib/types/page.type';

export enum QuestionDirection {
  INCREMENT,
  DECREMENT,
}

@Component({
  selector: 'question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
})
export class QuestionComponent implements OnInit {
  @Input() quoteType: QuoteType;
  @Input() asset_type: AssetType;
  @Input() assetTypeDisplayName: string;
  @Output() showBackButton: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() navigateQuestionSummary: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() navigateQuoteObjectMappingQuestions: EventEmitter<object> = new EventEmitter<object>();
  @Output() navigateHomeQuote: EventEmitter<void> = new EventEmitter<void>();
  @Output() navigateQuoteEndorsement: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() navigateWalletPage: EventEmitter<void> = new EventEmitter<void>();
  showBuildingContentCopyPopup = false;
  animationName = 'thumbs-up';
  displayText = 'something to say';
  upNextSectionText = 'up next is this category';
  displayHeader: string;
  enableNext = false;
  errorDescription: string = null;
  questionIndex = 0;
  loading = this.loadingCtrl.create();
  public percentageQuestions: any;
  underwritingAnswer: any;
  labelPosition = 'floating'; // TODO
  uniqueHeadings: string[];
  questionGroup: IQuestionGroup[] = [];
  totalQuestions: number;
  addressesToCopyFrom_userOrContentsOrBuildings: IAddresses[] = [];
  showSuccess = false;
  showPopup = false;
  activeAddress: string;
  popupHeader: string;
  popupMessage: string;
  AssetType = AssetType;
  QuestionType = QuestionType;
  AddressSelectorScreen = AddressSelectorScreen;

  constructor(
    public loadingCtrl: LoadingController,
    public busnQuestionService: BUSNQuestionService,
    public busnUserService: BUSNUserService,
    public busnQuoteScheduleService: BUSNQuoteScheduleService,
    public busnRbixService: BUSNRbixService,
    public busnAssetService: BUSNAssetService,
    public alertController: AlertController,
    public sharedConfig: SharedConfig,
    public navCtrl: NavController,
    public busnThirdPartyService: BUSNThirdPartyService,
    public platform: Platform,
    public sharedDialogService: SharedDialogService
  ) {
    SharedConfig.selectedPage = PageType.quoteQuestions;
  }

  ngOnInit() {
    this.initQuestionsPage();
  }

  async initQuestionsPage() {
    this.saveAndExit = this.saveAndExit.bind(this);
    SharedConfig.hideHeaderWhenAddedClaimType = false;
    SharedConfig.selectedAssetUnderwritingQuestions = this.busnQuestionService.getPersonalOrAssetSpecificQuestions(
      SharedConfig.user,
      JSON.parse(JSON.stringify(SharedConfig.underwritingQuestionsAll)),
      this.asset_type,
      QuestionFilteringType.getHiddenQuestionsForQuestionPage,
      true,
      null
    );
    this.sharedConfig.setShowCustomLoader(true, 'initQuestionsPage');
    this.questionGroup = this.busnQuestionService.initializeIndividualQuestionLoaders(
      this.asset_type,
      'question_comp',
      SharedConfig.selectedAssetUnderwritingQuestions
    );
    this.uniqueHeadings = this.busnQuestionService.getUniqueUnderwritingQuestionHeadings(
      'question_comp',
      SharedConfig.selectedAssetUnderwritingQuestions
    );
    this.questionIndex = SharedConfig.selectedAssetUnderwritingQuestions.findIndex(
      (x) => !this.busnQuoteScheduleService.isFieldValidationSuccess(x.underwriting_answers, x, this.sharedConfig.getSelectedAsset(this.asset_type))
    );
    // if (this.questionIndex < 0 || this.quoteType === QuoteType.MyProfile || this.quoteType ===  QuoteType.ViewAssetOnly) {
    const selectedQuestion = SharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex];

    if (this.questionIndex < 0 || this.quoteType === QuoteType.MyProfile || this.quoteType === QuoteType.ViewAssetOnly) {
      this.navigateQuestionSummary.emit();
    } else {
      if (selectedQuestion.type === QuestionType.number) {
        for (const underwritingAnswer of selectedQuestion.underwriting_answers) {
          if (!underwritingAnswer.object_answer && underwritingAnswer.claims_prefill) {
            underwritingAnswer.object_answer = '0';
            this.enableNext = true;
          }
        }
      }

      if (selectedQuestion.type === QuestionType.multi && selectedQuestion.underwriting_answers.find((x) => x.object_answer === undefined)) {
        for (let index = 0; index < selectedQuestion.underwriting_answers.length; index++) {
          const element = selectedQuestion.underwriting_answers[index];
          if (!element.object_answer) {
            element.object_answer = 0;
            this.enableNext = true;
          }
        }
      }

      if (selectedQuestion.underwriting_answers[0].object_answer) {
        this.enableNext = true;
      }
      if (selectedQuestion.type === QuestionType.multiSelect) {
        const someUndefined = selectedQuestion.underwriting_answers.find((x) => x.object_answer === undefined || typeof x.object_answer === null);
        const notUndefined = selectedQuestion.underwriting_answers.find((x) => x.object_answer !== undefined || typeof x.object_answer !== null);
        const partial = someUndefined && notUndefined ? true : false;
        if (partial) {
          this.enableNext = true;
        } else {
          this.enableNext = selectedQuestion.underwriting_answers.find((x) => x.object_answer === undefined || x.object_answer === null)
            ? false
            : true;
        }
      }

      await this.setAddressesForAddressCopyBuildingFromToContents();
      this.showBackButton.emit(false);
    }
    this.sharedConfig.setShowCustomLoader(false, 'initQuestionsPage');
  }

  updateIndividualQuestionLoaders() {
    const dynamicRangeTotalQuestions = SharedConfig.selectedAssetUnderwritingQuestions;

    this.totalQuestions = 100 / dynamicRangeTotalQuestions.length;
    for (let groupIndex = 0; groupIndex < this.uniqueHeadings.length; groupIndex++) {
      const heading = this.uniqueHeadings[groupIndex];
      const allQuestionsWithHeading = dynamicRangeTotalQuestions.filter((x) => x.heading === heading && x.underwriting_answers[0].hidden === false);
      this.questionGroup[groupIndex] = {
        index: groupIndex,
        size: allQuestionsWithHeading.length,
        width_of_progress_bar: allQuestionsWithHeading.length * this.totalQuestions,
        total_answered: allQuestionsWithHeading.filter(
          (x) =>
            x.underwriting_answers[0].object_answer !== undefined &&
            this.busnQuoteScheduleService.isFieldValidationSuccess(
              x.underwriting_answers,
              x,
              this.sharedConfig.getSelectedAsset(this.asset_type),
              null
            )
        ).length,
      };
    }
  }

  async selectAddressCopyQuestionsOver(address: IAddresses) {
    let content_building: IContent | IBuilding;
    this.sharedDialogService.showPopupDialog({
      header: 'Please wait!',
      subHeader: "We are busy with coping your selected address, this shouldn't take long.",
    });
    if (address.asset_id) {
      if (address.asset_type === AssetType.Contents) {
        content_building = await this.busnAssetService.getContent(address.asset_id);
      } else if (address.asset_type === AssetType.Buildings) {
        content_building = await this.busnAssetService.getBuilding(address.asset_id);
      }

      await this.copyContentBuildingQuestionsToAllUnderwritingQuestions(
        content_building,
        this.sharedConfig.getSelectedAsset(SharedConfig.selectedAssetUnderwritingQuestions[0].underwriting_answers[0].object_name) as
          | IContent
          | IBuilding
      );
    }

    // risk_address_line1
    SharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex].underwriting_answers[0].object_answer = address.asset_id
      ? content_building.address?.risk_address_line1
      : address.risk_address_line1;
    // risk_address_line2
    SharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex].underwriting_answers[1].object_answer = address.asset_id
      ? content_building.address?.risk_address_line2
      : address.risk_address_line2;
    // postal_code
    SharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex].underwriting_answers[2].object_answer = address.asset_id
      ? content_building.address?.postal_code
      : address.risk_address_code;

    SharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex].underwriting_answers.forEach((a) => {
      switch (a.object_field) {
        case 'risk_address_line2':
          this.setAddressCodeFields('risk_address', content_building, address);
          break;
        case 'risk_address_line2':
          this.setAddressCodeFields('risk_address', content_building, address);
          break;
        case 'daytime_address_line2':
          this.setAddressCodeFields('daytime_postal', content_building, address);
          break;
        case 'overnight_address_line2':
          this.setAddressCodeFields('overnight_postal', content_building, address);
          break;
        default:
          break;
      }
    });

    this.sharedDialogService.hidePopupDialog();
    this.compAnswerQuestion(SharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]);
    this.nextQuestion();
  }

  async setAddressCodeFields(genericField: string, content_building: IContent | IBuilding, address: IAddresses): Promise<void> {
    const asset_type = SharedConfig.selectedAssetUnderwritingQuestions[0].underwriting_answers[0].object_name;
    SharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex].underwriting_answers[3] = {
      object_name: asset_type,
      object_field: `${genericField}_code_id`,
      object_answer: address.asset_id ? content_building.address?.risk_address_code_id : address.risk_address_code_id,
    };
    SharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex].underwriting_answers[4] = {
      object_name: asset_type,
      object_field: `${genericField}_code_id_mnfg`,
      object_answer: address.asset_id ? content_building.address?.risk_address_code_id_mnfg : address.risk_address_code_id_mnfg,
    };
    SharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex].underwriting_answers[5] = {
      object_name: asset_type,
      object_field: `${genericField}_code_id_sa02`,
      object_answer: address.asset_id ? content_building.address?.risk_address_code_id_sa02 : address.risk_address_code_id_sa02,
    };
  }

  async compAnswerQuestion(answerQuestion: IQuestion | null) {
    this.sharedConfig.setShowCustomLoader(true, 'compAnswerQuestion');
    const currentQuestion = SharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex];

    if (answerQuestion === null) {
      this.clearCurrentQuestion(currentQuestion);
    } else {
      this.enableNext = this.calculateEnableNext(currentQuestion, answerQuestion);

      if (answerQuestion.type === QuestionType.multi) {
        this.handleMultiQuestion(answerQuestion);
      } else if (answerQuestion.type === QuestionType.number) {
        this.handleNumberQuestion(answerQuestion);
      }

      SharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex] = answerQuestion;

      await this.handleDependencies(currentQuestion);

      if (this.shouldSaveParentQuestion(currentQuestion)) {
        await this.saveParentQuestion();
      }

      if (this.shouldMoveToNextQuestion(answerQuestion)) {
       await this.nextQuestion();
      }
    }
    this.sharedConfig.setShowCustomLoader(false, 'compAnswerQuestion');

  }

  private clearCurrentQuestion(currentQuestion: IQuestion) {
    currentQuestion.underwriting_answers.forEach((answer) => {
      answer.object_answer = null;
    });
    this.enableNext = false;
    this.sharedConfig.setShowCustomLoader(false, 'clearCurrentQuestion');
  }

  private calculateEnableNext(currentQuestion: IQuestion, answerQuestion: IQuestion): boolean {
    if (currentQuestion.type === QuestionType.multiSelect) {
      const answers = currentQuestion.underwriting_answers;
      const someUndefined = answers.some((x) => x.object_answer === undefined || x.object_answer === null);
      const someAnswered = answers.some((x) => x.object_answer !== undefined && x.object_answer !== null);
      return someUndefined && someAnswered
        ? true
        : !answerQuestion.underwriting_answers.some((x) => x.object_answer === undefined || x.object_answer === null);
    }
    return answerQuestion.underwriting_answers[0].object_answer != null;
  }

  private handleMultiQuestion(answerQuestion: IQuestion) {
    answerQuestion.underwriting_answers = answerQuestion.underwriting_answers.map((answer) => {
      if (answer.object_answer === '') {
        answer.object_answer = '';
      } else {
        answer.object_answer = Number(answer.object_answer);
      }
      return answer;
    });
    this.enableNext = !answerQuestion.underwriting_answers.some((answer) => answer.object_answer === '' || answer.object_answer < 0);
  }

  private handleNumberQuestion(answerQuestion: IQuestion) {
    answerQuestion.underwriting_answers[0].object_answer = Number(answerQuestion.underwriting_answers[0].object_answer);
    this.enableNext = true;
  }

  private async handleDependencies(currentQuestion: IQuestion) {
    if (currentQuestion.dependency?.dependency_type === 'Parent' && !currentQuestion.underwriting_answers[0].object_answer) {
      const childDependencies = SharedConfig.selectedAssetUnderwritingQuestions.filter(
        (x) => x.dependency?.dependency_key === currentQuestion.dependency.dependency_key && x.dependency.dependency_type === 'Child'
      );
      for (const childQuestion of childDependencies) {
        await this.removeUnderwritingAnswers(childQuestion.underwriting_answers);
      }
    }
  }

  private shouldSaveParentQuestion(currentQuestion: IQuestion): boolean {
    return currentQuestion.dependency?.dependency_type === 'Parent';
  }

  private async saveParentQuestion() {
    await this.busnQuoteScheduleService.saveQuoteQuestions(
      SharedConfig.selectedAssetUnderwritingQuestions,
      this.sharedConfig.getSelectedAsset(SharedConfig.selectedAssetUnderwritingQuestions[0].underwriting_answers[0].object_name)
    );
    this.busnQuestionService.sanitizeQuestionsComparingToDBSource(SharedConfig.user);
  }

  private shouldMoveToNextQuestion(answerQuestion: IQuestion): boolean {
    if (answerQuestion.type === QuestionType.claimList) {
      if (answerQuestion.underwriting_answers[0].object_answer.length <= 0) {
        answerQuestion.underwriting_answers[0].object_answer.push({
          date: Date.now(),
          date_full: Date.now(),
          id: 0,
          reason: 'No claims',
        });
      }
      return true;
    }
    return [QuestionType.select, QuestionType.selectSearch, QuestionType.boolean].includes(answerQuestion.type);
  }

  async previousQuestion() {
    await this.changeQuestionDirection(QuestionDirection.DECREMENT);
    if (this.questionIndex > 0) {
      this.showBackButton.emit(true);
    } else {
      this.showBackButton.emit(false);
    }
  }

  async nextQuestion() {
    this.showBackButton.emit(true);
    // this.sharedConfig.setShowCustomLoader(true, 'nextQuestion');

    const validationSuccess = await this.validateSelectedQuestion(this.questionIndex);

    if (validationSuccess) {
      await this.changeQuestionDirection(QuestionDirection.INCREMENT);
    } else {
      console.log('validation failed.');
    }

    // this.sharedConfig.setShowCustomLoader(false, 'nextQuestion');
  }

  async changeQuestionDirection(questionDirection: QuestionDirection) {
    this.enableNext = false;
    const currentHeading = SharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex].heading;
    const currentObjName = SharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex].underwriting_answers[0].object_name;
    this.updateIndividualQuestionLoaders();
    this.updateQuestionDirectionCounter(questionDirection, currentHeading);

    const currentSelectedQuestion = SharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex];

    if (currentSelectedQuestion) {
      this.enableNext = true;
      for (const underwriting_answer of SharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex].underwriting_answers) {
        if (underwriting_answer.object_answer == null || underwriting_answer.object_answer === undefined) {
          this.enableNext = false;
        }
      }

      await this.autoCopyPersonCheckerQuestionsCheck(currentSelectedQuestion);
      const allUnderwritingObjectsHaveAnswers = this.allUnderwritingObjectsHaveAnswers(
        SharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex].underwriting_answers
      );

      if (allUnderwritingObjectsHaveAnswers && questionDirection === QuestionDirection.INCREMENT) {
        await this.changeQuestionDirection(questionDirection);
        return;
      }
    } else {
      // No more questions flow
      await this.executeNoMoreQuestionsFlow(currentObjName);
    }
  }

  private async autoCopyPersonCheckerQuestionsCheck(currentSelectedQuestion: IQuestion) {
    if (
      (currentSelectedQuestion.personChecker && currentSelectedQuestion.personChecker.type === 'Child') ||
      (currentSelectedQuestion.claims_prefill_type && currentSelectedQuestion.claims_prefill_type === 'Child')
    ) {
      if (currentSelectedQuestion.personChecker && currentSelectedQuestion.personChecker.type === 'Child') {
        currentSelectedQuestion.underwriting_answers = await this.busnQuestionService.autoPopulatePersonCheckerQuestions(currentSelectedQuestion);
      }
      if (currentSelectedQuestion.claims_prefill_type && currentSelectedQuestion.claims_prefill_type === 'Child') {
        currentSelectedQuestion.underwriting_answers = await this.autoPopulateClaimsPrefillQuestions(currentSelectedQuestion);
      }
    } else if (currentSelectedQuestion.dependency && currentSelectedQuestion.dependency.dependency_type === 'Child') {
      const parentDependency = SharedConfig.selectedAssetUnderwritingQuestions.find(
        (x) =>
          x.dependency &&
          x.dependency.dependency_key === currentSelectedQuestion.dependency.dependency_key &&
          x.dependency.dependency_type === 'Parent'
      );
      if (parentDependency && parentDependency.underwriting_answers[0].object_answer === false) {
        this.removeUnderwritingAnswers(currentSelectedQuestion.underwriting_answers);
        for (let index = 0; index < currentSelectedQuestion.underwriting_answers.length; index++) {
          delete SharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex].underwriting_answers[index].object_answer;
        }
      }
    }
  }

  private updateQuestionDirectionCounter(questionDirection: QuestionDirection, currentHeading: string) {
    if (questionDirection === QuestionDirection.INCREMENT) {
      this.questionIndex++;

      if (SharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]?.heading !== currentHeading) {
        if (SharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]?.heading) {
          this.saveParentQuestion();
          this.animationName = 'thumbs-up';
          this.displayText = `${currentHeading} complete!`;
          this.upNextSectionText = SharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]?.heading;
          SharedConfig.showUpNext = true;
        }
      }
    } else {
      this.questionIndex--;
    }
  }

  private async executeNoMoreQuestionsFlow(currentObjName: string) {
    SharedConfig.showUpNext = false;
    SharedConfig.pageHasScrollbar = false;

    this.busnQuoteScheduleService.saveQuoteQuestions(
      SharedConfig.selectedAssetUnderwritingQuestions,
      this.sharedConfig.getSelectedAsset(SharedConfig.selectedAssetUnderwritingQuestions[0].underwriting_answers[0].object_name)
    );

    if (currentObjName === AssetType.UserInsuranceHistory || currentObjName === AssetType.UserPersonalDetails) {
      this.animationName = 'successful-register';
      this.displayHeader = 'Amazing!';
      this.displayText = "Now let's get you covered.";
      this.showSuccess = true;
      this.busnThirdPartyService.logEvent('app_personal_details_completed');
    } else if (currentObjName === AssetType.Vehicles || currentObjName === AssetType.Motorcycles) {
      const modal = SharedConfig.selectedAssetUnderwritingQuestions.find((x) => x.type === QuestionType.vehicleSearch);
      this.animationName = 'successful-register';
      this.displayHeader = 'Great!';
      this.displayText = `Your ${modal.underwriting_answers[1].object_answer} has been added.`;
      this.showSuccess = true;
    } else if (currentObjName === AssetType.Caravans) {
      const modal = SharedConfig.selectedAssetUnderwritingQuestions
        .find((x) => x.type === QuestionType.text)
        .underwriting_answers.find((x) => x.object_field === 'model').object_answer;
      this.animationName = 'successful-register';
      this.displayHeader = 'Great!';
      this.displayText = `Your ${modal} has been added.`;
      this.showSuccess = true;
    } else if (currentObjName === AssetType.Buildings) {
      const address = SharedConfig.selectedAssetUnderwritingQuestions.find((x) => x.type === QuestionType.address);
      this.animationName = 'successful-register';
      this.displayHeader = 'Great!';
      this.displayText = `Your house at ${address.underwriting_answers[0].object_answer} has been added.`;
      this.showSuccess = true;
    } else if (currentObjName === AssetType.Contents) {
      const address = SharedConfig.selectedAssetUnderwritingQuestions.find((x) => x.type === QuestionType.address);
      this.animationName = 'successful-register';
      this.displayHeader = 'Great!';
      this.displayText = `Your contents at ${address.underwriting_answers[0].object_answer} has been added.`;
      this.showSuccess = true;
    } else if (currentObjName === AssetType.AllRisks) {
      this.animationName = 'successful-register';
      this.displayHeader = 'Great!';
      this.displayText = 'Your Clothing and personal effects has been added.';
      this.showSuccess = true;
    } else if (currentObjName === AssetType.SpecifiedItems) {
      const description = SharedConfig.selectedAssetUnderwritingQuestions
        .find((x) => x.type === QuestionType.text)
        .underwriting_answers.find((x) => x.object_field === 'description').object_answer;
      this.animationName = 'successful-register';
      this.displayHeader = 'Great!';
      this.displayText = `Your ${description} has been added.`;
      this.showSuccess = true;
    } else {
      if (!this.quoteType || this.quoteType === QuoteType.StandardQuoteRequest) {
        this.navigateHomeQuote.emit();
      } else {
        this.navigateQuoteEndorsement.emit();
      }
    }
  }

  allUnderwritingObjectsHaveAnswers(UnderwritingAnswers: UnderwritingAnswer[]): boolean {
    for (const underwritingAnswer of UnderwritingAnswers) {
      if (
        !underwritingAnswer ||
        underwritingAnswer.object_answer === null ||
        underwritingAnswer.object_answer === undefined ||
        Number.isNaN(underwritingAnswer.object_answer) ||
        underwritingAnswer.object_answer.toString().length <= 0 ||
        (underwritingAnswer.object_answer !== 0 && underwritingAnswer.claims_prefill !== undefined)
      ) {
        return false;
      }
    }

    return true;
  }

  async setAddressesForAddressCopyBuildingFromToContents() {
    const buildings: IBuilding[] = await this.busnAssetService.getBuildings();
    const contents: IContent[] = await this.busnAssetService.getContents();

    for (let index = 0; index < contents.length; index++) {
      const content = contents[index];
      content.is_completed = this.busnQuestionService.isAssetQuestionsComplete(AssetType.Contents, content, SharedConfig.user);
    }

    for (let building_index = 0; building_index < buildings.length; building_index++) {
      const building = buildings[building_index];
      building.is_completed = this.busnQuestionService.isAssetQuestionsComplete(AssetType.Buildings, building, SharedConfig.user);
    }

    if (SharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex].underwriting_answers[0].object_name === AssetType.Contents) {
      //building
      for (const building of buildings) {
        if (
          !this.addressesToCopyFrom_userOrContentsOrBuildings.find(
            (x) =>
              x.risk_address_line1 === building.address.risk_address_line1 &&
              x.risk_address_code_id === building.address.risk_address_code_id &&
              x.risk_address_line2 === building.address.risk_address_line2 &&
              x.risk_address_code === building.address.risk_address_code &&
              x.risk_address_code_id_mnfg === building.address.risk_address_code_id_mnfg &&
              x.risk_address_code_id_sa02 === building.address.risk_address_code_id_sa02
          ) &&
          building.is_completed &&
          !(
            contents.find((x) => x.address?.risk_address_code_id === building.address?.risk_address_code_id && x.is_completed) &&
            contents.find((x) => x.address?.risk_address_line1 === building.address?.risk_address_line1 && x.is_completed)
          )
        ) {
          this.addressesToCopyFrom_userOrContentsOrBuildings.push({
            risk_address_code_id: building.address.risk_address_code_id,
            risk_address_line1: building.address.risk_address_line1,
            risk_address_line2: building.address.risk_address_line2,
            risk_address_code: building.address.risk_address_code,
            risk_address_code_id_mnfg: building.address.risk_address_code_id_mnfg,
            risk_address_code_id_sa02: building.address.risk_address_code_id_sa02,
            asset_id: building.id,
            asset_type: AssetType.Buildings,
          });
        }
      }
    } else if (SharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex].underwriting_answers[0].object_name === AssetType.Buildings) {
      this.addressesToCopyFrom_userOrContentsOrBuildings = [];
      for (const content of contents) {
        if (
          !this.addressesToCopyFrom_userOrContentsOrBuildings.find(
            (x) =>
              x.risk_address_line1 === content.address.risk_address_line1 &&
              x.risk_address_code_id === content.address.risk_address_code_id &&
              x.risk_address_line2 === content.address.risk_address_line2 &&
              x.risk_address_code === content.address.risk_address_code &&
              x.risk_address_code_id_mnfg === content.address.risk_address_code_id_mnfg &&
              x.risk_address_code_id_sa02 === content.address.risk_address_code_id_sa02
          ) &&
          content.is_completed &&
          !(
            buildings.find((x) => x.address?.risk_address_code_id === content?.address?.risk_address_code_id && x.is_completed) &&
            buildings.find((x) => x.address?.risk_address_line1 === content?.address?.risk_address_line1 && x.is_completed)
          )
        ) {
          this.addressesToCopyFrom_userOrContentsOrBuildings.push({
            risk_address_code_id: content.address.risk_address_code_id,
            risk_address_line1: content.address.risk_address_line1,
            risk_address_line2: content.address.risk_address_line2,
            risk_address_code: content.address.risk_address_code,
            risk_address_code_id_mnfg: content.address.risk_address_code_id_mnfg,
            risk_address_code_id_sa02: content.address.risk_address_code_id_sa02,
            asset_id: content.id,
            asset_type: AssetType.Contents,
          });
        }
      }
    }

    if (
      SharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex].portal_filter_category !== 'Personal details' &&
      (!SharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex].underwriting_answers[0].object_answer ||
        SharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex].underwriting_answers[0].object_answer.length <= 0)
    ) {
      const address = this.sharedConfig.user.addresses;
      if (
        address.risk_address_line1 &&
        address.risk_address_line2 &&
        address.risk_address_code &&
        address.risk_address_code_id &&
        address.risk_address_code_id_mnfg &&
        address.risk_address_code_id_sa02
      ) {
        if (
          !this.addressesToCopyFrom_userOrContentsOrBuildings.find(
            (x) =>
              x.risk_address_line1 === address.risk_address_line1 &&
              x.risk_address_code_id === address.risk_address_code_id &&
              x.risk_address_line2 === address.risk_address_line2 &&
              x.risk_address_code === address.risk_address_code &&
              x.risk_address_code_id_mnfg === address.risk_address_code_id_mnfg &&
              x.risk_address_code_id_sa02 === address.risk_address_code_id_sa02
          )
        ) {
          if (this.addressesToCopyFrom_userOrContentsOrBuildings.length <= 0) {
            this.addressesToCopyFrom_userOrContentsOrBuildings.push({
              risk_address_code_id: address.risk_address_code_id,
              risk_address_line1: address.risk_address_line1,
              risk_address_line2: address.risk_address_line2,
              risk_address_code: address.risk_address_code,
              risk_address_code_id_mnfg: address.risk_address_code_id_mnfg,
              risk_address_code_id_sa02: address.risk_address_code_id_sa02,
              asset_id: null,
              asset_type: null,
            });
          }
        }
      }
    }
  }

  getClaimsParentIndex(): number {
    const index: number = SharedConfig.selectedAssetUnderwritingQuestions.findIndex((x) => x.claims_prefill_type === 'Parent');
    return index;
  }

  async deletePersonCheckerChildAnswers(question: IQuestion) {
    // If the parent of a personChecker question is change, we should remove those answers so they are asked again
    if (question.underwriting_answers[0].object_answer !== question.personChecker.triggerWording) {
      SharedConfig.selectedAssetUnderwritingQuestions.forEach(async (_question, index) => {
        if (_question.personChecker && _question.personChecker.key === question.personChecker.key && _question.personChecker.type === 'Child') {
          delete SharedConfig.selectedAssetUnderwritingQuestions[index].underwriting_answers[0].object_answer;
          await this.removeUnderwritingAnswers(SharedConfig.selectedAssetUnderwritingQuestions[index].underwriting_answers);
        }
      });
    }
  }

  async autoPopulateClaimsPrefillQuestions(question: IQuestion): Promise<UnderwritingAnswer[]> {
    const parentQuestionIndex = this.getClaimsParentIndex();
    if (parentQuestionIndex > -1) {
      if (!SharedConfig.selectedAssetUnderwritingQuestions[parentQuestionIndex].underwriting_answers[0].object_answer) {
        question.underwriting_answers = question.underwriting_answers.map((underwritingAnswer) => {
          underwritingAnswer.object_answer = underwritingAnswer.claims_prefill;
          return underwritingAnswer;
        });
      } else if (SharedConfig.selectedAssetUnderwritingQuestions[parentQuestionIndex].underwriting_answers[0].object_answer) {
        question.underwriting_answers = question.underwriting_answers.map((underwritingAnswer) => {
          delete underwritingAnswer.object_answer;
          return underwritingAnswer;
        });
      }
    }
    return question.underwriting_answers;
  }

  async validateSelectedQuestion(questionIndex: number, content_building: IContent | IBuilding = null): Promise<boolean> {
    for (const underwritingAnswer of SharedConfig.selectedAssetUnderwritingQuestions[questionIndex].underwriting_answers) {
      const underArr: Array<UnderwritingAnswer> = [underwritingAnswer];
      if (
        !this.busnQuoteScheduleService.isFieldValidationSuccess(
          underArr,
          SharedConfig.selectedAssetUnderwritingQuestions[questionIndex],
          this.sharedConfig.getSelectedAsset(this.asset_type),
          content_building
        )
      ) {
        this.errorDescription = this.busnQuoteScheduleService.errorDescription;
        return false;
      }
    }

    this.errorDescription = null;
    return true;
  }

  async removeUnderwritingAnswers(underwriting_answers: UnderwritingAnswer[]) {
    for (const underwritingAnswer of underwriting_answers) {
      if (underwritingAnswer.object_name === AssetType.UserPersonalDetails) {
        //  await this.waitTillSend(1000);
        delete this.sharedConfig.user.personal_details[underwritingAnswer.object_field];
        await this.busnUserService.updatePersonalDetails(this.sharedConfig.user.personal_details);
      } else if (underwritingAnswer.object_name === AssetType.UserInsuranceHistory) {
        //await this.waitTillSend(1000);
        if (!this.sharedConfig.user.insurance_history) this.sharedConfig.user.insurance_history = {};
        this.sharedConfig.user.insurance_history[underwritingAnswer.object_field] = underwritingAnswer.object_answer;
        await this.busnUserService.updateUserInsuranceHistory(SharedConfig.user.insurance_history);
      } else {
        await this.busnQuoteScheduleService.removeAnswerFromQuoteRequestAndAsset(underwritingAnswer);
      }
    }
  }

  async saveAndClose() {
    this.sharedDialogService.showPopupDialog({
      header: 'Do you want to save and close?',
      subHeader: 'You can come back later and continue where you left off.',
      buttonOneText: 'Yes, I want to leave',
      buttonTwoText: 'No, let’s continue',
      buttonOneCallback: this.saveAndExit,
      buttonTwoCallback: this.sharedDialogService.hidePopupDialog,
    });
  }

  async saveAndExit() {
    this.sharedConfig.setShowCustomLoader(true, 'saveAndExit');
    await this.busnQuoteScheduleService.saveQuoteQuestions(
      SharedConfig.selectedAssetUnderwritingQuestions,
      this.sharedConfig.getSelectedAsset(SharedConfig.selectedAssetUnderwritingQuestions[0].underwriting_answers[0].object_name)
    );
    this.sharedConfig.setShowCustomLoader(true, 'saveAndExit');

    this.sharedDialogService.hidePopupDialog();

    if (!SharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex]) {
      this.navigateHomeQuote.emit();
    }

    if (
      this.sharedConfig.schedulesActive?.length === 0 &&
      (SharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex].underwriting_answers[0].object_name === AssetType.UserPersonalDetails ||
        SharedConfig.selectedAssetUnderwritingQuestions[this.questionIndex].underwriting_answers[0].object_name === AssetType.UserInsuranceHistory)
    ) {
      this.navigateWalletPage.emit();
      SharedConfig.hideHeader = false;
    } else {
      this.navigateHomeQuote.emit();
      SharedConfig.hideHeader = false;
    }
  }

  async copyContentBuildingQuestionsToAllUnderwritingQuestions(
    content_building_to_copy: IContent | IBuilding,
    selected_content_building: IContent | IBuilding
  ): Promise<void> {
    let i = 0;
    for (const underwritingQuestion of SharedConfig.selectedAssetUnderwritingQuestions) {
      if (underwritingQuestion.copy_buildings_and_contents) {
        let b = 0;
        for (const underwritingAnswer of underwritingQuestion.underwriting_answers) {
          if (content_building_to_copy[underwritingAnswer.object_field] !== undefined && underwritingAnswer.copy_buildings_and_contents) {
            underwritingAnswer.object_answer = content_building_to_copy[underwritingAnswer.object_field];
            SharedConfig.selectedAssetUnderwritingQuestions[i].underwriting_answers[b].object_answer =
              content_building_to_copy[underwritingAnswer.object_field];
            await this.validateSelectedQuestion(i, selected_content_building);
          }
          b++;
        }
      }
      i++;
    }
    return;
  }
}
