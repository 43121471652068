import { BUSNUserService } from '@takectrl-multiapp/svcs-core-business';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IUser, QuoteRequestStatusType, QuoteType, SharedConfig, SharedDialogService } from '@takectrl-multiapp/svcs-share-config';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { UIAppInitializationService } from '../../services/ui-app-initialization.service';
import { ServiceWorkerService } from '@takectrl-multiapp/svcs-core';

@Component({
  selector: 'admin-panel-quote-on-behalf',
  templateUrl: './admin-panel-quote-on-behalf.component.html',
  styleUrls: ['./admin-panel-quote-on-behalf.component.scss'],
})
export class AdminPanelQuoteOnBehalfComponent implements OnInit, AfterViewInit {
  @Output() navToPage: EventEmitter<string> = new EventEmitter<string>();
  @Input() quoteType: QuoteType;
  @Input() endorsementUpdateRequiredData: unknown;

  email: string;
  user: IUser;
  QuoteType = QuoteType;
  constructor(
    public sharedConfig: SharedConfig,
    public busnThirdPartyService: BUSNThirdPartyService,
    public uiAppInitializationService: UIAppInitializationService,
    private readonly serviceWorkerService: ServiceWorkerService,
    public busnUserService: BUSNUserService,
    public sharedDialogService: SharedDialogService
  ) {}

  ngOnInit(): void {
    if (SharedConfig.routeParamUserEmailQuoteOnBehalf) {
      this.email = SharedConfig.routeParamUserEmailQuoteOnBehalf;
      this.autoLoadUser();
    }
  }

  autoLoadUser() {
    this.searchUser();
  }


  async ngAfterViewInit(): Promise<void> {}

  async searchUser() {
    this.sharedConfig.setShowCustomLoader(true)
    SharedConfig.showFullScreen = true;
    delete this.user;
    if (!this.email) {
      this.sharedDialogService.showPopupDialog({
        header: 'No Email',
        subHeader: 'Please enter an email address',
        buttonOneText: 'OK',
        buttonOneCallback: this.sharedDialogService.hidePopupDialog,
      });
    } else {
      const userSearchResponse = await this.busnUserService.getUserByEmail(this.email);

      if (!userSearchResponse.personal_details?.user_id) {
        this.sharedDialogService.showPopupDialog({
          header: 'No Email found',
          subHeader: 'Please check the email and try again',
          buttonOneText: 'OK',
          buttonOneCallback: this.sharedDialogService.hidePopupDialog,
        });
      } else {
        this.user = userSearchResponse;
        if (!this.user) {
          this.sharedDialogService.showPopupDialog({
            header: 'Invalid email',
            subHeader: 'No user found',
            buttonOneText: 'OK',
            buttonOneCallback: this.sharedDialogService.hidePopupDialog,
          });
        }
      }
    }
    this.sharedConfig.setShowCustomLoader(false)
  }

  closeSearch() {
    this.user = null;
    SharedConfig.showFullScreen = false;
  }

  cancel() {
    const activeQuoteRequest = this.sharedConfig?.quoteRequests?.find(
      (x) =>
        x.status === QuoteRequestStatusType.Submitted ||
        x.status === QuoteRequestStatusType.WaitingOnQuotes ||
        x.status === QuoteRequestStatusType.WaitingToAccept
    );
    if (this.sharedConfig.schedulesActive.length === 0 && activeQuoteRequest) {
      this.navToPage.emit('home/home-quote');
    } else {
      this.navToPage.emit('home/home-wallet');
    }
  }
}
