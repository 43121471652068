import { BUSNQuoteScheduleService, BUSNUserService } from '@takectrl-multiapp/svcs-core-business';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SharedConfig, IBankingDetails, IBank, SharedFormulaService, IBankBranch, IQuote, HeadersType } from '@takectrl-multiapp/svcs-share-config';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { AlertController, LoadingController } from '@ionic/angular';
declare const dataLayer: any[];

@Component({
  selector: 'quote-banking-details',
  templateUrl: './quote-banking-details.component.html',
  styleUrls: ['./quote-banking-details.component.scss'],
})
export class QuoteBankingDetailsComponent implements OnInit {
  @Output() navigateCasaOrComplete: EventEmitter<unknown> = new EventEmitter<unknown>();
  public banksAll: IBank[] = [];
  public banksFiltered: IBank[] = [];
  public banksPopular: IBank[] = [];
  public bank_branches: Array<IBankBranch> = [];
  public branchListSortName = [];
  public branchListSortCode = [];
  collectionDates = [];
  banking_details: IBankingDetails;
  newEmail: string;
  cover_start_date: string;
  bankSearchText: string;
  showPopular = true;
  maxDate: any;
  minDate = this.sharedFormulaService.formatDateyyymmdd(Date.now());
  showUpdateEmailPopup = false;
  bankSelectPopup = false;
  showDeductionDay = false;
  collection_day: string;
  branchAndCode: string;
  showCustomPopup = false;
  passwordField: string;
  // Returned Values
  insurer_id = SharedConfig.selectedQuote.insurer_id;
  constructor(
    private readonly busnQuoteScheduleService: BUSNQuoteScheduleService,
    private readonly alertCtrl: AlertController,
    public userService: BUSNUserService,
    private readonly busnThirdPartyService: BUSNThirdPartyService,
    public sharedConfig: SharedConfig,
    private readonly sharedFormulaService: SharedFormulaService,
    private readonly loadCtrl: LoadingController
  ) {}

  ngOnInit(): void {
    const dateMax = this.sharedFormulaService.addDays(new Date(this.sharedConfig.quoteRequest.effective_date), 59);
    this.maxDate = this.sharedFormulaService.formatDateyyymmdd(dateMax);
    console.log(this.maxDate);
    console.log(this.minDate);
    this.banking_details = {};
    this.banking_details.account_holder = `${this.sharedConfig.user.personal_details.name} ${this.sharedConfig.user.personal_details.surname}`;
    this.collectionDates = this.sharedFormulaService.getDateOrdinalsForMonth();

    dataLayer.push({
      event: 'page_show',
      screenPath: 'quote-banking-details',
      screenName: 'quote-banking-details',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }

  accountHolderValueChange(e: Event) {
    this.banking_details.account_holder = e.target['value'];
  }

  accountNumberValueChange(e: Event) {
    this.banking_details.account_number = e.target['value'];
  }

  bankSearchValueChange(searchText: string) {
    this.bankSearchText = searchText;

    if (!this.bankSearchText || this.bankSearchText.length <= 0) {
      this.showPopular = true;
    } else {
      this.showPopular = false;

      this.banksFiltered = this.banksAll.slice();
      this.banksFiltered = this.banksFiltered.filter((x) => x.bankName.toLocaleLowerCase().includes(this.bankSearchText.toLocaleLowerCase()));
    }
  }

  openCalendar() {
    SharedConfig.showCalendarPopup = true;
  }

  bankValueChange(bankName: string) {
    if (bankName === null) return;
    const bank: IBank = this.banksAll.find((b) => b.bankName === bankName);
    if (bank.code === '') {
      this.banking_details.branch_name = null;
      this.banking_details.branch_code = null;
      this.bank_branches = this.branchListSortName = this.branchListSortCode = [];
      this.busnQuoteScheduleService.getBankBranches(bank.bankName).then((bankBranches) => {
        this.bank_branches = bankBranches.bank_branches;
        this.branchListSortName = this.bank_branches.concat().sort((a, b) => a.name.localeCompare(b.name));
        this.branchListSortCode = this.bank_branches.concat().sort((a, b) => a.code.localeCompare(b.code));
      });
    } else {
      this.banking_details.branch_name = bank.name;
      this.banking_details.branch_code = bank.code;
      this.bank_branches = this.branchListSortName = this.branchListSortCode = [{ ...bank }];
    }
    this.banking_details.financial_institution = bankName;
  }

  onBankBranchNameSelected(name: string) {
    const branch = this.bank_branches.find((b) => b.name === name);
    if (branch) this.banking_details.branch_code = branch.code;
  }

  onBankBranchCodeSelected(code: string) {
    const branch = this.bank_branches.find((b) => b.code === code);
    if (branch) this.banking_details.branch_name = branch.name;
  }

  onCollectionDaySelected(collection_day) {
    this.showDeductionDay = false;
    this.collection_day = collection_day.day_ordinal_display_name;
    this.banking_details.collection_day = collection_day.day;
    SharedConfig.showFullScreen = false;
  }

  continue() {
    this.busnThirdPartyService.logEvent('action_quote_banking_details_completed', {
      timestamp: new Date(),
      quote_request_id: this.sharedConfig.quoteRequest.id,
    });
    if (SharedConfig.selectedQuote.insurer_id === 'KINGPRICE') {
      this.navigateCasaOrComplete.emit();
    } else {
      SharedConfig.quoteBankingDetails = this.banking_details;
      this.navigateCasaOrComplete.emit();
    }
  }

  openUpdateEmailPopup() {
    this.showUpdateEmailPopup = true;
    SharedConfig.showHeaderForType =  HeadersType.updateEmail;
    this.newEmail = '';
  }

  closeComponentPopup() {
    if (SharedConfig.showHeaderForType === HeadersType.updateEmail) {
      this.showUpdateEmailPopup = false;
    } else if (SharedConfig.showHeaderForType === HeadersType.banks) {
      this.bankSelectPopup = false;
      this.banksAll = [];
      this.banksFiltered = [];
      this.banksPopular = [];
    }
    delete SharedConfig.showHeaderForType;
  }

  async openBankSelectPopup() {
    SharedConfig.showHeaderForType = HeadersType.banks;
    this.bankSelectPopup = true;
    // let loader = await this.loadCtrl.create();
    //   loader.present();
    this.sharedConfig.setShowCustomLoader(true)
    this.busnQuoteScheduleService.getBanks().then(async (banks) => {
      this.banksAll = banks as Array<IBank>;
      this.banksFiltered = banks as Array<IBank>;
      this.banksPopular = (banks as Array<IBank>).filter(
        (x) =>
          x.display === 'ABSA' ||
          x.display === 'Capitec' ||
          x.display === 'FNB' ||
          x.display === 'Investec' ||
          x.display === 'Nedbank' ||
          x.display === 'Standard Bank'
      );
      this.sharedConfig.setShowCustomLoader(false)
    });
  }

  async updateEmail() {
    if (this.newEmail && this.newEmail !== '') {
      this.showCustomPopup = true;
      SharedConfig.showFullScreen = true;
    }
  }

  confirmEmailChange() {
    this.userService.changeEmailAddress(this.newEmail);
    this.dismissCustomPopup();
  }

  dismissCustomPopup() {
    this.showCustomPopup = false;
    SharedConfig.showFullScreen = false;
  }

  cancelPopupEmail() {
    this.showUpdateEmailPopup = false;
    delete SharedConfig.showHeaderForType;
  }

  selectBank(bank: IBank) {
    this.bankSelectPopup = false;
    delete SharedConfig.showHeaderForType;
    this.banking_details.financial_institution = bank.bankName;
    this.banking_details.branch_code = bank.miwayBranchCode;
    this.banking_details.branch_name = bank.miwayBranchName;
    this.branchAndCode = `${bank.miwayBranchName}, ${bank.miwayBranchCode}`;
    this.banksAll = [];
    this.banksFiltered = [];
    this.banksPopular = [];
  }

  async setCoverStartDate(dateValue) {
    const dateDialogAnswer = dateValue.split('T')[0];
    this.cover_start_date = dateDialogAnswer;
    this.sharedConfig.quoteRequest.cover_start_date = dateDialogAnswer;
    this.busnQuoteScheduleService.updateCoverStartDate(dateDialogAnswer, this.sharedConfig.quoteRequest);
  }

  async openDeductionDay() {
    SharedConfig.showFullScreen = true;
    this.sharedConfig.setShowCustomLoader(true)
    const intervalId = setInterval(() => {
      this.sharedConfig.setShowCustomLoader(false)
      this.showDeductionDay = true;
      clearInterval(intervalId);
    }, 100);
  }
}
