<div class="input-wrapper">
    <ion-radio-group *ngIf="!readOnly" value="{{question.underwriting_answers[0].object_answer}}">
        <ion-item mode="md" *ngFor="let questionOption of question.options" lines="none" 
            [ngClass]="{'active': (questionOption == question.underwriting_answers[0].object_answer || (questionOption === 'Yes' && question.underwriting_answers[0].object_answer === true) || (questionOption === 'No' && question.underwriting_answers[0].object_answer === false) )}" (click)="answerValue(questionOption)">
            <ion-text>{{questionOption}}</ion-text>
            <ion-icon class="checked" [ngClass]="{'selected': (questionOption == question.underwriting_answers[0].object_answer || (questionOption === 'Yes' && question.underwriting_answers[0].object_answer === true) || (questionOption === 'No' && question.underwriting_answers[0].object_answer === false) )}" src="assets/icon/app-icons/tick-primary.svg" slot="end"></ion-icon>
        </ion-item>
    </ion-radio-group>
    <ion-radio-group *ngIf="readOnly" value="{{question.underwriting_answers[0].object_answer}}">
        <ion-item mode="md" *ngFor="let questionOption of question.options" lines="none"
            [ngClass]="{'active': (questionOption == question.underwriting_answers[0].object_answer || (questionOption === 'Yes' && question.underwriting_answers[0].object_answer === true) || (questionOption === 'No' && question.underwriting_answers[0].object_answer === false) )}"
            >
            <ion-text>{{questionOption}}</ion-text>
            <ion-icon class="checked"
                [ngClass]="{'selected': (questionOption == question.underwriting_answers[0].object_answer || (questionOption === 'Yes' && question.underwriting_answers[0].object_answer === true) || (questionOption === 'No' && question.underwriting_answers[0].object_answer === false) )}"
                src="assets/icon/app-icons/tick-primary.svg" slot="end"></ion-icon>
        </ion-item>
    </ion-radio-group>
</div>