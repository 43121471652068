import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { IonContent } from '@ionic/angular';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { BUSNPolicyService } from '@takectrl-multiapp/svcs-core-business';
import {
  AssetType,
  CoverType,
  IInsuredAllRisk,
  IInsuredAsset,
  IInsuredBuilding,
  IInsuredCaravan,
  IInsuredContent,
  IInsuredMotorcycle,
  IInsuredVaps,
  IInsuredVehicle,
  IPolicyBenefit,
  IPolicyBenefitGroup,
  QuoteComparisonDetailType,
  SharedConfig,
} from '@takectrl-multiapp/svcs-share-config';

type BenefitsScreenLabelType = 'convenience' | 'essential' | 'excess' | 'Third party legal liability' | 'Special cover';
declare const dataLayer: any[];

@Component({
  selector: 'wallet-asset-detail',
  templateUrl: './wallet-asset-detail.component.html',
  styleUrls: ['./wallet-asset-detail.component.scss'],
})
export class WalletAssetDetailComponent implements OnInit {
  @ViewChild(IonContent, { static: false }) public content: IonContent;
  @Input() asset_type: AssetType;
  @Input() asset: IInsuredAsset;
  @Input() selectedInsurerId: string;

  private coverType: CoverType = CoverType.Comprehensive;
  public headerText: string;
  public headerSubText: string;
  public tabsArray: Array<unknown> = [];
  public activeClass = '';
  public tick = 'assets/icon/genetic-icons/tick-primaryX2.svg';
  public closeGrey = 'assets/icon/genetic-icons/close-greyX2.svg';
  public policyBenefits: IPolicyBenefit;
  public visibleBenefitsLabel: BenefitsScreenLabelType = 'essential';
  public visibleBenefits: IPolicyBenefitGroup;
  public visibleItems: Array<unknown>;
  public subGroupItems: Array<unknown>;
  public subGroupItemsHeading: string;
  public vapsBenefits: any;
  public visibleVapsBenefitsLabel: any;
  AssetType = AssetType;
  QuoteComparisonDetailType = QuoteComparisonDetailType;
  
  constructor(
    private readonly busnPolicyService: BUSNPolicyService,
    public sharedConfig: SharedConfig,
    private readonly busnThirdPartyService: BUSNThirdPartyService
  ) {}

  ngOnInit(): void {
    dataLayer.push({
      event: 'page_show',
      screenPath: 'wallet-asset-detail',
      screenName: 'wallet-asset-detail',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });

    switch (this.asset_type) {
      case AssetType.Vehicles:
        this.busnThirdPartyService.logEvent('page_wallet_details', { asset_type: AssetType.Vehicles as AssetType });
        this.headerText = (this.asset as IInsuredVehicle)?.details?.make
          ? (this.asset as IInsuredVehicle)?.details?.make
          : (this.asset as IInsuredVehicle)?.make;
        this.headerSubText = (this.asset as IInsuredVehicle)?.details?.model
          ? (this.asset as IInsuredVehicle)?.details?.model
          : (this.asset as IInsuredVehicle)?.model;
        break;
      case AssetType.Motorcycles:
        console.log(this.asset);
        this.busnThirdPartyService.logEvent('page_wallet_details', { asset_type: AssetType.Motorcycles });
        this.headerText = (this.asset as IInsuredMotorcycle)?.details?.make
          ? (this.asset as IInsuredMotorcycle)?.details?.make
          : (this.asset as IInsuredMotorcycle)?.make;
        this.headerSubText = (this.asset as IInsuredMotorcycle)?.details?.model
          ? (this.asset as IInsuredMotorcycle)?.details?.model
          : (this.asset as IInsuredMotorcycle)?.model;
        break;
      case AssetType.Caravans:
        this.busnThirdPartyService.logEvent('page_wallet_details', { asset_type: AssetType.Caravans });
        this.headerText = (this.asset as IInsuredCaravan)?.details?.make
          ? (this.asset as IInsuredCaravan)?.details?.make
          : (this.asset as IInsuredCaravan)?.make;
        this.headerSubText = (this.asset as IInsuredCaravan)?.details?.model
          ? (this.asset as IInsuredCaravan)?.details?.model
          : (this.asset as IInsuredCaravan)?.model;
        break;
      case AssetType.Buildings:
        this.busnThirdPartyService.logEvent('page_wallet_details', { asset_type: AssetType.Buildings });
        this.headerText = (this.asset as IInsuredBuilding)?.details?.risk_address_line1
          ? (this.asset as IInsuredBuilding)?.details?.risk_address_line1
          : (this.asset as IInsuredBuilding)?.address?.risk_address_line1;
        this.headerSubText = (this.asset as IInsuredBuilding)?.details?.risk_address_line2
          ? (this.asset as IInsuredBuilding)?.details?.risk_address_line2
          : (this.asset as IInsuredBuilding)?.address?.risk_address_line2;
        break;
      case AssetType.Contents:
        this.busnThirdPartyService.logEvent('page_wallet_details', { asset_type: AssetType.Contents });
        this.headerText = (this.asset as IInsuredContent)?.details?.risk_address_line1
          ? (this.asset as IInsuredContent)?.details?.risk_address_line1
          : (this.asset as IInsuredContent)?.address?.risk_address_line1;
        this.headerSubText = (this.asset as IInsuredContent)?.details?.risk_address_line2
          ? (this.asset as IInsuredContent)?.details?.risk_address_line2
          : (this.asset as IInsuredContent)?.address?.risk_address_line2;
        break;
      case AssetType.AllRisks:
        this.busnThirdPartyService.logEvent('page_wallet_details', { asset_type: AssetType.AllRisks });
        this.headerText = (this.asset as IInsuredAllRisk).asset_detail?.description
          ? (this.asset as IInsuredAllRisk)?.asset_detail?.description
          : (this.asset as IInsuredAllRisk)?.description
          ? (this.asset as IInsuredAllRisk)?.description
          : 'Clothing and personal effects';
        this.headerSubText = '';
        break;
      case AssetType.Other:
        this.busnThirdPartyService.logEvent('page_wallet_details', { asset_type: AssetType.Other });
        this.headerText = 'Everything else';
        this.headerSubText = '';
        this.visibleBenefitsLabel = 'Third party legal liability';
        break;
      case AssetType.Vaps:
        this.busnThirdPartyService.logEvent('page_wallet_details', { asset_type: AssetType.Vaps });
        this.headerText = (this.asset as IInsuredVaps).product_type;
        if (
          (this.asset as IInsuredVaps)?.asset_class === AssetType.Vehicles ||
          (this.asset as IInsuredVaps)?.asset_class === AssetType.Motorcycles ||
          (this.asset as IInsuredVaps)?.asset_class === AssetType.Caravans
        ) {
          this.headerSubText = `${(this.asset as IInsuredVaps)?.details?.make} ${(this.asset as IInsuredVaps)?.details?.model}`;
        } else if (
          (this.asset as IInsuredVaps)?.asset_class === AssetType.Buildings ||
          (this.asset as IInsuredVaps)?.asset_class === AssetType.Contents
        ) {
          this.headerSubText = (this.asset as IInsuredVaps)?.details?.risk_address_line1;
        } else {
          this.headerSubText = (this.asset as IInsuredVaps)?.specified_item?.description;
        }
        this.coverType = CoverType.Vaps;
        break;
      default:
        break;
    }
    if (this.asset_type === AssetType.Vaps) {
      this.busnPolicyService
        .getVapsPolicyBenefits(this.selectedInsurerId, this.coverType, (this.asset as IInsuredVaps).product_id)
        .then((benefits) => {
          this.policyBenefits = benefits;
          this.showVapsBenefits(this.policyBenefits.benefitGroups[0].description);
        });
    } else {
      this.busnPolicyService.getPolicyBenefits(this.asset_type, this.coverType).then((benefits) => {
        this.policyBenefits = benefits;
        this.showBenefits(this.visibleBenefitsLabel);
      });
    }
  }

  showVapsBenefits(benefit) {
    this.visibleVapsBenefitsLabel = benefit;
    this.vapsBenefits = this.policyBenefits.benefitGroups.filter((benefitDet) => benefitDet.description === benefit)[0];
    this.visibleItems = this.vapsBenefits.group_items.map((item) => {
      const isIcon = item.value === 'Yes' || item.value === 'No';
      let value = item.value;
      const description = item.description;
      const setColor = value;
      if (isIcon) {
        value = item.value === 'Yes' ? this.tick : this.closeGrey;
      }
      return { description, isIcon, value, setColor };
    });
  }

  showBenefits(benefit: BenefitsScreenLabelType): void {
    this.sharedConfig.setShowCustomLoader(true)
    this.visibleBenefitsLabel = benefit;
    this.subGroupItems = [];
    this.subGroupItemsHeading = '';
    if (benefit !== QuoteComparisonDetailType.Excess && benefit) {
      this.visibleBenefits = this.policyBenefits.benefitGroups.filter(
        (benefitDet) => benefitDet.description.toUpperCase() == benefit.toUpperCase()
      )[0];
      this.visibleItems = this.visibleBenefits.groupItems.map((item) => {
        const isIcon = item.values[this.selectedInsurerId] === 'Yes' || item.values[this.selectedInsurerId] === 'No';
        let value = item.values[this.selectedInsurerId];
        const description = item.description;
        const setColor = value;
        if (isIcon) {
          value = item.values[this.selectedInsurerId] === 'Yes' ? this.tick : this.closeGrey;
        }
        return { description, isIcon, value, setColor };
      });
      this.subGroupItemsHeading = this.visibleBenefits?.subBenefitGroup?.description;
      this.subGroupItems = this.visibleBenefits?.subBenefitGroup?.groupItems.map((item) => {
        const isIcon = false;
        let value = '';
        const description = item.description;
        const setColor = value;
        if (isIcon) {
          value = item.values[this.selectedInsurerId] === 'Yes' ? this.tick : this.closeGrey;
        }
        return { description, isIcon, value, setColor };
      });
      this.sharedConfig.setShowCustomLoader(false)
    } else {
      if (this.asset?.excess) {
        this.visibleItems = this.asset.excess.map((excess) => {
          const isIcon = false;
          let value = '';
          const description = excess.description;
          const setColor = value;
          if (excess.percentage > 0) {
            value = `${excess.percentage}% of claim, minimum R${excess.minimum_amount}`;
          } else {
            value = `R${excess.minimum_amount}`;
          }
          return { description, isIcon, value, setColor };
        });
        this.sharedConfig.setShowCustomLoader(false)
      } else {
        this.visibleItems = this.asset.quote_detail[0].excesses.map((excess) => {
          const isIcon = false;
          let value = '';
          const description = excess.description;
          const setColor = value;
          if (excess.percentage > 0) {
            value = `${excess.percentage}% of claim, minimum R${excess.minimum_amount}`;
          } else {
            value = `R${excess.minimum_amount}`;
          }
          return { description, isIcon, value, setColor };
        });
        this.sharedConfig.setShowCustomLoader(false)
      }
    }

    if (this.content) {
      this.content.getScrollElement().then((scrollElement) => {
        SharedConfig.pageHasScrollbar = scrollElement.scrollHeight > scrollElement.clientHeight;
      });
    }
  }
}
