<div class="backdrop"></div>
<div class="popup-standard limit-width-on-desktop-float" [ngClass]="{ 'popup-dailog-bottom': setBottom }">
  <div class="text-center">
    <h2 class="h2-bold" style="color: black">{{ sharedConfig.popupDialogHeader }}</h2>
  </div>
  <div class="sub-header">
    <p style="color: black">{{ sharedConfig.popupDialogSubHeader }}</p>
  </div>
  <div [ngSwitch]="sharedConfig.popupDialogAnswerSelectedQuestion?.type">
    <input-dropdown-motor-selector
      *ngSwitchCase="QuestionType.vehicleSearch"
      [readOnly]="false"
      [labelPosition]="'stacked'"
      [question]="sharedConfig.popupDialogAnswerSelectedQuestion"
      [showHeading]="true"
      (answerQuestion)="answerQuestion($event, i)"
    ></input-dropdown-motor-selector>
    <input-text-single-line
      *ngSwitchCase="QuestionType.text"
      [readOnly]="false"
      [question]="sharedConfig.popupDialogAnswerSelectedQuestion"
      [showHeading]="false"
      (answerQuestion)="answerQuestion($event, i)"
      [error]="errorDescription"
    ></input-text-single-line>
    <input-text-single-line
      *ngSwitchCase="QuestionType.number"
      [readOnly]="false"
      [question]="sharedConfig.popupDialogAnswerSelectedQuestion"
      [showHeading]="false"
      (answerQuestion)="answerQuestion($event, i)"
      [error]="errorDescription"
    ></input-text-single-line>
    <input-text-address
      *ngSwitchCase="QuestionType.address"
      [readOnly]="false"
      [question]="sharedConfig.popupDialogAnswerSelectedQuestion"
      [showHeading]="false"
      (answerQuestion)="answerQuestion($event, i)"
    ></input-text-address>
    <input-text-multi-lines
      [readOnly]="false"
      *ngSwitchCase="QuestionType.multi"
      [question]="sharedConfig.popupDialogAnswerSelectedQuestion"
      [showHeading]="true"
      (answerQuestion)="answerQuestion($event, i)"
    ></input-text-multi-lines>
    <input-check-list-multi-select
      [readOnly]="false"
      *ngSwitchCase="QuestionType.multiSelect"
      [question]="sharedConfig.popupDialogAnswerSelectedQuestion"
      [showHeading]="true"
      (answerQuestion)="answerQuestion($event, i)"
    ></input-check-list-multi-select>
    <input-check-list-single-select
      [readOnly]="false"
      *ngSwitchCase="QuestionType.boolean"
      [question]="sharedConfig.popupDialogAnswerSelectedQuestion"
      [showHeading]="false"
      (answerQuestion)="answerQuestion($event, i)"
    ></input-check-list-single-select>
    <input-check-list-single-select
      [readOnly]="false"
      *ngSwitchCase="QuestionType.select"
      [question]="sharedConfig.popupDialogAnswerSelectedQuestion"
      [showHeading]="false"
      (answerQuestion)="answerQuestion($event, i)"
    ></input-check-list-single-select>
    <input-datepicker
      [readOnly]="false"
      *ngSwitchCase="QuestionType.date"
      [question]="sharedConfig.popupDialogAnswerSelectedQuestion"
      [showHeading]="false"
      (answerQuestion)="answerQuestion($event, i)"
      [error]="errorDescription"
    ></input-datepicker>
    <input-datepicker
      [readOnly]="false"
      *ngSwitchCase="QuestionType.dateTime"
      [question]="sharedConfig.popupDialogAnswerSelectedQuestion"
      [showHeading]="false"
      (answerQuestion)="answerQuestion($event, i)"
      [error]="errorDescription"
    ></input-datepicker>
    <input-single-search
      [readOnly]="false"
      *ngSwitchCase="QuestionType.search"
      [question]="sharedConfig.popupDialogAnswerSelectedQuestion"
      [showHeading]="false"
      (answerQuestion)="answerQuestion($event)"
      [error]="errorDescription"
    ></input-single-search>
    <input-previous-claims
      *ngSwitchCase="'CLAIMLIST'"
      [question]="sharedConfig.popupDialogAnswerSelectedQuestion"
      [showHeading]="false"
      [readOnly]="false"
      (answerQuestion)="answerQuestion($event, i)"
      [error]="errorDescription"
    ></input-previous-claims>
  </div>

  <div class="buttons-wrapper">
    <ion-button *ngIf="sharedConfig.popupDialogButtonOneText" mode="ios" class="primary-button" (click)="buttonOneAction()">{{
      sharedConfig.popupDialogButtonOneText
    }}</ion-button>
    <ion-button *ngIf="sharedConfig.popupDialogButtonTwoText" mode="ios" class="secondary-button" (click)="buttonTwoAction()">
      {{ sharedConfig.popupDialogButtonTwoText }}
    </ion-button>
  </div>
</div>
