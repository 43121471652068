<div class="compare-tool-wrapper">
  <ion-grid>
    <ion-row>
      <ion-col class="mr-8">
        <div class="quote-wrapper" (click)="changeQuoteOne()">
          <div class="recommended" *ngIf="selectedQuoteToCompareOne?.recommended">
            <p>Recommended</p>
          </div>
          <div class="flex">
            <div class="flex-inside">
              <img src="assets/images/insurer-logos/left-color-logos/{{ selectedQuoteToCompareOne?.insurer_id | lowercase }}-colour.svg" />
              <p>
                <b>R {{ selectedQuoteToCompareOne?.total_premium | number : '1.0-0' }}</b>
              </p>
            </div>
            <ion-icon name="chevron-down" class="animated bounce"></ion-icon>
          </div>
        </div>
      </ion-col>
      <ion-col>
        <div class="quote-wrapper" (click)="changeQuoteTwo()">
          <div class="recommended" *ngIf="selectedQuoteToCompareTwo?.recommended">
            <p>Recommended</p>
          </div>
          <div class="flex">
            <div class="flex-inside">
              <img src="assets/images/insurer-logos/left-color-logos/{{ selectedQuoteToCompareTwo?.insurer_id | lowercase }}-colour.svg" />
              <p>
                <b>R {{ selectedQuoteToCompareTwo?.total_premium | number : '1.0-0' }}</b>
              </p>
            </div>
            <ion-icon name="chevron-down" class="animated bounce"></ion-icon>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
