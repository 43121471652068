import { BUSNRenewalService } from '@takectrl-multiapp/svcs-core-business';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AssetType, IRenewal, SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
declare const dataLayer: any[];

export interface IRenewalAssetDisplay {
  type: AssetType;
  icon: string;
  assets: Array<unknown>;
}

@Component({
  selector: 'renewal-details',
  templateUrl: './renewal-details.component.html',
  styleUrls: ['./renewal-details.component.scss'],
})
export class RenewalDetailsComponent implements OnInit {
  public renewalData: IRenewal;
  public vehicles: Array<unknown> = [];
  public motorcycles: Array<unknown> = [];
  public renewalAssets: Array<IRenewalAssetDisplay>;
  public renewalAssetsKeys: Array<string>;

  constructor(
    private readonly route: ActivatedRoute,
    public navCtrl: NavController,
    public sharedConfig: SharedConfig,
    private readonly busnRenewalService: BUSNRenewalService,
    private readonly busnThirdPartyService: BUSNThirdPartyService
  ) {}

  ngOnInit(): void {
    dataLayer.push({
      event: 'page_show',
      screenPath: 'renewal-details',
      screenName: 'renewal-details',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });

    const queryParams = this.route.snapshot.queryParams;
    this.renewalData = queryParams.renewal;

    this.renewalAssets = [
      {
        type: AssetType.Vehicles,
        icon: AssetType.Vehicles,
        assets: Object.values(this.renewalData.policy_diff.insured_vehicles ?? {}),
      },
      {
        type: AssetType.Motorcycles,
        icon: AssetType.Motorcycles,
        assets: Object.values(this.renewalData.policy_diff.insured_motorcycles ?? {}),
      },
      {
        type: AssetType.Caravans,
        icon: AssetType.Caravans,
        assets: Object.values(this.renewalData.policy_diff.insured_caravans ?? {}),
      },
      {
        type: AssetType.Buildings,
        icon: AssetType.Buildings,
        assets: Object.values(this.renewalData.policy_diff.insured_buildings ?? {}),
      },
      {
        type: AssetType.Contents,
        icon: AssetType.Contents,
        assets: Object.values(this.renewalData.policy_diff.insured_contents ?? {}),
      },
      {
        type: AssetType.AllRisks,
        icon: AssetType.AllRisks,
        assets: Object.values(this.renewalData.policy_diff.insured_all_risk ?? {}),
      },
      {
        type: AssetType.SpecifiedItems,
        icon: AssetType.SpecifiedItems,
        assets: Object.values(this.renewalData.policy_diff.insured_specified_items ?? {}),
      },
    ];
  }

  getExcessesForAsset(asset: any): Array<unknown> {
    return Object.values(asset.excess ?? {});
  }

  makeAssetHeading(asset_type: AssetType, asset: any) {
    switch (asset_type) {
      case AssetType.Motorcycles:
      case AssetType.Caravans:
      case AssetType.Vehicles: {
        return `${asset.details.make} ${asset.details.model}`;
      }
      case AssetType.Buildings:
      case AssetType.Contents: {
        return `${asset.details.risk_address_line1} ${asset.details.risk_address_line2}`;
      }
      case AssetType.AllRisks:
      case AssetType.SpecifiedItems: {
        return asset.details.description;
      }
      default: {
        return 'Asset';
      }
    }
  }
  async closeRenewalPage() {
    this.busnRenewalService.closeRenewal();
    this.busnThirdPartyService.logEvent('action_renewal_accepted');
  }

  contactBroker() {
    this.busnThirdPartyService.intercomDisplayLauncher();
  }
}
