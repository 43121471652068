import { Component, Input, NgZone, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { BUSNQuoteScheduleService } from '@takectrl-multiapp/svcs-core-business';
import { QuoteType, SharedConfig, SharedDialogService } from '@takectrl-multiapp/svcs-share-config';
import '@lottiefiles/lottie-player';
import { SharedConfigService } from 'libs/svcs-share-config/src/lib/services/shared-config.service';
import { firstValueFrom } from 'rxjs';
@Component({
  selector: 'get-started-animation',
  templateUrl: './get-started-animation.component.html',
  styleUrls: ['./get-started-animation.component.scss'],
})
export class GetStartedAnimationComponent implements OnInit {
  @Input() showRegisterSuccess: boolean;
  @Input() animationSrc: string;
  @Input() sub_heading: string;
  constructor(
    private readonly busnThirdPartyService: BUSNThirdPartyService,
    public sharedConfig: SharedConfig,
    public sharedConfigService: SharedConfigService,
    public busnQuoteScheduleService: BUSNQuoteScheduleService,
    public sharedDialogService: SharedDialogService,
    public navCtrl: NavController,
    private readonly zone: NgZone
  ) {}

  ngOnInit(): void {}

  async getStarted() {
    const user = await firstValueFrom(this.sharedConfigService.user$);

    this.sharedConfig.setShowCustomLoader(true)
    this.busnThirdPartyService.logEvent('action_start_new_quote');
    try {
      await this.busnQuoteScheduleService.createNewQuoteRequest();
      SharedConfig.showFooterTabs = true;
      this.sharedConfig.setShowCustomLoader(false)
      if (user.personal_details.is_completed || user.personal_details.email.includes('fake')) {
        this.compNavigateHome();
      } else {
        this.compNavigatePersonalDetails();
      }
    } catch (error) {
      this.sharedConfig.setShowCustomLoader(false)
      SharedConfig.showLottieFullScreen = false;
      this.sharedDialogService.showPopupDialog({
        header: 'Something went wrong!',
        subHeader: error.message,
        buttonOneText: 'OK',
        buttonOneCallback: this.sharedDialogService.hidePopupDialog,
      });
    }
  }

  compNavigatePersonalDetails() {
    const quoteType: QuoteType = QuoteType.StandardQuoteRequest;
    this.zone.run(() => {
      this.navCtrl.navigateRoot('personal-details', { queryParams: { quoteType: quoteType } });
    });
  }

  compNavigateHome() {
    SharedConfig.showLottieFullScreen = false;
    this.zone.run(() => {
      this.navCtrl.navigateRoot('home/home-wallet');
    });
  }
}
