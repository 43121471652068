<ion-slides class="limit-width-on-desktop" #slides [options]="slideOpts" (ionSlideReachEnd)="sliderAtEnd()" (ionSlideDidChange)="slideDidChange()">
  <ion-slide>
    <video #stepOne poster="assets/images/posters/stepOnePoster.png" [muted]="true" autoplay muted defaultMuted playsinline (ended)="onEnded()">
      <source [src]="sharedConfig.appClientDetail.landing_videos.step_one_video_url" type="video/mp4" />
    </video>
  </ion-slide>

  <ion-slide>
    <video #stepTwo poster="assets/images/posters/stepTwoPoster.png" [muted]="true" preload="auto" muted defaultMuted playsinline>
      <source [src]="sharedConfig.appClientDetail.landing_videos.step_two_video_url" type="video/mp4" />
    </video>
  </ion-slide>

  <ion-slide>
    <video #stepThree poster="assets/images/posters/stepThreePoster.png" [muted]="true" preload="auto" muted defaultMuted playsinline>
      <source [src]="sharedConfig.appClientDetail.landing_videos.step_three_video_url" type="video/mp4" />
    </video>
  </ion-slide>

  <ion-slide>
    <video #stepFour poster="assets/images/posters/stepFourPoster.png" [muted]="true" preload="auto" muted defaultMuted playsinline>
      <source [src]="sharedConfig.appClientDetail.landing_videos.step_four_video_url" type="video/mp4" />
    </video>
  </ion-slide>
</ion-slides>

<div *ngIf="lottieSplash" class="backdrop"></div>
<div *ngIf="lottieSplash" class="center-outer">
  <div class="center-inner">
    <div class="center-auto">
      <div class="loader-wrapper">
        <div role="status">
          <ion-img src="assets/logos/primary.svg"></ion-img>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="footer-wrapper">
  <div class="pagination p4">
    <ul>
      <a [ngClass]="{ 'is-active': getCurrentSliderIndex === 0 }">
        <li></li>
      </a>
      <a [ngClass]="{ 'is-active': getCurrentSliderIndex === 1 }">
        <li></li>
      </a>
      <a [ngClass]="{ 'is-active': getCurrentSliderIndex === 2 }">
        <li></li>
      </a>
      <a [ngClass]="{ 'is-active': getCurrentSliderIndex === 3 }">
        <li></li>
      </a>
    </ul>
  </div>
  <div class="buttons limit-width-on-desktop">
    <div class="signup-btn-div">
      <ion-button mode="ios" color="blue" class="primary-button" (click)="registerPage()"> Register </ion-button>
    </div>
    <div class="login-btn-div" (click)="loginPage()" routerDirection="forward">
      <ion-button mode="ios" class="secondary-button"> Log in </ion-button>
    </div>
  </div>
</div>




<!-- TODO ALL OTHER POPUPS SHOULD BE REPLACED BY THIS GENERIC POPUP -->
<popup-dialog   id="landing.component" *ngIf="sharedConfig.popupDialogShow"></popup-dialog>
<div class="maintenance-popup-backdrop" *ngIf="sharedConfig.appSettings?.maintenance_mode && m_mode_popup"></div>
<div class="maintenance-popup limit-width-on-desktop" *ngIf="sharedConfig.appSettings?.maintenance_mode && m_mode_popup">
  <div class="lotti-maintenance">
    <lottie-player autoplay loop mode="normal" src="assets/lottie-animations/maintenance-mode.json"> </lottie-player>
  </div>
  <div class="heading">
    <h2 class="h2-bold">Maintenance Mode</h2>
  </div>
  <div class="message">
    <p>Hi there! We will be running a system upgrade tonight. While we do our magic, get some rest and visit us again
      tomorrow.
      We thank you for your patience and apologise for the inconvenience.</p>
  </div>
  <div class="buttons-wrapper">
    <ion-item mode="ios" lines="none">
      <ion-icon (click)="showBypass()" slot="end" name="enter-outline"></ion-icon>
    </ion-item>
  </div>
</div>

<popup-dialog id="forgot.password.component" *ngIf="sharedConfig.popupDialogShow"></popup-dialog>
