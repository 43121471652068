import { Injectable, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class Rbix implements OnDestroy {
  private currentPage: string = '';
  private pageStartTime: number = 0;
  private sessionUpdateIntervalId: any;
  private eventQueue: UserActivityEvent[] = [];
  private isProcessingQueue: boolean = false;
  private sessionId: string = '';
  private consecutiveExceptionCount: number = 0;

  constructor(private readonly httpClient: HttpClient, private router: Router, private sharedConfig: SharedConfig) {
    this.initializeTracker();
  }

  private initializeTracker(): void {
    if (false) {
      this.pageStartTime = Date.now();
      this.generateSessionId().then((id) => (this.sessionId = id));
      this.setupRouterEventListener();
      this.startPeriodicSessionUpdate();
    }
  }

  private setupRouterEventListener(): void {
    this.router.events.pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.handlePageNavigation(event);
    });
  }

  private handlePageNavigation(event: NavigationEnd): void {
    const timeSpent = this.calculateTimeSpent();
    this.logPageNavigation(this.currentPage, timeSpent);
    this.currentPage = event.urlAfterRedirects;
    this.pageStartTime = Date.now();
  }

  private startPeriodicSessionUpdate(): void {
    this.sessionUpdateIntervalId = setInterval(() => {
      const timeSpent = this.calculateTimeSpent();
      this.updateSessionStatus(this.currentPage, 'active', timeSpent);
    }, 5000);
  }

  public logUserEvent(eventDetails: UserEventDetails): void {
    this.addEventToQueue({
      type: 'logEvent',
      details: eventDetails,
    });
  }

  public logPageNavigation(page: string, timeSpent: number): void {
    if (page) {
      this.addEventToQueue({
        type: 'logPageNavigation',
        page,
        timeSpent,
      });
    }
  }

  public updateSessionStatus(page: string, status: 'active' | 'inactive', timeSpent: number): void {
    this.addEventToQueue({
      type: 'updateSessionStatus',
      page,
      status,
      timeSpent,
    });
  }

  private addEventToQueue(event: UserActivityEvent): void {
    this.eventQueue.push(event);
    this.processQueueIfPossible();
  }

  private processQueueIfPossible(): void {
    if (!this.isProcessingQueue && this.consecutiveExceptionCount < 2) {
      this.processQueue();
    }
  }

  private processQueue(): void {
    if (this.isProcessingQueue || !this.isUserAuthenticated()) return;

    this.isProcessingQueue = true;
    try {
      while (this.eventQueue.length > 0) {
        const event = this.eventQueue.shift();
        if (event) this.processEvent(event);
      }
      this.consecutiveExceptionCount = 0;
    } catch (error) {
      this.consecutiveExceptionCount++;
      console.error('Error processing queue:', error);
    } finally {
      this.isProcessingQueue = false;
    }
  }

  private processEvent(event: UserActivityEvent): void {
    switch (event.type) {
      case 'logEvent':
        this.sendEventToServer(event.details);
        break;
      case 'logPageNavigation':
        this.sendPageNavigationToServer(event.page, event.timeSpent);
        break;
      case 'updateSessionStatus':
        this.sendSessionStatusToServer(event.page, event.status, event.timeSpent);
        break;
    }
  }

  private sendEventToServer(eventDetails: UserEventDetails): void {
    const url = `${this.sharedConfig.getAppCredentials.rbix_api_url}/event-tracking/action-event`;
    const body = {
      uid: this.sharedConfig.firebaseUser.uid,
      sessionId: this.sessionId,
      page: this.currentPage,
      clickType: eventDetails.type,
      tagName: eventDetails.tagName,
      elementId: eventDetails.id,
      elementClass: eventDetails.classList,
      timestamp: new Date().toISOString(),
    };
    this.sendHttpRequest(url, body);
  }

  private sendPageNavigationToServer(page: string, timeSpent: number): void {
    const url = `${this.sharedConfig.getAppCredentials.rbix_api_url}/event-tracking/page-navigation`;
    const body = {
      uid: this.sharedConfig.firebaseUser.uid,
      sessionId: this.sessionId,
      page: page,
      timeSpent: timeSpent / 1000,
    };
    this.sendHttpRequest(url, body);
  }

  private sendSessionStatusToServer(page: string, status: 'active' | 'inactive', timeSpent: number): void {
    const url = `${this.sharedConfig.getAppCredentials.rbix_api_url}/event-tracking/session-active-pulse`;
    const body = {
      uid: this.sharedConfig.firebaseUser.uid,
      sessionId: this.sessionId,
      page: page,
      status: status,
      timestamp: new Date().toISOString(),
      timeSpent: timeSpent / 1000,
    };
    this.sendHttpRequest(url, body);
  }

  private sendHttpRequest(url: string, body: any): void {
    const headers = new HttpHeaders().set('authorization', `Bearer ${this.sharedConfig.getAppCredentials.rbix_token}`);
    this.httpClient
      .post(url, body, { headers })
      .pipe(take(1))
      .subscribe({
        error: (error) => console.error('Error sending request:', error),
      });
  }

  private async generateSessionId(): Promise<string> {
    const date = new Date();
    const formattedDate = date.toISOString().split('T')[0];
    const uniqueId = uuidv4();
    return `${formattedDate}-${uniqueId}`;
  }

  private calculateTimeSpent(): number {
    return Date.now() - this.pageStartTime;
  }

  private isUserAuthenticated(): boolean {
    return !!(this.sharedConfig.firebaseUser && this.sharedConfig.firebaseUser.uid);
  }

  ngOnDestroy() {
    clearInterval(this.sessionUpdateIntervalId);
  }
}

interface UserEventDetails {
  type: string;
  tagName: string;
  id?: string;
  classList?: string;
}

type UserActivityEvent =
  | { type: 'logEvent'; details: UserEventDetails }
  | { type: 'logPageNavigation'; page: string; timeSpent: number }
  | { type: 'updateSessionStatus'; page: string; status: 'active' | 'inactive'; timeSpent: number };
