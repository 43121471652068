export const ResponseCodeEnum = {
  ok: { code: 200, message: 'OK' },
  created: { code: 201, message: 'Created' },
  accepted: { code: 202, message: 'Accepted' },
  noContent: { code: 204, message: 'No Content' },
  movedPermanently: { code: 301, message: 'Moved Permanently' },
  found: { code: 302, message: 'Found' },
  seeOther: { code: 303, message: 'See Other' },
  notModified: { code: 304, message: 'Not Modified' },
  badRequest: { code: 400, message: 'Bad Request' },
  unauthorized: { code: 401, message: 'Unauthorized' },
  paymentRequired: { code: 402, message: 'Payment Required' },
  forbidden: { code: 403, message: 'Forbidden' },
  notFound: { code: 404, message: 'Not Found' },
  methodNotAllowed: { code: 405, message: 'Method Not Allowed' },
  notAcceptable: { code: 406, message: 'Not Acceptable' },
  requestTimedOut: { code: 408, message: 'Request Time-out' },
  conflict: { code: 409, message: 'Conflict' },
  gone: { code: 410, message: 'Gone' },
  lengthRequired: { code: 411, message: 'Length Required' },
  preconditionFailed: { code: 412, message: 'Precondition Failed' },
  requestURITooLarge: { code: 414, message: 'Request-URI Too Large' },
  unsupportedMediaType: { code: 415, message: 'Unsupported Media Type' },
  requestRangeNotSatisfiable: { code: 416, message: 'Requested range not satisfiable' },
  internalServerError: { code: 500, message: 'Internal Server Error' },
  notImplemented: { code: 501, message: 'Not Implemented' },
  badGateway: { code: 502, message: 'Bad Gateway' },
  httpVersionNotSupported: { code: 505, message: 'HTTP Version not supported' },
};
