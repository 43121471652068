import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AssetType, IQuestion, QuestionType, QuoteType, SharedConfig, SharedDialogService } from '@takectrl-multiapp/svcs-share-config';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { BUSNAssetService, BUSNQuestionService, BUSNQuoteScheduleService, BUSNRbixService } from '@takectrl-multiapp/svcs-core-business';
import { PageType } from 'libs/svcs-share-config/src/lib/types/page.type';

@Component({
  selector: 'quote-questions-object-mapping',
  templateUrl: './quote-questions-object-mapping.component.html',
  styleUrls: ['./quote-questions-object-mapping.component.scss'],
})
export class QuoteQuestionsObjectMappingComponent implements OnInit, AfterViewInit {
  @Output() navigateBack: EventEmitter<void> = new EventEmitter<void>();
  @Input() asset_type: AssetType;
  @Input() quoteType: QuoteType;
  @Input() assetTypeDisplayName: string;
  @ViewChild('backgroundScreenParent', { static: false }) backgroundScreenParent: ElementRef;
  uniqueHeadings: string[];
  headings = [];
  header = 'Vehicle';
  popUpHeading: string;
  popupMessage: string;
  subHeader: string;
  showUpdatePopup = false;
  fullScreenRecording = true;

  allAnswered = false;
  constructor(
    public sharedConfig: SharedConfig,
    public sharedDialogService: SharedDialogService,
    private readonly cd: ChangeDetectorRef,
    public busnThirdPartyService: BUSNThirdPartyService,
    public busnQuoteScheduleService: BUSNQuoteScheduleService,
    public busnRbixService: BUSNRbixService,
    public busnQuestionService: BUSNQuestionService,
    public busnAssetService: BUSNAssetService
  ) {
    this.saveAnswerToObject = this.saveAnswerToObject.bind(this);
    this.cancelClosePopup = this.cancelClosePopup.bind(this);
    SharedConfig.selectedPage = PageType.questionRbMaps;
  }

  ngOnInit(): void {}

  async ngAfterViewInit(): Promise<void> {
    console.log(this.quoteType);
    if (!SharedConfig.startRecordingByPassPageDetectChanges) await this.busnRbixService.initializeAndStartRecordingForObjectMap();
  }

  assetQuestionAnswerAction(question: IQuestion) {
    SharedConfig.popupDialogAnswerSelectedQuestion = question;
    SharedConfig.popupDialogHeader = question.heading;
    SharedConfig.popupDialogSubHeader = question.sub_heading;

    if (question.type !== QuestionType.boolean && question.type !== QuestionType.select && question.type !== QuestionType.vehicleSearch) {
      SharedConfig.popupDialogButtonOneText = 'Ok';
    }
    SharedConfig.popupDialogButtonOneCallBack = this.saveAnswerToObject;
    SharedConfig.popupDialogButtonTwoCallBack = this.cancelClosePopup;
    SharedConfig.popupDialogButtonTwoText = 'Cancel';
    SharedConfig.popupDialogAnswerQuestionShow = true;

    const backgroundScreenParent = document.getElementById('background-screen-parent');
    backgroundScreenParent.classList.add('disable-pointer-events');
    this.cd.detectChanges();
  }

  cancelClosePopup() {
    SharedConfig.popupDialogAnswerQuestionShow = false;
    delete SharedConfig.popupDialogAnswerSelectedQuestion;
    delete SharedConfig.popupDialogHeader;
    delete SharedConfig.popupDialogSubHeader;
    delete SharedConfig.popupDialogButtonOneCallBack;
    delete SharedConfig.popupDialogButtonTwoCallBack;
    delete SharedConfig.popupDialogButtonOneText;
    delete SharedConfig.popupDialogButtonTwoText;

    const backgroundScreenParent = document.getElementById('background-screen-parent');
    backgroundScreenParent.classList.remove('disable-pointer-events');
    this.busnQuestionService.sanitizeQuestionsComparingToDBSource(SharedConfig.user);
    this.cd.detectChanges();
  }

  isMultiselectAnswerNone(question: IQuestion): boolean {
    if (
      question &&
      question.type === QuestionType.multiSelect &&
      question.underwriting_answers &&
      question.underwriting_answers[0].object_answer !== undefined &&
      question.underwriting_answers[0].object_answer !== null &&
      question.underwriting_answers.every((answer) => !answer.object_answer)
    ) {
      return true;
    }
    return false;
  }

  isAllQuestionsAndUnderwritingQuestionsAnswered(): boolean {
    return this.busnQuestionService.isAllQuestionsAndUnderwritingQuestionsAnswered(SharedConfig.selectedAssetUnderwritingQuestions);
  }

  isAllUnderwritingQuestionsAnswered(question: IQuestion): boolean {
    return this.busnQuestionService.isAllUnderwritingQuestionsAnswered(question);
  }

  hasUnderwritingQuestionError(question: IQuestion): boolean {
    if (question && question.underwriting_answers) {
      for (const underwriting_answer of question.underwriting_answers) {
        if (
          underwriting_answer.object_answer_error !== undefined &&
          underwriting_answer.object_answer_error !== null &&
          underwriting_answer.object_answer_error.length > 0
        ) {
          return true;
        }
      }
      return false;
    }
  }

  async saveAnswerToObject(answeredQuestion: IQuestion) {
    const isValid = this.busnQuoteScheduleService.isFieldValidationSuccess(
      answeredQuestion.underwriting_answers,
      answeredQuestion,
      this.sharedConfig.getSelectedAsset(answeredQuestion.underwriting_answers[0].object_name)
    );
    if (isValid) {
      if (answeredQuestion.type === QuestionType.multi) {
        answeredQuestion.underwriting_answers = answeredQuestion.underwriting_answers.map((answer) => {
          if (answer.object_answer === '') {
            answer.object_answer = '';
          } else {
            answer.object_answer = Number(answer.object_answer);
          }
          return answer;
        });
      }

      if (answeredQuestion.type === QuestionType.number) {
        answeredQuestion.underwriting_answers[0].object_answer = Number(answeredQuestion.underwriting_answers[0].object_answer);
      }

      answeredQuestion.underwriting_answers.map((x) => delete x.object_answer_error);
      this.sharedConfig.setSelectedAssetObjectAnswer(answeredQuestion);
    }

    this.busnQuestionService.sanitizeQuestionsComparingToDBSource(SharedConfig.user);

    const backgroundScreenParent = document.getElementById('background-screen-parent');
    backgroundScreenParent.classList.remove('disable-pointer-events');
    this.cancelClosePopup();
  }

  cancel() {
    this.cancelRecording();
    this.fullScreenRecording = false;
  }

  getAllAnsweredQuestions() {
    return this.busnRbixService.getAllAnsweredQuestions();
  }

  getAllUnansweredQuestions() {
    return this.busnRbixService.getAllUnansweredQuestions();
  }

  startRecording() {
    this.busnRbixService.startRecording();
    this.cd.detectChanges();
  }

  cancelRecording() {
    this.busnRbixService.cancelRecording();
    this.cd.detectChanges();
  }

  stopRecording() {
    this.fullScreenRecording = false;
    this.busnRbixService.stopRecording();
    this.cd.detectChanges();
  }

  closePopup() {
    this.showUpdatePopup = false;
    SharedConfig.showFullScreen = false;
    this.popupMessage = '';
  }

  showIntercom() {
    this.busnThirdPartyService.intercomDisplayLauncher();
    this.showUpdatePopup = false;
    SharedConfig.showFullScreen = false;
    this.popupMessage = '';
  }

  saveAndBack() {
    if (SharedConfig.isRecording) {
      this.busnRbixService.cancelRecording();
    }
    this.navigateBack.emit();
  }
}
