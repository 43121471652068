import { Injectable } from '@angular/core';
import { SharedFormulaService } from '@takectrl-multiapp/svcs-share-config';
import { ThirdPartyEventType } from '../../types/event.type';
declare const analytics;

@Injectable({
  providedIn: 'root',
})
export class SegmentIoService {
  constructor(private readonly sharedFormulaService: SharedFormulaService) {}

  async init(userKey: string, name: string, email: string): Promise<void> {
    await analytics.identify(userKey, {
      name: name,
      email: email,
    });
  }

  logEvent(eventType: ThirdPartyEventType, params: any = { dateUnix: Date.now(), date: this.sharedFormulaService.formatDateyyymmdd(Date.now()) }) {
    analytics.track(eventType, params);
    console.log(`Segment event logged: ${eventType} -> ${JSON.stringify(params)}`);
  }
}
