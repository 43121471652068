import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedConfig } from '@takectrl-multiapp/svcs-share-config';

@Component({
  selector: 'popup-calendar',
  templateUrl: './popup-calendar.component.html',
  styleUrls: ['./popup-calendar.component.scss'],
})
export class PopupCalendarComponent {
  @Input() maxDate: string;
  @Input() minDate: string;
  @Input() ngModal: string;
  @Input() heading: string;
  @Input() dateFormat: string;
  @Output() setDate: EventEmitter<unknown> = new EventEmitter<unknown>();
  public hasValue = false;
  constructor(public sharedConfig: SharedConfig) {}

  ngOnInit(): void {
    if (!this.minDate) {
      this.minDate = "1900-01-01"
    }
    if (!this.maxDate) {
      let currYear = new Date().getFullYear();
      currYear = currYear + 5;
      this.maxDate = currYear.toString();
    }

  }

  pickDate(value) {
    if (value) {
      this.hasValue = true;
    } else {
      this.hasValue = false;
    }
  }

  saveDate(dateValue) {
    this.setDate.emit(dateValue);
    SharedConfig.showCalendarPopup = false;
    SharedConfig.hideProgressBar = false;
    SharedConfig.showFullScreen = false
  }

  closeDate() {
    SharedConfig.showCalendarPopup = false;
    SharedConfig.addingClaim = false;
    SharedConfig.showFullScreen = false
    this.setDate.emit(null)
  }
}
