import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'quote-rating-indicator',
  templateUrl: './quote-rating-indicator.component.html',
  styleUrls: ['./quote-rating-indicator.component.scss']
})
export class QuoteRatingIndicatorComponent implements OnInit {
  @Input() selectedQuoteToCompareRatingOne: number;
  @Input() selectedQuoteToCompareRatingTwo: number;
  @Input() selectedQuoteToCompareRecommendedOne: boolean;
  @Input() selectedQuoteToCompareRecommendedTwo: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
