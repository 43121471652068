import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class SharedEncryptionService {
  constructor() {}

  encryptString(message: string, secret: string): string {
    // Encrypt
    return CryptoJS.AES.encrypt(JSON.stringify(message), secret).toString();
  }

  decryptString(encryptedString: string, secret: string): string {
    // Encrypt
    const bytes = CryptoJS.AES.decrypt(encryptedString, secret);
    const stringBytes = bytes.toString(CryptoJS.enc.Utf8) ;
    return JSON.parse(stringBytes);
  }

  decryptStringECB(encryptedString: string, secret: string): string {
    // Decrypt ECB
    encryptedString = encryptedString.replace(/ /g, '+');
    const key = CryptoJS.enc.Utf8.parse(secret);
    const decrypted = CryptoJS.AES.decrypt(encryptedString, key, { mode: CryptoJS.mode.ECB });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  encryptStringECB(stringToEncrypt: string, secret: string): string {
    // Encrypt ECB
    const key = CryptoJS.enc.Utf8.parse(secret);
    return CryptoJS.AES.encrypt(stringToEncrypt, key, { mode: CryptoJS.mode.ECB }).toString();
  }
}
