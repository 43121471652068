import { Injectable } from '@angular/core';
import { IAppCredentials, IAppSettings } from '@takectrl-multiapp/svcs-share-config';
import { APIHttpService } from './api-http.service';
import { Observable } from 'rxjs/internal/Observable';
import { ICtrlResponse } from '../interfaces/api.interface';
@Injectable({
  providedIn: 'root',
})
export class APISettingsService {
  constructor(private readonly ctrlApiHttpService: APIHttpService) {}

  getAppSettings(): Observable<ICtrlResponse<IAppSettings>> {
    return this.ctrlApiHttpService.get(`/app-settings/`);
  }

  getAppCredentials(): Observable<ICtrlResponse<IAppCredentials>> {
    return this.ctrlApiHttpService.get(`/app-credentials/`);
  }
}
