import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IOccupation, IQuestion } from '@takectrl-multiapp/svcs-share-config';
import { BUSNQuestionService } from '@takectrl-multiapp/svcs-core-business';
@Component({
  selector: 'input-single-search',
  templateUrl: './input-single-search.component.html',
  styleUrls: ['./input-single-search.component.scss'],
})
export class InputSingleSearchComponent implements OnInit {
  @Input() question: IQuestion;
  @Input() error: string;
  @Input() showHeading: boolean;
  @Input() readOnly: boolean;
  @Output() answerQuestion: EventEmitter<IQuestion> = new EventEmitter();
  showResults = false;
  apiRes: any;
  constructor(private readonly questionService: BUSNQuestionService) {}

  ngOnInit(): void {
    if (this.question.underwriting_answers[0].object_answer) {
      this.answerQuestion.emit(this.question)
    }
  }

  search(e: Event) {
    const searchString: string = e.target['value'];
    if (searchString.length >= 3) {
      this.questionService.searchOccupation(searchString).then((apiResults) => {
        if (apiResults) {
          this.apiRes = apiResults.occupations;
          this.showResults = true;
        }
      });
    }
  }

  setSearchResult(searchResult: IOccupation) {
    this.showResults = false;
    const object_field = this.question.underwriting_answers[0].object_field;
    // For this component to be made generic in the future, these question specific sections should be removed
    if (object_field === 'occupation') {
      this.question.underwriting_answers[0].object_answer = searchResult.english;
      this.question.underwriting_answers[1] = {
        ...this.question.underwriting_answers[0],
        hidden: true,
        object_field: 'occupation_id',
        object_answer: searchResult.occupation_code,
      };
    }
    this.answerQuestion.emit(this.question);
  }
}
