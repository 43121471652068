import { HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, NgZone, OnInit, Output } from '@angular/core';
import { NavController } from '@ionic/angular';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { BUSNAssetService, BUSNQuestionService, BUSNQuoteScheduleService, BUSNRbixService } from '@takectrl-multiapp/svcs-core-business';
import { AssetType, QuoteType, ReqResponse, SharedConfig, SharedDialogService } from '@takectrl-multiapp/svcs-share-config';
import { Storage } from '@capacitor/storage';
import { PageActionCodeType } from 'libs/svcs-share-config/src/lib/types/app.type';
import { Router } from '@angular/router';
import { APIHttpService } from 'libs/svcs-ctrl-api/src/lib/services/api-http.service';

interface IRbixResponse {
  hasReply: boolean;
  question_map: IQuestionMap;
  replyAudio: string; //Base64
  //  replyAudio: IReplyAudio;
}

interface IReplyAudio {
  audioConfig: any;
  audioContent: string;
}

export type RbixResponseCode =
  | 'NAV2USFLDOCS'
  | 'NAV2CHNGEMAIL'
  | 'NAV2FSCA'
  | 'OPENINTERCOM'
  | 'NAV2CHANGEPASSWORD'
  | 'NAV2DOWNLOADAPP'
  | 'STARTNEWQUOTE'
  | 'ADDNEWVEHICLE'
  | 'ADDNEWBUILDING'
  | 'ADDNEWMOTORCYCLE'
  | 'ADDNEWCARAVAN'
  | 'ADDNEWCONTENT'
  | 'DELETEALLASSETS'
  | 'ADDNEWALLRISK';

export interface IQuestionMap {
  action: string;
  code: RbixResponseCode;
  conditions: string[];
  date: number;
  key: string;
  reply: string;
}

@Component({
  selector: 'rbix-question-map-record-button',
  templateUrl: './rbix-question-map-record-button.component.html',
  styleUrls: ['./rbix-question-map-record-button.component.scss'],
})
export class RbixQuestionMapRecordButtonComponent implements OnInit {
  @Output() navToPage: EventEmitter<string> = new EventEmitter<string>();

  recordingPopupHeader = 'What would you like to do?';
  recordingPopupSubHeader = 'Type your request below, or press the record button to speak your request';

  constructor(
    public busnThirdPartyService: BUSNThirdPartyService,
    public sharedDialogService: SharedDialogService,
    public navCtrl: NavController,
    private readonly ngZone: NgZone,
    public busnRbixService: BUSNRbixService,
    private readonly router: Router,
    public httpService: APIHttpService,
    public busnQuoteScheduleService: BUSNQuoteScheduleService,
    public busnAssetService: BUSNAssetService,
    public busnQuestionService: BUSNQuestionService,
    private readonly cd: ChangeDetectorRef,
    public sharedConfig: SharedConfig
  ) {
    this.initializeRecordingAndStartRecording = this.initializeRecordingAndStartRecording.bind(this);
    this.cancelRecordingAndClosePopup = this.cancelRecordingAndClosePopup.bind(this);
    this.stopRecordingAndSubmitOrSubmitOnly = this.stopRecordingAndSubmitOrSubmitOnly.bind(this);
    this.showQuestionMapPopupDialog = this.showQuestionMapPopupDialog.bind(this);
    this.initializeAndStartRecordingForQuestionMap = this.initializeAndStartRecordingForQuestionMap.bind(this);
    this.cancelProcessing = this.cancelProcessing.bind(this);
  }

  audio = new Audio();
  showNow = false;

  ngOnInit(): void {
    SharedConfig.isRecording = false;
    SharedConfig.isProcessing = false;
    SharedConfig.stopTranscriptionProcessing = false;
    if (this.busnRbixService.isQuestionMapAvailable()) {
    }
  }

  async showQuestionMapPopupDialog() {
    try {
      // SharedConfig.isRecording = true;
      SharedConfig.isRecording = false;
      SharedConfig.isProcessing = false;
      this.sharedDialogService.showPopupDialog({
        header: this.recordingPopupHeader,
        subHeader: this.recordingPopupSubHeader,
        textAreaPlaceholder: 'insure my car... get help with...',
        imageUrl: 'assets/icon/loader/recording.svg',
        buttonOneText: 'Submit',
        buttonTwoText: 'Cancel',
        buttonThreeText: 'Use Voice',
        buttonOneCallback: this.stopRecordingAndSubmitOrSubmitOnly,
        buttonTwoCallback: this.cancelRecordingAndClosePopup,
        buttonThreeCallback: this.initializeRecordingAndStartRecording,
      });
      this.cd.detectChanges();
    } catch (error) {
      this.sharedDialogService.showPopupDialog({
        header: 'Something went wrong',
        subHeader: 'You need to manually grant access to your Microphone (Top next to your address bar)',
        buttonTwoText: 'Close',
        buttonTwoCallback: this.sharedDialogService.hidePopupDialog,
      });
    }
  }

  startTimer() {
    let seconds = 1,
      minutes = 0;
    SharedConfig.popupDialogButtonOneText = 'Submit (0:01)';

    const timerInterval = setInterval(() => {
      if (SharedConfig.isRecording === false) {
        clearInterval(timerInterval); // stop if recording is false
        return;
      }
      // increment seconds
      seconds++;

      // check if one minute has passed
      if (seconds > 59) {
        minutes++;
        seconds = 0;
      }

      // add leading zero to seconds and minutes less than 10
      const sec = seconds < 10 ? `0${seconds}` : seconds;
      const min = minutes < 10 ? `0${minutes}` : minutes;

      // update timer
      SharedConfig.popupDialogButtonOneText = `Submit (${min}:${sec})`;
    }, 1000);
  }

  async initializeRecordingAndStartRecording() {
    this.startTimer();
    try {
      await this.initializeAndStartRecordingForQuestionMap();
      // this.showQuestionMapPopupDialog();
      SharedConfig.stopTranscriptionProcessing = false;
      SharedConfig.isRecording = true;
      SharedConfig.popupDialogHeader = 'Listening...';
      delete SharedConfig.popupDialogSubHeader;
      SharedConfig.mediaRecorder.start();
    } catch (error) {
      SharedConfig.isProcessing = false;
      delete SharedConfig.popupDialogImageUrl;
      delete SharedConfig.popupDialogSubHeader;
      delete SharedConfig.popupDialogButtonOneText;
      delete SharedConfig.popupDialogButtonTwoCallBack;
      SharedConfig.popupDialogButtonTwoText = 'Close';
      SharedConfig.popupDialogButtonTwoCallBack = this.sharedDialogService.hidePopupDialog;

      if (error.name === 'NotAllowedError') {
        SharedConfig.popupDialogHeader = 'Not allowed';
        SharedConfig.popupDialogSubHeader =
          'You have declined permission to use your microphone and will have to manually grant access in your browser (Top next to your address bar)';
      } else {
        SharedConfig.popupDialogHeader = 'Something went wrong';
        SharedConfig.popupDialogSubHeader = error.message;
      }
    }
  }

  cancelRecordingAndClosePopup() {
    this.busnRbixService.cancelRecording();

    // REFACTOR -> MEDIA RECORDER IS A SHARED VAIRABEL, NO NEED TO USE LOCAL HERE
    if (SharedConfig.isRecording) {
      SharedConfig.mediaRecorder.stop();
    }

    this.sharedDialogService.hidePopupDialog();
    this.cd.detectChanges();
  }

  async stopRecordingAndSubmitOrSubmitOnly() {
    SharedConfig.isProcessing = true;
    SharedConfig.popupDialogHeader = 'Processing...';
    SharedConfig.popupDialogImageUrl = 'assets/icon/loader/recording_loading.svg';
    delete SharedConfig.popupDialogSubHeader;
    delete SharedConfig.popupDialogButtonOneText;
    SharedConfig.popupDialogButtonTwoText = 'Cancel';
    delete SharedConfig.popupDialogButtonOneCallBack;
    SharedConfig.popupDialogButtonTwoCallBack = this.cancelProcessing;
    if (SharedConfig.isRecording) {
      SharedConfig.isRecording = false;
      this.cd.detectChanges();
      SharedConfig.mediaRecorder.stop();
    } else {
      const rbixTranscription = await this.rbixTranscribeQuestionMapText(SharedConfig.popupDialogTextAreaValue);
      this.executeRbixTranscriptionResult(rbixTranscription);
    }
  }

  cancelProcessing() {
    this.sharedDialogService.hidePopupDialog();
    SharedConfig.stopTranscriptionProcessing = true;
  }

  async initializeAndStartRecordingForQuestionMap() {
    try {
      console.log('getUserMedia supported.');
      console.log(navigator.mediaDevices);
      if (navigator.mediaDevices) {
        console.log('getUserMedia Success');
        let chunks = [];

        let stream: MediaStream;
        console.log('INITIALIZING MEDIA STREAM START');
        stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        console.log('INITIALIZING MEDIA STREAM END');
        SharedConfig.mediaRecorder = new MediaRecorder(stream);
        console.log('INITIALIZED MEDIA STREAM');
        console.log(SharedConfig.mediaRecorder);
        SharedConfig.stopTranscriptionProcessing = false;

        SharedConfig.mediaRecorder.onstop = async (e) => {
          SharedConfig.isRecording = false;
          SharedConfig.isProcessing = true;

          if (!SharedConfig.stopTranscriptionProcessing) {
            console.log('recorder stopped');
            const blob = new Blob(chunks, { type: 'audio/wav; codecs=opus' });
            const rbixTranscription = await this.rbixTranscribeQuestionMapAudio(blob);

            this.executeRbixTranscriptionResult(rbixTranscription);
            // this.playAudio(convertedBase64);
            chunks = [];
            this.cd.detectChanges();
            // const audioURL = URL.createObjectURL(blob);
          } else {
            console.log('Recording cancelled');
            this.sharedDialogService.hidePopupDialog();
          }

          SharedConfig.isProcessing = false;
          SharedConfig.stopTranscriptionProcessing = false;
        };

        SharedConfig.mediaRecorder.ondataavailable = (e) => {
          chunks.push(e.data);
        };

        SharedConfig.allowsRecording = true;
        Storage.set({
          key: 'allowedRecording',
          value: JSON.stringify(true),
        });
      } else {
        console.log('getUserMedia FAILED!');
      }
    } catch (error) {
      console.log('ERROR INITIALIZING MEDIA RECORDER!');
      console.log(error);
    }
  }

  executeRbixTranscriptionResult(rbixTranscription: ReqResponse) {
    if (!SharedConfig.stopTranscriptionProcessing) {
      if (rbixTranscription && rbixTranscription.success) {
        const rbixTranscriptionData: IRbixResponse = rbixTranscription.data;
        console.log('recorder stopped');

        if (rbixTranscriptionData.hasReply) {
          // this.playAudio(rbixTranscriptionData.replyAudio.audioContent);
          this.playAudio(rbixTranscriptionData.replyAudio);
        }

        this.actionRbixResponseCode(rbixTranscriptionData.question_map.code);
        this.sharedDialogService.hidePopupDialog();
      } else {
        // something went wrong or not mapped
        console.log(`Error - ${rbixTranscription.message}`);
        console.log(`Text - ${rbixTranscription.data?.questionText}`);
        this.ngZone.run(() => {
          SharedConfig.popupDialogHeader = 'Hmmm...';
          SharedConfig.popupDialogSubHeader = "We're not quite sure what you meant, could you be more specific?";
          SharedConfig.popupDialogButtonOneText = 'Try again';
          SharedConfig.popupDialogButtonTwoText = 'Close';
          SharedConfig.popupDialogButtonOneCallBack = this.showQuestionMapPopupDialog;
          SharedConfig.popupDialogButtonTwoCallBack = this.sharedDialogService.hidePopupDialog;
          SharedConfig.popupDialogButtonThreeCallBack = this.sharedDialogService.hidePopupDialog;
          delete SharedConfig.popupDialogImageUrl;
        });
      }
    }
  }

  async actionRbixResponseCode(rbixTranscriptionDataResponseCode: RbixResponseCode) {
    switch (rbixTranscriptionDataResponseCode) {
      case 'NAV2CHNGEMAIL':
        this.navCtrl.navigateForward('side-menu-change-email');
        break;

      case 'NAV2FSCA':
        this.navCtrl.navigateForward('side-menu-fsb-licence');
        break;

      case 'NAV2USFLDOCS':
        this.navCtrl.navigateForward('side-menu-useful-documents');
        break;

      case 'OPENINTERCOM':
        this.busnThirdPartyService.intercomDisplayLauncher();
        break;

      case 'NAV2CHANGEPASSWORD':
        this.navCtrl.navigateForward('side-menu-change-password');
        break;

      case 'NAV2DOWNLOADAPP':
        this.navCtrl.navigateForward('download-app');
        break;

      case 'STARTNEWQUOTE':
        this.sharedConfig.setShowCustomLoader(true)
        this.busnQuoteScheduleService.createNewQuoteRequest().then(async (quoteRequest) => {
          this.navCtrl.navigateForward('quote-new');
          // (await this.loading).dismiss();
          this.sharedConfig.setShowCustomLoader(false)
        });
        break;

      case 'ADDNEWVEHICLE':
        if (this.router.url.includes('/quote-new')) {
          const vehicle = await this.busnAssetService.addVehicle();

          // Auto open asset ->
          SharedConfig.selectedAssetVehicle = vehicle;
          this.navCtrl.navigateRoot(['quote-questions'], {
            queryParams: { assetTypeDisplayName: 'Vehicle', asset_type: AssetType.Vehicles as AssetType, quoteType: QuoteType.StandardQuoteRequest as QuoteType },
          });
        } else {
          this.navCtrl.navigateRoot(['quote-new'], {
            queryParams: { action: JSON.stringify({ actionCode: 'QUOTE_NEW_VEHICLE' as PageActionCodeType }) },
          });
        }

        break;

      case 'ADDNEWBUILDING':
        if (this.router.url.includes('/quote-new')) {
          const building = await this.busnAssetService.addBuilding();

          // Auto open asset ->
          SharedConfig.selectedAssetBuilding = building;
          this.navCtrl.navigateRoot(['quote-questions'], {
            queryParams: { assetTypeDisplayName: 'Building', asset_type: AssetType.Buildings, quoteType: QuoteType.StandardQuoteRequest as QuoteType },
          });
        } else {
          this.navCtrl.navigateRoot(['quote-new'], {
            queryParams: { action: JSON.stringify({ actionCode: 'QUOTE_NEW_BUILDING' as PageActionCodeType }) },
          });
        }
        break;

      case 'ADDNEWMOTORCYCLE':
        if (this.router.url.includes('/quote-new')) {
          const motorcycle = await this.busnAssetService.addMotorcycle();

          // Auto open asset ->
          SharedConfig.selectedAssetMotorcycle = motorcycle;
          this.navCtrl.navigateRoot(['quote-questions'], {
            queryParams: { assetTypeDisplayName: 'Motorcycle', asset_type: AssetType.Motorcycles, quoteType: QuoteType.StandardQuoteRequest as QuoteType },
          });
        } else {
          this.navCtrl.navigateRoot(['quote-new'], {
            queryParams: { action: JSON.stringify({ actionCode: 'QUOTE_NEW_MOTORCYCLE' as PageActionCodeType }) },
          });
        }
        break;

      case 'ADDNEWCARAVAN':
        if (this.router.url.includes('/quote-new')) {
          const caravan = await this.busnAssetService.addCaravan();

          // Auto open asset ->
          SharedConfig.selectedAssetCaravan = caravan;
          this.navCtrl.navigateRoot(['quote-questions'], {
            queryParams: { assetTypeDisplayName: 'Caravan/Trailer', asset_type: AssetType.Caravans, quoteType: QuoteType.StandardQuoteRequest as QuoteType },
          });
        } else {
          this.navCtrl.navigateRoot(['quote-new'], {
            queryParams: { action: JSON.stringify({ actionCode: 'QUOTE_NEW_CARAVAN' as PageActionCodeType }) },
          });
        }
        break;

      case 'ADDNEWCONTENT':
        if (this.router.url.includes('/quote-new')) {
          const content = await this.busnAssetService.addContent();

          // Auto open asset ->
          SharedConfig.selectedAssetContent = content;
          this.navCtrl.navigateRoot(['quote-questions'], {
            queryParams: { assetTypeDisplayName: 'Content', asset_type: AssetType.Contents, quoteType: QuoteType.StandardQuoteRequest as QuoteType },
          });
        } else {
          this.navCtrl.navigateRoot(['quote-new'], {
            queryParams: { action: JSON.stringify({ actionCode: 'QUOTE_NEW_CONTENT' as PageActionCodeType }) },
          });
        }
        break;

      case 'ADDNEWALLRISK':
        if (this.router.url.includes('/quote-new')) {
          const specifiedItem = await this.busnAssetService.addSpecifiedItem();
          if (
            this.sharedConfig.quoteRequest.assets &&
            (!this.sharedConfig.quoteRequest.assets.all_risks || this.sharedConfig.quoteRequest.assets.all_risks.length === 0)
          ) {
            await this.busnAssetService.addAllRiskItem();
          }

          SharedConfig.selectedAssetSpecifiedItem = specifiedItem;
          this.navCtrl.navigateRoot(['quote-questions'], {
            queryParams: { assetTypeDisplayName: 'Specified Item', asset_type: AssetType.SpecifiedItems, quoteType: QuoteType.StandardQuoteRequest as QuoteType },
          });
        } else {
          this.navCtrl.navigateRoot(['quote-new'], {
            queryParams: { action: JSON.stringify({ actionCode: 'QUOTE_NEW_ALL_RISK' as PageActionCodeType }) },
          });
        }
        break;

      case 'DELETEALLASSETS':
        if (this.router.url.includes('/quote-new')) {
          const deleteObject = {
            all_risks: [],
            buildings: [],
            caravans: [],
            contents: [],
            motorcycles: [],
            specified_items: [],
            user_id: this.sharedConfig.user.id,
            vehicles: [],
          };

          this.sharedConfig.setShowCustomLoader(true)

          if (this.sharedConfig.quoteRequest.assets?.caravans)
            for (const asset of this.sharedConfig.quoteRequest.assets?.caravans) {
              deleteObject.caravans.push(asset.id);
            }
          if (this.sharedConfig.quoteRequest.assets?.contents)
            for (const asset of this.sharedConfig.quoteRequest.assets?.contents) {
              deleteObject.contents.push(asset.id);
            }
          if (this.sharedConfig.quoteRequest.assets?.motorcycles)
            for (const asset of this.sharedConfig.quoteRequest.assets?.motorcycles) {
              deleteObject.motorcycles.push(asset.id);
            }
          if (this.sharedConfig.quoteRequest.assets?.specified_items)
            for (const asset of this.sharedConfig.quoteRequest.assets?.specified_items) {
              deleteObject.specified_items.push(asset.id);
            }
          if (this.sharedConfig.quoteRequest.assets?.buildings)
            for (const asset of this.sharedConfig.quoteRequest.assets?.buildings) {
              deleteObject.buildings.push(asset.id);
            }
          if (this.sharedConfig.quoteRequest.assets?.vehicles)
            for (const asset of this.sharedConfig.quoteRequest.assets?.vehicles) {
              deleteObject.vehicles.push(asset.id);
            }
          if (this.sharedConfig.quoteRequest.assets?.all_risks)
            for (const asset of this.sharedConfig.quoteRequest.assets?.all_risks) {
              deleteObject.all_risks.push(asset.id);
            }
          await this.busnAssetService.removeAssetsFromQuote(deleteObject, this.sharedConfig.quoteRequest.id);
          this.sharedConfig.setShowCustomLoader(false)
        }
        break;

      default:
        break;
    }
  }

  playAudio(base64Audio: string) {
    SharedConfig.isProcessing = false;
    //Create a new audio element
    //Set the audio source to the base64 string
    // this.audio.src = `data:audio/mpeg;base64,${base64Audio}`;
    this.audio.src = base64Audio;
    //Set the audio type to mp3
    this.audio.setAttribute('type', 'audio/mpeg');
    //Play the audio
    this.audio.play();

    //Add an event listener to log the text "stopped" when the audio has stopped playing
    this.audio.addEventListener('ended', () => {
      SharedConfig.isProcessing = false;
      this.cd.detectChanges();
      console.log('stopped');
    });
  }

  async rbixTranscribeQuestionMapAudio(blob: Blob): Promise<ReqResponse> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('authorization', `Bearer ${this.sharedConfig.getAppCredentials.rbix_token}`);
    // headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    const body = new FormData();
    body.append('file', blob, 'recording.wav');
    body.append('collectionId', 'default');

    // const response: ReqResponse = await this.httpService
    //   .post(`${this.sharedConfig.getAppCredentials.rbix_api_url}/transcribe/question-map/audio`, body, headers)
    //   .catch((exception) => {
    //     console.log('exception');
    //     console.log(exception);
    //     return {
    //       success: false,
    //       message: exception.error.message,
    //     } as ReqResponse;
    //   });
    // return response;
    return null;

  }

  async rbixTranscribeQuestionMapText(requestText: string): Promise<ReqResponse> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('authorization', `Bearer ${this.sharedConfig.getAppCredentials.rbix_token}`);
    // headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    // const response: ReqResponse = await this.httpService
    //   .post(`${this.sharedConfig.getAppCredentials.rbix_api_url}/transcribe/question-map/text`, { message: requestText }, headers)

    //return response;
    return null;
  }

  // TEMPORARY
  // async synthesizeTextToSpeechBase64AsMp3(text: string): Promise<string> {
  //  let headers: HttpHeaders = new HttpHeaders();
  // headers = headers.set('bearer', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJrZXkiOiItTldpODBaajhVaFptUUl1MFlBZCIsIm5hbWUiOnRydWUsImlhdCI6MTY4NTcyOTIzN30.2MiETTvTwIEbJCG7XKroBuhPPbmcGSHv-8CWOHPGx6c');
  // headers = headers.set('Bearer', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJrZXkiOiItTldpODBaajhVaFptUUl1MFlBZCIsIm5hbWUiOnRydWUsImlhdCI6MTY4NTcyOTIzN30.2MiETTvTwIEbJCG7XKroBuhPPbmcGSHv-8CWOHPGx6c');
  //   // headers = headers.set('Content-Type', 'application/json; charset=utf-8');
  //   const response = await this.httpService.post('http://127.0.0.1:5001/rbix-2696d/us-central1/google/texttospeech', {
  //     text: 'hello there',
  //   });

  //   const base64String = response.data.audioContent;
  //   console.log(base64String);
  //   return base64String;
  // }
}
