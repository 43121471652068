<div class="nav">
    <div class="prev">
        <ion-icon (click)="slidePrevious()" [ngClass]="{'disabled': disableLeft}" src="assets/icon/app-icons/arrow-left-black.svg"></ion-icon>
    </div>
    <div class="next">
        <ion-icon (click)="slideNext()" *ngIf="!disableRight" src="assets/icon/app-icons/arrow-right-black.svg"></ion-icon>
        <div (click)="navHomeQuote()" *ngIf="disableRight"><b>Done</b></div>
    </div>
</div>
<ion-slides [options]="slideOpts" (ionSlideWillChange)="slideDidChange()" (ionSlideReachEnd)="sliderAtEnd()">
        <ion-slide>
            <div class="img-container">
                <img src="assets/images/tooltip/OB-Screen-1@2x.png" />
            </div>
        </ion-slide>
        <ion-slide>
            <div class="img-container">
                <img src="assets/images/tooltip/OB-Screen-2@2x.png" />
            </div>
        </ion-slide>
        <ion-slide>
            <div class="img-container">
                <img src="assets/images/tooltip/OB-Screen-3@2x.png" />
            </div>
        </ion-slide>
        <ion-slide>
            <div class="img-container">
                <img src="assets/images/tooltip/OB-Screen-4@2x.png" />
            </div>
        </ion-slide>
</ion-slides>
