import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { IQuote } from '@takectrl-multiapp/svcs-share-config';

@Component({
  selector: 'quote-comparison-top-vs-view',
  templateUrl: './quote-comparison-top-vs-view.component.html',
  styleUrls: ['./quote-comparison-top-vs-view.component.scss']
})
export class QuoteComparisonTopVsView implements OnInit {
  @Input() selectedQuoteToCompareOne: IQuote;
  @Input() selectedQuoteToCompareTwo: IQuote;
  @Output() incrementQuoteOne: EventEmitter<void> = new EventEmitter<void>();
  @Output() incrementQuoteTwo: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }
  
  changeQuoteOne(){
    this.incrementQuoteOne.emit()
  }
  
  changeQuoteTwo(){
    this.incrementQuoteTwo.emit()
  }

}
