import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import {
  ISchedule,
  IPolicyBenefit,
  AssetType,
  CoverType,
  ICancelPolicyAsset,
  IInsuredAsset,
  IDealerships,
  IEmergencyNumber,
  IClaimCategories,
  IClaimNumber,
  IInsurer,
  IT2wof,
} from '@takectrl-multiapp/svcs-share-config';
import { IClaim } from '@takectrl-multiapp/svcs-share-config';
import * as _ from 'lodash';
import { APIHttpService } from './api-http.service';
import { Observable } from 'rxjs';
import { ICtrlResponse } from '../interfaces/api.interface';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class APIPolicyService {
  constructor(private readonly ctrlHttp: APIHttpService, public firebase: AngularFireDatabase, private readonly ctrlApiHttpService: APIHttpService) {}

  addClaim(claim: IClaim): Observable<void> {
    return this.ctrlHttp.post(`/claims/`, claim);
  }

  getEmergencyNumber(): Observable<ICtrlResponse<Array<IEmergencyNumber>>> {
    return this.ctrlHttp.get(`/emergency-numbers/`);
  }

  getDealerships(b2b_client: HttpParams | { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; }): Observable<ICtrlResponse<Array<IDealerships>>> {
    return this.ctrlHttp.get(`/dealership-numbers/`, b2b_client);
  }

  getInsuredAsset(assetType: AssetType, id: number): Observable<ICtrlResponse<Array<IInsuredAsset>>> {
    return this.ctrlHttp.get(`/insured-assets/${assetType}`, { id: id });
  }

  async getInsuredItems<T>(activeSchedules: Array<ISchedule>, insuranceType: AssetType): Promise<Array<T>> {
    let returnResult: Array<T> = [];

    for (const schedule of activeSchedules) {
      const scheduleInsuredAssets = (await this.getInsuredAsset(insuranceType, schedule.id).toPromise()) as Array<T>;
      returnResult = returnResult.concat(scheduleInsuredAssets);
    }

    return returnResult;
  }

  async getPolicyBenefit(asset_type: AssetType, coverType = CoverType.Comprehensive): Promise<IPolicyBenefit> {
    return (await this.firebase.database.ref(`policyDocumentsByType/${coverType}/${asset_type}`).once('value')).val();
  }

  getVapsPolicyBenefit(insurer_id: string, cover_type: CoverType, product_id: any): Observable<ICtrlResponse<IPolicyBenefit>> {
    return this.ctrlHttp.get(`/policy-documents/`, { cover_type, product_id, insurer_id });
  }

  getAllPolicyBenefits(cover_type = CoverType.Comprehensive): Observable<ICtrlResponse<Array<IPolicyBenefit>>> {
    return this.ctrlHttp.get(`/policy-documents/`, { cover_type });
  }

  getT2wof(): Observable<ICtrlResponse<Array<IT2wof>>> {
    return this.ctrlHttp.get(`/t2wof/`, {});
  }

  getInsurers(): Observable<ICtrlResponse<Array<IInsurer>>> {
    return this.ctrlHttp.get(`/insurers/`, {});
  }

  getClaimNumbers(): Observable<ICtrlResponse<Array<IClaimNumber>>> {
    return this.ctrlHttp.get(`/claim-numbers/`, {});
  }

  getClaimCategories(): Observable<ICtrlResponse<Array<IClaimCategories>>> {
    return this.ctrlHttp.get(`/claim-categories/`, {});
  }

  cancelAssetFromPolicy(cancelPolicyAsset: ICancelPolicyAsset): Observable<ICtrlResponse<unknown>> {
    return this.ctrlApiHttpService.post(`/policies/cancel/asset/`, cancelPolicyAsset);
  }
}
