import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { IonContent } from '@ionic/angular';
import { IPrevousClaims, IQuestion, SharedConfig } from '@takectrl-multiapp/svcs-share-config';
@Component({
  selector: 'input-previous-claims',
  templateUrl: './input-previous-claims.component.html',
  styleUrls: ['./input-previous-claims.component.scss'],
})
export class InputPreviousClaimsComponent implements OnInit {
  minDate: number;
  maxDate: string;
  @Input() question: IQuestion;
  @Input() showHeading: boolean;
  @Input() error: string;
  @Input() readOnly: boolean;
  @Input() summary: boolean;
  @Output() answerQuestion: EventEmitter<IQuestion> = new EventEmitter();
  @ViewChild(IonContent, { static: false }) public content: IonContent;

  addingClaim = false;
  buttonText = 'Add a claim';
  claimReason: string;
  selectedClaim: IPrevousClaims = {};
  previousClaims: Array<IPrevousClaims> = [];
  claimReasons: Array<string> = [];
  editClaim: boolean;
  dateFormate: string;
  showRemoveClaimPopup = false;
  constructor(public sharedConfig: SharedConfig, private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    console.log(this.summary);
  }

  async ngAfterViewInit(): Promise<void> {
    let currYear = new Date().getFullYear();
    currYear = currYear - 5;
    this.minDate = currYear;
    const dateNow = new Date();
    const month = dateNow.getMonth() + 1;
    const monthStr = month.toString();
    if (monthStr.length > 1) {
      this.maxDate = `${dateNow.getFullYear()  }-${  month}`;
    } else {
      this.maxDate = `${dateNow.getFullYear()  }-0${  month}`;
    }
    if (this.selectedClaim?.date_full) {
      this.dateFormate = this.selectedClaim.date_full.split('T')[0].substring(0, 7);
    }
    // if (this.question.underwriting_answers[0].object_answer) {
    //   this.answerQuestion.emit(this.question);
    // }

    this.previousClaims = this.question.underwriting_answers[0].object_answer ?? [];
    this.previousClaims = this.question.underwriting_answers[0].object_answer
      ? this.question.underwriting_answers[0].object_answer.filter((x) => x.id !== 0)
      : [];
    if (this.previousClaims[0]?.reason !== "No claims" && this.previousClaims.length > 0) {
      this.buttonText = 'Add another claim';
    }
    this.claimReasons = SharedConfig.claimCategories[this.question.underwriting_answers[0].object_name];
    this.cdr.detectChanges();

    if (this.content) {
      this.content.getScrollElement().then((scrollElement) => {
        // console.log('checking for scroll bar');
        // console.log({ scrollElement });
        // console.log({ scrollHeight: scrollElement.scrollHeight });
        // console.log({ clientHeight: scrollElement.clientHeight });
        SharedConfig.pageHasScrollbar = scrollElement.scrollHeight > scrollElement.clientHeight;
        // console.log({ hasScrollBar: SharedConfig.pageHasScrollbar });
      });
    }
  }

  showCalender() {
    SharedConfig.showCalendarPopup = true;
  }

  pickDate(dateValue: string) {
    // this.selectedClaim.date_full = dateValue.toString() + "-01T12:00:00+02:00";
    if (dateValue === null) {
      this.dateFormate = '';
      this.selectedClaim.date_full = '';
    } else {
      this.dateFormate = dateValue.split('T')[0].substring(0, 7);
      this.selectedClaim.date_full = dateValue;
    }
  }

  createUuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, 
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
  }

  selectReason(reason: string) {
    this.selectedClaim.user_id = this.sharedConfig.user.id;
    this.selectedClaim.reason = reason;
    this.selectedClaim.temp_id = this.createUuid();
  }

  newClaim() {
    //hide / show other controls
    this.selectedClaim = {};
    this.dateFormate = null;
    SharedConfig.hideHeaderWhenAddedClaimType = false;
    SharedConfig.addingClaim = true;
    this.editClaim = false;
  }

  addEditClaim() {
    const index = this.previousClaims.findIndex((x) => (x?.id === this.selectedClaim.id && x?.id !== undefined) || x?.temp_id === this.selectedClaim.temp_id);
    if (index >= 0) {
      this.previousClaims[index] = this.selectedClaim;
    } else {
      this.previousClaims.push(this.selectedClaim);
    }
    this.selectedClaim = {};

    const noClaimsIndex = this.previousClaims.findIndex((x) => x?.reason === 'No claims');
    if (noClaimsIndex >= 0) {
        this.previousClaims.splice(noClaimsIndex, 1);
    }

    this.question.underwriting_answers[0].object_answer = this.previousClaims;
    if (!this.showHeading) {
      this.answerQuestion.emit(this.question);
    }
    if (this.previousClaims[0]?.reason !== "No claims") {
      this.buttonText = 'Add another claim';
    }
    SharedConfig.addingClaim = false;
    this.editClaim = false;
    SharedConfig.hideHeaderWhenAddedClaimType = true;
    this.dateFormate = null;
  }

  selectPreviousClaim(claim: IPrevousClaims) {
    SharedConfig.addingClaim = true;
    this.editClaim = true;
    SharedConfig.hideHeaderWhenAddedClaimType = false;
    this.selectedClaim = claim;
    if (this.selectedClaim?.date_full) {
      this.dateFormate = this.selectedClaim.date_full.split('T')[0].substring(0, 7);
    }
  }

  setDateFormat(date_full) {
    const date = new Date(date_full);
    return date.toLocaleString('default', { month: 'long', year: 'numeric' });
  }

  removeClaim() {
    let indexToRemove = -1;
    if(!this.selectedClaim.id){
      indexToRemove = this.previousClaims.findIndex((x) => x.temp_id === this.selectedClaim.temp_id);
    } else{
      indexToRemove = this.previousClaims.findIndex((x) => x.id === this.selectedClaim.id);
    }
    this.previousClaims.splice(indexToRemove,1)
    this.question.underwriting_answers[0].object_answer = this.previousClaims;

    if (!this.showHeading) {
      this.answerQuestion.emit(this.question);
    }
    this.selectedClaim = {};
    SharedConfig.addingClaim = false;
    this.editClaim = false;
    SharedConfig.hideHeaderWhenAddedClaimType = true;
    this.showRemoveClaimPopup = false;

    if (this.previousClaims.length > 0) {
      this.buttonText = 'Add a claim';
    }
  }

  showRemoveClaim() {
    this.showRemoveClaimPopup = true;
  }

  cancelRemove() {
    this.showRemoveClaimPopup = false;
  }

  nextQuestion() {
    this.question.underwriting_answers[0].object_answer = this.previousClaims;
    this.answerQuestion.emit(this.question);
    SharedConfig.hideHeaderWhenAddedClaimType = false;
  }
}
