import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { LoadingController, AlertController } from '@ionic/angular';
import { BUSNQuestionService } from '@takectrl-multiapp/svcs-core-business';
import {
  AssetType,
  IMotorSaveObject,
  IQuestion,
  IResponseMotorVehicles,
  IVehicleMakeModel,
  InputType,
  SharedConfig,
  UnderwritingAnswer,
} from '@takectrl-multiapp/svcs-share-config';
import * as _ from 'lodash';

interface ISplitBrand {
  popular?: string[];
  others?: string[];
}
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'input-dropdown-motor-selector',
  templateUrl: './input-dropdown-motor-selector.component.html',
  styleUrls: ['./input-dropdown-motor-selector.component.scss'],
})

//TODO : when databse refactor, change vechicle to CAR.
export class InputDropdownMotorSelectorComponent implements OnInit {
  @Input() question: IQuestion;
  @Input() showHeading: boolean;
  @Input() error: string;
  @Input() readOnly: boolean;
  @Output() answerQuestion: EventEmitter<IQuestion> = new EventEmitter();
  @Input() labelPosition: string;
  public yearUnderwriting: UnderwritingAnswer = { object_answer: '' };
  public makeUnderwriting: UnderwritingAnswer = { object_answer: '' };
  public modelUnderwriting: UnderwritingAnswer = { object_answer: '' };
  public modelVariantUnderwriting: UnderwritingAnswer = { object_answer: '' };
  public sumInsuredUnderwriting: UnderwritingAnswer;
  public sumInsuredTypeUnderwriting: UnderwritingAnswer;
  public modelIdUnderwriting: UnderwritingAnswer = { object_answer: '' };
  model_id: UnderwritingAnswer = { object_answer: '' };

  popularVehicleMakes = [
    'AUDI',
    'BMW',
    'CHEVROLET',
    'CITROEN',
    'DATSUN',
    'FORD',
    'HONDA',
    'HYUNDAI',
    'ISUZU',
    'JEEP',
    'KIA',
    'LAND ROVER',
    'MAZDA',
    'MERCEDES-BENZ',
    'NISSAN',
    'OPEL',
    'PEUGEOT',
    'RENAULT',
    'SUZUKI',
    'TOYOTA',
    'VOLKSWAGEN',
    'VOLVO',
  ];

  popularMotorcycleMakes = [
    'APRILIA',
    'BIG BOY',
    'BMW',
    'DUCATI',
    'HARLEY DAVIDSON',
    'HONDA',
    'JONWAY',
    'KAWASAKI',
    'KTM',
    'POLARIS',
    'SUZUKI',
    'TRIUMPH',
    'VESPA',
    'YAMAHA',
  ];

  popularMakes = [];

  listYear = [];
  makes: Array<string>;
  models: Array<IVehicleMakeModel>;
  modelVariants: Array<IResponseMotorVehicles>;

  loader = this.loading.create();
  motor: Array<IMotorSaveObject>;
  popupHeading: string;
  selectArray = [];
  splitBrands: ISplitBrand = { popular: [], others: [] };
  showSelectPopup = false;
  selectAnswer: string;
  constructor(public questionService: BUSNQuestionService, private readonly loading: LoadingController, private readonly alertCtrl: AlertController, private readonly sharedConfig: SharedConfig) {
    const thisYear = new Date().getFullYear();
    this.listYear = Array(30)
      .fill(0)
      // tslint:disable-next-line: radix
      .map((val, i) => parseInt(`${thisYear - i}`));
  }

  async ngOnInit(): Promise<void> {
    this.sharedConfig.setShowCustomLoader(true)

    if (this.question.underwriting_answers[0].object_name === AssetType.Vehicles) {
      this.popularMakes = this.popularVehicleMakes;
    } else {
      this.popularMakes = this.popularMotorcycleMakes;
    }
    this.getMake();

    const yearUnderwriting = _.cloneDeep(
      this.question.underwriting_answers.find((object_name) => object_name.object_field === 'year_of_manufacture')
    );
    const makeUnderwriting = _.cloneDeep(this.question.underwriting_answers.find((object_name) => object_name.object_field === 'make'));
    const modelUnderwriting = _.cloneDeep(this.question.underwriting_answers.find((object_name) => object_name.object_field === 'model_series'));
    const model = _.cloneDeep(this.question.underwriting_answers.find((object_name) => object_name.object_field === 'model'));
    const model_id = _.cloneDeep(this.question.underwriting_answers.find((object_name) => object_name.object_field === 'model_id'));

    if (
      typeof yearUnderwriting.object_answer !== InputType.number ||
      typeof makeUnderwriting.object_answer !== 'string' ||
      typeof modelUnderwriting.object_answer !== 'string' ||
      typeof model.object_answer !== 'string'
    ) {
      delete yearUnderwriting.object_answer;
      delete makeUnderwriting.object_answer;
      delete modelUnderwriting.object_answer;
      delete model.object_answer;
      delete model_id?.object_answer;
    }

    if (
      model &&
      yearUnderwriting &&
      modelUnderwriting &&
      makeUnderwriting &&
      makeUnderwriting.object_answer &&
      (makeUnderwriting.object_answer as string).length > 0
    ) {
      const [makes, models, modelVariants] = await Promise.all([
        this.questionService.getVehicleMake(this.question.underwriting_answers[0].object_name),
        this.questionService.getVehicleModel(
          this.question.underwriting_answers[0].object_name,
          makeUnderwriting.object_answer,
          yearUnderwriting.object_answer
        ),
        this.questionService.getVehicleModelVariant(
          this.question.underwriting_answers[0].object_name,
          makeUnderwriting.object_answer,
          yearUnderwriting.object_answer,
          modelUnderwriting.object_answer
        ),
      ]);

      this.makes = makes;
      this.splitBrands = this.splitBrandListIntoPopularAndOther();
      this.models = models;
      this.modelVariants = modelVariants;
    }

    this.yearUnderwriting = yearUnderwriting;
    this.makeUnderwriting = makeUnderwriting;
    this.modelUnderwriting = modelUnderwriting;
    this.modelVariantUnderwriting = _.cloneDeep(this.question.underwriting_answers.find((object_name) => object_name.object_field === 'model'));
    this.modelIdUnderwriting = _.cloneDeep(this.question.underwriting_answers.find((object_name) => object_name.object_field === 'model_id'));
    this.sumInsuredUnderwriting = {
      object_name: this.question.underwriting_answers[0].object_name,
      object_field: 'sum_insured',
      object_answer: 'Retail value',
      required: true,
    };

    this.sumInsuredTypeUnderwriting = {
      object_name: this.question.underwriting_answers[0].object_name,
      object_field: 'sum_insured_type',
      required: true,
    };

    this.modelIdUnderwriting = {
      object_name: this.question.underwriting_answers[0].object_name,
      object_field: 'model_id',
      object_answer: null,
    };

    if (this.question.underwriting_answers[0].object_name === AssetType.Vehicles) {
      this.modelIdUnderwriting.object_answer = SharedConfig.selectedAssetVehicle.model_id ? SharedConfig.selectedAssetVehicle.model_id : null;
      this.sumInsuredTypeUnderwriting.object_answer = SharedConfig.selectedAssetVehicle.sum_insured_type;
      this.sumInsuredUnderwriting.object_answer = SharedConfig.selectedAssetVehicle.sum_insured
        ? SharedConfig.selectedAssetVehicle.sum_insured
        : 'Retail value';
    } else if (this.question.underwriting_answers[0].object_name === AssetType.Motorcycles) {
      this.modelIdUnderwriting.object_answer = SharedConfig.selectedAssetMotorcycle.model_id ? SharedConfig.selectedAssetMotorcycle.model_id : null;
      this.sumInsuredTypeUnderwriting.object_answer = SharedConfig.selectedAssetMotorcycle.sum_insured_type;
      this.sumInsuredUnderwriting.object_answer = SharedConfig.selectedAssetMotorcycle.sum_insured
        ? SharedConfig.selectedAssetMotorcycle.sum_insured
        : 'Retail value';
    }

    if (SharedConfig.selectedPage !== 'summary' && this.modelIdUnderwriting.object_answer) {
      this.answerQuestion.emit(this.question); //<-- To enable next button
    }
    // (await this.loader).dismiss();
    this.sharedConfig.setShowCustomLoader(false)
  }

  selectMakePopup() {
    this.showSelectPopup = true;
    SharedConfig.showFullScreen = true;
    this.popupHeading = 'Make';
    SharedConfig.addingClaim = true;
  }

  splitBrandListIntoPopularAndOther(): ISplitBrand {
    const originalSorted = [...this.makes].sort(); // copy and sort the originalList
    const popularSorted = [...this.popularMakes].sort(); // copy and sort the popularList

    // initialize the two output arrays
    const popularBrands: string[] = [];
    const otherBrands: string[] = [];

    // iterate the originalSorted array
    originalSorted.forEach((brand) => {
      if (popularSorted.includes(brand)) {
        popularBrands.push(brand);
      } else {
        otherBrands.push(brand);
      }
    });

    return { popular: popularBrands, others: otherBrands };
  }

  showSelect(selectArray, heading) {
    this.showSelectPopup = true;
    SharedConfig.showFullScreen = true;
    this.selectArray = selectArray;
    this.popupHeading = heading;
    SharedConfig.addingClaim = true;
    switch (this.popupHeading) {
      case 'Year':
        this.selectAnswer = this.yearUnderwriting.object_answer;
        break;
      case 'Make':
        this.selectAnswer = this.makeUnderwriting.object_answer;
        break;
      case 'Model':
        this.selectAnswer = this.modelUnderwriting.object_answer;
        break;
      case 'Variant':
        this.selectAnswer = this.modelIdUnderwriting.object_answer;
        break;
      default:
        break;
    }
  }

  async setAnswer(answer) {
    this.selectAnswer = answer;
    switch (this.popupHeading) {
      case 'Year':
        this.onSelectYear(answer);
        this.closeSelectPopup();
        this.selectMakePopup();

        break;
      case 'Make':
        await this.onSelectMake(answer);
        this.closeSelectPopup();
        this.showSelect(this.models, 'Model');

        break;
      case 'Model':
        await this.onSelectModel(answer);
        this.closeSelectPopup();
        if (this.modelVariants?.length > 0) this.showSelect(this.modelVariants, 'Variant');
        break;
      case 'Variant':
        this.onSelectModelVariant(answer);
        this.closeSelectPopup();
        break;
      default:
        break;
    }
  }

  closeSelectPopup() {
    this.showSelectPopup = false;
    SharedConfig.showFullScreen = false;
    SharedConfig.addingClaim = false;
    this.selectArray = [];
    this.popupHeading = '';
  }

  async getMake() {
    // (await this.loader).present();
    this.sharedConfig.setShowCustomLoader(true)
    const asdf = this.question.underwriting_answers[0].object_name;
    const x = await this.questionService.getVehicleMake(this.question.underwriting_answers[0].object_name);
    this.makes = SharedConfig.selectedAssetVehicleMakes = await this.questionService.getVehicleMake(
      this.question.underwriting_answers[0].object_name
    );
    this.splitBrands = this.splitBrandListIntoPopularAndOther();
    if (!this.makes || this.makes.length <= 0) {
      const alertNotFound = await this.alertCtrl.create({
        header: 'Something went wrong',
        message: 'Please try again later',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              this.makes = null;
            },
          },
        ],
        backdropDismiss: false,
      });
      alertNotFound.present();
    }
    // (await this.loader).dismiss();
    this.sharedConfig.setShowCustomLoader(false)
  }

  async getModel(year?: string, make?: string) {
    // let loader = this.loading.create();
    // (await loader).present();
    this.sharedConfig.setShowCustomLoader(true)
    if (year && make) {
      this.models = SharedConfig.selectedAssetVehicleModels = await this.questionService.getVehicleModel(
        this.question.underwriting_answers[0].object_name,
        make,
        year
      );
    }

    var x = this.makeUnderwriting.object_answer;
    var sdf= this.yearUnderwriting.object_answer;
    this.models = SharedConfig.selectedAssetVehicleModels = await this.questionService.getVehicleModel(
      this.question.underwriting_answers[0].object_name,
      this.makeUnderwriting.object_answer,
      this.yearUnderwriting.object_answer
    );
   
    if (this.models.length <= 0) {
      this.closeSelectPopup();
      const alertNotFound = await this.alertCtrl.create({
        header: 'Nothing found',
        message: `No models was found for make ${this.makeUnderwriting.object_answer}`,
        buttons: [
          {
            text: 'OK',
            handler: () => {
              this.models = null;
            },
          },
        ],
        backdropDismiss: false,
      });
      alertNotFound.present();
    }
    // (await loader).dismiss();
    this.sharedConfig.setShowCustomLoader(false)
  }

  async getModelVariant(year?: string, make?: string, model?: string) {
    // let loader = this.loading.create();
    // (await loader).present();
    this.sharedConfig.setShowCustomLoader(true)
    if (year && make && model) {
      this.modelVariants = SharedConfig.selectedAssetVehicleModelVariants = await this.questionService.getVehicleModelVariant(
        this.question.underwriting_answers[0].object_name,
        make,
        year,
        model
      );
    } else {
      this.modelVariants = SharedConfig.selectedAssetVehicleModelVariants = await this.questionService.getVehicleModelVariant(
        this.question.underwriting_answers[0].object_name,
        this.makeUnderwriting.object_answer,
        this.yearUnderwriting.object_answer,
        this.modelUnderwriting.object_answer
      );
    }
    if (this.modelVariants.length <= 0) {
      this.closeSelectPopup();
      const alertNotFound = await this.alertCtrl.create({
        header: 'Nothing found',
        message: `No variants was found for model ${this.modelUnderwriting.object_answer}`,
        buttons: [
          {
            text: 'OK',
            handler: () => {
              this.modelVariants = null;
            },
          },
        ],
        backdropDismiss: false,
      });
      alertNotFound.present();
    }
    this.sharedConfig.setShowCustomLoader(false)
  }

  onSelectYear(year: number) {
    this.yearUnderwriting.object_answer = year;
    if (this.makeUnderwriting.object_answer) {
      this.makeUnderwriting.object_answer = null;
      this.modelUnderwriting.object_answer = null;
      this.modelIdUnderwriting.object_answer = null;
      this.makes = null;
      this.models = null;
      this.modelVariants = null;
    //  this.answerQuestion.emit(null);
    }
  }

  async onSelectMake(make: string) {
    this.makeUnderwriting.object_answer = make;
    if (this.modelUnderwriting.object_answer) {
      this.modelUnderwriting.object_answer = null;
      this.modelIdUnderwriting.object_answer = null;
      this.modelVariantUnderwriting.object_answer = null;
      this.models = null;
      this.modelVariants = null;
     // this.answerQuestion.emit(null);
    }

    await this.getModel();
  }

  async onSelectModel(model: string) {
    this.modelUnderwriting.object_answer = model;
    if (this.modelIdUnderwriting.object_answer) {
      this.modelIdUnderwriting.object_answer = null;
      this.modelVariants = null;
   //   this.answerQuestion.emit(null);
    }
    await this.getModelVariant();
  }

  setModelVariant(modelVariantId: string) {
    const variant = this.modelVariants.find((v) => v.id === modelVariantId);
    const sumInsuredValue = this.getSumInsuredValue(variant);
    this.modelVariantUnderwriting.object_answer = variant.variant;
    this.modelIdUnderwriting.object_answer = variant.id;
    console.log(sumInsuredValue.sum_insured);
    console.log(variant.valueNew);
    this.sumInsuredUnderwriting.object_answer = sumInsuredValue.sum_insured; //(variant.valueRetail === 0) ? variant.valueNew / 100 : variant.valueRetail / 100;
    this.sumInsuredTypeUnderwriting.object_answer = sumInsuredValue.sum_insured_type;
    console.log(this.sumInsuredTypeUnderwriting.object_answer);

    this.question.underwriting_answers = [
      this.yearUnderwriting,
      this.makeUnderwriting,
      this.modelUnderwriting,
      this.modelVariantUnderwriting,
      this.modelIdUnderwriting,
      this.sumInsuredTypeUnderwriting,
      this.sumInsuredUnderwriting,
    ];
    console.log(this.question.underwriting_answers);
  }

  getSumInsuredValue(variant: IResponseMotorVehicles): any {
    if (variant.valueRetail > 0) {
      return {
        sum_insured: variant.valueRetail / 100,
        sum_insured_type: 'Retail value',
      };
    } else {
      return {
        sum_insured: variant.valueNew / 100,
        sum_insured_type: 'As if it was new',
      };
    }
  }

  onSelectModelVariant(modelVariantId: string) {
    this.setModelVariant(modelVariantId);
    this.answerQuestion.emit(this.question);
  }
}
