import { NavController, LoadingController, AlertController } from '@ionic/angular';
import { Component, OnInit, NgZone, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { QuoteRequestStatusType, SharedConfig, SharedDialogService } from '@takectrl-multiapp/svcs-share-config';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { BUSNQuoteScheduleService } from '@takectrl-multiapp/svcs-core-business';
declare const dataLayer: unknown[];

@Component({
  selector: 'quote-request-not-started',
  templateUrl: './quote-request-not-started.component.html',
  styleUrls: ['./quote-request-not-started.component.scss'],
})
export class QuoteRequestNotStartedComponent implements OnInit {
  @Output() navigatePage: EventEmitter<unknown> = new EventEmitter();
  constructor(
    public navCtrl: NavController,
    public zone: NgZone,
    public loadingCtrl: LoadingController,
    public route: Router,
    public alertController: AlertController,
    public sharedConfig: SharedConfig,
    public busnThirdPartyService: BUSNThirdPartyService,
    public busnQuoteScheduleService: BUSNQuoteScheduleService,
  ) {}

  ngOnInit(): void {
    dataLayer.push({
      event: 'page_show',
      screenPath: 'quote-request-not-started',
      screenName: 'quote-request-not-started',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }

  async ngAfterViewInit(): Promise<void> {}

  navPersonalDetails() {
    this.navigatePage.emit('personal-details');
  }

  async navToQuoteNew() {
    if (
      !this.sharedConfig.quoteRequest ||
      this.sharedConfig.quoteRequest.status === QuoteRequestStatusType.Failed ||
      this.sharedConfig.quoteRequest.status === QuoteRequestStatusType.Cancelled
    ) {
      this.sharedConfig.setShowCustomLoader(true)
      this.busnThirdPartyService.logEvent('action_start_new_quote');
      this.busnQuoteScheduleService.createNewQuoteRequest().then(async (quoteRequest) => {
        this.sharedConfig.setShowCustomLoader(false)
        this.navigatePage.emit('quote-new');
      });
    } else {
      this.navigatePage.emit('quote-new');
    }
  }
}
