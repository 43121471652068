import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SharedConfig } from '@takectrl-multiapp/svcs-share-config';

@Component({
  selector: 'schedules-remove-asset-success',
  templateUrl: './schedules-remove-asset-success.component.html',
  styleUrls: ['./schedules-remove-asset-success.component.scss']
})
export class SchedulesRemoveAssetSuccessComponent implements OnInit {
  @Output() navigateClose: EventEmitter<unknown> = new EventEmitter();
  @Output() navigateReferFriend: EventEmitter<unknown> = new EventEmitter();
  showLottie = true;
  constructor(
    public sharedConfig: SharedConfig
  ) {
  }

  ngOnInit(): void {
  }

  goHome() {
    this.navigateClose.emit();
  }

  referafriend() {
    this.navigateReferFriend.emit();
  }

}
