<div class="wrapper limit-width-on-desktop-float">
  <div class="heading-section">
    <h2 class="h2-bold">Great, one last step. Let’s create a password.</h2>
  </div>
  <div class="input-wrapper">
    <ion-item mode="ios">
      <ion-label position="floating">Enter password</ion-label>
      <ion-input (ionChange)="passwordFieldOnChange()" [(ngModel)]="password" type="{{ passwordType }}"></ion-input>
      <ion-icon slot="end" src="assets/icon/input-icons/{{ passwordIcon }}.svg" class="password-icon" (click)="showPassword()"></ion-icon>
    </ion-item>
  </div>
  <div class="button-wrapper">
    <ion-button mode="ios" (click)="setNewPassword()" [disabled]="!enableSetPasswordButton" class="primary-button">Set password</ion-button>
  </div>
</div>
