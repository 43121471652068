import {  IRenewal, SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import { Injectable } from '@angular/core';
import { APIRenewalService } from '@takectrl-multiapp/svcs-ctrl-api';
import { NavController } from '@ionic/angular';
import { firstValueFrom } from 'rxjs';
import { BUSNThirdPartyService } from './busn-third-party.service';
import { ICtrlResponse } from 'libs/svcs-ctrl-api/src/lib/interfaces/api.interface';

@Injectable({
  providedIn: 'root',
})
export class BUSNRenewalService {
  constructor(
    private readonly apiRenewalServices: APIRenewalService,
    public busnThirdPartyService: BUSNThirdPartyService,
    public navCtrl: NavController,
    public sharedConfig: SharedConfig
  ) {}

  async checkForRenewal(): Promise<ICtrlResponse<Array<IRenewal>>> {
    const renewalResponse = (await firstValueFrom(this.apiRenewalServices.checkRenewals()));
    return renewalResponse;
  }


  closeRenewal(): void {
    this.navCtrl.navigateRoot('home/home-wallet');
  }

  contactBroker(): void {
    this.busnThirdPartyService.intercomDisplayLauncher();
  }
}
