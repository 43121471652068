<div class="white-bg">
  <div class="wrapper limit-width-on-desktop-float">
    <div class="bottom-section">
      <h2 class="h2-bold">Nothing to claim?</h2>
      <p>You'll be able to submit your claim here once you become a client. Give us a try and request a quote - it will
        only take a few minutes.</p>
      <quote-status-button></quote-status-button>
    </div>
  </div>
  <div class="img-bottom limit-width-on-desktop-float">
    <img src="assets/images/placeholder/claim.png" alt="" />
  </div>
</div>