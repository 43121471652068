import { Injectable, NgZone } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ServiceWorkerService {
  constructor(public update: SwUpdate, public zone: NgZone, public navCtrl: NavController) {}

  subscribeForUpdates() {
    console.log(`INITIATING SERVICE WORKER UPDATE LISTENER SERVICE`);
    this.update.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
           break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
          this.updateApp();
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });
  }

  updateApp() {
    this.update.activateUpdate();
    this.zone.run(() => {
      this.navCtrl.navigateRoot('public-landing');
    });
    document.location.reload();
  }
}
