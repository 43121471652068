import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SharedConfig, SharedDialogService } from '@takectrl-multiapp/svcs-share-config';
@Component({
  selector: 'popup-dialog',
  templateUrl: './popup-dialog.component.html',
  styleUrls: ['./popup-dialog.component.scss'],
})
export class PopupDialogComponent implements OnInit {
  setBottom: boolean;
  constructor(public sharedConfig: SharedConfig, public sharedDialogConfig: SharedDialogService) {}

  ngOnInit(): void {
    if (SharedConfig.showFooterTabs) {
      this.setBottom = true;
    } else {
      this.setBottom = false;
    }
  }

  closePopup() {
    this.sharedDialogConfig.hidePopupDialog();
  }

  buttonOneAction() {
    SharedConfig.popupDialogButtonOneCallBack();
  }

  buttonTwoAction() {
    SharedConfig.popupDialogButtonTwoCallBack();
  }

  buttonThreeAction() {
    SharedConfig.popupDialogButtonThreeCallBack();
  }

  onTextAreaChange(e: Event) {
    SharedConfig.popupDialogTextAreaValue = e.target['value'];
  }

  nextOnEnter(event) {
    const key = event.keyCode;
    if (key == 13) {
      this.buttonOneAction();
    }
  }
}
