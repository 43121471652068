<div class="loader-backdrop"></div>
<div *ngIf="animationName" class="center-outer">
  <div class="center-inner">
    <div class="center-auto">
      <div class="loader-wrapper">
        <div role="status">
          <lottie-player autoplay mode="normal" src="assets/lottie-animations/{{ animationName }}.json"> </lottie-player>
        </div>
      </div>
      <div class="loader-message">
        <h2 class="h2-bold">{{ displayHeader }}<br />{{ displayText }}</h2>
      </div>
    </div>
  </div>
</div>
