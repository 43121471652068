import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { IFirebaseLogin, InputType, SharedConfig, SharedDialogService, SharedEncryptionService } from '@takectrl-multiapp/svcs-share-config';
import { UIAppInitializationService } from '../../services/ui-app-initialization.service';
import { Storage } from '@capacitor/storage';
import { BUSNQuoteScheduleService, BUSNThirdPartyService, BUSNUserService } from '@takectrl-multiapp/svcs-core-business';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import '@lottiefiles/lottie-player';
declare const dataLayer: any[];
import { ApiAuthService } from '@takectrl-multiapp/svcs-ctrl-api';
import { firstValueFrom } from 'rxjs';
import { ServiceWorkerService } from '@takectrl-multiapp/svcs-core';

@Component({
  selector: 'login-with-email-password',
  templateUrl: './login-with-email-password.component.html',
  styleUrls: ['./login-with-email-password.component.scss'],
})
export class LoginWithEmailPasswordComponent implements OnInit, AfterViewInit {
  public loginForm: FormGroup;
  public passwordType = 'password';
  public passwordIcon = 'view';
  public passwordError = false;
  public emailError = false;
  msisdnError = false;
  public disableButtonCell = true;
  // private password: string = '1234321';
  // public userEmail: string ='1725546456433@fakectrl.com';
  private password: string = '';
  public userEmail: string ='';
  showSmallPopup = false;
  smallPopupHeader: string;
  smallPopupMessage: string;
  public disableButtonPassword = false;
  public disableButtonEmail = false;
  public biometricsLoginSetup: string;
  recaptchaVerifier: any;
  loaderElement: HTMLIonLoadingElement;
  windowRef: any;

  phoneNumber = SharedConfig.routeParamUserMsisdn;
  showOTPScreen = false;

  public hasFocus: string;
  public otpError = false;
  public displayNumber: string;
  public didResend = false;
  private intervalResent: any;
  completeVerificationCode: string;
  public showLoader = false;
  public maxLength = 6;
  @Output() routeUserToCorrectHomePage: EventEmitter<unknown> = new EventEmitter();
  @Output() navForgotPasswordPage: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() navigateSignUpPage: EventEmitter<unknown> = new EventEmitter<unknown>();
  @ViewChild('emailField') emailField: { setFocus: () => void };
  @ViewChild('passwordField') passwordField: { setFocus: () => void };

  constructor(
    public formBuilder: FormBuilder,
    public serviceWorkerService: ServiceWorkerService,
    public apiAuthService: ApiAuthService,
    public userService: BUSNUserService,
    public sharedConfig: SharedConfig,
    public sharedEncryptionService: SharedEncryptionService,
    public alertController: AlertController,
    private readonly sharedDialogService: SharedDialogService,
    public angularFire: AngularFireAuth,
    public uiAppInitializationService: UIAppInitializationService,
    public busnThirdPartyService: BUSNThirdPartyService
  ) {}

  async ngOnInit() {}

  async ngAfterViewInit(): Promise<void> {
    this.busnThirdPartyService.logEvent('page_login');
    if (!SharedConfig.routeParamUserMsisdn && SharedConfig.routeParamUserEmail && !SharedConfig.routeParamUserEmailQuoteOnBehalf) {
      this.setupViaEmail();
    }

    if (SharedConfig.routeParamUserEmail) {
      this.userEmail = SharedConfig.routeParamUserEmail;
      this.disableButtonEmail = false;
    }

    if (SharedConfig.routeParamUserPaswrd) {
      this.password = SharedConfig.routeParamUserPaswrd;
      this.disableButtonPassword = false;
      this.loginWithEmailAndPassword();
    }

    dataLayer.push({
      event: 'page_show',
      screenPath: 'login-with-email-password',
      screenName: 'login-with-email-password',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }

  async requestOTPValidateIfUserExistInDB() {
    this.sharedConfig.setShowCustomLoader(true);
    const appVerifier = this.windowRef.recaptchaVerifier;
    const createSANumberFormate = `+27${this.phoneNumber.substring(1, 10)}`;
    if (SharedConfig.appCredentials.firebase_functions_url && SharedConfig.appCredentials.firebase_functions_url.length > 0) {
      const doesUserExist = await this.userService.checkIfUserMsisdnIsRegistered(createSANumberFormate);
      console.log(doesUserExist);
      if (doesUserExist.success) {
        this.busnThirdPartyService.firebaseSignInWithPhoneNumber(createSANumberFormate, appVerifier).then((result) => {
          this.busnThirdPartyService.logEvent('app_registration_complete');
          this.sharedConfig.setShowCustomLoader(false);
          this.windowRef.confirmationResult = result;
          this.showOTPScreen = true;
        });
      } else {
        // loader.dismiss();
        this.sharedConfig.setShowCustomLoader(false);
        this.sharedDialogService.showPopupDialog({
          header: 'Something went wrong!',
          subHeader: 'This mobile number does not exist.',
          buttonOneText: 'OK',
          buttonOneCallback: this.sharedDialogService.hidePopupDialog,
        });
        this.disableButtonCell = true;
      }
    } else {
      this.sharedDialogService.showPopupDialog({
        header: 'Unable to connect',
        subHeader: 'Functions are not setup yet.',
        buttonOneText: 'OK',
        buttonOneCallback: this.sharedDialogService.hidePopupDialog,
      });
    }
  }

  showResent() {
    let codeResentCounter = 2;
    this.didResend = true;
    this.intervalResent = setInterval(() => {
      codeResentCounter = codeResentCounter - 1;
      if (codeResentCounter === 0) {
        this.didResend = false;
        clearInterval(this.intervalResent);
      }
    }, 1000);
  }

  setupViaEmail() {
    this.navForgotPasswordPage.emit();
    this.userService.resetPassword(SharedConfig.routeParamUserEmail).then(() => {
      this.navForgotPasswordPage.emit();
    });
  }

  dismissSmallPopup() {
    this.showSmallPopup = false;
    SharedConfig.showFullScreen = false;
    this.smallPopupHeader = '';
    this.smallPopupMessage = '';
  }

  closeUpdateAppPage(){
    SharedConfig.showPopupWithDismissButton_updateApp = false
  }

  onEnterEmailField(event: { keyCode: any }) {
    const key = event.keyCode;
    if (key === 13) {
      if (this.userEmail && !this.password) {
        this.passwordField.setFocus();
      } else if (this.userEmail && this.password) {
        this.loginWithEmailAndPassword();
      }
    }
  }

  onEnterPasswordField(event: { keyCode: any }) {
    const key = event.keyCode;
    if (key === 13) {
      if (this.password && !this.userEmail) {
        this.emailField.setFocus();
      } else if (this.password && this.userEmail) {
        this.loginWithEmailAndPassword();
      }
    }
  }

  async loginWithEmailAndPassword() {
    this.sharedConfig.setShowCustomLoader(true);
    this.userEmail = this.userEmail.toLocaleLowerCase();
    if (!this.emailError && !this.passwordError) {
      let user_credential: IFirebaseLogin;
      user_credential = await this.busnThirdPartyService.firebaseSignInWithEmailAndPassword(this.userEmail, this.password);
      if (user_credential.success) {
        this.busnThirdPartyService.logEvent('app_login_complete');

        try {
          const authResult = await firstValueFrom(this.apiAuthService.authAuthenticate(this.userEmail, this.password));
          if (authResult) {
            SharedConfig.swipeGesture = true;

            await this.uiAppInitializationService.hotReloadCore();
         
            this.sharedConfig.setShowCustomLoader(false);
            this.routeUserToCorrectHomePage.emit();
          } else {
            // Handle authentication failure
            // TODO, THIS NEEDS TO BE REFACTORED
            this.sharedConfig.setShowCustomLoader(false);
            this.sharedDialogService.showPopupDialog({
              header: 'Authentication failed',
              subHeader: 'We were unable to authenticate your account. Please try again.',
              buttonOneText: 'OK',
              buttonOneCallback: this.sharedDialogService.hidePopupDialog,
            });
          }
        } catch (exception) {
          console.error(exception);
          // Handle authentication error
          this.sharedConfig.setShowCustomLoader(false);
          this.sharedDialogService.showPopupDialog({
            header: 'Authentication error',
            subHeader: 'An error occurred during authentication. Please try again.',
            buttonOneText: 'OK',
            buttonOneCallback: this.sharedDialogService.hidePopupDialog,
          });
        }
      } else {
        this.sharedConfig.setShowCustomLoader(false);
        this.sharedDialogService.showPopupDialog({
          header: 'Login failed',
          subHeader:
            'We were unable to find your account. Please check the spelling of your email address and password, or create a new account by registering.',
          buttonOneText: 'OK',
          buttonOneCallback: this.sharedDialogService.hidePopupDialog,
        });
      }
    }
    this.sharedConfig.setShowCustomLoader(false);
    delete SharedConfig.showSetNewPasswordButtonOnLogin;
  }

  forgotPassword() {
    this.navForgotPasswordPage.emit();
  }

  async signUp() {
    this.navigateSignUpPage.emit();
  }

  async clearLocalStorage() {
    await Storage.remove({ key: 'userEmail' });
  }

  validatePassword(e: Event) {
    const password: string = e.target['value'];
    if (password.length < 6) {
      this.passwordError = true;
    } else {
      this.password = password;
      this.passwordError = false;
      this.disableButtonPassword = false;
    }
  }

  validateEmail(e: Event) {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailInput: string = e.target['value'];
    if (!emailRegex.test(emailInput)) {
      this.emailError = true;
    } else {
      this.userEmail = emailInput;
      this.emailError = false;
      this.disableButtonEmail = false;
    }
  }

  validateMsisdn(e: Event) {
    const numberInput = e.target['value'];
    const numberPattern = numberInput.match(/^[0-9]+$/);
    console.log(numberInput.length);
    if (numberInput.length !== 10 || numberPattern === null) {
      this.msisdnError = true;
      this.disableButtonCell = true;
    } else {
      this.phoneNumber = numberInput;
      this.msisdnError = false;
      this.disableButtonCell = false;
    }
  }

  showPassword() {
    this.passwordType = this.passwordType === InputType.text ? 'password' : InputType.text;
    this.passwordIcon = this.passwordIcon === 'view-close' ? 'view' : 'view-close';
  }
}
