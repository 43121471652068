<div class="wrapper bottom-padding limit-width-on-desktop-float">
  <div>
    <div class="heading">
      <h2 class="h2-bold">Welcome {{ sharedConfig?.user?.personal_details?.name }}!</h2>
      <p>Please enter the email address of the user's profile you would like to access.</p>
    </div>

    <div class="input-wrapper">
      <ion-item mode="ios">
        <ion-label position="floating">User Email</ion-label>
        <ion-input class="bold-text" [(ngModel)]="email"></ion-input>
      </ion-item>
    </div>

    <div class="button-row">
      <ion-button *ngIf="quoteType !== QuoteType.ViewAssetOnly" mode="ios" class="primary-button" (click)="searchUser()">Find user </ion-button>
      <ion-button mode="ios" class="secondary-button" *ngIf="quoteType !== QuoteType.MyProfile" (click)="cancel()">Cancel</ion-button>
    </div>
  </div>
</div>

<custom-popup *ngIf="user">
  <div class="popup-standard">
    <div class="heading">
      <h2 class="h2-bold">User Found!</h2>
    </div>
    <div class="user-found">
      <p>{{ user.personal_details.name }} {{ user.personal_details.surname }}</p>
    </div>
    <div class="buttons-wrapper">
      <ion-button *ngIf="quoteType !== QuoteType.ViewAssetOnly" mode="ios" class="primary-button" (click)="quoteOnBehalf()"
        >Access this user’s profile
      </ion-button>
      <ion-button mode="ios" class="secondary-button" (click)="closeSearch()">Close</ion-button>
    </div>
  </div>
</custom-popup>

<popup-with-dismiss-button *ngIf="sharedConfig.showPopupWithDismissButton"></popup-with-dismiss-button>

<!-- TODO ALL OTHER POPUPS SHOULD BE REPLACED BY THIS GENERIC POPUP -->
<popup-dialog id="quote.on.behalf.component" *ngIf="sharedConfig.popupDialogShow"></popup-dialog>
