import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeSwitcherService } from './services/theme-switcher.service';
import { SvcsFirebaseModule } from '@takectrl-multiapp/svcs-firebase';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/compat/analytics';

@NgModule({
  imports: [CommonModule, SvcsFirebaseModule, AngularFireAnalyticsModule],
  providers: [ThemeSwitcherService, ScreenTrackingService, UserTrackingService],
})
export class SvcsCoreModule {}

// export class SvcsCoreModule {

//   static forRoot(environmentVariables: IEnvironmentVariables): ModuleWithProviders {
//     return {
//       ngModule: SvcsCoreModule,
//       providers: [
//         MixpanelService,
//         {
//           provide: _MixPanelService,
//           useValue: environmentVariables
//         },
//         IntercomService,
//         {
//           provide: _IntercomService,
//           useValue: environmentVariables
//         }
//       ]
//     }
//   }
// }
