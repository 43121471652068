import { Injectable } from '@angular/core';
import { SharedConfig } from '../svcs-share-config.module';
import { SharedDialogService } from './shared-dialog.service';

@Injectable({
  providedIn: 'root',
})
export class SharedLoggingService {
  constructor(public sharedDialogService: SharedDialogService, public sharedConfig: SharedConfig) {}

  showDebugUserAlertDialog(message: string) {
    if (this.sharedConfig.appSettings?.debug_user === this.sharedConfig.firebaseUserEmail) {
      this.sharedDialogService.showPopupDialog({
        header: 'DEBUG',
        subHeader: message,
        buttonOneText: 'OK',
        buttonOneCallback: this.sharedDialogService.hidePopupDialog,
      });
    }
  }
}
