import { BUSNQuoteScheduleService, BUSNUserService } from '@takectrl-multiapp/svcs-core-business';
import {
  SharedDialogService,
  SharedConfig,
  IQuote,
  IInsurers,
  QuoteRequestType,
  QuoteRequestStatusType,
  IQuoteRequest,
} from '@takectrl-multiapp/svcs-share-config';
import { Component, OnInit, NgZone, Output, EventEmitter, OnDestroy } from '@angular/core';
import { LoadingController, AlertController, NavController } from '@ionic/angular';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { ServiceWorkerService } from '@takectrl-multiapp/svcs-core';
import { Subscription } from 'rxjs';
import { SharedConfigService } from 'libs/svcs-share-config/src/lib/services/shared-config.service';
import { PageType } from 'libs/svcs-share-config/src/lib/types/page.type';
declare const dataLayer: unknown[];

@Component({
  selector: 'quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss'],
})
export class QuoteComponent implements OnInit, OnDestroy {
  @Output() navigateQuoteSummary: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() navigateQuoteNew: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() hideBackButton: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() navigateQuoteInfo: EventEmitter<unknown> = new EventEmitter();
  @Output() navigatePersonalDetails: EventEmitter<unknown> = new EventEmitter();
  @Output() navigateForwardToPage: EventEmitter<unknown> = new EventEmitter();
  @Output() navigateRootToToPage: EventEmitter<unknown> = new EventEmitter();

  loading = this.loadingCtrl.create();
  public up_to_number_of_quotes = this.sharedConfig.appSettings?.up_to_number_of_quotes;
  popupRestartQuote = false;
  showPopup = false;
  popupHeader: string;
  popupMessage: string;
  showQuotesTab = false;
  popupDidNotQuote = false;
  allInsurers: Array<IInsurers> = [];
  failedPopup = true;
  QuoteRequestType = QuoteRequestType;
  QuoteRequestStatusType = QuoteRequestStatusType;
  private quoteRequestSubscription: Subscription;
  quoteRequest: IQuoteRequest;

  constructor(
    public userService: BUSNUserService,
    public zone: NgZone,
    public loadingCtrl: LoadingController,
    public alertController: AlertController,
    private readonly serviceWorkerService: ServiceWorkerService,
    public navCtrl: NavController,
    public sharedConfig: SharedConfig,
    public busnQuoteScheduleService: BUSNQuoteScheduleService,
    public sharedDialogService: SharedDialogService,
    public sharedConfigService: SharedConfigService,
    private readonly busnThirdPartyService: BUSNThirdPartyService
  ) {}

  async ngOnInit() {
    dataLayer.push({
      event: 'page_show',
      screenPath: 'quote',
      screenName: 'quote',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
    if (this.sharedConfig.schedulesActive && this.sharedConfig.schedulesActive.length > 0) {
      SharedConfig.showFooterTabs = true;
    }

    SharedConfig.selectedPage = PageType.homeQuote;
    // TODO implement this variable rather than shared conf
    this.quoteRequestSubscription = this.sharedConfigService.quoteRequest$.subscribe((quoteRequest) => {
      this.quoteRequest = quoteRequest;
    });

    this.busnQuoteScheduleService.getInsurers().then((insurers) => {
      this.allInsurers = insurers.filter((x) => x.active);

      this.allInsurers.map((x) => {
        switch (x.insurer_id) {
          case 'ABSAPLUS':
            x.img_source = 'assets/images/insurer-logos/left-color-logos/absaplus-colour.svg';
            break;
          case 'ABSAPREMIUM':
            x.img_source = 'assets/images/insurer-logos/left-color-logos/absapremium-colour.svg';
            break;

          case 'BROLINK10':
            x.img_source = 'assets/images/insurer-logos/left-color-logos/brolink10-colour.svg';
            break;

          case 'BRYTE':
            x.img_source = 'assets/images/insurer-logos/left-color-logos/bryte-colour.svg';
            break;

          case 'SANTAM':
            x.img_source = 'assets/images/insurer-logos/left-color-logos/santam-colour.svg';
            break;
          case 'KINGPRICE':
            x.img_source = 'assets/images/insurer-logos/left-color-logos/kingprice-colour.svg';
            break;
          case 'MIWAY':
            x.img_source = 'assets/images/insurer-logos/left-color-logos/miway-colour.svg';
            break;

          case 'MUTUAL&FEDERAL':
            x.img_source = 'assets/images/insurer-logos/left-color-logos/mutual&federal-colour.svg';
            break;

          case 'PEERLESS':
            x.img_source = 'assets/images/insurer-logos/left-color-logos/peerless-colour.svg';
            break;
          default:
            break;
        }
      });
    });
    this.startNewQuote = this.startNewQuote.bind(this);
    this.cancel = this.cancel.bind(this);
    this.showIntercom = this.showIntercom.bind(this);
  }

  ngOnDestroy(): void {
    if (this.quoteRequestSubscription) {
      this.quoteRequestSubscription.unsubscribe();
    }
  }

  navFromRefPage(page: string) {
    if (page === 'personal-details') {
      this.navigatePersonalDetails.emit();
    } else {
      this.navigateRootToToPage.emit(page);
    }
  }

  async fetchingQuotesStillInProgressMessage() {
    this.sharedDialogService.showPopupDialog({
      header: 'Fetching Quotes',
      subHeader: "Please hang tight, we're almost done fetching your quotes",
      buttonOneText: 'OK',
      buttonOneCallback: this.sharedDialogService.hidePopupDialog,
    });
  }

  showQuoteInfo() {
    this.navigateQuoteInfo.emit();
  }

  async navToMyQuote() {
    this.navigateQuoteNew.emit();
  }

  getInsurerThatReturnedQuotesList(): IInsurers[] {
    const quotes = this.sharedConfig.quoteRequest.quotes;
    const insurers = SharedConfig.insurers;
    const returnInsurers: IInsurers[] = [];
    for (const insurer of this.allInsurers) {
      if (insurers.find((x) => x.id === insurer.id)) {
        if (quotes && quotes.find((x) => x.insurer_id === insurer.insurer_id)) {
          returnInsurers.push(insurer);
        }
      }
    }
    return returnInsurers;
  }

  getInsurerThatHasNotReturnedQuotesList(quotes: IQuote[]): IInsurers[] {
    const returnInsurers: IInsurers[] = [];
    for (const insurer of this.allInsurers) {
      if (SharedConfig.insurers.find((x) => x.id === insurer.id)) {
        if (!quotes || !quotes.find((x) => x.insurer_id === insurer.insurer_id)) {
          returnInsurers.push(insurer);
        }
      }
    }
    return returnInsurers;
  }

  chatToUs() {
    this.busnThirdPartyService.intercomDisplayLauncher();
    this.failedPopup = false;
  }

  closeQuoteFailedMessage() {
    this.failedPopup = false;
  }

  backToWalletPage() {
    this.navigateRootToToPage.emit('home/home-wallet');
  }

  async startNewQuote() {
    this.sharedConfig.setShowCustomLoader(true);
    this.sharedDialogService.hidePopupDialog();
    SharedConfig.hideHeader = false;
    this.busnThirdPartyService.logEvent('action_start_new_quote');
    try {
      this.busnQuoteScheduleService.createNewQuoteRequest().then(async (quoteReq) => {
        this.navigateQuoteNew.emit();
        this.sharedConfig.setShowCustomLoader(false);
      });
    } catch (error) {
      this.sharedDialogService.showPopupDialog({
        header: 'Something went wrong!',
        subHeader: error.message,
        buttonOneText: 'OK',
        buttonOneCallback: this.sharedDialogService.hidePopupDialog,
      });
      this.sharedConfig.setShowCustomLoader(false);
    }
  }

  async startNewQuoteVerifyDialog() {
    SharedConfig.showFooterTabs = false;
    SharedConfig.hideHeader = true;
    this.sharedDialogService.showPopupDialog({
      header: 'Are you sure you want to restart your quote?',
      subHeader:
        'By restarting your quote, you will lose the quotes you have received. However, we’ve saved the data you’ve entered already, so you won’t have to answer the same questions again.',
      buttonOneText: 'Restart my quote',
      buttonOneCallback: this.startNewQuote,
      buttonTwoText: 'Close',
      buttonTwoCallback: this.cancel,
    });
  }

  async cancelQuoteRequest() {
    this.sharedConfig.setShowCustomLoader(true);
    await this.busnQuoteScheduleService.cancelQuoteRequest();
    this.sharedConfig.setShowCustomLoader(false);
  }

  openViewNewQuotesPage() {
    if (this.sharedConfig.quoteRequest.status === QuoteRequestStatusType.WaitingToAccept) {
      this.busnThirdPartyService.logEvent('action_quote_reviewed_waiting_to_accept');
    } else if (this.sharedConfig.quoteRequest.status === QuoteRequestStatusType.WaitingOnQuotes) {
      this.busnThirdPartyService.logEvent('action_quote_reviewed_waiting_on_quotes');
    }
    this.navigateQuoteSummary.emit();
  }

  cancel = () => {
    this.sharedDialogService.hidePopupDialog();
    SharedConfig.hideHeader = false;
    if (this.sharedConfig.schedulesActive.length > 0) {
      SharedConfig.showFooterTabs = true;
    }
  };

  showPopupDidNotQuote() {
    this.sharedDialogService.showPopupDialog({
      header: 'Some insurers did not quote',
      subHeader:
        'Some of the insurers were unable to provide a quote. Please chat to us if you would like to find out more, or continue if you are happy with the quotes you have received.',
      buttonOneText: 'Ok got it',
      buttonOneCallback: this.cancel,
      buttonTwoText: 'Contact us',
      buttonTwoCallback: this.showIntercom,
    });
  }

  showIntercom = () => {
    this.busnThirdPartyService.intercomDisplayLauncher();
    this.sharedDialogService.hidePopupDialog();
  };

  showHideDidNotQuotes() {
    if (this.showQuotesTab === false) {
      this.showQuotesTab = true;
    } else {
      this.showQuotesTab = false;
    }
  }
}
