import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { SharedConfig, QuoteRequestType } from '@takectrl-multiapp/svcs-share-config';
import { PageType } from 'libs/svcs-share-config/src/lib/types/page.type';
declare const dataLayer: any[];

@Component({
  selector: 'quote-endorsement-accept',
  templateUrl: './quote-endorsement-accept.component.html',
  styleUrls: ['./quote-endorsement-accept.component.scss'],
})
export class QuoteEndorsementAcceptComponent implements OnInit {
  @Output() navigateWalletAssetDetailsPage: EventEmitter<unknown> = new EventEmitter();
  @Output() navigateWalletPage: EventEmitter<unknown> = new EventEmitter();
  QuoteRequestType = QuoteRequestType;
  constructor(public sharedConfig: SharedConfig) {}
  ngOnInit(): void {
    SharedConfig.selectedPage = PageType.quoteEndorsementAccept;
    dataLayer.push({
      event: 'page_show',
      screenPath: 'quote-endorsement-accept',
      screenName: 'quote-endorsement-accept',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }

  navigateToWalletAssetDetailsPage(asset: any) {
    this.navigateWalletAssetDetailsPage.emit(asset);
  }

  navigateToWalletPage() {
    this.navigateWalletPage.emit();
  }
}
