export * from './lib/ui-pages.module';
// export * from './lib/pages/landing/landing.component'
export * from './lib/services/ui-auth.service';

export * from './lib/pages/admin-panel/admin-panel.component';
export * from './lib/pages/admin-panel-quote-on-behalf/admin-panel-quote-on-behalf.component';
export * from './lib/pages/change-email/change-email.component';
export * from './lib/pages/change-password/change-password.component';
export * from './lib/pages/claim/claim.component';
export * from './lib/pages/ctrl-center/ctrl-center.component';
export * from './lib/pages/delete-account-info/delete-account-info.component';
export * from './lib/pages/download-app/download-app.component';
export * from './lib/pages/emergency/emergency.component';
export * from './lib/pages/forgot-password/forgot-password.component';
export * from './lib/pages/fsb-licence/fsb-licence.component';
export * from './lib/pages/home/home.component';
export * from './lib/pages/landing/landing.component';
export * from './lib/pages/login/login.component';
export * from './lib/pages/motor-group-details/motor-group-details.component';
export * from './lib/pages/needs-info/needs-info.component';
export * from './lib/pages/needs-results/needs-results.component';
export * from './lib/pages/needs-sliders/needs-sliders.component';
export * from './lib/pages/question-personal-details/question-personal-details.component';
export * from './lib/pages/question-quote/question-quote.component';
export * from './lib/pages/question-summary/question-summary.component';
export * from './lib/pages/quote/quote.component';
export * from './lib/pages/quote-accept/quote-accept.component';
export * from './lib/pages/quote-added-benefits/quote-added-benefits.component';
export * from './lib/pages/quote-banking-details/quote-banking-details.component';
export * from './lib/pages/quote-banking-details-casa/quote-banking-details-casa.component';
export * from './lib/pages/quote-claims-history-check/quote-claims-history-check.component';
export * from './lib/pages/quote-comparison-details/quote-comparison-details.component';
export * from './lib/pages/quote-comparison-overall/quote-comparison-overall.component';
export * from './lib/pages/quote-complete/quote-complete.component';
export * from './lib/pages/quote-endorsement-accept/quote-endorsement-accept.component';
export * from './lib/pages/quote-fetching/quote-fetching.component';
export * from './lib/pages/quote-info/quote-info.component';
export * from './lib/pages/quote-list-retrieved-summary/quote-list-retrieved-summary.component';
export * from './lib/pages/quote-new/quote-new.component';
export * from './lib/pages/quote-questions-object-mapping/quote-questions-object-mapping.component';
export * from './lib/pages/quote-summary-not-editable/quote-summary-not-editable.component';
export * from './lib/pages/referral/referral.component';
export * from './lib/pages/renewal/renewal.component';
export * from './lib/pages/renewal-details/renewal-details.component';
export * from './lib/pages/schedules-remove-asset/schedules-remove-asset.component';
export * from './lib/pages/sign-up/sign-up.component';
export * from './lib/pages/terms-conditions/terms-conditions.component';
export * from './lib/pages/update/update.component';
export * from './lib/pages/useful-documents/useful-documents.component';
export * from './lib/pages/wallet/wallet.component';
export * from './lib/pages/wallet-asset/wallet-asset.component';
export * from './lib/pages/wallet-asset-detail/wallet-asset-detail.component';