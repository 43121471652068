import { Injectable } from '@angular/core';
import {
  IClaimMailPayload,
  IReferralMailPayload,
  IMandateViolationMailPayload,
  IHolidayViolationMailPayload,
  IEmergencyMailPayload,
} from '@takectrl-multiapp/svcs-share-config';
import { APIHttpService } from './api-http.service';
import { Observable } from 'rxjs';
import { ICtrlResponse } from '../interfaces/api.interface';

@Injectable({
  providedIn: 'root',
})
export class APIMailService {
  constructor(private readonly ctrlHttp: APIHttpService) {}

  newClaimEmail(payload: IClaimMailPayload): Observable<ICtrlResponse<unknown>> {
    return this.ctrlHttp.post(`/mails/claims/`, payload);
  }

  sendBrokerEmergencyEmail(payload: IEmergencyMailPayload): Observable<ICtrlResponse<unknown>> {
    return this.ctrlHttp.post(`/mails/emergency-assistance/`, payload);
  }

  mandateViolationEmail(payload: IMandateViolationMailPayload): Observable<ICtrlResponse<unknown>> {
    return this.ctrlHttp.post(`/mails/mandate-violation/`, payload);
  }

  holidayViolationEmail(payload: IHolidayViolationMailPayload): Observable<ICtrlResponse<unknown>> {
    return this.ctrlHttp.post(`/mails/holiday-period-violation/`, payload);
  }

  newReferralEmail(payload: IReferralMailPayload): Observable<ICtrlResponse<unknown>> {
    return this.ctrlHttp.post(`/mails/referral/`, payload);
  }
}
