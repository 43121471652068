import { Injectable } from '@angular/core';
import { APIAssetService, APIMetaService, APIQuestionService, APIQuoteScheduleService, APIUserService } from '@takectrl-multiapp/svcs-ctrl-api';
import {
  AssetType,
  IAddresses,
  IOccupations,
  IPostalCodeAreas,
  IQuestion,
  IUser,
  QuestionFilteringType,
  QuoteType,
  SharedConfig,
  UnderwritingAnswer,
} from '@takectrl-multiapp/svcs-share-config';
import { BUSNQuoteScheduleService } from './busn-quote-schedule.service';
import { firstValueFrom } from 'rxjs';
import { SharedConfigService } from 'libs/svcs-share-config/src/lib/services/shared-config.service';
type fromPage = 'question_comp' | 'quote_claims_history_check' | 'quote_summary_not_editable';
@Injectable({
  providedIn: 'root',
})
export class BUSNQuestionService {
  errorDescription: string;
  constructor(
    public apiQuestionService: APIQuestionService,
    public apiMetaService: APIMetaService,
    public apiUserService: APIUserService,
    public apiAssetService: APIAssetService,
    public apiQuoteScheduleService: APIQuoteScheduleService,
    public sharedConfig: SharedConfig,
    public sharedConfigService: SharedConfigService,
    public busnQuoteScheduleService: BUSNQuoteScheduleService
  ) {}

  async getUnderwritingQuestions(refetch = false): Promise<Array<IQuestion>> {
    if (!SharedConfig.underwritingQuestionsAll || refetch) {
      const underwritingQuestions = await this.apiQuestionService.getUnderwritingQuestions();
      const filtered = underwritingQuestions.filter((x) => !x.underwriting_answers[0].hidden);
      return filtered;
    } else {
      return SharedConfig.underwritingQuestionsAll;
    }
  }
  isAllQuestionsAndUnderwritingQuestionsAnswered(selectedAssetUnderwritingQuestions: IQuestion[]): boolean {
    for (const question of selectedAssetUnderwritingQuestions) {
      const x = this.isAllUnderwritingQuestionsAnswered(question);
      if (!x) {
        return false;
      }
    }
    return true;
  }

  isAllUnderwritingQuestionsAnswered(question: IQuestion): boolean {
    if (question && question.underwriting_answers) {
      for (const underwriting_answer of question.underwriting_answers) {
        if (
          underwriting_answer.object_answer === undefined ||
          underwriting_answer.object_answer === null ||
          (underwriting_answer.object_answer_error !== null && underwriting_answer.object_answer_error?.length > 0)
        ) {
          return false;
        }
      }
      return true;
    }
  }

  sanitizeQuestionsComparingToDBSource(user: IUser) {
    const allUnderwritingQuestionsAsFetchedFromDB = this.getPersonalOrAssetSpecificQuestions(
      user,
      JSON.parse(JSON.stringify(SharedConfig.underwritingQuestionsAll)),
      SharedConfig.selectedAssetUnderwritingQuestions[0].underwriting_answers[0].object_name,
      QuestionFilteringType.hideHiddenQuestionsForSummary,
      true
    );

    for (let i = 0; i < allUnderwritingQuestionsAsFetchedFromDB.length; i++) {
      const currentUnderwritingQuestion = SharedConfig.selectedAssetUnderwritingQuestions.find(
        (x) => x.underwriting_answers[0].object_field === allUnderwritingQuestionsAsFetchedFromDB[i].underwriting_answers[0].object_field
      );
      if (currentUnderwritingQuestion) {
        allUnderwritingQuestionsAsFetchedFromDB[i] = currentUnderwritingQuestion;
      }
    }

    SharedConfig.selectedAssetUnderwritingQuestions = allUnderwritingQuestionsAsFetchedFromDB;
  }

  async setAddress(userAddress: IAddresses): Promise<IAddresses> {
    return (await firstValueFrom(this.apiQuestionService.setAddress(userAddress))).results;
  }

  async getVehicleMake(vehicle_type: AssetType): Promise<Array<string>> {
    switch (vehicle_type) {
      case AssetType.Vehicles:
        // TODO BACKEND SHOULD UPDATE THIS
        // return (await firstValueFrom(this.apiQuestionService.getCarMake())).results;
        const motorVehicleMakes = (await firstValueFrom(this.apiQuestionService.getCarMake()))['motorVehicleMakes'];
        return motorVehicleMakes;

      case AssetType.Motorcycles:
        const motorCycleMakes = (await firstValueFrom(this.apiQuestionService.getMotorcycleMake()))['motorVehicleMakes'];
        return motorCycleMakes;
      default:
        return [];
    }
  }

  async getVehicleModel(vehicle_type: AssetType, make: string, year: string): Promise<Array<any>> {
    switch (vehicle_type) {
      case AssetType.Vehicles:
        const motorVehicleModels = (await firstValueFrom(this.apiQuestionService.getCarModel(make, year)))['motorVehicleModels'];
        return motorVehicleModels;

      case AssetType.Motorcycles:
        const motorCycleModels = (await firstValueFrom(this.apiQuestionService.getMotorcycleModel(make, year)))['motorVehicleModels'];
        return motorCycleModels;

      default:
        return [];
    }
  }

  async getVehicleModelVariant(vehicle_type: AssetType, make: string, year: string, model: string): Promise<any> {
    switch (vehicle_type) {
      case AssetType.Vehicles:
        const motorVehicleVariants = (await firstValueFrom(this.apiQuestionService.getCarModelVariant(make, year, model)))['motorVehicles'];
        return motorVehicleVariants;

      case AssetType.Motorcycles:
        const motorCycleVariants = (await firstValueFrom(this.apiQuestionService.getMotorcycleModelVariant(make, year, model)))['motorVehicles'];
        return motorCycleVariants;

      default:
        return [];
    }
  }

  async getAddressFromString(postalCode: string): Promise<IPostalCodeAreas> {
    return (await firstValueFrom(this.apiQuestionService.getAddressFromString(postalCode))).results;
  }

  async searchCityWithPostal(postalCode: string): Promise<IPostalCodeAreas> {
    return (await firstValueFrom(this.apiQuestionService.getPostalCodeAreas(postalCode))).results;
  }
  async searchOccupation(searchText: string): Promise<IOccupations> {
    return (await firstValueFrom(this.apiMetaService.searchOccupation(searchText))).results;
  }

  getPersonCheckParentIndex(key): number {
    const index: number = SharedConfig.selectedAssetUnderwritingQuestions.findIndex(
      (x) => x.personChecker && x.personChecker.key === key && x.personChecker.type === 'Parent'
    );
    return index;
  }

  async autoPopulatePersonCheckerQuestions(question: IQuestion): Promise<UnderwritingAnswer[]> {
    const fields: Array<string> = question.personChecker?.source?.split('.') ?? [];
    if (fields.length === 2) {
      if (this.sharedConfig.user[fields[0]] && this.sharedConfig.user[fields[0]][fields[1]] !== undefined) {
        const parentQuestionIndex = this.getPersonCheckParentIndex(question.personChecker.key);
        if (parentQuestionIndex > -1) {
          if (
            SharedConfig.selectedAssetUnderwritingQuestions[parentQuestionIndex].underwriting_answers[0].object_answer ===
            SharedConfig.selectedAssetUnderwritingQuestions[parentQuestionIndex].personChecker.triggerWording
          ) {
            question.underwriting_answers[0].object_answer = this.sharedConfig.user[fields[0]][fields[1]];
          }
        }
      }
    }
    return question.underwriting_answers;
  }

  public getPersonalOrAssetSpecificQuestions(
    user: IUser,
    underwritingQuestionsAll: Array<IQuestion>,
    asset_type: AssetType,
    questionFilteringType = QuestionFilteringType.getHiddenQuestionsForQuestionPage,
    clearAllUnderwritingAnswers = true,
    filterByHeading = null
  ): Array<IQuestion> {
    let filteredAssetSpecificQuestions = [];
    if (asset_type === AssetType.UserPersonalDetails || asset_type === AssetType.UserInsuranceHistory) {
      const personalDetailsQuestion = this.getAssetSpecificQuestions(
        user,
        underwritingQuestionsAll,
        AssetType.UserPersonalDetails,
        this.sharedConfig.getSelectedAsset(AssetType.UserPersonalDetails),
        questionFilteringType,
        clearAllUnderwritingAnswers
      );
      const previousInsurerQuestion = this.getAssetSpecificQuestions(
        user,
        underwritingQuestionsAll,
        AssetType.UserInsuranceHistory,
        this.sharedConfig.getSelectedAsset(AssetType.UserInsuranceHistory),
        questionFilteringType,
        clearAllUnderwritingAnswers
      );
      filteredAssetSpecificQuestions.push(...personalDetailsQuestion, ...previousInsurerQuestion);
    } else {
      filteredAssetSpecificQuestions = this.getAssetSpecificQuestions(
        user,
        underwritingQuestionsAll,
        asset_type,
        this.sharedConfig.getSelectedAsset(asset_type),
        questionFilteringType,
        clearAllUnderwritingAnswers
      );
    }
    if (filterByHeading) {
      return filteredAssetSpecificQuestions.filter((x) => x.heading === filterByHeading);
    } else {
      return filteredAssetSpecificQuestions;
    }
  }

  private getAssetSpecificQuestions(
    user: IUser,
    underwritingQuestionsAll: IQuestion[],
    asset_type: AssetType,
    selectedAsset: any,
    questionFilteringType: QuestionFilteringType = QuestionFilteringType.getHiddenQuestionsForQuestionPage,
    clearAllUnderwritingAnswers = true
  ): IQuestion[] {
    let questions: IQuestion[] = [];

    questions = underwritingQuestionsAll.filter((q) => q.underwriting_answers[0].object_name === asset_type);
    if (clearAllUnderwritingAnswers) questions.forEach((a) => a.underwriting_answers.forEach((x) => delete x.object_answer));

    const filteredQuestions: IQuestion[] = [];
    if (!selectedAsset) selectedAsset = [];
    for (const question of questions) {
      let addToFilteredQuestions = true;

      if (question.dependency && question.dependency.dependency_type === 'Child') {
        const parentDependencyQuestion = questions.find(
          (x) => x.dependency && x.dependency.dependency_key === question.dependency.dependency_key && x.dependency.dependency_type === 'Parent'
        );
        if (parentDependencyQuestion) {
          for (const underwritingAnswer of parentDependencyQuestion.underwriting_answers) {
            if (underwritingAnswer.object_answer !== undefined || underwritingAnswer.object_answer !== null)
              addToFilteredQuestions = underwritingAnswer.object_answer;
          }
        }
      }

      let bulkUpdatePersonalDetails = false;
      let bulkUpdateInsuranceHistory = false;
      let bulkCreateInsuranceHistory = false;
      for (const underwritingAnswer of question.underwriting_answers) {
        if (underwritingAnswer.hidden && underwritingAnswer.answer !== undefined) {
          // When to override Hidden fields
          // 1. when the field is required AND empty / undefined
          // 2. when the field is required AND answers_old has values AND the field has a value that is inside the type "oldQuestions"

          // override current QuoteRequest answer with existing asset answer if
          // 1.field is required AND answers_old has values AND the field has a value that is NOT inside the type "oldQuestions"
          if (
            (underwritingAnswer.required &&
              (!selectedAsset.hasOwnProperty(underwritingAnswer.object_field) || selectedAsset[underwritingAnswer.object_field] === undefined)) ||
            (underwritingAnswer.required &&
              underwritingAnswer.answers_old &&
              underwritingAnswer.answers_old.includes(selectedAsset[underwritingAnswer.object_field]))
          ) {
            underwritingAnswer.object_answer = underwritingAnswer.answer;
            // update the changed underwriting answer if it has changed or is empty
            if (underwritingAnswer.object_name === AssetType.UserPersonalDetails) {
              bulkUpdatePersonalDetails = true;
              user.personal_details[underwritingAnswer.object_field] = underwritingAnswer.object_answer;
            } else if (underwritingAnswer.object_name === AssetType.UserInsuranceHistory) {
              if (!user.insurance_history) {
                user.insurance_history = {};
              }
              user.insurance_history[underwritingAnswer.object_field] = underwritingAnswer.object_answer;

              if (!user.insurance_history.id) {
                bulkCreateInsuranceHistory = true;
              } else {
                bulkUpdateInsuranceHistory = true;
              }
            } else {
              selectedAsset[underwritingAnswer.object_field] = underwritingAnswer.answer;
            }
          } else if (
            underwritingAnswer.required &&
            underwritingAnswer.answers_old &&
            !underwritingAnswer.answers_old.includes(selectedAsset[underwritingAnswer.object_field])
          ) {
            underwritingAnswer.object_answer = selectedAsset[underwritingAnswer.object_field];
          } else {
            underwritingAnswer.object_answer = underwritingAnswer.answer;
          }

          addToFilteredQuestions = questionFilteringType === QuestionFilteringType.getHiddenQuestionsForQuestionPage ? true : false;
        } else {
          // TODO: This is a hack, because of the new API and old Underwriting questions that do no line up. -- allot of hacking going here. (Especially for Vehicles and buildings)
          const daytimeAddress = underwritingAnswer.object_field.indexOf('daytime');
          const overnightAddress = underwritingAnswer.object_field.indexOf('overnight');
          const fieldName = underwritingAnswer.object_field.replace('daytime', 'risk').replace('overnight', 'risk');
          if (
            selectedAsset.hasOwnProperty(underwritingAnswer.object_field) ||
            user?.addresses?.hasOwnProperty(underwritingAnswer.object_field) ||
            underwritingAnswer.object_field === 'risk_address_code' ||
            selectedAsset?.address?.hasOwnProperty(underwritingAnswer.object_field)
          ) {
            if (underwritingAnswer.object_name === AssetType.UserPersonalDetails) {
              if (underwritingAnswer.object_field === 'risk_address_code') underwritingAnswer.object_field = 'postal_code';
              if (selectedAsset[underwritingAnswer.object_field] !== undefined) {
                underwritingAnswer.object_answer = selectedAsset[underwritingAnswer.object_field];
              } else if (user.addresses && user.addresses[underwritingAnswer.object_field]) {
                underwritingAnswer.object_answer = user.addresses[underwritingAnswer.object_field];
              }
            } else if (
              selectedAsset.address &&
              (underwritingAnswer.object_name === AssetType.Buildings || underwritingAnswer.object_name === AssetType.Contents) &&
              underwritingAnswer.object_field.includes('address')
            ) {
              if (underwritingAnswer.object_field === 'risk_address_code') {
                underwritingAnswer.object_answer = selectedAsset.address['postal_code'];
              } else {
                underwritingAnswer.object_answer = selectedAsset.address[underwritingAnswer.object_field];
              }
            } else {
              underwritingAnswer.object_answer = selectedAsset[underwritingAnswer.object_field] ?? selectedAsset[underwritingAnswer.object_field];
            }
          } else {
            if (fieldName === 'risk_postal_code') {
              if (daytimeAddress >= 0 && selectedAsset.daytime_address) {
                underwritingAnswer.object_answer = selectedAsset.daytime_address['postal_code'];
              } else if (overnightAddress >= 0 && selectedAsset.overnight_address) {
                underwritingAnswer.object_answer = selectedAsset.overnight_address['postal_code'];
              }
            } else if (fieldName === 'risk_postal_code_id') {
              if (daytimeAddress >= 0 && selectedAsset.daytime_address) {
                underwritingAnswer.object_answer = selectedAsset.daytime_address['risk_address_code_id'];
              } else if (overnightAddress >= 0 && selectedAsset.overnight_address) {
                underwritingAnswer.object_answer = selectedAsset.overnight_address['risk_address_code_id'];
              }
            } else if (fieldName === 'risk_postal_code_id_mnfg') {
              if (daytimeAddress >= 0 && selectedAsset.daytime_address) {
                underwritingAnswer.object_answer = selectedAsset.daytime_address['risk_address_code_id_mnfg'];
              } else if (overnightAddress >= 0 && selectedAsset.overnight_address) {
                underwritingAnswer.object_answer = selectedAsset.overnight_address['risk_address_code_id_mnfg'];
              }
            } else if (fieldName === 'risk_postal_code_id_sa02') {
              if (daytimeAddress >= 0 && selectedAsset.daytime_address) {
                underwritingAnswer.object_answer = selectedAsset.daytime_address['risk_address_code_id_sa02'];
              } else if (overnightAddress >= 0 && selectedAsset.overnight_address) {
                underwritingAnswer.object_answer = selectedAsset.overnight_address['risk_address_code_id_sa02'];
              }
            } else if (fieldName === 'risk_address_line1') {
              if (daytimeAddress >= 0 && selectedAsset.daytime_address) {
                underwritingAnswer.object_answer = selectedAsset.daytime_address['risk_address_line1'];
              } else if (overnightAddress >= 0 && selectedAsset.overnight_address) {
                underwritingAnswer.object_answer = selectedAsset.overnight_address['risk_address_line1'];
              }
            } else if (fieldName === 'risk_address_line2') {
              if (daytimeAddress >= 0 && selectedAsset.daytime_address) {
                underwritingAnswer.object_answer = selectedAsset.daytime_address['risk_address_line2'];
              } else if (overnightAddress >= 0 && selectedAsset.overnight_address) {
                underwritingAnswer.object_answer = selectedAsset.overnight_address['risk_address_line2'];
              }
            }
          }
        }
      }

      if (bulkCreateInsuranceHistory) {
        this.apiUserService.createUserInsuranceHistory(user.insurance_history);
      } else if (bulkUpdateInsuranceHistory) {
        this.apiUserService.updateUserInsuranceHistory(user.insurance_history);
      }
      if (bulkUpdatePersonalDetails) {
        this.apiUserService.updatePersonalDetails(user.personal_details);
      }

      if (addToFilteredQuestions) filteredQuestions.push(question);
    }
    return filteredQuestions;
  }

  allRequiredUnderWritingAnswersPresent(question: IQuestion, quoteRequestSelectedAsset: any, asset_type: AssetType, user: IUser) {
    if (question.underwriting_answers[0].object_field.toLowerCase().includes('address')) {
      // includes risk_address of overnight_address

      let address: IAddresses;
      if (asset_type === AssetType.UserPersonalDetails) {
        address = user.addresses;
      } else {
        if (question.underwriting_answers[0].object_field.toLowerCase().includes('daytime')) {
          address = quoteRequestSelectedAsset.daytime_address;
        } else if (question.underwriting_answers[0].object_field.toLowerCase().includes('overnight')) {
          address = quoteRequestSelectedAsset.overnight_address;
        } else {
          address = quoteRequestSelectedAsset.address;
        }
      }

      const isAddressMissing = this.isAddressMissing(address);

      if (isAddressMissing) {
        for (const underwritingAnswer of question.underwriting_answers) {
          delete underwritingAnswer.object_answer;
        }
        return false;
      } else {
        return true;
      }
    } else {
      const missing = question.underwriting_answers.some(
        (answer) =>
          ((answer.object_answer === undefined || answer.object_answer === null) && answer.required) ||
          ((answer.object_answer === undefined || answer.object_answer === null) && answer.object_field === 'claims') ||
          ((answer.object_answer === undefined || answer.object_answer === null) && this.isParentTrueThusValidateChild(question))
      );
      return !missing;
    }
  }

  isAddressMissing(address: IAddresses): boolean {
    let isMissing = false;

    if (
      !address?.postal_code ||
      !address?.risk_address_code_id ||
      !address?.risk_address_code_id_mnfg ||
      !address?.risk_address_code_id_sa02 ||
      !address?.risk_address_line1 ||
      !address?.risk_address_line2
    ) {
      isMissing = true;
    }
    return isMissing;
  }

  isPartialAddressMissing(
    genericField: string,
    postalCodeField: string,
    addressLine1Field: string,
    addressLine2Field: string,
    quoteRequestSelectedAsset: any
  ): boolean {
    // todo this will need to be refactored for ALL ASSETS

    const postalCodeUnderwritingAnswer = quoteRequestSelectedAsset[postalCodeField];
    const addressLine1UnderwritingAnswer = quoteRequestSelectedAsset[addressLine1Field];
    const addressLine2UnderwritingAnswer = quoteRequestSelectedAsset[addressLine2Field];
    const postalCodeIdUnderwritingAnswer = quoteRequestSelectedAsset[`${genericField}_code_id`];
    const postalCodeIdSA02UnderwritingAnswer = quoteRequestSelectedAsset[`${genericField}_code_id_sa02`];
    const postalCodeIdMNFGUnderwritingAnswer = quoteRequestSelectedAsset[`${genericField}_code_id_mnfg`];

    if (
      !postalCodeUnderwritingAnswer ||
      typeof postalCodeUnderwritingAnswer !== 'string' ||
      !addressLine1UnderwritingAnswer ||
      typeof addressLine1UnderwritingAnswer !== 'string' ||
      !addressLine2UnderwritingAnswer ||
      typeof addressLine2UnderwritingAnswer !== 'string' ||
      !postalCodeIdUnderwritingAnswer ||
      typeof postalCodeIdUnderwritingAnswer !== 'string' ||
      !postalCodeIdSA02UnderwritingAnswer ||
      typeof postalCodeIdSA02UnderwritingAnswer !== 'string' ||
      !postalCodeIdMNFGUnderwritingAnswer ||
      typeof postalCodeIdMNFGUnderwritingAnswer !== 'string'
    ) {
      delete quoteRequestSelectedAsset[postalCodeField];
      delete quoteRequestSelectedAsset[addressLine1Field];
      delete quoteRequestSelectedAsset[addressLine2Field];
      delete quoteRequestSelectedAsset[`${genericField}_code_id`];
      delete quoteRequestSelectedAsset[`${genericField}_code_id_sa02`];
      delete quoteRequestSelectedAsset[`${genericField}_code_id_mnfg`];
      return true;
    } else {
      return false;
    }
  }

  isParentTrueThusValidateChild(question: IQuestion): boolean {
    if (!question.dependency) {
      return false;
    }
    const parentDependencyQuestions = SharedConfig.underwritingQuestionsAll.filter(
      (x) => x.dependency && x.dependency.dependency_key === question.dependency.dependency_key && x.dependency.dependency_type === 'Parent'
    );

    if (parentDependencyQuestions) {
      return true;
    } else {
      return false;
    }
  }

  isAssetQuestionsComplete(asset_type: AssetType, quoteRequestSelectedAsset: any, user:IUser): boolean {
    const filteredAssetQuestions = this.getAssetSpecificQuestions(
      user,
      JSON.parse(JSON.stringify(SharedConfig.underwritingQuestionsAll)),
      asset_type,
      quoteRequestSelectedAsset,
      QuestionFilteringType.hideHiddenQuestionsForSummary,
      false
    );

    const hasUnansweredQuestions = filteredAssetQuestions.some((question) => {
      const answersPresent = this.allRequiredUnderWritingAnswersPresent(question, quoteRequestSelectedAsset, asset_type, user);
      const isValidated = this.busnQuoteScheduleService.isFieldValidationSuccess(
        question.underwriting_answers,
        question,
        quoteRequestSelectedAsset,
        null
      );

      return !answersPresent || !isValidated;
    });

    return !hasUnansweredQuestions;
  }

  setIsCompleteFlagOnAllAssetsIfCompleted(user: IUser) {
    const isUserPersonalDetails = this.isAssetQuestionsComplete(AssetType.UserPersonalDetails, this.sharedConfig.user.personal_details, user);
    const isUserInsuranceHistory = this.isAssetQuestionsComplete(AssetType.UserInsuranceHistory, this.sharedConfig.user.insurance_history, user);
    this.sharedConfig.user.personal_details.is_completed = isUserPersonalDetails && isUserInsuranceHistory;

    this.sharedConfig?.quoteRequest?.assets?.all_risks?.forEach((all_risks) => {
      const index = this.sharedConfig?.quoteRequest?.assets?.all_risks.findIndex((x) => x.id === all_risks.id);

      const isCompletedPreCheck = this.sharedConfig.quoteRequest.assets.all_risks[index].is_completed;
      const isCompleted = this.isAssetQuestionsComplete(AssetType.AllRisks, all_risks, user);

      if (isCompletedPreCheck !== isCompleted) {
        // Update the db if the status did change
        this.sharedConfig.quoteRequest.assets.all_risks[index].is_completed = isCompleted;
        this.apiAssetService.updateAllRiskItem(all_risks);
      } else {
        this.sharedConfig.quoteRequest.assets.all_risks[index].is_completed = isCompleted;
      }
    });

    this.sharedConfig?.quoteRequest?.assets?.buildings?.forEach((building) => {
      const index = this.sharedConfig?.quoteRequest?.assets?.buildings.findIndex((x) => x.id === building.id);

      const isCompletedPreCheck = this.sharedConfig.quoteRequest.assets.buildings[index].is_completed;
      const isCompleted = this.isAssetQuestionsComplete(AssetType.Buildings, building, user);

      if (isCompletedPreCheck !== isCompleted) {
        // Update the db if the status did change
        this.sharedConfig.quoteRequest.assets.buildings[index].is_completed = isCompleted;
        this.apiAssetService.updateBuilding(building);
      } else {
        this.sharedConfig.quoteRequest.assets.buildings[index].is_completed = isCompleted;
      }
    });

    this.sharedConfig?.quoteRequest?.assets?.caravans?.forEach((caravan) => {
      const index = this.sharedConfig?.quoteRequest?.assets?.caravans.findIndex((x) => x.id === caravan.id);

      const isCompletedPreCheck = this.sharedConfig.quoteRequest.assets.caravans[index].is_completed;
      const isCompleted = this.isAssetQuestionsComplete(AssetType.Caravans, caravan, user);

      if (isCompletedPreCheck !== isCompleted) {
        // Update the db if the status did change
        this.sharedConfig.quoteRequest.assets.caravans[index].is_completed = isCompleted;
        this.apiAssetService.updateCaravan(caravan);
      } else {
        this.sharedConfig.quoteRequest.assets.caravans[index].is_completed = isCompleted;
      }
    });

    this.sharedConfig?.quoteRequest?.assets?.contents?.forEach((content) => {
      const index = this.sharedConfig?.quoteRequest?.assets?.contents.findIndex((x) => x.id === content.id);

      const isCompletedPreCheck = this.sharedConfig.quoteRequest.assets.contents[index].is_completed;
      const isCompleted = this.isAssetQuestionsComplete(AssetType.Contents, content, user);

      if (isCompletedPreCheck !== isCompleted) {
        // Update the db if the status did change
        this.sharedConfig.quoteRequest.assets.contents[index].is_completed = isCompleted;
        this.apiAssetService.updateContent(content);
      } else {
        this.sharedConfig.quoteRequest.assets.contents[index].is_completed = isCompleted;
      }
    });

    this.sharedConfig?.quoteRequest?.assets?.motorcycles?.forEach((motorcycle) => {
      const index = this.sharedConfig?.quoteRequest?.assets?.motorcycles.findIndex((x) => x.id === motorcycle.id);

      const isCompletedPreCheck = this.sharedConfig.quoteRequest.assets.motorcycles[index].is_completed;
      const isCompleted = this.isAssetQuestionsComplete(AssetType.Motorcycles, motorcycle, user);

      if (isCompletedPreCheck !== isCompleted) {
        // Update the db if the status did change
        this.sharedConfig.quoteRequest.assets.motorcycles[index].is_completed = isCompleted;
        this.apiAssetService.updateMotorcycle(motorcycle);
      } else {
        this.sharedConfig.quoteRequest.assets.motorcycles[index].is_completed = isCompleted;
      }
    });

    this.sharedConfig?.quoteRequest?.assets?.specified_items?.forEach((specified_item) => {
      const index = this.sharedConfig?.quoteRequest?.assets?.specified_items.findIndex((x) => x.id === specified_item.id);

      const isCompletedPreCheck = this.sharedConfig.quoteRequest.assets.specified_items[index].is_completed;
      const isCompleted = this.isAssetQuestionsComplete(AssetType.SpecifiedItems, specified_item, user);

      if (isCompletedPreCheck !== isCompleted) {
        // Update the db if the status did change
        this.sharedConfig.quoteRequest.assets.specified_items[index].is_completed = isCompleted;
        this.apiAssetService.updateSpecifiedItem(specified_item);
      } else {
        this.sharedConfig.quoteRequest.assets.specified_items[index].is_completed = isCompleted;
      }
    });

    this.sharedConfig?.quoteRequest?.assets?.vehicles?.forEach((vehicle) => {
      const index = this.sharedConfig?.quoteRequest?.assets?.vehicles.findIndex((x) => x.id === vehicle.id);

      const isCompletedPreCheck = this.sharedConfig.quoteRequest.assets.vehicles[index].is_completed;
      const isCompleted = this.isAssetQuestionsComplete(AssetType.Vehicles, vehicle, user);

      if (isCompletedPreCheck !== isCompleted) {
        // Update the db if the status did change
        this.sharedConfig.quoteRequest.assets.vehicles[index].is_completed = isCompleted;
        this.apiAssetService.updateVehicle(vehicle);
      } else {
        this.sharedConfig.quoteRequest.assets.vehicles[index].is_completed = isCompleted;
      }
    });

    this.checkIfQuoteRequestCompletedAndCanGoNext();
  }

  checkIfQuoteRequestCompletedAndCanGoNext() {
    const assetTypes = ['all_risks', 'buildings', 'caravans', 'contents', 'motorcycles', 'specified_items', 'vehicles'];
    const assetsComplete = assetTypes.every(
      (type) => !this.sharedConfig.quoteRequest?.assets?.[type]?.some((asset: { is_completed: any }) => !asset.is_completed)
    );
    //  const totalQuotesCount = assetTypes.reduce((total, type) => total + (quoteRequest?.assets?.[type]?.length || 0), 0);
    // const isPersonalDetailsComplete = user.personal_details.is_completed;

    //  if (quoteRequest?.type === 'QUOTE') {
    // this.liveVariables.isQuoteRequestCompletedAndCanGoNext = isPersonalDetailsComplete && assetsComplete && totalQuotesCount > 0;
    this.sharedConfigService.liveVariables.isQuoteRequestCompletedAndCanGoNext = assetsComplete;
    //   } else {
    //   this.liveVariables.isQuoteRequestCompletedAndCanGoNext = assetsComplete && totalQuotesCount > 0;
    //   this.liveVariables.isQuoteRequestCompletedAndCanGoNext = assetsComplete;
    // }
  }

  initializeIndividualQuestionLoaders(
    asset_type: AssetType,
    from_page: fromPage,
    selectedAssetUnderwritingQuestions: IQuestion[],
    quote_type?: QuoteType
  ) {
    const questionGroup = [];
    const totalQuestions = 100 / selectedAssetUnderwritingQuestions.length;
    const uniqueHeadings = this.getUniqueUnderwritingQuestionHeadings(from_page, selectedAssetUnderwritingQuestions, quote_type);
    for (let groupIndex = 0; groupIndex < uniqueHeadings.length; groupIndex++) {
      const heading = uniqueHeadings[groupIndex];
      const allQuestionsWithHeading = selectedAssetUnderwritingQuestions.filter(
        (x) => x.heading === heading && x.underwriting_answers[0].hidden === false
      );
      // This will compile as needed and return your desired structure
      switch (from_page) {
        case 'question_comp':
          const notNullAnswer = allQuestionsWithHeading.filter((x) => x.underwriting_answers[0].object_answer !== undefined);
          const isValidAndSuccess = notNullAnswer.filter((x) =>
            this.busnQuoteScheduleService.isFieldValidationSuccess(x.underwriting_answers, x, this.sharedConfig.getSelectedAsset(asset_type))
          );

          const totalAnswered = isValidAndSuccess.length;

          questionGroup.push({
            index: groupIndex,
            size: allQuestionsWithHeading.length,
            width_of_progress_bar: allQuestionsWithHeading.length * totalQuestions,
            total_answered: totalAnswered,
          });
          break;
        case 'quote_claims_history_check':
          questionGroup.push({
            index: groupIndex,
            answeredQuestions: allQuestionsWithHeading,
            headings: heading,
            total_answered: 0,
          });
          break;
        case 'quote_summary_not_editable':
          questionGroup.push({
            index: groupIndex,
            answeredQuestions: allQuestionsWithHeading,
            headings: heading,
            total_answered: 0,
          });
          break;
        default:
          break;
      }
    }
    return questionGroup;
  }

  getUniqueUnderwritingQuestionHeadings(from_page: fromPage, selectedAssetUnderwritingQuestions, quote_type?): string[] {
    const headings = [];
    if (from_page === 'question_comp' || from_page === 'quote_summary_not_editable') {
      if (quote_type === QuoteType.MyProfile) {
        for (const selectedAssetUWQ of selectedAssetUnderwritingQuestions.filter(
          (x) => x.underwriting_answers[0].hidden === false && x.underwriting_answers[0].object_name === AssetType.UserPersonalDetails
        )) {
          headings.push(selectedAssetUWQ.heading);
        }
      } else {
        for (const selectedAssetUWQ of selectedAssetUnderwritingQuestions.filter((x) => x.underwriting_answers[0].hidden === false)) {
          headings.push(selectedAssetUWQ.heading);
        }
      }
    } else {
      for (const selectedAssetUWQ of selectedAssetUnderwritingQuestions.filter(
        (x) => x.underwriting_answers[0].hidden === false && x.underwriting_answers[0].object_name === AssetType.UserInsuranceHistory
      )) {
        headings.push(selectedAssetUWQ.heading);
      }
    }

    return headings.filter((heading, index) => headings.indexOf(heading) === index);
  }
}
