import { Injectable } from '@angular/core';
import {
  IPersonalDetails,
  IInsuranceHistory,
  IProviders,
  IUser,
  IAccountDetails,
  SharedConfig,
  IEmailAuth,
  ReqResponse,
  IUserAllRelatedData,
  IUserPermission,
} from '@takectrl-multiapp/svcs-share-config';
import { of } from 'rxjs';
import { APIHttpService } from './api-http.service';
import { ICtrlFileResponse, ICtrlResponse } from '../interfaces/api.interface';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { ApiAuthService } from './api-auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class APIUserService {

  constructor(
    private readonly httpService: APIHttpService,
    public apiAuthService: ApiAuthService,
    public firebase: AngularFireDatabase,
    private readonly sharedConfig: SharedConfig
  ) {}

  getAllUserRelatedData(): Observable<ICtrlResponse<IUserAllRelatedData>> {
    return this.httpService.get(`/user/all-related/data/`, {});
  }

  checkEmailForSpam(email: string): Observable<IEmailAuth> {
    return this.httpService.post('/users/verify/email/', { email: email });
  }

  getUserPermissions(): Observable<ICtrlResponse<IUserPermission>> {
    return this.httpService.get(`/users/get_user_permissions/`, {});
  }

  checkIfUserMsisdnIsRegistered(phoneNumber: string): Observable<ReqResponse> {
    return this.httpService.get(
      `${SharedConfig.appCredentials.firebase_functions_url}/authentication/user-record-by-msisdn?msisdn=${phoneNumber.substring(1)}`
    );
  }

  getUser(firebase_uuid: string): Observable<ICtrlResponse<IUser>> {
    return this.httpService.get(`/users/`, { firebase_uuid });
  }

  getUserByUserId(user_id: number): Observable<ICtrlResponse<IUser>> {
    return this.httpService.get(`/users/`, { user_id });
  }

  generateUserIntercomID(): Observable<ICtrlResponse<IUser>> {
    return this.httpService.post(`/users/set/providers/intercom/`, {});
  }

  getUserByEmail(email: string): Observable<ICtrlResponse<IUser>> {
    return this.httpService.post(`/admin/user/lookup/`, { email: email });
  }

  createUser(userDetails: IUser): Observable<ICtrlResponse<IUser>> {
    const payload = {
      ...userDetails,
    };
    payload.firebase_uuid = SharedConfig.firebaseUserKey;

    return this.httpService.post(`/users/`, payload);
  }

  updateUser(userDetails: IUser): Observable<ICtrlResponse<IUser>> {
    return this.httpService.patch(`/users/${this.sharedConfig.user.id}/`, userDetails);
  }

  updatePersonalDetails(personal_details: IPersonalDetails): Observable<void> {
    return this.httpService.patch(`/users/personal-details/${this.sharedConfig.user.personal_details.id}/`, personal_details);
  }

  updateProviders(providers: IProviders): Observable<void> {
    return this.httpService.post(`/users/providers/${this.sharedConfig.user.id}/`, providers);
  }

  updateUserAccountDetails(account_details: IAccountDetails): Observable<void> {
    return this.httpService.patch(`/users/account-details/${this.sharedConfig.user.account_details.id}/`, account_details);
  }

  createUserAccountDetails(account_details: IAccountDetails): Observable<void> {
    return this.httpService.post(`/users/account-details/${this.sharedConfig.user.account_details.id}/`, account_details);
  }

  createUserInsuranceHistory(insuranceHistory: IInsuranceHistory): Observable<void> {
    return this.httpService.post(`/users/insurance-history/${insuranceHistory.id}/`, insuranceHistory);
  }

  updateUserInsuranceHistory(insuranceHistory: IInsuranceHistory): Observable<void> {
    //---Fix for backend not populating seriti users insurance history. can remove later
    if (!insuranceHistory) return of(null);
    //---Fix for backend not populating seriti users insurance history. can remove later
    return this.httpService.patch(`/users/insurance-history/${insuranceHistory.id}/`, insuranceHistory);
  }

  getDocuments(): Observable<ICtrlResponse<ICtrlFileResponse[]>> {
    return this.httpService.get(`/files/list/${this.sharedConfig.user.id}/user/`);
  }

  deleteUserAccount() {
    return this.httpService.delete(`/users/`);
  }

  addReferral(name: string, surname: string, email: string): Observable<ICtrlResponse<unknown>> {
    const referral = {
      preferred_name: name,
      surname: surname,
      email: email,
    };
    return this.httpService.post(`/user_referrals/`, referral);
  }

  updateUserEmail(email: string): Observable<ICtrlResponse<unknown>> {
    return this.httpService.post(`/users/update/email/`, { email: email });
  }
}
