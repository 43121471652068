<div class="wrapper wrap-around limit-width-on-desktop-float">
  <div class="header-wrapper">
    <div *ngIf="playAnimation" class="animation-wrapper">
      <lottie-player autoplay mode="normal" src="assets/lottie-animations/successful-register.json"></lottie-player>
    </div>
    <h2 class="h2-bold">
      {{ sharedConfig.appClientDetail.app_client_specific_text_settings.quote_complete_header_text }}
    </h2>
  </div>
  <div class="content-wrapper">
    <div>
      <h2 class="question-heading">What happens next?</h2>
      <ion-grid>
        <ion-row>
          <ion-col size="1">
            <div class="square-bg">
              <p>1</p>
            </div>
          </ion-col>
          <ion-col>
            <p>
              {{sharedConfig.appClientDetail.display_name_full}} is now your advisor, so feel free to contact us if you need any assistance.
            </p>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="1">
            <div class="square-bg">
              <p>2</p>
            </div>
          </ion-col>
          <ion-col>
            <p><b>Download</b> the {{sharedConfig.appClientDetail.display_name_full}} app to have instant access to everything you need in one place. <a href={{sharedConfig.appSettings.app_download_url}} target="_blank">{{sharedConfig.appSettings.app_download_url}}</a></p>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="1">
            <div class="square-bg">
              <p>3</p>
            </div>
          </ion-col>
          <ion-col>
            <p>
              This is your new policy number 
              <a><b>{{ policy_number }}</b></a>
            </p>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="1">
            <div class="square-bg">
              <p>4</p>
            </div>
          </ion-col>
          <ion-col>
            <p>We've sent you a welcome <b>Whatsapp</b> and <b>email</b> with your policy and a bit more infomation about us.</p>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="1">
            <div class="square-bg">
              <p>5</p>
            </div>
          </ion-col>
          <ion-col>
            <p>You will need to cancel your current insurance from <a><b>{{ effective_date }}</b></a></p>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</div>
<div class="footer-button-wrapper">
  <div class="fade"></div>
  <div class="footer-button-background">
    <ion-button mode="ios" (click)="goHome()" mode="ios" class="footer-button limit-width-desktop-on-button" expand="block">
      View my policy
    </ion-button>
  </div>
</div>

<!-- TODO ALL OTHER POPUPS SHOULD BE REPLACED BY THIS GENERIC POPUP -->
<popup-dialog  id="quote.complete.component" *ngIf="sharedConfig.popupDialogShow"></popup-dialog>
