import { Component, OnInit, NgZone, AfterViewChecked } from '@angular/core';
import { NavController } from '@ionic/angular';
import { BUSNQuoteScheduleService } from '@takectrl-multiapp/svcs-core-business';
import { IQuoteRequest, SharedConfig } from '@takectrl-multiapp/svcs-share-config';
@Component({
  selector: 'quote-status-button',
  templateUrl: './quote-status-button.component.html',
  styleUrls: ['./quote-status-button.component.scss'],
})
export class QuoteStatusButtonComponent implements OnInit, AfterViewChecked {
  // @Input() quoteRequest: IQuoteRequest;
  public buttonText: string;
  quoteRequest: IQuoteRequest;
  constructor(
    private readonly zone: NgZone,
    public navCtrl: NavController,
    public busnQuoteScheduleService: BUSNQuoteScheduleService,
    public sharedConfig: SharedConfig
  ) {}
  
  ngOnInit(): void {
    
  }

  async ngAfterViewChecked(): Promise<void> {}

  navQuote() {
    this.zone.run(() => {
      this.navCtrl.navigateRoot(['home/home-quote']);
    });
  }
}
