<div class="backdrop"></div>
<div class="popup-standard limit-width-on-desktop-float">
  <div class="text-center">
    <h2 class="h2-bold">{{ header }}</h2>
  </div>
  <div class="sub-header">
    <p>{{ message }}</p>
  </div>
  <div class="buttons-wrapper">
    <ng-content></ng-content>
  </div>
</div>
