import { SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import { Component, Input, NgZone, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import '@lottiefiles/lottie-player';

@Component({
  selector: 'success-animation',
  templateUrl: './success-animation.component.html',
  styleUrls: ['./success-animation.component.scss'],
})
export class SuccessAnimationComponent implements OnInit {
  @Input() animationName: string;
  @Input() displayText: string;
  @Input() displayHeader: string;
  @Input() quoteType: string;
  constructor(public navCtrl: NavController, private readonly zone: NgZone, public sharedConfig: SharedConfig) {}

  ngOnInit(): void {
    this.showQuoteNewComp();
  }

  showQuoteNewComp() {
    let counter = 3;
    const intervalId = setInterval(() => {
      counter = counter - 1;
      if (counter === 0) {
        this.checkWhereToRoute();
        clearInterval(intervalId);
      }
    }, 1000);
  }

  checkWhereToRoute() {
    // todo, this routing code should not be in here, it should be a callback with the routing happening in the class or component calling this component.
    // Hack
   
   
    //  if (!this.quoteType || this.quoteType === QuoteType.StandardQuoteRequest) {
    this.zone.run(async () => {
      this.navCtrl.navigateRoot(['quote-new']);
    });
    // } else {
    //   this.zone.run(async () => {
    //     this.navCtrl.navigateRoot('quote-endorsement', {
    //       queryParams: { data: { endorsementRequest: this.sharedConfig.endorsementRequest, insurer_id: '' } },
    //     });
    //   });
    // }
  }
}
