import { LoadingController } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { BUSNUserService } from '@takectrl-multiapp/svcs-core-business';
import { Component, OnInit } from '@angular/core';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import { ICtrlFileResponse } from 'libs/svcs-ctrl-api/src/lib/interfaces/api.interface';
declare const dataLayer: any[];

@Component({
  selector: 'useful-documents',
  templateUrl: './useful-documents.component.html',
  styleUrls: ['./useful-documents.component.scss'],
})
export class UsefulDocumentsComponent implements OnInit {
  documents: ICtrlFileResponse[];
  loader = this.loadingController.create();
  constructor(
    public thirdpartyService: BUSNThirdPartyService,
    public sharedConfig: SharedConfig,
    private readonly busnUserService: BUSNUserService,
    private readonly iab: InAppBrowser,
    private readonly loadingController: LoadingController
  ) {}

  ngOnInit(): void {
    this.getDocuments();

    dataLayer.push({
      event: 'page_show',
      screenPath: 'useful-documents',
      screenName: 'useful-documents',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }

  async getDocuments() {
    this.sharedConfig.setShowCustomLoader(true)
    this.busnUserService.getDocuments().then(async (docs) => {
      this.documents = docs;
      this.sharedConfig.setShowCustomLoader(false)
    });
  }

  viewPDF(index: number) {
    this.iab.create(this.documents[index].url, '_system');
  }
}
