export enum InsurerType {
  absaplus = 'ABSAPLUS',
  absapremium = 'ABSAPREMIUM',
  brolink10 = 'BROLINK10',
  bryte = 'BRYTE',
  santam = 'SANTAM',
  kingprice = 'KINGPRICE',
  miway = 'MIWAY',
  mutualFederal = 'MUTUAL&FEDERAL',
  peerless = 'PEERLESS',
}
