<div class="input-wrapper">
  <ion-item mode="ios" (click)="showSelect(listYear, 'Year')">
    <ion-label *ngIf="showHeading" position="{{ labelPosition }}">Year</ion-label>
    <ion-input readonly="true" [(ngModel)]="yearUnderwriting.object_answer"></ion-input>
  </ion-item>
  <p class="error-message" *ngIf="error">{{ error }}</p>
  <ion-item mode="ios" (click)="selectMakePopup()">
    <ion-label *ngIf="showHeading" position="{{ labelPosition }}">Make</ion-label>
    <ion-input [disabled]="!makes || readOnly || !yearUnderwriting.object_answer" readonly="true" [(ngModel)]="makeUnderwriting.object_answer"></ion-input>
  </ion-item>
  <p class="error-message" *ngIf="error">{{ error }}</p>
  <ion-item mode="ios" (click)="showSelect(models, 'Model')">
    <ion-label *ngIf="showHeading" position="{{ labelPosition }}">Model</ion-label>
    <ion-input readonly="true" [disabled]="!models || readOnly" [(ngModel)]="modelUnderwriting.object_answer"></ion-input>
  </ion-item>
  <p class="error-message" *ngIf="error">{{ error }}</p>
  <ion-item mode="ios" (click)="showSelect(modelVariants, 'Variant')">
    <ion-label *ngIf="showHeading" position="{{ labelPosition }}">Variant</ion-label>
    <ion-input readonly="true" [disabled]="!modelVariants || readOnly" [(ngModel)]="modelVariantUnderwriting.object_answer"></ion-input>
  </ion-item>
  <p class="error-message" *ngIf="error">{{ error }}</p>
</div>

<div class="backdrop" *ngIf="showSelectPopup"></div>
<div class="select-popup limit-width-on-desktop-float" *ngIf="showSelectPopup">
  <div class="heading">
    <h2 class="h2-bold">{{ popupHeading }}</h2>
  </div>
  <div class="select-wrapper" *ngIf="popupHeading === 'Make'">
    <ion-item
      mode="md"
      *ngFor="let selectOption of splitBrands?.popular"
      lines="none"
      [ngClass]="{ active: selectOption == selectAnswer }"
      (click)="setAnswer(selectOption)"
    >
      <ion-text>{{ selectOption }}</ion-text>
    </ion-item>

    <ion-item-divider style="margin-top: 20px; margin-bottom: 20px;" color="primary">
      <ion-label>Other</ion-label>
    </ion-item-divider>

    <ion-item
      mode="md"
      *ngFor="let selectOption of splitBrands.others"
      lines="none"
      [ngClass]="{ active: selectOption == selectAnswer }"
      (click)="setAnswer(selectOption)"
    >
      <ion-text>{{ selectOption }}</ion-text>
    </ion-item>
  </div>

  <div class="select-wrapper" *ngIf="popupHeading === 'Year'">
    <ion-item
      mode="md"
      *ngFor="let selectOption of selectArray"
      lines="none"
      [ngClass]="{ active: selectOption == selectAnswer }"
      (click)="setAnswer(selectOption)"
    >
      <ion-text>{{ selectOption }}</ion-text>
    </ion-item>
  </div>

  <div class="select-wrapper" *ngIf="popupHeading === 'Model'">
    <ion-item
      mode="md"
      *ngFor="let selectOption of selectArray"
      lines="none"
      [ngClass]="{ active: selectOption.name == selectAnswer }"
      (click)="setAnswer(selectOption.name)"
    >
      <ion-text>{{ selectOption.name }}</ion-text>
    </ion-item>
  </div>
  <div class="select-wrapper" *ngIf="popupHeading === 'Variant'">
    <ion-item
      mode="md"
      *ngFor="let selectOption of selectArray"
      lines="none"
      [ngClass]="{ active: selectOption.id == selectAnswer }"
      (click)="setAnswer(selectOption.id)"
    >
      <ion-text>{{ selectOption.variant }}</ion-text>
    </ion-item>
  </div>
  <div class="buttons-wrapper">
    <ion-button mode="ios" (click)="closeSelectPopup()" class="secondary-button">Cancel</ion-button>
  </div>
</div>
