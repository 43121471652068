<div class="sticky-header">
  <div *ngIf="quoteComparisonDetailType !== QuoteComparisonDetailType.SpecialConditions">
      <quote-comparison-top-vs-view [selectedQuoteToCompareOne]=selectedQuoteToCompareOne
          [selectedQuoteToCompareTwo]=selectedQuoteToCompareTwo (incrementQuoteOne)=compIncrementQuoteOne()
          (incrementQuoteTwo)=compIncrementQuoteTwo()></quote-comparison-top-vs-view>
  </div>
  <quote-comparison-asset-details-tabs [quoteComparisonDetailType]="quoteComparisonDetailType" [quoteComparisonAssetTabs]=quoteComparisonAssetTabs (setActiveComparisonAssetTab)=compSetActiveComparisonAssetTab($event) [disableRight]=disableRight></quote-comparison-asset-details-tabs>
</div>
<div class="content limit-width-on-desktop-float">
  <ion-grid class="list card-list" *ngIf="quoteComparisonDetailType === QuoteComparisonDetailType.EssentialCover">
      <ion-row *ngFor="let activeQuoteAssetTabEssentialDetail of essentialQuoteOne; let i = index">
          <ion-col size="2">
              <ion-icon *ngIf="activeQuoteAssetTabEssentialDetail.values === 'Yes'"
                  src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
              <ion-icon *ngIf="activeQuoteAssetTabEssentialDetail.values === 'No'"
                  src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
              <small *ngIf="activeQuoteAssetTabEssentialDetail.values === 'Optional'" class="optional-word">Optional</small>
          </ion-col>
          <ion-col size="8">
              <p class="description">{{activeQuoteAssetTabEssentialDetail.description}}</p>
          </ion-col>
          <ion-col size="2">
              <ion-icon *ngIf="essentialQuoteTwo[i].values === 'Yes'"
                  src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
              <ion-icon *ngIf="essentialQuoteTwo[i].values === 'No'"
                  src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
              <small *ngIf="essentialQuoteTwo[i].values === 'Optional'" class="optional-word">Optional</small>
          </ion-col>
        </ion-row>
    
  </ion-grid>

  <ion-grid class="list card-list" *ngIf="quoteComparisonDetailType === QuoteComparisonDetailType.ConvenienceCover">
      <ion-row *ngFor="let activeQuoteAssetTabConvenienceDetail of convenienceQuoteOne; let i = index">
          <ion-col size="2">
              <ion-icon *ngIf="activeQuoteAssetTabConvenienceDetail.values === 'Yes'"
                  src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
              <ion-icon *ngIf="activeQuoteAssetTabConvenienceDetail.values === 'No'"
                  src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
              <small *ngIf="activeQuoteAssetTabConvenienceDetail.values === 'Optional'" class="optional-word">Optional</small>
          </ion-col>
          <ion-col size="8">
              <p class="description">{{activeQuoteAssetTabConvenienceDetail.description}}</p>
          </ion-col>
          <ion-col size="2">
              <ion-icon *ngIf="convenienceQuoteTwo[i].values === 'Yes'"
                  src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
              <ion-icon *ngIf="convenienceQuoteTwo[i].values === 'No'"
                  src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
              <small *ngIf="convenienceQuoteTwo[i].values === 'Optional'" class="optional-word">Optional</small>
          </ion-col>
      </ion-row>
  </ion-grid>

  <ion-grid *ngIf="quoteComparisonDetailType === QuoteComparisonDetailType.Excess" class="excess-grid card-list">
      <div *ngIf="activeTabType === AssetType.Vehicles || activeTabType === AssetType.Motorcycles || activeTabType === AssetType.Caravans || activeTabType === AssetType.Buildings || activeTabType === AssetType.Contents">
          <div *ngFor="let quoteOne of quoteOneSelectedAsset; let i = index">
              <ion-row>
                  <ion-col size="12">
                      <div class="header">
                          <b
                              *ngIf="activeTabType === AssetType.Vehicles || activeTabType === AssetType.Motorcycles || activeTabType === AssetType.Caravans">{{quoteOne.asset_details.model}}</b>
                          <b
                              *ngIf="activeTabType === AssetType.Buildings || activeTabType === AssetType.Contents">{{quoteOne.asset_details.address.risk_address_line1}}</b>
                      </div>
                  </ion-col>
              </ion-row>
              <ion-row *ngFor="let excess of quoteOne?.excesses;let in = index">
                  <ion-col size="4">
                      <div class="space">
                          <b class="small" *ngIf="excess.percentage !== 0">{{excess.percentage | number: '1.0-0'}}% of claim
                              <br />
                              min R{{excess.minimum_amount | number: '1.0-0'}}</b>
                          <b class="small" *ngIf="excess.percentage === 0">R{{excess.minimum_amount | number: '1.0-0'}}</b>
                      </div>
                  </ion-col>
                  <ion-col size="4">
                      <div class="space">
                          <b class="small b-medium">{{excess.description}}</b>
                      </div>
                  </ion-col>
                  <ion-col size="4">
                      <div class="space">
                          <b class="small"
                              *ngIf="quoteTwoSelectedAsset[i]?.excesses[in]?.percentage !== 0">{{quoteTwoSelectedAsset[i]?.excesses[in]?.percentage
                              | number: '1.0-0'}}% of claim <br />
                              min R{{quoteTwoSelectedAsset[i]?.excesses[in]?.minimum_amount | number: '1.0-0'}}</b>
                          <b class="small"
                              *ngIf="quoteTwoSelectedAsset[i]?.excesses[in]?.percentage === 0">R{{quoteTwoSelectedAsset[i]?.excesses[in]?.minimum_amount
                              | number: '1.0-0'}}</b>
                      </div>
                  </ion-col>
              </ion-row>
              <hr *ngIf="quoteOne.length > 0">
          </div>
      </div>
      <div *ngIf="activeTabType === AssetType.AllRisks">
          <div class="premium-wrapper" *ngIf="compareQuoteOneAllRisk">
              <ion-row>
                  <ion-col size="12">
                      <div class="header">
                          <b>Clothing and personal effects</b>
                      </div>
                  </ion-col>
              </ion-row>
              <div *ngFor="let quoteOne of compareQuoteOneAllRisk; let i = index">
                  <ion-row *ngFor="let excess of quoteOne?.excesses;let in = index">
                      <ion-col size="4">
                          <div class="space" >
                              <b class="small" *ngIf="excess.percentage !== 0">{{excess.percentage | number: '1.0-0'}}% of claim
                                  <br />
                                  min R{{excess.minimum_amount | number: '1.0-0'}}</b>
                              <b class="small" *ngIf="excess.percentage === 0">R{{excess.minimum_amount | number: '1.0-0'}}</b>
                          </div>
                      </ion-col>
                      <ion-col size="4">
                          <div class="space">
                              <b class="small b-medium">{{excess.description}}</b>
                          </div>
                      </ion-col>
                      <ion-col size="4">
                          <div class="space">
                              <b class="small" *ngIf="compareQuoteTwoAllRisk[i]?.excesses[in]?.percentage !== 0">{{compareQuoteTwoAllRisk[i]?.excesses[in]?.percentage | number: '1.0-0'}}% of claim
                                  <br />
                                  min R{{compareQuoteTwoAllRisk[i]?.excesses[in]?.minimum_amount | number: '1.0-0'}}</b>
                              <b class="small" *ngIf="compareQuoteTwoAllRisk[i]?.excesses[in].percentage === 0">R{{compareQuoteTwoAllRisk[i]?.excesses[in]?.minimum_amount | number: '1.0-0'}}</b>
                          </div>
                      </ion-col>
                  </ion-row>
              </div>
          </div>
          <div *ngIf="compareQuoteOneSpecifiedItems">
              <div class="premium-wrapper" *ngFor="let quoteOne of compareQuoteOneSpecifiedItems; let i = index">
                  <ion-row>
                      <ion-col size="12">
                          <div class="header">
                              <b>{{quoteRequestSpecifiedItems[i].description}}</b>
                          </div>
                      </ion-col>
                  </ion-row>
                  <ion-row *ngFor="let excess of quoteOne?.excesses;let in = index">
                      <ion-col size="4">
                          <div class="space">
                              <b class="small" *ngIf="excess.percentage !== 0">{{excess.percentage | number: '1.0-0'}}% of claim
                                  <br />
                                  min R{{excess.minimum_amount | number: '1.0-0'}}</b>
                              <b class="small" *ngIf="excess.percentage === 0">R{{excess.minimum_amount | number: '1.0-0'}}</b>
                          </div>
                      </ion-col>
                      <ion-col size="4">
                          <div class="space">
                              <b class="small b-medium">{{excess.description}}</b>
                          </div>
                      </ion-col>
                      <ion-col size="4">
                          <div class="space">
                              <b class="small" *ngIf="compareQuoteTwoSpecifiedItems[i]?.excesses[in].percentage !== 0">{{compareQuoteTwoSpecifiedItems[i]?.excesses[in].percentage | number: '1.0-0'}}% of claim
                                  <br />
                                  min R{{compareQuoteTwoSpecifiedItems[i]?.excesses[in].minimum_amount | number: '1.0-0'}}</b>
                              <b class="small" *ngIf="compareQuoteTwoSpecifiedItems[i]?.excesses[in].percentage === 0">R{{compareQuoteTwoSpecifiedItems[i]?.excesses[in].minimum_amount | number: '1.0-0'}}</b>
                          </div>
                      </ion-col>
                  </ion-row>
                  <hr *ngIf="quoteOne.length > 0">
              </div>
          </div>
      </div>
  </ion-grid>

  <ion-grid *ngIf="quoteComparisonDetailType === QuoteComparisonDetailType.Premium" class="premium-grid card-list">
      <div *ngIf="activeTabType !== 'personal_liability'"> 
          <div *ngIf="activeTabType === AssetType.Vehicles || activeTabType === AssetType.Motorcycles || activeTabType === AssetType.Caravans || activeTabType === AssetType.Buildings || activeTabType === AssetType.Contents">
              <div class="premium-wrapper" *ngFor="let quoteOne of quoteOneSelectedAsset; let i = index">
                  <ion-row>
                      <ion-col size="12">
                          <div class="header">
                              <b
                                  *ngIf="activeTabType === AssetType.Vehicles || activeTabType === AssetType.Motorcycles || activeTabType === AssetType.Caravans">{{quoteOne.asset_details.model}}</b>
                              <b
                                  *ngIf="activeTabType === AssetType.Buildings || activeTabType === AssetType.Contents">{{quoteOne.asset_details.address.risk_address_line1}}</b>
                          </div>
                      </ion-col>
                  </ion-row>
                  <ion-row>
                      <ion-col size="4">
                          <div class="space">
                              <b class="small">R{{quoteOne?.premium | number: '1.0-0'}}</b>
                          </div>
                      </ion-col>
                      <ion-col size="4">
                          <div class="space">
                              <b class="small b-medium">Premium</b>
                          </div>
                      </ion-col>
                      <ion-col size="4">
                          <div class="space">
                              <b class="small">R{{quoteTwoSelectedAsset[i]?.premium | number: '1.0-0'}}</b>
                          </div>
                      </ion-col>
                  </ion-row>
                  <ion-row>
                      <ion-col size="4">
                          <div class="space">
                              <b class="small">R{{quoteOne?.sum_insured | number: '1.0-0'}}</b>
                          </div>
                      </ion-col>
                      <ion-col size="4">
                          <div class="space">
                              <b class="small b-medium">Sum insured</b>
                          </div>
                      </ion-col>
                      <ion-col size="4">
                          <div class="space">
                              <b class="small">R{{quoteTwoSelectedAsset[i]?.sum_insured | number: '1.0-0'}}</b>
                          </div>
                      </ion-col>
                  </ion-row>
                  <hr *ngIf="quoteOne.length > 0">
              </div>
          </div>
      </div>
      <div *ngIf="activeTabType === AssetType.AllRisks">
          <div *ngIf="compareQuoteOneAllRisk">
              <ion-row>
                  <ion-col size="12">
                      <div class="header">
                          <b>Clothing and personal effects</b>
                      </div>
                  </ion-col>
              </ion-row>
              <div *ngFor="let quoteOne of compareQuoteOneAllRisk; let i = index">
                  <ion-row>
                      <ion-col size="4">
                          <div class="space">
                              <b class="small">R{{quoteOne?.premium | number: '1.0-0'}}</b>
                          </div>
                      </ion-col>
                      <ion-col size="4">
                          <div class="space">
                              <b class="small b-medium">Premium</b>
                          </div>
                      </ion-col>
                      <ion-col size="4">
                          <div class="space">
                              <b class="small">R{{compareQuoteTwoAllRisk[i]?.premium | number: '1.0-0'}}</b>
                          </div>
                      </ion-col>
                  </ion-row>
                  <ion-row>
                      <ion-col size="4">
                          <div class="space">
                              <b class="small">R{{quoteOne?.sum_insured | number: '1.0-0'}}</b>
                          </div>
                      </ion-col>
                      <ion-col size="4">
                          <div class="space">
                              <b class="small b-medium">Sum insured</b>
                          </div>
                      </ion-col>
                      <ion-col size="4">
                          <div class="space">
                              <b class="small">R{{compareQuoteTwoAllRisk[i]?.sum_insured | number: '1.0-0'}}</b>
                          </div>
                      </ion-col>
                  </ion-row>
                  <hr *ngIf="quoteOne.length > 0">
              </div>
          </div>
          <div *ngIf="compareQuoteOneSpecifiedItems">
              <div *ngFor="let quoteOne of compareQuoteOneSpecifiedItems; let i = index">
                  <ion-row>
                      <ion-col size="12">
                          <div class="header">
                              <b>{{quoteRequestSpecifiedItems[i].description}}</b>
                          </div>
                      </ion-col>
                  </ion-row>
                  <ion-row>
                      <ion-col size="4">
                          <div class="space">
                              <b class="small">R{{quoteOne?.premium | number: '1.0-0'}}</b>
                          </div>
                      </ion-col>
                      <ion-col size="4">
                          <div class="space">
                              <b class="small b-medium">Premium</b>
                          </div>
                      </ion-col>
                      <ion-col size="4">
                          <div class="space">
                              <b class="small">R{{compareQuoteTwoSpecifiedItems[i]?.premium | number: '1.0-0'}}</b>
                          </div>
                      </ion-col>
                  </ion-row>
                  <ion-row>
                      <ion-col size="4">
                          <div class="space">
                              <b class="small">R{{quoteOne?.sum_insured | number: '1.0-0'}}</b>
                          </div>
                      </ion-col>
                      <ion-col size="4">
                          <div class="space">
                              <b class="small b-medium">Sum insured</b>
                          </div>
                      </ion-col>
                      <ion-col size="4">
                          <div class="space">
                              <b class="small">R{{compareQuoteTwoSpecifiedItems[i]?.sum_insured | number: '1.0-0'}}</b>
                          </div>
                      </ion-col>
                  </ion-row>
                  <hr *ngIf="quoteOne.length > 0">
              </div>
          </div>
      </div>
      <div *ngIf="activeTabType === 'personal_liability'">
          <ion-row>
              <ion-col size="12">
                  <div class="header">
                      <b>Personal liability</b>
                  </div>
              </ion-col>
          </ion-row>
          <ion-row>
              <ion-col size="4">
                  <div class="space">
                      <b *ngIf="quoteOneSelectedAsset?.personal_liability?.premium && quoteOneSelectedAsset?.personal_liability?.premium > 0" class="small">R{{quoteOneSelectedAsset?.personal_liability?.premium | number: '1.0-0'}}</b>
                    <b *ngIf="!quoteOneSelectedAsset?.personal_liability?.premium || !quoteOneSelectedAsset?.personal_liability?.premium > 0" class="small">N/A</b>
                  </div>
              </ion-col>
              <ion-col size="4">
                  <div class="space">
                      <b class="small b-medium">Premium</b>
                  </div>
              </ion-col>
              <ion-col size="4">
                  <div class="space">
                      <b *ngIf="quoteTwoSelectedAsset?.personal_liability?.premium && quoteTwoSelectedAsset?.personal_liability?.premium > 0" class="small">R{{quoteTwoSelectedAsset?.personal_liability?.premium | number: '1.0-0'}}</b>
                    <b *ngIf="!quoteTwoSelectedAsset?.personal_liability?.premium || !quoteTwoSelectedAsset?.personal_liability?.premium > 0" class="small">N/A</b>
                  </div>
              </ion-col>
          </ion-row>
          <ion-row>
              <ion-col size="4">
                  <div class="space">
                      <b *ngIf="quoteOneSelectedAsset?.personal_liability?.sum_insured && quoteOneSelectedAsset?.personal_liability?.sum_insured > 0" class="small">R{{quoteOneSelectedAsset?.personal_liability?.sum_insured | number: '1.0-0'}}</b>
                    <b *ngIf="!quoteOneSelectedAsset?.personal_liability?.sum_insured || !quoteOneSelectedAsset?.personal_liability?.sum_insured > 0" class="small">N/A</b>
                  </div>
              </ion-col>
              <ion-col size="4">
                  <div class="space">
                      <b class="small b-medium">Sum insured</b>
                  </div>
              </ion-col>
              <ion-col size="4">
                  <div class="space">
                      <b *ngIf="quoteTwoSelectedAsset?.personal_liability?.sum_insured && quoteTwoSelectedAsset?.personal_liability?.sum_insured > 0" class="small">R{{quoteTwoSelectedAsset?.personal_liability?.sum_insured | number: '1.0-0'}}</b>
                    <b *ngIf="!quoteTwoSelectedAsset?.personal_liability?.sum_insured || !quoteTwoSelectedAsset?.personal_liability?.sum_insured > 0" class="small">N/A</b>
                  </div>
              </ion-col>
          </ion-row>
          <ion-row>
              <ion-col size="12">
                  <div class="header">
                      <b>Sasria</b>
                  </div>
              </ion-col>
          </ion-row>
          <ion-row>
              <ion-col size="4">
                  <div class="space">
                      <b *ngIf="compareQuoteSasriaOne && compareQuoteSasriaOne > 0" class="small">R{{compareQuoteSasriaOne | number: '1.0-0'}}</b>
                    <b *ngIf="!compareQuoteSasriaOne || !compareQuoteSasriaOne > 0" class="small">N/A</b>
                  </div>
              </ion-col>
              <ion-col size="4">
                  <div class="space">
                      <b class="small b-medium">Premium</b>
                  </div>
              </ion-col>
              <ion-col size="4">
                  <div class="space">
                      <b *ngIf="compareQuoteSasriaTwo && compareQuoteSasriaTwo > 0" class="small">R{{compareQuoteSasriaTwo | number: '1.0-0'}}</b>
                    <b *ngIf="!compareQuoteSasriaTwo || !compareQuoteSasriaTwo > 0" class="small">N/A</b>
                  </div>
              </ion-col>
          </ion-row>
          <ion-row>
              <ion-col size="12">
                  <div class="header">
                      <b>Advice fee</b>
                  </div>
              </ion-col>
          </ion-row>
          <ion-row>
              <ion-col size="4">
                  <div class="space">
                      <b *ngIf="quoteOneSelectedAsset?.broker_fee && quoteOneSelectedAsset?.broker_fee > 0" class="small">R{{quoteOneSelectedAsset?.broker_fee | number: '1.0-0'}}</b>
                    <b *ngIf="!quoteOneSelectedAsset?.broker_fee || !quoteOneSelectedAsset?.broker_fee > 0" class="small">N/A</b>
                  </div>
              </ion-col>
              <ion-col size="4">
                  <div class="space">
                      <b class="small b-medium">Premium</b>
                  </div>
              </ion-col>
              <ion-col size="4">
                  <div class="space">
                      <b *ngIf="quoteTwoSelectedAsset?.broker_fee && quoteTwoSelectedAsset?.broker_fee > 0" class="small">R{{quoteTwoSelectedAsset?.broker_fee | number: '1.0-0'}}</b>
                    <b *ngIf="!quoteTwoSelectedAsset?.broker_fee || !quoteTwoSelectedAsset?.broker_fee > 0" class="small">N/A</b>
                  </div>
              </ion-col>
          </ion-row>
          <ion-row>
              <ion-col size="12">
                  <div class="header">
                      <b *ngIf="quoteOneSelectedAsset?.assistance_benefits || quoteTwoSelectedAsset?.assistance_benefits">{{quoteOneSelectedAsset?.assistance_benefits[0]?.description ? quoteOneSelectedAsset?.assistance_benefits[0]?.description : quoteTwoSelectedAsset?.assistance_benefits[0]?.description}}</b>
                      <b *ngIf="quoteTwoSelectedAsset?.assistance_benefits && !quoteOneSelectedAsset?.assistance_benefits">{{quoteTwoSelectedAsset?.assistance_benefits[0]?.description}}</b>
                  </div>
              </ion-col>
          </ion-row>
          <ion-row *ngIf="(quoteOneSelectedAsset?.assistance_benefits?.length > 0 || quoteTwoSelectedAsset?.assistance_benefits?.length > 0)">
              <ion-col size="4">
                  <div class="space">
                      <b class="small" *ngIf="quoteOneSelectedAsset?.assistance_benefits[0]?.premium && quoteOneSelectedAsset?.assistance_benefits[0]?.premium > 0">R{{quoteOneSelectedAsset?.assistance_benefits[0]?.premium | number: '1.0-0'}}</b>
                      <b class="small" *ngIf="!quoteOneSelectedAsset?.assistance_benefits[0]?.premium || !quoteOneSelectedAsset?.assistance_benefits[0]?.premium > 0">N/A</b>
                  </div>
              </ion-col>
              <ion-col size="4">
                  <div class="space">
                      <b class="small b-medium">Premium</b>
                  </div>
              </ion-col>
              <ion-col size="4">
                  <div class="space">
                      <b class="small" *ngIf="quoteTwoSelectedAsset?.assistance_benefits[0]?.premium && quoteTwoSelectedAsset?.assistance_benefits[0]?.premium > 0">R{{quoteTwoSelectedAsset?.assistance_benefits[0]?.premium | number: '1.0-0'}}</b>
                      <b class="small" *ngIf="!quoteTwoSelectedAsset?.assistance_benefits[0]?.premium || !quoteTwoSelectedAsset?.assistance_benefits[0]?.premium > 0">N/A</b>
                  </div>
              </ion-col>
          </ion-row>
          <ion-row>
              <ion-col size="12">
                  <div class="header">
                      <b *ngIf="quoteOneSelectedAsset?.assistance_benefits?.length > 1 || quoteTwoSelectedAsset?.assistance_benefits?.length > 1">{{quoteOneSelectedAsset?.assistance_benefits[1]?.description ? quoteOneSelectedAsset?.assistance_benefits[1]?.description : quoteTwoSelectedAsset?.assistance_benefits[1]?.description}}</b>
                      <b *ngIf="quoteTwoSelectedAsset?.assistance_benefits?.length > 1 && !quoteOneSelectedAsset?.assistance_benefits?.length > 1">{{quoteTwoSelectedAsset?.assistance_benefits[1]?.description}}</b>
                  </div>
              </ion-col>
          </ion-row>
          <ion-row *ngIf="(quoteOneSelectedAsset?.assistance_benefits?.length > 1 || quoteTwoSelectedAsset?.assistance_benefits?.length > 1)">
              <ion-col size="4">
                  <div class="space">
                      <b class="small" *ngIf="quoteOneSelectedAsset?.assistance_benefits[1]?.premium && quoteOneSelectedAsset?.assistance_benefits[1]?.premium > 0">R{{quoteOneSelectedAsset?.assistance_benefits[1]?.premium | number: '1.0-0'}}</b>
                      <b class="small" *ngIf="!quoteOneSelectedAsset?.assistance_benefits[1]?.premium || !quoteOneSelectedAsset?.assistance_benefits[1]?.premium > 0">N/A</b>
                  </div>
              </ion-col>
              <ion-col size="4">
                  <div class="space">
                      <b class="small b-medium">Premium</b>
                  </div>
              </ion-col>
              <ion-col size="4">
                  <div class="space">
                      <b class="small" *ngIf="quoteTwoSelectedAsset?.assistance_benefits[1]?.premium && quoteTwoSelectedAsset?.assistance_benefits[1]?.premium > 0">R{{quoteTwoSelectedAsset?.assistance_benefits[1]?.premium | number: '1.0-0'}}</b>
                      <b class="small" *ngIf="!quoteTwoSelectedAsset?.assistance_benefits[1]?.premium || !quoteTwoSelectedAsset?.assistance_benefits[1]?.premium > 0">N/A</b>
                  </div>
              </ion-col>
          </ion-row>
          <ion-row>
              <ion-col size="12">
                  <div class="header">
                      <b *ngIf="quoteOneSelectedAsset?.assistance_benefits?.length > 2 || quoteTwoSelectedAsset?.assistance_benefits?.length > 2">{{quoteOneSelectedAsset?.assistance_benefits[2]?.description ? quoteOneSelectedAsset?.assistance_benefits[2]?.description : quoteTwoSelectedAsset?.assistance_benefits[2]?.description}}</b>
                      <b *ngIf="quoteTwoSelectedAsset?.assistance_benefits?.length > 2 && !quoteOneSelectedAsset?.assistance_benefits?.length > 2">{{quoteTwoSelectedAsset?.assistance_benefits[2]?.description}}</b>
                  </div>
              </ion-col>
          </ion-row>
          <ion-row *ngIf="(quoteOneSelectedAsset?.assistance_benefits?.length > 2 || quoteTwoSelectedAsset?.assistance_benefits?.length > 2)">
              <ion-col size="4">
                  <div class="space">
                      <b class="small" *ngIf="quoteOneSelectedAsset?.assistance_benefits[2]?.premium && quoteOneSelectedAsset?.assistance_benefits[2]?.premium > 0">R{{quoteOneSelectedAsset?.assistance_benefits[2]?.premium | number: '1.0-0'}}</b>
                      <b class="small" *ngIf="!quoteOneSelectedAsset?.assistance_benefits[2]?.premium || !quoteOneSelectedAsset?.assistance_benefits[2]?.premium > 0">N/A</b>
                  </div>
              </ion-col>
              <ion-col size="4">
                  <div class="space">
                      <b class="small b-medium">Premium</b>
                  </div>
              </ion-col>
              <ion-col size="4">
                  <div class="space">
                      <b class="small" *ngIf="quoteTwoSelectedAsset?.assistance_benefits[2]?.premium && quoteTwoSelectedAsset?.assistance_benefits[2]?.premium > 0">R{{quoteTwoSelectedAsset?.assistance_benefits[2]?.premium | number: '1.0-0'}}</b>
                      <b class="small" *ngIf="!quoteTwoSelectedAsset?.assistance_benefits[2]?.premium || !quoteTwoSelectedAsset?.assistance_benefits[2]?.premium > 0">N/A</b>
                  </div>
              </ion-col>
          </ion-row>
          <hr *ngIf="quoteOneSelectedAsset?.assistance_benefits?.length > 2">
          <ion-row>
              <ion-col size="12">
                  <div class="header">
                      <b *ngIf="quoteOneSelectedAsset?.assistance_benefits?.length > 3 || quoteTwoSelectedAsset?.assistance_benefits?.length > 3">{{quoteOneSelectedAsset?.assistance_benefits[3]?.description ? quoteOneSelectedAsset?.assistance_benefits[3]?.description : quoteTwoSelectedAsset?.assistance_benefits[3]?.description}}</b>
                      <b *ngIf="quoteTwoSelectedAsset?.assistance_benefits?.length > 3 && !quoteOneSelectedAsset?.assistance_benefits?.length > 3">{{quoteTwoSelectedAsset?.assistance_benefits[3]?.description}}</b>
                  </div>
              </ion-col>
          </ion-row>
          <ion-row *ngIf="(quoteOneSelectedAsset?.assistance_benefits?.length > 3 || quoteTwoSelectedAsset?.assistance_benefits?.length > 3)">
              <ion-col size="4">
                  <div class="space">
                      <b class="small" *ngIf="quoteOneSelectedAsset?.assistance_benefits[3]?.premium && quoteOneSelectedAsset?.assistance_benefits[3]?.premium > 0">R{{quoteOneSelectedAsset?.assistance_benefits[3]?.premium | number: '1.0-0'}}</b>
                      <b class="small" *ngIf="!quoteOneSelectedAsset?.assistance_benefits[3]?.premium || !quoteOneSelectedAsset?.assistance_benefits[3]?.premium > 0">N/A</b>
                  </div>
              </ion-col>
              <ion-col size="4">
                  <div class="space">
                      <b class="small b-medium">Premium</b>
                  </div>
              </ion-col>
              <ion-col size="4">
                  <div class="space">
                      <b class="small" *ngIf="quoteTwoSelectedAsset?.assistance_benefits[3]?.premium && quoteTwoSelectedAsset?.assistance_benefits[3]?.premium > 0">R{{quoteTwoSelectedAsset?.assistance_benefits[3]?.premium | number: '1.0-0'}}</b>
                      <b class="small" *ngIf="!quoteTwoSelectedAsset?.assistance_benefits[3]?.premium || !quoteTwoSelectedAsset?.assistance_benefits[3]?.premium > 0">N/A</b>
                  </div>
              </ion-col>
          </ion-row>
      </div>
  </ion-grid>

  <div *ngIf="quoteComparisonDetailType === QuoteComparisonDetailType.OptionalCover" class="card-list">
      <div *ngIf="activeTabType === AssetType.Vehicles">
          <ion-grid *ngFor="let quoteOne of quoteOneSelectedAsset; let i = index">
              <ion-row>
                  <ion-col size="12">
                      <div class="header">
                          <b>{{quoteOne?.asset_details?.model}}</b>
                      </div>
                  </ion-col>
              </ion-row>
              <ion-row>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteOne?.extension_car_hire"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
                      <ion-icon *ngIf="!quoteOne?.extension_car_hire"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
                  <ion-col size="8">
                      <p class="description">Car hire</p>
                  </ion-col>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteTwoSelectedAsset[i]?.extension_car_hire"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
                      <ion-icon *ngIf="!quoteTwoSelectedAsset[i]?.extension_car_hire"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
              </ion-row>
              <ion-row>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteOne?.optional_credit_shortfall"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
                      <ion-icon *ngIf="!quoteOne?.optional_credit_shortfall"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
                  <ion-col size="8">
                      <p class="description">Credit shortfall</p>
                  </ion-col>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteTwoSelectedAsset[i]?.optional_credit_shortfall"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
                      <ion-icon *ngIf="!quoteTwoSelectedAsset[i]?.optional_credit_shortfall"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
              </ion-row>
              <ion-row>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteOne?.optional_4x4"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
                      <ion-icon *ngIf="!quoteOne?.optional_4x4"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
                  <ion-col size="8">
                      <p class="description">4x4 cover</p>
                  </ion-col>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteTwoSelectedAsset[i]?.optional_4x4"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
                      <ion-icon *ngIf="!quoteTwoSelectedAsset[i]?.optional_4x4"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
              </ion-row>
              <ion-row>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteOne?.extension_specified_accessories" src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary">
                      </ion-icon>
                      <ion-icon *ngIf="!quoteOne?.extension_specified_accessories"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
                  <ion-col size="8">
                      <p class="description">Specified accessories</p>
                  </ion-col>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteTwoSelectedAsset[i]?.extension_specified_accessories"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
                      <ion-icon *ngIf="!quoteTwoSelectedAsset[i]?.extension_specified_accessories"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
              </ion-row>
              <ion-row>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteOne?.optional_excess_waiver"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary">
                      </ion-icon>
                      <ion-icon *ngIf="!quoteOne?.optional_excess_waiver"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
                  <ion-col size="8">
                      <p class="description">Excess waiver</p>
                  </ion-col>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteTwoSelectedAsset[i]?.optional_excess_waiver"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
                      <ion-icon *ngIf="!quoteTwoSelectedAsset[i]?.optional_excess_waiver"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
              </ion-row>
              <ion-row>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteOne?.roadside_assistance"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary">
                      </ion-icon>
                      <ion-icon *ngIf="!quoteOne?.roadside_assistance"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
                  <ion-col size="8">
                      <p class="description">Roadside assistance</p>
                  </ion-col>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteTwoSelectedAsset[i]?.roadside_assistance"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
                      <ion-icon *ngIf="!quoteTwoSelectedAsset[i]?.roadside_assistance"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
              </ion-row>
              <hr *ngIf="quoteOne.length > 0">
          </ion-grid>
      </div>
      <div *ngIf="activeTabType === AssetType.Motorcycles">
          <ion-grid >
              <ion-row>
                  <ion-col size="12">
                      <div class="header">
                          <b>This asset type has no optional cover</b>
                      </div>
                  </ion-col>
              </ion-row>
          </ion-grid>
      </div>
      <div *ngIf="activeTabType === AssetType.Caravans">
          <ion-grid >
              <ion-row>
                  <ion-col size="12">
                      <div class="header">
                          <b>This asset type has no optional cover</b>
                      </div>
                  </ion-col>
              </ion-row>
          </ion-grid>
      </div>
      <div *ngIf="activeTabType === AssetType.Contents">
          <ion-grid *ngFor="let quoteOne of quoteOneSelectedAsset; let i = index" >
              <ion-row>
                  <ion-col size="12">
                      <div class="header">
                          <b>{{quoteOne?.asset_details?.address?.risk_address_line1}}</b>
                      </div>
                  </ion-col>
              </ion-row>
              <ion-row>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteOne?.extension_accidental_damage"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
                      <ion-icon *ngIf="!quoteOne?.extension_accidental_damage"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
                  <ion-col size="8">
                      <p class="description">Accidental damage</p>
                  </ion-col>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteTwoSelectedAsset[i]?.extension_accidental_damage"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
                      <ion-icon *ngIf="!quoteTwoSelectedAsset[i]?.extension_accidental_damage"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
              </ion-row>
              <ion-row>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteOne?.extension_mech_elec_electronic_damage"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
                      <ion-icon *ngIf="!quoteOne?.extension_mech_elec_electronic_damage"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
                  <ion-col size="8">
                      <p class="description">Mechanical / electric / electronic damage</p>
                  </ion-col>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteTwoSelectedAsset[i]?.extension_mech_elec_electronic_damage"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
                      <ion-icon *ngIf="!quoteTwoSelectedAsset[i]?.extension_mech_elec_electronic_damage"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
              </ion-row>
              <ion-row>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteOne?.extension_power_surge"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
                      <ion-icon *ngIf="!quoteOne?.extension_power_surge"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
                  <ion-col size="8">
                      <p class="description">Power surge</p>
                  </ion-col>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteTwoSelectedAsset[i]?.extension_power_surge"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
                      <ion-icon *ngIf="!quoteTwoSelectedAsset[i]?.extension_power_surge"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
              </ion-row>
              <ion-row>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteOne?.home_assist"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary">
                      </ion-icon>
                      <ion-icon *ngIf="!quoteOne?.home_assist"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
                  <ion-col size="8">
                      <p class="description">Home assist</p>
                  </ion-col>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteTwoSelectedAsset[i]?.home_assist"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
                      <ion-icon *ngIf="!quoteTwoSelectedAsset[i]?.home_assist"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
              </ion-row>
              <ion-row>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteOne?.optional_subsidence"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary">
                      </ion-icon>
                      <ion-icon *ngIf="!quoteOne?.optional_subsidence"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
                  <ion-col size="8">
                      <p class="description">Subsidence and landslip extension</p>
                  </ion-col>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteTwoSelectedAsset[i]?.optional_subsidence"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
                      <ion-icon *ngIf="!quoteTwoSelectedAsset[i]?.optional_subsidence"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
              </ion-row>
              <ion-row>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteOne?.optional_baboon_cover"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary">
                      </ion-icon>
                      <ion-icon *ngIf="!quoteOne?.optional_baboon_cover"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
                  <ion-col size="8">
                      <p class="description">Baboon and monkey</p>
                  </ion-col>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteTwoSelectedAsset[i]?.optional_baboon_cover"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
                      <ion-icon *ngIf="!quoteTwoSelectedAsset[i]?.optional_baboon_cover"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
              </ion-row>
              <hr *ngIf="quoteOne.length > 0">
          </ion-grid>
      </div>
      <div *ngIf="activeTabType === AssetType.Buildings">
          <ion-grid *ngFor="let quoteOne of quoteOneSelectedAsset; let i = index">
              <ion-row>
                  <ion-col size="12">
                      <div class="header">
                          <b>{{quoteOne?.asset_details?.address?.risk_address_line1}}</b>
                      </div>
                  </ion-col>
              </ion-row>
              <ion-row>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteOne?.extension_accidental_damage_to_machinery"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
                      <ion-icon *ngIf="!quoteOne?.extension_accidental_damage_to_machinery"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
                  <ion-col size="8">
                      <p class="description">Accidental damage to machinery</p>
                  </ion-col>
                  <ion-col size="2">
                      <ion-icon
                          *ngIf="quoteTwoSelectedAsset[i]?.extension_accidental_damage_to_machinery"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
                      <ion-icon
                          *ngIf="!quoteTwoSelectedAsset[i]?.extension_accidental_damage_to_machinery"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
              </ion-row>
              <ion-row>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteOne?.geyser"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
                      <ion-icon *ngIf="!quoteOne?.geyser"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
                  <ion-col size="8">
                      <p class="description">Geyser maintenance</p>
                  </ion-col>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteTwoSelectedAsset[i]?.geyser"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
                      <ion-icon *ngIf="!quoteTwoSelectedAsset[i]?.geyser"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
              </ion-row>
              <ion-row>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteOne?.extension_power_surge"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
                      <ion-icon *ngIf="!quoteOne?.extension_power_surge"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
                  <ion-col size="8">
                      <p class="description">Power surge</p>
                  </ion-col>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteTwoSelectedAsset[i]?.extension_power_surge"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
                      <ion-icon *ngIf="!quoteTwoSelectedAsset[i]?.extension_power_surge"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
              </ion-row>
              <ion-row>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteOne?.home_assist"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary">
                      </ion-icon>
                      <ion-icon *ngIf="!quoteOne?.home_assist"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
                  <ion-col size="8">
                      <p class="description">Home assist</p>
                  </ion-col>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteTwoSelectedAsset[i]?.home_assist"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
                      <ion-icon *ngIf="!quoteTwoSelectedAsset[i]?.home_assist"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
              </ion-row>
              <ion-row>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteOne?.optional_subsidence"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary">
                      </ion-icon>
                      <ion-icon *ngIf="!quoteOne?.optional_subsidence"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
                  <ion-col size="8">
                      <p class="description">Subsidence and landslip extension</p>
                  </ion-col>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteTwoSelectedAsset[i]?.optional_subsidence"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
                      <ion-icon *ngIf="!quoteTwoSelectedAsset[i]?.optional_subsidence"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
              </ion-row>
              <ion-row>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteOne?.optional_baboon_cover"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary">
                      </ion-icon>
                      <ion-icon *ngIf="!quoteOne?.optional_baboon_cover"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
                  <ion-col size="8">
                      <p class="description">Baboon and monkey</p>
                  </ion-col>
                  <ion-col size="2">
                      <ion-icon *ngIf="quoteTwoSelectedAsset[i]?.optional_baboon_cover"
                          src="assets/icon/genetic-icons/tick-primaryX2.svg" class="icon-fill-primary"></ion-icon>
                      <ion-icon *ngIf="!quoteTwoSelectedAsset[i]?.optional_baboon_cover"
                          src="assets/icon/genetic-icons/close-greyX2.svg" class="icon-fill-dark-grey"></ion-icon>
                  </ion-col>
              </ion-row>
              <hr *ngIf="quoteOne.length > 0">
          </ion-grid>
      </div>
      <div *ngIf="activeTabType === AssetType.AllRisks">
          <ion-grid>
              <ion-row>
                  <ion-col size="12">
                      <div class="header">
                          <b>This asset type has no optional cover</b>
                      </div>
                  </ion-col>
              </ion-row>
          </ion-grid>
      </div>
      <div *ngIf="activeTabType === 'personal_liability'">
          <ion-grid>
              <ion-row>
                  <ion-col size="12">
                      <div class="header">
                          <b>This asset type has no optional cover</b>
                      </div>
                  </ion-col>
              </ion-row>
          </ion-grid>
      </div>
  </div>

  <div *ngIf="quoteComparisonDetailType === QuoteComparisonDetailType.SpecialConditions" class="card-list">
      <div *ngFor="let specialCondition of selectedAssetSpecialConditions">
          <div *ngIf="specialCondition?.conditions" class="special-conditions-image">
              <img src="assets/images/insurer-logos/center-color/card-{{ specialCondition?.insurer | lowercase }}-center.svg" />
          </div>
          <div *ngFor="let condition of specialCondition.conditions">
              <ul><li>{{condition}}</li></ul>
          </div>
          <hr *ngIf="specialCondition?.conditions" class="special-hr" />
      </div>
  </div>
</div>