import { Injectable } from '@angular/core';
import {
  IAPIQuoteConvertResponse,
  IBankBranches,
  IBuilding,
  IContent,
  IQuestion,
  IQuote,
  IQuoteRequest,
  IResponse,
  ISchedule,
  ISpecialConditionAssets,
  SharedConfig,
  UnderwritingAnswer,
  SharedFormulaService,
  IInsurers,
  IAddresses,
  IBankingDetails,
  QuoteRequestType,
  IQuoteCreateResponse,
  IBank,
  AssetType,
  ICasaDetails,
  IQuoteConvertResponse,
  QuoteRequestStatusType,
  QuestionType,
  InputType,
  IAlertBrokerOfEndorsement,
  IResponseMotorVehiclesSnakeCase,
  QuestionDependencyType,
} from '@takectrl-multiapp/svcs-share-config';
import { APIAssetService, APIQuoteScheduleService, APIUserService } from '@takectrl-multiapp/svcs-ctrl-api';
import validate from 'validate-id-za';
import { BUSNAssetService } from './busn-asset.service';
import { Storage } from '@capacitor/storage';
import { firstValueFrom, take } from 'rxjs';
import { ICtrlResponse } from 'libs/svcs-ctrl-api/src/lib/interfaces/api.interface';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { SharedConfigService } from 'libs/svcs-share-config/src/lib/services/shared-config.service';
import { AlertController } from '@ionic/angular';
@Injectable({
  providedIn: 'root',
})
export class BUSNQuoteScheduleService {
  errorDescription: string;
  constructor(
    private readonly busnAssetServiceService: BUSNAssetService,
    private readonly sharedConfig: SharedConfig,
    private readonly alertController: AlertController,
    private readonly apiQuoteScheduleService: APIQuoteScheduleService,
    private readonly busnThirdPartyService: BUSNThirdPartyService,
    private readonly sharedFormulaService: SharedFormulaService,
    private readonly apiUserService: APIUserService,
    private readonly apiAssetService: APIAssetService,
    private readonly sharedConfigService: SharedConfigService
  ) {}

  async getAllActiveSchedules(): Promise<Array<ISchedule>> {
    let schedulesAll = (await firstValueFrom(this.apiQuoteScheduleService.getAllSchedules())).results;
    if (!schedulesAll) schedulesAll = [];
    const activeSchedulesWithAssets = await this.apiQuoteScheduleService.setScheduleLinkedAssets(
      schedulesAll.filter((x) => x.status === 'ACTIVE' || x.status === 'PENDING_ENDORSE')
    );
    return activeSchedulesWithAssets;
  }

  // @Josh, add the type in here
  async checkForUpdatedPremiums(): Promise<ICtrlResponse<unknown>> {
    return await firstValueFrom(this.apiQuoteScheduleService.premiumCheck());
  }

  async submitQuoteRequest(): Promise<IResponse> {
    this.busnThirdPartyService.logEvent('action_quote_requested', { action: 'requested' });
    SharedConfig.acknowledgedQuoteRequestError = false;
    try {
      this.sharedConfig.quoteRequest.needs_analysis_completed = new Date().getTime();
      this.sharedConfig.quoteRequest.status = QuoteRequestStatusType.Submitted;
      await this.updateQuoteRequest(this.sharedConfig.quoteRequest);

      let quoteCreateResponse: ICtrlResponse<IQuoteCreateResponse> = await firstValueFrom(
        this.apiQuoteScheduleService.initiateQuoteRequestProcessing(this.sharedConfig.quoteRequest.id)
      );
      if (quoteCreateResponse.success === true) {
        return {
          success: true,
        };
      } else {
        // completely failed
        this.sharedConfig.quoteRequest.status = QuoteRequestStatusType.Failed;
        this.updateQuoteRequest(this.sharedConfig.quoteRequest);
        this.busnThirdPartyService.logEvent('business_quote_request_failed', {
          uid: SharedConfig.firebaseUserKey,
          name: this.sharedConfig.user.personal_details.name,
          email: this.sharedConfig.user.personal_details.email,
          timestamp: new Date().getTime(),
          quote_request_id: this.sharedConfig.quoteRequest.id,
        });
        return {
          success: false,
        };
      }
    } catch (error) {
      return {
        message: error.message,
        success: false,
      };
    }
  }

  async submitEndorsementRequest(): Promise<IResponse> {
    this.sharedConfig.setShowCustomLoader(true, 'submitEndorsementRequest');
    this.busnThirdPartyService.logEvent('action_endorsement', { action: 'requested' });
    SharedConfig.acknowledgedQuoteRequestError = false;
    try {
      this.sharedConfig.quoteRequest.status = QuoteRequestStatusType.Submitted;
      await this.updateQuoteRequest(this.sharedConfig.quoteRequest);

      let quoteCreateResponse: IQuoteCreateResponse;

      quoteCreateResponse = (
        await firstValueFrom(this.apiQuoteScheduleService.initiateEndorsementRequestProcessing(this.sharedConfig.quoteRequest.id))
      ).results;

      if (quoteCreateResponse.success === true) {
        this.sharedConfig.setShowCustomLoader(false, 'submitEndorsementRequest');
        return {
          success: true,
        };
      } else {
        // completely failed
        this.sharedConfig.quoteRequest.status = QuoteRequestStatusType.Failed;
        await this.updateQuoteRequest(this.sharedConfig.quoteRequest);
        this.busnThirdPartyService.logEvent('business_endorsement_request_failed', {
          uid: SharedConfig.firebaseUserKey,
          name: this.sharedConfig.user.personal_details.name,
          email: this.sharedConfig.user.personal_details.email,
          timestamp: new Date().getTime(),
          quote_request_id: this.sharedConfig.quoteRequest.id,
        });
        this.sharedConfig.setShowCustomLoader(false, 'submitEndorsementRequest');
        return {
          success: false,
          message: quoteCreateResponse.message,
        };
      }
    } catch (error) {
      return {
        message: error.message,
        success: false,
      };
    }
  }

  async endorsementUpdateAlertBroker(alertBrokerPayload: IAlertBrokerOfEndorsement): Promise<ICtrlResponse<IAlertBrokerOfEndorsement>> {
    return await firstValueFrom(this.apiQuoteScheduleService.endorsementUpdateAlertBroker(alertBrokerPayload));
  }

  async getQuoteRequestById(userId: string, quote_id: string): Promise<IQuoteRequest> {
    if (!userId) console.warn('getQuoteRequestById(userId: string, quote_id: string): Promise<DatabaseSnapshot> call will fail as userId missing');
    return (await firstValueFrom(this.apiQuoteScheduleService.getQuoteRequestById(quote_id))).results;
  }

  async getQuoteRequests(): Promise<Array<IQuoteRequest>> {
    return (await firstValueFrom(this.apiQuoteScheduleService.getQuoteRequests())).results;
  }

  async underwriting_questions_completed(quoteRequest: IQuoteRequest): Promise<void> {
    await firstValueFrom(this.apiQuoteScheduleService.setUnderwritingQuestionsCompleted(quoteRequest));
  }

  async optionals_completed(quoteRequest: IQuoteRequest): Promise<void> {
    await firstValueFrom(this.apiQuoteScheduleService.setOptionalsCompleted(quoteRequest));
  }

  async confirmation_completed(quoteRequest: IQuoteRequest): Promise<void> {
    await firstValueFrom(this.apiQuoteScheduleService.setConfirmationCompleted(quoteRequest));
  }

  async saveQuoteQuestions(questions: Array<IQuestion>, asset: any) {
    let address: IAddresses = {};
    let dayTime = false;
    let overnight = false;
    if (questions[0]?.underwriting_answers[0]?.object_name)
      switch (questions[0].underwriting_answers[0].object_name) {
        case AssetType.AllRisks:
          for (const question of questions) {
            if (question.dependency && question.dependency.dependency_type === QuestionDependencyType.parent) {
              const childDependencyQuestions = SharedConfig.underwritingQuestionsAll.filter(
                (x) =>
                  x.dependency &&
                  x.dependency.dependency_key === question.dependency.dependency_key &&
                  x.dependency.dependency_type === QuestionDependencyType.child &&
                  x.underwriting_answers[0].object_name === AssetType.AllRisks
              );
              if (childDependencyQuestions && childDependencyQuestions.length > 0 && question.underwriting_answers[0].object_answer === false) {
                for (const childQuestion of childDependencyQuestions) {
                  for (const underwritingAnswer of childQuestion.underwriting_answers) {
                    SharedConfig.selectedAssetAllRiskItem[underwritingAnswer.object_field] = null;
                  }
                }
              }
            }
            for (const underwritingAnswer of question.underwriting_answers) {
              SharedConfig.selectedAssetAllRiskItem[underwritingAnswer.object_field] = underwritingAnswer.object_answer;
            }
          }

          await this.busnAssetServiceService.updateAllRiskItem(SharedConfig.selectedAssetAllRiskItem);
          this.updateQuoteRequest(this.sharedConfig.quoteRequest);
          break;

        case AssetType.Buildings:
          for (const question of questions) {
            if (question.dependency && question.dependency.dependency_type === QuestionDependencyType.parent) {
              const childDependencyQuestions = SharedConfig.underwritingQuestionsAll.filter(
                (x) =>
                  x.dependency &&
                  x.dependency.dependency_key === question.dependency.dependency_key &&
                  x.dependency.dependency_type === QuestionDependencyType.child &&
                  x.underwriting_answers[0].object_name === AssetType.Buildings
              );
              if (childDependencyQuestions && childDependencyQuestions.length > 0 && question.underwriting_answers[0].object_answer === false) {
                for (const childQuestion of childDependencyQuestions) {
                  for (const underwritingAnswer of childQuestion.underwriting_answers) {
                    SharedConfig.selectedAssetBuilding[underwritingAnswer.object_field] = null;
                  }
                }
              }
            }

            for (const underwritingAnswer of question.underwriting_answers) {
              const isAddressField =
                underwritingAnswer.object_field.indexOf('address') >= 0 || underwritingAnswer.object_field.indexOf('postal') >= 0;

              if (isAddressField) {
                address.user_id = this.sharedConfig.user.id;
                const fieldName = underwritingAnswer.object_field.replace('daytime', 'risk').replace('overnight', 'risk');
                if (fieldName === 'risk_address_code') {
                  underwritingAnswer.object_field = 'postal_code';
                } else if (fieldName === 'risk_postal_code_id') {
                  underwritingAnswer.object_field = 'risk_address_code_id';
                } else if (fieldName === 'risk_postal_code_id_mnfg') {
                  underwritingAnswer.object_field = 'risk_address_code_id_mnfg';
                } else if (fieldName === 'risk_postal_code_id_sa02') {
                  underwritingAnswer.object_field = 'risk_address_code_id_sa02';
                } else if (fieldName === 'risk_address_line1') {
                  underwritingAnswer.object_field = 'risk_address_line1';
                } else if (fieldName === 'risk_address_line2') {
                  underwritingAnswer.object_field = 'risk_address_line2';
                }
                address[underwritingAnswer.object_field] = underwritingAnswer.object_answer;
              } else {
                SharedConfig.selectedAssetBuilding[underwritingAnswer.object_field] = underwritingAnswer.object_answer;
              }
            }

            const isAddressField =
              question.underwriting_answers[0].object_field.indexOf('address') >= 0 ||
              question.underwriting_answers[0].object_field.indexOf('postal') >= 0;
            if (isAddressField) {
              const addressRequest: IAddresses = await this.setAddress(address);
              SharedConfig.selectedAssetBuilding.address_id = addressRequest.id;
              SharedConfig.selectedAssetBuilding.address = addressRequest;
              address = {};
            }
          }
          await this.busnAssetServiceService.updateBuilding(SharedConfig.selectedAssetBuilding);
          this.updateQuoteRequest(this.sharedConfig.quoteRequest);
          break;

        case AssetType.Caravans:
          for (const question of questions) {
            if (question.dependency && question.dependency.dependency_type === QuestionDependencyType.parent) {
              const childDependencyQuestions = SharedConfig.underwritingQuestionsAll.filter(
                (x) =>
                  x.dependency &&
                  x.dependency.dependency_key === question.dependency.dependency_key &&
                  x.dependency.dependency_type === QuestionDependencyType.child &&
                  x.underwriting_answers[0].object_name === AssetType.Caravans
              );
              if (childDependencyQuestions && childDependencyQuestions.length > 0 && question.underwriting_answers[0].object_answer === false) {
                for (const childQuestion of childDependencyQuestions) {
                  for (const underwritingAnswer of childQuestion.underwriting_answers) {
                    SharedConfig.selectedAssetCaravan[underwritingAnswer.object_field] = null;
                  }
                }
              }
            }
            let addressRequest: IAddresses = {};
            dayTime = false;
            overnight = false;
            for (const underwritingAnswer of question.underwriting_answers) {
              const isAddressField =
                underwritingAnswer.object_field.indexOf('address') >= 0 || underwritingAnswer.object_field.indexOf('postal') >= 0;
              if (isAddressField) {
                if (underwritingAnswer.object_field.indexOf('daytime') >= 0) {
                  dayTime = true;
                  overnight = false;
                }
                if (underwritingAnswer.object_field.indexOf('overnight') >= 0) {
                  overnight = true;
                  dayTime = false;
                }
                address.user_id = this.sharedConfig.user.id;
                const fieldName = underwritingAnswer.object_field.replace('daytime', 'risk').replace('overnight', 'risk');
                if (fieldName === 'risk_postal_code') {
                  underwritingAnswer.object_field = 'postal_code';
                } else if (fieldName === 'risk_postal_code_id') {
                  underwritingAnswer.object_field = 'risk_address_code_id';
                } else if (fieldName === 'risk_postal_code_id_mnfg') {
                  underwritingAnswer.object_field = 'risk_address_code_id_mnfg';
                } else if (fieldName === 'risk_postal_code_id_sa02') {
                  underwritingAnswer.object_field = 'risk_address_code_id_sa02';
                } else if (fieldName === 'risk_address_line1') {
                  underwritingAnswer.object_field = 'risk_address_line1';
                } else if (fieldName === 'risk_address_line2') {
                  underwritingAnswer.object_field = 'risk_address_line2';
                }
                address[underwritingAnswer.object_field] = underwritingAnswer.object_answer;
              } else {
                overnight = false;
                dayTime = false;
                SharedConfig.selectedAssetCaravan[underwritingAnswer.object_field] = underwritingAnswer.object_answer;
              }
            }
            if (dayTime) {
              addressRequest = await this.setAddress(address);
              SharedConfig.selectedAssetCaravan.daytime_address_id = addressRequest.id;
              SharedConfig.selectedAssetCaravan.daytime_address = addressRequest;
            }
            if (overnight) {
              addressRequest = await this.setAddress(address);
              SharedConfig.selectedAssetCaravan.overnight_address_id = addressRequest.id;
              SharedConfig.selectedAssetCaravan.overnight_address = addressRequest;
            }
          }
          await this.busnAssetServiceService.updateCaravan(SharedConfig.selectedAssetCaravan);
          this.updateQuoteRequest(this.sharedConfig.quoteRequest);
          break;

        case AssetType.Contents:
          for (const question of questions) {
            if (question.dependency && question.dependency.dependency_type === QuestionDependencyType.parent) {
              const childDependencyQuestions = SharedConfig.underwritingQuestionsAll.filter(
                (x) =>
                  x.dependency &&
                  x.dependency.dependency_key === question.dependency.dependency_key &&
                  x.dependency.dependency_type === QuestionDependencyType.child &&
                  x.underwriting_answers[0].object_name === AssetType.Contents
              );
              if (childDependencyQuestions && childDependencyQuestions.length > 0 && question.underwriting_answers[0].object_answer === false) {
                for (const childQuestion of childDependencyQuestions) {
                  for (const underwritingAnswer of childQuestion.underwriting_answers) {
                    SharedConfig.selectedAssetContent[underwritingAnswer.object_field] = null;
                  }
                }
              }
            }
            const addressRequest: IAddresses = {};
            for (const underwritingAnswer of question.underwriting_answers) {
              const isAddressField =
                underwritingAnswer.object_field.indexOf('address') >= 0 || underwritingAnswer.object_field.indexOf('postal') >= 0;

              if (isAddressField) {
                address.user_id = this.sharedConfig.user.id;
                const fieldName = underwritingAnswer.object_field.replace('daytime', 'risk').replace('overnight', 'risk');
                if (fieldName === 'risk_address_code') {
                  underwritingAnswer.object_field = 'postal_code';
                } else if (fieldName === 'risk_postal_code_id') {
                  underwritingAnswer.object_field = 'risk_address_code_id';
                } else if (fieldName === 'risk_postal_code_id_mnfg') {
                  underwritingAnswer.object_field = 'risk_address_code_id_mnfg';
                } else if (fieldName === 'risk_postal_code_id_sa02') {
                  underwritingAnswer.object_field = 'risk_address_code_id_sa02';
                } else if (fieldName === 'risk_address_line1') {
                  underwritingAnswer.object_field = 'risk_address_line1';
                } else if (fieldName === 'risk_address_line2') {
                  underwritingAnswer.object_field = 'risk_address_line2';
                }
                address[underwritingAnswer.object_field] = underwritingAnswer.object_answer;
              } else {
                SharedConfig.selectedAssetContent[underwritingAnswer.object_field] = underwritingAnswer.object_answer;
              }
            }

            const isAddressField =
              question.underwriting_answers[0].object_field.indexOf('address') >= 0 ||
              question.underwriting_answers[0].object_field.indexOf('postal') >= 0;
            if (isAddressField) {
              const addressRequest: IAddresses = await this.setAddress(address);
              SharedConfig.selectedAssetContent.address_id = addressRequest.id;
              SharedConfig.selectedAssetContent.address = addressRequest;
              address = {};
            }
          }
          await this.busnAssetServiceService.updateContent(SharedConfig.selectedAssetContent);
          this.updateQuoteRequest(this.sharedConfig.quoteRequest);
          break;

        case AssetType.Motorcycles:
          for (const question of questions) {
            if (question.dependency && question.dependency.dependency_type === QuestionDependencyType.parent) {
              const childDependencyQuestions = SharedConfig.underwritingQuestionsAll.filter(
                (x) =>
                  x.dependency &&
                  x.dependency.dependency_key === question.dependency.dependency_key &&
                  x.dependency.dependency_type === QuestionDependencyType.child &&
                  x.underwriting_answers[0].object_name === AssetType.Motorcycles
              );
              if (childDependencyQuestions && childDependencyQuestions.length > 0 && question.underwriting_answers[0].object_answer === false) {
                for (const childQuestion of childDependencyQuestions) {
                  for (const underwritingAnswer of childQuestion.underwriting_answers) {
                    SharedConfig.selectedAssetMotorcycle[underwritingAnswer.object_field] = null;
                  }
                }
              }
            }
            let addressRequest: IAddresses = {};
            dayTime = false;
            overnight = false;
            for (const underwritingAnswer of question.underwriting_answers) {
              const isAddressField =
                underwritingAnswer.object_field.indexOf('address') >= 0 || underwritingAnswer.object_field.indexOf('postal') >= 0;
              if (isAddressField) {
                if (underwritingAnswer.object_field.indexOf('daytime') >= 0) {
                  dayTime = true;
                  overnight = false;
                }
                if (underwritingAnswer.object_field.indexOf('overnight') >= 0) {
                  overnight = true;
                  dayTime = false;
                }
                address.user_id = this.sharedConfig.user.id;
                const fieldName = underwritingAnswer.object_field.replace('daytime', 'risk').replace('overnight', 'risk');
                if (fieldName === 'risk_postal_code') {
                  underwritingAnswer.object_field = 'postal_code';
                } else if (fieldName === 'risk_postal_code_id') {
                  underwritingAnswer.object_field = 'risk_address_code_id';
                } else if (fieldName === 'risk_postal_code_id_mnfg') {
                  underwritingAnswer.object_field = 'risk_address_code_id_mnfg';
                } else if (fieldName === 'risk_postal_code_id_sa02') {
                  underwritingAnswer.object_field = 'risk_address_code_id_sa02';
                } else if (fieldName === 'risk_address_line1') {
                  underwritingAnswer.object_field = 'risk_address_line1';
                } else if (fieldName === 'risk_address_line2') {
                  underwritingAnswer.object_field = 'risk_address_line2';
                }
                address[underwritingAnswer.object_field] = underwritingAnswer.object_answer;
              } else {
                overnight = false;
                dayTime = false;
                SharedConfig.selectedAssetMotorcycle[underwritingAnswer.object_field] = underwritingAnswer.object_answer;
              }
            }
            if (dayTime) {
              addressRequest = await this.setAddress(address);
              SharedConfig.selectedAssetMotorcycle.daytime_address_id = addressRequest.id;
              SharedConfig.selectedAssetMotorcycle.daytime_address = addressRequest;
            }
            if (overnight) {
              addressRequest = await this.setAddress(address);
              SharedConfig.selectedAssetMotorcycle.overnight_address_id = addressRequest.id;
              SharedConfig.selectedAssetMotorcycle.overnight_address = addressRequest;
            }
          }
          await this.busnAssetServiceService.updateMotorcycle(SharedConfig.selectedAssetMotorcycle);
          this.updateQuoteRequest(this.sharedConfig.quoteRequest);
          break;

        case AssetType.SpecifiedItems:
          const specifiedItemsAssetIndex = this.sharedConfig.quoteRequest.assets.specified_items.findIndex((x) => x.id === asset.id);
          for (const question of questions) {
            if (question.dependency && question.dependency.dependency_type === QuestionDependencyType.parent) {
              const childDependencyQuestions = SharedConfig.underwritingQuestionsAll.filter(
                (x) =>
                  x.dependency &&
                  x.dependency.dependency_key === question.dependency.dependency_key &&
                  x.dependency.dependency_type === QuestionDependencyType.child &&
                  x.underwriting_answers[0].object_name === AssetType.SpecifiedItems
              );
              if (childDependencyQuestions && childDependencyQuestions.length > 0 && question.underwriting_answers[0].object_answer === false) {
                for (const childQuestion of childDependencyQuestions) {
                  for (const underwritingAnswer of childQuestion.underwriting_answers) {
                    this.sharedConfig.quoteRequest.assets.specified_items[specifiedItemsAssetIndex][underwritingAnswer.object_field] = null;
                    SharedConfig.selectedAssetSpecifiedItem[underwritingAnswer.object_field] = null;
                  }
                }
              }
            }
            for (const underwritingAnswer of question.underwriting_answers) {
              this.sharedConfig.quoteRequest.assets.specified_items[specifiedItemsAssetIndex][underwritingAnswer.object_field] =
                underwritingAnswer.object_answer;
              SharedConfig.selectedAssetSpecifiedItem[underwritingAnswer.object_field] = underwritingAnswer.object_answer;
            }
          }
          await this.busnAssetServiceService.updateSpecifiedItem(SharedConfig.selectedAssetSpecifiedItem);
          this.updateQuoteRequest(this.sharedConfig.quoteRequest);
          break;

        case AssetType.Vehicles:
          const QuoteAssetIndex = this.sharedConfig.quoteRequest.assets.vehicles.findIndex((x) => x.id === asset.id);
          for (const question of questions) {
            // This sets the  QuestionDependencyType.child object_answer to null and does the PATCH to backend. (API will return the child if you have answered it already and does not delete it.)
            if (question.dependency && question.dependency.dependency_type === QuestionDependencyType.parent) {
              const childDependencyQuestions = SharedConfig.underwritingQuestionsAll.filter(
                (x) =>
                  x.dependency &&
                  x.dependency.dependency_key === question.dependency.dependency_key &&
                  x.dependency.dependency_type === QuestionDependencyType.child &&
                  x.underwriting_answers[0].object_name === AssetType.Vehicles
              );
              if (childDependencyQuestions && childDependencyQuestions.length > 0 && question.underwriting_answers[0].object_answer === false) {
                for (const childQuestion of childDependencyQuestions) {
                  for (const underwritingAnswer of childQuestion.underwriting_answers) {
                    this.sharedConfig.quoteRequest.assets.vehicles[QuoteAssetIndex][underwritingAnswer.object_field] = null;
                    SharedConfig.selectedAssetVehicle[underwritingAnswer.object_field] = null;
                  }
                }
              }
            }
            dayTime = false;
            overnight = false;
            for (const underwritingAnswer of question.underwriting_answers) {
              const isAddressField =
                underwritingAnswer.object_field.indexOf('address') >= 0 || underwritingAnswer.object_field.indexOf('postal') >= 0;
              if (isAddressField) {
                if (underwritingAnswer.object_field.indexOf('daytime') >= 0) {
                  dayTime = true;
                  overnight = false;
                }
                if (underwritingAnswer.object_field.indexOf('overnight') >= 0) {
                  overnight = true;
                  dayTime = false;
                }
                address.user_id = this.sharedConfig.user.id;
                const fieldName = underwritingAnswer.object_field.replace('daytime', 'risk').replace('overnight', 'risk');
                if (fieldName === 'risk_postal_code') {
                  underwritingAnswer.object_field = 'postal_code';
                } else if (fieldName === 'risk_postal_code_id') {
                  underwritingAnswer.object_field = 'risk_address_code_id';
                } else if (fieldName === 'risk_postal_code_id_mnfg') {
                  underwritingAnswer.object_field = 'risk_address_code_id_mnfg';
                } else if (fieldName === 'risk_postal_code_id_sa02') {
                  underwritingAnswer.object_field = 'risk_address_code_id_sa02';
                } else if (fieldName === 'risk_address_line1') {
                  underwritingAnswer.object_field = 'risk_address_line1';
                } else if (fieldName === 'risk_address_line2') {
                  underwritingAnswer.object_field = 'risk_address_line2';
                }
                address[underwritingAnswer.object_field] = underwritingAnswer.object_answer;
              } else {
                this.sharedConfig.quoteRequest.assets.vehicles[QuoteAssetIndex][underwritingAnswer.object_field] = underwritingAnswer.object_answer;
                SharedConfig.selectedAssetVehicle[underwritingAnswer.object_field] = underwritingAnswer.object_answer;
              }
            }

            const isAddressField =
              question.underwriting_answers[0].object_field.indexOf('address') >= 0 ||
              question.underwriting_answers[0].object_field.indexOf('postal') >= 0;
            if (isAddressField) {
              let addressRequest: IAddresses = {};

              if (dayTime) {
                addressRequest = await this.setAddress(address);
                SharedConfig.selectedAssetVehicle.daytime_address_id = addressRequest.id;
                SharedConfig.selectedAssetVehicle.daytime_address = addressRequest;
                address = {};
              }
              if (overnight) {
                addressRequest = await this.setAddress(address);
                SharedConfig.selectedAssetVehicle.overnight_address_id = addressRequest.id;
                SharedConfig.selectedAssetVehicle.overnight_address = addressRequest;
                address = {};
              }
            }
          }

          await this.busnAssetServiceService.updateVehicle(SharedConfig.selectedAssetVehicle);
          this.updateQuoteRequest(this.sharedConfig.quoteRequest);
          break;

        //users.personal_details and users.insurance_history
        default:
          for (const question of questions) {
            if (question.dependency && question.dependency.dependency_type === QuestionDependencyType.parent) {
              const childDependencyQuestions = SharedConfig.underwritingQuestionsAll.filter(
                (x) =>
                  x.dependency &&
                  x.dependency.dependency_key === question.dependency.dependency_key &&
                  x.dependency.dependency_type === QuestionDependencyType.child &&
                  x.underwriting_answers[0].object_name === AssetType.Buildings
              );
              if (childDependencyQuestions && childDependencyQuestions.length > 0 && question.underwriting_answers[0].object_answer === false) {
                for (const childQuestion of childDependencyQuestions) {
                  for (const underwritingAnswer of childQuestion.underwriting_answers) {
                    if (question.underwriting_answers[0].object_name === AssetType.UserPersonalDetails) {
                      SharedConfig.user.personal_details[underwritingAnswer.object_field] = null;
                    } else {
                      SharedConfig.user.insurance_history[underwritingAnswer.object_field] = null;
                    }
                  }
                }
              }
            }

            for (const underwritingAnswer of question.underwriting_answers) {
              const isAddressField =
                underwritingAnswer.object_field.indexOf('address') >= 0 || underwritingAnswer.object_field.indexOf('postal') >= 0;

              if (isAddressField) {
                address.user_id = this.sharedConfig.user.id;
                address[underwritingAnswer.object_field] = underwritingAnswer.object_answer;
              } else {
                if (question.underwriting_answers[0].object_name === AssetType.UserPersonalDetails) {
                  SharedConfig.user.personal_details[underwritingAnswer.object_field] = underwritingAnswer.object_answer;
                } else {
                  if (SharedConfig.user.insurance_history) {
                    const historyCheck0 = SharedConfig.user.insurance_history;
                    SharedConfig.user.insurance_history[underwritingAnswer.object_field] = underwritingAnswer.object_answer;
                    const historyCheck = SharedConfig.user.insurance_history;
                    const historyCheck1 = SharedConfig.user.insurance_history;
                  }
                }
              }
            }

            const isAddressField =
              question.underwriting_answers[0].object_field.indexOf('address') >= 0 ||
              question.underwriting_answers[0].object_field.indexOf('postal') >= 0;
            if (isAddressField) {
              const addressRequest: IAddresses = await this.setAddress(address);
              SharedConfig.user.address_id = addressRequest.id;
              SharedConfig.user.addresses = addressRequest;
              address = {};
            }
          }

          // Deep clone variables to not have them overridden by "getAllUserData"
          const insuranceHistoryToSave = { ...SharedConfig.user.insurance_history };
          const personalHistoryToSave = { ...SharedConfig.user.personal_details };

          await firstValueFrom(this.apiUserService.updateUser(SharedConfig.user).pipe(take(1)));
          await firstValueFrom(this.apiUserService.updatePersonalDetails(personalHistoryToSave).pipe(take(1)));
          await firstValueFrom(this.apiUserService.updateUserInsuranceHistory(insuranceHistoryToSave).pipe(take(1)));

          break;
      }
  }

  async removeUnderwritingAnswers(underwriting_answers: UnderwritingAnswer[]) {
    for (const underwritingAnswer of underwriting_answers) {
      await this.removeAnswerFromQuoteRequestAndAsset(underwritingAnswer);
    }
  }

  async setAddress(userAddress: IAddresses): Promise<IAddresses> {
    return (await firstValueFrom(this.apiQuoteScheduleService.setAddress(userAddress))).results;
  }

  async removeAnswerFromQuoteRequestAndAsset(underwritingAnswer: UnderwritingAnswer) {
    let QuoteAssetIndex = -1;
    switch (underwritingAnswer.object_name) {
      case AssetType.Buildings:
        QuoteAssetIndex = this.sharedConfig.quoteRequest.assets.buildings.findIndex((x) => x.id === SharedConfig.selectedAssetBuilding.id);
        this.sharedConfig.quoteRequest.assets.buildings[QuoteAssetIndex][underwritingAnswer.object_field] = null;
        SharedConfig.selectedAssetBuilding[underwritingAnswer.object_field] = null;
        await this.busnAssetServiceService.updateBuilding(SharedConfig.selectedAssetBuilding);
        await this.updateQuoteRequest(this.sharedConfig.quoteRequest);
        break;

      case AssetType.Caravans:
        QuoteAssetIndex = this.sharedConfig.quoteRequest.assets.caravans.findIndex((x) => x.id === SharedConfig.selectedAssetCaravan.id);
        this.sharedConfig.quoteRequest.assets.caravans[QuoteAssetIndex][underwritingAnswer.object_field] = null;
        SharedConfig.selectedAssetCaravan[underwritingAnswer.object_field] = null;
        await this.busnAssetServiceService.updateCaravan(SharedConfig.selectedAssetCaravan);
        await this.updateQuoteRequest(this.sharedConfig.quoteRequest);

        break;

      case AssetType.Contents:
        QuoteAssetIndex = this.sharedConfig.quoteRequest.assets.contents.findIndex((x) => x.id === SharedConfig.selectedAssetContent.id);
        this.sharedConfig.quoteRequest.assets.contents[QuoteAssetIndex][underwritingAnswer.object_field] = null;
        SharedConfig.selectedAssetContent[underwritingAnswer.object_field] = null;
        await this.busnAssetServiceService.updateContent(SharedConfig.selectedAssetContent);
        await this.updateQuoteRequest(this.sharedConfig.quoteRequest);

        break;

      case AssetType.Motorcycles:
        QuoteAssetIndex = this.sharedConfig.quoteRequest.assets.motorcycles.findIndex((x) => x.id === SharedConfig.selectedAssetMotorcycle.id);
        this.sharedConfig.quoteRequest.assets.motorcycles[QuoteAssetIndex][underwritingAnswer.object_field] = null;
        SharedConfig.selectedAssetMotorcycle[underwritingAnswer.object_field] = null;
        await this.busnAssetServiceService.updateMotorcycle(SharedConfig.selectedAssetMotorcycle);
        await this.updateQuoteRequest(this.sharedConfig.quoteRequest);

        break;

      case AssetType.SpecifiedItems:
        QuoteAssetIndex = this.sharedConfig.quoteRequest.assets.specified_items.findIndex((x) => x.id === SharedConfig.selectedAssetSpecifiedItem.id);
        this.sharedConfig.quoteRequest.assets.specified_items[QuoteAssetIndex][underwritingAnswer.object_field] = null;
        SharedConfig.selectedAssetSpecifiedItem[underwritingAnswer.object_field] = null;
        await this.busnAssetServiceService.updateSpecifiedItem(SharedConfig.selectedAssetSpecifiedItem);
        await this.updateQuoteRequest(this.sharedConfig.quoteRequest);

        break;

      case AssetType.Vehicles:
        QuoteAssetIndex = this.sharedConfig.quoteRequest.assets.vehicles.findIndex((x) => x.id === SharedConfig.selectedAssetVehicle.id);

        this.sharedConfig.quoteRequest.assets.vehicles[QuoteAssetIndex][underwritingAnswer.object_field] = null;
        SharedConfig.selectedAssetVehicle[underwritingAnswer.object_field] = null;
        await this.busnAssetServiceService.updateVehicle(SharedConfig.selectedAssetVehicle);
        await this.updateQuoteRequest(this.sharedConfig.quoteRequest);

        break;

      case AssetType.AllRisks:
        QuoteAssetIndex = this.sharedConfig.quoteRequest.assets.all_risks.findIndex((x) => x.id === SharedConfig.selectedAssetAllRiskItem.id);
        this.sharedConfig.quoteRequest.assets.all_risks[QuoteAssetIndex][underwritingAnswer.object_field] = null;
        SharedConfig.selectedAssetAllRiskItem[underwritingAnswer.object_field] = null;
        await this.busnAssetServiceService.updateAllRiskItem(SharedConfig.selectedAssetAllRiskItem);
        await this.updateQuoteRequest(this.sharedConfig.quoteRequest);

        break;
      default:
        break;
    }
  }

  // REFACTOR THIS -
  // REFACTOR THIS -
  // REFACTOR THIS -
  // REFACTOR THIS -
  // REFACTOR THIS -
  // REFACTOR THIS -
  // REFACTOR THIS -
  // REFACTOR THIS -
  isFieldValidationSuccess(
    underwriting_answers: Array<UnderwritingAnswer>,
    question: IQuestion,
    quoteRequestSelectedAsset: any,
    content_building: IContent | IBuilding = null
  ): boolean {
    const questionType = question.type;

    for (const underwritingAnswer of underwriting_answers) {
      if (
        underwritingAnswer.object_answer !== null &&
        underwritingAnswer.object_answer !== undefined &&
        underwritingAnswer.object_answer.length !== 0
      ) {
        switch (questionType) {
          case QuestionType.boolean:
            if (typeof underwritingAnswer.object_answer !== 'boolean') {
              this.errorDescription = underwritingAnswer.object_answer_error = 'Please provide valid answer';
              return false;
            }
            break;
          case QuestionType.select:
            if (typeof underwritingAnswer.object_answer !== 'string' || question.options.indexOf(underwritingAnswer.object_answer) < 0) {
              this.errorDescription = underwritingAnswer.object_answer_error = 'Please provide valid answer';
              return false;
            }
            break;
          case 'SELECTSEARCH':
            if (typeof underwritingAnswer.object_answer !== 'string' || question.options.indexOf(underwritingAnswer.object_answer) < 0) {
              this.errorDescription = 'Please provide valid answer';
              return false;
            }
            break;
          case QuestionType.multiSelect:
            if (!content_building) {
              if (question.underwriting_answers.find((x) => (x.object_answer === undefined && x.required) || typeof x.object_answer !== 'boolean')) {
                this.errorDescription = underwritingAnswer.object_answer_error = 'Please make a selection';
                return false;
              }
            }
            break;
          case QuestionType.text:
            if (typeof underwritingAnswer.object_answer !== 'string') {
              this.errorDescription = underwritingAnswer.object_answer_error = 'Please provide valid answer';
              return false;
            }

            if (underwritingAnswer.object_field === 'cell_nr' && underwritingAnswer.object_answer.length === 10) {
              const output = underwritingAnswer.object_answer.match(/^[0-9]+$/);
              if (output === null) {
                this.errorDescription = underwritingAnswer.object_answer_error = "Number can't contain letters";
                return false;
              }
            } else if (underwritingAnswer.object_field === 'cell_nr' && underwritingAnswer.object_answer.length !== 10) {
              this.errorDescription = underwritingAnswer.object_answer_error = 'Phone number must have 10 digits';
              return false;
            } else if (underwritingAnswer.object_field === 'cell_nr' && underwritingAnswer.object_answer.indexOf(' ') >= 0) {
              this.errorDescription = underwritingAnswer.object_answer_error = 'No spaces allowed';
              return false;
            }

            if (
              (underwritingAnswer.min && (!underwritingAnswer || underwritingAnswer.object_answer.toString().length < underwritingAnswer.min)) ||
              (underwritingAnswer.max && (!underwritingAnswer || underwritingAnswer.object_answer.toString().length > underwritingAnswer.max))
            ) {
              if (
                underwritingAnswer.object_field === 'id_nr' ||
                underwritingAnswer.object_field === 'regular_driver_id' ||
                underwritingAnswer.object_field === 'registered_owner_id' ||
                underwritingAnswer.object_field === 'regular_driver_id' ||
                underwritingAnswer.object_field === 'registered_owner_id'
              ) {
                this.errorDescription = underwritingAnswer.object_answer_error = 'Your ID number must contain 13 digits';
              } else {
                this.errorDescription =
                  underwritingAnswer.object_answer_error = `The answer must be more than ${underwritingAnswer.min} and less than ${underwritingAnswer.max}`;
              }
              return false;
            }

            if (underwritingAnswer.object_field === 'risk_address_code' && underwritingAnswer.object_answer.indexOf(' ') >= 0) {
              this.errorDescription = underwritingAnswer.object_answer_error = 'No spaces allowed in postal code';
              return false;
            }

            if (underwritingAnswer.required && (!underwritingAnswer.object_answer || underwritingAnswer.object_answer.toString().length === 0)) {
              this.errorDescription = underwritingAnswer.object_answer_error = 'Please provide an answer';
              return false;
            }
            if (
              (underwritingAnswer.object_field === 'id_nr' ||
                underwritingAnswer.object_field === 'regular_driver_id' ||
                underwritingAnswer.object_field === 'registered_owner_id') &&
              !validate(underwritingAnswer.object_answer)
            ) {
              this.errorDescription = underwritingAnswer.object_answer_error = 'ID number is invalid';
              return false;
            }
            if (
              underwritingAnswer.object_field === 'id_nr' ||
              underwritingAnswer.object_field === 'regular_driver_id' ||
              underwritingAnswer.object_field === 'registered_owner_id'
            ) {
              const id = underwritingAnswer.object_answer;
              const y1o = id.substring(0, 1);
              const y2e = id.substring(1, 2);
              const m1o = id.substring(2, 3);
              const m2e = id.substring(3, 4);
              const d1o = id.substring(4, 5);
              const d2e = id.substring(5, 6);

              let dateInID = '';
              if (y1o === '0' && y2e === '0') {
                dateInID = `2000/${m1o}${m2e}/${d1o}${d2e}`;
              } else {
                dateInID = `${y1o + y2e}/${m1o}${m2e}/${d1o}${d2e}`;
              }
              const date_parse = Date.parse(dateInID);
              const testDateInID = isNaN(date_parse);
              if (testDateInID) {
                this.errorDescription = underwritingAnswer.object_answer_error = 'ID number is invalid';
                return false;
              }
            }
            break;
          case QuestionType.multi:
            const _numberAwnser = Number(underwritingAnswer.object_answer);
            if (underwritingAnswer.object_answer === undefined || Number.isNaN(underwritingAnswer.object_answer)) {
              this.errorDescription = underwritingAnswer.object_answer_error = 'Please provide an answer';
              return false;
            }
            break;
          case QuestionType.number:
            const _numAnswer = Number(underwritingAnswer.object_answer);
            underwritingAnswer.object_answer = _numAnswer;
            const isnum = /^\d+$/.test(underwritingAnswer.object_answer);
            if (underwritingAnswer.object_answer === undefined || typeof underwritingAnswer.object_answer !== InputType.number) {
              this.errorDescription = underwritingAnswer.object_answer_error = 'Please provide an answer';
              return false;
            }
            if (underwritingAnswer.min !== _numAnswer && _numAnswer === 0) {
              this.errorDescription = underwritingAnswer.object_answer_error = `The answer must be more than ${underwritingAnswer.min}`;
              return false;
            }
            if (underwritingAnswer.min !== _numAnswer && _numAnswer === 0) {
              this.errorDescription = underwritingAnswer.object_answer_error = `The answer must be more than ${underwritingAnswer.min}`;
              return false;
            }
            if (
              (underwritingAnswer.max && underwritingAnswer.max <= _numAnswer) ||
              (underwritingAnswer.min && underwritingAnswer.min >= _numAnswer)
            ) {
              if (
                underwritingAnswer.object_field === 'regularDriverID' ||
                underwritingAnswer.object_field === 'registeredOwnerID' ||
                underwritingAnswer.object_field === 'regularDriverID' ||
                underwritingAnswer.object_field === 'registeredOwnerID'
              ) {
                this.errorDescription = underwritingAnswer.object_answer_error = 'Your ID number must contain 13 digits';
              } else {
                this.errorDescription =
                  underwritingAnswer.object_answer_error = `The answer must be more than ${underwritingAnswer.min} and less than ${underwritingAnswer.max}`;
              }
              return false;
            }
            if (!isnum) {
              this.errorDescription = underwritingAnswer.object_answer_error =
                'Please provide a valid number (no decimal or negative numbers allowed)';
              return false;
            }
            break;
          case QuestionType.date:
            // TODO JOSH -> VALIDATION DISABLED FOR NOW
            const x = underwritingAnswer.object_answer;
            const xd = underwritingAnswer.object_answer;
            // const numAnswer = Number(underwritingAnswer.object_answer);
            // if (
            //   underwritingAnswer.object_answer === undefined ||
            //   typeof underwritingAnswer.object_answer !== 'string' ||
            //   numAnswer === 0 ||
            //   !this.dateIsValidyyyymmdd(underwritingAnswer.object_answer)
            // ) {
            //  this.errorDescription = underwritingAnswer.object_answer_error = 'Please provide an answer';
            //   return false;
            // }
            break;
          case QuestionType.address:
            if (question.underwriting_answers.find((x) => typeof x.object_answer !== 'string' || x.object_answer.length <= 0)) {
              this.errorDescription = underwritingAnswer.object_answer_error = 'Please provide an answer';
              return false;
            }
            break;
          case QuestionType.vehicleSearch:
            const year_of_manufacture = question.underwriting_answers.find((x) => x.object_field === 'year_of_manufacture');
            const model_series = question.underwriting_answers.find((x) => x.object_field === 'model_series');
            const model = question.underwriting_answers.find((x) => x.object_field === 'model');
            const make = question.underwriting_answers.find((x) => x.object_field === 'make');
            let model_id = question.underwriting_answers.find((x) => x.object_field === 'model_id');

            if (!model_id) {
              if (quoteRequestSelectedAsset && question.underwriting_answers[0].object_name === AssetType.Vehicles) {
                model_id = quoteRequestSelectedAsset.model_id ? quoteRequestSelectedAsset.model_id : null;
                const sum_insured_type = quoteRequestSelectedAsset.sum_insured_type;
                const sum_insured = quoteRequestSelectedAsset.sum_insured ? quoteRequestSelectedAsset.sum_insured : 'Retail value';
              } else if (quoteRequestSelectedAsset && question.underwriting_answers[0].object_name === AssetType.Motorcycles) {
                model_id = quoteRequestSelectedAsset.model_id ? quoteRequestSelectedAsset.model_id : null;
                // const sumInsuredTypeUnderwriting = quoteRequestSelectedAsset.sum_insured_type;
                // const sumInsuredUnderwriting = quoteRequestSelectedAsset.sum_insured ? quoteRequestSelectedAsset.sum_insured : 'Retail value';
              }
            } else {
              model_id = model_id.object_answer;
            }

            if (
              typeof year_of_manufacture.object_answer !== InputType.number ||
              typeof model_series.object_answer !== 'string' ||
              typeof model.object_answer !== 'string' ||
              typeof make.object_answer !== 'string' ||
              !year_of_manufacture.object_answer ||
              !model_series.object_answer ||
              !model.object_answer ||
              !make.object_answer ||
              !model_id
            ) {
              delete question.underwriting_answers.find((x) => x.object_field === 'year_of_manufacture').object_answer;
              delete question.underwriting_answers.find((x) => x.object_field === 'model_series').object_answer;
              delete question.underwriting_answers.find((x) => x.object_field === 'model').object_answer;
              delete question.underwriting_answers.find((x) => x.object_field === 'make').object_answer;
              if (!model_id) delete quoteRequestSelectedAsset?.model_id;
              this.errorDescription = underwritingAnswer.object_answer_error = 'Please provide an answer';
              return false;
            }
            break;
          default:
            break;
        }

        for (let index = 0; index < question.underwriting_answers.length; index++) {
          const element = question.underwriting_answers[index];
          if (element.object_field === 'days_work_from_home') {
            const chech_for_negative_numbers = Math.sign(element.object_answer);
            if (element.object_answer > element.max) {
              this.errorDescription = underwritingAnswer.object_answer_error = 'Exceeding max number of days in a week';
              return false;
            }
            if (chech_for_negative_numbers === -1) {
              this.errorDescription = underwritingAnswer.object_answer_error =
                'Please provide a valid number (no decimal or negative numbers allowed)';
              return false;
            }
          } else if (element.object_field === 'monthly_mileage') {
            if (element.object_answer > element.max) {
              this.errorDescription = `Kilometers per month can't be more than ${element.max}`;
              return false;
            }
          }
        }

        if (underwritingAnswer.object_field === 'sum_insured') {
          if (underwritingAnswer.object_answer < underwritingAnswer.min || underwritingAnswer.object_answer > underwritingAnswer.max) {
            this.errorDescription =
              underwritingAnswer.object_answer_error = `The answer must be more than ${underwritingAnswer.min} and less than ${underwritingAnswer.max}`;
            return false;
          }
        }

        if (
          underwritingAnswer.object_field === 'name' ||
          underwritingAnswer.object_field === 'surname' ||
          underwritingAnswer.object_field === 'surname_on_id'
        ) {
          const match = underwritingAnswer.object_answer.match(/\d+/g);
          if (match != null) {
            this.errorDescription = underwritingAnswer.object_answer_error = 'No numbers allowed';
            return false;
          }
        }

        if (
          underwritingAnswer.object_field === 'id_nr' ||
          underwritingAnswer.object_field === 'regular_driver_id' ||
          underwritingAnswer.object_field === 'registered_owner_id'
        ) {
          if (underwritingAnswer.object_answer.toString().length !== 13) {
            this.errorDescription = underwritingAnswer.object_answer_error = 'ID number requires 13 digits and only numbers are allowed';
            return false;
          }
        }

        if (underwritingAnswer.object_field === 'regular_driver_id') {
          SharedConfig.regularDriverIdNumber = underwritingAnswer.object_answer;
        }

        if (underwritingAnswer.object_field === 'regular_driver_license_type') {
          SharedConfig.regular_driver_license_type = underwritingAnswer.object_answer;
        }
        if (underwritingAnswer.object_field === 'regular_driver_license_date') {
          if (SharedConfig.regularDriverIdNumber) {
            const ageOnDriversLicense = this.sharedFormulaService.getAgeOnDriversLicense(
              underwritingAnswer.object_answer,
              SharedConfig.regularDriverIdNumber
            );
            // let ageOnDriversLicense = this.sharedFormulaService.getAgeFromIdNumber(SharedConfig.regularDriverIdNumber)
            if (SharedConfig.regular_driver_license_type === 'A1 - Motor Cycle < 125cc') {
              if (ageOnDriversLicense < 16) {
                this.errorDescription = underwritingAnswer.object_answer_error = 'You must be 16 and older to possess a code A1 license';
                return false;
              }
            } else if (SharedConfig.regular_driver_license_type === 'L - Learners') {
              if (ageOnDriversLicense < 17) {
                this.errorDescription = underwritingAnswer.object_answer_error = "You must be 17 and older to possess a Learner's license";
                return false;
              }
            } else {
              if (ageOnDriversLicense < 18) {
                this.errorDescription = underwritingAnswer.object_answer_error = "You must be 18 and older to possess a driver's license";
                return false;
              }
            }
          }
        }
      } else {
        if (underwritingAnswer.required) {
          if (questionType === QuestionType.vehicleSearch) {
            for (const _underwritingAnswer of underwriting_answers) {
              delete _underwritingAnswer.object_answer;
            }
          }
          this.errorDescription = underwritingAnswer.object_answer_error = 'Please provide an answer';
          return false;
        } else {
          return true;
        }
      }
    }
    this.errorDescription = null;
    return true;
  }

  dateIsValidyyyymmdd(dateStr: string) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    if (dateStr.match(regex) === null) {
      return false;
    }

    const date = new Date(dateStr);

    const timestamp = date.getTime();

    if (typeof timestamp !== InputType.number || Number.isNaN(timestamp)) {
      return false;
    }

    return date.toISOString().startsWith(dateStr);
  }

  async createNewQuoteRequest(): Promise<IQuoteRequest> {
    this.sharedConfig.setShowCustomLoader(true, 'createNewQuoteRequest');
    const quoteRequestType: QuoteRequestType = QuoteRequestType.Quote;

    try {
      const quote_create_payload = {
        effective_date: this.sharedFormulaService.formatDateyyymmdd(Date.now()),
        type: quoteRequestType,
      };

      const quoteRequest$ = this.apiQuoteScheduleService.createNewQuoteRequest(quote_create_payload).pipe(take(1));
      let quoteReq: IQuoteRequest = (await firstValueFrom(quoteRequest$)).results;

      const shouldRecreate = await this.reCreateQuoteRequestIfIncompleteDetailsBecauseOfFaultyBackendSyncWithoutValidationShouldRecreateQuote(
        quoteReq
      );

      if (shouldRecreate) {
        const quoteRequest$ = this.apiQuoteScheduleService.createNewQuoteRequest(quote_create_payload).pipe(take(1));
        quoteReq = (await firstValueFrom(quoteRequest$)).results;
      }

      SharedConfig.quoteRequest = quoteReq;
      this.sharedConfigService.setQuoteRequest(quoteReq);
      Storage.set({
        key: 'hasSeenErrorPopup',
        value: 'false',
      });
      this.sharedConfig.setShowCustomLoader(false, 'createNewQuoteRequest');
      return quoteReq;
    } catch (error) {
      console.error('FAILED TO CREATE NEW QUOTE REQUEST');
      console.error(error);
      this.sharedConfig.setShowCustomLoader(false, 'createNewQuoteRequest');
      return null; // TODO Error handling generic class
    }
  }

  async createNewEndorsementRequest(quoteRequestType = QuoteRequestType.EndorsementAdd, schedule_id?: number): Promise<IQuoteRequest> {
    try {
      const quote_create_payload = {
        effective_date: this.sharedFormulaService.formatDateyyymmdd(Date.now()),
        type: quoteRequestType,
        // TODO WITH NEW FUNCTIONALITY TO SELECT MULTIPLE SCHEDULES
        schedule_id: schedule_id ? schedule_id : this.sharedConfig.schedulesActive[0].id,
      };

      const quoteRequest$ = this.apiQuoteScheduleService.createNewQuoteRequest(quote_create_payload).pipe(take(1));
      let quoteReq: IQuoteRequest = (await firstValueFrom(quoteRequest$)).results;

      const shouldRecreate = await this.reCreateQuoteRequestIfIncompleteDetailsBecauseOfFaultyBackendSyncWithoutValidationShouldRecreateQuote(
        quoteReq
      );

      if (shouldRecreate) {
        const quoteRequest$ = this.apiQuoteScheduleService.createNewQuoteRequest(quote_create_payload).pipe(take(1));
        quoteReq = (await firstValueFrom(quoteRequest$)).results;
      }

      SharedConfig.quoteRequest = quoteReq;
      this.sharedConfigService.setQuoteRequest(quoteReq);
      Storage.set({
        key: 'hasSeenErrorPopup',
        value: 'false',
      });
      return quoteReq;
    } catch (error) {
      console.error('FAILED TO CREATE NEW QUOTE REQUEST');
      console.error(error);
      return null; // TODO Error handling generic class
    }
  }

  async reCreateQuoteRequestIfIncompleteDetailsBecauseOfFaultyBackendSyncWithoutValidationShouldRecreateQuote(
    quoteRequest: IQuoteRequest
  ): Promise<Boolean> {
    //----------------- OLD APP VEHICLE CHECK (MODELSERIES)
    let serverError = false;
    let reCreateQuoteRequest = false;
    let updateAsset = false;
    if (quoteRequest.assets?.vehicles) {
      for (const vehicle of quoteRequest.assets?.vehicles) {
        updateAsset = false;
        if (vehicle.year_of_manufacture && vehicle.model_id) {
          if (!vehicle.model_series) {
            reCreateQuoteRequest = true;
            updateAsset = true;
            const responseMotorVehicleResponse = await firstValueFrom(
              this.apiAssetService.getVehicleModelByModelID(vehicle.year_of_manufacture.toString(), vehicle.model_id)
            );
            const responseMotorVehicle = responseMotorVehicleResponse as IResponseMotorVehiclesSnakeCase;
            if (!responseMotorVehicle) {
              delete vehicle.model;
              delete vehicle.model_id;
              delete vehicle.model_series;
              delete vehicle.sum_insured;
              break;
            }

            vehicle.model_series = responseMotorVehicle.model.name;
          }
          if (vehicle.model_variant) {
            vehicle.model = vehicle.model_variant;
          }
          if (!vehicle.sum_insured) {
            reCreateQuoteRequest = true;
            updateAsset = true;
            let responseMotorVehicle;
            if (!responseMotorVehicle) {
              const responseMotorVehicleResponse = await firstValueFrom(
                this.apiAssetService.getVehicleModelByModelID(vehicle.year_of_manufacture.toString(), vehicle.model_id)
              );
              responseMotorVehicle = responseMotorVehicleResponse as IResponseMotorVehiclesSnakeCase;
            }

            if (
              (!responseMotorVehicle || !responseMotorVehicle.value_retail || responseMotorVehicle.value_retail <= 0) &&
              responseMotorVehicle.value_new <= 0
            ) {
              const alert = await this.alertController.create({
                header: 'Something went wrong',
                message: 'The sum insured value for your new vehicle could not be found. Please contact us.',
                buttons: ['OK'],
              });
              await alert.present();
            } else {
              if (responseMotorVehicle.value_retail > 0) {
                vehicle.sum_insured = responseMotorVehicle.value_retail / 100;
                vehicle.sum_insured_type = 'Retail value';
              } else {
                vehicle.sum_insured = responseMotorVehicle.value_new / 100;
                vehicle.sum_insured_type = 'As if it was new';
              }
            }
          }
          if (updateAsset) await this.apiAssetService.updateVehicle(vehicle).toPromise();
        }
      }
    }
    if (quoteRequest.assets?.motorcycles)
      for (const vehicle of quoteRequest.assets.motorcycles) {
        updateAsset = false;
        if (vehicle.year_of_manufacture && vehicle.model_id) {
          if (!vehicle.model_series) {
            reCreateQuoteRequest = true;
            updateAsset = true;
            const responseMotorVehicle$ = this.apiAssetService.getVehicleModelByModelID(vehicle.year_of_manufacture.toString(), vehicle.model_id);
            const responseMotorVehicleResponse = await firstValueFrom(responseMotorVehicle$);
            const responseMotorVehicle = responseMotorVehicleResponse as IResponseMotorVehiclesSnakeCase;
            if (!responseMotorVehicle) {
              delete vehicle.model;
              delete vehicle.model_id;
              delete vehicle.model_series;
              delete vehicle.sum_insured;
              break;
            }
            vehicle.model_series = responseMotorVehicle.model.name;
          }
          if (vehicle.model_variant) {
            vehicle.model = vehicle.model_variant;
          }
          if (!vehicle.sum_insured) {
            reCreateQuoteRequest = true;
            updateAsset = true;
            const responseMotorVehicle$ = this.apiAssetService.getVehicleModelByModelID(vehicle.year_of_manufacture.toString(), vehicle.model_id);
            const responseMotorVehicleResponse = await firstValueFrom(responseMotorVehicle$);
            const responseMotorVehicle = responseMotorVehicleResponse as IResponseMotorVehiclesSnakeCase;
            if (!responseMotorVehicle) {
              serverError = true;
              return;
            }
            if (responseMotorVehicle.value_retail > 0) {
              vehicle.sum_insured = responseMotorVehicle.value_retail / 100;
              vehicle.sum_insured_type = 'Retail value';
            } else {
              vehicle.sum_insured = responseMotorVehicle.value_new / 100;
              vehicle.sum_insured_type = 'As if it was new';
            }
          }
        }
        if (updateAsset) (await firstValueFrom(this.apiAssetService.updateMotorcycle(vehicle))).results;
      }
    if (serverError) {
      const failedAlert = await this.alertController.create({
        header: 'Something went wrong',
        subHeader: 'Please try again later or contact us',
        buttons: [
          {
            text: 'Ok',
            handler: () => {
              failedAlert.dismiss();
            },
          },
        ],
      });
      await failedAlert.present();
      return;
    }

    if (reCreateQuoteRequest) {
      return true;
    }

    return false;
  }

  async cancelQuoteRequest(): Promise<void> {
    const quote_create_payload: IQuoteRequest = {
      status: QuoteRequestStatusType.Cancelled,
      id: this.sharedConfig.quoteRequest.id,
    };

    this.sharedConfig.quoteRequest.status === QuoteRequestStatusType.Cancelled;
    await firstValueFrom(this.apiQuoteScheduleService.patchQuoteRequest(quote_create_payload));
  }

  async updateQuoteRequest(quoteRequest: IQuoteRequest): Promise<IQuoteRequest> {
    if (!quoteRequest) {
      await this.createNewQuoteRequest();
    }
    const setQuoteRequestResult = await firstValueFrom(this.apiQuoteScheduleService.setQuoteRequest(quoteRequest));
    return setQuoteRequestResult.results;
  }

  async getSpecialConditions(): Promise<ISpecialConditionAssets> {
    return (await firstValueFrom(this.apiQuoteScheduleService.getSpecialConditions())).results;
  }

  async updateCoverStartDate(date: string, quote_request: IQuoteRequest): Promise<void> {
    (await firstValueFrom(this.apiQuoteScheduleService.updateCoverStartDate(date, quote_request))).results;
  }

  async getQuote(user_id: string): Promise<IQuote> {
    return (await firstValueFrom(this.apiQuoteScheduleService.getQuote(user_id))).results;
  }

  async getBanks(): Promise<IBank[]> {
    return (await firstValueFrom(this.apiQuoteScheduleService.getBanks())).results;
  }

  async getBankBranches(bankName: string): Promise<IBankBranches> {
    return (await firstValueFrom(this.apiQuoteScheduleService.getBankBranches(bankName))).results;
  }

  async getCasaList(): Promise<ICasaDetails> {
    const casaResponse = await firstValueFrom(this.apiQuoteScheduleService.loadCASAList());
    return casaResponse.results;
  }

  updateUserCasa(
    corCode,
    corDesc,
    cobCode,
    cobDesc,
    nationalityCode,
    nationalityDesc,
    sourceOfIncomeCode,
    sourceOfIncomeDesc,
    empCode,
    empDesc,
    occupation_code,
    occupationDesc
  ): void {
    this.apiQuoteScheduleService.updateCASA(
      corCode,
      corDesc,
      cobCode,
      cobDesc,
      nationalityCode,
      nationalityDesc,
      sourceOfIncomeCode,
      sourceOfIncomeDesc,
      empCode,
      empDesc,
      occupation_code,
      occupationDesc
    );
  }

  async kingPriceConfirm(quoteRequestID: number): Promise<IResponse> {
    return (await firstValueFrom(this.apiQuoteScheduleService.submitKingPriceConfirmation(quoteRequestID))).results;
  }

  async convertMiway(quoteRequestID: number): Promise<IAPIQuoteConvertResponse> {
    return (await firstValueFrom(this.apiQuoteScheduleService.miwayConvert(quoteRequestID))).results;
  }

  async getInsurers(): Promise<Array<IInsurers>> {
    return (await firstValueFrom(this.apiQuoteScheduleService.getInsurers())).results;
  }

  async quoteConvert(quoteRequestID: number, quote_nr: string, bankingDetails: IBankingDetails): Promise<IQuoteConvertResponse> {
    return (await firstValueFrom(this.apiQuoteScheduleService.brolinkConvert(quoteRequestID, quote_nr, bankingDetails))).results;
  }

  async acceptEndorsement(quoteRequestID: number): Promise<ICtrlResponse<String>> {
    const response = await firstValueFrom(this.apiQuoteScheduleService.acceptEndorsement(quoteRequestID));
    return response;
  }

  async declineEndorsement(quoteRequestID: number): Promise<ICtrlResponse<String>> {
    const response = await firstValueFrom(this.apiQuoteScheduleService.declineEndorsement(quoteRequestID));
    return response;
  }
}
