<div class="wrapper bottom-padding limit-width-on-desktop-float">
  <div class="summary-question-body">
    <div *ngFor="let question of selectedAssetUnderwritingQuestions; let i = index">
      <div *ngIf="onlyShowClaimsQuestion">
        <div class="heading-label" *ngIf="question?.type === 'CLAIMLIST'">
          <h2 class="question-heading" *ngIf="!sharedConfig.hideHeaderWhenAddedClaimType">{{ question.sub_heading }}</h2>
        </div>
        <div [ngSwitch]="question?.type">
          <input-previous-claims
            *ngSwitchCase="'CLAIMLIST'"
            [question]="question"
            [showHeading]="false"
            [readOnly]="readOnly"
            [summary]="summary"
            (answerQuestion)="answerQuestion($event, i)"
            [error]="errorDescription"
          ></input-previous-claims>
        </div>
      </div>
      <div *ngIf="!onlyShowClaimsQuestion">
        <div class="heading-label">
          <p>{{ question.wording_for_summary }}</p>
        </div>
        <div class="question-spacing" [ngSwitch]="question?.type">
          <input-dropdown-motor-selector
            *ngSwitchCase="QuestionType.vehicleSearch"
            [readOnly]="readOnly"
            [labelPosition]="labelPosition"
            [question]="question"
            [showHeading]="false"
            [page]="'summary'"
            (answerQuestion)="answerQuestion($event, i)"
          ></input-dropdown-motor-selector>
          <input-text-single-line
            *ngSwitchCase="QuestionType.text"
            [readOnly]="readOnly"
            [question]="question"
            [showHeading]="false"
            [page]="'summary'"
            (answerQuestion)="answerQuestion($event, i)"
            [error]="errorDescription"
          ></input-text-single-line>
          <input-text-single-line
            *ngSwitchCase="QuestionType.number"
            [readOnly]="readOnly"
            [question]="question"
            [showHeading]="false"
            [page]="'summary'"
            (answerQuestion)="answerQuestion($event, i)"
            [error]="errorDescription"
          ></input-text-single-line>
          <input-text-address
            *ngSwitchCase="QuestionType.address"
            [readOnly]="readOnly"
            [question]="question"
            [showHeading]="false"
            [page]="'summary'"
            (answerQuestion)="answerQuestion($event, i)"
          ></input-text-address>
          <input-text-multi-lines
            [readOnly]="readOnly"
            *ngSwitchCase="QuestionType.multi"
            [question]="question"
            [showHeading]="true"
            [page]="'summary'"
            (answerQuestion)="answerQuestion($event, i)"
          ></input-text-multi-lines>
          <input-check-list-multi-select
            [readOnly]="readOnly"
            *ngSwitchCase="QuestionType.multiSelect"
            [question]="question"
            [showHeading]="true"
            [page]="'summary'"
            (answerQuestion)="answerQuestionSelect($event, i)"
          ></input-check-list-multi-select>
          <ion-item class="question-spacing" *ngSwitchCase="QuestionType.select">
            <ion-input
              readonly="true"
              [(ngModel)]="question.underwriting_answers[0].object_answer"
              (click)="populateSelectArray(question, i)"
            ></ion-input>
          </ion-item>
          <input-check-list-single-select-search
            *ngSwitchCase="'SELECTSEARCH'"
            [question]="question"
            [readOnly]="readOnly"
            (answerQuestion)="answerQuestionSelect($event, i)"
          ></input-check-list-single-select-search>
          <input-check-list-single-select
            [readOnly]="readOnly"
            *ngSwitchCase="QuestionType.boolean"
            [question]="question"
            [showHeading]="false"
            [page]="'summary'"
            (answerQuestion)="answerQuestionSelect($event, i)"
          ></input-check-list-single-select>
          <input-datepicker
            [readOnly]="readOnly"
            *ngSwitchCase="QuestionType.date"
            [question]="question"
            [showHeading]="false"
            [page]="'summary'"
            (answerQuestion)="answerQuestion($event, i)"
            [error]="errorDescription"
          ></input-datepicker>
          <input-datepicker
            [readOnly]="readOnly"
            *ngSwitchCase="QuestionType.dateTime"
            [question]="question"
            [showHeading]="false"
            [page]="'summary'"
            (answerQuestion)="answerQuestion($event, i)"
            [error]="errorDescription"
          ></input-datepicker>
          <input-single-search
            [readOnly]="readOnly"
            *ngSwitchCase="QuestionType.search"
            [question]="question"
            [showHeading]="false"
            [page]="'summary'"
            (answerQuestion)="answerQuestion($event)"
            [error]="errorDescription"
          ></input-single-search>
          <input-previous-claims
            *ngSwitchCase="'CLAIMLIST'"
            [question]="question"
            [showHeading]="false"
            [page]="'summary'"
            [readOnly]="readOnly"
            [summary]="summary"
            (answerQuestion)="answerQuestion($event, i)"
            [error]="errorDescription"
          ></input-previous-claims>
        </div>
      </div>

      <p class="error-message" *ngIf="showError">{{ selectedAssetUnderwritingQuestions[i].underwriting_answers[0].object_answer_error }}</p>
    </div>
  </div>

  <div class="button-row"></div>
</div>

<div class="footer-button-wrapper" *ngIf="!sharedConfig.addingClaim">
  <div class="fade"></div>
  <div class="footer-button-background">
    <ion-button mode="ios" class="footer-button limit-width-desktop-on-button" expand="block" (click)="confirmChangesAndSave()">
      <b *ngIf="!onlyShowClaimsQuestion">Save</b>
      <b *ngIf="onlyShowClaimsQuestion">Done</b>
    </ion-button>
  </div>
</div>

<popup-with-dismiss-button *ngIf="sharedConfig.showPopupWithDismissButton"></popup-with-dismiss-button>
<popup-summary-select
  *ngIf="showSelectPopup"
  [questionSelected]="questionSelected"
  [questionIndex]="questionIndex"
  (questionSelect)="answeredQuestionSelect($event, questionIndex)"
>
  <ion-button mode="ios" class="secondary-button" (click)="closeSelectPopup()"> Close </ion-button>
</popup-summary-select>

<!-- TODO ALL OTHER POPUPS SHOULD BE REPLACED BY THIS GENERIC POPUP -->
<popup-dialog id="question.summary.component" *ngIf="sharedConfig.popupDialogShow"></popup-dialog>
