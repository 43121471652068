import { Component, NgZone, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import '@lottiefiles/lottie-player';
import { BUSNUserService } from '@takectrl-multiapp/svcs-core-business';

@Component({
  selector: 'animation-please-wait-notification',
  templateUrl: './animation-please-wait-notification.component.html',
  styleUrls: ['./animation-please-wait-notification.component.scss'],
})
export class AnimationPleaseWaitNotificationComponent implements OnInit {
  show = true;
  pushNotifications: boolean;
  constructor(public busnUserService: BUSNUserService, public navCtrl: NavController, private readonly zone: NgZone, private readonly sharedConfig: SharedConfig) {}

  ngOnInit(): void {
   SharedConfig.selectedPage === 'animation-please-wait'
  }

  next() {
    SharedConfig.showFullScreen = false;
    this.zone.run(() => {
      this.navCtrl.navigateRoot('home/home-quote');
    });
  }
}
