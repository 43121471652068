<div class="wrapper white-bg" >
  <div class="header-wrapper limit-width-on-desktop-float">
    <h2 class="h2-bold">Emergency?</h2>
    <p>You can get emergency assistance as an added benefit which includes the following:</p>
  </div>
  <div class="table-wrapper limit-width-on-desktop-float">
    <ion-grid>
      <ion-row>
        <ion-col>
          <div class="left-wrapper">
            <span>
              <ion-icon class="icon-fill-primary" src="assets/icon/genetic-icons/tick-primary.svg"></ion-icon>
            </span>
            <b>Roadside</b>
          </div>
        </ion-col>
        <ion-col>
          <div class="right-wrapper">Accident, flat tyre or battery, etc.</div>
        </ion-col>
      </ion-row>
      <hr />
      <ion-row>
        <ion-col>
          <div class="left-wrapper">
            <span>
              <ion-icon class="icon-fill-primary" src="assets/icon/genetic-icons/tick-primary.svg"></ion-icon>
            </span>
            <b>Home</b>
          </div>
        </ion-col>
        <ion-col>
          <div class="right-wrapper">Plumber, electrician, locksmith, etc.</div>
        </ion-col>
      </ion-row>
      <hr />
      <ion-row>
        <ion-col>
          <div class="left-wrapper">
            <span>
              <ion-icon class="icon-fill-primary" src="assets/icon/genetic-icons/tick-primary.svg"></ion-icon>
            </span>
            <b>Take me home</b>
          </div>
        </ion-col>
        <ion-col>
          <div class="right-wrapper">If you've been drinking alcohol.</div>
        </ion-col>
      </ion-row>
      <hr />
      <ion-row>
        <ion-col>
          <div class="left-wrapper padding-5">
            <span>
              <ion-icon class="icon-fill-primary" src="assets/icon/genetic-icons/tick-primary.svg"></ion-icon>
            </span>
            <b>Legal</b>
          </div>
        </ion-col>
        <ion-col>
          <div class="right-wrapper padding-8">Advice from attorneys.</div>
        </ion-col>
      </ion-row>
      <hr />
      <ion-row>
        <ion-col>
          <div class="left-wrapper padding-5">
            <span>
              <ion-icon class="icon-fill-primary" src="assets/icon/genetic-icons/tick-primary.svg"></ion-icon>
            </span>
            <b>Medical </b>
          </div>
        </ion-col>
        <ion-col>
          <div class="right-wrapper padding-8">Telephonic.</div>
        </ion-col>
      </ion-row>
      <hr />
      <ion-row>
        <div class="bottom-text">
          <p>
            You've decided not to include this benefit when you quoted. If you're interested in adding this, please
            <b (click)="showIntercom()">contact us.</b>
          </p>
        </div>
      </ion-row>
    </ion-grid>
  </div>
</div>