import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class UserTrackingService {
  constructor(private readonly platform: Platform) {}

  init() {
    this.askTrackingPermission(this.platform);
    this.readTrackingPermission(this.platform);
  }

  askTrackingPermission(platform: Platform) {
    if (platform.is('cordova') && platform.is('ios')) {
      if (window['cordova']) {
        console.log('trying to request permission ');
        window['cordova'].exec(win, fail, 'idfa', 'requestPermission', []);
      }
    }

    function win(res: any) {
      console.log(`Idfa success ${JSON.stringify(res)}`);
    }
    function fail(res: any) {
      console.log(`Idfa fail ${JSON.stringify(res)}`);
    }
  }

  readTrackingPermission(platform: Platform) {
    if (platform.is('cordova') && platform.is('ios')) {
      if (window['cordova']) {
        window['cordova'].exec(win, fail, 'idfa', 'getInfo', []);
      }
    }

    function win(res: any) {
      console.log(`Idfa success  ${JSON.stringify(res)}`);
    }
    function fail(res: any) {
      console.log(`Idfa fail ${JSON.stringify(res)}`);
    }
  }
}
