import { Injectable } from '@angular/core';
import { APIHttpService } from './api-http.service';
import { IOccupations, SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import { Observable } from 'rxjs';
import { ICtrlResponse } from '../interfaces/api.interface';

@Injectable({
  providedIn: 'root',
})
export class APIMetaService {
  constructor(private readonly ctrlHttp: APIHttpService) {}

  searchOccupation(occupation: string): Observable<ICtrlResponse<IOccupations>> {
    return this.ctrlHttp.post(`/occupation/search/${occupation}/`, {});
  }
}
