import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor() {}

  DaysDiffTillNowUnix(actDate: Date) {
    const diff = Math.floor(new Date().getTime() - actDate.getTime());
    const day = 1000 * 60 * 60 * 24;
    const days = Math.floor(diff / day);

    return days;
  }
}
