import { Injectable } from '@angular/core';
import { ThirdPartyEventType } from '../../types/event.type';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

@Injectable({
  providedIn: 'root',
})
export class FirebaseAnalyticsService {
  constructor() {}

  async initWeb(firebaseCredentials: any) {
    await FirebaseAnalytics.initializeFirebase(firebaseCredentials)
      .then((_data) => {
      })
      .catch((error) => {
        console.error(`FirebaseAnalytics WEB Initialization Failed : ${error}`);
      });
  }

  initMobile(user_id: string) {
    FirebaseAnalytics.setUserId({
      userId: user_id,
    });
    console.log('Firebase Analytics: UserID set');

    FirebaseAnalytics.setCollectionEnabled({
      enabled: true,
    });
  }

  logEvent(eventType: ThirdPartyEventType, params: object) {
    console.log(`Firebase Analytic starting LOG: ${eventType}`);
    FirebaseAnalytics.logEvent({
      name: eventType.toString(),
      params: params,
    })
      .then((_data) => {
        console.log(`Firebase Analytic event logged ${eventType} -> ${JSON.stringify(params)}`);
      })
      .catch((error) => {
        console.error(`Firebase Analytic event error : ${error}`);
      });
  }
}
