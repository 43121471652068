import { SharedConfig, IEmergencyCategories, ISchedule, SharedDialogService } from '@takectrl-multiapp/svcs-share-config';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActionSheetController, AlertController, IonContent, IonSlides, Platform } from '@ionic/angular';
import { BUSNEmergencyService, BUSNPolicyService } from '@takectrl-multiapp/svcs-core-business';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
declare const dataLayer: any[];

@Component({
  selector: 'emergency-with-assistance-benefits',
  templateUrl: './emergency-with-assistance-benefits.component.html',
  styleUrls: ['./emergency-with-assistance-benefits.component.scss'],
})
export class EmergencyWithAssistanceBenefitsComponent implements OnInit {
  @ViewChild(IonContent, { static: false }) public content: IonContent;
  @ViewChild('slides') ionSlides: IonSlides;
  private insurerEmergencyNumber: any = '';
  private readonly hard_of_hearing = false;
  public emergencyCategoriesPerInsurer = [];
  // Client Card Details
  clientName = `${this.sharedConfig.user.personal_details.name} ${this.sharedConfig.user.personal_details.surname}`;
  public getCurrentSliderIndex = 0;
  selectedPolicyNumber: string;
  insurer_id: string;
  showSmallPopup = false;
  smallPopupHeader: string;
  smallPopupMessage: string;
  smallPopupButtonText: string;
  whichPopup: string;
  schedulesWithAssistanceBenefits: Array<ISchedule> = [];
  constructor(
    public actionSheetController: ActionSheetController,
    public alertController: AlertController,
    public emergencyService: BUSNEmergencyService,
    private readonly busnPolicyService: BUSNPolicyService,
    public busnThirdPartyService: BUSNThirdPartyService,
    public sharedConfig: SharedConfig,
    public platform: Platform,
    public sharedDialogService: SharedDialogService
  ) {}

  async ngOnInit() {
    this.schedulesWithAssistanceBenefits = this.sharedConfig.schedulesActive.filter((x) => x.assistance_benefits_requested);
    console.log(this.sharedConfig.schedulesActive);
    this.getEmergencyCategories();

    dataLayer.push({
      event: 'page_show',
      screenPath: 'emergency-with-assistance-benefits',
      screenName: 'emergency-with-assistance-benefits',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
    this.callEmergency = this.callEmergency.bind(this);
    this.callFromHardHearing = this.callFromHardHearing.bind(this);
    this.dismissSmallPopup = this.dismissSmallPopup.bind(this);
  }

  getEmergencyCategories() {
    this.sharedConfig.setShowCustomLoader(true)
    let insurerId = this.schedulesWithAssistanceBenefits[this.getCurrentSliderIndex]?.insurer_id
      ? this.schedulesWithAssistanceBenefits[this.getCurrentSliderIndex]?.insurer_id
      : this.sharedConfig.schedulesActive[0]?.insurer_id;
    this.emergencyService.emergencyCategories(insurerId).then(async (emergencyCategories) => {
      let categoryOfInsurer: any = emergencyCategories;
      if (categoryOfInsurer) {
        for (let emergencyCategory of categoryOfInsurer) {
          let categoryImageName = emergencyCategory.split(' ')[0] + '-' + emergencyCategory.split(' ')[1];
          this.emergencyCategoriesPerInsurer.push({ description: emergencyCategory, image_name: categoryImageName } as IEmergencyCategories);
        }
        this.sharedConfig.setShowCustomLoader(false)
      } else {
        this.emergencyCategoriesPerInsurer = [];
        this.sharedConfig.setShowCustomLoader(false)
      }
    });
  }

  ngAfterViewInit() {}

  async showActionSheet(insurer_id) {
    this.insurer_id = insurer_id;
    this.busnPolicyService.getEmergencyNumbers().then((emergencyNumber) => {
      if (emergencyNumber)
        for (const id of Object.keys(emergencyNumber)) {
          if (id == insurer_id) {
            const insurerNumber = emergencyNumber[id];
            this.insurerEmergencyNumber = insurerNumber.number;
          }
        }
        if (this.hard_of_hearing) {
          this.hardOfHearingAlert(); 
        } else {
          this.showCallAlert();
        }
    });
  }

  async hardOfHearingAlert() {
    this.whichPopup = 'hardHearingAlert';
    this.sharedDialogService.showPopupDialog({
      header: 'Take Note',
      subHeader: 'Assistance as a service involves external service providers and as such a telephone call will now be triggered',
      buttonOneText: this.platform.is('desktop') ? 'OK' : 'Call',
      buttonOneCallback: this.callFromHardHearing,
      buttonTwoText: 'Cancel',
      buttonTwoCallback: this.dismissSmallPopup,
    });
  }

  callFromHardHearing() {
    this.dismissSmallPopup();
    this.showCallAlert();
  }

  async showCallAlert() {
    if (this.platform.is('mobile')) {
      this.sharedDialogService.showPopupDialog({
        header: 'Emergency contact number',
        subHeader: this.insurerEmergencyNumber,
        buttonOneText: 'Call',
        buttonOneCallback: this.callEmergency,
        buttonTwoText: 'Cancel',
        buttonTwoCallback: this.dismissSmallPopup,
      });
    } else {
      this.sharedDialogService.showPopupDialog({
        header: 'Emergency contact number',
        subHeader: this.insurerEmergencyNumber,
        buttonOneText: 'OK',
        buttonOneCallback: this.callEmergency,
      });
    }
  }

  callEmergency() {
    this.emergencyService.submitEmergencyPhoneCall(
      this.insurerEmergencyNumber,
      this.emergencyService.selectedAssetsArray,
      this.insurer_id,
      this.selectedPolicyNumber
    );
    this.emergencyService.clearAll(this.emergencyService.selectedAssetsArray);
    SharedConfig.showFooterTabs = true;
    this.dismissSmallPopup();
  }

  addOrRemoveAssets(categories: IEmergencyCategories, categoryType: string, add: boolean, policy_number: string) {
    this.selectedPolicyNumber = policy_number;
    categories.description = categoryType;
    if (add) {
      const prev_description = this.emergencyService.selectedAssetsArray[0]?.description;
      this.emergencyService.selectedAssetsArray.push(categories);
      if (prev_description !== categories.description) {
        this.emergencyService.selectedAssetsArray[0].selected = false;
        if (this.emergencyService.selectedAssetsArray.length > 1) this.emergencyService.selectedAssetsArray.splice(0, 1);
      }
      categories.selected = true;
    } else {
      const idx = this.emergencyService.selectedAssetsArray.indexOf(categories);
      if (idx >= 0) {
        this.emergencyService.selectedAssetsArray.splice(idx, 1);
        console.log(this.emergencyService.selectedAssetsArray);
        categories.selected = false;
      }
    }
    if (this.emergencyService.selectedAssetsArray.length > 0) {
      SharedConfig.showFooterTabs = false;
    } else if (this.emergencyService.selectedAssetsArray.length === 0) {
      SharedConfig.showFooterTabs = true;
    }
  }

  slideDidChange() {
    this.ionSlides.getActiveIndex().then((index) => {
      this.getCurrentSliderIndex = index;
    });
  }

  next() {
    this.getCurrentSliderIndex = this.getCurrentSliderIndex + 1;
    this.ionSlides.slideNext(100, false);
  }

  prev() {
    this.getCurrentSliderIndex = this.getCurrentSliderIndex - 1;
    this.ionSlides.slidePrev(100, false);
  }

  dismissSmallPopup() {
    this.sharedDialogService.hidePopupDialog();
    this.emergencyService.clearAll(this.emergencyService.selectedAssetsArray);
  }
}
