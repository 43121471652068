import { AlertController, LoadingController } from '@ionic/angular';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BUSNUserService } from '@takectrl-multiapp/svcs-core-business';
import { SharedConfig, SharedDialogService } from '@takectrl-multiapp/svcs-share-config';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
declare const dataLayer: any[];

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  @Output() navigateLandingPage: EventEmitter<unknown> = new EventEmitter();
  @Output() navigateLoginPage: EventEmitter<unknown> = new EventEmitter();
  public clientName = this.sharedConfig.appClientDetail.display_name_full;
  public resetPasswordForm: FormGroup;
  private readonly loader = this.loadingCtrl.create();
  public displayEmail: string;
  showSmallPopup = false;
  smallPopupHeader: string;
  smallPopupMessage: string;
  constructor(
    public formBuilder: FormBuilder,
    private readonly busnUserService: BUSNUserService,
    public sharedConfig: SharedConfig,
    public thirdpartyService: BUSNThirdPartyService,
    private readonly alertCtrl: AlertController,
    private readonly loadingCtrl: LoadingController,
    public sharedDialogService: SharedDialogService
  ) {
    const emailPattern =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.resetPasswordForm = formBuilder.group({
      email: [SharedConfig.routeParamUserEmail, Validators.compose([Validators.required, Validators.pattern(emailPattern)])],
    });
  }

  ngOnInit(): void {
    this.displayEmail = SharedConfig.routeParamUserEmail;

    dataLayer.push({
      event: 'page_show',
      screenPath: 'forgot-password',
      screenName: 'forgot-password',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
    this.confirm = this.confirm.bind(this);
  }

  cancel() {
    this.navigateLandingPage.emit();
  }

  login() {
    this.navigateLoginPage.emit();
    SharedConfig.showSetNewPasswordButtonOnLogin = false;
    delete SharedConfig.routeParamUserMsisdn;
    delete SharedConfig.routeParamUserEmail;
  }

  async resetPassword() {
    this.sharedConfig.setShowCustomLoader(true)
    this.busnUserService.resetPassword(this.resetPasswordForm.value.email).then(
      async (user) => {
        this.sharedConfig.setShowCustomLoader(false)
        this.sharedDialogService.showPopupDialog({
          header: 'Reset password',
          subHeader: 'We just sent you a reset link to your email',
          buttonOneText: 'Ok',
          buttonOneCallback: this.confirm,
        });
      },
      async (error) => {
        this.sharedConfig.setShowCustomLoader(false)
        this.sharedDialogService.showPopupDialog({
          header: 'Something went wrong!',
          subHeader: error.message,
          buttonOneText: 'Ok',
          buttonOneCallback: this.sharedDialogService.hidePopupDialog,
        });
      }
    );
  }

  confirm() {
    this.navigateLandingPage.emit();
    this.sharedDialogService.hidePopupDialog();
  }
}
