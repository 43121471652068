import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { UiPagesModule } from '@takectrl-multiapp/ui-pages';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { Mixpanel, MixpanelPeople } from '@ionic-native/mixpanel/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { HttpClientModule } from '@angular/common/http';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    UiPagesModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true})

  ],
  providers: [
    StatusBar,
    LaunchNavigator,
    SplashScreen,
    // Turned off bugsnag for testing
    // { provide: ErrorHandler, useFactory: errorHandlerFactory },
    Mixpanel,
    MixpanelPeople,
    CallNumber,
    InAppBrowser,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}