import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BUSNQuestionService, BUSNQuoteScheduleService, BUSNUserService } from '@takectrl-multiapp/svcs-core-business';
import {
  AssetType,
  IQuestion,
  QuestionFilteringType,
  QuestionType,
  QuoteType,
  SharedConfig,
  SharedDialogService,
} from '@takectrl-multiapp/svcs-share-config';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { PageType } from 'libs/svcs-share-config/src/lib/types/page.type';
declare const dataLayer: any[];

@Component({
  selector: 'question-summary',
  templateUrl: './question-summary.component.html',
  styleUrls: ['./question-summary.component.scss'],
})
export class QuestionSummaryComponent implements OnInit {
  @Output() navigateQuoteNew: EventEmitter<void> = new EventEmitter<void>();
  @Output() navigateWalletPage: EventEmitter<void> = new EventEmitter<void>();
  @Output() navigateWalletAsset: EventEmitter<unknown> = new EventEmitter();
  @Output() navigateSummaryNotEditablePage: EventEmitter<unknown> = new EventEmitter();
  @Input() quoteType: QuoteType;
  @Input() onlyShowClaimsQuestion: string;
  @Input() endorsementUpdateRequiredData: any;
  @Input() filterByHeading: string;
  questionSelected: IQuestion;
  questionIndex: number;
  labelPosition = 'stacked';
  maxDate: number;
  error: string;
  showError = true;
  vehicleQuestion = {};
  addressQuestion = {};
  multiQuestion = {};
  selectedAssetUnderwritingQuestions: IQuestion[];
  readOnly = false;
  summary = true;
  public header = 'Review and confirm your information below.';
  public subHeader: string;
  showSelectPopup = false;
  tempStoreRegularDriverAnswer = '';
  tempStoreRegisteredOwnerAnswer = '';
  QuestionType = QuestionType;
  constructor(
    public busnQuoteScheduleService: BUSNQuoteScheduleService,
    public busnQuestionService: BUSNQuestionService,
    public busnThirdPartyService: BUSNThirdPartyService,
    public busnUserService: BUSNUserService,
    public sharedConfig: SharedConfig,
    private readonly sharedDialogService: SharedDialogService
  ) {
    SharedConfig.selectedPage = PageType.summary;
  }

  ngOnInit(): void {
    this.selectedAssetUnderwritingQuestions = this.busnQuestionService.getPersonalOrAssetSpecificQuestions(
      SharedConfig.user,
      JSON.parse(JSON.stringify(SharedConfig.underwritingQuestionsAll)),
      SharedConfig.selectedAssetUnderwritingQuestions[0].underwriting_answers[0].object_name,
      QuestionFilteringType.hideHiddenQuestionsForSummary,
      true,
      this.onlyShowClaimsQuestion === 'true' ? null : this.filterByHeading
    );

    const tempCheckRegularDriver = this.selectedAssetUnderwritingQuestions.filter(
      (x) => x.underwriting_answers[0].object_field === 'regularDriverRelationship'
    );
    const tempCheckRegisteredOwner = this.selectedAssetUnderwritingQuestions.filter(
      (x) => x.underwriting_answers[0].object_field === 'registeredOwnerRelationship'
    );
    if (tempCheckRegularDriver.length > 0) {
      this.tempStoreRegularDriverAnswer = tempCheckRegularDriver[0].underwriting_answers[0].object_answer;
    }

    if (tempCheckRegisteredOwner.length > 0) {
      this.tempStoreRegisteredOwnerAnswer = tempCheckRegisteredOwner[0].underwriting_answers[0].object_answer;
    }

    let currYear = new Date().getFullYear();
    currYear = currYear + 5;
    this.maxDate = currYear;
    if (this.quoteType === QuoteType.MyProfile) {
      this.navigateSummaryNotEditablePage.emit();
      this.header = `Welcome to ${this.sharedConfig.appClientDetail.display_name}`;
    }
    if (this.quoteType === QuoteType.ViewAssetOnly) {
      this.readOnly = true;
      this.header = 'View asset details below.';
      this.subHeader = `The information below is for your policy ${this.endorsementUpdateRequiredData.policy_id}`;
    }

    dataLayer.push({
      event: 'page_show',
      screenPath: 'question-summary',
      screenName: 'question-summary',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }

  populateSelectArray(questionSelect, index: number) {
    const intervalId = setInterval(() => {
      SharedConfig.showFullScreen = true;
      this.showSelectPopup = true;
      this.questionIndex = index;
      this.questionSelected = questionSelect;
      clearInterval(intervalId);
    }, 200);
  }

  closeSelectPopup() {
    SharedConfig.showFullScreen = false;
    this.showSelectPopup = false;
    this.questionIndex = null;
    this.questionSelected = null;
  }

  answerQuestionSelect(answeredQuestion: IQuestion, questionIndex: number) {
    let getPersonalOrAssetSpecificQuestions = true;
    if (
      answeredQuestion.underwriting_answers[0].object_field === 'drivingOffence' ||
      answeredQuestion.underwriting_answers[0].object_field === 'universityDegree'
    ) {
      getPersonalOrAssetSpecificQuestions = false;
    }
    this.answerQuestion(answeredQuestion, questionIndex, false);
  }

  answerQuestion(answeredQuestion: IQuestion, questionIndex: number, getPersonalOrAssetSpecificQuestions = false) {
    const isValid = this.busnQuoteScheduleService.isFieldValidationSuccess(answeredQuestion.underwriting_answers, answeredQuestion, null);
    if (!isValid) {
      answeredQuestion.underwriting_answers[0].object_answer_error = this.busnQuoteScheduleService.errorDescription;
      return;
    }

    if (answeredQuestion.type === QuestionType.multi) {
      answeredQuestion.underwriting_answers = answeredQuestion.underwriting_answers.map((answer) => {
        if (answer.object_answer === '') {
          answer.object_answer = '';
        } else {
          answer.object_answer = Number(answer.object_answer);
        }
        return answer;
      });
    }

    if (this.busnQuoteScheduleService.errorDescription) return;

    if (answeredQuestion.type === QuestionType.number) {
      answeredQuestion.underwriting_answers[0].object_answer = Number(answeredQuestion.underwriting_answers[0].object_answer);
    }

    this.selectedAssetUnderwritingQuestions[questionIndex] = answeredQuestion;

    if (
      this.selectedAssetUnderwritingQuestions[questionIndex].dependency &&
      this.selectedAssetUnderwritingQuestions[questionIndex].dependency.dependency_type === 'Parent'
    ) {
      // REMOVE DEPENENCY QUESTIONS ON FALSE
      if (this.selectedAssetUnderwritingQuestions[questionIndex].underwriting_answers[0].object_answer === false) {
        const childDependencyQuestions = this.selectedAssetUnderwritingQuestions.filter(
          (x) =>
            x.dependency &&
            x.dependency.dependency_key === this.selectedAssetUnderwritingQuestions[questionIndex].dependency.dependency_key &&
            x.dependency.dependency_type === 'Child' &&
            x.underwriting_answers[0].object_name === this.selectedAssetUnderwritingQuestions[questionIndex].underwriting_answers[0].object_name
        );
        if (
          childDependencyQuestions &&
          childDependencyQuestions.length > 0 &&
          this.selectedAssetUnderwritingQuestions[questionIndex].underwriting_answers[0].object_answer === false
        ) {
          for (const childQuestion of childDependencyQuestions) {
            for (const underwritingAnswer of childQuestion.underwriting_answers) {
              const childQuestionIndex = this.selectedAssetUnderwritingQuestions.findIndex(
                (x) => x.underwriting_answers[0].object_field === underwritingAnswer.object_field
              );
              this.selectedAssetUnderwritingQuestions.splice(childQuestionIndex, 1);
            }
          }
        }
        // ADD DEPENENCY QUESTIONS ON TRUE
      } else {
        const childDependencyQuestions = SharedConfig.underwritingQuestionsAll.filter(
          (x) =>
            x.dependency &&
            x.dependency.dependency_key === this.selectedAssetUnderwritingQuestions[questionIndex].dependency.dependency_key &&
            x.dependency.dependency_type === 'Child' &&
            x.underwriting_answers[0].object_name === this.selectedAssetUnderwritingQuestions[questionIndex].underwriting_answers[0].object_name
        );
        if (
          childDependencyQuestions &&
          childDependencyQuestions.length > 0 &&
          this.selectedAssetUnderwritingQuestions[questionIndex].underwriting_answers[0].object_answer === true
        ) {
          let currentIndexPlusOne = questionIndex;
          for (const childQuestion of childDependencyQuestions) {
            currentIndexPlusOne++;
            this.selectedAssetUnderwritingQuestions.splice(currentIndexPlusOne, 0, childQuestion);
          }
        }
      }
    }

    this.sharedConfig.setSelectedAssetObjectAnswer(answeredQuestion);

    if (getPersonalOrAssetSpecificQuestions) {
      const underwritingQuestions = this.busnQuestionService.getPersonalOrAssetSpecificQuestions(
        SharedConfig.user,
        JSON.parse(JSON.stringify(SharedConfig.underwritingQuestionsAll)),
        SharedConfig.selectedAssetUnderwritingQuestions[0].underwriting_answers[0].object_name,
        QuestionFilteringType.hideHiddenQuestionsForSummary,
        true,
        this.onlyShowClaimsQuestion === 'true' ? null : this.filterByHeading
      );

      for (let underwritingQuestion of underwritingQuestions) {
        const currentUnderwritingQuestion = this.selectedAssetUnderwritingQuestions.find(
          (x) => x.underwriting_answers[0].object_field === underwritingQuestion.underwriting_answers[0].object_field
        );

        underwritingQuestion = currentUnderwritingQuestion;
      }

      this.selectedAssetUnderwritingQuestions = underwritingQuestions;
    }
  }

  extraPersonal(question) {
    if (question.personChecker.source && question.personChecker.source.indexOf('extraPersonal') > -1) {
      const parentQuestionIndex = this.busnQuestionService.getPersonCheckParentIndex(question.personChecker.key);
      if (parentQuestionIndex > 1) {
        if (question.underwriting_answers[0].object_answer === question.personChecker.triggerWording) {
          const questions = [];
          questions.push(question);
          console.log(questions);
        }
      }
    }
  }

  async answeredQuestionSelect(selection: any, questionIndex: number) {
    if (
      (this.tempStoreRegularDriverAnswer.length > 0 && this.tempStoreRegularDriverAnswer === selection) ||
      (this.tempStoreRegisteredOwnerAnswer.length > 0 && this.tempStoreRegisteredOwnerAnswer === selection)
    ) {
      this.selectedAssetUnderwritingQuestions = this.busnQuestionService.getPersonalOrAssetSpecificQuestions(
        SharedConfig.user,
        JSON.parse(JSON.stringify(SharedConfig.underwritingQuestionsAll)),
        SharedConfig.selectedAssetUnderwritingQuestions[0].underwriting_answers[0].object_name,
        QuestionFilteringType.hideHiddenQuestionsForSummary,
        true,
        this.onlyShowClaimsQuestion === 'true' ? null : this.filterByHeading
      );
    }
    const answeredQuestion = this.selectedAssetUnderwritingQuestions[questionIndex];
    if (
      (answeredQuestion.underwriting_answers[0].object_field === 'regular_driver_relationship' ||
        answeredQuestion.underwriting_answers[0].object_field === 'registered_owner_relationship') &&
      answeredQuestion.underwriting_answers[0].object_answer !== selection &&
      this.tempStoreRegularDriverAnswer !== selection &&
      this.tempStoreRegisteredOwnerAnswer !== selection
    ) {
      for (const selectedAssetUnderwritingQuestion of this.selectedAssetUnderwritingQuestions) {
        delete selectedAssetUnderwritingQuestion.underwriting_answers[0].object_answer;
        delete selectedAssetUnderwritingQuestion.underwriting_answers[0].object_answer_error;
      }
    }
    delete this.selectedAssetUnderwritingQuestions[questionIndex].underwriting_answers[0].object_answer_error;
    this.selectedAssetUnderwritingQuestions[questionIndex].underwriting_answers[0].object_answer = selection;
    this.selectedAssetUnderwritingQuestions[questionIndex] = answeredQuestion;

    if (selection === 'Policyholder') {
      const parentQuestionIndex = await this.busnQuestionService.getPersonCheckParentIndex(answeredQuestion.personChecker.key);
      SharedConfig.selectedAssetUnderwritingQuestions[parentQuestionIndex].underwriting_answers[0].object_answer = selection;
      let i = questionIndex;
      for (const selectedAssetUnderwritingQuestion of this.selectedAssetUnderwritingQuestions) {
        this.selectedAssetUnderwritingQuestions[i].underwriting_answers = await this.busnQuestionService.autoPopulatePersonCheckerQuestions(
          selectedAssetUnderwritingQuestion
        );
        i++;
      }
    }

    this.closeSelectPopup();
  }

  async confirmChangesAndSave() {
    this.sharedConfig.setShowCustomLoader(true)
    const tempArrayForUndefinedAnswers = [];
    for (const selectedAssetUnderwritingQuestion of this.selectedAssetUnderwritingQuestions) {
      for (const underwritingAnswer of selectedAssetUnderwritingQuestion.underwriting_answers) {
        underwritingAnswer.object_answer_error = '';
        const fieldName = underwritingAnswer.object_field.replace('daytime', 'risk').replace('overnight', 'risk');
        if (fieldName === 'risk_address_code') {
          underwritingAnswer.object_field = 'postal_code';
        }
        if (selectedAssetUnderwritingQuestion.type === 'CLAIMLIST') {
          if (selectedAssetUnderwritingQuestion.underwriting_answers[0].object_answer.length <= 0) {
            selectedAssetUnderwritingQuestion.underwriting_answers[0].object_answer.push({
              date: Date.now(),
              date_full: Date.now(),
              reason: 'No claims',
              user_id: this.sharedConfig.user.id,
            });
          }
        }

        if (underwritingAnswer.object_field === 'monthly_mileage') {
          if (underwritingAnswer.object_answer > underwritingAnswer.max) {
            selectedAssetUnderwritingQuestion.underwriting_answers[0].object_answer_error = `Kilometers per month can't be more than ${underwritingAnswer.max}`;
            tempArrayForUndefinedAnswers.push({ answerUndefined: true });
          } else {
            tempArrayForUndefinedAnswers.push({ answerUndefined: false });
          }
        }

        if (underwritingAnswer.object_field === 'days_work_from_home') {
          const check_for_negative_numbers = Math.sign(underwritingAnswer.object_answer);
          if (underwritingAnswer.object_answer > underwritingAnswer.max) {
            selectedAssetUnderwritingQuestion.underwriting_answers[0].object_answer_error = 'Exceeding max number of days in a week';
            tempArrayForUndefinedAnswers.push({ answerUndefined: true });
          } else {
            tempArrayForUndefinedAnswers.push({ answerUndefined: false });
          }
          if (check_for_negative_numbers === -1) {
            selectedAssetUnderwritingQuestion.underwriting_answers[0].object_answer_error =
              'Please provide a valid number (no decimal or negative numbers allowed)';
            tempArrayForUndefinedAnswers.push({ answerUndefined: true });
          } else {
            tempArrayForUndefinedAnswers.push({ answerUndefined: false });
          }
        }

        if (underwritingAnswer.object_answer === undefined && selectedAssetUnderwritingQuestion.type !== 'CLAIMLIST') {
          selectedAssetUnderwritingQuestion.underwriting_answers[0].object_answer_error = 'Please provide an answer';
          tempArrayForUndefinedAnswers.push({ answerUndefined: true });
        } else {
          tempArrayForUndefinedAnswers.push({ answerUndefined: false });
        }
      }
    }
    const checkForUndefinedAnswers = tempArrayForUndefinedAnswers.find((x) => x.answerUndefined);
    const validationError = this.selectedAssetUnderwritingQuestions.find((x) => x.underwriting_answers.find((y) => y.object_answer_error));

    if (!validationError && !checkForUndefinedAnswers) {
      if (
        this.selectedAssetUnderwritingQuestions[0].underwriting_answers[0].object_name === AssetType.UserPersonalDetails ||
        this.selectedAssetUnderwritingQuestions[0].underwriting_answers[0].object_name === AssetType.UserInsuranceHistory
      ) {
        await this.busnUserService.updateUserDetailsAnswers(
          this.selectedAssetUnderwritingQuestions.filter((x) => x.underwriting_answers[0].object_name === AssetType.UserPersonalDetails)
        );

        await this.busnUserService.updateUserInsuranceHistories(
          this.selectedAssetUnderwritingQuestions.filter((x) => x.underwriting_answers[0].object_name === AssetType.UserInsuranceHistory)
        );
      } else {
        await this.busnQuoteScheduleService.saveQuoteQuestions(
          this.selectedAssetUnderwritingQuestions,
          this.sharedConfig.getSelectedAsset(this.selectedAssetUnderwritingQuestions[0].underwriting_answers[0].object_name)
        );
      }
      this.sharedConfig.setShowCustomLoader(false)
      this.navigateSummaryNotEditablePage.emit();
    } else {
      this.sharedConfig.setShowCustomLoader(false)
      this.sharedDialogService.showPopupDialog({
        header: 'Something seem off',
        subHeader: 'Please recheck your answers and ensure they are all complete.',
        buttonOneText: 'OK',
        buttonOneCallback: this.sharedDialogService.hidePopupDialog,
      });
    }
  }

  done() {
    this.navigateWalletAsset.emit();
  }
}
