import { Injectable } from '@angular/core';
import {
  IAllRiskItem,
  IBuilding,
  ICaravan,
  IContent,
  IDeleteAssets,
  IMotorcycle,
  ISpecifiedItem,
  IVehicle,
} from '@takectrl-multiapp/svcs-share-config';
import { APIHttpService } from './api-http.service';
import { Observable } from 'rxjs';
import { ICtrlResponse } from '../interfaces/api.interface';

@Injectable({
  providedIn: 'root',
})
export class APIAssetService {
  constructor(private readonly ctrlHttp: APIHttpService) {}

  getContents(): Observable<ICtrlResponse<Array<IContent>>> {
    return this.ctrlHttp.get('/contents/');
  }
  
  getVehicles(): Observable<ICtrlResponse<Array<IVehicle>>> {
    return this.ctrlHttp.get('/vehicles/');
  }
  
  getVehicle(asset_id: number): Observable<ICtrlResponse<IVehicle>> {
    return this.ctrlHttp.get(`/vehicles/${asset_id}/`);
  }
  
  getMotorcycle(asset_id: number): Observable<ICtrlResponse<IMotorcycle>> {
    return this.ctrlHttp.get(`/motorcycles/${asset_id}/`);
  }
  
  getCaravan(asset_id: number): Observable<ICtrlResponse<ICaravan>> {
    return this.ctrlHttp.get(`/caravans/${asset_id}/`);
  }
  
  getBuilding(asset_id: number): Observable<ICtrlResponse<IBuilding>> {
    return this.ctrlHttp.get(`/buildings/${asset_id}/`);
  }
  
  getContent(asset_id: number): Observable<ICtrlResponse<IContent>> {
    return this.ctrlHttp.get(`/contents/${asset_id}/`);
  }
  
  getSpecifiedItem(asset_id: number): Observable<ICtrlResponse<ISpecifiedItem>> {
    return this.ctrlHttp.get(`/specified-items/${asset_id}/`);
  }
  
  getAllRisk(asset_id: number): Observable<ICtrlResponse<IAllRiskItem>> {
    return this.ctrlHttp.get(`/all-risks/${asset_id}/`);
  }
  
  getMotorcycles(): Observable<ICtrlResponse<Array<IMotorcycle>>> {
    return this.ctrlHttp.get('/motorcycles/');
  }
  
  getCaravans(): Observable<ICtrlResponse<Array<ICaravan>>> {
    return this.ctrlHttp.get('/caravans/');
  }
  
  getBuildings(): Observable<ICtrlResponse<Array<IBuilding>>> {
    return this.ctrlHttp.get('/buildings/');
  }
  
  getAllRiskItems(): Observable<ICtrlResponse<Array<IAllRiskItem>>> {
    return this.ctrlHttp.get('/all-risks/');
  }
  
  getSpecifiedItems(): Observable<ICtrlResponse<Array<ISpecifiedItem>>> {
    return this.ctrlHttp.get('/specified-items/');
  }
  
  addBuilding(building: IBuilding): Observable<ICtrlResponse<IBuilding>> {
    return this.ctrlHttp.post('/buildings/', building);
  }
  
  addContent(content: IContent): Observable<ICtrlResponse<IContent>> {
    return this.ctrlHttp.post<ICtrlResponse<IContent>>('/contents/', content);
  }
  
  addAllRiskItem(all_risks: IAllRiskItem): Observable<ICtrlResponse<IAllRiskItem>> {
    return this.ctrlHttp.post<ICtrlResponse<IAllRiskItem>>('/all-risks/', all_risks);
  }
  
  addSpecifiedItem(specified_item: ISpecifiedItem): Observable<ICtrlResponse<ISpecifiedItem>> {
    return this.ctrlHttp.post<ICtrlResponse<ISpecifiedItem>>('/specified-items/', specified_item);
  }
  
  removeAsset(deleteAsset: IDeleteAssets, quoteRequestId: number): Observable<ICtrlResponse<any>> {
    return this.ctrlHttp.post<ICtrlResponse<any>>(`/quote-requests/${quoteRequestId}/remove/assets/`, deleteAsset);
  }
  
  updateVehicle(vehicle: IVehicle): Observable<ICtrlResponse<IVehicle>> {
    return this.ctrlHttp.patch<ICtrlResponse<IVehicle>>(`/vehicles/${vehicle.id}/`, vehicle);
  }
  
  addVehicle(vehicle: IVehicle): Observable<ICtrlResponse<IVehicle>> {
    return this.ctrlHttp.post<ICtrlResponse<IVehicle>>('/vehicles/', vehicle);
  }
  
  updateMotorcycle(motorcycle: IMotorcycle): Observable<ICtrlResponse<IMotorcycle>> {
    return this.ctrlHttp.patch<ICtrlResponse<IMotorcycle>>(`/motorcycles/${motorcycle.id}/`, motorcycle);
  }
  
  addMotorcycle(motorcycle: IMotorcycle): Observable<ICtrlResponse<IMotorcycle>> {
    return this.ctrlHttp.post<ICtrlResponse<IMotorcycle>>('/motorcycles/', motorcycle);
  }
  
  updateCaravan(caravan: ICaravan): Observable<ICtrlResponse<ICaravan>> {
    return this.ctrlHttp.patch<ICtrlResponse<ICaravan>>(`/caravans/${caravan.id}/`, caravan);
  }
  
  addCaravan(caravan: ICaravan): Observable<ICtrlResponse<ICaravan>> {
    return this.ctrlHttp.post<ICtrlResponse<ICaravan>>('/caravans/', caravan);
  }
  
  updateBuilding(building: IBuilding): Observable<ICtrlResponse<IBuilding>> {
    return this.ctrlHttp.patch<ICtrlResponse<IBuilding>>(`/buildings/${building.id}/`, building);
  }
  
  updateContent(content: IContent): Observable<ICtrlResponse<IContent>> {
    return this.ctrlHttp.patch<ICtrlResponse<IContent>>(`/contents/${content.id}/`, content);
  }
  
  updateAllRiskItem(all_risks: IAllRiskItem): Observable<ICtrlResponse<IAllRiskItem>> {
    return this.ctrlHttp.patch<ICtrlResponse<IAllRiskItem>>(`/all-risks/${all_risks.id}/`, all_risks);
  }
  
  updateSpecifiedItem(specified_item: ISpecifiedItem): Observable<ICtrlResponse<ISpecifiedItem>> {
    return this.ctrlHttp.patch<ICtrlResponse<ISpecifiedItem>>(`/specified-items/${specified_item.id}/`, specified_item);
  }
  
  getVehicleModelByModelID(year: string, model_id: string): Observable<any> {
    return this.ctrlHttp.get(`/vehicles/lookup/${model_id}/${year}/?should_return_snake_case=true`);
  }
}
