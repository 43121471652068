<div class="wrapper limit-width-on-desktop">
  <div class="head-section">
    <div>
      <h2 class="h2-bold">Hi {{ sharedConfig.routeParamUserFirstName }} {{ sharedConfig.routeParamUserLastName }} 👋</h2>
      <p>
        We sent a one-time pin to <b>{{ displayNumber }}</b>
      </p>
    </div>
  </div>
  <div class="login-form">
    <div id="recaptcha-container"></div>
    <div *ngIf="sharedConfig.routeParamUserMsisdn || showOTPScreen">
      <ion-grid class="otp-grid">
        <ion-row>
          <ion-col size="2"
            ><ion-input
              autofocus="true"
              [ngClass]="{ 'otp-input-focus': hasFocus === 'otpOne', 'otp-input-error': otpError }"
              (ionFocus)="setFocusOne('otpOne')"
              (ionInput)="onChangeOtpInputOne($event)"
              #otpInputOne
              class="otp-input"
              inputmode="numeric"
              maxlength="{{ maxLength }}"
              pattern="[0-9]*"
              type="text"
              value="{{ verificationCodeInputOne }}"
            ></ion-input
          ></ion-col>
          <ion-col size="2"
            ><ion-input
              [ngClass]="{ 'otp-input-focus': hasFocus === 'otpTwo', 'otp-input-error': otpError }"
              (ionFocus)="setFocusOne('otpTwo')"
              (keydown)="backspaceInputTwo($event)"
              (ionInput)="onChangeOtpInputTwo($event)"
              #otpInputTwo
              class="otp-input"
              inputmode="numeric"
              maxlength="1"
              pattern="[0-9]*"
              type="text"
              value="{{ verificationCodeInputTwo }}"
            ></ion-input
          ></ion-col>
          <ion-col size="2"
            ><ion-input
              [ngClass]="{ 'otp-input-focus': hasFocus === 'otpThree', 'otp-input-error': otpError }"
              (ionFocus)="setFocusOne('otpThree')"
              (keydown)="backspaceInputThree($event)"
              (ionInput)="onChangeOtpInputThree($event)"
              #otpInputThree
              class="otp-input"
              inputmode="numeric"
              maxlength="1"
              pattern="[0-9]*"
              type="text"
              value="{{ verificationCodeInputThree }}"
            ></ion-input
          ></ion-col>
          <ion-col size="2"
            ><ion-input
              [ngClass]="{ 'otp-input-focus': hasFocus === 'otpFour', 'otp-input-error': otpError }"
              (ionFocus)="setFocusOne('otpFour')"
              (keydown)="backspaceInputFour($event)"
              (ionInput)="onChangeOtpInputFour($event)"
              #otpInputFour
              class="otp-input"
              inputmode="numeric"
              maxlength="1"
              pattern="[0-9]*"
              type="text"
              value="{{ verificationCodeInputFour }}"
            ></ion-input
          ></ion-col>
          <ion-col size="2"
            ><ion-input
              [ngClass]="{ 'otp-input-focus': hasFocus === 'otpFive', 'otp-input-error': otpError }"
              (ionFocus)="setFocusOne('otpFive')"
              (keydown)="backspaceInputFive($event, 'otpInputFive')"
              (ionInput)="onChangeOtpInputFive($event)"
              #otpInputFive
              class="otp-input"
              inputmode="numeric"
              maxlength="1"
              pattern="[0-9]*"
              type="text"
              value="{{ verificationCodeInputFive }}"
            ></ion-input
          ></ion-col>
          <ion-col size="2"
            ><ion-input
              [ngClass]="{ 'otp-input-focus': hasFocus === 'otpSix', 'otp-input-error': otpError }"
              (ionFocus)="setFocusOne('otpSix')"
              (keydown)="backspaceInputSix($event, 'otpInputSix')"
              (ionInput)="onChangeOtpInputSix($event)"
              #otpInputSix
              class="otp-input"
              inputmode="numeric"
              maxlength="1"
              pattern="[0-9]*"
              type="text"
              value="{{ verificationCodeInputSix }}"
            ></ion-input
          ></ion-col>
        </ion-row>
      </ion-grid>
      <div class="resend-div">
        <ion-grid class="resend-grid">
          <ion-row>
            <ion-col *ngIf="!didResend && !showLoader" size="5"><a class="bold pointer" (click)="sendOTP()">Resend code</a></ion-col>
            <ion-col *ngIf="showLoader"><div class="loader"></div></ion-col>
            <ion-col *ngIf="didResend" size="5">
              <div class="resent-wrapper">
                <ion-icon src="assets/icon/genetic-icons/checkbox-tick.svg" class="icon-fill-success"></ion-icon>
                <p class="bold code-sent">Code sent</p>
              </div>
            </ion-col>
            <ion-col class="text-right" size="7"><small *ngIf="otpError" class="error-message">Seems the OTP is incorrect</small></ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </div>
  </div>
</div>

<!-- <set-new-password *ngIf="sharedConfig.showSetNewPasswordComponent" (finishedPasswordUpdate)="compFinishedPasswordUpdate($event)" [showRegisterSuccess]="showRegisterSuccess"></set-new-password> -->

<div class="footer-banner" *ngIf="sharedConfig.routeParamUserMsisdn || showOTPScreen">
  <ion-grid class="limit-width-on-desktop">
    <ion-row>
      <ion-col size="7">Not receiving a code?</ion-col>
      <ion-col class="text-right" size="5"><a class="bold pointer" (click)="setupViaEmail()">Setup via email</a></ion-col>
    </ion-row>
  </ion-grid>
</div>

<custom-loader *ngIf="sharedConfig.showCustomLoader"></custom-loader>

<popup-dialog  id="loginWithOtp.component" *ngIf="sharedConfig.popupDialogShow"></popup-dialog>
