import { SharedConfig, IPriorityRating } from '@takectrl-multiapp/svcs-share-config';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BusnNeedsAnalysisService } from '@takectrl-multiapp/svcs-core-business';
declare const dataLayer: any[];

@Component({
  selector: 'needs-sliders',
  templateUrl: './needs-sliders.component.html',
  styleUrls: ['./needs-sliders.component.scss'],
})
export class NeedsSlidersComponent implements OnInit {
  @Output() navigateToNeedsResults: EventEmitter<unknown> = new EventEmitter<unknown>();
  public priorityRatings = Array<IPriorityRating>();
  public premium_vs_core_cover = 0;
  public premium_vs_nice_to_haves = 0;
  public premium_vs_low_excess = 0;
  public core_cover_vs_nice_to_haves = 0;
  public core_cover_vs_low_excess = 0;
  public nice_to_haves_vs_low_excess = 0;
  explainerPopup = false;
  constructor(public busnNeedsAnalysisService: BusnNeedsAnalysisService, public sharedConfig: SharedConfig) {}

  ngOnInit(): void {
    this.busnNeedsAnalysisService.getQuoteFNA().then((result) => {
      if (result !== null) {
        this.premium_vs_core_cover = result.premium_vs_core_cover;
        this.premium_vs_nice_to_haves = result.premium_vs_nice_to_haves;
        this.premium_vs_low_excess = result.premium_vs_low_excess;
        this.core_cover_vs_nice_to_haves = result.core_cover_vs_nice_to_haves;
        this.core_cover_vs_low_excess = result.core_cover_vs_low_excess;
        this.nice_to_haves_vs_low_excess = result.nice_to_haves_vs_low_excess;
      }
    });
    if (this.sharedConfig.quoteRequest.needs_analysis_completed) {
      this.getRatiosAndGoNext('Results');
    }

    dataLayer.push({
      event: 'page_show',
      screenPath: 'needs-sliders',
      screenName: 'needs-sliders',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }

  showExplainerPopup() {
    SharedConfig.showFullScreen = true;
    this.explainerPopup = true;
  }

  closeExplainerPopup() {
    SharedConfig.showFullScreen = false;
    this.explainerPopup = false;
  }

  async getRatiosAndGoNext(pageName: string) {
    this.sharedConfig.setShowCustomLoader(true)
    this.busnNeedsAnalysisService
      .updateFNA(
        this.premium_vs_core_cover,
        this.premium_vs_nice_to_haves,
        this.premium_vs_low_excess,
        this.core_cover_vs_nice_to_haves,
        this.core_cover_vs_low_excess,
        this.nice_to_haves_vs_low_excess
      )
      .then((needsAnalysis) => {
        this.priorityRatings = needsAnalysis;

        function compare(a, b) {
          if (a.value < b.value) return 1;
          if (a.value > b.value) return -1;
          return 0;
        }

        this.priorityRatings.sort(compare);
        this.priorityRatings[0].ranking_position = '1';
        this.priorityRatings[1].ranking_position = '2';
        this.priorityRatings[2].ranking_position = '3';
        this.priorityRatings[3].ranking_position = '4';

        this.sharedConfig.setShowCustomLoader(false)
        this.navigateToNeedsResults.emit({ pageName: pageName, priorityRatings: this.priorityRatings });
      });
  }
}
