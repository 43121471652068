<div class="sticky-header">
  <quote-comparison-top-vs-view
    [selectedQuoteToCompareOne]="selectedQuoteToCompareOne"
    [selectedQuoteToCompareTwo]="selectedQuoteToCompareTwo"
    (incrementQuoteOne)="compIncrementQuoteOne()"
    (incrementQuoteTwo)="compIncrementQuoteTwo()"
  ></quote-comparison-top-vs-view>
</div>
<div class="content-wrapper">
  <div class="card-wrapper special-card-padding">
    <ion-grid>
      <ion-row class="grid-row">
        <div class="rating">
          <div class="mandate">
            <ion-icon *ngIf="!selectedQuoteToCompareOne.mandate_violations_draft" src="assets/icon/app-icons/mandate-grey.svg"> </ion-icon>
            <ion-icon
              (click)="mandate()"
              *ngIf="selectedQuoteToCompareOne.mandate_violations_draft"
              src="assets/icon/app-icons/mandate.svg"
            ></ion-icon>
          </div>
          <h1>{{ selectedQuoteToCompareOne.rating_ctrl * 100 | number : '2.0-0' }}</h1>
        </div>
        <div class="heading-wrapper">
          <p><b>Overall rating</b></p>
          <small>Based on your preferences</small>
        </div>
        <div class="rating justify-right">
          <h1>{{ selectedQuoteToCompareTwo.rating_ctrl * 100 | number : '2.0-0' }}</h1>
          <div class="mandate">
            <ion-icon *ngIf="!selectedQuoteToCompareTwo.mandate_violations_draft" src="assets/icon/app-icons/mandate-grey.svg"> </ion-icon>
            <ion-icon
              (click)="mandate()"
              *ngIf="selectedQuoteToCompareTwo.mandate_violations_draft"
              src="assets/icon/app-icons/mandate.svg"
            ></ion-icon>
          </div>
        </div>
      </ion-row>
    </ion-grid>
  </div>
  <!-- Essential cover -->
  <div class="card-wrapper" (click)="navEsssentialCover()">
    <ion-grid>
      <ion-row>
        <div class="heading-wrapper">
          <p><b>Essential cover</b></p>
          <small>The crucial to have cover</small>
        </div>
      </ion-row>
    </ion-grid>
    <div class="ratings-progress-wrapper">
      <quote-rating-indicator
        [selectedQuoteToCompareRatingOne]="selectedQuoteToCompareOne.rating_essential"
        [selectedQuoteToCompareRatingTwo]="selectedQuoteToCompareTwo.rating_essential"
        [selectedQuoteToCompareRecommendedOne]="selectedQuoteToCompareOne.recommended"
        [selectedQuoteToCompareRecommendedTwo]="selectedQuoteToCompareTwo.recommended"
      ></quote-rating-indicator>
    </div>
  </div>
  <!-- Convenience cover -->
  <div class="card-wrapper" (click)="navConvenienceCover()">
    <ion-grid>
      <ion-row>
        <div class="heading-wrapper">
          <p><b>Convenience cover</b></p>
          <small>The nice to have cover</small>
        </div>
      </ion-row>
    </ion-grid>
    <div class="ratings-progress-wrapper">
      <quote-rating-indicator
        [selectedQuoteToCompareRatingOne]="selectedQuoteToCompareOne.rating_convenience"
        [selectedQuoteToCompareRatingTwo]="selectedQuoteToCompareTwo.rating_convenience"
        [selectedQuoteToCompareRecommendedOne]="selectedQuoteToCompareOne.recommended"
        [selectedQuoteToCompareRecommendedTwo]="selectedQuoteToCompareTwo.recommended"
      ></quote-rating-indicator>
    </div>
  </div>
  <!-- Excess -->
  <div class="card-wrapper" (click)="navExcessComparison()">
    <ion-grid>
      <ion-row>
        <div class="heading-wrapper">
          <p><b>Excess</b></p>
          <small>Amount you pay when you claim</small>
        </div>
      </ion-row>
    </ion-grid>
    <div class="ratings-progress-wrapper">
      <quote-rating-indicator
        [selectedQuoteToCompareRatingOne]="selectedQuoteToCompareOne.rating_excess"
        [selectedQuoteToCompareRatingTwo]="selectedQuoteToCompareTwo.rating_excess"
        [selectedQuoteToCompareRecommendedOne]="selectedQuoteToCompareOne.recommended"
        [selectedQuoteToCompareRecommendedTwo]="selectedQuoteToCompareTwo.recommended"
      ></quote-rating-indicator>
    </div>
  </div>
  <!-- Premiums -->
  <div class="card-wrapper" (click)="navPremium()">
    <ion-grid>
      <ion-row>
        <div class="heading-wrapper">
          <p><b>Premium</b></p>
          <small>Amount you pay per month</small>
        </div>
      </ion-row>
    </ion-grid>
  </div>
  <!-- Special conditions -->
  <div class="card-wrapper" (click)="navSpecialConditions()">
    <ion-grid>
      <ion-row>
        <div class="heading-wrapper">
          <p><b>Special conditions</b></p>
          <small>Things to note</small>
        </div>
      </ion-row>
    </ion-grid>
  </div>
  <!-- Optional cover -->
  <div class="card-wrapper" (click)="navOptionalCover()">
    <ion-grid>
      <ion-row>
        <div class="heading-wrapper">
          <p><b>Optional cover</b></p>
          <small>Extra cover that you can choose</small>
        </div>
      </ion-row>
    </ion-grid>
  </div>
</div>
<div class="banner-padding" *ngIf="sharedConfig.quoteRequest.status === QuoteRequestStatusType.WaitingOnQuotes"></div>
<div class="banner" *ngIf="sharedConfig.quoteRequest.status === QuoteRequestStatusType.WaitingOnQuotes">
  <div class="banner-inner">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fill-rule="evenodd">
        <g>
          <g>
            <g>
              <path
                class="icon-fill-light"
                d="M12 3c4.97 0 9 4.03 9 9s-4.03 9-9 9-9-4.03-9-9 4.03-9 9-9z"
                transform="translate(-78 -734) translate(0 726) translate(78 8)"
              />
              <path
                class="icon-fill-endorsement-tick"
                d="M14.964 7.678L16.036 8.322 11.188 16.402 7.137 13.509 7.863 12.491 10.812 14.598z"
                transform="translate(-78 -734) translate(0 726) translate(78 8)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
    <p>We are busy getting your quotes</p>
  </div>
</div>
<div class="compare-button-row">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-button
          class="choose-quote-button"
          mode="ios"
          [disabled]="sharedConfig.quoteRequest.status !== QuoteRequestStatusType.WaitingToAccept"
          (click)="selectQuote(selectedQuoteToCompareOne)"
          >Pick me</ion-button
        >
      </ion-col>
      <ion-col>
        <ion-button
          class="choose-quote-button"
          mode="ios"
          [disabled]="sharedConfig.quoteRequest.status !== QuoteRequestStatusType.WaitingToAccept"
          (click)="selectQuote(selectedQuoteToCompareTwo)"
          >Pick me</ion-button
        >
      </ion-col>
    </ion-row>
  </ion-grid>
</div>

<popup-dialog id="quote.comparison.component" *ngIf="sharedConfig.popupDialogShow"></popup-dialog>
