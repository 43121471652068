import { Component, Input, OnInit } from '@angular/core';
import { BUSNPolicyService } from '@takectrl-multiapp/svcs-core-business';
import { LoadingController } from '@ionic/angular';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import {
  IQuote,
  SharedObjectTransformationService,
  QuoteComparisonDetailType,
  SharedConfig,
  ISpecialConditionAssets,
  ISpecialConditionClients,
  AssetType,
  CoverType,
  IQuoteCompareDetails,
  IQuoteCompValues,
  IQuoteCompareGroupItems,
  IActiveQuoteAssetTabDetails,
  IInsuredAsset,
  IPersonalLiabilityCompare,
} from '@takectrl-multiapp/svcs-share-config';
declare const dataLayer: any[];

@Component({
  selector: 'quote-comparison-details',
  templateUrl: './quote-comparison-details.component.html',
  styleUrls: ['./quote-comparison-details.component.scss'],
})
export class QuoteComparisonDetailsComponent implements OnInit {
  @Input() quoteComparisonDetailType: QuoteComparisonDetailType;
  selectedQuoteToCompareOne: IQuote;
  selectedQuoteToCompareTwo: IQuote;
  public detailType: string;
  public insurerIdQuoteCompareOne: string;
  public insurerIdQuoteCompareTwo: string;
  public convenience: Array<IQuoteCompValues>;
  convenienceQuoteOne: Array<IQuoteCompareGroupItems>;
  convenienceQuoteTwo: Array<IQuoteCompareGroupItems>;
  public essential: Array<IQuoteCompValues>;
  essentialQuoteOne: Array<IQuoteCompareGroupItems>;
  essentialQuoteTwo: Array<IQuoteCompareGroupItems>;
  public subBenefitGroup: Array<IQuoteCompValues>;
  public subBenefitGroupHeader: IQuoteCompValues;
  public specialCondition: Array<unknown>;
  public selectedAssetSpecialConditions = [];
  public quoteOneSelectedAsset: Array<IInsuredAsset> | IPersonalLiabilityCompare;
  public quoteTwoSelectedAsset: Array<IInsuredAsset> | IPersonalLiabilityCompare;
  selectedQuoteToCompareOneSasria: number;
  selectedQuoteToCompareTwoSasria: number;

  compareQuoteSasriaOne: number;
  compareQuoteSasriaTwo: number;

  activeTabType: string;

  special_conditions: ISpecialConditionAssets;
  activeQuoteAssetTabCoveredDetails: IActiveQuoteAssetTabDetails;
  quoteComparisonAssetTabs: Array<IActiveQuoteAssetTabDetails> = [];
  quoteTabslength: number;

  specialConditionsAllRisk: ISpecialConditionClients;
  specialConditionsVehicles: ISpecialConditionClients;
  specialConditionsMotorcycles: ISpecialConditionClients;
  specialConditionsCaravans: ISpecialConditionClients;
  specialConditionsContents: ISpecialConditionClients;
  specialConditionsBuildings: ISpecialConditionClients;
  specialConditionsMock: Object;
  load = this.loading.create();
  quoteCompareDetailsArray: Array<IQuoteCompareDetails> = [];
  disableRight: boolean;
  public compareQuoteOneAllRisk: Array<unknown>; // need allRisk on quote
  public compareQuoteTwoAllRisk: Array<unknown>; // need allRisk on quote
  public compareQuoteOneSpecifiedItems: Array<unknown>; // need SpecifiedItems on quote
  public compareQuoteTwoSpecifiedItems: Array<unknown>; // need SpecifiedItems on quote
  public quoteRequestSpecifiedItems: Array<unknown>; // need SpecifiedItems on quote
  quoteOneComprehensive: any;
  quoteTwoComprehensive: any;
  benefits: any;
  QuoteComparisonDetailType = QuoteComparisonDetailType;
  AssetType = AssetType;

  constructor(
    private readonly sharedObjectTransformationService: SharedObjectTransformationService,
    public sharedConfig: SharedConfig,
    private readonly busnPolicyService: BUSNPolicyService,
    private readonly loading: LoadingController,
    private readonly busnThirdPartyService: BUSNThirdPartyService
  ) {}

  ngOnInit(): void {
    dataLayer.push({
      event: 'page_show',
      screenPath: 'quote-comparison-details',
      screenName: 'quote-comparison-details',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });

    this.loader(true);
    this.selectedQuoteToCompareOne = SharedConfig.selectedQuoteToCompareOne;
    this.selectedQuoteToCompareTwo = SharedConfig.selectedQuoteToCompareTwo;
    this.busnPolicyService.getAllPolicyBenefits(CoverType.Comprehensive).then((benefits) => {
      this.benefits = benefits;
      this.calculateQuoteComparisonDetails();
    });
  }

  async loader(loadStatus: boolean) {
    if (loadStatus) {
      this.sharedConfig.setShowCustomLoader(true)
    } else if (!loadStatus) {
      this.sharedConfig.setShowCustomLoader(false)
    }
  }

  compIncrementQuoteOne() {
    this.selectedQuoteToCompareOne = SharedConfig.selectedQuoteToCompareOne =
      this.sharedObjectTransformationService.incrementGloballyComparedQuoteOne(
        this.sharedConfig.quoteRequest.quotes,
        this.selectedQuoteToCompareOne,
        this.selectedQuoteToCompareTwo
      );
    this.updateQuoteSharedConfig();
    this.selectedQuoteToCompareOneSasria = this.selectedQuoteToCompareOne.sasria;
    this.selectedQuoteToCompareTwoSasria = this.selectedQuoteToCompareTwo.sasria;
  }

  compIncrementQuoteTwo() {
    this.selectedQuoteToCompareTwo = SharedConfig.selectedQuoteToCompareTwo =
      this.sharedObjectTransformationService.incrementGloballyComparedQuoteTwo(
        this.sharedConfig.quoteRequest.quotes,
        this.selectedQuoteToCompareOne,
        this.selectedQuoteToCompareTwo
      );
    this.updateQuoteSharedConfig();
    this.selectedQuoteToCompareOneSasria = this.selectedQuoteToCompareOne.sasria;
    this.selectedQuoteToCompareTwoSasria = this.selectedQuoteToCompareTwo.sasria;
  }

  compSetActiveComparisonAssetTab(quoteComparisonAssetTab: IActiveQuoteAssetTabDetails) {
    this.selectAsset(quoteComparisonAssetTab);
    this.updateQuoteSharedConfig();
  }

  getQuoteComparisonAssetTabs() {
    const requestedAssets = this.sharedConfig.quoteRequest.assets;
    if (requestedAssets.vehicles) {
      this.setAssetDetails(AssetType.Vehicles);
      this.quoteCompareDetailsArray.push({
        name: 'Vehicle',
        type: AssetType.Vehicles,
        active: false,
        essentialDetails: {
          quote_comp_one: this.benefits[this.selectedQuoteToCompareOne.insurer_id][AssetType.Vehicles].benefit_groups[0],
          quote_comp_two: this.benefits[this.selectedQuoteToCompareTwo.insurer_id][AssetType.Vehicles].benefit_groups[0],
        },
        convenienceDetails: {
          quote_comp_one: this.benefits[this.selectedQuoteToCompareOne.insurer_id][AssetType.Vehicles].benefit_groups[1],
          quote_comp_two: this.benefits[this.selectedQuoteToCompareTwo.insurer_id][AssetType.Vehicles].benefit_groups[1],
        },
        special_conditions: [this.special_conditions.vehicles],
        quoteCompareOne: SharedConfig.selectedQuoteToCompareOne.quote_vehicles,
        quoteCompareTwo: SharedConfig.selectedQuoteToCompareTwo.quote_vehicles,
      });
    }
    if (requestedAssets.motorcycles) {
      this.setAssetDetails(AssetType.Motorcycles);
      this.quoteCompareDetailsArray.push({
        name: AssetType.Motorcycles,
        type: AssetType.Motorcycles,
        active: false,
        essentialDetails: {
          quote_comp_one: this.benefits[this.selectedQuoteToCompareOne.insurer_id][AssetType.Motorcycles].benefit_groups[0],
          quote_comp_two: this.benefits[this.selectedQuoteToCompareTwo.insurer_id][AssetType.Motorcycles].benefit_groups[0],
        },
        convenienceDetails: {
          quote_comp_one: this.benefits[this.selectedQuoteToCompareOne.insurer_id][AssetType.Motorcycles].benefit_groups[1],
          quote_comp_two: this.benefits[this.selectedQuoteToCompareTwo.insurer_id][AssetType.Motorcycles].benefit_groups[1],
        },
        special_conditions: [this.special_conditions.motorcycles],
        quoteCompareOne: SharedConfig.selectedQuoteToCompareOne.quote_motorcycles,
        quoteCompareTwo: SharedConfig.selectedQuoteToCompareTwo.quote_motorcycles,
      });
    }
    if (requestedAssets.caravans) {
      this.setAssetDetails(AssetType.Caravans);
      this.quoteCompareDetailsArray.push({
        name: AssetType.Caravans,
        type: AssetType.Caravans,
        active: false,
        essentialDetails: {
          quote_comp_one: this.benefits[this.selectedQuoteToCompareOne.insurer_id][AssetType.Caravans].benefit_groups[0],
          quote_comp_two: this.benefits[this.selectedQuoteToCompareTwo.insurer_id][AssetType.Caravans].benefit_groups[0],
        },
        convenienceDetails: {
          quote_comp_one: this.benefits[this.selectedQuoteToCompareOne.insurer_id][AssetType.Caravans].benefit_groups[1],
          quote_comp_two: this.benefits[this.selectedQuoteToCompareTwo.insurer_id][AssetType.Caravans].benefit_groups[1],
        },
        special_conditions: [this.special_conditions.caravans],
        quoteCompareOne: SharedConfig.selectedQuoteToCompareOne.quote_caravans,
        quoteCompareTwo: SharedConfig.selectedQuoteToCompareTwo.quote_caravans,
      });
    }
    if (requestedAssets.buildings) {
      this.setAssetDetails(AssetType.Buildings);
      this.quoteCompareDetailsArray.push({
        name: AssetType.Buildings,
        type: AssetType.Buildings,
        active: false,
        essentialDetails: {
          quote_comp_one: this.benefits[this.selectedQuoteToCompareOne.insurer_id][AssetType.Buildings].benefit_groups[0],
          quote_comp_two: this.benefits[this.selectedQuoteToCompareTwo.insurer_id][AssetType.Buildings].benefit_groups[0],
        },
        convenienceDetails: {
          quote_comp_one: this.benefits[this.selectedQuoteToCompareOne.insurer_id][AssetType.Buildings].benefit_groups[1],
          quote_comp_two: this.benefits[this.selectedQuoteToCompareTwo.insurer_id][AssetType.Buildings].benefit_groups[1],
        },
        special_conditions: [this.special_conditions.buildings],
        quoteCompareOne: SharedConfig.selectedQuoteToCompareOne.quote_buildings,
        quoteCompareTwo: SharedConfig.selectedQuoteToCompareTwo.quote_buildings,
      });
    }
    if (requestedAssets.contents) {
      this.setAssetDetails(AssetType.Contents);
      this.quoteCompareDetailsArray.push({
        name: AssetType.Contents,
        type: AssetType.Contents,
        active: false,
        essentialDetails: {
          quote_comp_one: this.benefits[this.selectedQuoteToCompareOne.insurer_id][AssetType.Contents].benefit_groups[0],
          quote_comp_two: this.benefits[this.selectedQuoteToCompareTwo.insurer_id][AssetType.Contents].benefit_groups[0],
        },
        convenienceDetails: {
          quote_comp_one: this.benefits[this.selectedQuoteToCompareOne.insurer_id][AssetType.Contents].benefit_groups[1],
          quote_comp_two: this.benefits[this.selectedQuoteToCompareTwo.insurer_id][AssetType.Contents].benefit_groups[1],
        },
        special_conditions: [this.special_conditions.contents],
        quoteCompareOne: SharedConfig.selectedQuoteToCompareOne.quote_contents,
        quoteCompareTwo: SharedConfig.selectedQuoteToCompareTwo.quote_contents,
      });
    }
    if (requestedAssets.all_risks || requestedAssets.specified_items) {
      this.setAssetDetails(AssetType.AllRisks);
      this.setAssetDetails(AssetType.SpecifiedItems);
      if (this.quoteComparisonDetailType !== 'optionalCover') {
        const aa =   SharedConfig.selectedQuoteToCompareOne.quote_specified_items;
        const aas =    SharedConfig.selectedQuoteToCompareTwo.quote_specified_items;
        const aaff =  this.sharedConfig.quoteRequest.assets.specified_items;
        const adda =   {
          name: 'All Risk',
          type: AssetType.AllRisks,
          active: false,
          essentialDetails: {
            quote_comp_one: this.benefits[this.selectedQuoteToCompareOne.insurer_id][AssetType.AllRisks].benefit_groups[0],
            quote_comp_two: this.benefits[this.selectedQuoteToCompareTwo.insurer_id][AssetType.AllRisks].benefit_groups[0],
          },
          convenienceDetails: {
            quote_comp_one: this.benefits[this.selectedQuoteToCompareOne.insurer_id][AssetType.AllRisks].benefit_groups[1],
            quote_comp_two: this.benefits[this.selectedQuoteToCompareTwo.insurer_id][AssetType.AllRisks].benefit_groups[1],
          },
          special_conditions: [this.special_conditions.all_risks],
          all_risks: {
            quoteCompareOne: SharedConfig.selectedQuoteToCompareOne.quote_all_risks,
            quoteCompareTwo: SharedConfig.selectedQuoteToCompareTwo.quote_all_risks,
          },
          specified_items: {
            quoteCompareOne: SharedConfig.selectedQuoteToCompareOne.quote_specified_items,
            quoteCompareTwo: SharedConfig.selectedQuoteToCompareTwo.quote_specified_items,
            specifiedItemNames: this.sharedConfig.quoteRequest.assets.specified_items,
          },
        }
        this.quoteCompareDetailsArray.push({
          name: 'All Risk',
          type: AssetType.AllRisks,
          active: false,
          essentialDetails: {
            quote_comp_one: this.benefits[this.selectedQuoteToCompareOne.insurer_id][AssetType.AllRisks].benefit_groups[0],
            quote_comp_two: this.benefits[this.selectedQuoteToCompareTwo.insurer_id][AssetType.AllRisks].benefit_groups[0],
          },
          convenienceDetails: {
            quote_comp_one: this.benefits[this.selectedQuoteToCompareOne.insurer_id][AssetType.AllRisks].benefit_groups[1],
            quote_comp_two: this.benefits[this.selectedQuoteToCompareTwo.insurer_id][AssetType.AllRisks].benefit_groups[1],
          },
          special_conditions: [this.special_conditions.all_risks],
          all_risks: {
            quoteCompareOne: SharedConfig.selectedQuoteToCompareOne.quote_all_risks,
            quoteCompareTwo: SharedConfig.selectedQuoteToCompareTwo.quote_all_risks,
          },
          specified_items: {
            quoteCompareOne: SharedConfig.selectedQuoteToCompareOne.quote_specified_items,
            quoteCompareTwo: SharedConfig.selectedQuoteToCompareTwo.quote_specified_items,
            specifiedItemNames: this.sharedConfig.quoteRequest.assets.specified_items,
          },
        });
      }
    }
    // if (requestedAssets.specified_items) {
    //   this.quoteCompareDetailsArray.push({ name: 'SpecifiedItems', type: AssetType.SpecifiedItems, active: false, essentialDetails: [this.benefitGroupSpecifiedItems[0]], convenienceDetails: [this.benefitGroupSpecifiedItems[1]], special_conditions: [this.special_conditions.all_risks], quoteCompareOne: SharedConfig.selectedQuoteToCompareOne.assets.specified_items, quoteCompareTwo: SharedConfig.selectedQuoteToCompareTwo.assets.specified_items })
    // }
    if (
      this.quoteComparisonDetailType === QuoteComparisonDetailType.ConvenienceCover ||
      this.quoteComparisonDetailType === QuoteComparisonDetailType.EssentialCover ||
      this.quoteComparisonDetailType === QuoteComparisonDetailType.Premium
    ) {
      this.quoteCompareDetailsArray.push({
        name: AssetType.Other,
        type: 'personal_liability',
        active: false,
        essentialDetails: {
          quote_comp_one: this.benefits[this.selectedQuoteToCompareOne.insurer_id][AssetType.Other].benefit_groups[0],
          quote_comp_two: this.benefits[this.selectedQuoteToCompareTwo.insurer_id][AssetType.Other].benefit_groups[0],
        },
        convenienceDetails: {
          quote_comp_one: this.benefits[this.selectedQuoteToCompareOne.insurer_id][AssetType.Other].benefit_groups[1],
          quote_comp_two: this.benefits[this.selectedQuoteToCompareTwo.insurer_id][AssetType.Other].benefit_groups[1],
        },
        quoteCompareOne: {
          assistance_benefits: SharedConfig.selectedQuoteToCompareOne.assistance_benefits,
          broker_fee: SharedConfig.selectedQuoteToCompareOne.broker_fee,
          personal_liability: SharedConfig.selectedQuoteToCompareOne.personal_liability,
        },
        quoteCompareTwo: {
          assistance_benefits: SharedConfig.selectedQuoteToCompareTwo.assistance_benefits,
          broker_fee: SharedConfig.selectedQuoteToCompareTwo.broker_fee,
          personal_liability: SharedConfig.selectedQuoteToCompareTwo.personal_liability,
        },
      });
    }
  }

  onLoadMakeFirstTabActive() {
    this.quoteCompareDetailsArray[0].active = true;
  }

  updateQuoteSharedConfig() {
    this.selectedQuoteToCompareOne = SharedConfig.selectedQuoteToCompareOne;
    this.selectedQuoteToCompareTwo = SharedConfig.selectedQuoteToCompareTwo;
    const asset_type = this.activeQuoteAssetTabCoveredDetails.type;
    if (asset_type !== 'personal_liability') {
      this.activeQuoteAssetTabCoveredDetails.essentialDetails = {
        quote_comp_one: this.benefits[this.selectedQuoteToCompareOne.insurer_id][asset_type].benefit_groups[0],
        quote_comp_two: this.benefits[this.selectedQuoteToCompareTwo.insurer_id][asset_type].benefit_groups[0],
      };
      this.activeQuoteAssetTabCoveredDetails.convenienceDetails = {
        quote_comp_one: this.benefits[this.selectedQuoteToCompareOne.insurer_id][asset_type].benefit_groups[1],
        quote_comp_two: this.benefits[this.selectedQuoteToCompareTwo.insurer_id][asset_type].benefit_groups[1],
      };
      // TODO Get a quote with all_risk and specified_items, The below code will not work for SQL.
      if (asset_type === AssetType.AllRisks) {
        this.activeQuoteAssetTabCoveredDetails[AssetType.AllRisks]['quoteCompareOne'] = SharedConfig.selectedQuoteToCompareOne.quote_all_risks;
        this.activeQuoteAssetTabCoveredDetails[AssetType.AllRisks]['quoteCompareTwo'] = SharedConfig.selectedQuoteToCompareTwo.quote_all_risks;
        this.activeQuoteAssetTabCoveredDetails[AssetType.SpecifiedItems]['quoteCompareOne'] =
          SharedConfig.selectedQuoteToCompareOne.quote_specified_items;
        this.activeQuoteAssetTabCoveredDetails[AssetType.SpecifiedItems]['quoteCompareTwo'] =
          SharedConfig.selectedQuoteToCompareTwo.quote_specified_items;
        this.activeQuoteAssetTabCoveredDetails[AssetType.SpecifiedItems]['specifiedItemNames'] =
          this.sharedConfig.quoteRequest.assets.specified_items;
      }
      this.selectAsset(this.activeQuoteAssetTabCoveredDetails);
    } else if (asset_type === 'personal_liability') {
      this.activeQuoteAssetTabCoveredDetails.quoteCompareOne = {
        assistance_benefits: SharedConfig.selectedQuoteToCompareOne.assistance_benefits,
        broker_fee: SharedConfig.selectedQuoteToCompareOne.broker_fee,
        personal_liability: SharedConfig.selectedQuoteToCompareOne.personal_liability,
      };
      this.activeQuoteAssetTabCoveredDetails.quoteCompareTwo = {
        assistance_benefits: SharedConfig.selectedQuoteToCompareTwo.assistance_benefits,
        broker_fee: SharedConfig.selectedQuoteToCompareTwo.broker_fee,
        personal_liability: SharedConfig.selectedQuoteToCompareTwo.personal_liability,
      };
      this.activeQuoteAssetTabCoveredDetails.essentialDetails = {
        quote_comp_one: this.benefits[this.selectedQuoteToCompareOne.insurer_id][AssetType.Other].benefit_groups[0],
        quote_comp_two: this.benefits[this.selectedQuoteToCompareTwo.insurer_id][AssetType.Other].benefit_groups[0],
      };
      this.activeQuoteAssetTabCoveredDetails.convenienceDetails = {
        quote_comp_one: this.benefits[this.selectedQuoteToCompareOne.insurer_id][AssetType.Other].benefit_groups[1],
        quote_comp_two: this.benefits[this.selectedQuoteToCompareTwo.insurer_id][AssetType.Other].benefit_groups[1],
      };
      this.selectAsset(this.activeQuoteAssetTabCoveredDetails);
    }

    this.special_conditions = SharedConfig.selectedQuoteSpecialConditions;
    this.insurerIdQuoteCompareOne = this.selectedQuoteToCompareOne.insurer_id;
    this.insurerIdQuoteCompareTwo = this.selectedQuoteToCompareTwo.insurer_id;
  }

  calculateQuoteComparisonDetails() {
    this.selectedQuoteToCompareOne = SharedConfig.selectedQuoteToCompareOne;
    console.log(this.selectedQuoteToCompareOne);
    this.selectedQuoteToCompareTwo = SharedConfig.selectedQuoteToCompareTwo;
    this.special_conditions = SharedConfig.selectedQuoteSpecialConditions;
    this.specialConditionsAllRisk = this.special_conditions.all_risks;
    this.specialConditionsVehicles = this.special_conditions.vehicles;
    this.specialConditionsMotorcycles = this.special_conditions.motorcycles;
    this.specialConditionsCaravans = this.special_conditions.caravans;
    this.specialConditionsContents = this.special_conditions.contents;
    this.specialConditionsBuildings = this.special_conditions.buildings;

    this.insurerIdQuoteCompareOne = this.selectedQuoteToCompareOne.insurer_id;
    this.insurerIdQuoteCompareTwo = this.selectedQuoteToCompareTwo.insurer_id;
    this.getQuoteComparisonAssetTabs();
    this.onLoadMakeFirstTabActive();
    this.loader(false);
    this.selectedQuoteToCompareOneSasria = this.selectedQuoteToCompareOne.sasria;
    this.selectedQuoteToCompareTwoSasria = this.selectedQuoteToCompareTwo.sasria;
    this.quoteComparisonAssetTabs = this.quoteCompareDetailsArray;
    this.quoteTabslength = this.quoteComparisonAssetTabs.length;
    if (this.quoteTabslength < 4) {
      this.disableRight = true;
    }

    this.activeQuoteAssetTabCoveredDetails = this.quoteComparisonAssetTabs[0];
    this.activeTabType = this.activeQuoteAssetTabCoveredDetails.type;
    this.selectAsset(this.activeQuoteAssetTabCoveredDetails);
  }

  setAssetDetails(assetType) {
    const asset = assetType.type ? assetType.type : assetType;
    switch (asset) {
      case AssetType.Vehicles:
        for (let index = 0; index < SharedConfig.selectedQuoteToCompareOne.quote_vehicles.length; index++) {
          SharedConfig.selectedQuoteToCompareOne.quote_vehicles[index].asset_details = this.sharedConfig.quoteRequest.assets.vehicles.find(
            (x) => x.id === SharedConfig.selectedQuoteToCompareOne.quote_vehicles[index].vehicles_id
          );
        }
        for (let index = 0; index < SharedConfig.selectedQuoteToCompareTwo.quote_vehicles.length; index++) {
          SharedConfig.selectedQuoteToCompareTwo.quote_vehicles[index].asset_details = this.sharedConfig.quoteRequest.assets.vehicles.find(
            (x) => x.id === SharedConfig.selectedQuoteToCompareOne.quote_vehicles[index].vehicles_id
          );
        }
        break;
      case AssetType.Motorcycles:
        for (let index = 0; index < SharedConfig.selectedQuoteToCompareOne.quote_motorcycles.length; index++) {
          SharedConfig.selectedQuoteToCompareOne.quote_motorcycles[index].asset_details = this.sharedConfig.quoteRequest.assets.motorcycles.find(
            (x) => x.id === SharedConfig.selectedQuoteToCompareOne.quote_motorcycles[index].motorcycles_id
          );
        }
        for (let index = 0; index < SharedConfig.selectedQuoteToCompareTwo.quote_motorcycles.length; index++) {
          SharedConfig.selectedQuoteToCompareTwo.quote_motorcycles[index].asset_details = this.sharedConfig.quoteRequest.assets.motorcycles.find(
            (x) => x.id === SharedConfig.selectedQuoteToCompareOne.quote_motorcycles[index].motorcycles_id
          );
        }
        break;
      case AssetType.Caravans:
        for (let index = 0; index < SharedConfig.selectedQuoteToCompareOne.quote_caravans.length; index++) {
          SharedConfig.selectedQuoteToCompareOne.quote_caravans[index].asset_details = this.sharedConfig.quoteRequest.assets.caravans.find(
            (x) => x.id === SharedConfig.selectedQuoteToCompareOne.quote_caravans[index].caravans_id
          );
        }
        for (let index = 0; index < SharedConfig.selectedQuoteToCompareTwo.quote_caravans.length; index++) {
          SharedConfig.selectedQuoteToCompareTwo.quote_caravans[index].asset_details = this.sharedConfig.quoteRequest.assets.caravans.find(
            (x) => x.id === SharedConfig.selectedQuoteToCompareOne.quote_caravans[index].caravans_id
          );
        }
        break;
      case AssetType.Buildings:
        for (let index = 0; index < SharedConfig.selectedQuoteToCompareOne.quote_buildings.length; index++) {
          SharedConfig.selectedQuoteToCompareOne.quote_buildings[index].asset_details = this.sharedConfig.quoteRequest.assets.buildings.find(
            (x) => x.id === SharedConfig.selectedQuoteToCompareOne.quote_buildings[index].buildings_id
          );
        }
        for (let index = 0; index < SharedConfig.selectedQuoteToCompareTwo.quote_buildings.length; index++) {
          SharedConfig.selectedQuoteToCompareTwo.quote_buildings[index].asset_details = this.sharedConfig.quoteRequest.assets.buildings.find(
            (x) => x.id === SharedConfig.selectedQuoteToCompareOne.quote_buildings[index].buildings_id
          );
        }
        break;
      case AssetType.Contents:
        for (let index = 0; index < SharedConfig.selectedQuoteToCompareOne.quote_contents.length; index++) {
          SharedConfig.selectedQuoteToCompareOne.quote_contents[index].asset_details = this.sharedConfig.quoteRequest.assets.contents.find(
            (x) => x.id === SharedConfig.selectedQuoteToCompareOne.quote_contents[index].contents_id
          );
        }
        for (let index = 0; index < SharedConfig.selectedQuoteToCompareTwo.quote_contents.length; index++) {
          SharedConfig.selectedQuoteToCompareTwo.quote_contents[index].asset_details = this.sharedConfig.quoteRequest.assets.contents.find(
            (x) => x.id === SharedConfig.selectedQuoteToCompareOne.quote_contents[index].contents_id
          );
        }
        break;
      case AssetType.AllRisks:
        for (let index = 0; index < SharedConfig.selectedQuoteToCompareOne.quote_all_risks.length; index++) {
          SharedConfig.selectedQuoteToCompareOne.quote_all_risks[index].asset_details = this.sharedConfig.quoteRequest.assets.all_risks.find(
            (x) => x.id === SharedConfig.selectedQuoteToCompareOne.quote_all_risks[index].all_risks_id
          );
        }
        for (let index = 0; index < SharedConfig.selectedQuoteToCompareTwo.quote_all_risks.length; index++) {
          SharedConfig.selectedQuoteToCompareTwo.quote_all_risks[index].asset_details = this.sharedConfig.quoteRequest.assets.all_risks.find(
            (x) => x.id === SharedConfig.selectedQuoteToCompareOne.quote_all_risks[index].all_risks_id
          );
        }
        break;
      case AssetType.SpecifiedItems:
        for (let index = 0; index < SharedConfig.selectedQuoteToCompareOne.quote_specified_items.length; index++) {
          SharedConfig.selectedQuoteToCompareOne.quote_specified_items[index].asset_details =
            this.sharedConfig.quoteRequest.assets.specified_items.find(
              (x) => x.id === SharedConfig.selectedQuoteToCompareOne.quote_specified_items[index].specified_items_id
            );
        }
        for (let index = 0; index < SharedConfig.selectedQuoteToCompareTwo.quote_specified_items.length; index++) {
          SharedConfig.selectedQuoteToCompareTwo.quote_specified_items[index].asset_details =
            this.sharedConfig.quoteRequest.assets.specified_items.find(
              (x) => x.id === SharedConfig.selectedQuoteToCompareOne.quote_specified_items[index].specified_items_id
            );
        }
        break;

      default:
        break;
    }
  }

  selectAsset(activeTab: IActiveQuoteAssetTabDetails) {
    this.setAssetDetails(activeTab);
    console.log(activeTab);
    this.convenience = [];
    this.essential = [];
    this.specialCondition = [];
    this.selectedAssetSpecialConditions = [];

    this.activeTabType = activeTab.type;
    this.activeQuoteAssetTabCoveredDetails = activeTab;
    console.log(this.activeTabType);
    switch (this.quoteComparisonDetailType) {
      case QuoteComparisonDetailType.ConvenienceCover:
        this.convenience = [
          this.activeQuoteAssetTabCoveredDetails.convenienceDetails.quote_comp_one,
          this.activeQuoteAssetTabCoveredDetails.convenienceDetails.quote_comp_two,
        ];
        this.convenienceQuoteOne = this.convenience[0].group_items;
        this.convenienceQuoteTwo = this.convenience[1].group_items;
        this.busnThirdPartyService.logEvent('page_convenience_cover_comparison', { quoteRequest: this.sharedConfig.quoteRequest.id });
        break;
      case QuoteComparisonDetailType.EssentialCover:
        this.subBenefitGroup = [];
        this.subBenefitGroupHeader = {
          description: '',
        };
        this.essential = [
          this.activeQuoteAssetTabCoveredDetails.essentialDetails.quote_comp_one,
          this.activeQuoteAssetTabCoveredDetails.essentialDetails.quote_comp_two,
        ];
        this.essentialQuoteOne = this.essential[0].group_items;
        this.essentialQuoteTwo = this.essential[1].group_items;
        if (this.activeQuoteAssetTabCoveredDetails.type === AssetType.AllRisks) {
          this.subBenefitGroupHeader.description = this.activeQuoteAssetTabCoveredDetails.essentialDetails[0].subBenefitGroup.description;
          this.subBenefitGroup = this.activeQuoteAssetTabCoveredDetails.essentialDetails[0].subBenefitGroup.groupItems;
        }
        this.busnThirdPartyService.logEvent('page_essential_cover_comparison', { quoteRequest: this.sharedConfig.quoteRequest.id });
        break;
      case QuoteComparisonDetailType.Excess:
        this.quoteOneSelectedAsset = SharedConfig.selectedQuoteToCompareOne[`quote_${activeTab.type}`];
        this.quoteTwoSelectedAsset = SharedConfig.selectedQuoteToCompareTwo[`quote_${activeTab.type}`];
        this.compareQuoteOneAllRisk = this.activeQuoteAssetTabCoveredDetails[AssetType.AllRisks]?.quoteCompareOne;
        this.compareQuoteTwoAllRisk = this.activeQuoteAssetTabCoveredDetails[AssetType.AllRisks]?.quoteCompareTwo;
        this.compareQuoteOneSpecifiedItems = this.activeQuoteAssetTabCoveredDetails[AssetType.SpecifiedItems]?.quoteCompareOne;
        this.compareQuoteTwoSpecifiedItems = this.activeQuoteAssetTabCoveredDetails[AssetType.SpecifiedItems]?.quoteCompareTwo;
        this.quoteRequestSpecifiedItems = this.activeQuoteAssetTabCoveredDetails[AssetType.SpecifiedItems]?.specifiedItemNames;
        this.busnThirdPartyService.logEvent('page_excess_comparison', { quoteRequest: this.sharedConfig.quoteRequest.id });
        break;
      case QuoteComparisonDetailType.Premium:
        if (activeTab.type === 'personal_liability') {
          this.quoteOneSelectedAsset = activeTab.quoteCompareOne;
          this.quoteTwoSelectedAsset = activeTab.quoteCompareTwo;
        } else {
          this.quoteOneSelectedAsset = SharedConfig.selectedQuoteToCompareOne[`quote_${activeTab.type}`];
          this.quoteTwoSelectedAsset = SharedConfig.selectedQuoteToCompareTwo[`quote_${activeTab.type}`];
        }
        this.compareQuoteOneAllRisk = this.activeQuoteAssetTabCoveredDetails[AssetType.AllRisks]?.quoteCompareOne;
        this.compareQuoteTwoAllRisk = this.activeQuoteAssetTabCoveredDetails[AssetType.AllRisks]?.quoteCompareTwo;
        this.compareQuoteOneSpecifiedItems = this.activeQuoteAssetTabCoveredDetails[AssetType.SpecifiedItems]?.quoteCompareOne;
        this.compareQuoteTwoSpecifiedItems = this.activeQuoteAssetTabCoveredDetails[AssetType.SpecifiedItems]?.quoteCompareTwo;
        this.quoteRequestSpecifiedItems = this.activeQuoteAssetTabCoveredDetails[AssetType.SpecifiedItems]?.specifiedItemNames;
        this.busnThirdPartyService.logEvent('page_premium_comparison', { quoteRequest: this.sharedConfig.quoteRequest.id });
        this.compareQuoteSasriaOne = this.selectedQuoteToCompareOne.sasria;
        this.compareQuoteSasriaTwo = this.selectedQuoteToCompareTwo.sasria;
        break;
      case QuoteComparisonDetailType.SpecialConditions:
        const activeTddab = this.activeQuoteAssetTabCoveredDetails
        this.specialCondition = this.activeQuoteAssetTabCoveredDetails[QuoteComparisonDetailType.SpecialConditions];
        Object.keys(this.specialCondition).map((value) => {
          this.sharedConfig.quoteRequest.quotes.forEach((q) => {
            if ((value = q.insurer_id)) {
              this.selectedAssetSpecialConditions.push({ insurer: value, conditions: this.specialCondition[0][value] });
            }
          });
        });
        this.busnThirdPartyService.logEvent('page_special_conditions', { quoteRequest: this.sharedConfig.quoteRequest.id });
        break;
      case 'optionalCover':
        this.quoteOneSelectedAsset = SharedConfig.selectedQuoteToCompareOne[`quote_${activeTab.type}`];
        this.quoteTwoSelectedAsset = SharedConfig.selectedQuoteToCompareTwo[`quote_${activeTab.type}`];
        console.log(this.quoteOneSelectedAsset);
        this.busnThirdPartyService.logEvent('page_optional_cover', { quoteRequest: this.sharedConfig.quoteRequest.id });
        break;
      default:
        break;
    }
  }
}
