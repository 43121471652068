import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { InputType, IQuestion, QuestionType, SharedConfig } from '@takectrl-multiapp/svcs-share-config';
@Component({
  selector: 'input-text-single-line',
  templateUrl: './input-text-single-line.component.html',
  styleUrls: ['./input-text-single-line.component.scss'],
})
export class InputTextSingleLineComponent implements OnInit {
  @Input() question: IQuestion;
  @Input() showHeading: boolean;
  @Input() error: string;
  @Input() readOnly: boolean;
  @Input() page: string;

  textInputType = InputType.text;
  @Output() answerQuestion: EventEmitter<IQuestion> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {
    if (this.question.type !== QuestionType.number) {
      this.textInputType = InputType.text;
    } else if (this.question.type === QuestionType.number) {
      this.textInputType = InputType.number;
      if (this.question.underwriting_answers[0].object_answer === undefined || this.question.underwriting_answers[0].object_answer === null) {
        this.question.underwriting_answers[0].object_answer = 0;
      }
    }

    // this is to emit the default 0 value as an answer to enable the next button
    if (SharedConfig.selectedPage === 'quote-questions') {
      this.answerQuestion.emit(this.question);
    }
  }

  answerValue(e: Event) {
    if (this.question.underwriting_answers[0].object_field === 'surname_on_id') {
      const getAnswer = e.target['value'];
      const capitilizeAnswer = getAnswer.charAt(0).toUpperCase() + getAnswer.slice(1);
      this.question.underwriting_answers[0].object_answer = capitilizeAnswer.trim();

      //  this.answerQuestion.emit(this.question);
    } else {
      this.question.underwriting_answers[0].object_answer = e.target['value'].trim();
      //   this.answerQuestion.emit(this.question);
    }
    if (SharedConfig.selectedPage === 'quote-questions') {
      this.answerQuestion.emit(this.question);
    }
  }
}
