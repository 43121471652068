import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { SharedConfig } from '@takectrl-multiapp/svcs-share-config';
declare const dataLayer: unknown[];

@Component({
  selector: 'emergency-without-assistance-benefits',
  templateUrl: './emergency-without-assistance-benefits.component.html',
  styleUrls: ['./emergency-without-assistance-benefits.component.scss'],
})
export class EmergencyWithoutAssistanceBenefitsComponent implements OnInit {
  constructor(
    public sharedConfig: SharedConfig,
    public busnThirdPartyService: BUSNThirdPartyService
  ) {}

  ngOnInit(): void {
    dataLayer.push({
      event: 'page_show',
      screenPath: 'emergency-without-assistance-benefits',
      screenName: 'emergency-without-assistance-benefits',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }

  async ngAfterViewInit(): Promise<void> {}

  showIntercom() {
    this.busnThirdPartyService.intercomDisplayLauncher();
  }
}
