import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BUSNQuoteScheduleService, BUSNUserService } from '@takectrl-multiapp/svcs-core-business';
import { SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
declare const dataLayer: any[];

@Component({
  selector: 'quote-added-benefits',
  templateUrl: './quote-added-benefits.component.html',
  styleUrls: ['./quote-added-benefits.component.scss'],
})
export class QuoteAddedBenefitsComponent implements OnInit {
  @Output() navigateNeedsAnalysis: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public sharedConfig: SharedConfig,
    private readonly busnQuoteScheduleService: BUSNQuoteScheduleService,
    public busnThirdPartyService: BUSNThirdPartyService,
    public userService: BUSNUserService
  ) {}

  ngOnInit(): void {
    dataLayer.push({
      event: 'page_show',
      screenPath: 'quote-added-benefits',
      screenName: 'quote-added-benefits',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }

  updateQuoteRequestAddedBenefitsAndContinue(assistance_benefits_requested = false) {
    this.sharedConfig.quoteRequest.assistance_benefits_requested = assistance_benefits_requested;
    this.busnQuoteScheduleService.updateQuoteRequest(this.sharedConfig.quoteRequest);
    this.busnThirdPartyService.logEvent('action_assistance_accepted', { didAccept: assistance_benefits_requested });
    this.navigateNeedsAnalysis.emit();
  }
  itcCheckAgree() {
    this.userService.updateUserItc();
    this.busnThirdPartyService.logEvent('action_agreed_itc_check');
  }

  chatToUs() {
    this.busnThirdPartyService.logEvent('action_itc_chat_to_us');
    this.busnThirdPartyService.intercomDisplayLauncher();
  }
}
