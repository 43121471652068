<div class="input-wrapper">
  <ion-item mode="ios">
    <ion-label *ngIf="showHeading" position="floating">{{ question.underwriting_answers[0].place_holder }}</ion-label>
    <ion-input
      class="bold-text"
      readonly="{{ readOnly }}"
      min="0"
      type="{{ textInputType }}"
      [ngClass]="{ capitalize: question.underwriting_answers[0].object_field === 'surname_on_id' }"
      value="{{ question.underwriting_answers[0].object_answer }}"
      (input)="answerValue($event)"
    ></ion-input>
  </ion-item>
  <p class="error-message" *ngIf="error">{{ error }}</p>
</div>
