<div class="loader-backdrop"></div>

<div *ngIf="show" class="center-outer">
  <div class="center-inner">
    <div class="center-auto">
      <div class="loader-wrapper">
        <div role="status">
          <lottie-player *ngIf="show" autoplay mode="normal" src="assets/lottie-animations/successful-register.json">
          </lottie-player>
        </div>
      </div>
      <div class="loader-message limit-width-on-desktop">
        <h2 class="h2-bold">You did your bit..</h2>
        <p>Your quotes will be ready to view in a few minutes. Please be patient while we fetch you the best prices from the
          insurers.</p>
      </div>
    </div>
  </div>
  
</div>
<div *ngIf="show" class="bottom ">
  <div class="started-button">
    <ion-button mode="ios" (click)="next()" class="primary-button limit-width-desktop-on-button">Got it</ion-button>
  </div>
</div>