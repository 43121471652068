import { NavController } from '@ionic/angular';
import { Injectable, NgZone } from '@angular/core';
import { SharedConfig } from '../svcs-share-config.module';
import { IPopupDialogParametersInterface } from '../interfaces/dialog.interface';

@Injectable({
  providedIn: 'root',
})
export class SharedDialogService {
  constructor(private readonly zone: NgZone, private readonly navCtrl: NavController) {}

   showPopupDialog(params: IPopupDialogParametersInterface) {
    SharedConfig.popupDialogShow = true;
    SharedConfig.popupDialogHeader = params.header;
    SharedConfig.popupDialogSubHeader = params.subHeader;
    SharedConfig.popupDialogImageUrl = params.imageUrl;
    SharedConfig.popupDialogButtonTopCloseShow =
      params.buttonTopCloseShow != null || params.buttonTopCloseShow != undefined ? params.buttonTopCloseShow : false;
    SharedConfig.popupDialogButtonOneText = params.buttonOneText;
    SharedConfig.popupDialogButtonTwoText = params.buttonTwoText;
    SharedConfig.popupDialogButtonOneCallBack = params.buttonOneCallback;
    SharedConfig.popupDialogButtonTwoCallBack = params.buttonTwoCallback;
    SharedConfig.popupDialogTextAreaValue = params.textAreaValue;
    SharedConfig.popupDialogTextAreaPlaceholder = params.textAreaPlaceholder;
    SharedConfig.popupDialogButtonThreeText = params.buttonThreeText;
    SharedConfig.popupDialogButtonThreeCallBack = params.buttonThreeCallback;
    SharedConfig.popupDialogShowThreeButtons = params.popupDialogShowThreeButtons;
    SharedConfig.popupDialogReturnRoute = params.navRoute;
  }

   hidePopupDialog() {
    SharedConfig.popupDialogShow = false;
    SharedConfig.showFullScreen = false;
    delete SharedConfig.popupDialogHeader;
    delete SharedConfig.popupDialogSubHeader;
    delete SharedConfig.popupDialogImageUrl;
    delete SharedConfig.popupDialogButtonOneText;
    delete SharedConfig.popupDialogButtonTwoText;
    delete SharedConfig.popupDialogButtonThreeText;
    delete SharedConfig.popupDialogButtonOneCallBack;
    delete SharedConfig.popupDialogButtonTwoCallBack;
    delete SharedConfig.popupDialogButtonThreeCallBack;
    delete SharedConfig.popupDialogShowThreeButtons;
    if (SharedConfig.popupDialogReturnRoute) {
      this.zone.run(() => {
        this.navCtrl.navigateRoot([SharedConfig.popupDialogReturnRoute]);
      });
    }
    delete SharedConfig.popupDialogReturnRoute;
  }
}
