<ion-buttons *ngIf="busnRbixService.isQuestionMapAvailable()" slot="end">
  <ion-icon
    slot="icon-only"
    *ngIf="!recording && !processing"
    (click)="showQuestionMapPopupDialog()"
    class="chat-icon"
    src="assets/icon/toolbar-icons/lyrics-microphone-music-svgrepo-com.svg"
  ></ion-icon>
  <!-- <ion-icon slot="icon-only" *ngIf="recording && !processing" (click)="stopRecording()" class="chat-icon" src="assets/icon/toolbar-icons/mp3-music-player-3-svgrepo-com.svg"></ion-icon> -->
  <ion-icon slot="icon-only" *ngIf="processing" class="chat-icon" src="assets/icon/toolbar-icons/loading-svgrepo-com.svg"></ion-icon>
</ion-buttons>
