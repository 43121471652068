<div class="wrapper limit-width-on-desktop-float">
    <div class="head-section">
        <div class="top-text">
            <h2 class="h2-bold text-center">Not permitted</h2>
        </div>
    </div>
    <div class="img">
        <div class="loader-wrapper" *ngIf="showLottie">
            <div role="status">
                <lottie-player autoplay loop mode="normal" src="assets/lottie-animations/error.json">
                </lottie-player>
            </div>
        </div>
    </div>
    <div class="bottom-text text-center">
        <p>Unfortunately, you can't remove that particular asset because of conditions set by the insurers. Let's chat about
        it to see what we can do.</p>
    </div>
</div>

<div class="footer-button-wrapper">
    <div class="fade"></div>
    <div class="footer-button-background">
        <ion-button mode="ios" (click)="showIntercom()" class="footer-button" expand="block">
            Contact us
        </ion-button>
        <ion-button mode="ios" class="secondary-button" (click)="dismiss()">Dismiss</ion-button>
    </div>
</div>
