import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';

@Component({
  selector: 'schedules-remove-asset-fail',
  templateUrl: './schedules-remove-asset-fail.component.html',
  styleUrls: ['./schedules-remove-asset-fail.component.scss']
})
export class SchedulesRemoveAssetFailComponent implements OnInit {
  @Output() navigateClose: EventEmitter<unknown> = new EventEmitter();
  showLottie = true;
  constructor(private readonly busnThirdPartyService: BUSNThirdPartyService) { }

  ngOnInit(): void {
  }

  dismiss() {
    this.navigateClose.emit();
  }

  showIntercom() {
    this.busnThirdPartyService.intercomDisplayLauncher();
  }

}
