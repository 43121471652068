import { SharedConfig, ISchedule } from '@takectrl-multiapp/svcs-share-config';
import { Component, OnInit } from '@angular/core';
declare const dataLayer: any[];

@Component({
  selector: 'emergency',
  templateUrl: './emergency.component.html',
  styleUrls: ['./emergency.component.scss'],
})
export class EmergencyComponent implements OnInit {
  schedulesWithAssistanceBenefits: Array<ISchedule> = [];
  selectedPolicyNumber: string;
  constructor(public sharedConfig: SharedConfig) {}

  async ngOnInit() {
    this.schedulesWithAssistanceBenefits = this.sharedConfig.schedulesActive.filter((x) => x.assistance_benefits_requested);
    dataLayer.push({
      event: 'page_show',
      screenPath: 'emergency',
      screenName: 'emergency',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }
}
