<div class="client-card">
  <div class="top-card">
    <div>
      <img src="assets/images/insurer-logos/white-logos/card-{{ insurer_id | lowercase }}-white.svg" />
    </div>
  </div>
  <div class="bottom-card">
    <p class="header">
      <b>{{ clientName }}</b>
    </p>
    <ion-grid>
      <ion-row>
        <ion-col>
          <p>Policy number</p>
          <p>Cover date</p>
        </ion-col>
        <ion-col class="col-to-right">
          <p>
            <b>{{ policy_number }}</b>
          </p>
          <p>
            <b>{{ effective_date }}</b>
          </p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</div>
