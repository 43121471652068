import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IQuote, SharedConfig, SharedDialogService, QuoteRequestStatusType } from '@takectrl-multiapp/svcs-share-config';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
declare const dataLayer: any[];

@Component({
  selector: 'quote-list-retrieved-summary',
  templateUrl: './quote-list-retrieved-summary.component.html',
  styleUrls: ['./quote-list-retrieved-summary.component.scss'],
})
export class QuoteListRetrievedSummaryComponent implements OnInit {
  @Output() navigateQuoteComparisonOverall: EventEmitter<unknown> = new EventEmitter<unknown>();
  public up_to_number_of_quotes = this.sharedConfig.appSettings?.up_to_number_of_quotes;
  recommendedQuote?: IQuote;
  quotesOther: Array<IQuote> = Array<IQuote>();
  showPopup = false;
  popupHeader: string;
  popupMessage: string;
  QuoteRequestStatusType = QuoteRequestStatusType;
  constructor(public thirdpartyService: BUSNThirdPartyService, public sharedConfig: SharedConfig, private readonly sharedDialogService: SharedDialogService) {}

  ngOnInit(): void {
    dataLayer.push({
      event: 'page_show',
      screenPath: 'quote-list-retrieved-summary',
      screenName: 'quote-list-retrieved-summary',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }

  navQuoteComparisonOverall() {
    this.navigateQuoteComparisonOverall.emit();
  }

  async mandateBellAlert() {
    this.sharedDialogService.showPopupDialog({
      header: 'Double checking',
      subHeader: "The insurer might need to run a final check on your quote. If you want to accept this one, please let us know in the chat and we'll make sure it's done as soon as possible.",
      buttonOneText: 'Got it',
      buttonOneCallback: this.sharedDialogService.hidePopupDialog
    });
  }
}
