import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import { BUSNQuestionService } from '@takectrl-multiapp/svcs-core-business';
import { AssetType, IQuestion, QuoteType, SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import { QuestionComponent } from '../../components/question/question.component';
import { PageType } from 'libs/svcs-share-config/src/lib/types/page.type';
declare const dataLayer: any[];

@Component({
  selector: 'question-quote',
  templateUrl: './question-quote.component.html',
  styleUrls: ['./question-quote.component.scss'],
})
export class QuestionQuoteComponent implements OnInit {
  @ViewChild(QuestionComponent) questionComponent: QuestionComponent;
  @Input() asset_type: AssetType;
  @Input() quoteType: QuoteType;
  @Input() assetTypeDisplayName: string;
  @Output() showBackButton: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() navigateQuestionSummary: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() navigateHomeQuote: EventEmitter<void> = new EventEmitter<void>();
  @Output() navigateQuoteEndorsement: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() navigateWalletPage: EventEmitter<void> = new EventEmitter<void>();
  @Output() navigateQuoteObjectMappingQuestions: EventEmitter<unknown> = new EventEmitter<unknown>();

  questionsAssetNotYetAnswered: Array<IQuestion>;

  constructor(public thirdpartyService: BUSNThirdPartyService, public sharedConfig: SharedConfig, public busnQuestionService: BUSNQuestionService) {
    SharedConfig.selectedPage = PageType.questions;
  }

  ngOnInit() {
    dataLayer.push({
      event: 'page_show',
      screenPath: 'question-quote',
      screenName: 'question-quote',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });
  }

  prevQuestion() {
    this.questionComponent.previousQuestion();
  }

  compShowBackButton(value: boolean) {
    this.showBackButton.emit(value);
  }

  compNavigateQuestionSummary() {
    this.navigateQuestionSummary.emit();
  }

  compNavigateQuoteEndorsement(value: any) {
    this.navigateQuoteEndorsement.emit({
      endorsementRequest: value.endorsementRequest,
      insurer_id: value.insurer_id,
    });
  }

  compNavigateQuoteObjectMappingQuestions(queryParams: object) {
    this.navigateQuoteObjectMappingQuestions.emit(queryParams);
  }

  compNavigateWalletPage() {
    this.navigateWalletPage.emit();
  }

  compNavigateHomeQuote() {
    this.navigateHomeQuote.emit();
  }
}
