<div class="indicator-wrapper">
  <ion-grid>
    <ion-row>
      <div class="left-indicator">
        <span class="to-left" [ngStyle]="{ width: 'calc(' + (selectedQuoteToCompareRatingOne * 100 | number : '2.0-0') + '% )' }"
          ><p>{{ selectedQuoteToCompareRatingOne * 100 | number : '2.0-0' }}</p></span
        >
      </div>
      <div class="right-indicator">
        <span class="to-right" [ngStyle]="{ width: 'calc(' + (selectedQuoteToCompareRatingTwo * 100 | number : '2.0-0') + '% )' }"
          ><p>{{ selectedQuoteToCompareRatingTwo * 100 | number : '2.0-0' }}</p></span
        >
      </div>
    </ion-row>
  </ion-grid>
</div>
