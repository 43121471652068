import { Injectable } from '@angular/core';
import { ISchedule, ISchedulesPremiumDetails, IAssistanceBenefits } from '../interfaces/schedule.interface';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class SharedAPIDataCalculationService {
  // All these methods needs might need to migrate to the CTRL api
  constructor() {}

  getSchedulesTotalPremium(schedules: ISchedule[]): ISchedulesPremiumDetails {
    const returnSchedulesPremiumDetails: ISchedulesPremiumDetails = {
      total_premium: 0,
    };

    returnSchedulesPremiumDetails.otherPremium = this.getOtherPremiumFromSchedules(schedules);
    returnSchedulesPremiumDetails.total_premium += returnSchedulesPremiumDetails.otherPremium;

    returnSchedulesPremiumDetails.contents_policies_count = schedules.filter(
      (x) => x.insured_assets.contents && x.insured_assets.contents.length > 0
    ).length;
    returnSchedulesPremiumDetails.vehicle_policies_count = schedules.filter(
      (x) => x.insured_assets.vehicles && x.insured_assets.vehicles.length > 0
    ).length;
    returnSchedulesPremiumDetails.motorcycle_policies_count = schedules.filter(
      (x) => x.insured_assets.motorcycles && x.insured_assets.motorcycles.length > 0
    ).length;
    returnSchedulesPremiumDetails.caravan_policies_count = schedules.filter(
      (x) => x.insured_assets.caravans && x.insured_assets.caravans.length > 0
    ).length;
    returnSchedulesPremiumDetails.buildings_policies_count = schedules.filter(
      (x) => x.insured_assets.buildings && x.insured_assets.buildings.length > 0
    ).length;

    returnSchedulesPremiumDetails.all_risks_policies_count = schedules.filter(
      (x) => (x.insured_assets.all_risks && x.insured_assets.all_risks.length) || (x.insured_assets.specified_items && x.insured_assets.specified_items.length)
    ).length;

    returnSchedulesPremiumDetails.contents_premium = _.sum(
      schedules.map((x) => x.insured_assets?.contents ?? []).map((x) => _.sum(x.map((x) => x.premium)))
    );
    returnSchedulesPremiumDetails.vehicles_premium = _.sum(
      schedules.map((x) => x.insured_assets?.vehicles ?? []).map((x) => _.sum(x.map((x) => x.premium)))
    );
    returnSchedulesPremiumDetails.motorcycles_premium = _.sum(
      schedules.map((x) => x.insured_assets?.motorcycles ?? []).map((x) => _.sum(x.map((x) => x.premium)))
    );
    returnSchedulesPremiumDetails.caravans_premium = _.sum(
      schedules.map((x) => x.insured_assets?.caravans ?? []).map((x) => _.sum(x.map((x) => x.premium)))
    );
    returnSchedulesPremiumDetails.buildings_premium = _.sum(
      schedules.map((x) => x.insured_assets?.buildings ?? []).map((x) => _.sum(x.map((x) => x.premium)))
    );
    // SQL TODO VARIABLE NOT ON OBJECT, CONFIRM WHERE THIS VAR IS?
    returnSchedulesPremiumDetails.specified_items_premium = _.sum(
      schedules.map((x) => x.insured_assets?.specified_items ?? []).map((x) => _.sum(x.map((x) => x.premium)))
    );
    returnSchedulesPremiumDetails.all_risks_premium = _.sum(schedules.map((x) => x.insured_assets?.all_risks ?? []).map((x) => _.sum(x.map((x) => x.premium))));
    returnSchedulesPremiumDetails.vaps_premium = _.sum(schedules.map((x) => x.insured_assets?.vaps ?? []).map((x) => _.sum(x.map((x) => x.premium))));
    returnSchedulesPremiumDetails.vaps_policies_count = schedules.filter((x) => x.insured_assets?.vaps && x.insured_assets.vaps.length > 0).length;
    returnSchedulesPremiumDetails.total_premium = _.sum(schedules.map((x) => x.total_premium));

    return returnSchedulesPremiumDetails;
  }

  public getOtherPremiumFromSchedules(schedules: ISchedule[]): number {
    let premium = 0;

    for (const schedule of schedules) {
      premium += this.getOtherPremiumFromSchedule(schedule);
    }

    return premium;
  }

  public getOtherPremiumFromSchedule(schedule: ISchedule): number {
    let premium = 0;

    if (schedule) {
      if (schedule.admin_fee) {
        premium += schedule.admin_fee;
      }

      if (schedule.sasria) {
        premium += schedule.sasria;
      }

      if (schedule.assistance_benefits) {
        const brolinkAssist = schedule.assistance_benefits.filter(x => x.description === "Brolink assist" || x.description === "Assistance benefits");
        const carHire = schedule.assistance_benefits.filter(x => x.description === "EFS Car Hire" || x.description === "Personal accident");
        if (schedule.assistance_benefits_requested && brolinkAssist.length > 0) {
          premium += this.getBrolinkAssist(brolinkAssist[0]);
        }
        if (carHire.length > 0) {
          premium += this.getCarHire(carHire[0]);
        }
      }
    }

    if (schedule.personal_liability) {
      premium += schedule.personal_liability.premium;
    }
    return premium;
  }

  getCarHire(carHire: IAssistanceBenefits) {
    let amount = 0;
    if (carHire?.premium) {
      amount = carHire?.premium;
    }
    return amount;
  }

  getBrolinkAssist(brolinkAssist: IAssistanceBenefits) {
    let amount = 0;
    if (brolinkAssist?.premium) {
          amount = brolinkAssist.premium;
        }
    return amount;
  }
}
