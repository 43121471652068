import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { BUSNQuoteScheduleService } from '@takectrl-multiapp/svcs-core-business';
import { BUSNThirdPartyService } from '@takectrl-multiapp/svcs-core-business';
import {
  SharedFormulaService,
  IQuote,
  SharedConfig,
  QuoteComparisonDetailType,
  SharedObjectTransformationService,
  QuoteRequestStatusType,
  SharedDialogService,
} from '@takectrl-multiapp/svcs-share-config';
import { BUSNCommunicationService } from 'libs/svcs-core-business/src/lib/services/busn-communication.service';
declare const dataLayer: any[];

@Component({
  selector: 'quote-comparison-overall',
  templateUrl: './quote-comparison-overall.component.html',
  styleUrls: ['./quote-comparison-overall.component.scss'],
})
export class QuoteComparisonOverallComponent implements OnInit {
  @Output() navigateQuoteComparisonDetails: EventEmitter<QuoteComparisonDetailType> = new EventEmitter<QuoteComparisonDetailType>();
  @Output() navigateQuoteBankingDetails: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() navigateQuoteAccept: EventEmitter<unknown> = new EventEmitter<unknown>();
  selectedQuoteToCompareOne: IQuote;
  selectedQuoteToCompareTwo: IQuote;
  showPopup = false;
  popupHeader: string;
  popupMessage: string;
  QuoteRequestStatusType = QuoteRequestStatusType;
  constructor(
    public sharedFormulaService: SharedFormulaService,
    public sharedConfig: SharedConfig,
    private readonly busnQuoteScheduleService: BUSNQuoteScheduleService,
    private readonly sharedObjectTransformationService: SharedObjectTransformationService,
    private readonly busnThirdPartyService: BUSNThirdPartyService,
    private readonly busnCommunicationService: BUSNCommunicationService,
    private readonly sharedDialogService: SharedDialogService
  ) {}

  ngOnInit(): void {
    dataLayer.push({
      event: 'page_show',
      screenPath: 'quote-comparison-overall',
      screenName: 'quote-comparison-overall',
      userUid: SharedConfig.firebaseUserKey ? SharedConfig.firebaseUserKey : 'NaN',
    });

    this.busnThirdPartyService.logEvent('action_compare_quote', {
      timestamp: new Date(),
      quote_request_id: this.sharedConfig.quoteRequest.id,
    });
    delete SharedConfig.selectedQuoteToCompareOne;
    delete SharedConfig.selectedQuoteToCompareTwo;
    if (this.sharedConfig.quoteRequest.quotes.length === 1) {
      this.selectedQuoteToCompareOne = SharedConfig.selectedQuoteToCompareOne = this.sharedConfig.quoteRequest.quotes[0];
      this.selectedQuoteToCompareTwo = SharedConfig.selectedQuoteToCompareTwo = this.sharedConfig.quoteRequest.quotes[0];
    } else {
      this.selectedQuoteToCompareOne = SharedConfig.selectedQuoteToCompareOne = this.sharedConfig.quoteRequest.quotes.find((x) => x.recommended);
if(!this.selectedQuoteToCompareOne) this.selectedQuoteToCompareOne = this.sharedConfig.quoteRequest.quotes[0]
      this.selectedQuoteToCompareTwo = SharedConfig.selectedQuoteToCompareTwo = this.sharedConfig.quoteRequest.quotes.filter(
        (x) => !x.recommended
      )[0];
    }

    if (!SharedConfig.selectedQuoteSpecialConditions) {
      this.busnQuoteScheduleService.getSpecialConditions().then((specialConditions) => {
        const a = specialConditions
        SharedConfig.selectedQuoteSpecialConditions = specialConditions;
      });
    }
    console.log(this.selectedQuoteToCompareOne);
  }

  navConvenienceCover() {
    this.navigateQuoteComparisonDetails.emit(QuoteComparisonDetailType.ConvenienceCover);
  }

  navEsssentialCover() {
    this.navigateQuoteComparisonDetails.emit(QuoteComparisonDetailType.EssentialCover);
  }

  navExcessComparison() {
    this.navigateQuoteComparisonDetails.emit(QuoteComparisonDetailType.Excess);
  }

  navSpecialConditions() {
    this.navigateQuoteComparisonDetails.emit(QuoteComparisonDetailType.SpecialConditions);
  }

  navOptionalCover() {
    this.navigateQuoteComparisonDetails.emit(QuoteComparisonDetailType.OptionalCover);
  }

  navPremium() {
    this.navigateQuoteComparisonDetails.emit(QuoteComparisonDetailType.Premium);
  }

  async mandate() {
    this.sharedDialogService.showPopupDialog({
      header: 'Double checking',
      subHeader:
        "The insurer might need to run a final check on your quote. If you want to accept this one, please let us know in the chat and we'll make sure it's done as soon as possible.",
      buttonOneText: 'Got it',
      buttonOneCallback: this.sharedDialogService.hidePopupDialog,
    });
  }

  async selectQuote(selectedQuote: IQuote) {
    SharedConfig.selectedQuote = selectedQuote;

    if (selectedQuote.holiday_period_cover) {
      this.busnThirdPartyService.logEvent('action_holiday_period_cover_selected', { quote: selectedQuote.insurer_description });
      const emailSuccess = await this.busnCommunicationService.sendHolidayViolationEmail(SharedConfig.firebaseUserKey);
      if (!emailSuccess) {
        this.alert('An Error occured', 'We are unable to select this quote at this moment. Please contact us in the chat.');
      } else {
        this.alert(
          'That time of the year',
          'This is a high-risk time of the year and some insurers take extra precautions. We’ll let you know in the chat once the cover is approved, or if we need more information.'
        );
      }
    } else if (selectedQuote.mandate_violations_draft) {
      this.busnThirdPartyService.logEvent('action_mandated_quote_selected', { quote: selectedQuote.insurer_description });

      const emailSuccess = await this.busnCommunicationService.sendMandateViolationEmail(SharedConfig.user.id, selectedQuote.insurer_description);

      if (!emailSuccess || !emailSuccess.success) {
        this.alert('An Error occured', 'We are unable to select this quote at this moment. Please contact us in the chat.');
      } else {
        this.alert(
          'Good stuff',
          'This insurer is just confirming a few things before you can accept the quote right here in the app. We’ll let you know as soon as they give the go-ahead. Thanks for your patience!'
        );
      }
    } else {
      let manddateViolation = false;
      if (this.sharedConfig.quoteRequest.mandate_violations_draft) manddateViolation = true;
      this.busnThirdPartyService.logEvent('action_quote_chosen', {
        timestamp: new Date(),
        quote_request_id: this.sharedConfig.quoteRequest.id,
        selectedQuote: selectedQuote.insurer_id,
        recommendedQuote: selectedQuote.recommended,
        mandateViolation: manddateViolation,
      });
      this.acceptQuote(selectedQuote);
    }
  }

  async alert(header, message) {
    this.sharedDialogService.showPopupDialog({
      header: header,
      subHeader: message,
      buttonOneText: 'Got it',
      buttonOneCallback: this.sharedDialogService.hidePopupDialog,
    });
  }

  compIncrementQuoteOne() {
    this.selectedQuoteToCompareOne = SharedConfig.selectedQuoteToCompareOne =
      this.sharedObjectTransformationService.incrementGloballyComparedQuoteOne(
        this.sharedConfig.quoteRequest.quotes,
        this.selectedQuoteToCompareOne,
        this.selectedQuoteToCompareTwo
      );
  }

  compIncrementQuoteTwo() {
    this.selectedQuoteToCompareTwo = SharedConfig.selectedQuoteToCompareTwo =
      this.sharedObjectTransformationService.incrementGloballyComparedQuoteTwo(
        this.sharedConfig.quoteRequest.quotes,
        this.selectedQuoteToCompareOne,
        this.selectedQuoteToCompareTwo
      );
  }

  acceptQuote(selectedQuote: IQuote) {
    this.navigateQuoteBankingDetails.emit();
  }
}
