import { Injectable } from '@angular/core';
import { IUser } from '../interfaces/user.interface';
import { SharedConfig } from '../svcs-share-config.module';

@Injectable()
export class UserFactory{

  constructor(
      public sharedConfig: SharedConfig
  ){}

  static initUser(
      email: string,
      name: string,
      surname: string,
      referralCode: string,
      hard_of_hearing: boolean,
      popiaConsentGiven:boolean,
      popia_consent_message:string,
      user_referral_code: string
      ): IUser {
        const user: IUser = {
            personal_details: {
                name: name,
                surname: surname,
                email: email
            },
            account_details: {
                hard_of_hearing: hard_of_hearing,
                user_referral_code: user_referral_code,
                signup_code: referralCode,
                account_type: "signup-user",
                itc_check: false,
                ctrl_consent_check: false,
                popia_consent: popiaConsentGiven,
                popia_consent_timestamp: new Date().getTime(),
                popia_consent_message: popia_consent_message,
                
            },
            insurance_history: {
                special_conditions: false
            },
            created_time: '',
            is_deleted: false,
        };
        if (SharedConfig.broker){
            user.broker = SharedConfig.broker
        }
        console.log("Created user");
        return user;
    }

}