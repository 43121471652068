import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { APIMailService } from '@takectrl-multiapp/svcs-ctrl-api';
import { firstValueFrom } from 'rxjs';
import { IClaimMailPayload, IEmergencyMailPayload, IHolidayViolationMailPayload, IMandateViolationMailPayload, SharedConfig } from '@takectrl-multiapp/svcs-share-config';
import { ICtrlResponse } from 'libs/svcs-ctrl-api/src/lib/interfaces/api.interface';
import { Platform } from '@ionic/angular';
import { CommunicationService } from '@takectrl-multiapp/svcs-core';
import { CallNumber } from '@ionic-native/call-number/ngx';

@Injectable({
  providedIn: 'root',
})
export class BUSNCommunicationService {
  constructor(
    public sharedConfig: SharedConfig,
    public callNumber: CallNumber,
    public communicationService: CommunicationService,
    private platform: Platform,
    private readonly apiMailService: APIMailService,
    public angularFireDatabase: AngularFireDatabase,
  ) {}


  async sendMandateViolationEmail(user_id: number, insurer: string): Promise<ICtrlResponse<unknown>> {
    console.log('Sending mandate violation email');
    const payload: IMandateViolationMailPayload = {
      user_id,
      insurer,
    };
    return await firstValueFrom(this.apiMailService.mandateViolationEmail(payload));
  }

  async sendHolidayViolationEmail(user_id: string): Promise<ICtrlResponse<unknown>> {
    console.log('Sending holiday violation email');
    const payload: IHolidayViolationMailPayload = {
      user_id,
    };
    return await firstValueFrom(this.apiMailService.holidayViolationEmail(payload));
  }
  
  async sendClaimEmail(emailPayload: IClaimMailPayload): Promise<any> {
    console.log('Sending claims email')
    const claimsResult = await (firstValueFrom(this.apiMailService.newClaimEmail(emailPayload)));
    return claimsResult
  }

  async sendBrokerEmail(emailPayload: IEmergencyMailPayload): Promise<any> {
    const brokerResult = await (firstValueFrom(this.apiMailService.sendBrokerEmergencyEmail(emailPayload)));
    return brokerResult;
  }


  callBroker(): void {
    if (this.platform.is('mobileweb')) {
      window.open('tel:' + this.sharedConfig.user.broker.telephone, '_blank');
    } else if (this.platform.is('desktop')) {
      SharedConfig.showPopupWithDismissButton = true;
      SharedConfig.headerForPopupWithDismissButton = 'Broker details:';
      SharedConfig.messageForPopupWithDismissButton = this.sharedConfig.user.broker.contact + ': ' + this.sharedConfig.user.broker.telephone;
      SharedConfig.buttonTextForPopupWithDismissButton = 'OK';
    } else {
      this.communicationService.makePhoneCall(this.sharedConfig.user.broker.telephone);
    }
  }
}
